<template>
  <section class="WaStepper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 text-center">
          <p
            class="d-flex flex-column flex-sm-row align-items-center justify-content-center"
          >
            <slot name="step" />
            <span
              v-if="$_cmsSupport_hasContentSlotWithName('label')"
              class="separator"
            >
              <slot name="label" />
            </span>
          </p>
          <slot name="title" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WaStepper',
  props: {
    dataActualStep: {
      type: [String, Number],
      default: '1',
    },
    dataTotalSteps: {
      type: [String, Number],
      default: '1',
    },
  },
  created() {
    this.setStep();
  },
  beforeUpdate() {
    this.setStep();
  },
  methods: {
    setStep() {
      if (this.$_cmsSupport_hasContentSlotWithName('step')) {
        this.$slots['step'] = this.$slots['step'].map((node) => {
          node.text = this.$_stringSupport_interpolate(node.text, [
            this.dataActualStep,
            this.dataTotalSteps,
          ]);
          return node;
        });
      } else {
        console.log(
          'Error: wa-stepper requires content be provided in the step slot.'
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaStepper {
  .separator {
    &::before {
      content: none;

      @media (min-width: $bootstrap-sm) {
        content: '-';
        display: inline;
        margin: 0 5px;
      }
    }
  }
}
</style>
