<template functional>
  <li
    :class="['WaOneTrustPanelCta', data.staticClass, data.class]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <a href="#" class="navItemFooter ot-sdk-show-settings" @click.prevent="">
      <slot />
    </a>
  </li>
</template>

<script>
export default {
  name: 'WaOneTrustPanelCta',
  functional: true,
  props: {},
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaOneTrustPanelCta {
  font-size: inherit;
}

.navItemFooter {
  color: $color-blue-logo;
  text-decoration: none;
  display: block;
  font-weight: $fh-medium; //fh-normal

  &:active,
  &:hover {
    color: $color-blue-logo;
    text-decoration: underline;
  }
}

.bg-color-blue-logo {
  .navItemFooter {
    color: $color-white;
    text-decoration: none;

    &:active,
    &:hover {
      color: $color-white;
    }
  }
}
</style>
