var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ContactFormThankYouCmp"},[_c('wa-side-backgrounds',{attrs:{"data-background-right":((_vm.$root.$context.staticRoot) + "project/img/contact-form/img-right.jpg"),"data-background-left":((_vm.$root.$context.staticRoot) + "project/img/contact-form/img-left.jpg"),"data-width-left":"302","data-height-left":"400","data-width-right":"302","data-height-right":"400"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center"},[_c('svg',{staticClass:"icon text-color-secondary-item",attrs:{"width":"48","height":"48"}},[_c('use',{attrs:{"href":"#icon-email-send","fill":"currentColor"}})]),_c('div',{class:[
              'title-wrapper',
              'typo-w',
              'typo-w-4',
              'typo-bold',
              'text-center',
              'text-color-secondary-item',
              'padding-top-xs',
              { 'padding-bottom-m': !_vm.routes.thank_you.image } ],domProps:{"innerHTML":_vm._s(_vm.routes.thank_you.texts.title)}}),(_vm.routes.thank_you.image)?_c('div',{staticClass:"image-wrapper padding-top-r padding-bottom-r"},[_c('img',{attrs:{"src":_vm.routes.thank_you.image}})]):_vm._e(),_c('div',{staticClass:"typo-a-7 typo-light text-center text-color-black padding-bottom-m"},[(_vm.unlockedThankYou.submission_number)?[_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.routes.thank_you.texts.numberLabel)+" ")]),_c('span',{staticClass:"d-block typo-w typo-w-4 typo-bold text-color-secondary-item padding-top-s"},[_vm._v(" "+_vm._s(_vm.unlockedThankYou.submission_number)+" ")])]:_c('span',[_vm._v(_vm._s(_vm.routes.thank_you.texts.noNumberLabel))])],2),_c('wa-cta',{staticClass:"primary",attrs:{"data-href":_vm.routes.thank_you.controls.doneCta.goToUrl}},[_vm._v(" "+_vm._s(_vm.routes.thank_you.controls.doneCta.label)+" ")])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }