import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import {
  ApiError,
  UnknownError,
  ValidationError,
  prettyPrintErrorProperties,
} from '../helpers/utils';

Vue.use(VueScrollTo);

const FormElementsSupport = Vue.extend({
  data() {
    return {
      /* date and time format */
      clientDateFormat: 'DD/MM/YYYY',
      apiDateFormat: 'YYYY-MM-DD',
      /* after submit */
      submitted: false,
      loading: false,
      serverErrors: {},
      scrollToError: true,
      globalErrors: [],
      toastErrors: [],
    };
  },
  methods: {
    $_formElementsSupport_handleApiError(apiResponse) {
      // the api has issues
      // show a feedback to the user like a toast or global/contextual error
      if (
        this.$root.$refs['$error-toast'] ||
        this.$parent.$refs['$error-toast'] ||
        this.$refs['$error-toast']
      ) {
        this.toastErrors.push('ApiError: Something wrong');
      } else {
        this.globalErrors.push({
          condition: true,
          message: 'ApiError: Something wrong',
        });
      }
      // Renew the submission
      this.submitted = false;
      // Throw the error
      throw new ApiError('Something wrong with the API');
    },
    $_formElementsSupport_handleUnknownError(e) {
      // 500 or unknown status code
      // show a feedback to the user like a toast or global/contextual error
      if (
        this.$root.$refs['$error-toast'] ||
        this.$parent.$refs['$error-toast'] ||
        this.$refs['$error-toast']
      ) {
        this.toastErrors.push(
          'UnknownError: Wrong Status code or Unexpected error'
        );
      } else {
        this.globalErrors.push({
          condition: true,
          message: 'UnknownError: Wrong Status code or Unexpected error',
        });
      }
      // Renew the submission
      this.submitted = false;
      // Log and throw the unknown error
      prettyPrintErrorProperties(e);
      throw new UnknownError('Wrong Status code or Unexpected error');
    },
    $_formElementsSupport_handleErrors(e, i18nToken = null) {
      // status code errors (default: 400, 401, 403) you can customize this props to manage more status code
      // show a feedback to the user like a toast or global/contextual error
      const properties = Object.getOwnPropertyNames(e);
      properties.forEach((p) => {
        const prop = Object.getOwnPropertyDescriptor(e, p);
        if (prop && prop.value) {
          if (p === 'response') {
            let unknownError = true;
            if (prop.value.status === 400) {
              /**
               * Validation errors
               * expected response
               * {"username":["Questo campo non può essere omesso","Questo campo non può essere omesso 2"],"description":["Questo campo non può essere omesso"]};
               */
              let data = prop.value.data;
              if (data) {
                unknownError = false;
                let remoteErrors = false;
                if ('errors' in data) {
                  remoteErrors = true;
                  data = data.errors;
                }
                for (const [property, value] of Object.entries(data)) {
                  if (property == 'non_field_errors') {
                    // Global validation errors
                    this.globalErrors.push({
                      condition: true,
                      message:
                        i18nToken && i18nToken[value]
                          ? i18nToken[value]
                          : value,
                    });
                  } else {
                    const temp = {};
                    if (!remoteErrors) {
                      temp[property] =
                        i18nToken && i18nToken[value]
                          ? i18nToken[value]
                          : value;
                    } else {
                      temp[property] = value;
                    }
                    this.serverErrors = { ...this.serverErrors, ...temp };
                  }
                }
                Object.keys(this.serverErrors).forEach((key, index) => {
                  if (this.scrollToError && index === 0) {
                    this.$scrollTo(
                      document.querySelector(`[name='${key}']`),
                      500,
                      {
                        cancelable: false,
                        easing: 'ease',
                        offset: -30,
                      }
                    );
                  }
                  this.$v[key] && this.$v[key].$touch();
                  const unwatch = this.$watch(key, (newVal, oldVal) => {
                    if (newVal !== oldVal) {
                      Vue.delete(this.serverErrors, key);
                      setTimeout(() => {
                        this.$v[key] && this.$v[key].$touch();
                      }, 0);
                      if (unwatch) {
                        unwatch();
                      }
                    }
                  });
                });
              }
            } else if (prop.value.status === 401 || prop.value.status === 403) {
              const data = prop.value.data;
              if (data) {
                unknownError = false;
                this.globalErrors.push({
                  condition: true,
                  message: data.detail,
                });
              }
            } else {
              // Other status code errors
              const statusText = prop.value.statusText;
              if (statusText) {
                unknownError = false;
                this.globalErrors.push({
                  condition: true,
                  message: statusText,
                });
              }
            }
            if (unknownError) {
              this.$_formElementsSupport_handleUnknownError(e);
            } else {
              // Renew the submission
              this.submitted = false;
              // Throw the error
              throw new ValidationError(`Status code ${e.response.status}`);
            }
          }
        }
      });
    },
  },
});

export default FormElementsSupport;
