var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"watabsContainer",staticClass:"WaTabs container-fluid"},[_c('div',{ref:"watabsRow",staticClass:"row externalRow"},[(_vm.$viewportWidth < 768)?_c('div',{staticClass:"col-12 externalCol"},[(_vm.categories.length)?_c('scroll-x-navbar-cmp',{ref:"horizontalMainMenu",class:[
          'mainCategoriesList horizontalMenu',
          {
            gradientSx: _vm.mainScrollerHasGradientLeft,
            gradientDx: _vm.mainScrollerHasGradientRight,
          } ],attrs:{"nav-identifier-class":"scrollXMainMenu"}},_vm._l((_vm.categories),function(category){return _c('li',{key:((category.slug) + "-" + _vm._uid + "-mobile-scroll"),ref:((category.slug) + "-" + _vm._uid + "-mobile-scroll"),refInFor:true,on:{"click":function($event){_vm.selectMainCategory(category, true);
            _vm.scrollToEl(
              _vm.$refs.horizontalMainMenu.$refs.scroller,
              ((category.slug) + "-" + _vm._uid + "-mobile-scroll"),
              _vm.directionToScrollInViewport(
                ((category.slug) + "-" + _vm._uid + "-mobile-scroll"),
                100
              )
            );}}},[_c('span',{class:[
              'mainCategory',
              'typo-w-4',
              {
                selectedMainCategory: _vm.selectedCategory.slug === category.slug,
              } ]},[_vm._v(" "+_vm._s(category.label)+" ")])])}),0):_vm._e()],1):_vm._e(),(_vm.$viewportWidth < 768)?_c('div',{class:[
        'sticky-top',
        {
          hasHeaderOffset: _vm.hasHeaderOffset,
        } ]},[(
          _vm.subCategoriesByCategorySlug[_vm.selectedCategory.slug] &&
          _vm.subCategoriesByCategorySlug[_vm.selectedCategory.slug].length
        )?_c('scroll-x-navbar-cmp',{ref:"horizontalSubMenu",class:[
          'subCategoriesList horizontalMenu',
          {
            // 'gradientSx':subScrollerHasGradientLeft,
            // 'gradientDx':subScrollerHasGradientRight
          } ],attrs:{"nav-identifier-class":"scrollXSubMenu"}},_vm._l((_vm.subCategoriesByCategorySlug[
            _vm.selectedCategory.slug
          ]),function(subCategory){return _c('li',{key:((_vm.selectedCategory.slug) + "-" + (subCategory.slug) + "-mobile-scroll"),on:{"click":function($event){return _vm.selectSubCategory(subCategory)}}},[_c('span',{staticClass:"subCategoryItem"},[_c('span',{class:[
                'subCategory',
                'typo-a-7',
                {
                  selectedSubcategory:
                    _vm.activeSubCategory.slug === subCategory.slug,
                } ]},[_vm._v(" "+_vm._s(subCategory.label)+" ")])])])}),0):_vm._e()],1):_vm._e(),(_vm.$viewportWidth >= 768)?_c('div',{staticClass:"col-sm-4 col-md-3"},[_c('div',{staticClass:"verticalMenu sticky-top"},[(_vm.categories.length)?_c('ul',{staticClass:"mainCategoriesList"},_vm._l((_vm.categories),function(category){return _c('li',{key:((category.slug) + "-" + _vm._uid + "-desk"),ref:((category.slug) + "-" + _vm._uid + "-desk"),refInFor:true,staticClass:"margin-bottom-s",on:{"click":function($event){return _vm.selectMainCategory(category)}}},[_c('span',{class:[
                'mainCategory',
                'typo-w-4',
                {
                  selectedMainCategory: _vm.inOpenAccordions(category.slug),
                } ]},[_vm._v(" "+_vm._s(category.label)+" ")]),_c('ul',{directives:[{name:"expand",rawName:"v-expand",value:(_vm.inOpenAccordions(category.slug)),expression:"inOpenAccordions(category.slug)"}],class:['subCategoriesList'],attrs:{"aria-expanded":!_vm.inOpenAccordions(category.slug) ? 'true' : 'false',"aria-hidden":_vm.inOpenAccordions(category.slug) ? 'true' : 'false'}},_vm._l((_vm.subCategoriesByCategorySlug[
                  category.slug
                ]),function(subCategory,idx){return _c('li',{key:((category.slug) + "-" + (subCategory.slug) + "-" + _vm._uid + "-desk"),ref:((category.slug) + "-" + (subCategory.slug) + "-" + _vm._uid + "-desk"),refInFor:true,class:[
                  'subCategoryWrapper',
                  {
                    'padding-top-s': idx === 0,
                  } ],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.selectSubCategory(subCategory)}}},[_c('span',{class:[
                    'subCategory',
                    'typo-a-7',
                    {
                      selectedSubcategory:
                        _vm.activeSubCategory.slug === subCategory.slug &&
                        _vm.activeCategory.slug === category.slug,
                    } ]},[_vm._v(" "+_vm._s(subCategory.label)+" ")])])}),0)])}),0):_vm._e()])]):_vm._e(),_c('div',{staticClass:"col-12 col-sm-8 col-md-9 text-center"},[_c('div',{staticClass:"products container-fluid p-0"},[_c('transition-group',{staticClass:"productsTransition row",attrs:{"name":"slide","mode":"out-in","duration":{ enter: 500, leave: 0 }}},_vm._l((_vm.paginatedProducts.products),function(product,prodIndex){return _c('div',{key:prodIndex + product.label,staticClass:"col-12 col-sm-6 col-md-4"},[(_vm.$root.isPowerReviewActive)?[_c('pr-category-review-wrapper',{attrs:{"data-item":product}},[_c('wa-product-card',{class:[
                    'product',
                    {
                      'margin-top-s': _vm.productMarginClass(prodIndex),
                    } ],attrs:{"data-conf":product}})],1)]:[_c('wa-product-card',{class:[
                  'product',
                  {
                    'margin-top-s': _vm.productMarginClass(prodIndex),
                  } ],attrs:{"data-conf":product}})]],2)}),0)],1)]),_c('div',{staticClass:"col-12 offset-sm-4 col-sm-8 offset-md-3 col-md-9 text-center"},[(_vm.showLoadMore)?_c('wa-cta',{staticClass:"primary margin-top-m loadMoreBtn",on:{"click":function($event){$event.preventDefault();_vm.numberOfProducts += 6}}},[_vm._v(" "+_vm._s(_vm.$store.state.config.load_more_label)+" ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }