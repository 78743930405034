import { render, staticRenderFns } from "./wa-pm9-leisure-and-fun-tt-cta.vue?vue&type=template&id=2eca9de0&scoped=true&functional=true&"
import script from "./wa-pm9-leisure-and-fun-tt-cta.vue?vue&type=script&lang=js&"
export * from "./wa-pm9-leisure-and-fun-tt-cta.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "2eca9de0",
  null
  
)

export default component.exports