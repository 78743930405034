import { render, staticRenderFns } from "./wa-pm5-6-sandwich-summer-campaign-thank-you.vue?vue&type=template&id=ed5b2754&scoped=true&"
import script from "./wa-pm5-6-sandwich-summer-campaign-thank-you.vue?vue&type=script&lang=js&"
export * from "./wa-pm5-6-sandwich-summer-campaign-thank-you.vue?vue&type=script&lang=js&"
import style0 from "./wa-pm5-6-sandwich-summer-campaign-thank-you.vue?vue&type=style&index=0&id=ed5b2754&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed5b2754",
  null
  
)

export default component.exports