import { render, staticRenderFns } from "./wa-pmextra-norway-sandwich-cta.vue?vue&type=template&id=452af402&scoped=true&functional=true&"
import script from "./wa-pmextra-norway-sandwich-cta.vue?vue&type=script&lang=js&"
export * from "./wa-pmextra-norway-sandwich-cta.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "452af402",
  null
  
)

export default component.exports