<template>
  <div class="WaFocusItem">
    <figure class="innerWrapper">
      <svg v-if="dataIconId" :class="['icon', dataIconColor]">
        <use :href="`#${dataIconId}`" fill="currentColor" />
      </svg>
      <div v-else class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-8 col-sm-7 col-md-12">
            <ratio-size-box
              data-width-ratio-component="4"
              data-height-ratio-component="3"
              data-vertical-align-content="center"
              data-crop="cover"
            >
              <slot name="image" />
            </ratio-size-box>
          </div>
        </div>
      </div>
      <figcaption
        v-if="hasSlotTitle || hasSlotDescription"
        :class="[
          { 'padding-top-r': dataIconId, 'padding-top-xs': !dataIconId },
        ]"
      >
        <div
          v-if="hasSlotTitle"
          class="itemTitle typo-normal typo-w-4 noMBLastChild padding-bottom-s"
        >
          <slot name="title" />
        </div>
        <div
          v-if="hasSlotDescription"
          class="itemDescription typo-light typo-a-7 noMBLastChild"
        >
          <slot name="description" />
        </div>
      </figcaption>
      <wa-cta
        v-if="hasModal"
        class="padding-top-s typo-a-8"
        data-icon-id="icon-arrow-angle-right"
        data-force-click="true"
        data-href="#"
        @click.stop.prevent="openModal()"
      >
        {{ dataLabelCta }}
      </wa-cta>
    </figure>
    <wa-modal
      v-if="hasModal"
      ref="ItemModal"
      :data-click-to-close="false"
      :data-name="`item-modal-${_uid}`"
      :data-has-close-button="true"
      :class="[
        {
          hasBigModal: hasBigModal,
        },
      ]"
    >
      <div class="modal-layout">
        <div v-if="hasBigModal" class="modal-image-wrapper">
          <img :src="dataModalImageSrc" alt="" />
        </div>
        <div class="modal-content-wrapper padding-top-m padding-bottom-m">
          <div class="dWrapper" v-html="dataModalContent"></div>
        </div>
      </div>
    </wa-modal>
  </div>
</template>

<script>
/**
 * <wa-focus-item
class="padding-top-l"
  data-label-cta="Lorem ipsum"
  data-modal-image-src="https://picsum.photos/4000"
  :data-has-modal="true"
  data-modal-content="<h3 class='typo-w-4 typo-bold text-color-natural'>Insektenfreundliche BefpFlanzung</h3>
          <h6 clsaa='typo-a-7 typo-extra-bold text-color-black'>Du hast einen Garten?</h6>
          <p class='typo-a-7 typo-light text-color-black'>Blühfläche statt Grünfläche: Lege auf deinem Zierrasen früh-, mittel- und spätblühende Sorten Pflanzen an, um Insekten ganzjährig Nahrung zu bieten. Eine Übersicht mit besonders  insektenfreundlichen Pflanzen findest du hier.</p>
          <h6 clsaa='typo-a-7 typo-extra-bold text-color-black'>Du hast einen Garten?</h6>
          <p class='typo-a-7 typo-light text-color-black'>Blühfläche statt Grünfläche: Lege auf deinem Zierrasen früh-, mittel- und spätblühende Sorten Pflanzen an, um Insekten ganzjährig Nahrung zu bieten. Eine Übersicht mit besonders  insektenfreundlichen Pflanzen findest du hier.</p>
          <h6 clsaa='typo-a-7 typo-extra-bold text-color-black'>Du hast einen Garten?</h6>
          <p class='typo-a-7 typo-light text-color-black'>Blühfläche statt Grünfläche: Lege auf deinem Zierrasen früh-, mittel- und spätblühende Sorten Pflanzen an, um Insekten ganzjährig Nahrung zu bieten. Eine Übersicht mit besonders  insektenfreundlichen Pflanzen findest du hier.</p>
          "
>
                  <template slot="image">
                    <img src="https://picsum.photos/2000" alt="Lorem ipsum">
                  </template>
                  <span
                    slot="title"
                  >
                    LOREM IPSUM
                  </span>
                  <template slot="description">
                    lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                  </template>
                </wa-focus-item>

 */
export default {
  name: 'WaFocusItem',
  props: {
    dataIconId: {
      type: String,
      default: null,
    },
    dataIconColor: {
      type: String,
      default: null,
    },
    dataLabelCta: {
      type: String,
      default: null,
    },
    dataHasModal: {
      type: [String, Boolean],
      default: false,
    },
    dataModalImageSrc: {
      type: String,
      default: null,
    },
    dataModalContent: {
      type: String,
      default: null,
    },
  },
  computed: {
    hasSlotTitle() {
      return !!(this.$slots['title'] || [])[0];
    },
    hasSlotDescription() {
      return !!(this.$slots['description'] || [])[0];
    },
    hasModal() {
      return this.$_cmsSupport_castPropToType(this.dataHasModal, 'boolean');
    },
    hasBigModal() {
      return !!this.dataModalImageSrc;
    },
  },
  methods: {
    openModal() {
      this.$modal.show(`item-modal-${this._uid}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaFocusItem {
  ::v-deep .WaModal.hasBigModal .modal-container {
    @media (min-width: $bootstrap-sm) {
      width: 100vw;
      height: 100vh;
      padding: 0;
      margin: 0;
    }
  }
  ::v-deep .WaModal.hasBigModal .modal-container .modal-box {
    @media (min-width: $bootstrap-sm) {
      height: 100vh;
      max-height: none;
      padding: 0;
      width: 100vw;
      height: 100vh;
      padding: 0;
      margin: 0;
    }
  }
  ::v-deep .WaModal.hasBigModal .modal-close {
    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: $color-grey-9-75;
      &:before,
      &:after {
        background: $color-white;
      }
    }
    @include media-breakpoint-up(md) {
      &:before,
      &:after {
        width: 32px;
        margin: -1px 0 0 -16px;
      }
    }
  }
}

.innerWrapper {
  margin: 0;
  text-align: center;

  .icon {
    width: 96px;
    height: 96px;
  }
}
.modal-content-wrapper {
  padding-left: 32px;
  padding-right: 32px;
}

.hasBigModal {
  .modal-layout {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    position: relative;
    flex-direction: column;
    @media (min-width: $bootstrap-md) {
      height: 100vh;
      overflow: hidden;
    }

    .modal-content-wrapper {
      width: calc(100% - 60px);
      margin: 0 auto;
      padding: 0;
      @media (min-width: $bootstrap-sm) {
        width: calc(100% - 120px);
      }
      @media (min-width: $bootstrap-md) {
        width: calc(100% - 382px);
        overflow: auto;
        .dWrapper {
          width: 500px;
          margin: 0 auto;
        }
      }
      @media (min-width: $bootstrap-lg) {
        width: calc(100% - 382px);
        .dWrapper {
          width: 735px;
          margin: 0 auto;
        }
      }
    }
    @media (min-width: $bootstrap-md) {
      flex-direction: row;
      justify-content: center;
      min-height: 0;
    }

    // Modal mobile and tablet - upper image layout
    .modal-image-wrapper {
      position: relative;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      min-height: 200px;
      max-height: 280px;
      img {
        position: absolute;
        min-height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    // Modal over tablet devices - left column image layout
    @media (min-width: $bootstrap-md) {
      .modal-image-wrapper {
        width: 382px;
        max-height: 99999px;
        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }
}
</style>
