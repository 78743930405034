<template>
  <div class="ContactFormTriageFaqCmp">
    <div
      v-for="(accordion, i) in accordions"
      :key="`accordion-${i}`"
      class="accordion-wrapper"
    >
      <h3
        v-if="accordion.title"
        class="typo-w typo-w-5 typo-bold text-color-secondary-item text-center margin-bottom-0 padding-bottom-r"
      >
        {{ accordion.title }}
      </h3>
      <wa-accordion class="fullwidth">
        <wa-accordion-item
          v-for="(item, j) in accordion.items"
          :key="`accordion-item-${j}`"
          class="accordion-item margin-bottom-0"
        >
          <template #title>
            <h4
              class="typo-a typo-a-6 typo-medium text-color-blue-logo margin-bottom-0"
            >
              {{ item.title }}
            </h4>
          </template>
          <template #content>
            <div class="content-wrapper padding-top-r" v-html="item.content" />
          </template>
        </wa-accordion-item>
      </wa-accordion>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactFormTriageFaqCmp',
  computed: {
    ...mapState('contactForm2024', ['faqs', 'queryString']),
    faqsType() {
      if (this.queryString?.triage_type && this.queryString?.triage_topic) {
        return this.queryString?.triage_promotions ? 'promotions' : 'generic';
      }

      return null;
    },
    accordions() {
      return this.faqsType
        ? this.faqsType === 'promotions'
          ? this.faqs[this.faqsType][this.queryString.triage_promotions]
          : this.faqs[this.faqsType]
        : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormTriageFaqCmp {
  .accordion-wrapper {
    & ~ .accordion-wrapper {
      padding-top: var(--contact-form-accordion-wrapper-padding-top, 0);
    }

    .accordion-item {
      & ~ .accordion-item {
        margin-top: var(--contact-form-accordion-item-margin-top, 0);
      }

      ::v-deep .content-wrapper {
        *:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
