<template>
  <wa-side-backgrounds
    class="padding-top-xxl padding-bottom-xxl"
    :data-background-right="
      require('@images/contest-plant-a-tree/thank-you-bg-right.png')
    "
    :data-background-left="
      require('@images/contest-plant-a-tree/thank-you-bg-left.png')
    "
    data-width-right="286"
    data-height-right="336"
    data-width-left="302"
    data-height-left="326"
  >
    <div class="WaContestPlantATreeThankYou">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-healty-green"
          >
            <svg class="icon margin-bottom-r">
              <use href="#icon-email-send" fill="currentColor" />
            </svg>
            <div
              class="title typo-w-4 typo-bold text-center margin-bottom-r noMBLastChild"
              v-html="contentData.thank_you_title"
            />
            <p
              class="content typo-a-7 typo-light text-center text-color-black margin-bottom-m"
            >
              {{ contentData.thank_you_description }}
            </p>
            <wa-cta class="primary" :data-href="home_page_url">
              {{ contentData.thank_you_cta }}
            </wa-cta>
          </div>
        </div>
      </div>
    </div>
  </wa-side-backgrounds>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { debug } from '@helpers/utils';

export default {
  name: 'WaContestPlantATreeThankYou',
  computed: {
    ...mapState('config', ['home_page_url']),
    ...mapState('contestPlantATree', ['ch-key', 'contentData']),
  },
  created() {
    this.setInvertedHeader(false);
  },
  mounted() {
    this.$nextTick(() => {
      this.pushGenericCh(this['ch-key']);
      try {
        window.dataLayer.push({ event: 'TYP_plant-a-tree' });
      } catch (error) {
        debug &&
          console.log(
            '%c[CANNOT PUSH ON DATA LAYER]',
            'padding:4px;background-color:red;color:white;'
          );
        debug && console.error(error);
      }
    });
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    ...mapActions('gigya', ['pushGenericCh']),
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaContestPlantATreeThankYou {
  .icon {
    width: 48px;
    height: 48px;
  }
}
</style>
