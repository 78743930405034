<template>
  <div class="WaRecipeInfoList">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-4">
          <div class="ingredients">
            <h3 :class="['title typo-w-4 typo-bold margin-bottom-0']">
              {{ dataIngredientsLabel }}
            </h3>
            <ul class="ingredientsList margin-top-r margin-bottom-0">
              <li
                v-for="(ingredient, idx) in ingredients"
                :key="`${idx}-${_uid}`"
                class="bulletItem margin-top-0 margin-bottom-s typo-a-6 typo-light"
              >
                {{ ingredient }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-7 offset-sm-1">
          <div class="howToList">
            <h3 class="title typo-w-4 typo-bold margin-bottom-0">
              {{ dataStepsLabel }}
            </h3>
            <ol class="stepsList margin-top-r margin-bottom-0">
              <li
                v-for="step in steps"
                :key="step"
                class="numberItem margin-top-0 margin-bottom-s typo-a-6 typo-light"
              >
                <span class="bullet" />
                {{ step }}
              </li>
            </ol>
            <div class="cta margin-top-r">
              <wa-cta
                class="inverted-link text-color-blue-logo"
                data-icon-id="icon-back"
                :data-href="dataCtaLink"
              >
                {{ dataCtaText }}
              </wa-cta>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaRecipeInfoList',
  props: {
    dataIngredientsLabel: {
      type: String,
      default: '',
    },
    dataIngredients: {
      type: [Array, String],
      default: () => [],
    },
    dataStepsLabel: {
      type: String,
      default: '',
    },
    dataSteps: {
      type: [Array, String],
      default: () => [],
    },
    dataCtaLink: {
      type: String,
      default: '',
    },
    dataCtaText: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {};
  },
  computed: {
    steps() {
      return this.$_cmsSupport_castPropToType(this.dataSteps, 'array');
    },
    ingredients() {
      return this.$_cmsSupport_castPropToType(this.dataIngredients, 'array');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaRecipeInfoList {
  .title {
    color: $color-secondary-cyan;
  }

  .bulletItem {
    position: relative;
    display: block;
    padding-left: 13px;

    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      //margin-right: 8px;
      background-color: $color-black;
      top: 7px;
      left: 0;
      @include media-breakpoint-up(sm) {
        top: 9px;
      }
      @include media-breakpoint-up(md) {
        top: 12px;
      }
      @include media-breakpoint-up(lg) {
        top: 12px;
      }
    }

    .ingredientsList {
      list-style: none;
    }
  }

  .howToList {
    margin-top: 40px;

    @include media-breakpoint-up(sm) {
      margin-top: 0px;
    }

    .stepsList {
      list-style: none;
      counter-reset: step-counter;
      padding-inline-start: 0px;

      .numberItem {
        display: flex;
        counter-increment: step-counter;
        align-items: flex-start;

        &::before {
          content: counter(step-counter);
          font-weight: $fh-extra-bold;
        }

        .bullet {
          content: '';
          background-color: $color-black;
          display: block;
          padding: 2px;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          margin-right: 8px;
          margin-left: 2px;
          margin-top: 11px;
          @include media-breakpoint-up(sm) {
            margin-top: 14px;
          }
          @include media-breakpoint-up(md) {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .cta {
    /deep/.svgWapper {
      margin-left: 0;
    }
  }
}
</style>
