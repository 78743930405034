var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"WaSideBySideRecipes"},[_c('div',{staticClass:"sideBySideWrapper container-fluid"},[_c('div',{staticClass:"row"},[(
          _vm.hasImageSlot &&
          (_vm.$currentStep === 'xs' ||
            (_vm.$currentStep !== 'xs' && _vm.vcls.includes('left-image')))
        )?_c('div',{staticClass:"col-12 col-sm-5 col-md-4 d-sm-flex align-items-sm-center justify-content-sm-end"},[_c('div',{ref:"$left",class:[_vm.imageStylesBySide, _vm.getFadeAnimation(_vm.vcls, 'sideImage')]},[_c('ratio-size-box',{attrs:{"data-crop":"cover","data-width-ratio-component":_vm.$currentStep === 'xs' ? _vm.dataRatioWidthMobile : _vm.dataRatioWidth,"data-height-ratio-component":_vm.$currentStep === 'xs' ? _vm.dataRatioHeightMobile : _vm.dataRatioHeight,"data-vertical-align-content":"center"}},[_vm._t("image")],2)],1)]):_vm._e(),_c('div',{class:[
          'col-12 col-sm-6 d-sm-flex align-items-sm-center',
          {
            'offset-sm-1': _vm.vcls.includes('left-image'),
            'offset-md-1':
              _vm.vcls.includes('right-image') &&
              (_vm.imageRatioIsVertical || _vm.$currentStep === 'lg'),
            'padding-top-m': _vm.$currentStep === 'xs',
          } ]},[_c('div',{ref:_vm.vcls.includes('right-image') ? '$left' : '$right',class:_vm.$currentStep === 'xs'
              ? null
              : ['sideText', _vm.getFadeAnimation(_vm.vcls, 'sideText'), _vm.vcls]},[(_vm.hasTitleSlot)?_c('div',{class:['title typo-light margin-bottom-r', 'typo-w-4']},[_vm._t("title")],2):_vm._e(),(_vm.hasWysiwygSlot)?_c('div',{staticClass:"contentText typo-light typo-a-7"},[_vm._t("wysiwyg")],2):_vm._e()])]),(
          _vm.hasImageSlot &&
          _vm.$currentStep !== 'xs' &&
          _vm.vcls.includes('right-image')
        )?_c('div',{staticClass:"col-12 col-sm-5 col-md-4 offset-1 d-flex align-items-center justify-content-start"},[_c('div',{ref:"$right",class:[_vm.imageStylesBySide, _vm.getFadeAnimation(_vm.vcls, 'sideImage')]},[_c('ratio-size-box',{attrs:{"data-crop":"cover","data-width-ratio-component":_vm.$currentStep === 'xs' ? _vm.dataRatioWidthMobile : _vm.dataRatioWidth,"data-height-ratio-component":_vm.$currentStep === 'xs' ? _vm.dataRatioHeightMobile : _vm.dataRatioHeight,"data-vertical-align-content":"center"}},[_vm._t("image")],2)],1)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }