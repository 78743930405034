<template>
  <div class="WaRecipeHeroDetail">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-6">
          <figure class="ratio43 margin-bottom-0">
            <img class="image" :src="dataImgSrc" :alt="dataImgAlt" />
          </figure>
        </div>
        <div class="col-12 offset-sm-1 col-sm-5">
          <div class="small-text">
            <slot name="small_title" />
          </div>
          <div class="title margin-top-xs">
            <slot name="title_text" />
          </div>
          <div
            v-if="$root.isPowerReviewActive"
            id="pwr-snippets"
            class="margin-top-xs"
          >
            <div :id="`pr-reviewsnippet-${_uid}`"></div>
            <!-- <div :id="`pr-qasnippet-${_uid}`"></div> -->
          </div>
          <div
            class="description typo-a-6 typo-light noMBLastChild margin-top-xs"
          >
            <slot name="description_wysiwyg" />
          </div>
          <div v-if="labels" class="labels margin-bottom-s">
            <div class="labelsList margin-top-s margin-bottom-0">
              <span
                v-for="(label, i) in labels"
                :key="i"
                class="labelsList__item"
              >
                {{ label }}
              </span>
            </div>
          </div>
          <!-- social_share -->
          <div
            v-if="$_cmsSupport_hasContentSlotWithName('social-share')"
            class="social text-color-grey-8"
          >
            <slot name="social-share" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaRecipeHeroDetail',
  props: {
    dataLabelGrayOneText: {
      type: String,
      default: '',
    },
    dataLabelGrayTwoText: {
      type: String,
      default: '',
    },
    dataLabelGrayThreeText: {
      type: String,
      default: '',
    },
    dataLabelGrayFourText: {
      type: String,
      default: '',
    },
    dataImgSrc: {
      type: String,
      default: '',
    },
    dataImgAlt: {
      type: String,
      default: '',
    },
  },
  computed: {
    labels() {
      let ret = [];
      if (this.dataLabelGrayOneText) {
        ret.push(this.dataLabelGrayOneText);
      }
      if (this.dataLabelGrayTwoText) {
        ret.push(this.dataLabelGrayTwoText);
      }
      if (this.dataLabelGrayThreeText) {
        ret.push(this.dataLabelGrayThreeText);
      }
      if (this.dataLabelGrayFourText) {
        ret.push(this.dataLabelGrayFourText);
      }
      return ret;
    },
  },
  mounted() {
    if (this.$root.isPowerReviewActive) {
      this.$watch(
        () => this.$root.arePowerReviewConfsReady,
        (confsAreReady) => {
          if (confsAreReady) {
            this.addPrWidget();
          }
        },
        { immediate: true }
      );
    }
  },
  methods: {
    addPrWidget() {
      //272079137513752 	Sesam & Vollkorn
      this.$root.setWidgetsToMount([
        ...this.$root.getWidgetsToMount(),
        {
          ...this.$root.getReviewDisplayBaseConf(),
          components: {
            ReviewSnippet: `pr-reviewsnippet-${this._uid}`,
            //QuestionSnippet: `pr-qasnippet-${this._uid}`,
          },
        },
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaRecipeHeroDetail {
  .ratio43 {
    position: relative;
    padding-bottom: 75%;

    .image {
      position: absolute;
      object-fit: cover;
      object-position: 50% 50%;
      max-width: 100%;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 4px;

      @include media-breakpoint-up(sm) {
        height: auto;
      }

      @include media-breakpoint-up(lg) {
        height: 100%;
      }
    }
  }

  .small-text {
    margin-top: 32px;

    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }
  }

  .labels {
    color: $color-grey-8-75;

    .labelsList {
      display: flex;
      flex-wrap: wrap;
      line-height: 1.3;

      .labelsList__item {
        font-weight: $fh-medium;
        font-family: $font-primary;
        font-size: 16px;
        line-height: 1.3;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
          font-size: 18px;
        }

        &:nth-child(n + 2) {
          margin-left: 5px;

          &::before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background-color: $color-grey-8-75;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
