<template functional>
  <div
    :ref="data.ref"
    :class="[
      'WaPmextraNorwaySandwichCta noMBLastChild',
      data.staticClass,
      data.class,
    ]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WaPmextraNorwaySandwichCta',
  functional: true,
};
</script>

<style scoped></style>
