<template>
  <div class="ContactFormBreadcrumbCmp">
    <div
      v-for="(config, i) in breadcrumbConfig"
      :key="`breadcrumb-item-${i}`"
      class="breadcrumb-item bg-color-grey-2"
    >
      <span class="typo-a typo-a-10 text-color-blue-logo">
        {{ config.label }}
      </span>
      <router-link class="reset text-color-blue-logo" :to="config.to">
        <svg width="12" height="12">
          <use href="#icon-close" fill="currentColor" />
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactFormBreadcrumbCmp',
  computed: {
    ...mapState('contactForm2024', [
      'routes',
      'promotions',
      'countries',
      'first_level_group',
      'second_level_group',
      'third_level_group',
      'queryString',
      'faqs',
    ]),
    breadcrumbArray() {
      const array = [];
      const qs = { ...this.queryString };
      const pageOrderArray = qs.page_order ? qs.page_order.split(',') : [];

      pageOrderArray
        .filter(
          (e) =>
            e === this.routes.triage_type.name ||
            e === this.routes.triage_topic.name ||
            e === this.routes.triage_promotions.name ||
            e === this.routes.triage_faq.name ||
            e === this.routes.ticketing_country.name ||
            e === this.routes.ticketing_first_level.name ||
            e === this.routes.ticketing_second_level.name ||
            e === this.routes.ticketing_third_level.name
        )
        .forEach((page) => {
          const [key, value] = Object.entries(this.routes).find(
            ([key, value]) => value.name === page
          );

          array.push({
            route: key,
            routeName: value.name,
          });
        });

      return array;
    },
    breadcrumbConfig() {
      const configs = [];
      const qs = { ...this.queryString };

      this.breadcrumbArray.forEach((item, index) => {
        const config = {};
        const itemQs = { ...qs };

        for (let i = index; i < this.breadcrumbArray.length; i++) {
          itemQs.page_order = itemQs.page_order
            .replace(`${this.breadcrumbArray[i].routeName}`, '')
            .replace(/,[\s,]*$/, '');
          if (itemQs.page_order === '') {
            delete itemQs.page_order;
          }

          delete itemQs[this.breadcrumbArray[i].route];
        }

        if (item.route === 'triage_type') {
          config.label = this.routes?.triage_type.controls.selectionCtas.find(
            (e) => e.id === this.queryString?.triage_type
          )?.label;
        }

        if (item.route === 'triage_topic') {
          config.label = this.routes?.triage_topic.controls.selectionCtas.find(
            (e) => e.id === this.queryString?.triage_topic
          )?.label;
        }

        if (item.route === 'triage_promotions') {
          config.label = this.promotions?.find(
            (e) => e.id === this.queryString?.triage_promotions
          )?.label;
        }

        if (item.route === 'triage_faq') {
          const promo = this.promotions?.find(
            (e) => e.id === this.queryString?.triage_promotions
          )?.label;
          config.label = promo ? `${promo} Faq` : `Faq`;
        }

        if (item.route === 'ticketing_country') {
          config.label = this.countries?.items.find(
            (e) => e.value === this.queryString?.ticketing_country
          )?.label;
        }

        if (item.route === 'ticketing_first_level') {
          config.label =
            this.first_level_group
              .find((e) => e.country === this.queryString?.ticketing_country)
              ?.items.find(
                (e) => e.slug === this.queryString?.ticketing_first_level
              )?.label ||
            this.routes?.ticketing_first_level.controls.selectionCtasFrom.optionalSkipCtaItems.find(
              (e) => e.slug === this.queryString?.ticketing_first_level
            )?.label;
        }

        if (item.route === 'ticketing_second_level') {
          config.label = this.second_level_group.find(
            (e) => e.slug === this.queryString?.ticketing_second_level
          )?.label;
        }

        if (item.route === 'ticketing_third_level') {
          config.label = this.third_level_group.find(
            (e) => e.id === this.queryString?.ticketing_third_level
          )?.label;
        }

        config.to = { name: item.routeName, query: itemQs };

        configs.push(config);
      });
      //console.log('configs', configs);
      return configs;
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormBreadcrumbCmp {
  .breadcrumb-item {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    padding: 8px 8px 8px 16px;
    border-radius: 60px;

    .reset {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
    }
  }
}
</style>
