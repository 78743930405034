<template functional>
  <div
    :ref="data.ref"
    :class="['WaIrregularDivider', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <svg
      viewBox="0 0 260 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="divider"
    >
      <path
        opacity="0.75"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M260 2.03801C259.4 3.11863 258.6 2.82392 257.9 2.82392C246.901 2.62744 246.101 2.13624 235.101 2.23448C223.802 2.33272 212.602 1.93977 201.303 2.5292C197.903 2.72568 194.503 2.43096 191.103 2.5292C187.304 2.62744 183.504 2.82392 179.704 3.21687C175.904 3.70807 172.104 3.41335 168.305 3.51159C154.505 3.70807 150.905 3.60983 137.006 3.90455C129.406 4.00279 121.907 4.00279 114.307 3.51159C109.107 3.21687 103.808 3.41335 98.508 3.80631C93.3083 4.19926 88.2085 3.90455 83.1087 3.70807C78.809 3.51159 74.4092 3.41335 70.0094 3.31511C63.4097 3.11863 56.8101 4.00279 50.3104 2.62744C49.2104 2.43096 48.1105 2.72568 47.0105 2.72568C34.4112 2.23448 21.8118 2.72568 9.21241 3.0204C7.01252 3.11863 4.81263 2.82392 2.61274 2.72568C2.01277 2.72568 1.4128 2.72568 0.912826 2.23448C0.41285 1.93977 -0.0871248 1.44857 0.0128703 0.760899C0.112865 -0.0250126 0.812831 0.0732263 1.4128 0.0732263C5.61259 -0.0250127 9.81238 0.564421 13.9122 0.367943C19.3119 0.171465 24.7116 0.171465 30.1114 0.0732263C39.8109 -0.123252 49.4104 0.66266 59.1099 1.05562C78.109 1.84153 97.1081 1.54681 116.107 1.54681C141.606 1.54681 156.905 1.54681 182.404 0.760899C210.002 -0.0250126 227.202 -0.417969 254.7 0.66266C256.5 0.760899 258.5 0.466182 260 2.03801Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'WaIrregularDivider',
  functional: true,
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaIrregularDivider {
  .divider {
    width: 130px;
    height: 3px;
    display: block;
    margin: 0 auto;

    @media (min-width: $bootstrap-sm) {
      width: 195px;
      height: 3px;
    }

    @media (min-width: $bootstrap-md) {
      width: 260px;
      height: 4px;
    }
  }
}
</style>
