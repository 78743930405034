<template functional>
  <li
    :class="['WaFooterMenuItem', data.staticClass, data.class]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <a
      :href="props.dataHref"
      :target="props.dataTarget !== '' ? props.dataTarget : null"
      :rel="props.dataRel !== '' ? props.dataRel : null"
      class="navItemFooter"
    >
      <slot />
    </a>
  </li>
</template>

<script>
export default {
  name: 'WaFooterMenuItem',
  functional: true,
  props: {
    dataHref: {
      type: String,
      required: true,
    },
    dataTarget: {
      type: String,
      default: '_self',
    },
    dataRel: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaFooterMenuItem {
  font-size: inherit;
}

.navItemFooter {
  color: $color-blue-logo;
  text-decoration: none;
  display: block;
  font-weight: $fh-medium; //fh-normal

  &:active,
  &:hover {
    color: $color-blue-logo;
    text-decoration: underline;
  }
}

.bg-color-blue-logo {
  .navItemFooter {
    color: $color-white;
    text-decoration: none;

    &:active,
    &:hover {
      color: $color-white;
    }
  }
}
</style>
