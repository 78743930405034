<template functional>
  <div
    :class="['WaGridColumn', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot name="wa-grid-column-childs" />
  </div>
</template>

<script>
export default {
  name: 'WaGridColumn',
  functional: true,
};
</script>
