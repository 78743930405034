export default {
  data: [
    {
      name: 'Agrigento',
      id: '1',
      province_id: '1',
    },
    {
      name: 'Alessandria',
      id: '2',
      province_id: '2',
    },
    {
      name: 'Ancona',
      id: '3',
      province_id: '3',
    },
    {
      name: 'Aosta',
      id: '4',
      province_id: '4',
    },
    {
      name: 'Arezzo',
      id: '5',
      province_id: '5',
    },
  ],
};
