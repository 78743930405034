<template>
  <a class="WaProductCard" :href="JsonDataConf.href">
    <div class="animatedImage">
      <ratio-size-box
        data-width-ratio-component="4"
        data-height-ratio-component="3"
        data-vertical-align-content="center"
        data-crop="contain"
      >
        <img
          ref="prodPicture"
          class="cardImage riseThis"
          :src="JsonDataConf.card_src"
          :alt="JsonDataConf.card_alt"
        />
      </ratio-size-box>
    </div>
    <div class="content">
      <p class="description">
        <span
          ref="productLabel"
          :class="[
            'label',
            JsonDataConf.variant_class === 'big_title'
              ? 'typo-a-6'
              : 'typo-a-7',
          ]"
        >
          {{ JsonDataConf.label }}
        </span>
        <wa-tag
          v-if="JsonDataConf.is_new"
          class="tag text-color-white bg-color-secondary-red"
        >
          {{ $store.state.config.new_label }}
        </wa-tag>
        <wa-tag
          v-else-if="JsonDataConf.wa_tag"
          :class="[
            'tag',
            JsonDataConf.wa_tag.label_color,
            JsonDataConf.wa_tag.label_background_color,
          ]"
        >
          {{ JsonDataConf.wa_tag.label }}
        </wa-tag>
      </p>
    </div>
  </a>
</template>

<script>
export default {
  name: 'WaProductCard',
  props: {
    dataConf: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    JsonDataConf() {
      return typeof this.dataConf === 'string'
        ? JSON.parse(this.dataConf)
        : this.dataConf;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables.scss';
@import '~bootstrap/scss/mixins/breakpoints';

.WaProductCard {
  display: inline-block;
  color: inherit;
  text-align: center;
  cursor: pointer;
  width: 100%;
  text-decoration: none;

  .animatedImage {
    position: relative;
    transition: all ease 0.5s;
    top: 0;
  }

  &:hover {
    color: inherit;

    .label {
      text-decoration: underline;
    }

    .animatedImage {
      top: -10px;
    }
  }
}

.linkText {
  color: inherit;
  cursor: pointer;
}

.cardImage {
  display: block;
  margin: auto;
  position: relative;
  max-width: 100%;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.15));
}

.description {
  display: flex;
  justify-content: center;
  align-items: center;

  /deep/ .tag {
    margin: 0 0 0 8px;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 24px;
  }
}

.label {
  vertical-align: middle;
}

.content {
  margin: 8px 0 0 0;
}
</style>
