<template>
  <div
    :class="[
      'WaAlertBanner',
      {
        overlay: isOpen && $viewportWidth < 768,
      },
    ]"
  >
    <div v-if="isOpen" class="wrapper">
      <div class="top">
        <div class="iconAndLabel">
          <!-- InfoIcon -->
          <div class="infoIcon">
            <svg width="24" height="24">
              <use :href="`#${dataIdIcon}`" fill="currentColor" />
            </svg>
          </div>
          <h4 class="title margin-bottom-0 typo-a-8 typo-bold">
            {{ dataTitleText }}
          </h4>
        </div>
        <!-- CloseIcon -->
        <div class="closeIcon" @click="isOpen = false">
          <svg width="24" height="24">
            <use href="#icon-close" fill="currentColor" />
          </svg>
        </div>
      </div>
      <div class="bottom">
        <div
          v-if="$_cmsSupport_hasContentSlotWithName('wysiwyg_text')"
          class="text typo-a-8 typo-light"
        >
          <slot name="wysiwyg_text" />
        </div>
        <div v-if="$_cmsSupport_hasContentSlotWithName('cta')" class="cta">
          <slot name="cta" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaAlertBanner',
  props: {
    dataIdIcon: {
      type: String,
      default: 'icon-info-circle',
    },
    dataTitleText: {
      type: String,
      default: 'Wasa Communication',
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaAlertBanner {
  &.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba($color-grey-8, 0.75);
  }

  .wrapper {
    background: $color-white;
    padding: 24px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    position: fixed;
    width: calc(100% - 60px);
    z-index: 2;
    /* box-sizing: content-box; */
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    @include media-breakpoint-up(sm) {
      transform: none;
      width: 312px;
      right: 24px;
      bottom: 24px;
      top: auto;
      left: auto;
    }

    @include media-breakpoint-up(md) {
      width: 363px;
    }

    @include media-breakpoint-up(lg) {
      width: 411px;
    }

    .top {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 16px;

      @include media-breakpoint-up(md) {
        margin-bottom: 24px;
      }
      .iconAndLabel {
        @include media-breakpoint-up(md) {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
        }
      }

      .infoIcon {
        order: 1;
        width: 50%;

        @include media-breakpoint-up(sm) {
          width: auto;
        }
      }

      .closeIcon {
        order: 3;
        color: $color-grey-9;
        cursor: pointer;

        &:hover {
          color: $color-grey-8;
        }
      }

      .title {
        order: 3;
        margin-top: 8px;

        @include media-breakpoint-up(md) {
          margin-top: 0;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
