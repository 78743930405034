var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"$form",staticClass:"WaContactFormProblemProduct",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8 text-center padding-bottom-r"},[_c('span',[_vm._v(_vm._s(_vm.contentData.form_preserve_product_text))])])]),(_vm.formConfig && _vm.formConfig.product && _vm.formConfig.product.is_visible)?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8 text-center padding-bottom-r"},[_c('wa-select',{staticClass:"medium text-color-blue-logo",attrs:{"data-label":_vm.dataLabel('product', _vm.contentData.form_select_product_placeholder),"data-show-label":false,"data-name":"product","data-placeholder":_vm.dataLabel('product', _vm.contentData.form_select_product_placeholder),"data-option-api-url":_vm.selectsApi['products-api'],"data-error-occurred":_vm.$v.product.$error,"data-errors":_vm.dataErrors('product', {
            required: _vm.contentData.form_select_product_error_required,
          })},on:{"change":function($event){return _vm.onFieldChange('product', $event)}},model:{value:(_vm.$v.product.$model),callback:function ($$v) {_vm.$set(_vm.$v.product, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.product.$model"}},[_c('template',{slot:"icon"},[_c('svg',{attrs:{"width":"24","height":"24"}},[_c('use',{attrs:{"href":"#icon-arrow-angle-down","fill":"currentColor"}})])])],2)],1)]):_vm._e(),(_vm.formConfig && _vm.formConfig.picture && _vm.formConfig.picture.is_visible)?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8 padding-bottom-r"},[_c('wa-upload',{ref:"$waUpload",attrs:{"name":"picture","label":_vm.dataLabel('picture', _vm.contentData.form_input_file_picture_label),"label-smaller":_vm.dataLabel(
            'picture',
            _vm.contentData.form_input_file_picture_label_small
          ),"label-on-hover":_vm.dataLabel('picture', _vm.contentData.form_input_file_picture_hover),"cta-text":_vm.contentData.form_input_file_picture_cta,"helper-text":_vm.contentData.form_input_file_picture_helper,"helper-text-smaller":_vm.contentData.form_input_file_picture_helper_small,"data-error-occurred":_vm.$v.picture.$error,"errors":_vm.dataErrors('picture', {}, [
            {
              condition: !_vm.$v.picture.mustBeImage,
              message: _vm.contentData.form_input_file_picture_error_format,
            },
            {
              condition: !_vm.$v.picture.validSize,
              message: _vm.contentData.form_input_file_picture_error_size,
            } ])},on:{"change":function($event){return _vm.onFieldChange('picture', $event)}},model:{value:(_vm.$v.picture.$model),callback:function ($$v) {_vm.$set(_vm.$v.picture, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.picture.$model"}})],1)]):_vm._e(),(
      _vm.formConfig &&
      _vm.formConfig.description &&
      _vm.formConfig.description.is_visible
    )?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8 padding-bottom-r"},[_c('wa-textarea',{attrs:{"data-name":"description","data-label":_vm.dataLabel(
            'description',
            _vm.contentData.form_textarea_description_label
          ),"data-error-occurred":_vm.$v.description.$error,"data-errors":_vm.dataErrors(
            'description',
            {
              required: _vm.contentData.form_textarea_description_error_required,
            },
            [
              {
                condition: !_vm.$v.description.maxLength,
                message:
                  _vm.contentData.form_textarea_description_error_max_length,
              } ]
          )},on:{"input":function($event){return _vm.onFieldChange('description', $event)}},model:{value:(_vm.$v.description.$model),callback:function ($$v) {_vm.$set(_vm.$v.description, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.description.$model"}})],1)]):_vm._e(),_c('div',{staticClass:"row justify-content-center no-gutters"},[_c('div',{staticClass:"col-12 col-md-8"},[(_vm.contentData.step_three_package_data_label !== '')?_c('div',{staticClass:"typo-a typo-a-9 typo-extra-bold margin-bottom-s text-color-secondary-item"},[_vm._v(" "+_vm._s(_vm.contentData.step_three_package_data_label)+" ")]):_vm._e(),_c('div',{staticClass:"row"},[(
            _vm.formConfig && _vm.formConfig.barcode && _vm.formConfig.barcode.is_visible
          )?_c('div',{staticClass:"col-12 col-md-6 padding-bottom-r",class:_vm.barcodeTooltipImg ? ['d-flex align-items-center'] : null},[(_vm.barcodeTooltipImg)?_c('svg',{directives:[{name:"tooltip",rawName:"v-tooltip.auto",value:({
              content: _vm.barcodeTooltipImg,
              autoHide: true,
              hideOnTargetClick: true,
              trigger: 'hover click',
            }),expression:"{\n              content: barcodeTooltipImg,\n              autoHide: true,\n              hideOnTargetClick: true,\n              trigger: 'hover click',\n            }",modifiers:{"auto":true}}],staticClass:"info"},[_c('use',{attrs:{"href":"#icon-info-circle","fill":"currentColor"}})]):_vm._e(),_c('wa-input',{attrs:{"data-name":"barcode","data-label":_vm.dataLabel('barcode', _vm.contentData.form_input_text_barcode_label),"data-error-occurred":_vm.$v.barcode.$error,"data-errors":_vm.dataErrors('barcode', {})},on:{"input":function($event){return _vm.onFieldChange('barcode', $event)}},model:{value:(_vm.$v.barcode.$model),callback:function ($$v) {_vm.$set(_vm.$v.barcode, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.barcode.$model"}})],1):_vm._e(),(_vm.formConfig && _vm.formConfig.batch && _vm.formConfig.batch.is_visible)?_c('div',{staticClass:"col-12 col-md-6 padding-bottom-r",class:_vm.batchTooltipImg ? ['d-flex align-items-center'] : null},[(_vm.batchTooltipImg)?_c('svg',{directives:[{name:"tooltip",rawName:"v-tooltip.auto",value:({
              content: _vm.batchTooltipImg,
              autoHide: true,
              hideOnTargetClick: true,
              trigger: 'hover click',
            }),expression:"{\n              content: batchTooltipImg,\n              autoHide: true,\n              hideOnTargetClick: true,\n              trigger: 'hover click',\n            }",modifiers:{"auto":true}}],staticClass:"info"},[_c('use',{attrs:{"href":"#icon-info-circle","fill":"currentColor"}})]):_vm._e(),_c('wa-input',{attrs:{"data-name":"batch","data-label":_vm.dataLabel('batch', _vm.contentData.form_input_text_batch_label),"data-error-occurred":_vm.$v.batch.$error,"data-errors":_vm.dataErrors('batch', {})},on:{"input":function($event){return _vm.onFieldChange('batch', $event)}},model:{value:(_vm.$v.batch.$model),callback:function ($$v) {_vm.$set(_vm.$v.batch, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.batch.$model"}})],1):_vm._e(),(
            _vm.formConfig &&
            _vm.formConfig.bestbefore &&
            _vm.formConfig.bestbefore.is_visible
          )?_c('div',{staticClass:"col-12 col-md-6 padding-bottom-r",class:_vm.bestbeforeTooltipImg ? ['d-flex align-items-center'] : null},[(_vm.bestbeforeTooltipImg)?_c('svg',{directives:[{name:"tooltip",rawName:"v-tooltip.auto",value:({
              content: _vm.bestbeforeTooltipImg,
              autoHide: true,
              hideOnTargetClick: true,
              trigger: 'hover click',
            }),expression:"{\n              content: bestbeforeTooltipImg,\n              autoHide: true,\n              hideOnTargetClick: true,\n              trigger: 'hover click',\n            }",modifiers:{"auto":true}}],staticClass:"info"},[_c('use',{attrs:{"href":"#icon-info-circle","fill":"currentColor"}})]):_vm._e(),_c('div',{staticClass:"datepicker-wrapper"},[_c('datetime',{attrs:{"phrases":{
                ok: _vm.contentData.date_picker_ok_label,
                cancel: _vm.contentData.date_picker_cancel_label,
              }},on:{"input":_vm.datePickerInput,"mouseenter":function($event){return _vm.toggleHoverBestbeforeInput()},"mouseleave":function($event){return _vm.toggleHoverBestbeforeInput()}},model:{value:(_vm.$v.bestbefore.$model),callback:function ($$v) {_vm.$set(_vm.$v.bestbefore, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.bestbefore.$model"}}),_c('wa-input',{attrs:{"data-name":"bestbefore","data-label":_vm.dataLabel(
                  'bestbefore',
                  _vm.contentData.form_input_text_bestbefore_label
                ),"data-mask":_vm.maskByFormat,"data-error-occurred":_vm.$v.bestbefore.$error,"data-errors":_vm.dataErrors('bestbefore', {}, [
                  {
                    condition: !_vm.$v.bestbefore.validDate,
                    message: _vm.contentData.form_date_expired_error_valid,
                  } ])},on:{"input":_vm.onBestBeforeChange},model:{value:(_vm.$v.bestbefore.$model),callback:function ($$v) {_vm.$set(_vm.$v.bestbefore, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.bestbefore.$model"}})],1)]):_vm._e()])])]),_c('div',{staticClass:"row justify-content-center no-gutters"},[_c('div',{staticClass:"col-12 col-md-8"},[(_vm.contentData.step_three_store_info_label !== '')?_c('div',{staticClass:"typo-a typo-a-9 typo-extra-bold margin-bottom-s text-color-secondary-item"},[_vm._v(" "+_vm._s(_vm.contentData.step_three_store_info_label)+" ")]):_vm._e(),_c('div',{staticClass:"row"},[(
            _vm.formConfig &&
            _vm.formConfig.shopCountry &&
            _vm.formConfig.shopCountry.is_visible
          )?_c('div',{staticClass:"col-12 col-md-6 padding-bottom-r"},[_c('wa-dropdown',{attrs:{"data-name":"shopCountry","data-label":_vm.dataLabel('shopCountry', _vm.contentData.form_select_country_label),"data-placeholder":_vm.dataLabel(
                'shopCountry',
                _vm.contentData.form_select_country_placeholder
              ),"data-option-list":_vm.countryList,"data-error-occurred":_vm.$v.shopCountry.$error,"data-errors":_vm.dataErrors('shopCountry', {
                required: _vm.contentData.form_select_country_error_required,
              })},on:{"change":function($event){return _vm.onFieldChange('shopCountry', $event)}},model:{value:(_vm.$v.shopCountry.$model),callback:function ($$v) {_vm.$set(_vm.$v.shopCountry, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.shopCountry.$model"}})],1):_vm._e(),(
            _vm.formConfig &&
            _vm.formConfig.shopProvince &&
            _vm.formConfig.shopProvince.is_visible
          )?_c('div',{staticClass:"col-12 col-md-6 padding-bottom-r"},[_c('wa-input',{attrs:{"data-name":"shopProvince","data-label":_vm.dataLabel(
                'shopProvince',
                _vm.contentData.form_input_text_province_label
              ),"data-error-occurred":_vm.$v.shopProvince.$error,"data-errors":_vm.dataErrors(
                'shopProvince',
                { regex: _vm.contentData.form_input_text_province_error_regex },
                [
                  {
                    condition: !_vm.$v.shopProvince.minLength,
                    message:
                      _vm.contentData.form_input_text_province_error_minLength,
                  },
                  {
                    condition: !_vm.$v.shopProvince.maxLength,
                    message:
                      _vm.contentData.form_input_text_province_error_maxLength,
                  } ]
              )},on:{"input":function($event){return _vm.onFieldChange('shopProvince', $event)}},model:{value:(_vm.$v.shopProvince.$model),callback:function ($$v) {_vm.$set(_vm.$v.shopProvince, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.shopProvince.$model"}})],1):_vm._e(),(
            _vm.formConfig &&
            _vm.formConfig.shopCity &&
            _vm.formConfig.shopCity.is_visible
          )?_c('div',{staticClass:"col-12 col-md-6 padding-bottom-r"},[_c('wa-input',{attrs:{"data-name":"shopCity","data-label":_vm.dataLabel('shopCity', _vm.contentData.form_input_text_city_label),"data-error-occurred":_vm.$v.shopCity.$error,"data-errors":_vm.dataErrors(
                'shopCity',
                { regex: _vm.contentData.form_input_text_city_error_regex },
                [
                  {
                    condition: !_vm.$v.shopCity.minLength,
                    message: _vm.contentData.form_input_text_city_error_minLength,
                  },
                  {
                    condition: !_vm.$v.shopCity.maxLength,
                    message: _vm.contentData.form_input_text_city_error_maxLength,
                  } ]
              )},on:{"input":function($event){return _vm.onFieldChange('shopCity', $event)}},model:{value:(_vm.$v.shopCity.$model),callback:function ($$v) {_vm.$set(_vm.$v.shopCity, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.shopCity.$model"}})],1):_vm._e(),(
            _vm.formConfig && _vm.formConfig.shopCap && _vm.formConfig.shopCap.is_visible
          )?_c('div',{staticClass:"col-12 col-md-6 padding-bottom-r"},[_c('wa-input',{attrs:{"data-name":"shopCap","data-label":_vm.dataLabel('shopCap', _vm.contentData.form_input_text_cap_label),"data-error-occurred":_vm.$v.shopCap.$error,"data-errors":_vm.dataErrors('shopCap', {
                regex: _vm.contentData.form_input_text_cap_error_regex,
              })},on:{"input":function($event){return _vm.onFieldChange('shopCap', $event)}},model:{value:(_vm.$v.shopCap.$model),callback:function ($$v) {_vm.$set(_vm.$v.shopCap, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.shopCap.$model"}})],1):_vm._e(),(
            _vm.formConfig &&
            _vm.formConfig.shopAddress &&
            _vm.formConfig.shopAddress.is_visible
          )?_c('div',{staticClass:"col-12 col-md-6"},[_c('wa-input',{attrs:{"data-name":"shopAddress","data-label":_vm.dataLabel(
                'shopAddress',
                _vm.contentData.form_input_text_address_label
              ),"data-error-occurred":_vm.$v.shopAddress.$error,"data-errors":_vm.dataErrors(
                'shopAddress',
                { regex: _vm.contentData.form_input_text_address_error_regex },
                [
                  {
                    condition: !_vm.$v.shopAddress.minLength,
                    message:
                      _vm.contentData.form_input_text_address_error_minLength,
                  },
                  {
                    condition: !_vm.$v.shopAddress.maxLength,
                    message:
                      _vm.contentData.form_input_text_address_error_maxLength,
                  } ]
              )},on:{"input":function($event){return _vm.onFieldChange('shopAddress', $event)}},model:{value:(_vm.$v.shopAddress.$model),callback:function ($$v) {_vm.$set(_vm.$v.shopAddress, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.shopAddress.$model"}})],1):_vm._e()])])]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8 padding-top-xs padding-bottom-r"},[_c('span',{staticClass:"typo-a-9 typo-light text-color-grey-8"},[_vm._v(" "+_vm._s(_vm.contentData.mandatory_field_indication_placeholder)+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('wa-cta',{staticClass:"primary",attrs:{"data-type":"submit","data-disabled":_vm.disableSubmit},on:{"click":function($event){$event.preventDefault();return _vm.onSubmitBtnClick($event)}}},[_vm._v(" "+_vm._s(_vm.contentData.continue_cta_label)+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }