import { render, staticRenderFns } from "./wa-recipe-hero-detail.vue?vue&type=template&id=028859d6&scoped=true&"
import script from "./wa-recipe-hero-detail.vue?vue&type=script&lang=js&"
export * from "./wa-recipe-hero-detail.vue?vue&type=script&lang=js&"
import style0 from "./wa-recipe-hero-detail.vue?vue&type=style&index=0&id=028859d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "028859d6",
  null
  
)

export default component.exports