<template>
  <div class="WaGoogleAddressAutocomplete">
    <slot v-if="!!dataLabel" name="label">
      <label
        id="label"
        :for="dataId"
        :class="['label', { labelFocus: dataProva }]"
        v-html="dataLabel"
      />
    </slot>

    <div
      v-if="!dataUseGoogleWidget"
      class="field-wrapper"
      @keydown="onKeydown"
      @keyup="onKeyup"
    >
      <slot name="field">
        <input
          :id="dataId"
          ref="inputField"
          v-model="query"
          :class="[
            'input typo-a-9 ',
            { inputFocus: dataProva, error: dataErrorOccurred },
          ]"
          type="text"
          :name="dataName"
          :placeholder="dataPlaceholder"
          autocomplete="off"
          @keydown="inputPlace"
          @input="onInputChange"
          @blur="onBlur"
          @focus="onFocus"
        />

        <svg v-if="noKeyDown" class="icon-search" width="24" height="24">
          <use href="#icon-search" fill="currentColor" />
        </svg>

        <svg
          v-if="keyDown && !dataErrorOccurred"
          class="icon-close"
          width="24"
          height="24"
          @click="resetInput"
        >
          <use href="#icon-close" fill="currentColor" />
        </svg>
        <div v-if="dataErrorOccurred" class="form__error">
          <svg width="20" height="20">
            <use href="#icon-triangle-error" fill="currentColor" />
          </svg>

          <span
            v-for="(error, i) in hasErrors"
            :key="`error-${i}`"
            class="form__error--text"
            v-html="error.message"
          />
        </div>
      </slot>
    </div>

    <div v-else class="field-wrapper">
      <slot name="field">
        <input
          v-if="dataUseGoogleWidget"
          :id="dataId"
          ref="inputField"
          type="text"
          :name="dataName"
          :placeholder="dataPlaceholder"
          autocomplete="off"
          @keydown.enter.prevent
        />
      </slot>
    </div>
    <slot :suggestions="suggestions" name="suggestions">
      <ul v-if="suggestions.length > 0 && showSuggestions" class="suggestions">
        <li v-for="s in suggestions" :key="s.dataId">
          <a href="#" @click.prevent="doSelect(s)">
            <svg class="icon-location" width="24" height="24">
              <use href="#icon-location" fill="currentColor" />
            </svg>
            <span v-html="s.description" />
          </a>
        </li>
      </ul>
    </slot>

    <slot name="messages">
      <div class="errors" v-html="errorMessage" />
    </slot>
  </div>
</template>

<script>
const KEYCODE = {
  ESC: 27,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ENTER: 13,
  SPACE: 32,
  TAB: 9,
};

const CALLBACK_NAME = 'initMap';
let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;
// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});
const gmapsInit = (key) => {
  // If Google Maps already is initialized
  // the `initPromise` should get resolved
  // eventually.
  if (initialized) return initPromise;

  initialized = true;
  // The callback function is called by
  // the Google Maps script if it is
  // successfully loaded.
  window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

  // We inject a new script tag into
  // the `<head>` of our HTML to load
  // the Google Maps script.
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=${CALLBACK_NAME}`;
  script.onerror = rejectInitPromise;
  document.querySelector('head').appendChild(script);

  return initPromise;
};

export default {
  name: 'WaGoogleAddressAutocomplete',
  props: {
    dataApiKey: {
      type: String,
      default: '',
    },
    dataDebounceTime: {
      type: Number,
      default: 1000,
    },
    dataUseGoogleWidget: {
      type: Boolean,
      default: false,
    },
    dataId: {
      type: String,
      default: 'place',
    },
    dataLabel: {
      type: String,
      default: null,
    },
    dataName: {
      type: String,
      default: 'place',
    },
    dataPlaceholder: {
      type: String,
      //default: 'Inserisci un indirizzo'
      default: null,
    },
    dataErrorOccurred: {
      type: Boolean,
      default: null,
    },
    dataErrors: {
      type: Array,
      default: null,
    },
    errorMess: {
      type: String,
      default: 'Error Message or Informative text',
    },
    dataGoogleWidgetOptions: {
      type: Object,
      default: () => {
        return {
          componentRestrictions: {
            country: 'it',
          },
        };
      },
    },
  },
  data() {
    return {
      google: undefined,
      geocoder: null,
      service: null,
      query: null,
      isTyping: false,
      timeout: null,
      readonly: null,
      suggestions: [],
      errorMessage: null,
      showSuggestions: false,
      keyDown: false,
      noKeyDown: true,
      dataProva: false,
    };
  },
  computed: {
    hasErrors() {
      if (this.dataErrorOccurred) {
        return this.dataErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
    randomString: () =>
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5),
  },
  async mounted() {
    try {
      this.google = await gmapsInit(this.dataApiKey);
      this.service = await this.createService();
      if (this.dataUseGoogleWidget) {
        this.selectFirstOnEnter(this.getInputElement());
        this.service.setFields([
          'address_components',
          'geometry',
          'icon',
          'name',
        ]);
        this.service.addListener('place_changed', () => {
          this.onPlaceChanged();
        });
      } else {
        this.geocoder = new window.google.maps.Geocoder();
      }
    } catch (error) {
      //console.log(error);
      this.errorMessage = 'Si è verificato un errore.';
    }
  },
  methods: {
    inputPlace() {
      this.dataProva = true;
      this.noKeyDown = false;
      this.keyDown = true;
    },
    resetInput() {
      this.noKeyDown = true;
      this.keyDown = false;
      this.dataProva = false;
      this.query = null;
    },
    onPlaceChanged() {
      const place = this.service.getPlace();
      this.$emit('retrieve-address-component', place);
    },
    doSelect(s) {
      this.select(s);
    },
    getAutocompletionRequestOptions() {
      return {
        input: this.getInputElement().value,
      };
    },
    async doSearch() {
      this.search()
        .then((response) => {
          this.showSuggestions = true;
          this.suggestions = response.map((s) => {
            const text = this.getInputElement().value.toLowerCase();
            var index = s.description.toLowerCase().indexOf(text);
            if (index >= 0) {
              s.description =
                s.description.substring(0, index) +
                '<b>' +
                s.description.substring(index, index + text.length) +
                '</b>' +
                s.description.substring(index + text.length);
            }

            return s;
          });

          //console.log('this.suggestions',this.suggestions)
        })
        .catch((err) => {
          this.suggestions = [];
          this.showSuggestions = false;
          //console.log(err);
        });
    },
    async search() {
      return new Promise((resolve, reject) => {
        if (!this.getInputElement().value) {
          this.$emit('retrieve-address-component', null);
          reject(new Error('Input empty'));
        } else {
          // this.showActivityIndicator = true;
          this.service.getPlacePredictions(
            this.getAutocompletionRequestOptions(),
            (response, status) => {
              // this.showActivityIndicator = false;
              //console.log(status);
              switch (status) {
                case window.google.maps.places.PlacesServiceStatus.OK:
                  resolve(response);
                  break;
                default:
                  reject(new Error(`Error with status: ${status}`));
              }
            }
          );
        }
      });
    },
    getInputElement() {
      return this.$refs.inputField;
    },
    onInputChange(e) {
      if (!this.isTyping) this.isTyping = true;

      clearTimeout(this.timeout);

      if (this.isTyping) {
        this.timeout = setTimeout(() => {
          this.isTyping = false;
          this.doSearch();
        }, this.dataDebounceTime);
      }
      this.$emit('input', e.target.value);
    },
    selectFirstOnEnter(input) {
      // store the original event binding function
      const _addEventListener = input.addEventListener
        ? input.addEventListener
        : input.attachEvent;

      // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected, and then trigger the original listener.
      const addEventListenerWrapper = (type, listener) => {
        if (type === 'focus') {
          const orig_listener = listener;
          listener = (event) => {
            this.errorMessage = null;
            orig_listener.apply(input, [event]);
          };
        }
        if (type === 'blur') {
          const orig_listener = listener;
          listener = (event) => {
            const suggestion_showed =
              this.$el.querySelectorAll('.pac-container > .pac-item').length >
              0;
            const suggestion_selected =
              this.$el.querySelectorAll('.pac-item-selected').length > 0;
            if (suggestion_showed && !suggestion_selected && !this.selected) {
              this.errorMessage = 'Seleziona un indirizzo dalla lista.';
            }
            orig_listener.apply(input, [event]);
          };
        }
        if (type === 'keydown') {
          const orig_listener = listener;
          listener = (event) => {
            const suggestion_selected =
              this.$el.querySelectorAll('.pac-item-selected').length > 0;
            if (event.which === 13 && !suggestion_selected) {
              const simulated_downarrow = new Event('keydown', {
                keyCode: 40,
                which: 40,
              });
              orig_listener.apply(input, [simulated_downarrow]);
            }
            orig_listener.apply(input, [event]);
          };
        }
        // add the modified listener
        _addEventListener.apply(input, [type, listener]);
      };

      if (input.addEventListener) {
        input.addEventListener = addEventListenerWrapper;
      } else if (input.attachEvent) {
        input.attachEvent = addEventListenerWrapper;
      }
    },
    onUndo() {
      this.$emit('retrieve-address-component', null);
      this.readonly = null;
      this.selected = false;
      this.streetNumberInput = false;
      this.form._streetNumber = '';
    },
    async createService() {
      return this.dataUseGoogleWidget
        ? new this.google.maps.places.Autocomplete(
            this.getInputElement(),
            this.dataGoogleWidgetOptions
          )
        : new this.google.maps.places.AutocompleteService();
    },
    onKeydown(event) {
      if (!this.dataUseGoogleWidget) {
        const element = this.$el.querySelector('[tabindex]');

        if (element && event.keyCode === KEYCODE.TAB) {
          event.preventDefault() && element.focus();
        }
      }
    },
    onKeyup(event) {
      if (!this.dataUseGoogleWidget) {
        switch (event.keyCode) {
          case KEYCODE.ENTER:
            // case KEYCODE.SPACE:
            if (this.$el.querySelector('a.focus')) {
              this.$el
                .querySelector('a.focus')
                .dispatchEvent(new Event('click'));
            }
            return;
          case KEYCODE.ESC:
            this.hide();
            this.getInputElement().blur();
            return;
          case KEYCODE.UP:
            this.up();
            event.preventDefault();
            return;
          case KEYCODE.DOWN:
            this.down();
            event.preventDefault();
            return;
        }

        // this.search().then(response => {
        //     this.suggestions = response;
        //     this.showSuggestions = true;
        // }, error => {
        //     if (error) {
        //         this.suggestions = [];
        //     }
        // });
      }
    },
    up() {
      const focused = this.$el.querySelector('a.focus');

      if (this.suggestions.length > 0) {
        if (focused) {
          focused.classList.remove('focus');
        }

        if (focused && focused.parentElement.previousElementSibling) {
          focused.parentElement.previousElementSibling
            .querySelector('a')
            .classList.add('focus');
        } else {
          const links = this.$el.querySelectorAll('a');
          links[links.length - 1].classList.add('focus');
        }
      }
    },
    down() {
      const focused = this.$el.querySelector('a.focus');

      if (this.suggestions.length > 0) {
        if (focused) {
          focused.classList.remove('focus');
        }
        if (focused && focused.parentElement.nextElementSibling) {
          focused.parentElement.nextElementSibling
            .querySelector('a')
            .classList.add('focus');
        } else {
          this.$el.querySelector('a').classList.add('focus');
        }
      }
    },
    onFocus(event) {
      if (this.query) {
        if (!this.suggestions.length) {
          this.onKeyup(event);
        }

        this.show();
      }
    },
    onBlur(event) {
      if (!this.$el.contains(event.relatedTarget)) {
        this.hide();
      }
    },
    onItemBlur(event) {
      this.onBlur(event);
    },
    onItemClick(event, child) {
      this.select(child.item);
      this.suggestions = [];
    },
    hide() {
      this.showSuggestions = false;
    },
    show() {
      this.showSuggestions = true;
    },
    select(place) {
      this.geocode({ placeId: place.place_id }).then((response) => {
        //this.hide();
        this.$emit('retrieve-address-component', response[0], place);
        this.query = response[0].formatted_address;
        this.showSuggestions = false;
      });
    },
    geocode(options) {
      const geocoder = this.geocoder;
      return new Promise((resolve, reject) => {
        if (!options.geometry) {
          geocoder.geocode(options, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              resolve(results);
            } else {
              reject(status);
            }
          });
        } else {
          resolve([options]);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaGoogleAddressAutocomplete {
  position: relative;
  width: 100%;
}

//custom input rules NDV
.WaGoogleAddressAutocomplete {
  .input {
    border: 1px solid $color-grey-6;
    border-radius: 4px;
    //padding: 14px 0 14px 16px;
    padding-left: 16px;
    height: 52px;
    line-height: 52px;
    width: 100%;
    color: $color-black;
    text-overflow: ellipsis;

    &:focus {
      border: 1px solid $color-grey-9;
    }

    &:hover {
      cursor: pointer;
      border-color: $color-blue-logo;
    }

    ::placeholder {
      font-size: 16px;
    }
  }

  .inputFocus {
    padding: 25px 28px 3px 16px;
  }

  .input,
  .label {
    font: $font-primary, $font-system;
    font-weight: $fh-light;
    font-size: 16px;
  }

  .label {
    position: absolute;
    top: 52%;
    transform: translateY(-50%);
    z-index: 1;
    padding-left: 16px;
    margin-bottom: 0;
    color: $color-grey-6;
  }

  .labelFocus {
    font-size: 13px;
    top: 8px;
    transform: none;
  }
}

.field-wrapper {
  position: relative;
}

.icon-search {
  color: $color-grey-4;
  right: 14px;
}

.icon-close {
  color: $color-black;
  right: 20px;
}

.icon-search,
.icon-close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.icon-location {
  margin-right: 14px;
  color: $color-grey-6;
}

//custom ul
.WaGoogleAddressAutocomplete .suggestions {
  z-index: 100;
  list-style: none;
  //margin:0;
  margin-top: 12px;
  //padding: 0;
  padding: 8px;
  position: absolute;
  top: 100%;
  background-color: white;
  width: 100%;
  border: 1px solid inherit;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.WaGoogleAddressAutocomplete .suggestions li {
  list-style: none;
  font-size: 16px;
  //border: 1px solid #000;
  border-top: none;
  font: $font-primary, $font-system;
}

.WaGoogleAddressAutocomplete .suggestions li a {
  display: block;
  padding: 10px 0 10px 12px;
  color: #000;
  /deep/ b {
    font-weight: 600;
  }
}

/*.WaGoogleAddressAutocomplete .suggestions li a:link,
  .WaGoogleAddressAutocomplete .suggestions li a:visited,
  .WaGoogleAddressAutocomplete .suggestions li a:hover,
  .WaGoogleAddressAutocomplete .suggestions li a:active {
    color: #000;
    text-decoration: none;
  }*/

.WaGoogleAddressAutocomplete .suggestions li a:hover {
  color: $color-blue-logo;
}

.WaGoogleAddressAutocomplete .suggestions li a.focus {
  color: $color-blue-logo;
  background-color: $color-grey-2;
}

.WaGoogleAddressAutocomplete .suggestions li a.focus svg {
  color: $color-blue-logo;
}

.WaGoogleAddressAutocomplete .errors {
  color: #ff0000;
}

.form__error {
  color: $color-informative-red;

  svg {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .form__error--text {
    display: block;
    font-size: 13px;
    font-weight: $fh-medium;
    margin-top: 8px;

    &:last-child {
      padding-bottom: 7px;
    }
  }
}
</style>
