<template>
  <div class="WaHeaderWrapper" :style="isSticky ? { height: spacer } : null">
    <header
      ref="$header"
      :class="[
        'WaHeader',
        'typo-a-9',
        theme,
        {
          'sticky': isSticky,
          'sticky-visible': isSticky && isStickyVisible,
          'h-100':
            isSticky &&
            $viewportWidth < 1024 &&
            (showUserDropdown || showMobileMenu),
          'mobileMenuVisible': showMobileMenu,
        },
      ]"
    >
      <div class="wrapper h-100">
        <div class="container-fluid">
          <div class="row justify-content-between desktop-menu">
            <div class="col-6 col-md-2">
              <a :href="home_page_url" class="brand">
                <svg>
                  <use :href="loadProperLogo" />
                </svg>
              </a>
            </div>
            <div class="col-6 col-md-2">
              <div class="icons">
                <svg
                  v-if="$viewportWidth >= 768"
                  width="24"
                  height="24"
                  class="language-icon"
                  @click="onShowModal"
                >
                  <use href="#icon-world" fill="currentColor" />
                </svg>
                <span v-if="$viewportWidth >= 768" class="language-text">
                  {{ siteCodeForHeader }}
                  <span
                    v-if="$viewportWidth >= 768 && showLogin && loaded"
                    class="separator"
                  ></span>
                </span>

                <template v-if="showLogin && loaded">
                  <svg
                    v-if="checkLogged"
                    class="user-icon"
                    width="24"
                    height="24"
                    @click.prevent="goToLogin"
                  >
                    <use href="#icon-user" fill="currentColor" />
                  </svg>
                  <div v-else class="user-dropdown-container">
                    <img
                      v-if="user.thumbnailURL"
                      :class="['user-img', { active: showUserDropdown }]"
                      :src="user.thumbnailURL"
                      @click="onToggleUserDropdown(!showUserDropdown)"
                    />
                    <div
                      v-else
                      :class="[
                        'user-img d-flex align-items-center justify-content-center bg-color-blue-logo text-color-white',
                        { active: showUserDropdown },
                      ]"
                      @click="onToggleUserDropdown(!showUserDropdown)"
                    >
                      <svg width="24" height="24">
                        <use href="#icon-user" fill="currentColor" />
                      </svg>
                    </div>
                    <transition name="user-dropdown-wrapper">
                      <div
                        v-if="showUserDropdown"
                        class="typo-a-8 user-dropdown-wrapper"
                        @click.self="
                          $viewportWidth < 1024
                            ? onToggleUserDropdown(false)
                            : null
                        "
                      >
                        <div class="user-dropdown">
                          <div class="content">
                            <div class="head">
                              <span v-if="user.firstName && user.lastName">
                                {{ user.firstName }} {{ user.lastName }}
                              </span>
                              <span v-else>
                                {{ user.email }}
                              </span>
                            </div>
                            <div class="control-panel">
                              <ul>
                                <li>
                                  <a :href="dataGigyaProfileUrl">
                                    {{ $store.state.gigya.edit_profile_label }}
                                  </a>
                                </li>
                                <li>
                                  <a href="#" @click.prevent="onLogout">
                                    {{ $store.state.gigya.logout_label }}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                </template>
                <svg
                  v-if="$viewportWidth < 1024"
                  class="hamburger-icon"
                  width="24"
                  height="24"
                  @click="onToggleMobileMenu(true)"
                >
                  <use href="#icon-hamburger-menu" fill="currentColor" />
                </svg>
              </div>
            </div>
            <div v-if="$viewportWidth >= 1024" class="overlap">
              <nav class="menu">
                <slot name="header-menu-items" />
              </nav>
            </div>
          </div>
          <transition name="mobile-menu">
            <div
              v-if="showMobileMenu"
              ref="$mobileMenu"
              class="row no-gutters justify-content-end mobile-menu"
              @click.self="onToggleMobileMenu(false)"
            >
              <div class="col-12 col-sm-5 right-side">
                <div class="flexWrapper">
                  <div>
                    <div class="head">
                      <svg
                        class="close-icon"
                        width="24"
                        height="24"
                        @click="onToggleMobileMenu(false)"
                      >
                        <use href="#icon-close" fill="currentColor" />
                      </svg>
                    </div>
                    <nav class="menu typo-a-5">
                      <slot name="header-menu-items" />
                    </nav>
                  </div>
                  <div
                    v-if="$viewportWidth < 768"
                    class="language-chooser"
                    @click="onShowModal"
                  >
                    <span class="typo-a-6">
                      {{ currentCountry }} ({{ currentLang }})
                    </span>
                    <svg class="language-icon" width="24" height="24">
                      <use href="#icon-world" fill="currentColor" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </header>
    <wa-modal
      :data-has-inner-scroll="true"
      :data-has-back-button="showMobileMenu && $viewportWidth < 768"
    >
      <div class="modal-content">
        <span class="title typo-w typo-w-4 text-color-secondary-item typo-bold">
          {{ $store.state.config.language_chooser_label }}
        </span>
        <div class="row">
          <div
            v-for="(region, i) in orderedLanguages"
            :key="`region-${i}-${_uid}`"
            class="col-6 col-sm-4"
          >
            <a
              :href="region.href"
              :class="[
                'language-item d-flex flex-column align-items-start justify-content-center',
                { active: region.site_code === region.language_code },
              ]"
            >
              <span class="typo-a-8 typo-medium">
                {{ region.country_label }}
              </span>
              <span class="typo-a-10 typo-light">
                {{ region.tongue_label }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </wa-modal>
  </div>
</template>

<script>
import LanguageSupport from '@mixins/language-support';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'WaHeader',
  mixins: [LanguageSupport],
  props: {
    dataShowLogin: {
      type: [Boolean, String],
      default: false,
    },
    dataGigyaLoginUrl: {
      type: String,
      default: '',
    },
    dataGigyaProfileUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentOverlappingHeight: '58px', //'84px' //'142px' //'178px'
      isSticky: false,
      isStickyVisible: false,
      showUserDropdown: false,
      showMobileMenu: false,
      spacer: 'auto',
    };
  },
  computed: {
    ...mapState('gigya', ['user', 'loaded']),
    ...mapState('config', ['home_page_url']),
    ...mapGetters('config', ['orderedLanguages']),
    showLogin() {
      return this.$_cmsSupport_castPropToType(this.dataShowLogin, 'boolean');
    },
    theme() {
      return this.$store.state.config.inverted_header ? 'dark' : 'true';
    },
    checkLogged() {
      return !this.user;
    },
    loadProperLogo() {
      let logo = '#brand-wasa';

      if (this.isSticky || this.$viewportWidth < 1024) {
        logo += '-xs';
      }

      if (this.theme === 'dark') {
        logo += '-stroke';
      }

      return logo;
    },
  },
  watch: {
    $currentScrollPosition: {
      handler() {
        if (this.$refs.$header && !this.$root.isPrintPage) {
          let bottom =
            Math.ceil(window.innerHeight + window.scrollY) >=
            document.documentElement.scrollHeight;

          if (
            this.$currentScrollPosition * -1 >=
            this.$refs.$header.clientHeight
          ) {
            bottom =
              Math.ceil(window.innerHeight + window.scrollY) >=
              document.documentElement.scrollHeight;

            this.isSticky = true;

            if (this.showUserDropdown) {
              /* Close the user dropdown if is open */
              this.showUserDropdown = false;
            }

            if (this.$scrollDirection === 'up' && !bottom) {
              this.isStickyVisible = true;
            } else {
              this.isStickyVisible = false;
            }
          } else {
            this.isStickyVisible = false;
            this.isSticky = false;
          }
        }
      },
      immediate: true,
    },
    $currentStep: {
      handler(newVal) {
        //we cannot compute correct height because fixed height is different from normal height
        //so if a user scroll and after refresh the page we cannot get the correct height of the header
        //so we map it manually
        if (newVal === 'xs') {
          this.currentOverlappingHeight = '58px';
        } else if (newVal === 'sm') {
          this.currentOverlappingHeight = '84px';
        } else if (newVal === 'md') {
          this.currentOverlappingHeight = '142px';
        } else {
          this.currentOverlappingHeight = '178px';
        }
      },
      immediate: true,
    },
    $viewportWidth: {
      handler(newVal, oldVal) {
        if (newVal != oldVal && this.showUserDropdown) {
          if (newVal < 1024) {
            this.onScrollYHidden(true);
          } else {
            this.onScrollYHidden(false);
          }
        }
      },
    },
  },
  mounted() {
    this.$watch(
      () => this.$root.localH,
      (val) => {
        this.spacer = val;
      },
      { immediate: true }
    );
  },
  methods: {
    goToLogin() {
      window.location.href = this.dataGigyaLoginUrl;
    },
    onLogout() {
      window.gigya.accounts.logout();
    },
    onToggleMobileMenu(value) {
      const handler = (v) => {
        this.onScrollYHidden(v);
        this.showMobileMenu = v;
      };
      if (this.$root.isIos) {
        if (value) {
          const scrollY =
            document.documentElement.style.getPropertyValue('--scroll-y');
          const body = document.body;
          body.style.position = 'fixed';
          body.style.top = `-${scrollY}`;
          setTimeout(() => {
            // const scrollYBack = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0'));
            setTimeout(() => {
              handler(value);
            }, 0);
          }, 0);
        } else {
          handler(value);
        }
      } else {
        handler(value);
      }
    },
    onToggleUserDropdown(value) {
      if (value) {
        if (this.$viewportWidth < 1024) {
          this.onScrollYHidden(value);
        }
      } else {
        this.onScrollYHidden(value);
      }
      this.showUserDropdown = value;
    },
    onShowModal() {
      if (this.showUserDropdown) {
        this.onToggleUserDropdown(false);
      }
      this.$modal.show('modal-name');
    },
    onScrollYHidden(value) {
      if (value) {
        document.body.classList.add('modal-open');
        (() => {
          let doCheck = () => {
            if (this.$refs.$mobileMenu) {
              this.$_a11Support_disableScrollOuterElement({
                $el: this.$refs.$mobileMenu,
                enable: true,
              });
            } else {
              setTimeout(doCheck, 100);
            }
          };
          doCheck();
        })();
      } else {
        document.body.classList.remove('modal-open');
        this.$_a11Support_clearAllBodyScrollLocks();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaHeaderWrapper {
  position: relative;
  z-index: map_get($z, nav);
}

.WaHeader {
  color: $color-blue-logo;
  height: 58px;

  @media (min-width: $bootstrap-sm) {
    height: 84px;
  }

  @media (min-width: $bootstrap-md) {
    height: 142px;
  }

  @media (min-width: $bootstrap-lg) {
    height: 178px;
  }

  .desktop-menu {
    position: relative;

    .brand {
      svg {
        width: 60px;
        height: 48px;
        margin: 10px 0 0 -10px;

        @media (min-width: $bootstrap-sm) {
          width: 80px;
          height: 64px;
          margin: 20px 0 0 -40px;
        }

        @media (min-width: $bootstrap-md) {
          width: 140px;
          height: 112px;
          margin: 30px 0 0 -32px;
        }

        @media (min-width: $bootstrap-lg) {
          width: 185px;
          height: 148px;
          margin: 30px 0 0 -48px;
        }
      }
    }

    .overlap {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .menu,
    .icons {
      display: flex;
      align-items: center;
      height: 100%;
      white-space: nowrap;
    }

    .menu {
      @media (min-width: $bootstrap-lg) {
        height: auto;
        margin: 52px 0 0;
      }
    }

    .icons {
      justify-content: flex-end;
      min-height: 40px;
      margin: 0 -10px 0 0;

      @media (min-width: $bootstrap-sm) {
        margin: 0 -40px 0 0;
      }

      @media (min-width: $bootstrap-md) {
        margin: 0 -30px 0 0;
      }

      @media (min-width: $bootstrap-lg) {
        height: auto;
        margin: 52px 0 0;
      }

      .language-icon {
        cursor: pointer;
      }

      .language-text {
        display: flex;
        align-items: center;
        margin-left: 12px;
        height: 32px;

        .separator {
          margin-left: 16px;
          height: 100%;
          border-left: 2px solid currentColor;
        }
      }

      .user-icon {
        cursor: pointer;
        margin: 0 0 0 16px;
      }

      .user-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 0 0 16px;
        cursor: pointer;

        &.active {
          box-shadow: 0 0 0 3px rgba($color-black, 0.25);
        }
      }

      .hamburger-icon {
        margin: 0 0 0 16px;
        cursor: pointer;
      }

      .user-dropdown-container {
        position: relative;

        .user-dropdown-wrapper {
          @media (max-width: $bootstrap-md - 1px) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            background-color: rgba($color-grey-8, 0.75);
            opacity: 1;
          }

          &.user-dropdown-wrapper-enter-active,
          &.user-dropdown-wrapper-leave-active {
            transition: opacity 300ms ease-out;

            .user-dropdown {
              @media (max-width: $bootstrap-md - 1px) {
                transition: transform 300ms ease-out;
              }
            }
          }

          &.user-dropdown-wrapper-enter,
          &.user-dropdown-wrapper-leave-to {
            opacity: 0;

            .user-dropdown {
              @media (max-width: $bootstrap-md - 1px) {
                transform: translateY(100%);
              }
            }
          }

          .user-dropdown {
            padding: 0 20px;
            background-color: $color-white;
            color: $color-black;
            transform: translateY(0);

            @media (min-width: $bootstrap-sm) {
              padding: 0;
            }

            @media (min-width: $bootstrap-md) {
              position: absolute;
              right: 0;
              width: calc((100vw * 25) / 100);
              padding: 0 8px;
              margin: 16px 0 0;
              border-radius: 4px;
              filter: drop-shadow(0 4px 16px rgba(0, 0, 0, 0.15));
            }

            .content {
              width: auto;
              margin: 0;

              @media (min-width: $bootstrap-sm) and (max-width: $bootstrap-md - 1px) {
                width: calc(
                  100% - #{map_get($bootstrap-container-side-spacings, sm)} - #{map_get(
                      $bootstrap-gutters,
                      sm
                    )}
                );
                margin: 0 auto;
              }

              .head {
                font-weight: $fh-medium;
                border-bottom: 1px solid $color-grey-4;
                padding: 24px 10px;

                @media (min-width: $bootstrap-sm) {
                  padding: 24px 0;
                }

                @media (min-width: $bootstrap-md) {
                  padding: 24px 12px;
                }
              }

              .control-panel {
                font-weight: $fh-light;
                color: $color-black;
                padding: 8px 10px 40px;

                @media (min-width: $bootstrap-sm) {
                  padding: 8px 0 64px;
                }

                @media (min-width: $bootstrap-md) {
                  padding: 8px 12px;
                }

                ul {
                  list-style: none;
                  margin: 0;
                  padding: 0;

                  li {
                    padding: 11.5px 0;
                    margin: 0;

                    a {
                      color: inherit;

                      &:hover {
                        color: $color-blue-logo;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba($color-grey-8, 0.75);
    opacity: 1;

    &.mobile-menu-enter-active,
    &.mobile-menu-leave-active {
      transition: opacity 300ms ease-out;

      .right-side {
        transition: transform 300ms ease-out;
      }
    }

    &.mobile-menu-enter,
    &.mobile-menu-leave-to {
      opacity: 0;

      .right-side {
        transform: translateX(100%);
      }
    }

    .right-side {
      transform: translateX(0);
      background-color: $color-white;

      .flexWrapper {
        display: flex;
        flex-flow: column nowrap;
        height: 100%;
        justify-content: space-between;
      }

      .head {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 30px;

        @media (min-width: $bootstrap-sm) {
          padding: 30px 20px;
        }

        .close-icon {
          color: $color-grey-9;
          cursor: pointer;

          &:hover {
            color: $color-grey-8;
          }
        }
      }

      .menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 30px;
        white-space: nowrap;

        @media (min-width: $bootstrap-sm) {
          padding: 0 20px;
        }

        /deep/ .WaHeaderMenuItem {
          @media (min-width: $bootstrap-md) {
            padding: 8px 0;
            margin: 0 0 24px;
            background-position: left bottom;
          }
        }
      }

      .language-chooser {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 30px 40px 0;
        align-self: flex-end;
        cursor: pointer;

        span {
          display: inline-block;
          text-decoration: underline;
          padding-right: 8px;
        }
      }
    }
  }

  /* Theme variant */
  &.sticky {
    position: fixed;
    top: -80px;
    right: 0;
    left: 0;
    z-index: map_get($z, menu);
    height: 80px;
    transition: transform 300ms ease-out;
    transform: translateY(0);

    @media (min-width: $bootstrap-sm) {
      top: -110px;
      height: 110px;
    }

    @media (min-width: $bootstrap-md) {
      top: -120px;
      height: 120px;
    }

    .wrapper {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background-color: $color-white;
        filter: drop-shadow(0 4px 16px rgba(0, 0, 0, 0.15));
        height: 58px;

        @media (min-width: $bootstrap-sm) {
          height: 84px;
        }

        @media (min-width: $bootstrap-md) {
          height: 92px;
        }
      }
    }

    .desktop-menu {
      .menu,
      .icons {
        @media (min-width: $bootstrap-lg) {
          height: 100%;
          margin: 0;
        }
      }

      .brand {
        svg {
          margin-top: 10px;

          @media (min-width: $bootstrap-sm) {
            margin-top: 20px;
          }

          @media (min-width: $bootstrap-md) {
            width: 90px;
            height: 72px;
            margin-top: 19px;
          }
        }
      }
    }

    &.sticky-visible {
      transform: translateY(80px);

      @media (min-width: $bootstrap-sm) {
        transform: translateY(110px);
      }

      @media (min-width: $bootstrap-md) {
        transform: translateY(120px);
      }
    }
  }

  &.dark:not(.sticky) {
    .desktop-menu {
      color: $color-white;

      .menu {
        /* deep needed ??? */
        .WaHeaderMenuItem {
          .content {
            &::after {
              background-image: linear-gradient($color-white, $color-white);
            }
          }
        }
      }

      .user-img {
        &.active {
          box-shadow: 0 0 0 3px rgba($color-white, 0.25);
        }
      }
    }
  }

  &:not(.sticky.sticky-visible).mobileMenuVisible {
    top: 0;
  }
}

.modal-content {
  padding: 32px 30px 0;
  color: $color-blue-logo;

  @media (min-width: $bootstrap-sm) {
    padding: 40px 16px 0;
  }

  @media (min-width: $bootstrap-lg) {
    padding: 32px 24px 0;
  }

  .title {
    display: block;
    text-align: center;
    padding: 0 10px 32px;
    @media (min-width: $bootstrap-sm) {
      padding: 0 0 40px;
    }

    @media (min-width: $bootstrap-lg) {
      padding: 0 0 64px;
    }
  }

  .language-item {
    margin-bottom: 14px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 2px solid transparent;
    cursor: pointer;
    color: inherit;
    text-decoration: none;

    @media (min-width: $bootstrap-sm) {
      padding: 12px 16px;
    }

    &:hover {
      border-color: $color-grey-4;
    }

    &.active {
      background-color: rgba($color-grey-2, 0.75);
    }
  }
}
</style>
