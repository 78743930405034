<template functional>
  <div
    :ref="data.ref"
    :class="['WaCtaPerLine', data.staticClass, data.class]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WaCtaPerLine',
  functional: true,
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaCtaPerLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
