<template>
  <div class="ContactFormTriageTopicCmp">
    <router-link
      v-for="item in routerLinks"
      :key="`list-item-${item.id}`"
      class="list-item"
      :to="{
        name: getRouteName(routeDependencies, queryString, routes, item),
        query: {
          ...queryString,
          triage_topic: item.id,
          page_order: `${queryString.page_order},${routes.triage_topic.name}`,
        },
      }"
    >
      <wa-cta-box
        class="contact-form-cta-box text-color-blue-logo"
        :data-id-icon="item.icon.svgId"
      >
        <template #title>
          <h4 class="typo-a typo-a-6 typo-medium margin-bottom-0">
            {{ item.label }}
          </h4>
        </template>
      </wa-cta-box>
    </router-link>
    <a
      v-for="item in externalLinks"
      :key="`list-item-${item.id}`"
      :href="item.href"
      target="_blank"
      class="list-item"
    >
      <wa-cta-box
        class="contact-form-cta-box text-color-blue-logo"
        :data-id-icon="item.icon.svgId"
      >
        <template #title>
          <h4 class="typo-a typo-a-6 typo-medium margin-bottom-0">
            {{ item.label }}
          </h4>
        </template>
      </wa-cta-box>
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContactForm2024Mixin from '../../mixins/contact_form_2024_mixin';

export default {
  name: 'ContactFormTriageTopicCmp',
  mixins: [ContactForm2024Mixin],
  data: function () {
    return {
      routeDependencies: ['triage_type'],
    };
  },
  computed: {
    ...mapState('contactForm2024', ['routes', 'queryString']),
    routerLinks() {
      return this.getActiveCtas(
        'triage_topic',
        this.routeDependencies,
        this.queryString,
        this.routes
      ).filter((item) => {
        return !item.href;
      });
    },
    externalLinks() {
      return this.getActiveCtas(
        'triage_topic',
        this.routeDependencies,
        this.queryString,
        this.routes
      ).filter((item) => {
        return !!item.href;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormTriageTopicCmp {
  .list-item {
    display: block;
    text-decoration: none;

    & ~ .list-item {
      padding-top: var(--contact-form-list-item-padding-top, 0);
    }
  }
}
</style>
