<template>
  <div class="WaDummyGrid">
    <div class="container" style="height: 500px; outline: 1px solid red">
      <div class="row" style="height: 500px; outline: 1px solid blue">
        <div class="col-12" style="height: 500px; outline: 1px solid green" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaDummyGrid',
};
</script>

<style lang="scss">
@import '~$scss/_variables';
/* ignore warning */
.WaDummyGrid {
}
</style>
