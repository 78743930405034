<template>
  <div class="WaPmextraNorwaySandwichEnterContest">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WaPmextraNorwaySandwichEnterContest',
  props: {
    dataPromo: {
      type: [Object, String],
      required: true,
    },
    dataRelatedProducts: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    i18n() {
      return this.$_cmsSupport_castPropToType(this.dataPromo, 'object');
    },
    relatedProducts() {
      return this.$_cmsSupport_castPropToType(
        this.dataRelatedProducts,
        'array'
      );
    },
  },
  created() {
    this.setPmextraNorwaySandwichI18nParticipate(this.i18n);
    this.setPmextraNorwaySandwichRelatedProducts(this.relatedProducts);
  },
  methods: {
    ...mapActions('pmextraNorwaySandwich', [
      'setPmextraNorwaySandwichI18nParticipate',
      'setPmextraNorwaySandwichRelatedProducts',
    ]),
  },
};
</script>

<style scoped></style>
