export const PRODUCTS = {
  data: [
    {
      code: '1040922001200',
      description: 'Sesam & Vollkorn 200g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1081120002260',
      description: 'Vollkorn Roggen 260g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1071120003275',
      description: 'Rustikal 275g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1071120004235',
      description: 'Roggen Sauerteig 235g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1040122001210',
      description: 'Leinsamen 210g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1070122002230',
      description: 'Hafer & Sesam 230g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1070122001230',
      description: 'Milch & Joghurt 230g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1040522001245',
      description: 'Selection Chia & Meersalz 245g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1040522003245',
      description: 'Selection Mohn & Meersalz 245g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1040122011240',
      description: 'Wasa Gluten- und Laktosefrei Classic 240g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1040122012240',
      description: 'Wasa Gluten- und Laktosefrei Sesam & Meersalz 240g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '109052103790',
      description: 'Sandwich Mild Cheese 90g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1040122008120',
      description: 'Sandwich Cheese Tomato & Basil 120g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '107012200690',
      description: 'Sandwich Cheese & French Herbs 90g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1070122005111',
      description: 'Sandwich Cheese & Chives 111g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '104012200999',
      description: 'Sandwich Sour Cream & Onion 99g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1040522002190',
      description: 'Tasty Snacks Roasted Carlic & Sea Salt Crisps 190g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1070324001190',
      description: 'Tasty Snacks Sesame & Sea Salt Crisps 190g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1070324002190',
      description: 'Tasty Snacks Rosemary & Sea Salt Crisps 190g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1070324003205',
      description: 'Tasty Snacks French Herbs Rounds 205g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1070324004235',
      description: 'Tasty Snacks Sesame & Sea Salt Rounds 235g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1070324005180',
      description: 'Tasty Snacks Sea Salt Crackers 180g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1070324006150',
      description: 'Tasty Snacks Paprika Crackers 150g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1070324007160',
      description: 'Tasty Snacks Tomato & Oregano Crackers 160g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
    {
      code: '1070324008150',
      description: 'Tasty Snacks Black & Green Olives Crackers 150g',
      category: {
        id: '1',
        description: "Prodotti coinvolti nell'iniziativa",
      },
    },
  ],
};

export const ONLINE_STORES_DE = {
  data: [
    {
      id: '18',
      description: 'Rewe Online',
    },
    {
      id: '19',
      description: 'Bringmeister',
    },
    {
      id: '20',
      description: 'Andere Online-Händler',
    },
  ],
};

export const ONLINE_STORES_AT = {
  data: [
    {
      id: '38',
      description: 'Andere Online-Händler',
    },
    {
      id: '39',
      description: 'Billa Online',
    },
    {
      id: '40',
      description: 'Spar Online',
    },
    {
      id: '41',
      description: 'Mpreis Online',
    },
    {
      id: '42',
      description: 'Unimarkt Online',
    },
  ],
};

export const OFFLINE_STORES_DE = {
  data: [
    {
      id: '1',
      description: 'Edeka',
    },
    {
      id: '2',
      description: 'Rewe',
    },
    {
      id: '3',
      description: 'Kaufland',
    },
    {
      id: '4',
      description: 'real',
    },
    {
      id: '5',
      description: 'tegut',
    },
    {
      id: '6',
      description: 'Globus',
    },
    {
      id: '7',
      description: 'V-Markt',
    },
    {
      id: '8',
      description: 'Bünting',
    },
    {
      id: '9',
      description: 'Famila',
    },
    {
      id: '10',
      description: 'Metro C&C',
    },
    {
      id: '11',
      description: 'K+K',
    },
    {
      id: '12',
      description: 'Lidl',
    },
    {
      id: '13',
      description: 'Penny',
    },
    {
      id: '14',
      description: 'Netto',
    },
    {
      id: '15',
      description: 'Netto Stavenhagen',
    },
    {
      id: '16',
      description: 'Norma',
    },
    {
      id: '17',
      description: 'Andere stationäre Händler',
    },
  ],
};

export const OFFLINE_STORES_AT = {
  data: [
    {
      id: '21',
      description: 'Adeg',
    },
    {
      id: '22',
      description: 'Billa',
    },
    {
      id: '23',
      description: 'Eurospar',
    },
    {
      id: '24',
      description: 'Hofer',
    },
    {
      id: '25',
      description: 'Interspar',
    },
    {
      id: '26',
      description: 'Maximarkt',
    },
    {
      id: '27',
      description: 'Merkur',
    },
    {
      id: '28',
      description: 'Metro',
    },
    {
      id: '29',
      description: 'Mpreis',
    },
    {
      id: '30',
      description: 'Penny',
    },
    {
      id: '31',
      description: 'Spar',
    },
    {
      id: '32',
      description: 'Unimarkt',
    },
    {
      id: '33',
      description: 'Andere stationäre Händler',
    },
  ],
};
