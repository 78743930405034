<template>
  <form ref="$form" class="WaContactFormCompanyCv" @submit.prevent>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 text-center padding-bottom-r">
        <span>
          {{ contentData.form_send_cv_text }}
          <wa-cta data-target="_blank" :data-href="link_cv">
            {{ contentData.form_send_cv_link_text }}
          </wa-cta>
        </span>
      </div>
    </div>
    <div
      v-if="
        formConfig &&
        formConfig.description &&
        formConfig.description.is_visible
      "
      class="row justify-content-center"
    >
      <div class="col-12 col-md-8">
        <wa-textarea
          v-model.trim="$v.description.$model"
          data-name="description"
          :data-label="
            dataLabel(
              'description',
              contentData.form_textarea_description_label
            )
          "
          :data-error-occurred="$v.description.$error"
          :data-errors="
            dataErrors(
              'description',
              {
                required: contentData.form_textarea_description_error_required,
              },
              [
                {
                  condition: !$v.description.maxLength,
                  message:
                    contentData.form_textarea_description_error_max_length,
                },
              ]
            )
          "
          @input="onFieldChange('description', $event)"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 padding-top-xs padding-bottom-r">
        <span class="typo-a-9 typo-light text-color-grey-8">
          {{ contentData.mandatory_field_indication_placeholder }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <wa-cta
          class="primary"
          data-type="submit"
          :data-disabled="disableSubmit"
          @click.prevent="onSubmitBtnClick"
        >
          {{ contentData.continue_cta_label }}
        </wa-cta>
      </div>
    </div>
  </form>
</template>

<script>
import WaContactFormFieldsUpdate from '@mixins/wa-contact-form-fields-update';
import { helpers, required, maxLength } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

export default {
  name: 'WaContactFormCompanyCv',
  mixins: [WaContactFormFieldsUpdate],
  data() {
    return {
      /* form field */
      description: null,
      fields: ['description'],
    };
  },
  computed: {
    ...mapState('contact', ['formData', 'links', 'contentData', 'fieldsInfos']),
    ...mapState('config', ['link_cv']),
  },
  validations() {
    let validations = {};
    this.fields.forEach((prop) => {
      //console.log('_ _');
      if (
        this.formConfig &&
        this.formConfig &&
        this.formConfig[prop].is_visible
      ) {
        validations[prop] = {};
      }
    });

    Object.keys(validations).forEach((prop) => {
      if (this.formConfig[prop].required) {
        validations[prop] = {
          ...validations[prop],
          required,
        };
      }

      if (this.formConfig[prop].regex) {
        validations[prop] = {
          ...validations[prop],
          regex: (val) =>
            !helpers.req(val) ||
            new RegExp(this.formConfig[prop].regex).test(val),
        };
      }
    });

    //console.log('_ _ _');
    if (
      this.formConfig &&
      this.formConfig.description &&
      this.formConfig.description.is_visible
    ) {
      validations.description = {
        ...validations.description,
        //it seems that the way be and fe are counting the characters of the string are different
        //so validation frontend count less than 500 or 500 and let the user go trough the flow
        //while backend count them like they are more than 500 so returns an error.
        //because we do not have this handling in a proper way because of 'storie' we just limit
        //this string to 490. sorry
        maxLength: maxLength(490),
      };
    }

    return validations;
  },
  mounted() {
    window.cfStepsContent = window.cfStepsContent || [];
    window.cfStepsContent.push('WaContactFormCompanyCv');
    this.fields.forEach((f) => {
      if (this.formData[f]) {
        this[f] = this.formData[f];
      }
    });
  },
};
</script>

<style scoped></style>
