export default {
  currentStepNavigation: (state) => {
    return +`${state.currentStep ? state.currentStep.num : null}`;
  },
  totalStepNavigation: (state) => {
    return +`${state.views.filter((a) => a.num).length}`;
  },
  steps: (state) => {
    const arrayToObject = (array) =>
      array.reduce((obj, item) => {
        obj[item.name] = item;
        return obj;
      }, {});

    return arrayToObject(state.views);
  },
};
