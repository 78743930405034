/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    currentUri: '',
    inverted_header: false,
    inverted_footer: false,
    selected_main_category: '',
    selected_sub_category: '',
    date_format: '',
    enable_seo_products_urls: '',
    gmap_key: '',
    home_page_url: '',
    language_chooser_label: '',
    link_cv: '',
    load_more_label: '',
    new_label: '',
    recaptcha_key: '',
    label_cta_all_news: '',
    buy_now_label: '',
    url_cta_all_news: '',
    languages: [],
    language: 'en',
    apis: [],
    recipe_labels: {
      all_recipes_link: '',
      back_link_label: '',
      discover_more_label: '',
      how_to_label: '',
      ingredients_label: '',
      ingredients_label_hero: '',
      kcal_label: '',
      made_with_label: '',
      people_label: '',
      related_description_label: '',
      related_title_label: '',
      see_more_link_label: '',
    },
    hideRecaptcha: false,
  },
  actions,
  mutations,
  getters,
};
