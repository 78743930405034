<template>
  <div class="ContactFormTicketingCmp">
    <slot />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactFormTicketingCmp',
  props: {
    dataCurrentStep: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('contactForm2024', ['common', 'queryString']),
  },
};
</script>

<style scoped></style>
