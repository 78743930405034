<template>
  <div class="ContactFormTriagePromotionsCmp">
    <router-link
      v-for="item in items"
      :key="`list-item-${item.id}`"
      class="list-item"
      :to="{
        name: routes.triage_promotions.controls.selectionCtaFrom.selectionCtaGoToName.find(
          (gotoObj) => gotoObj.triage_type === queryString.triage_type
        ).name,
        query: {
          ...queryString,
          triage_promotions: item.id,
          page_order: `${queryString.page_order},${routes.triage_promotions.name}`,
        },
      }"
    >
      <wa-cta-box
        class="contact-form-cta-box text-color-blue-logo"
        :data-id-icon="item.icon.svgId"
      >
        <template #title>
          <h4 class="typo-a typo-a-6 typo-medium margin-bottom-0">
            {{ item.label }}
          </h4>
        </template>
      </wa-cta-box>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactFormTriagePromotionsCmp',
  computed: {
    ...mapState('contactForm2024', ['routes', 'queryString']),
    items() {
      const modelName =
        this.routes?.triage_promotions?.controls?.selectionCtaFrom
          ?.selectionCtaItems;

      return modelName ? this.$store?.state?.contactForm2024[modelName] : [];
    },
  },
  mounted() {
    if (!this.items.length) {
      this.$router.replace({
        path: '/',
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.ContactFormTriagePromotionsCmp {
  .list-item {
    display: block;
    text-decoration: none;

    & ~ .list-item {
      padding-top: var(--contact-form-list-item-padding-top, 0);
    }
  }
}
</style>
