var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"WaRadioChooser form-group__wrapper container-fluid"},[_c('div',{class:[
      'form-group row',
      { 'form-group--error': _vm.dataErrorOccurred, 'disabled': _vm.dataDisabled } ]},_vm._l((_vm.dataRadioList),function(radio,i){return _c('div',{key:("radio-" + i + "-" + _vm._uid),staticClass:"form-check__wrapper col-12 col-sm-6"},[_c('label',{class:[
          'form-check',
          {
            disabled: _vm.dataDisabled || radio.disabled,
            checked: radio.value === _vm.value,
          } ]},[(radio.iconId)?_c('svg',{staticClass:"form-check-icon",attrs:{"width":"48","height":"48"}},[_c('use',{attrs:{"href":("#" + (radio.iconId)),"fill":"currentColor"}})]):_vm._e(),_c('span',{staticClass:"form-check-label typo-a-6 typo-medium",attrs:{"for":("input-radio-" + i + "-" + _vm._uid)},domProps:{"innerHTML":_vm._s(radio.label)}}),_c('input',{staticClass:"form-check-input",attrs:{"id":("input-radio-" + i + "-" + _vm._uid),"type":"radio","name":_vm.dataName,"disabled":_vm.dataDisabled || radio.disabled},domProps:{"value":radio.value,"checked":radio.value === _vm.value},on:{"change":_vm.onUpdate}}),_c('span',{staticClass:"checkmark"})])])}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},_vm._l((_vm.hasErrors),function(error,i){return _c('span',{key:("error-" + i + "-" + _vm._uid),staticClass:"error typo-a-10 typo-medium",domProps:{"innerHTML":_vm._s(error.message)}})}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }