var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"WaInput form-group__wrapper"},[_c('div',{class:[
      'form-group',
      { 'disabled': _vm.dataDisabled, 'form-group--error': _vm.dataErrorOccurred } ]},[_c('div',{class:['form-control__wrapper', 'typo-a-9', 'typo-light']},[_c('label',{class:['form__label', { focus: _vm.isFocus || _vm.value }]},[_vm._v(" "+_vm._s(_vm.dataLabel)+" ")]),(_vm.dataMask)?_c('input',{directives:[{name:"facade",rawName:"v-facade",value:(_vm.dataMask),expression:"dataMask"}],class:[
          'typo-a-9',
          'typo-light',
          'form-control',
          'form__input',
          { error: _vm.dataErrorOccurred } ],attrs:{"id":_vm.dataId,"type":_vm.dataType,"name":_vm.dataName,"placeholder":_vm.dataPlaceholder,"disabled":_vm.dataDisabled},domProps:{"value":_vm.value},on:{"focus":function($event){_vm.isFocus = true},"blur":function($event){_vm.isFocus = false},"input":_vm.onUpdate}}):_c('input',{class:[
          'typo-a-9',
          'typo-light',
          'form-control',
          'form__input',
          { error: _vm.dataErrorOccurred } ],attrs:{"id":_vm.dataId,"type":_vm.dataType,"name":_vm.dataName,"placeholder":_vm.dataPlaceholder,"disabled":_vm.dataDisabled},domProps:{"value":_vm.value},on:{"focus":function($event){_vm.isFocus = true},"blur":function($event){_vm.isFocus = false},"input":_vm.onUpdate}}),(_vm.dataErrorOccurred)?_c('div',{staticClass:"form__error"},[_c('svg',{attrs:{"width":"20","height":"20"}},[_c('use',{attrs:{"href":"#icon-triangle-error","fill":"currentColor"}})]),_vm._l((_vm.hasErrors),function(error,i){return _c('span',{key:("error-" + i),staticClass:"form__error--text",domProps:{"innerHTML":_vm._s(error.message)}})})],2):_vm._e(),(_vm.dataHelperText)?_c('div',{staticClass:"form__helperText"},[_vm._v(" "+_vm._s(_vm.dataHelperText)+" ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }