<template>
  <wa-side-backgrounds
    :data-background-right="`${$root.$context.staticRoot}project/img/contact-form/img-right.jpg`"
    :data-background-left="`${$root.$context.staticRoot}project/img/contact-form/img-left.jpg`"
    data-width-left="302"
    data-height-left="400"
    data-width-right="302"
    data-height-right="400"
  >
    <div class="WaContactFormThankYou">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-secondary-item"
          >
            <svg class="icon">
              <use href="#icon-email-send" fill="currentColor" />
            </svg>
            <div
              v-if="!dataIsJeko"
              class="title typo-w typo-w-4 typo-bold text-center"
              v-html="contentData.step_thank_you_title"
            />
            <div
              v-else
              class="title typo-w typo-w-4 typo-bold text-center"
              v-html="contentData.jeko_thank_you_page_title"
            />
            <div
              class="content typo-a-7 typo-light text-center text-color-black"
            >
              <div
                v-if="!dataIsJeko"
                v-html="contentData.step_thank_you_subtitle"
              />
              <div
                v-else
                v-html="contentData.jeko_thank_you_page_description"
              />
              <!-- <wa-irregular-divider
                v-if="user"
                class="text-color-secondary-item padding-top-m padding-bottom-m"
              />
              <div
                v-if="user"
                v-html="contentData.step_thank_you_update_text"
              /> -->
            </div>
            <div
              class="buttons d-flex flex-column flex-sm-row align-items-center justify-content-center"
            >
              <!--              <template v-if="user">-->
              <!--                <wa-cta-->
              <!--                  class="secondary"-->
              <!--                >-->
              <!--                  {{ contentData.dont_update_cta_label }}-->
              <!--                </wa-cta>-->
              <!--                <wa-cta-->
              <!--                  class="primary"-->
              <!--                  @click="$modal.show('modal-toast')"-->
              <!--                >-->
              <!--                  {{ contentData.update_cta_label }}-->
              <!--                </wa-cta>-->
              <!--              </template>-->
              <!--              <template v-else>-->
              <wa-cta class="primary" :data-href="home_page_url">
                {{ contentData.back_to_homepage_cta_label }}
              </wa-cta>
              <!--              </template>-->
            </div>
          </div>
        </div>
      </div>
      <!--      <wa-modal-toast-->
      <!--        class="padding-top-l padding-bottom-l"-->
      <!--        data-icon="icon-check-circle-hand-drawn"-->
      <!--      >-->
      <!--        <template slot="title">-->
      <!--          {{ contentData.step_thank_you_update_title }}-->
      <!--        </template>-->
      <!--        <template slot="content">-->
      <!--          {{ contentData.step_thank_you_update_results }}-->
      <!--        </template>-->
      <!--        <template slot="buttons">-->
      <!--          <wa-cta-->
      <!--            class="primary"-->
      <!--          >-->
      <!--            {{ contentData.back_to_homepage_cta_label }}-->
      <!--          </wa-cta>-->
      <!--        </template>-->
      <!--      </wa-modal-toast>-->
    </div>
  </wa-side-backgrounds>
</template>

<script>
import SmcSupport from '@mixins/smc-support';
import { mapState } from 'vuex';

export default {
  name: 'WaContactFormThankYou',
  mixins: [SmcSupport],
  props: {
    dataIsJeko: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('config', ['home_page_url']),
    ...mapState('gigya', ['user']),
    ...mapState('contact', ['contentData', 'productDetail', 'formData']),
  },
  mounted() {
    if (window.dataLayer) {
      window.dataLayer.push({
        'event': 'form_submitted',
        'data-form-id': `${this.formData['step-1']}_${this.formData['step-2']}`,
        'data-form-name': 'contact_form',
      });
    }
    /* smc */
    const unwatch = this.$watch(
      () => this.smcReady,
      (val) => {
        if (val) {
          const payload = {
            initiativeId: '',
            interactionDetail: {
              'step-1': this.formData['step-1'],
              'step-2': this.formData['step-2'],
            },
            interactionOutcome: '',
          };
          if (this.formData['step-2'] === 'products') {
            payload.products = [
              {
                ProductCode: this.productDetail.smc_id,
                Quantity: 1,
              },
            ];
          }
          if (this.formData['step-2'] === 'promotions') {
            payload.initiativeId = this.formData.contest;
            payload.interactionOutcome = this.formData.type;
          }

          this.$_smcSupport_sendEvent('ZCONTACT_FORM_REQ', payload);
          if (unwatch) {
            unwatch();
          }
        }
      },
      { immediate: true }
    );
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaContactFormThankYou {
  .icon {
    width: 48px;
    height: 48px;
  }

  .icon,
  .title {
    margin: 0 0 16px;

    @media (min-width: $bootstrap-sm) {
      margin: 0 0 24px;
    }

    @media (min-width: $bootstrap-md) {
      margin: 0 0 32px;
    }
  }

  .content {
    & > :last-child {
      margin: 0 !important;
    }
  }

  .buttons {
    /deep/ .WaCta {
      margin: 64px 16px 0;

      @media (min-width: $bootstrap-sm) {
        margin: 64px 12px 0;
      }

      @media (min-width: $bootstrap-md) {
        margin: 64px 16px 0;
      }
    }
  }
}
</style>
