<template>
  <div class="WaDropdown form-group__wrapper">
    <div :class="['form-group', { disabled: dataDisabled }]">
      <div :class="['form-control__wrapper', 'typo-a-9', 'typo-light']">
        <label :class="['form__label', { focus: isFocus || value }]">
          {{ dataLabel }}
        </label>
        <div
          :class="[
            'frame',
            { focus: isFocus, filled: value, error: dataErrorOccurred },
          ]"
        >
          <select
            :id="dataId"
            :aria-label="dataLabel"
            :name="dataName"
            :class="[
              'typo-a-9',
              'typo-light',
              'form-control',
              'form__select',
              {
                'focus': isFocus,
                'filled': value,
                'error': dataErrorOccurred,
                'alter-selected-variant': dataAlternativeSelectedValue,
              },
            ]"
            :disabled="dataDisabled"
            :required="dataPlaceholder"
            @focus="isFocus = true"
            @blur="isFocus = false"
            @change="onUpdate"
          >
            <option v-if="dataPlaceholder" value="">
              {{ dataPlaceholder }}
            </option>
            <option
              v-for="(option, i) in dataOptionList"
              :key="`option-${i}`"
              :value="option.key"
              :selected="useInt ? +option.key === +value : option.key === value"
            >
              {{ option.dataValue }}
            </option>
          </select>
        </div>

        <div v-if="dataAlternativeSelectedValue" class="alter-selected">
          <span v-if="value">
            {{
              dataOptionList.find((e) => +e.key === +value)
                .dataAlternativeSelectedValue
            }}
          </span>
        </div>

        <svg
          v-if="!dataErrorOccurred"
          class="form__icon"
          width="24"
          height="24"
        >
          <use href="#icon-arrow-angle-down" fill="currentColor" />
        </svg>
        <div v-if="dataErrorOccurred" class="form__error">
          <svg width="24" height="24">
            <use href="#icon-triangle-error" fill="currentColor" />
          </svg>

          <span
            v-for="(error, i) in hasErrors"
            :key="`error-${i}`"
            class="form__error--text"
            v-html="error.message"
          />
        </div>
        <div v-if="dataHelperText" class="form__helperText">
          {{ dataHelperText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaDropdown',
  props: {
    dataLabel: {
      type: String,
      default: '',
    },
    dataUseInt: {
      type: [String, Boolean],
      default: true,
    },
    dataId: {
      type: String,
      default: null,
    },
    dataName: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    dataPlaceholder: {
      type: String,
      default: null,
    },
    dataDisabled: {
      type: Boolean,
      default: null,
    },
    dataOptionList: {
      type: Array,
      default: () => [],
    },
    dataAlternativeSelectedValue: {
      type: Boolean,
      default: false,
    },
    dataErrorOccurred: {
      type: Boolean,
      default: null,
    },
    dataErrors: {
      type: Array,
      default: null,
    },
    dataHelperText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    useInt() {
      return this.$_cmsSupport_castPropToType(this.dataUseInt, 'boolean');
    },
    hasErrors() {
      if (this.dataErrorOccurred) {
        return this.dataErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  methods: {
    onUpdate(e) {
      this.$emit('input', e.target.value);
      this.$emit('change', e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.form-group__wrapper {
  width: 100%;
  position: relative;
  color: $color-grey-9;

  .form-control__wrapper {
    position: relative;
    width: 100%;

    .form__label {
      margin: auto;
      position: absolute;
      top: 17px;
      left: 16px;
      color: $color-grey-6;
      pointer-events: none;
      transition: all 0.1s ease-in-out;

      &.focus {
        font-size: 13px;
        font-weight: $fh-medium;
        top: 8px;
        margin-bottom: 23px;
      }
    }

    .frame {
      border: 1px solid $color-grey-6;
      border-radius: 4px;

      &.filled {
        border: 1px solid $color-grey-6;
      }

      &.focus {
        border: 1px solid $color-grey-9;
      }

      &.error {
        border: 1px solid $color-informative-red;
      }
    }

    .form__select {
      border: 0;
      border-radius: 4px;
      background-color: $color-white;
      padding-top: 17px;
      padding-bottom: 14px;
      padding-left: 16px;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &:invalid {
        color: white;
      }

      option {
        color: black;
      }

      &:active,
      &:focus,
      &.focus {
        cursor: pointer;
        outline: none !important;
        padding-top: 23px;
        padding-bottom: 8px;

        &::-webkit-credentials-auto-fill-button {
          visibility: hidden;
        }

        &:invalid {
          color: $color-grey-6;
        }
      }

      &.filled {
        padding-top: 23px;
        padding-bottom: 8px;
      }
    }

    .form__error {
      color: $color-informative-red;

      svg {
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 2;
      }

      .form__error--text {
        display: block;
        font-size: 13px;
        font-weight: $fh-medium;
        margin-top: 8px;

        &:last-child {
          padding-bottom: 7px;
        }
      }
    }

    .form__icon {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 2;
      pointer-events: none;
    }

    .form__helperText {
      font-size: 13px;
      font-weight: $fh-medium;
      color: $color-grey-6;
      margin-top: 8px;
    }

    .form__select.alter-selected-variant {
      opacity: 0;
    }

    .alter-selected {
      position: absolute;
      top: 24px;
      right: 0;
      bottom: 9px;
      left: 17px;
      background-color: $color-white;
      white-space: nowrap;
      z-index: -1;
    }
  }
}
</style>
