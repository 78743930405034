var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"WaSideBySide"},[_c('div',{staticClass:"sideBySideWrapper container-fluid"},[_c('div',{staticClass:"row"},[(
          _vm.hasImageSlot &&
          (_vm.$currentStep === 'xs' ||
            (_vm.$currentStep !== 'xs' && _vm.dataVariantClass === 'left-image'))
        )?_c('div',{staticClass:"col-12 col-sm-6 d-sm-flex align-items-sm-center justify-content-sm-end"},[_c('div',{ref:"$left",class:_vm.$currentStep === 'xs'
              ? ['imageRatio169']
              : [
                  'sideImage left',
                  _vm.getFadeAnimation(_vm.dataVariantClass, 'sideImage') ]},[_vm._t("image")],2)]):_vm._e(),_c('div',{class:[
          'col-12 col-sm-5 col-lg-4 offset-lg-1 d-sm-flex align-items-sm-center',
          {
            'offset-sm-1': _vm.dataVariantClass === 'left-image',
            'padding-top-m': _vm.$currentStep === 'xs',
          } ]},[_c('div',{ref:_vm.dataVariantClass === 'right-image' ? '$left' : '$right',class:_vm.$currentStep === 'xs'
              ? null
              : [
                  'sideText',
                  _vm.getFadeAnimation(_vm.dataVariantClass, 'sideText'),
                  {
                    right: _vm.dataVariantClass === 'left-image',
                    left: _vm.dataVariantClass === 'right-image',
                  } ]},[(_vm.hasSmallTitleSlot)?_c('div',{staticClass:"small_title typo-heavy typo-a-7 margin-bottom-xs"},[_vm._t("small_title")],2):_vm._e(),(_vm.hasTitleSlot)?_c('div',{class:[
              'title typo-bold margin-bottom-r',
              _vm.dataSubvariantClass === 'simple' ? 'typo-w-4' : 'typo-w-3' ]},[_vm._t("title")],2):_vm._e(),(_vm.hasWysiwygSlot)?_c('div',{staticClass:"contentText typo-light typo-a-7"},[_vm._t("wysiwyg")],2):_vm._e(),(_vm.hasCtaSlot)?[_vm._t("cta")]:_vm._e()],2)]),(
          _vm.hasImageSlot &&
          _vm.$currentStep !== 'xs' &&
          _vm.dataVariantClass === 'right-image'
        )?_c('div',{staticClass:"col-6 offset-1 d-flex align-items-center justify-content-start"},[_c('div',{ref:"$right",class:[
            'sideImage right',
            _vm.getFadeAnimation(_vm.dataVariantClass, 'sideImage') ]},[_vm._t("image")],2)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }