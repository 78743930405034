<template>
  <nav class="ScrollXNavbarCmp">
    <div ref="scroller" class="scroller">
      <ul :class="[navIdentifierClass]" @mousedown="mouseDownHandler">
        <slot />
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'ScrollXNavbarCmp',
  props: {
    navIdentifierClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      width: null,
      height: null,
      pos: {
        top: 0,
        left: 0,
        x: 0,
        y: 0,
      },
    };
  },
  watch: {
    $viewportWidth: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.getWidth();
          this.getHeight();
        }
      },
    },
  },
  mounted() {
    this.getWidth();
    this.getHeight();
  },
  methods: {
    getWidth() {
      let accumulator = 0;
      this.$el
        .querySelectorAll('li')
        .forEach((e) => (accumulator += e.offsetWidth));
      this.width = accumulator;
    },
    getHeight() {
      this.height = this.$el.querySelector('li').offsetHeight;
    },
    mouseDownHandler(e) {
      if (this.width > this.$viewportWidth) {
        // Change the cursor and prevent user from selecting the text
        this.$el.querySelector('ul').classList.add('grab');
        this.pos = {
          // The current scroll
          left: this.$el.querySelector('ul').scrollLeft,
          top: this.$el.querySelector('ul').scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY,
        };
        window.addEventListener('mousemove', this.mouseMoveHandler);
        window.addEventListener('mouseup', this.mouseUpHandler);
      }
    },
    mouseMoveHandler(e) {
      this.$el.querySelector('ul').classList.add('grabbing');

      // How far the mouse has been moved
      const dx = e.clientX - this.pos.x;
      const dy = e.clientY - this.pos.y;

      // Scroll the element
      this.$el.querySelector('ul').scrollTop = this.pos.top - dy;
      this.$el.querySelector('ul').scrollLeft = this.pos.left - dx;
    },
    mouseUpHandler() {
      // Reset
      this.$el.querySelector('ul').classList.remove('grab');
      this.$el.querySelector('ul').classList.remove('grabbing');

      this.pos = {
        top: 0,
        left: 0,
        x: 0,
        y: 0,
      };
      window.removeEventListener('mousemove', this.mouseMoveHandler);
      window.removeEventListener('mouseup', this.mouseUpHandler);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.ScrollXNavbarCmp {
  position: relative;
  width: 100%;
  overflow: hidden;

  .scroller {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 35px;
  }

  //.scrollXMainMenu {
  //  padding: 7px;
  //}

  // &:after {
  //   content: '';
  //   position: absolute;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  // }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    list-style-type: none;
    //overflow-x: auto;
    white-space: nowrap;
    cursor: pointer;
    -ms-overflow-style: none;
    /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;
    /* Hide scrollbar for Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    &.grab {
      cursor: move;
      /* fallback if grab cursor is unsupported */
      cursor: grab;
      user-select: none;
      /* Standard syntax */
    }

    &.grabbing {
      cursor: grabbing;
    }
  }
}
</style>
