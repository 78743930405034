/* Import dependencies */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

/* Modules */
// Import modules here
import config from './config';
import contact from './contact';
import gigya from './gigya';
import smc from './smc';
import icons from './icons';
import recipes from './recipes';
import news from './news';

import contestPlantATree from './contest-plant-a-tree'; // 11/20
import pm9LeisureAndFun from './pm9-leisure-and-fun'; // 02/21
import pm56BrunchInNature from './pm5-6-brunch-in-nature'; // 03/21
import pmextraInstagram from './pmextra-instagram'; // 06/21
import pm56SandwichSummerCampaign from './pm5-6-sandwich-summer-campaign'; // 07/21
import pmextraNorwaySandwich from './pmextra-norway-sandwich'; // 12/21
import pm9SaveTheInsects from './pm9-save-the-insects'; // 02/22
import pm56SandwichSurf from './pm5-6-sandwich-surf'; // 05/22
import pm56SeptemberGame from './pm5-6-september-game'; // 07/22
import pm56Biscuits from './pm5-6-biscuits'; // 03/23
import pm9LeisureAndFunTt from './pm9-leisure-and-fun-tt'; // 05/23
import pm56Camper from './pm5-6-camper'; // 04/24

export default new Vuex.Store({
  state: {
    appReady: false,
    storeSetupCompleted: false,
    pageSetupCompleted: false,
    loading: [],
    unlockedView: null,
    unlockedWaitingView: null,
  },
  actions,
  mutations,
  getters,
  modules: {
    // Register modules here
    config,
    contact,
    gigya,
    smc,
    icons,
    recipes,
    news,
    contestPlantATree,
    pm9LeisureAndFun,
    pm56BrunchInNature,
    pmextraInstagram,
    pm56SandwichSummerCampaign,
    pmextraNorwaySandwich,
    pm9SaveTheInsects,
    pm56SandwichSurf,
    pm56SeptemberGame,
    pm56Biscuits,
    pm9LeisureAndFunTt,
    pm56Camper,
  },
});
