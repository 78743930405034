<template>
  <form ref="$form" class="WaContactFormProblemProduct" @submit.prevent>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 text-center padding-bottom-r">
        <span>{{ contentData.form_preserve_product_text }}</span>
      </div>
    </div>
    <div
      v-if="formConfig && formConfig.product && formConfig.product.is_visible"
      class="row justify-content-center"
    >
      <div class="col-12 col-md-8 text-center padding-bottom-r">
        <wa-select
          v-model.trim="$v.product.$model"
          class="medium text-color-blue-logo"
          :data-label="
            dataLabel('product', contentData.form_select_product_placeholder)
          "
          :data-show-label="false"
          data-name="product"
          :data-placeholder="
            dataLabel('product', contentData.form_select_product_placeholder)
          "
          :data-option-api-url="selectsApi['products-api']"
          :data-error-occurred="$v.product.$error"
          :data-errors="
            dataErrors('product', {
              required: contentData.form_select_product_error_required,
            })
          "
          @change="onFieldChange('product', $event)"
        >
          <template slot="icon">
            <svg width="24" height="24">
              <use href="#icon-arrow-angle-down" fill="currentColor" />
            </svg>
          </template>
        </wa-select>
      </div>
    </div>
    <div
      v-if="formConfig && formConfig.picture && formConfig.picture.is_visible"
      class="row justify-content-center"
    >
      <div class="col-12 col-md-8 padding-bottom-r">
        <wa-upload
          ref="$waUpload"
          v-model.trim="$v.picture.$model"
          name="picture"
          :label="
            dataLabel('picture', contentData.form_input_file_picture_label)
          "
          :label-smaller="
            dataLabel(
              'picture',
              contentData.form_input_file_picture_label_small
            )
          "
          :label-on-hover="
            dataLabel('picture', contentData.form_input_file_picture_hover)
          "
          :cta-text="contentData.form_input_file_picture_cta"
          :helper-text="contentData.form_input_file_picture_helper"
          :helper-text-smaller="
            contentData.form_input_file_picture_helper_small
          "
          :data-error-occurred="$v.picture.$error"
          :errors="
            dataErrors('picture', {}, [
              {
                condition: !$v.picture.mustBeImage,
                message: contentData.form_input_file_picture_error_format,
              },
              {
                condition: !$v.picture.validSize,
                message: contentData.form_input_file_picture_error_size,
              },
            ])
          "
          @change="onFieldChange('picture', $event)"
        />
      </div>
    </div>
    <div
      v-if="
        formConfig &&
        formConfig.description &&
        formConfig.description.is_visible
      "
      class="row justify-content-center"
    >
      <div class="col-12 col-md-8 padding-bottom-r">
        <wa-textarea
          v-model.trim="$v.description.$model"
          data-name="description"
          :data-label="
            dataLabel(
              'description',
              contentData.form_textarea_description_label
            )
          "
          :data-error-occurred="$v.description.$error"
          :data-errors="
            dataErrors(
              'description',
              {
                required: contentData.form_textarea_description_error_required,
              },
              [
                {
                  condition: !$v.description.maxLength,
                  message:
                    contentData.form_textarea_description_error_max_length,
                },
              ]
            )
          "
          @input="onFieldChange('description', $event)"
        />
      </div>
    </div>
    <div class="row justify-content-center no-gutters">
      <div class="col-12 col-md-8">
        <div
          v-if="contentData.step_three_package_data_label !== ''"
          class="typo-a typo-a-9 typo-extra-bold margin-bottom-s text-color-secondary-item"
        >
          {{ contentData.step_three_package_data_label }}
        </div>
        <div class="row">
          <div
            v-if="
              formConfig && formConfig.barcode && formConfig.barcode.is_visible
            "
            class="col-12 col-md-6 padding-bottom-r"
            :class="barcodeTooltipImg ? ['d-flex align-items-center'] : null"
          >
            <svg
              v-if="barcodeTooltipImg"
              v-tooltip.auto="{
                content: barcodeTooltipImg,
                autoHide: true,
                hideOnTargetClick: true,
                trigger: 'hover click',
              }"
              class="info"
            >
              <use href="#icon-info-circle" fill="currentColor" />
            </svg>
            <wa-input
              v-model.trim="$v.barcode.$model"
              data-name="barcode"
              :data-label="
                dataLabel('barcode', contentData.form_input_text_barcode_label)
              "
              :data-error-occurred="$v.barcode.$error"
              :data-errors="dataErrors('barcode', {})"
              @input="onFieldChange('barcode', $event)"
            />
          </div>
          <div
            v-if="formConfig && formConfig.batch && formConfig.batch.is_visible"
            class="col-12 col-md-6 padding-bottom-r"
            :class="batchTooltipImg ? ['d-flex align-items-center'] : null"
          >
            <svg
              v-if="batchTooltipImg"
              v-tooltip.auto="{
                content: batchTooltipImg,
                autoHide: true,
                hideOnTargetClick: true,
                trigger: 'hover click',
              }"
              class="info"
            >
              <use href="#icon-info-circle" fill="currentColor" />
            </svg>
            <wa-input
              v-model.trim="$v.batch.$model"
              data-name="batch"
              :data-label="
                dataLabel('batch', contentData.form_input_text_batch_label)
              "
              :data-error-occurred="$v.batch.$error"
              :data-errors="dataErrors('batch', {})"
              @input="onFieldChange('batch', $event)"
            />
          </div>
          <div
            v-if="
              formConfig &&
              formConfig.bestbefore &&
              formConfig.bestbefore.is_visible
            "
            class="col-12 col-md-6 padding-bottom-r"
            :class="bestbeforeTooltipImg ? ['d-flex align-items-center'] : null"
          >
            <svg
              v-if="bestbeforeTooltipImg"
              v-tooltip.auto="{
                content: bestbeforeTooltipImg,
                autoHide: true,
                hideOnTargetClick: true,
                trigger: 'hover click',
              }"
              class="info"
            >
              <use href="#icon-info-circle" fill="currentColor" />
            </svg>
            <div class="datepicker-wrapper">
              <!-- invisible datepicker on top of wa-input -->
              <datetime
                v-model.trim="$v.bestbefore.$model"
                :phrases="{
                  ok: contentData.date_picker_ok_label,
                  cancel: contentData.date_picker_cancel_label,
                }"
                @input="datePickerInput"
                @mouseenter="toggleHoverBestbeforeInput()"
                @mouseleave="toggleHoverBestbeforeInput()"
              ></datetime>
              <wa-input
                v-model.trim="$v.bestbefore.$model"
                data-name="bestbefore"
                :data-label="
                  dataLabel(
                    'bestbefore',
                    contentData.form_input_text_bestbefore_label
                  )
                "
                :data-mask="maskByFormat"
                :data-error-occurred="$v.bestbefore.$error"
                :data-errors="
                  dataErrors('bestbefore', {}, [
                    {
                      condition: !$v.bestbefore.validDate,
                      message: contentData.form_date_expired_error_valid,
                    },
                  ])
                "
                @input="onBestBeforeChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center no-gutters">
      <div class="col-12 col-md-8">
        <div
          v-if="contentData.step_three_store_info_label !== ''"
          class="typo-a typo-a-9 typo-extra-bold margin-bottom-s text-color-secondary-item"
        >
          {{ contentData.step_three_store_info_label }}
        </div>
        <div class="row">
          <div
            v-if="
              formConfig &&
              formConfig.shopCountry &&
              formConfig.shopCountry.is_visible
            "
            class="col-12 col-md-6 padding-bottom-r"
          >
            <wa-dropdown
              v-model.trim="$v.shopCountry.$model"
              data-name="shopCountry"
              :data-label="
                dataLabel('shopCountry', contentData.form_select_country_label)
              "
              :data-placeholder="
                dataLabel(
                  'shopCountry',
                  contentData.form_select_country_placeholder
                )
              "
              :data-option-list="countryList"
              :data-error-occurred="$v.shopCountry.$error"
              :data-errors="
                dataErrors('shopCountry', {
                  required: contentData.form_select_country_error_required,
                })
              "
              @change="onFieldChange('shopCountry', $event)"
            />
          </div>
          <div
            v-if="
              formConfig &&
              formConfig.shopProvince &&
              formConfig.shopProvince.is_visible
            "
            class="col-12 col-md-6 padding-bottom-r"
          >
            <wa-input
              v-model.trim="$v.shopProvince.$model"
              data-name="shopProvince"
              :data-label="
                dataLabel(
                  'shopProvince',
                  contentData.form_input_text_province_label
                )
              "
              :data-error-occurred="$v.shopProvince.$error"
              :data-errors="
                dataErrors(
                  'shopProvince',
                  { regex: contentData.form_input_text_province_error_regex },
                  [
                    {
                      condition: !$v.shopProvince.minLength,
                      message:
                        contentData.form_input_text_province_error_minLength,
                    },
                    {
                      condition: !$v.shopProvince.maxLength,
                      message:
                        contentData.form_input_text_province_error_maxLength,
                    },
                  ]
                )
              "
              @input="onFieldChange('shopProvince', $event)"
            />
          </div>
          <div
            v-if="
              formConfig &&
              formConfig.shopCity &&
              formConfig.shopCity.is_visible
            "
            class="col-12 col-md-6 padding-bottom-r"
          >
            <wa-input
              v-model.trim="$v.shopCity.$model"
              data-name="shopCity"
              :data-label="
                dataLabel('shopCity', contentData.form_input_text_city_label)
              "
              :data-error-occurred="$v.shopCity.$error"
              :data-errors="
                dataErrors(
                  'shopCity',
                  { regex: contentData.form_input_text_city_error_regex },
                  [
                    {
                      condition: !$v.shopCity.minLength,
                      message: contentData.form_input_text_city_error_minLength,
                    },
                    {
                      condition: !$v.shopCity.maxLength,
                      message: contentData.form_input_text_city_error_maxLength,
                    },
                  ]
                )
              "
              @input="onFieldChange('shopCity', $event)"
            />
          </div>
          <div
            v-if="
              formConfig && formConfig.shopCap && formConfig.shopCap.is_visible
            "
            class="col-12 col-md-6 padding-bottom-r"
          >
            <wa-input
              v-model.trim="$v.shopCap.$model"
              data-name="shopCap"
              :data-label="
                dataLabel('shopCap', contentData.form_input_text_cap_label)
              "
              :data-error-occurred="$v.shopCap.$error"
              :data-errors="
                dataErrors('shopCap', {
                  regex: contentData.form_input_text_cap_error_regex,
                })
              "
              @input="onFieldChange('shopCap', $event)"
            />
          </div>
          <div
            v-if="
              formConfig &&
              formConfig.shopAddress &&
              formConfig.shopAddress.is_visible
            "
            class="col-12 col-md-6"
          >
            <wa-input
              v-model.trim="$v.shopAddress.$model"
              data-name="shopAddress"
              :data-label="
                dataLabel(
                  'shopAddress',
                  contentData.form_input_text_address_label
                )
              "
              :data-error-occurred="$v.shopAddress.$error"
              :data-errors="
                dataErrors(
                  'shopAddress',
                  { regex: contentData.form_input_text_address_error_regex },
                  [
                    {
                      condition: !$v.shopAddress.minLength,
                      message:
                        contentData.form_input_text_address_error_minLength,
                    },
                    {
                      condition: !$v.shopAddress.maxLength,
                      message:
                        contentData.form_input_text_address_error_maxLength,
                    },
                  ]
                )
              "
              @input="onFieldChange('shopAddress', $event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 padding-top-xs padding-bottom-r">
        <span class="typo-a-9 typo-light text-color-grey-8">
          {{ contentData.mandatory_field_indication_placeholder }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <wa-cta
          class="primary"
          data-type="submit"
          :data-disabled="disableSubmit"
          @click.prevent="onSubmitBtnClick"
        >
          {{ contentData.continue_cta_label }}
        </wa-cta>
      </div>
    </div>
  </form>
</template>

<script>
import WaContactFormFieldsUpdate from '@mixins/wa-contact-form-fields-update';
import LanguageSupport from '@mixins/language-support';
import {
  helpers,
  required,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
import { mapState, mapActions } from 'vuex';
import * as moment from 'moment';
import axios from 'axios';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

export default {
  name: 'WaContactFormProblemProduct',
  components: {
    datetime: Datetime,
  },
  mixins: [WaContactFormFieldsUpdate, LanguageSupport],
  data() {
    return {
      /* form field */
      product: null,
      productDetail: null,
      picture: null,
      description: null,
      barcode: null,
      batch: null,
      bestbefore: null,
      shopCountry: null,
      countryList: [],
      shopProvince: null,
      shopCity: null,
      shopCap: null,
      shopAddress: null,
      fields: [
        'product',
        'picture',
        'description',
        'barcode',
        'batch',
        'bestbefore',
        'shopCountry',
        'shopProvince',
        'shopCity',
        'shopCap',
        'shopAddress',
      ],
    };
  },
  computed: {
    ...mapState('contact', [
      'formData',
      'selectsApi',
      'contentData',
      'fieldsInfos',
    ]),
    ...mapState('config', ['date_format', 'gmap_key']),
    barcodeTooltipImg() {
      return this.productDetail && this.productDetail.img_bar_code
        ? '<img width="150" src="' + this.productDetail.img_bar_code + '">'
        : null;
    },
    batchTooltipImg() {
      return this.productDetail && this.productDetail.img_lot
        ? '<img width="150" src="' + this.productDetail.img_lot + '">'
        : null;
    },
    bestbeforeTooltipImg() {
      return this.productDetail && this.productDetail.img_expiration_date
        ? '<img width="150" src="' +
            this.productDetail.img_expiration_date +
            '">'
        : null;
    },
    maskByFormat() {
      const string = this.date_format;
      const pattern = /[D,M,Y]/gi;

      return string.replace(pattern, '#');
    },
  },
  validations() {
    let validations = {};
    this.fields.forEach((prop) => {
      //console.log('. .');
      if (
        this.formConfig &&
        this.formConfig[prop] &&
        this.formConfig[prop].is_visible
      ) {
        validations[prop] = {};
      }
    });

    Object.keys(validations).forEach((prop) => {
      if (this.formConfig[prop].required) {
        validations[prop] = {
          ...validations[prop],
          required,
        };
      }

      if (this.formConfig[prop].regex) {
        validations[prop] = {
          ...validations[prop],
          regex: (val) =>
            !helpers.req(val) ||
            new RegExp(this.formConfig[prop].regex).test(val),
        };
      }
    });

    //console.log('. . .');
    if (
      this.formConfig &&
      this.formConfig.picture &&
      this.formConfig.picture.is_visible
    ) {
      validations.picture = {
        ...validations.picture,
        mustBeImage: (val) => {
          return (
            !helpers.req(val) ||
            val.type === 'image/jpeg' ||
            val.type === 'image/jpg' ||
            val.type === 'image/png'
          );
        },
        validSize: (val) => {
          const mb = 3 * 1024 * 1024;
          return !helpers.req(val) || val.size <= mb;
        },
      };
    }

    //console.log('. . . .');
    if (
      this.formConfig &&
      this.formConfig.description &&
      this.formConfig.description.is_visible
    ) {
      validations.description = {
        ...validations.description,
        //it seems that the way be and fe are counting the characters of the string are different
        //so validation frontend count less than 500 or 500 and let the user go trough the flow
        //while backend count them like they are more than 500 so returns an error.
        //because we do not have this handling in a proper way because of 'storie' we just limit
        //this string to 490. sorry
        maxLength: maxLength(490),
      };
    }

    //console.log('. . . . .');
    if (
      this.formConfig &&
      this.formConfig.bestbefore &&
      this.formConfig.bestbefore.is_visible
    ) {
      validations.bestbefore = {
        ...validations.bestbefore,
        validDate: (val) => {
          return (
            !helpers.req(val) || !!moment(val, this.date_format, true).isValid()
          );
        },
      };
    }

    //console.log('. . . . . .');
    if (
      this.formConfig &&
      this.formConfig.shopProvince &&
      this.formConfig.shopProvince.is_visible
    ) {
      validations.shopProvince = {
        ...validations.shopProvince,
        minLength: minLength(2),
        maxLength: maxLength(60),
      };
    }

    //console.log('. . . . . .');
    if (
      this.formConfig &&
      this.formConfig.shopCity &&
      this.formConfig.shopCity.is_visible
    ) {
      validations.shopCity = {
        ...validations.shopCity,
        minLength: minLength(2),
        maxLength: maxLength(60),
      };
    }

    //console.log('. . . . . . .');
    if (
      this.formConfig &&
      this.formConfig.shopAddress &&
      this.formConfig.shopAddress.is_visible
    ) {
      validations.shopAddress = {
        ...validations.shopAddress,
        minLength: minLength(2),
        maxLength: maxLength(120),
      };
    }

    return validations;
  },
  watch: {
    product: {
      async handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (this.productDetail && !newVal) {
            await this.setProductDetail(null);
            this.productDetail = null;
          }

          if (newVal) {
            try {
              let result = await axios.get(
                `${this.selectsApi['products-api']}${newVal}/`
              );

              if (result.data) {
                await this.setProductDetail(result.data);
                this.productDetail = result.data;
              }
            } catch (e) {
              console.log(e);
            }
          }
        }
      },
    },
  },
  mounted() {
    window.cfStepsContent = window.cfStepsContent || [];
    window.cfStepsContent.push('WaContactFormProblemProduct');
    import('@fixtures/country-fixture').then((response) => {
      this.countryList = response.data;
    });

    this.fields.forEach((f) => {
      if (this.formData[f]) {
        if (f === 'bestbefore') {
          this[f] = moment(this.formData[f], 'YYYY-MM-DD').format(
            this.date_format
          );
        } else {
          this[f] = this.formData[f];
        }
      }
    });
    this.$nextTick(() => {
      if (this.picture && this.$refs && this.$refs.$waUpload) {
        this.$refs.$waUpload.setFile(this.picture);
      }
    });
  },
  methods: {
    ...mapActions('contact', ['setProductDetail', 'setFormData']),
    // onRetrieveAddress(place) {
    //   this.shopAddress = JSON.stringify(place);
    //   this.setFormData({
    //     'shop-address': this.shopAddress
    //   });
    // },
    focusBestbeforeInput() {
      document.querySelector('input[name="bestbefore"]').focus();
    },
    toggleHoverBestbeforeInput() {
      document
        .querySelector('input[name="bestbefore"]')
        .classList.toggle('hover-state');
    },
    datePickerInput(v) {
      // date value given by datepicker is formatted to the desired date_format and set on variable bestbefore
      // this allow us to use the datepicker selection without losing  wa-input value and error handling
      if (v) {
        const formatted_date = moment(v).format(this.date_format);
        this.bestbefore = formatted_date;
        this.focusBestbeforeInput();
      }
    },
    onBestBeforeChange(v) {
      if (moment(v, this.date_format, true).isValid()) {
        this.setFormData({
          // Be careful on changing this `format('YYYY-MM-DD')` because we format
          // the dates for c4c api in the backend.
          // We should agrre with the backend if we would like to change this
          bestbefore: moment(v, this.date_format).format('YYYY-MM-DD'),
        });
        this.$v['bestbefore'].$touch();
      } else {
        this.setFormData({
          bestbefore: '',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
.datepicker-wrapper {
  position: relative;

  /deep/.vdatetime-input {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

/deep/.vdatetime-popup {
  font: $font-primary, $font-system;
  .vdatetime-popup__header,
  .vdatetime-calendar__month__day--selected > span > span {
    background: $color-blue-logo;
  }
  .vdatetime-popup__actions__button {
    color: $color-blue-logo;
  }
}
</style>
