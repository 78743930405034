<template>
  <a :href="conf.link" class="WaHighlightCarouselItem">
    <div ref="$image" class="image">
      <img :src="conf.image" :alt="conf.title" />
    </div>
    <div class="info">
      <h4 class="title typo-a-6 typo-medium padding-top-r">
        {{ conf.title }}
      </h4>
      <p v-if="conf.text" class="text text-uppercase typo-medium typo-a-10">
        {{ conf.text }}
      </p>
    </div>
  </a>
</template>

<script>
export default {
  name: 'WaHighlightCarouselItem',
  props: {
    dataConf: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    conf() {
      return this.$_cmsSupport_castPropToType(this.dataConf, 'object');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaHighlightCarouselItem {
  display: block;
  pointer-events: none;
  text-decoration: none;
  text-align: center;

  .image {
    width: 100%;
    height: 0;
    padding-bottom: (3 * 100% / 4);
    position: relative;
    transition: transform 0.6s ease-in-out;

    img {
      display: block;
      width: 79%;
      height: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: width 0.6s ease, margin-left 0.3s ease;
      margin-left: 0;
      filter: drop-shadow(0 0 12px rgba($color-black, 0.15));

      @media (min-width: $bootstrap-sm) {
        filter: drop-shadow(0 0 16px rgba($color-black, 0.15));
      }

      @media (min-width: $bootstrap-md) {
        filter: drop-shadow(0 0 24px rgba($color-black, 0.15));
      }
    }
  }

  .title {
    color: $color-black;
    margin: 0;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
  }
  .text {
    color: $color-grey-8-75;
    margin: 8px 0 0;
  }

  .info {
    opacity: 0;
    transform: translate(0, 30px);
    transition: all 0.6s ease-in-out;
  }
}
</style>
