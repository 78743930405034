import * as types from '../mutation_types';

export default {
  setPm56SeptemberGameInitData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        Object.keys(payload).forEach((k) => {
          commit(types.SET_PM5_6_SEPTEMBER_GAME_INIT_DATA, {
            key: k,
            value: payload[k],
          });
        });
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  async setPm56SeptemberGameI18nParticipate({ commit }, obj) {
    commit(types.SET_PM5_6_SEPTEMBER_GAME_I18N_PARTICIPATE, obj);
  },
  async setPm56SeptemberGameI18nPrize({ commit }, obj) {
    commit(types.SET_PM5_6_SEPTEMBER_GAME_I18N_PRIZE, obj);
  },
  async setPm56SeptemberGameRegex({ commit }, obj) {
    commit(types.SET_PM5_6_SEPTEMBER_GAME_REGEX, obj);
  },
};
