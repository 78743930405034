import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    'contest-published': false,
    'landing-page-url': '',
    'legal-age': '',
    'map-filters-api': '',
    'products-api': '',
    'partners-api': '',
    'stores-api': '',
    'privacy-check': '',
    'join-api': '',
    'ch-key-pm9-leisure-and-fun': '',
    'reg-source-pm9-leisure-and-fun': '',
    'form-page-url': '',
    'map-page-url': '',
    'contentData': {
      hero_title: 'KASSENBON HOCHLADEN',
      hero_title_small: 'Contest',
      hero_description:
        'Lorem Ipsum ist ein einfacher Demo-Text für die Print- und Schriftindustrie.',
      product_heading: 'WHAT PRODUCT DID YOU BUY?',
      product_label: 'Select a product',
      product_placeholder: 'Select a product',
      product_error_required: 'The product is mandatory',
      selected_partner_error_required: 'Please select the partner',
      partner_name: 'Freizeitpartner Auswählen',
      partner_placeholder: 'Select partner',
      shop_type_heading: 'WHERE DID YOU BUY?',
      shop_type_description:
        '<p>Each receipt allows only one participation<br>(even if you purchase in the same document)</p>',
      shop_type_store_label: 'Store',
      shop_type_online_label: 'Online',
      shop_type_error_required: 'The shop type is mandatory',
      shop_label: 'Shop list',
      shop_placeholder: 'Choose an option',
      shop_error_required: 'The shop is mandatory',
      picture_heading: 'Upload your receipt',
      picture_description:
        'Upload your receipt front photo and your back photo only if it reports data of issuance of the purchase document',
      picture_label: 'Drag & Drop',
      picture_label_small: 'Upload an image',
      picture_label_hover: 'Release your image here',
      picture_cta: 'browse',
      picture_helper: 'jpg or png (max 3 mb) or',
      picture_helper_small: 'jpg or png (max 3 mb)',
      picture_error_required: 'The picture of the receipt is mandatory',
      picture_error_format: 'File format not allowed',
      picture_error_size: 'File exceeds maximum allowed size',
      picture_instruction_heading: 'How to correctly photograph your receipt',
      picture_instruction_legend_1: 'Store name',
      picture_instruction_legend_2: 'Wasa product',
      picture_instruction_legend_3: 'Date and time',
      picture_instruction_legend_4: 'Total sum',
      picture_instruction_legend_5: 'Etc (tbc with Reinbold Rost)',
      picture_instruction_hint_1: 'Place the receipt of a flat surface',
      picture_instruction_hint_2:
        'Frame the receipt so taht it is fully visible',
      picture_instruction_hint_3:
        'Make sure yuor smartphone is parallel to the receipt',
      picture_instruction_info_heading: 'Info to know',
      picture_instruction_info_1:
        'You can take a picture of a long receipt by folding it.',
      picture_instruction_info_2:
        'If the back of the receipt also has data on it, photograph it and upload the photo.',
      rules_label: 'I have read the %contest rules%',
      rules_href: '',
      rules_error_required: 'This field is mandatory',
      submit_cta: 'Abschicken',
      global_error_logged:
        'Error occurred: You must be logged in to participate in the contest',
      global_error_legal_age:
        'Error occurred: You must be of legal age to participate in the contest',
      global_error_inactive_contest: 'Error occurred: The contest is inactive',
      global_error_external_server_communication_failed:
        'Error occurred: Communication with external server has failed',
      global_error_unable_to_retrieve_user_token:
        'Error occurred: User token not retrived',
      global_error_missing_parameters_in_configuration:
        'Error occurred: Missing some parameters in configuration',
      global_error_rule_acceptance_flag_false:
        'Error occurred: Rule acceptance missing',
      thank_you_title: '<h4>ALL DONE!<br>THANK YOU FOR PARTICIPATING</h4>',
      thank_you_description: 'We will send you an email soon.',
      thank_you_cta: 'Back to Homepage',
    },
  },
  actions,
  mutations,
  getters,
};
