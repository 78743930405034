<template>
  <div :class="['WaMoments', { 'replace-cursor': mouseHover }]">
    <div :class="['swipe-icon', { visible: mouseHover }]" :style="cursorStyle">
      <svg width="48" height="48">
        <use href="#icon-horizontal-swipe" fill="currentColor" />
      </svg>
    </div>
    <div class="container-fluid" :style="{ height: componentHeight }">
      <div ref="$wrapper" class="wrapper">
        <div ref="$scroller" class="swiper-container">
          <button
            ref="$prev"
            type="button"
            class="swiper-button swiper-button-prev text-color-secondary-orange d-sm-none"
          >
            <svg width="24" height="24">
              <use href="#icon-arrow-angle-right" fill="currentColor" />
            </svg>
          </button>
          <button
            ref="$next"
            type="button"
            class="swiper-button swiper-button-next text-color-secondary-orange d-sm-none"
          >
            <svg width="24" height="24">
              <use href="#icon-arrow-angle-right" fill="currentColor" />
            </svg>
          </button>
          <div class="swiper-wrapper">
            <div
              v-for="(moment, index) in moments"
              :key="`slide_${index}`"
              class="swiper-slide"
              :style="{ width: `${slidesWidth[index]}px` }"
            >
              <component
                :is="moment.bg_tag"
                :class="[
                  'text',
                  'typo-w-bold typo-w-1 text-uppercase',
                  `text-color-${moment.bg_color}`,
                ]"
              >
                <span
                  ref="$widths"
                  class="text-inner"
                  v-html="moment.bg_text"
                />
              </component>
            </div>
          </div>
        </div>
        <div class="content-wrapper">
          <div class="row justify-content-center">
            <div class="col-sm-6">
              <div class="image-wrapper">
                <transition name="image" mode="out-in">
                  <template v-for="(moment, index) in moments">
                    <div
                      v-if="index === currentSlide"
                      :key="`image_${index}`"
                      class="image"
                    >
                      <img :src="moment.img_src" :alt="moment.img_alt" />
                    </div>
                  </template>
                </transition>
              </div>
              <transition
                name="content"
                mode="out-in"
                @before-leave="setHeightValue"
                @enter="setHeightValue"
                @after-enter="unsetHeightValue"
              >
                <template v-for="(moment, index) in moments">
                  <div
                    v-if="index === currentSlide"
                    :key="`content_${index}`"
                    class="content margin-top-s"
                    v-html="moment.content_wysiwyg"
                  />
                </template>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';

export default {
  name: 'WaMoments',
  props: {
    dataMoments: { type: [Array, String], required: true },
  },
  data() {
    return {
      swiper: null,
      slidesWidth: [],
      currentSlide: 0,
      height: null,
      mouseHover: false,
      mouseX: 0,
      mouseY: 0,
    };
  },
  computed: {
    moments() {
      return typeof this.dataMoments === 'string'
        ? JSON.parse(this.dataMoments)
        : this.dataMoments;
    },
    componentHeight() {
      if (this.height) {
        return `${this.height}px`;
      } else {
        return 'auto';
      }
    },
    cursorStyle() {
      return {
        top: `${this.mouseY}px`,
        left: `${this.mouseX}px`,
      };
    },
  },
  mounted() {
    this.updateSlidesWidth();
    window.addEventListener('load', () => {
      this.updateSlidesWidth();
      window.removeEventListener('load', this.updateSlidesWidth);
    });
    this.$nextTick(() => {
      this.swiper = new Swiper(this.$refs.$scroller, {
        touchEventsTarget: 'container',
        slidesPerView: 1,
        spaceBetween: 32,
        centeredSlides: true,
        loop: true,
        slideToClickedSlide: true,
        navigation: {
          prevEl: this.$refs.$prev,
          nextEl: this.$refs.$next,
        },
        breakpoints: {
          768: {
            spaceBetween: 40,
            slidesPerView: 'auto',
          },
          1024: {
            spaceBetween: 64,
            slidesPerView: 'auto',
          },
        },
        on: {
          resize: () => {
            this.updateSlidesWidth();
          },
          slideChange: () => {
            if (this.swiper) {
              this.currentSlide = this.swiper.realIndex;
            }
          },
          touchMove: (swiper, e) => {
            this.onMouseMove(e);
          },
        },
      });
    });
    window.addEventListener('mousemove', this.onMouseMove);
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    window.removeEventListener('mousemove', this.onMouseMove);
  },
  methods: {
    updateSlidesWidth() {
      let changed = false;
      if (this.$refs.$widths) {
        this.$refs.$widths.forEach((item, index) => {
          let newWidth = Math.min(
            item.offsetWidth,
            this.$refs.$scroller.offsetWidth
          );
          if (this.slidesWidth[index] !== newWidth) {
            this.$set(this.slidesWidth, index, newWidth);
            changed = true;
          }
        });
        if (this.swiper && changed) {
          this.$nextTick(() => {
            //because in editing mode the nextTick could be executed
            //after the component is destroyed we check again
            if (this.swiper) {
              this.swiper.loopCreate();
              this.swiper.update();
            }
          });
        }
      }
    },
    setHeightValue() {
      this.height = this.$refs.$wrapper.clientHeight;
    },
    unsetHeightValue() {
      this.height = null;
    },
    onMouseMove(e) {
      const rect = this.$el.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      if (x >= 48 && y >= 48 && x <= rect.width - 48 && y <= rect.height - 48) {
        this.mouseHover = true;
        this.mouseX = x;
        this.mouseY = y;
      } else {
        this.mouseHover = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaMoments {
  overflow: hidden;
  position: relative;

  > .container-fluid {
    transition: height 0.5s ease-in-out;
    position: relative;
    z-index: 1;
  }

  @include media-breakpoint-up(md) {
    &.replace-cursor {
      cursor: none;
    }
  }
}

.wrapper {
  position: relative;
  padding-top: 64px;

  @include media-breakpoint-up(sm) {
    padding-top: 0;
  }
}

.swiper-container {
  overflow: visible;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  .swiper-wrapper {
    z-index: 1;
  }
}

.content-wrapper {
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.text {
  margin: 0;
  text-align: center;

  @include media-breakpoint-up(sm) {
    white-space: nowrap;

    ::v-deep {
      br {
        display: none;
      }
    }
  }
}

.swiper-slide {
  height: auto;
  opacity: 0.25;
  transition: opacity 0.6s ease;
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
  opacity: 1;
}

.image-wrapper {
  position: relative;
  margin-top: 0;
  @include media-breakpoint-up(sm) {
    margin-top: 32px;
  }
  @include media-breakpoint-up(md) {
    margin-top: 48px;
  }
}

.image {
  width: 100%;
  height: 0;
  padding-bottom: (9 * 100% / 16);
  position: relative;

  > img {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.content {
  text-align: center;

  ::v-deep {
    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}

.swiper-button {
  position: absolute;
  right: -14px;
  top: 42px;
  z-index: 2;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    display: block;
  }
}

.swiper-button-prev {
  right: auto;
  left: -14px;
  justify-content: flex-start;

  svg {
    transform: rotate(180deg);
  }
}

.swipe-icon {
  border-radius: 50%;
  color: $color-secondary-item;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  margin: -48px 0 0 -48px;
  pointer-events: none;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);

  svg {
    display: block;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 0.5s ease, transform 0.5s ease;

    &.visible {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.content-enter-active,
.content-leave-active {
  transition: opacity 0.4s ease-in-out;
}
.content-enter,
.content-leave-to {
  opacity: 0;
}

.image-enter-active,
.image-leave-active {
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}
.image-enter,
.image-leave-to {
  opacity: 0;
  transform: translate(0, 30px);
}
</style>
