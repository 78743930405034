<template>
  <div class="WaPm9SaveTheInsectsMapPointer">
    <slot name="title" />
    <div class="subtitle">
      <slot name="subtitle" />
    </div>
    <div class="counterLabel text-center">
      <slot name="counter-label" />
      <div
        class="counterValue typo-bold text-center typo-w-4 text-color-blue-logo padding-bottom-m padding-top-xs"
      >
        {{ counterValue }}
        <span class="nospace">
          <span class="typo-w-4">M</span>
          <span class="typo-w-4"><sup>2</sup></span>
        </span>
      </div>
    </div>
    <ratio-size-box
      class="WaPm9SaveTheInsectsMapPointer"
      :data-width-ratio-component="$viewportWidth < 768 ? 4 : 16"
      :data-height-ratio-component="$viewportWidth < 768 ? 3 : 9"
      data-vertical-align-content="center"
    >
      <div ref="$gmap" class="gmap" />
      <js-loader
        data-async="true"
        data-defer="true"
        :data-id="dataKey"
        :data-src="`https://maps.googleapis.com/maps/api/js?key=${dataKey}&language=${dataLanguage}`"
      ></js-loader>
    </ratio-size-box>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
/*
  At the moment the map will not be configurable but we defined a set of props
  to help future development. The handling of markers and alt should be changed in future
  <wa-map-counter
    class="margin-top-xxl margin-bottom-xxl "
    data-key="AIzaSyDhchFFi7mMeRnu1K6sL3c6WhC1NhZV_pU" //gmap key internal for development
    data-marker-icon-url="{% static 'project/img/flower_pin.png' %}"
    data-marker-size="24,24"
    data-marker-scaled-size="24,24"
    data-marker-anchor="12,12"
    data-language="it"
  >
    <h3 slot="title" class="typo-w-4 text-center text-color-natural padding-bottom-r">Wo WIR ES BLÜHEN LASSEN</h3>
    <h4 slot="subtitle" class="typo-a-6 typo-light text-color-black text-center padding-bottom-xl">Der Fokus unserer Aktion liegt darauf, lokale kosysteme zu stärken.Deshalb kannst du dir hier ganz genau anschauen wo in Deutschland wir es blühen lassen.</h4>
    <span slot="counter-label" class="typo-a-8 typo-extra-bold text-center text-color-grey-8">Angelegte blühfläche</span>
  </wa-map-counter>
*/

import MapWidgetPopupMixin from '@/mixins/map_widget_popup_mixin';
import axios from 'axios';

export default {
  name: 'WaPm9SaveTheInsectsMapPointer',
  mixins: [MapWidgetPopupMixin],
  props: {
    dataCenter: {
      type: String,
      default: '50.110924,8.682127',
    },
    dataMarkerIconUrl: {
      type: String,
      required: true,
    },
    dataMarkerSize: {
      type: String,
      required: true,
    },
    dataMarkerScaledSize: {
      type: String,
      required: true,
    },
    dataMarkerAnchor: {
      type: String,
      required: true,
    },
    dataLanguage: {
      type: String,
      default: 'en',
    },
    dataKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      zoom: 6,
      counterValue: 0,
    };
  },
  computed: {
    ...mapState('pm9SaveTheInsects', ['list-map-pois-api']),
    mapCenter() {
      const [lat, lng] = this.dataCenter.split(',');
      return { lat: +lat, lng: +lng };
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    setupMapApi() {
      axios
        .get(
          this['list-map-pois-api']
          // 'https://run.mocky.io/v3/c3522aea-08f5-4402-b560-92f88b13c800'
        )
        .then((response) => {
          this.counterValue = parseInt(response.data.data.square_meters, 10);
          response.data.data.poi.forEach((p) => {
            this.setMarker(p.latitude, p.longitude);
          });
        });
    },
    initMap() {
      (() => {
        let doCheck = async () => {
          if (window.google) {
            this.map = new window.google.maps.Map(this.$refs.$gmap, {
              center: this.mapCenter,
              zoom: +this.zoom /*,
              mapTypeId: this.dataMaptype*/,
            });
            this.setupMapApi();
            //this.setMarker();
            //this.createPopupClass();
          } else {
            setTimeout(doCheck, 50);
          }
        };
        doCheck();
      })();
    },
    setMarker(lat, lng) {
      //console.log(this)
      //console.log('window.google.maps.SymbolPath.CIRCLE',window.google.maps.SymbolPath.CIRCLE)
      // const pinColor = "FE7569";
      // const pinImage = new google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + pinColor,
      //     new google.maps.Size(21, 34),
      //     new google.maps.Point(0,0),
      //     new google.maps.Point(10, 34));
      // const pinShadow = new google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_shadow",
      //     new google.maps.Size(40, 37),
      //     new google.maps.Point(0, 0),
      //     new google.maps.Point(12, 35));
      //https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png
      const image = {
        url: this.dataMarkerIconUrl,
        size: new window.google.maps.Size(
          this.dataMarkerSize.split(',')[0],
          this.dataMarkerSize.split(',')[1]
        ),
        scaledSize: new window.google.maps.Size(
          this.dataMarkerScaledSize.split(',')[0],
          this.dataMarkerScaledSize.split(',')[1]
        ),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new window.google.maps.Point(
          this.dataMarkerAnchor.split(',')[0],
          this.dataMarkerAnchor.split(',')[1]
        ),
      };
      const marker = new window.google.maps.Marker({
        position: { lat: +lat, lng: +lng },
        map: this.map,
        clickable: false,
        icon: image,
        //shadow: pinShadow
        //icon: {
        //  path: window.google.maps.SymbolPath.CIRCLE,
        //  scale:10,
        //  strokeColor:'#002F6C',
        //  fillColor: '#002F6C',
        //},
        //
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~$scss/utility';
.WaPm9SaveTheInsectsMapPointer {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }
  .nospace {
    font-size: 0;
    sup {
      top: -1.1em;
      font-size: 50%;
    }
  }
  .subtitle {
    max-width: 846px;
    margin: 0 auto;
    width: 90%;
  }

  // .counterLabel {
  //   .counterValue {
  //     display: block;
  //     .squareMetersTwo {
  //       top: -25px;
  //       right: -3px;
  //     }
  //   }
  // }

  .WaPm9SaveTheInsectsMapPointer {
    &.RatioSizeBox {
      > .gmap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
