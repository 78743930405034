<template>
  <wa-side-backgrounds
    class="padding-top-xxl padding-bottom-xxl custom-position-bg"
    :data-background-right="
      require('@images/promo-ce-tt/thank-you-bg-right-tt.png')
    "
    :data-background-left="
      require('@images/promo-ce-tt/thank-you-bg-left-tt.png')
    "
    data-width-right="302"
    data-height-right="400"
    data-width-left="302"
    data-height-left="400"
  >
    <div class="WaPm9LeisureAndFunTtThankYou">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-sea-salt-blue"
          >
            <svg class="icon margin-bottom-r">
              <use href="#icon-email-send" fill="currentColor" />
            </svg>
            <div
              class="title typo-w-4 typo-bold text-center margin-bottom-r noMBLastChild"
              v-html="contentData.thank_you_title"
            />
            <p
              class="content typo-a-7 typo-light text-center text-color-black margin-bottom-m"
            >
              {{ contentData.thank_you_description }}
            </p>
            <wa-cta class="primary" :data-href="home_page_url">
              {{ contentData.thank_you_cta }}
            </wa-cta>
          </div>
        </div>
      </div>
    </div>
  </wa-side-backgrounds>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// import { debug } from '@helpers/utils';

export default {
  name: 'WaPm9LeisureAndFunTtThankYou',
  computed: {
    ...mapState('config', ['home_page_url']),
    ...mapState('pm9LeisureAndFunTt', [
      'ch-key-pm9-leisure-and-fun-tt',
      'contentData',
    ]),
  },
  created() {
    this.setInvertedHeader(false);
  },
  mounted() {
    this.$nextTick(() => {
      this.pushGenericCh(this['ch-key-pm9-leisure-and-fun-tt']);
      // TODO UPDATE CODE WHEN WE HAVE CORRECT INSTRUCTION
      // try {
      //  window.dataLayer.push({event: 'TYP_leisure-and-fun'});
      // } catch (error) {
      //  debug && console.log('%c[CANNOT PUSH ON DATA LAYER]', 'padding:4px;background-color:red;color:white;');
      //  debug && console.error(error);
      // }
    });
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    ...mapActions('gigya', ['pushGenericCh']),
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaPm9LeisureAndFunTtThankYou {
  .icon {
    width: 48px;
    height: 48px;
  }
}
.WaSideBackgrounds.custom-position-bg::v-deep {
  .leftBg,
  .rightBg {
    top: 66%;
  }
  .leftBg {
    right: 60%;
    left: unset;
  }
  .rightBg {
    left: 60%;
    right: unset;
  }
}
</style>
