import * as types from '../mutation_types';

export default {
  setPmextraInstagramInitData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        Object.keys(payload).forEach((k) => {
          commit(types.SET_PMEXTRA_INSTAGRAM_INIT_DATA, {
            key: k,
            value: payload[k],
          });
        });
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  async setPmextraInstagramI18nParticipate({ commit }, obj) {
    commit(types.SET_PMEXTRA_INSTAGRAM_I18N_PARTICIPATE, obj);
  },
  async setPmextraInstagramI18nPrize({ commit }, obj) {
    commit(types.SET_PMEXTRA_INSTAGRAM_I18N_PRIZE, obj);
  },
};
