import Vue from 'vue';
import MapPopup from '@/components/map_popup';

// Note: this way of creating constructors DOES NOT imply inheriting the same configuration of Vue root instance!
// Therefore, dependencies such as store and router must be manually injected at construction time
const MapPopupClass = Vue.extend(MapPopup);

const MapWidgetPopupMixin = {
  data() {
    return {
      Popup: null,
      popupInstance: null,
      popupComponent: null,
    };
  },
  methods: {
    createPopupClass() {
      // NOTE: call this only after Google API are loaded
      /**
       * A customized popup on the map.
       * @param {!google.maps.LatLng} position
       * @param {!Element} content
       * @constructor
       * @extends {google.maps.OverlayView}
       */
      const Popup = function (position, content) {
        this.position = position;
        this.anchor = content;
        this.stopEventPropagation();
      };

      Popup.prototype = Object.create(window.google.maps.OverlayView.prototype);

      /** Called when the popup is added to the map. */
      Popup.prototype.onAdd = function () {
        this.getPanes().floatPane.appendChild(this.anchor);
      };

      /** Called when the popup is removed from the map. */
      Popup.prototype.onRemove = function () {
        if (this.anchor.parentElement) {
          this.anchor.parentElement.removeChild(this.anchor);
        }
      };

      /** Called when the popup needs to draw itself. */
      Popup.prototype.draw = function () {
        let divPosition = this.getProjection().fromLatLngToDivPixel(
          this.position
        );
        // Hide the popup when it is far out of view.
        let display =
          Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
            ? 'block'
            : 'none';

        if (display === 'block') {
          this.anchor.style.left = divPosition.x + 'px';
          this.anchor.style.top = divPosition.y + 'px';
        }
        if (this.anchor.style.display !== display) {
          this.anchor.style.display = display;
        }
      };

      /** Stops clicks/drags from bubbling up to the map. */
      Popup.prototype.stopEventPropagation = function () {
        let anchor = this.anchor;
        anchor.style.cursor = 'auto';

        [
          'click',
          'dblclick',
          'contextmenu',
          'wheel',
          'mousedown',
          'touchstart',
          'pointerdown',
        ].forEach(function (event) {
          anchor.addEventListener(event, function (e) {
            e.stopPropagation();
          });
        });
      };

      this.Popup = Popup;
    },
    createPopup(position, data) {
      // Mount new popup component
      let me = this;
      this.popupComponent = new MapPopupClass({
        propsData: { marker: data },
        store: this.$store,
        created() {
          this.$off('close-popup').$on('close-popup', this.close);
        },
        methods: {
          close() {
            me.closePopup();
          },
        },
      });
      this.popupComponent.$mount();
      this.popupInstance = new this.Popup(position, this.popupComponent.$el);
    },
    openPopup() {
      this.popupInstance.setMap(this.map);
    },
    closePopup() {
      this.popupInstance && this.popupInstance.setMap(null);
    },
    destroyPopup() {
      this.popupInstance = null;
      this.popupComponent && this.popupComponent.$destroy();
    },
    refreshPopup(position, data) {
      // Just a little helper to chain close, destroy, create and open
      this.closePopup();
      this.destroyPopup();
      this.createPopup(position, data);
      this.openPopup();
    },
  },
};

export default MapWidgetPopupMixin;
