import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    active: false,
    version: 2020,
    dataCenter: null,
    apiKey: null,
    lang: null,
    loginApi: '',
    logoutApi: '',
    goTo: null,
    loaded: false,
    user: null,
    preferences: null,
    otActiveGroups: '',
    hashGuidApi: '',
    gigya_profile_cta_label: '',
    gigya_privacy_cta_label: '',
    edit_profile_label: '',
    logout_label: '',
    screensData: [],
    currentScreen: '',
    gigya_login_page_url: '',
    gigya_profile_page_url: '',
    phoneNumber: '',
  },
  actions,
  mutations,
  getters,
};
