<template>
  <section
    v-if="storeSetupCompleted"
    :class="['WaContactForm', currentStep ? currentStep.slug : 'home']"
  >
    <div class="views" :style="appWrapperStyle">
      <transition
        v-for="v in views"
        :key="`${v.name}-${_uid}`"
        name="view"
        mode="out-in"
      >
        <router-view
          :name="v.name"
          :class="[
            'view',
            v.name,
            { active: currentStep ? v.name === currentStep.name : false },
          ]"
        />
      </transition>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'WaContactForm',
  props: {
    dataContactForm: {
      type: [Object, String],
      required: true,
    },
    dataFieldsInfos: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    ...mapState(['storeSetupCompleted']),
    ...mapState('config', ['apis']),
    ...mapState('contact', ['views', 'currentStep', 'height']),
    appWrapperStyle() {
      return {
        height: `${this.height}px`,
      };
    },
    contactForm() {
      return this.$_cmsSupport_castPropToType(this.dataContactForm, 'object');
    },
    fieldsInfos() {
      return this.$_cmsSupport_castPropToType(this.dataFieldsInfos, 'object');
    },
  },
  watch: {
    storeSetupCompleted: {
      async handler(newVal, oldVal) {
        if (newVal === true) {
          this.setSelectsApi(this.apis);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.setContentData(this.contactForm);
    this.setFieldsInfos(this.fieldsInfos);
  },
  methods: {
    ...mapActions('contact', [
      'setSelectsApi',
      'setHomeInfo',
      'setContentData',
      'setFieldsInfos',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaContactForm {
  padding: 200px 0;

  /deep/ .WaSelect {
    display: inline-block;
  }

  .view-enter-active,
  .view-leave-active {
    transition: opacity 0.5s ease;
  }
  .view-enter, .view-leave-to
      /* .component-fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .views {
    position: relative;
    transition: height 0.2s ease-in-out;

    .view {
      position: absolute;
      top: 0;
      /* left: 50%; */
      left: 0;
      width: 100%;
      min-height: 75px;
      padding-top: 50px;
      border-radius: 10px 10px 0 0;
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out,
        background 0.5s ease-in-out;

      /* Gestione slide con ombra
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 75px;
          border: 1px solid darken($color-white, 2%);
          border-bottom: 0;
          border-radius: 10px 10px 0 0;
        }
        */

      &:nth-last-child(5) {
        z-index: 1;
        /* transform: translate(-50%, -70px) scaleX(0.75);
          background: darken($color-white, 4%);
          background: linear-gradient(180deg, darken($color-white, 4%) 0%, rgba($color-white, 0) 100%); */
      }

      &:nth-last-child(4) {
        z-index: 2;
        /* transform: translate(-50%, -55px) scaleX(0.8);
          background: darken($color-white, 3%);
          background: linear-gradient(180deg, darken($color-white, 3%) 0%, rgba($color-white, 0) 100%); */
      }

      &:nth-last-child(3) {
        z-index: 3;
        /* transform: translate(-50%, -37.5px) scaleX(0.85);
          background: darken($color-white, 2%);
          background: linear-gradient(180deg, darken($color-white, 2%) 0%, rgba($color-white, 0) 100%); */
      }

      &:nth-last-child(2) {
        z-index: 4;
        /* transform: translate(-50%, -20px) scaleX(0.9);
          background: darken($color-white, 1%);
          background: linear-gradient(180deg, darken($color-white, 1%) 0%, rgba($color-white, 0) 100%); */
      }

      &:nth-last-child(1) {
        z-index: 5;
        /* transform: translate(-50%, 0px) scaleX(1); */
        background: $color-white;

        /* &::before {
            transform: scaleX(0.95);
          } */
      }

      &.home {
        position: relative;
        top: auto;
        left: auto;
        transform: none;

        /* &::before {
            content: none;
          } */
      }
    }
  }

  /deep/ svg.info {
    color: $color-blue-logo;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
  }

  /deep/ .phone-container {
    display: flex;

    .phone-prefix {
      max-width: 115px;
      flex-grow: 0;

      .frame,
      select {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0 !important;
      }
    }

    .phone-number {
      width: auto;
      flex-grow: 1;

      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
</style>
