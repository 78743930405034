<template>
  <span :class="['WaVerticalName typo-a-9 typo-medium', dataVertical]">
    <span class="inner">
      <slot />
    </span>
  </span>
</template>

<script>
export default {
  name: 'VerticalName',
  props: {
    dataVertical: {
      type: String,
      default: 'position-top',
    },
  },
  watch: {
    $currentStep: {
      handler() {
        this.updatePosition();
      },
    },
    dataVertical: {
      handler() {
        this.updatePosition();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.updatePosition();
    });
  },
  methods: {
    updatePosition() {
      const container =
        this.$parent.$el.querySelector('.container') ||
        this.$parent.$el.querySelector('.container-fluid');
      const rt = parseInt(
        window.getComputedStyle(container).paddingRight.split('px')[0],
        10
      );
      const height = parseInt(
        window.getComputedStyle(this.$el).height.split('px')[0],
        10
      );

      if (
        container.style.position === 'static' ||
        container.style.position === ''
      ) {
        container.style.position = 'relative';
      }

      if (this.dataVertical === 'position-top') {
        //let offset = parseInt(window.getComputedStyle(this.$parent.$el).paddingTop.split('px')[0],10);
        let offset = 0;
        this.$el.style.right = rt + 'px';
        this.$el.style.bottom = 'auto';
        this.$el.style.top = offset + 80 + 'px';
      } else {
        //let offset = parseInt(window.getComputedStyle(this.$parent.$el).paddingBottom.split('px')[0],10);
        let offset = 0;
        this.$el.style.right = rt + height + 'px';
        this.$el.style.top = 'auto';
        this.$el.style.bottom = offset + 80 + 'px';
      }
    },
  },
};
</script>

<style lang="scss">
@import '~$scss/_variables';

.WaVerticalName {
  display: none;
  position: absolute;
  .inner {
    position: relative;
    display: block;
    width: 100%;
    text-transform: uppercase;
    &::before {
      border-top-width: 1px;
      border-top-style: solid;
      height: 1px;
      width: 24px;
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.position-top {
    transform-origin: left top;
    transform: translate(100%) rotate(90deg);
    .inner {
      padding-left: 39px;
      &::before {
        left: 0;
      }
    }
  }
  &.position-bottom {
    transform-origin: right bottom;
    transform: rotate(90deg);
    .inner {
      padding-right: 39px;
      &::before {
        right: 0;
      }
    }
  }

  @media (min-width: $bootstrap-md) {
    display: inline-block;
  }
}
</style>
