<template>
  <div class="WaTextModuleCta">
    <div class="container">
      <div class="row justify-content-center">
        <div v-if="hasSlotSmallTitle" class="col-12 noMBLastChild">
          <!--Typo classes: typo-heavy typo-a-7-->
          <slot name="small-title" />
        </div>
        <div
          v-if="hasSlotTitle"
          class="col-12 col-sm-8 noMBLastChild padding-top-s"
        >
          <!--Typo classes: typo-bold typo-w-3-->
          <slot name="title" />
        </div>
        <div
          v-if="hasSlotWysiwyg"
          class="col-12 col-sm-8 noMBLastChild padding-top-r"
        >
          <!--Typo classes: typo-light typo-6-->
          <slot name="wysiwyg" />
        </div>
        <div
          v-if="hasSlotCtasButtons"
          class="col-12 ctas padding-top-r noMBLastChild"
        >
          <slot name="ctas-buttons" />
        </div>
        <div
          v-if="hasSlotCtasLinks"
          class="col-12 ctas padding-top-r noMBLastChild"
        >
          <slot name="ctas-links" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaTextModuleCta',
  computed: {
    hasSlotSmallTitle() {
      return !!(this.$slots['small-title'] || [])[0];
    },
    hasSlotTitle() {
      return !!(this.$slots['title'] || [])[0];
    },
    hasSlotWysiwyg() {
      return !!(this.$slots['wysiwyg'] || [])[0];
    },
    hasSlotCtasButtons() {
      return !!(this.$slots['ctas-buttons'] || [])[0];
    },
    hasSlotCtasLinks() {
      return !!(this.$slots['ctas-links'] || [])[0];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables.scss';

.WaTextModuleCta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .title {
    /*  even empty its usefukl for debugging */
    /*
    font-family: $font-secondary;
    padding-top: 8px;
    padding-bottom: 16px;

    @media (min-width: $bootstrap-sm) {
      padding-top: 12px;
      padding-bottom: 24px;
    }

    @media (min-width: $bootstrap-md) {
      padding-top: 16px;
      padding-bottom: 32px;
    }
    */
  }

  .smallTitle {
    /*  even empty its usefukl for debugging */
  }

  .wysiwyg {
    /*  even empty its usefukl for debugging */

    /*padding-bottom: 24px;

    @media (min-width: $bootstrap-md) {
      padding-bottom: 32px;
    }
    */
  }

  .ctas {
    text-align: center;
    .WaCta.buttonComponent + .WaCta.buttonComponent,
    .WaCta.linkComponent + .WaCta.linkComponent {
      margin-top: 24px;
      @media (min-width: $bootstrap-sm) {
        margin-top: 0;
        margin-left: 24px;
      }

      @media (min-width: $bootstrap-md) {
        margin-top: 0;
        margin-left: 32px;
      }
    }
  }
}
</style>
