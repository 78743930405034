<template functional>
  <div
    :class="[
      'WaMediaImage container-fluid',
      data.class,
      data.staticClass,
      props.dataVariant,
    ]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <div class="row justify-content-center">
      <template v-if="props.dataVariant === 'full'">
        <div class="col-sm-12 col-md-10">
          <ratio-size-box
            data-width-ratio-component="16"
            data-height-ratio-component="9"
            data-vertical-align-content="center"
            data-crop="cover"
          >
            <slot />
          </ratio-size-box>
        </div>
      </template>
      <template v-else-if="props.dataVariant === 'badge'">
        <div class="col-sm-12 col-md-10">
          <slot />
        </div>
      </template>
      <template v-else-if="props.dataVariant === 'badge-l'">
        <div class="col-sm-12 col-md-10">
          <slot />
        </div>
      </template>
      <template v-else-if="props.dataVariant === 'inAccordion'">
        <div class="col-12 col-sm-8">
          <ratio-size-box
            data-width-ratio-component="4"
            data-height-ratio-component="3"
            data-vertical-align-content="center"
            data-crop="cover"
          >
            <slot />
          </ratio-size-box>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaMediaImage',
  functional: true,
  props: {
    dataVariant: {
      type: String,
      default: 'full',
    },
    dataIsInAccordion: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaMediaImage {
  // BADGE VARIANT
  @include media-breakpoint-down(xs) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    > .row {
      margin-left: 0;
      margin-right: 0;

      [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  &.badge {
    text-align: center;

    // this variant has specified dimensions
    /deep/ img {
      width: 64px;
      height: 64px;

      @media (min-width: $bootstrap-sm) {
        width: 80px;
        height: 80px;
      }

      @media (min-width: $bootstrap-md) {
        width: 120px;
        height: 120px;
      }
    }
  }

  &.badge-l {
    text-align: center;

    // this variant has specified dimensions
    ::v-deep img {
      width: 160px;
      height: 80px;

      @media (min-width: $bootstrap-sm) {
        width: 240px;
        height: 120px;
      }

      @media (min-width: $bootstrap-md) {
        width: 300px;
        height: 150px;
      }
    }
  }
}
</style>
