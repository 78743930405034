<template>
  <a
    :href="recipe.recipe_href"
    :class="['WaRecipeCard', 'cardLink', { hasTag: isVisibleTag }]"
  >
    <!--
      Variants:
      - ratio-4-3 (default) set the aspect ratio at 4:3
      - ratio-16-9 set the aspect ratio at 16:9

      Support Classes:
      - 'hasTag' set the component tag visible
    -->

    <figure class="imgWrapper">
      <!-- Component Tag if prop label is full -->
      <span v-if="isVisibleTag" class="cardLabel">
        <wa-tag
          :class="[
            `text-color-${conf.label_color}`,
            `bg-color-${conf.label_background_color}`,
          ]"
        >
          {{ conf.label }}
        </wa-tag>
      </span>
      <ratio-size-box
        :data-width-ratio-component="alternativeRatio ? 16 : 4"
        :data-height-ratio-component="alternativeRatio ? 9 : 3"
        data-vertical-align-content="center"
        data-crop="cover"
      >
        <img
          class="pic imageHover"
          :src="recipe.recipe_card_src"
          :alt="recipe.recipe_card_alt"
        />
      </ratio-size-box>
    </figure>

    <div class="contentCard">
      <h3
        :class="[
          'recipeTitle',
          'typo-a-5',
          'margin-top-s',
          recipe.recipe_title_color,
        ]"
      >
        <!-- Title text  -->
        {{ recipe.recipe_title }}
      </h3>

      <!-- Meal Type  -->
      <span v-if="recipe.meal_type" class="mealType margin-top-xs">
        {{ recipe.meal_type.map((m) => m.dataValue).join(' - ') }}
      </span>
    </div>
  </a>
</template>

<script>
export default {
  name: 'WaRecipeCard',
  props: {
    dataConf: {
      type: [Object, String],
      required: true,
    },
    dataAlternativeRatio: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
  },
  computed: {
    alternativeRatio() {
      return this.$_cmsSupport_castPropToType(
        this.dataAlternativeRatio,
        'boolean'
      );
    },
    isVisibleTag() {
      return this.conf.has_tag;
    },
    conf() {
      return this.$_cmsSupport_castPropToType(this.dataConf, 'object');
    },
    recipe() {
      return {
        recipe_href: this.conf.href,
        recipe_card_src: this.conf.img_src,
        recipe_card_alt: this.conf.img_alt,
        recipe_title: this.conf.title,
        meal_type: this.conf.meal_types,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaRecipeCard {
  display: block;
  .imgWrapper {
    position: relative;
  }
  .imageHover {
    transition: all 0.4s ease;
  }

  &.cardLink {
    text-decoration: none;
    color: $color-black;
    border-radius: 4px;

    &:hover {
      text-decoration: none;
      cursor: pointer;

      .imageHover {
        transform: scale(1.1);
        border-radius: 4px;
      }
    }
  }

  /deep/.RatioSizeBox {
    border-radius: 4px;
  }

  .contentCard {
    .recipeTitle {
      display: block;
      font-weight: $fh-medium;
    }

    .mealType {
      color: $color-grey-8;
      font-size: 16px;
    }
  }

  //Variant
  //show component tag
  &.hasTag {
    .cardLabel {
      display: block;
      position: absolute;
      right: 8px;
      top: 8px;
      z-index: 1;

      @media (min-width: $bootstrap-sm) {
        right: 12px;
        top: 12px;
      }

      @media (min-width: $bootstrap-md) {
        right: 16px;
        top: 16px;
      }
    }
  }
}
</style>
