<template>
  <div>
    <form ref="$form" class="WaContactFormRecontact" @submit.prevent>
      <div class="row justify-content-center no-gutters">
        <div class="col-12 col-md-8">
          <div class="row">
            <div
              v-if="
                formConfig &&
                formConfig.first_name &&
                formConfig.first_name.is_visible
              "
              class="col-12 col-md-6 padding-bottom-r"
              :class="hasUserFirstName ? ['d-flex align-items-center'] : null"
            >
              <svg
                v-if="hasUserFirstName"
                v-tooltip.auto="{
                  content: loggedDataTooltip,
                  autoHide: true,
                  hideOnTargetClick: true,
                  trigger: 'hover click',
                }"
                class="info"
              >
                <use href="#icon-info-circle" fill="currentColor" />
              </svg>
              <wa-input
                v-model.trim="$v.first_name.$model"
                :data-label="
                  dataLabel(
                    'first_name',
                    contentData.form_input_text_name_label
                  )
                "
                data-name="first_name"
                :data-disabled="hasUserFirstName"
                :data-error-occurred="
                  !hasUserFirstName ? $v.first_name.$error : null
                "
                :data-errors="
                  !hasUserFirstName
                    ? dataErrors(
                        'first_name',
                        {
                          required:
                            contentData.form_input_text_name_error_required,
                          regex: contentData.form_input_text_name_error_regex,
                        },
                        [
                          {
                            condition: !$v.first_name.minLength,
                            message:
                              contentData.form_input_text_name_error_minLength,
                          },
                          {
                            condition: !$v.first_name.maxLength,
                            message:
                              contentData.form_input_text_name_error_maxLength,
                          },
                        ]
                      )
                    : null
                "
                @input="onFieldChange('first_name', $event)"
              />
            </div>
            <div
              v-if="
                formConfig &&
                formConfig.last_name &&
                formConfig.last_name.is_visible
              "
              class="col-12 col-md-6 padding-bottom-r"
              :class="hasUserLastName ? ['d-flex align-items-center'] : null"
            >
              <svg
                v-if="hasUserLastName"
                v-tooltip.auto="{
                  content: loggedDataTooltip,
                  autoHide: true,
                  hideOnTargetClick: true,
                  trigger: 'hover click',
                }"
                class="info"
              >
                <use href="#icon-info-circle" fill="currentColor" />
              </svg>
              <wa-input
                v-model.trim="$v.last_name.$model"
                :data-label="
                  dataLabel(
                    'last_name',
                    contentData.form_input_text_surname_label
                  )
                "
                data-name="last_name"
                :data-disabled="hasUserLastName"
                :data-error-occurred="
                  !hasUserLastName ? $v.last_name.$error : null
                "
                :data-errors="
                  !hasUserLastName
                    ? dataErrors(
                        'last_name',
                        {
                          required:
                            contentData.form_input_text_surname_error_required,
                          regex:
                            contentData.form_input_text_surname_error_regex,
                        },
                        [
                          {
                            condition: !$v.last_name.minLength,
                            message:
                              contentData.form_input_text_surname_error_minLength,
                          },
                          {
                            condition: !$v.last_name.maxLength,
                            message:
                              contentData.form_input_text_surname_error_maxLength,
                          },
                        ]
                      )
                    : null
                "
                @input="onFieldChange('last_name', $event)"
              />
            </div>
            <div
              v-if="
                formConfig && formConfig.email && formConfig.email.is_visible
              "
              class="col-12 col-md-6 padding-bottom-r"
              :class="hasUserEmail ? ['d-flex align-items-center'] : null"
            >
              <svg
                v-if="hasUserEmail"
                v-tooltip.auto="{
                  content: loggedDataTooltip,
                  autoHide: true,
                  hideOnTargetClick: true,
                  trigger: 'hover click',
                }"
                class="info"
              >
                <use href="#icon-info-circle" fill="currentColor" />
              </svg>
              <wa-input
                v-model.trim="$v.email.$model"
                :data-label="
                  dataLabel('email', contentData.form_input_text_email_label)
                "
                data-name="email"
                :data-disabled="hasUserEmail"
                :data-error-occurred="!hasUserEmail ? $v.email.$error : null"
                :data-errors="
                  !hasUserEmail
                    ? dataErrors(
                        'email',
                        {
                          required:
                            contentData.form_input_text_email_error_required,
                        },
                        [
                          {
                            condition: !$v.email.email,
                            message:
                              contentData.form_input_text_email_error_format,
                          },
                        ]
                      )
                    : null
                "
                @input="onFieldChange('email', $event)"
              />
            </div>
            <div
              v-if="
                formConfig &&
                formConfig.phone_number &&
                formConfig.phone_number.is_visible
              "
              class="col-12 col-md-6 padding-bottom-r phone-container"
            >
              <wa-dropdown
                v-model.trim="$v.phone_prefix.$model"
                data-name="phone_prefix"
                :data-label="contentData.form_select_prefix_placeholder"
                class="phone-prefix"
                :data-alternative-selected-value="true"
                :data-option-list="prefixesList"
                @change="onPhoneChange('phone_prefix', $event)"
              />
              <wa-input
                v-model.trim="$v.phone_number.$model"
                :data-label="
                  dataLabel(
                    'phone_number',
                    contentData.form_input_text_phone_label
                  )
                "
                data-name="phone_number"
                class="phone-number"
                :data-error-occurred="$v.phone_number.$error"
                :data-errors="
                  dataErrors(
                    'phone_number',
                    {
                      required:
                        contentData.form_input_text_phone_error_required,
                      regex: contentData.form_input_text_phone_error_regex,
                    },
                    [
                      {
                        condition: !$v.phone_number.minLength,
                        message:
                          contentData.form_input_text_phone_error_minLength,
                      },
                      {
                        condition: !$v.phone_number.maxLength,
                        message:
                          contentData.form_input_text_phone_error_maxLength,
                      },
                    ]
                  )
                "
                @input="onPhoneChange('phone_number', $event)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center no-gutters">
        <div class="col-12 col-md-8">
          <div class="row">
            <div
              v-if="
                formConfig &&
                formConfig.country &&
                formConfig.country.is_visible
              "
              class="col-12 col-md-6 padding-bottom-r"
            >
              <wa-dropdown
                v-model.trim="$v.country.$model"
                data-name="country"
                :data-label="
                  dataLabel('country', contentData.form_select_country_label)
                "
                :data-placeholder="
                  dataLabel(
                    'country',
                    contentData.form_select_country_placeholder
                  )
                "
                :data-option-list="countryList"
                :data-error-occurred="$v.country.$error"
                :data-errors="
                  dataErrors('country', {
                    required: contentData.form_select_country_error_required,
                  })
                "
                @change="onFieldChange('country', $event)"
              />
            </div>
            <!-- <div class="col-12 col-md-8">
              <wa-google-address-autocomplete
                :data-api-key="gmap_key"
                :data-label="contentData.form_google_autocomplete_your_address_label"
                @retrieve-address-component="onRetrieveAddress"
              />
            </div> -->
            <div
              v-if="
                formConfig &&
                formConfig.province &&
                formConfig.province.is_visible
              "
              class="col-12 col-md-6 padding-bottom-r"
            >
              <wa-input
                v-model.trim="$v.province.$model"
                :data-label="
                  dataLabel(
                    'province',
                    contentData.form_input_text_province_label
                  )
                "
                data-name="province"
                :data-error-occurred="$v.province.$error"
                :data-errors="
                  dataErrors(
                    'province',
                    { regex: contentData.form_input_text_province_error_regex },
                    [
                      {
                        condition: !$v.province.minLength,
                        message:
                          contentData.form_input_text_province_error_minLength,
                      },
                      {
                        condition: !$v.province.maxLength,
                        message:
                          contentData.form_input_text_province_error_maxLength,
                      },
                    ]
                  )
                "
                @input="onFieldChange('province', $event)"
              />
            </div>
            <div
              v-if="formConfig && formConfig.city && formConfig.city.is_visible"
              class="col-12 col-md-6 padding-bottom-r"
            >
              <wa-input
                v-model.trim="$v.city.$model"
                :data-label="
                  dataLabel('city', contentData.form_input_text_city_label)
                "
                data-name="city"
                :data-error-occurred="$v.city.$error"
                :data-errors="
                  dataErrors(
                    'city',
                    { regex: contentData.form_input_text_city_error_regex },
                    [
                      {
                        condition: !$v.city.minLength,
                        message:
                          contentData.form_input_text_city_error_minLength,
                      },
                      {
                        condition: !$v.city.maxLength,
                        message:
                          contentData.form_input_text_city_error_maxLength,
                      },
                    ]
                  )
                "
                @input="onFieldChange('city', $event)"
              />
            </div>
            <div
              v-if="formConfig && formConfig.cap && formConfig.cap.is_visible"
              class="col-12 col-md-6 padding-bottom-r"
            >
              <wa-input
                v-model.trim="$v.cap.$model"
                :data-label="
                  dataLabel('cap', contentData.form_input_text_cap_label)
                "
                data-name="cap"
                :data-error-occurred="$v.cap.$error"
                :data-errors="
                  dataErrors('cap', {
                    regex: contentData.form_input_text_cap_error_regex,
                  })
                "
                @input="onFieldChange('cap', $event)"
              />
            </div>
            <div
              v-if="
                formConfig &&
                formConfig.address &&
                formConfig.address.is_visible
              "
              class="col-12 col-md-6 padding-bottom-r"
            >
              <wa-input
                v-model.trim="$v.address.$model"
                :data-label="
                  dataLabel(
                    'address',
                    contentData.form_input_text_address_label
                  )
                "
                data-name="address"
                :data-error-occurred="$v.address.$error"
                :data-errors="
                  dataErrors(
                    'address',
                    { regex: contentData.form_input_text_address_error_regex },
                    [
                      {
                        condition: !$v.address.minLength,
                        message:
                          contentData.form_input_text_address_error_minLength,
                      },
                      {
                        condition: !$v.address.maxLength,
                        message:
                          contentData.form_input_text_address_error_maxLength,
                      },
                    ]
                  )
                "
                @input="onFieldChange('address', $event)"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="formConfig && formConfig.privacy && formConfig.privacy.is_visible"
        class="row justify-content-center"
      >
        <div class="col-12 col-md-8 padding-bottom-r">
          <div
            class="typo-a typo-a-9 typo-light"
            v-html="contentData.form_terms_and_conditions_text"
          />
          <wa-checkbox
            v-model.trim="$v.privacy.$model"
            data-name="privacy"
            :data-checkbox-list="[
              {
                label: dataLabel(
                  'privacy',
                  contentData.form_input_checkbox_privacy_label
                ),
              },
            ]"
            :data-error-occurred="$v.privacy.$error"
            :data-errors="
              formConfig.privacy.required
                ? [
                    {
                      condition: !$v.privacy.mustBeChecked,
                      message:
                        contentData.form_input_checkbox_privacy_error_required,
                    },
                  ]
                : null
            "
            @input="onFieldChange('privacy', $event)"
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 padding-top-xs padding-bottom-r">
          <span class="typo-a-9 typo-light text-color-grey-8">
            {{ contentData.mandatory_field_indication_placeholder }}
          </span>
        </div>
      </div>
      <template
        v-if="
          formConfig && formConfig.recaptcha && formConfig.recaptcha.is_visible
        "
      >
        <div v-if="!isOptional" class="row">
          <div class="col padding-top-xs padding-bottom-m">
            <div class="form-group__wrapper">
              <div class="form-group">
                <div
                  class="form-control__wrapper d-flex align-items-center justify-content-center"
                >
                  <vue-recaptcha
                    :sitekey="recaptcha_key"
                    :load-recaptcha-script="true"
                    @verify="onRecaptchaVerify"
                  />
                  <template v-if="formConfig.recaptcha.required">
                    <div
                      v-if="$v.recaptcha.$dirty && !$v.recaptcha.required"
                      class="form__error"
                    >
                      <span class="form__error--text">
                        {{ contentData.form_recaptcha_error_required }}
                      </span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col text-center">
          <wa-cta
            class="primary"
            data-type="submit"
            :data-icon-id="spinner ? 'icon-ellipsis-loader' : ''"
            :data-disabled="disableSubmit"
            @click.prevent="onSubmit"
          >
            {{ contentData.continue_cta_label }}
          </wa-cta>
        </div>
      </div>
    </form>
    <wa-modal
      v-if="showModal"
      ref="JekoModal"
      :data-click-to-close="false"
      class="padding-top-l padding-bottom-l text-center"
      data-name="jeko-modal"
      :data-has-close-button="false"
      @closed="jekoModalClosed"
    >
      <div
        class="content text-center"
        v-html="contentData.jeko_retry_modal_description_label"
      />
      <div class="ctaWrapper padding-top-l">
        <wa-cta
          v-if="numbersOfAttemps < jekoIntegration['jeko-max-retry']"
          class="primary"
          @click="retryHandler"
        >
          {{ contentData.jeko_retry_modal_button_retry_label }}
        </wa-cta>
        <wa-cta v-else class="primary" @click="sendToCustomerServiceHandler">
          {{
            contentData.jeko_retry_modal_button_send_to_customer_service_label
          }}
        </wa-cta>
        <wa-cta class="secondary" @click="cancelHandler">
          {{ contentData.jeko_retry_modal_button_cancel_label }}
        </wa-cta>
      </div>
    </wa-modal>
  </div>
</template>

<script>
import WaContactFormFieldsUpdate from '@mixins/wa-contact-form-fields-update';
import LanguageSupport from '@mixins/language-support';
import phonePrefixesFixture from '@fixtures/phone-prefixes';
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  requiredUnless,
} from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import axios from 'axios';

export default {
  name: 'WaContactFormRecontact',
  components: {
    VueRecaptcha,
  },
  mixins: [WaContactFormFieldsUpdate, LanguageSupport],
  data() {
    return {
      showModal: false,
      numbersOfAttemps: 1,
      hasUserFirstName: false,
      hasUserLastName: false,
      hasUserEmail: false,
      spinner: false,
      /* form field */
      first_name: null,
      last_name: null,
      email: null,
      phone_number: null,
      privacy: false,
      recaptcha: undefined,
      country: null,
      countryList: [],
      province: null,
      city: null,
      cap: null,
      address: null,
      fields: [
        'first_name',
        'last_name',
        'email',
        'phone_number',
        'country',
        'province',
        'city',
        'cap',
        'address',
        'privacy',
        'recaptcha',
      ],
      prefixesList: null,
      phone_prefix: null,
    };
  },
  computed: {
    ...mapState('gigya', ['user']),
    ...mapState('contact', [
      'currentStep',
      'formData',
      'selectsApi',
      'contentData',
      'fieldsInfos',
      'jekoIntegration',
    ]),
    ...mapState('config', ['gmap_key', 'recaptcha_key']),
    loggedDataTooltip() {
      return (
        '<p style="font-size: 1rem; line-height: 1; margin: 0; padding: 10px 5px;">' +
        this.contentData.form_tooltip_personal_area_text +
        '</p>'
      );
    },
    isOptional() {
      return this.$store.state.config.hideRecaptcha;
    },
  },
  validations() {
    let validations = {};
    this.fields.forEach((prop) => {
      //console.log(', ,');
      if (
        this.formConfig &&
        this.formConfig[prop] &&
        this.formConfig[prop].is_visible
      ) {
        validations[prop] = {};
      }
    });

    Object.keys(validations).forEach((prop) => {
      if (this.formConfig[prop].required) {
        if (prop === 'privacy') {
          validations.privacy = {
            ...validations.privacy,
            mustBeChecked: (val) => !!val,
          };
        } else if (prop === 'recaptcha') {
          validations.recaptcha = {
            ...validations.recaptcha,
            required: requiredUnless('isOptional'),
          };
        } else {
          let flag = false;

          if (
            prop === 'first_name' ||
            prop === 'last_name' ||
            prop === 'email'
          ) {
            if (prop === 'first_name' && !this.hasUserFirstName) {
              flag = true;
            }
            if (prop === 'last_name' && !this.hasUserLastName) {
              flag = true;
            }
            if (prop === 'email' && !this.hasUserEmail) {
              flag = true;
            }
          } else {
            flag = true;
          }

          if (flag === true) {
            validations[prop] = {
              ...validations[prop],
              required,
            };
          }
        }
      }

      if (this.formConfig[prop].regex) {
        let flag = false;

        if (prop === 'first_name' || prop === 'last_name' || prop === 'email') {
          if (prop === 'first_name' && !this.hasUserFirstName) {
            flag = true;
          }
          if (prop === 'last_name' && !this.hasUserLastName) {
            flag = true;
          }
          if (prop === 'email' && !this.hasUserEmail) {
            flag = true;
          }
        } else {
          flag = true;
        }

        if (flag === true) {
          validations[prop] = {
            ...validations[prop],
            regex: (val) =>
              !helpers.req(val) ||
              new RegExp(this.formConfig[prop].regex).test(val),
          };
        }
      }
    });

    //console.log(', , ,');
    if (
      this.formConfig &&
      this.formConfig.phone_number &&
      this.formConfig.phone_number.is_visible
    ) {
      validations.phone_prefix = {};
      validations.phone_number = {
        ...validations.phone_number,
        minLength: minLength(8),
        maxLength: maxLength(18),
      };
    }

    //console.log(', , , ,');
    if (
      this.formConfig &&
      this.formConfig.province &&
      this.formConfig.province.is_visible
    ) {
      validations.province = {
        ...validations.province,
        minLength: minLength(2),
        maxLength: maxLength(60),
      };
    }

    //console.log(', , , , ,');
    if (
      this.formConfig &&
      this.formConfig.city &&
      this.formConfig.city.is_visible
    ) {
      validations.city = {
        ...validations.city,
        minLength: minLength(2),
        maxLength: maxLength(60),
      };
    }

    //console.log(', , , , , ,');
    if (
      this.formConfig &&
      this.formConfig.address &&
      this.formConfig.address.is_visible
    ) {
      validations.address = {
        ...validations.address,
        minLength: minLength(2),
        maxLength: maxLength(120),
      };
    }

    //console.log(', , , , , , ,');
    if (
      this.formConfig &&
      this.formConfig.first_name &&
      this.formConfig.first_name.is_visible
    ) {
      if (!this.hasUserFirstName) {
        validations.first_name = {
          ...validations.first_name,
          minLength: minLength(2),
          maxLength: maxLength(60),
        };
      }
    }

    //console.log(', , , , , , , ,');
    if (
      this.formConfig &&
      this.formConfig.last_name &&
      this.formConfig.last_name.is_visible
    ) {
      if (!this.hasUserLastName) {
        validations.last_name = {
          ...validations.last_name,
          minLength: minLength(2),
          maxLength: maxLength(60),
        };
      }
    }

    //console.log(', , , , , , , , , , ');
    if (
      this.formConfig &&
      this.formConfig.email &&
      this.formConfig.email.is_visible
    ) {
      if (!this.hasUserEmail) {
        validations.email = {
          ...validations.email,
          email,
        };
      }
    }

    return validations;
  },
  mounted() {
    window.cfStepsContent = window.cfStepsContent || [];
    window.cfStepsContent.push('WaContactFormRecontact');
    import('@fixtures/country-fixture').then((response) => {
      this.countryList = response.data;
    });

    if (this.jekoIntegration['jeko-is-active'] === true) {
      this.showModal = true;
    }

    if (this.user) {
      if (this.user.firstName) {
        this.hasUserFirstName = true;
        this.first_name = this.user.firstName;
        this.setFormData({
          first_name: this.user.firstName,
        });
      }

      if (this.user.lastName) {
        this.hasUserLastName = true;
        this.last_name = this.user.lastName;
        this.setFormData({
          last_name: this.user.lastName,
        });
      }

      if (this.user.email) {
        this.hasUserEmail = true;
        this.email = this.user.email;
        this.setFormData({
          email: this.user.email,
        });
      }
    }

    let count = 0;
    this.prefixesList = phonePrefixesFixture.map((e) => {
      count++;
      const prop = Object.keys(e)[0];
      if (count === 1) {
        this.phone_prefix = e[prop];
      }
      if (this.currentCountry === prop) {
        this.phone_prefix = e[prop];
      }

      return {
        key: e[prop],
        dataValue: `${prop}: + ${e[prop]}`,
        dataAlternativeSelectedValue: `+ ${e[prop]}`,
      };
    });

    this.fields.forEach((f) => {
      if (this.formData[f]) {
        this[f] = this.formData[f];
      }
    });

    if (this.$store.state.contact.contentData.form_input_prefix_default) {
      this.setFormData({
        phone_prefix:
          this.$store.state.contact.contentData.form_input_prefix_default,
      });
      this.phone_prefix =
        this.$store.state.contact.contentData.form_input_prefix_default;
      this.$nextTick(() => {
        if (this.$v && this.$v.phone_prefix) {
          this.$v.phone_prefix.$touch();
        }
      });
    }
  },
  methods: {
    sendToCustomerServiceHandler() {
      let formData = this.getFormData();
      formData.set('has_reached_max_retry', true);
      this.onSubmitApi(this, formData);
      this.$modal.hide('jeko-modal');
      this.$router.push({
        name: 'step-5',
        params: { error: false, isJeko: true },
      });
    },
    cancelHandler() {
      window.location.reload();
    },
    retryHandler() {
      this.numbersOfAttemps++;
      this.$modal.hide('jeko-modal');
    },
    jekoModalClosed() {
      if (this.numbersOfAttemps <= this.jekoIntegration['jeko-max-retry']) {
        this.onSubmit();
      } else {
        this.sendToCustomerServiceHandler();
      }
    },
    showJekoModal() {
      this.$modal.show('jeko-modal');
    },
    onRecaptchaVerify(response) {
      this.recaptcha = response;
      this.setFormData({
        recaptcha: this.recaptcha,
      });
    },
    onSubmit() {
      const me = this;
      me.$v.$touch();
      if (!me.$v.$invalid) {
        this.forceDisable = true;
        let formData = this.getFormData();
        this.spinner = true;

        if (this.user) {
          window.gigya.accounts.getAccountInfo({
            include: 'id_token,data,profile',
            callback: async (data) => {
              formData.set('gigya_id', data.UID);
              this.onSubmitApi(me, formData);
            },
          });
        } else {
          formData.set('gigya_id', '');
          this.onSubmitApi(me, formData);
        }
      } else {
        console.log('invalid data');
      }
    },
    onSubmitApi(me, formData) {
      window.cfData = formData;
      axios
        .post(this.selectsApi['submit-contact-form-api'], formData)
        .then(() => {
          me.$emit('submit');
        })
        .catch((e) => {
          this.forceDisable = false;
          let properties = Object.getOwnPropertyNames(e);
          properties.forEach((p) => {
            let prop = Object.getOwnPropertyDescriptor(e, p);
            if (prop && prop.value && prop.value.request) {
              try {
                let response = JSON.parse(prop.value.request.response);
                this.$store.dispatch('contact/setJekoIntegration', {
                  success: response.success,
                  general_errors: response.general_errors || [],
                  file_errors: response.file_errors || [],
                });
              } catch (e) {
                console.warn(e);
              }
            }
          });
          me.$emit('submit-error');
        })
        .finally(() => {
          this.spinner = false;
        });
    },
    getFormData() {
      let obj = new FormData();

      for (let k in this.formData) {
        if (this.formData[k]) {
          obj.set(k, this.formData[k]);
        }
      }

      return obj;
    },
    // onRetrieveAddress(place) {
    //   let ar = [
    //       {
    //         localKey: 'address',
    //         mapsKey: 'route'
    //       },
    //       {
    //         localKey: 'city',
    //         mapsKey: 'administrative_area_level_3'
    //       },
    //       {
    //         localKey: 'district',
    //         mapsKey: 'administrative_area_level_2'
    //       },
    //       {
    //         localKey: 'zipcode',
    //         mapsKey: 'postal_code'
    //       }
    //     ],
    //     mapsKeys = ar.map((el) => el.mapsKey);
    //   if (place) {
    //     place.address_components.forEach((c) => {
    //       c.types.forEach((t) => {
    //         if (mapsKeys.includes(t)) {
    //           let localKey = ar.find((obj) => obj.mapsKey === t).localKey;
    //           this.onFieldChange(localKey, c.long_name);
    //           return;
    //         }
    //       });
    //     });
    //   }
    // }
  },
};
</script>

<style scoped>
.ctaWrapper {
  display: flex;
  justify-content: space-evenly;
}
</style>
