<template>
  <div class="ContactFormGigyaLandingCmp">
    <h3 class="title text-color-secondary-item typo-w typo-w-3 text-center">
      <template v-if="userLoggedIn">{{ routes.gigya_page.texts.title.loggedIn }}</template>
      <template v-else>{{ routes.gigya_page.texts.title.notLoggedIn }}</template>

    </h3>
    <div class="content">
      <p class="text-center">
        <template v-if="userLoggedIn">{{ routes.gigya_page.texts.subtitle.loggedIn }}</template>
        <template v-else>{{ routes.gigya_page.texts.subtitle.notLoggedIn }}</template>
      </p>
      <div class="buttons-container">
        <wa-text-module-cta :key="keyToForceRenderComponentAgain">
          <template v-if="!userLoggedIn" slot="ctas-buttons">
            <wa-cta
              class="secondary"
              data-type="button"
              :data-href="routes.gigya_page.controls.buttons.registerLink.url"
            >
              {{ routes.gigya_page.controls.buttons.registerLink.text }}
            </wa-cta>
            <wa-cta
              class="primary"
              data-type="button"
              :data-href="routes.gigya_page.controls.buttons.loginLink.url"
            >
              {{ routes.gigya_page.controls.buttons.loginLink.text }}
            </wa-cta>
          </template>
          <template slot="ctas-links" class="p-0">
            <router-link
              :to="{
                name: routes.gigya_page.controls.buttons.continueLink.goToName,
              }"
              tag="div"
            >
              <wa-cta
                v-if="!userLoggedIn"
                class="link padding-top-s text-color-blue-logo"
                data-icon-id="icon-arrow-angle-right"
              >
                {{
                  routes.gigya_page.controls.buttons.continueLink.texts
                    .notLoggedIn
                }}
              </wa-cta>
              <wa-cta
                v-else
                class="link padding-top-s text-color-blue-logo"
                data-icon-id="icon-arrow-angle-right"
              >
                {{
                  routes.gigya_page.controls.buttons.continueLink.texts.loggedIn
                }}
              </wa-cta>
            </router-link>
          </template>
        </wa-text-module-cta>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactFormGigyaLandingCmp',
  data() {
    return {
      keyToForceRenderComponentAgain: 0,
    };
  },
  computed: {
    ...mapState('contactForm2024', ['faqs', 'queryString', 'routes']),
    ...mapState('gigya', ['active', 'user', 'gigya_login_page_url']),
    currentPageUrl() {
      return window.location.href;
    },
    userLoggedIn() {
      return !!this.user;
    },
  },
  watch: {
    user: function () {
      this.keyToForceRenderComponentAgain++;
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormGigyaLandingCmp {
  padding-top: var(--contact-form-head-padding-top, 0);
  padding-bottom: var(--contact-form-head-padding-bottom, 0);
}
</style>
