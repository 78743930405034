<template>
  <div class="WaBreadcrumbs">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav v-if="+dataItemsNumber >= 1">
            <ul class="breadcrumbs-nav">
              <li
                v-for="n in +dataItemsNumber"
                :key="`item-${n}-${_uid}`"
                class="item typo-a-9 typo-medium text-color-blue-logo"
              >
                <slot :name="`item-${n}`" />
                <span v-if="n < +dataItemsNumber" class="separator-icon">
                  /
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaBreadcrumbs',
  props: {
    dataItemsNumber: {
      Type: String,
      default: '2',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaBreadcrumbs {
  .breadcrumbs-nav {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 16px 0;

    .item {
      display: flex;
      align-items: center;
      margin: 0;

      .separator-icon {
        flex-shrink: 0;
        margin: 0 8px;
        text-align: center;
      }

      a {
        color: inherit;
      }
    }
  }
}
</style>
