var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ContactFormTicketingCountryCmp"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('wa-dropdown',{attrs:{"data-name":"ticketing_country","data-use-int":false,"data-label":_vm.ticketingCountryLabel,"data-option-list":_vm.countries.items && _vm.countries.items.length
          ? [
              { key: 'GLOBAL', dataValue: '----------' } ].concat( _vm.countries.items.map(function (e) {
                return {
                  key: e.value,
                  dataValue: e.label,
                };
              }) )
          : [{ key: 'GLOBAL', dataValue: '----------' }],"data-error-occurred":_vm.$v.ticketing_country.$error,"data-errors":[
        {
          condition: !_vm.$v.ticketing_country.required,
          message: _vm.routes.ticketing_country.controls.dropdown.requiredError,
        },
        {
          condition: !_vm.$v.ticketing_country.notGlobal,
          message: _vm.routes.ticketing_country.controls.dropdown.requiredError,
        } ]},on:{"change":_vm.onChange},model:{value:(_vm.$v.ticketing_country.$model),callback:function ($$v) {_vm.$set(_vm.$v.ticketing_country, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.ticketing_country.$model"}}),_c('div',{staticClass:"submit-wrapper text-center"},[_c('wa-cta',{staticClass:"primary",attrs:{"data-type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmitForm($event)}}},[_vm._v(" "+_vm._s(_vm.routes.ticketing_country.controls.continueCta.label)+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }