<template>
  <div ref="watabsContainer" class="WaTabs container-fluid">
    <div ref="watabsRow" class="row externalRow">
      <div v-if="$viewportWidth < 768" class="col-12 externalCol">
        <scroll-x-navbar-cmp
          v-if="categories.length"
          ref="horizontalMainMenu"
          :class="[
            'mainCategoriesList horizontalMenu',
            {
              gradientSx: mainScrollerHasGradientLeft,
              gradientDx: mainScrollerHasGradientRight,
            },
          ]"
          nav-identifier-class="scrollXMainMenu"
        >
          <li
            v-for="category in categories"
            :key="`${category.slug}-${_uid}-mobile-scroll`"
            :ref="`${category.slug}-${_uid}-mobile-scroll`"
            @click="
              selectMainCategory(category, true);
              scrollToEl(
                $refs.horizontalMainMenu.$refs.scroller,
                `${category.slug}-${_uid}-mobile-scroll`,
                directionToScrollInViewport(
                  `${category.slug}-${_uid}-mobile-scroll`,
                  100
                )
              );
            "
          >
            <span
              :class="[
                'mainCategory',
                'typo-w-4',
                {
                  selectedMainCategory: selectedCategory.slug === category.slug,
                },
              ]"
            >
              {{ category.label }}
            </span>
          </li>
        </scroll-x-navbar-cmp>
      </div>
      <!-- Horizontal menus -->
      <!-- Horizontal main menu -->
      <!-- Horizontal sub menu -->
      <div
        v-if="$viewportWidth < 768"
        :class="[
          'sticky-top',
          {
            hasHeaderOffset: hasHeaderOffset,
          },
        ]"
      >
        <scroll-x-navbar-cmp
          v-if="
            subCategoriesByCategorySlug[selectedCategory.slug] &&
            subCategoriesByCategorySlug[selectedCategory.slug].length
          "
          ref="horizontalSubMenu"
          :class="[
            'subCategoriesList horizontalMenu',
            {
              // 'gradientSx':subScrollerHasGradientLeft,
              // 'gradientDx':subScrollerHasGradientRight
            },
          ]"
          nav-identifier-class="scrollXSubMenu"
        >
          <li
            v-for="subCategory in subCategoriesByCategorySlug[
              selectedCategory.slug
            ]"
            :key="`${selectedCategory.slug}-${subCategory.slug}-mobile-scroll`"
            @click="selectSubCategory(subCategory)"
          >
            <span class="subCategoryItem">
              <span
                :class="[
                  'subCategory',
                  'typo-a-7',
                  {
                    selectedSubcategory:
                      activeSubCategory.slug === subCategory.slug,
                  },
                ]"
              >
                {{ subCategory.label }}
              </span>
            </span>
          </li>
        </scroll-x-navbar-cmp>
      </div>
      <div v-if="$viewportWidth >= 768" class="col-sm-4 col-md-3">
        <!-- Vertical menus -->
        <div class="verticalMenu sticky-top">
          <ul v-if="categories.length" class="mainCategoriesList">
            <li
              v-for="category in categories"
              :key="`${category.slug}-${_uid}-desk`"
              :ref="`${category.slug}-${_uid}-desk`"
              class="margin-bottom-s"
              @click="selectMainCategory(category)"
            >
              <!-- , 'selectedMainCategory' -->
              <span
                :class="[
                  'mainCategory',
                  'typo-w-4',
                  {
                    selectedMainCategory: inOpenAccordions(category.slug),
                  },
                ]"
              >
                {{ category.label }}
              </span>
              <!-- vertical sub menu animate height -->
              <ul
                v-expand="inOpenAccordions(category.slug)"
                :class="['subCategoriesList']"
                :aria-expanded="
                  !inOpenAccordions(category.slug) ? 'true' : 'false'
                "
                :aria-hidden="
                  inOpenAccordions(category.slug) ? 'true' : 'false'
                "
              >
                <li
                  v-for="(subCategory, idx) in subCategoriesByCategorySlug[
                    category.slug
                  ]"
                  :key="`${category.slug}-${subCategory.slug}-${_uid}-desk`"
                  :ref="`${category.slug}-${subCategory.slug}-${_uid}-desk`"
                  :class="[
                    'subCategoryWrapper',
                    {
                      'padding-top-s': idx === 0,
                    },
                  ]"
                  @click.prevent.stop="selectSubCategory(subCategory)"
                >
                  <span
                    :class="[
                      'subCategory',
                      'typo-a-7',
                      {
                        selectedSubcategory:
                          activeSubCategory.slug === subCategory.slug &&
                          activeCategory.slug === category.slug,
                      },
                    ]"
                  >
                    {{ subCategory.label }}
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- vertical main menu -->
      </div>
      <!-- Product list -->
      <div class="col-12 col-sm-8 col-md-9 text-center">
        <div class="products container-fluid p-0">
          <transition-group
            class="productsTransition row"
            name="slide"
            mode="out-in"
            :duration="{ enter: 500, leave: 0 }"
          >
            <div
              v-for="(product, prodIndex) in paginatedProducts.products"
              :key="prodIndex + product.label"
              class="col-12 col-sm-6 col-md-4"
            >
              <template v-if="$root.isPowerReviewActive">
                <pr-category-review-wrapper :data-item="product">
                  <wa-product-card
                    :data-conf="product"
                    :class="[
                      'product',
                      {
                        'margin-top-s': productMarginClass(prodIndex),
                      },
                    ]"
                  />
                </pr-category-review-wrapper>
              </template>
              <template v-else>
                <wa-product-card
                  :data-conf="product"
                  :class="[
                    'product',
                    {
                      'margin-top-s': productMarginClass(prodIndex),
                    },
                  ]"
                />
              </template>
            </div>
          </transition-group>
        </div>
      </div>
      <!-- Load more button -->
      <div class="col-12 offset-sm-4 col-sm-8 offset-md-3 col-md-9 text-center">
        <wa-cta
          v-if="showLoadMore"
          class="primary margin-top-m loadMoreBtn"
          @click.prevent="numberOfProducts += 6"
        >
          {{ $store.state.config.load_more_label }}
        </wa-cta>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollXNavbarCmp from './scroll-x-navbar-cmp';
import anime from 'animejs/lib/anime.es.js';
import { mapState } from 'vuex';
import { debug, colorLog } from '@helpers/utils';

export default {
  name: 'WaTabs',
  components: {
    ScrollXNavbarCmp,
  },
  props: {
    dataAllLabel: {
      type: String,
      required: true,
    },
    dataProductsConf: {
      type: [Array, String],
      required: true,
    },
  },
  data() {
    return {
      rendered: false,
      hasHeaderOffset: false,
      selectedCategory: {
        slug: '',
        label: '',
      },
      activeCategory: {
        slug: '',
        label: '',
      },
      activeSubCategory: {
        slug: '',
        label: '',
      },
      numberOfProducts: 12,
      storedClassName: '',
      mainScrollerHasGradientLeft: false,
      mainScrollerHasGradientRight: false,
      subScrollerHasGradientLeft: false,
      subScrollerHasGradientRight: false,
      mobileListnerAdded: false,
      openEls: [],
      autoclose: true,
      transition: false,
    };
  },
  computed: {
    ...mapState(['pageSetupCompleted']),
    ...mapState('config', ['enable_seo_products_urls']),
    allCategory() {
      return {
        //Step %ph% of %ph%
        slug: 'all',
        label: this.$_stringSupport_interpolate(this.dataAllLabel, [
          this.selectedCategory.label,
        ]),
      };
    },
    categories() {
      return this.products.length
        ? this.products.reduce((acc, curr) => {
            if (
              !acc.find((category) => category.slug === curr.main_category) &&
              acc.main_category !== ''
            ) {
              return [
                ...acc,
                {
                  slug: curr.main_category,
                  label: curr.main_category_label,
                },
              ];
            } else {
              return acc;
            }
          }, [])
        : [];
    },
    subCategories() {
      return this.products.length
        ? this.products.reduce((acc, curr) => {
            if (!acc.find((category) => category.slug === curr.sub_category)) {
              return [
                ...acc,
                {
                  slug: curr.sub_category,
                  label: curr.sub_category_label,
                },
              ];
            } else {
              return acc;
            }
          }, [])
        : [];
    },
    subCategoriesByCategorySlug() {
      let ret = {};
      this.products.length &&
        this.products.forEach((product) => {
          if (!ret[product.main_category]) {
            ret[product.main_category] = this.products.reduce((acc, curr) => {
              if (
                curr.main_category === product.main_category &&
                !acc.find(
                  (subCategory) => subCategory.slug === curr.sub_category
                ) &&
                curr.sub_category !== ''
              ) {
                return [
                  ...acc,
                  {
                    slug: curr.sub_category,
                    label: curr.sub_category_label,
                  },
                ];
              } else {
                return acc;
              }
            }, []);

            const all = this.allCategory;
            //debug && console.log('aggiungo la all a',product.main_category,ret[product.main_category])
            if (ret[product.main_category].length === 0) {
              ret[product.main_category] = [all];
            } else {
              ret[product.main_category] = [all, ...ret[product.main_category]];
            }
          }
        });
      //debug && console.log(ret)
      return ret;
    },
    products() {
      return this.$_cmsSupport_castPropToType(this.dataProductsConf, 'array');
    },
    inOpenAccordions() {
      return (mainIndex) => {
        return this.openEls.indexOf(mainIndex) !== -1;
      };
    },
    filteredProducts() {
      if (
        this.activeCategory.slug === '' &&
        this.activeSubCategory.slug === 'all'
      ) {
        return this.products;
      } else {
        return this.products.filter((product) => {
          if (this.activeSubCategory.slug === 'all') {
            return product.main_category === this.activeCategory.slug;
          } else {
            return (
              product.main_category === this.activeCategory.slug &&
              product.sub_category === this.activeSubCategory.slug
            );
          }
        });
      }
    },
    paginatedProducts() {
      const totalLength = this.filteredProducts.length;
      const products =
        totalLength > this.numberOfProducts
          ? this.filteredProducts.slice(0, this.numberOfProducts)
          : this.filteredProducts;
      return { products, totalLength };
    },
    showLoadMore() {
      return this.paginatedProducts.totalLength > this.numberOfProducts;
    },
  },
  watch: {
    pageSetupCompleted: {
      //this handler will be called only once if wa config is loaded before wa tabs
      //twice if wa config is loaded after
      handler(val) {
        debug &&
          colorLog({
            label: 'watch',
            message: `pageSetupCompleted -> ${val}`,
            themeName: 'blue',
          });
        if (val) {
          this.setup();
        }
      },
      immediate: true,
    },
    $currentScrollPosition: {
      immediate: true,
      //we need this to handle movement up if we scrolled down, the element become sticky, we scrolled up and the header appeared, we continued to scroll up until the element is not anymore sticky and we should remove the offset attached to handle the header
      handler() {
        if (
          this.$scrollDirection === 'up' &&
          this.$refs.horizontalMainMenu &&
          this.$refs.horizontalMainMenu.$el.getBoundingClientRect().top +
            this.$refs.horizontalMainMenu.$el.getBoundingClientRect().height >
            0
        ) {
          this.hasHeaderOffset = false;
        }
      },
    },
    $scrollDirection: {
      immediate: true,
      //we need this to handle movement down if the header is shown
      handler(newVal) {
        this.hasHeaderOffset = false;
        if (['xs'].includes(this.$currentStep)) {
          if (newVal === 'up') {
            this.hasHeaderOffset = true;
            //transition: transform 300ms ease-out; 80px
            //devo aggiungere lo spazio perchè si vede l'header
          }
        }
      },
    },
    $viewportWidth: {
      immediate: true,
      handler(newVal) {
        if (newVal < 768) {
          if (!this.mobileListnerAdded) {
            //patch for random error on sentry
            this.attachMobileListners();
          }
        }
      },
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.mobileListnerAdded) {
      if (
        this.$refs?.horizontalMainMenu?.$refs?.scroller &&
        this.$refs?.horizontalSubMenu?.$refs?.scroller
      ) {
        this.$refs.horizontalMainMenu.$refs.scroller.removeEventListener(
          'scroll',
          this.handleScrollerGradients
        );
        this.$refs.horizontalSubMenu.$refs.scroller.removeEventListener(
          'scroll',
          this.handleScrollerGradients
        );
      }
    }
  },

  methods: {
    //patch for random error on sentry
    attachMobileListners() {
      try {
        this.addScrollListners();
        this.mobileListnerAdded = true;
      } catch (e) {
        setTimeout(this.attachMobileListners, 300);
      }
    },
    setup() {
      let main, sub;
      debug &&
        colorLog({
          label: 'selected_main_category',
          message: `${this.$store.state.config.selected_main_category}`,
          themeName: 'vue',
        });
      if (this.$store.state.config.selected_main_category) {
        main = this.categories.find(
          (item) =>
            item.slug === this.$store.state.config.selected_main_category
        );
      } else {
        main = this.categories[0];
      }
      debug &&
        colorLog({
          label: 'selected_sub_category',
          message: `${this.$store.state.config.selected_sub_category}`,
          themeName: 'vue',
        });
      if (this.$store.state.config.selected_sub_category) {
        sub = this.subCategories.find(
          (item) => item.slug === this.$store.state.config.selected_sub_category
        );
      } else {
        sub = this.subCategoriesByCategorySlug[this.categories[0].slug][0];
      }

      this.$store.state.config.selected_sub_category;
      this.selectedCategory = main;
      this.activeCategory = main;
      this.activeSubCategory = sub;
      this.openEls = [main.slug];
      this.handleSlug();
    },
    handleSlug() {
      if (debug) {
        if (this.enable_seo_products_urls) {
          if (window.CMS) {
            colorLog({
              label: 'enable_seo_products_urls',
              message: `${this.enable_seo_products_urls}; DISABLING FOR EDIT MODE`,
              themeName: 'red',
            });
          } else {
            debug &&
              colorLog({
                label: 'enable_seo_products_urls',
                message: `${this.enable_seo_products_urls}`,
                themeName: 'vue',
              });
          }
        } else {
          debug &&
            colorLog({
              label: 'enable_seo_products_urls',
              message: `${this.enable_seo_products_urls}`,
              themeName: 'red',
            });
        }
      }

      if (!this.enable_seo_products_urls || window.CMS) {
        return;
      }
      debug && console.log('----------------handleSlug---------------');
      const base = this.$store.state.config.currentUri.split(
        window.location.host
      )[1];

      const main = this.activeCategory;
      const sub = this.activeSubCategory;

      const selectedMainCategory =
        this.$store.state.config.selected_main_category;

      debug && console.log('this.activeCategory;', this.activeCategory.slug);
      debug &&
        console.log('this.activeSubCategory;', this.activeSubCategory.slug);
      debug && console.log('selectedMainCategory', selectedMainCategory);

      let slug = ``;
      if (selectedMainCategory) {
        slug += `${base.split(selectedMainCategory)[0]}`;
      } else {
        slug += `${base}`;
      }
      let title = `${main.label} |`;

      debug && console.log('BASE SLUG', slug);

      if (main) {
        debug && console.log('aggiungo main slug', main.slug);
        slug += `${main.slug}/`;
      }
      if (sub && sub.slug !== 'all') {
        debug && console.log('aggiungo sub slug', sub.slug);
        slug += `${sub.slug}/`;
      }
      title += ` ${sub.label}`;

      window.history.replaceState({}, title, slug);
      debug && console.log('-----------------------------------------');
    },
    productMarginClass(prodIndex) {
      if (this.$viewportWidth < 768) {
        return false;
      } else {
        if (this.$viewportWidth < 1024) {
          if (prodIndex < 2) {
            return false;
          } else {
            return true;
          }
        } else {
          if (prodIndex < 3) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    addScrollListners() {
      this.$nextTick(() => {
        if (
          this.$refs?.horizontalMainMenu?.$refs?.scroller &&
          this.$refs?.horizontalSubMenu?.$refs?.scroller
        ) {
          this.$refs.horizontalMainMenu.$refs.scroller.addEventListener(
            'scroll',
            this.handleScrollerGradients
          );
          this.$refs.horizontalSubMenu.$refs.scroller.addEventListener(
            'scroll',
            this.handleScrollerGradients
          );
        }
      });
    },

    selectSubCategory(subCategory) {
      this.activeSubCategory = subCategory;
      this.activeCategory = this.selectedCategory;
      this.numberOfProducts = 12;
      if (this.$root.isPowerReviewActive) {
        setTimeout(this.$root.unmountWidgets, 0);
        setTimeout(this.$root.mountWidgets, 0);
      }

      this.handleSlug();
    },

    selectMainCategory(category, activate = false) {
      // if (index !== this.selectedCategory) {
      //this.activeSubCategory = '';
      this.selectedCategory = category;
      this.activeSubCategory = this.allCategory;
      if (activate) {
        this.activeCategory = this.selectedCategory;
        this.numberOfProducts = 12;
      } else {
        //added because of rework asked to selected automatically
        //first sub cat. because of this request a lot of logic can
        //be reworked but because is a big amount we will just keep
        //this two pasted lines
        this.activeCategory = this.selectedCategory;
        this.numberOfProducts = 12;

        if (this.transition) {
          return false;
        }
        this.transition = true;

        if (
          this.autoclose &&
          this.openEls.length &&
          this.openEls.indexOf(category.slug) === -1
        ) {
          this.openEls = [];
        }
        // const active = this.categories[index]
        const idx = this.openEls.indexOf(category.slug);

        if (idx !== -1) {
          this.openEls.splice(idx, 1);
        } else {
          this.openEls.push(category.slug);
        }
        const end = () => {
          this.$el.removeEventListener('transitionend', end);
          this.transition = false;
        };
        this.$el.addEventListener('transitionend', end);
      }
      // rimuovo i gradienti dello scroller figlio
      if (this.$viewportWidth < 768) {
        if (this.$refs?.horizontalSubMenu?.$refs?.scroller) {
          this.$refs.horizontalSubMenu.$refs.scroller.scrollLeft = 0;
        }
      }
      // this.mainScrollerHasGradient();
      // }
      if (this.$root.isPowerReviewActive) {
        setTimeout(this.$root.unmountWidgets, 0);
        setTimeout(this.$root.mountWidgets, 0);
      }
      this.handleSlug();
    },
    handleScrollerGradients($event) {
      const tgt = $event.target;
      const isNotScrolled = tgt.scrollLeft === 0;
      const isFullyScrolled =
        tgt.scrollLeft + tgt.clientWidth >= tgt.scrollWidth;

      if (
        $event.target.parentElement.classList.contains('mainCategoriesList')
      ) {
        this.mainScrollerHasGradientLeft = isNotScrolled ? false : true;
        this.mainScrollerHasGradientRight = isFullyScrolled ? false : true;
      } else {
        this.subScrollerHasGradientLeft = isNotScrolled ? false : true;
        this.subScrollerHasGradientRight = isFullyScrolled ? false : true;
      }
    },

    directionToScrollInViewport(el, percentVisibleOfElement) {
      // find direction of scrolling based on what part of the element is hidden from the viewport
      let result,
        rect = this.$refs[el][0].getBoundingClientRect(),
        rectWidth = this.$refs[el][0].getBoundingClientRect().width,
        // if the word in the menu is too short i have to set a min value of 150
        rectWidthRefined = rectWidth < 150 ? 150 : rectWidth,
        // 50 is to cover the offset on the client width caused by the side gradients
        windowWidth =
          window.innerWidth - 50 || document.documentElement.clientWidth - 50,
        leftIsOutViewport =
          Math.floor(
            100 -
              ((rect.left >= 0 ? 0 : rect.left) / +-(rectWidthRefined / 1)) *
                100
          ) < percentVisibleOfElement,
        rightIsOutViewport =
          Math.floor(
            100 - ((rect.right - windowWidth) / rectWidthRefined) * 100
          ) < percentVisibleOfElement;

      // IT RETURNS DX, SX OR EMPTY, the last one is meant to be used as a falsy value
      leftIsOutViewport
        ? (result = 'dx')
        : rightIsOutViewport
        ? (result = 'sx')
        : (result = '');
      return result;
    },
    scrollToEl(containerEl, targetRefName, scrollDirection) {
      // if a direction has benn given then proceed
      if (scrollDirection) {
        const rect = this.$refs[targetRefName][0].getBoundingClientRect();
        // ref cannot be used in this case cause the container element is in another component
        // btw, the container class is not a blind guess, a prop has been added exactly for this
        // the new value is calc adding the width of the element to the scrll left
        // if i scroll for the width of the element i will show it for sure
        const scrollLeftNewValue =
          scrollDirection === 'sx'
            ? containerEl.scrollLeft + rect.width
            : containerEl.scrollLeft - rect.width;
        // animation with animejs
        anime({
          targets: containerEl,
          scrollLeft: scrollLeftNewValue,
          duration: 250,
          easing: 'easeInOutQuad',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaTabs {
  @include media-breakpoint-down(md) {
    width: 100%;
    padding: 0;

    .externalRow {
      margin: 0;
    }

    .externalCol {
      padding: 0;
    }

    /deep/ .scrollXMainMenu,
    /deep/ .scrollXSubMenu {
      padding: 0 16px;
    }
  }

  .hideMe {
    display: none;
  }

  .loadMoreBtn {
    margin-left: 10px;

    @media (min-width: $bootstrap-sm) {
      margin: auto;
    }
  }

  .mainCategoriesList {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $color-grey-4;
    height: 60px;

    @media (min-width: $bootstrap-sm) {
      flex-direction: column;
      border-bottom: 0;
      margin: 0;
      padding: 0;
      height: auto;
    }

    .mainCategory {
      font-family: $font-secondary;
      font-weight: inherit;
      color: $color-grey-8;
      transition: color ease-out 0.5s;
      transition: opacity ease-out 0.5s;
      font-weight: bold;
      padding: 15px 0 15px;
      /* padding: 16px 0 18px; */
      display: block;
      margin: 0 12px 0 12px;
      opacity: 0.6;
      cursor: pointer;

      @media (min-width: $bootstrap-sm) {
        display: block;
        margin: 0 12px 0 0;
        padding: 6px 0;
      }

      &:hover {
        color: $color-grey-9;
        opacity: 1;
      }
    }

    ul {
      li {
        &:first-child {
          span {
            margin: 0 12px 0 0;
          }
        }
      }
    }

    .selectedMainCategory {
      border-bottom: 3px solid;
      color: $color-blue-logo;
      transition: color ease-out 0.5s;
      transition: opacity ease-out 0.5s;
      opacity: 1;

      @media (min-width: $bootstrap-sm) {
        border-left: 3px solid;
        border-bottom: 0;
        padding: 6px 0 6px 16px;
        margin: 0;
        display: block;
      }
    }
  }

  .gradientSx {
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 60px;
      content: '';
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      pointer-events: none;
      /* so the text is still selectable */
      z-index: 33;

      @media (min-width: $bootstrap-sm) {
        display: none;
      }
    }
  }

  .gradientDx {
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 64px;
      content: '';
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      pointer-events: none;
      /* so the text is still selectable */
      z-index: 33;

      @media (min-width: $bootstrap-sm) {
        display: none;
      }
    }
  }

  // -----------------------
  // SUB MENUs
  // -----------------------

  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 2;
    width: 100%;
    background: $color-white;
    transform: translateY(0);
    transition: transform 200ms ease-out;
    &.hasHeaderOffset {
      transition: transform 400ms ease-out;
      transform: translateY(57px);
      z-index: 100;
    }

    @media (min-width: $bootstrap-sm) {
      padding-top: 64px;
      margin-top: -64px;
    }
    @media (min-width: $bootstrap-md) {
      padding-top: 80px;
      margin-top: -80px;
    }
    @media (min-width: $bootstrap-lg) {
      padding-top: 80px;
      margin-top: -80px;
    }
  }

  .subCategoriesList {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    overflow-x: hidden;
    font-family: $font-primary;
    width: 100%;
    background: inherit;
    height: 77px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);

    @media (min-width: $bootstrap-sm) {
      width: auto;
      height: auto;
      overflow: hidden;
      padding: 0;
      margin: 0;
      background: inherit;
      filter: none;
      box-shadow: none;
      display: block;
    }

    .subCategoryItem {
      padding: 20px 4px;
      display: block;
    }

    .subCategoryWrapper {
      @media (min-width: $bootstrap-sm) {
        display: block;
        padding-bottom: 0;
        margin-bottom: 12px;

        &:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    .subCategory {
      border: 2px solid $color-grey-4;
      border-radius: 30px;
      padding: 6px 14px;
      margin: 0;
      display: inline-block;
      color: $color-grey-8;
      transition: all ease-out 0.3s;
      cursor: pointer;

      &:hover {
        color: $color-grey-9;
        border: 2px solid $color-grey-6;
      }

      @media (min-width: $bootstrap-sm) {
        display: inline-block;
      }
    }

    .selectedSubcategory {
      color: $color-blue-logo;
      background-color: $color-grey-2;
      transition: all ease-out 0.3s;
      border: 2px solid $color-grey-2;

      &:hover {
        border: 2px solid $color-grey-2;
      }
    }

    ul {
      li {
        &:first-child {
          .subCategoryItem {
            padding-left: 0;
          }
        }
      }
    }
  }

  // -----------------------
  // SUB MENU CHANGE
  // -----------------------

  .verticalMenu {
    display: none;

    @media (min-width: $bootstrap-sm) {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .horizontalMenu {
    display: block;

    @media (min-width: $bootstrap-sm) {
      display: none;
    }
  }

  // -----------------------
  // TRANSITIONS
  // -----------------------

  .productsTransition {
    > div {
      margin-top: 24px;

      @media (min-width: $bootstrap-sm) {
        margin-top: 0;

        &:nth-child(n + 3) {
          margin-top: 24px;
        }
      }

      @media (min-width: $bootstrap-md) {
        margin-top: 0;

        &:nth-child(n + 3) {
          margin-top: 0;
        }

        &:nth-child(n + 4) {
          margin-top: 24px;
        }
      }
    }
  }

  .products-enter-active {
    transition: all 1s;
    opacity: 1;
  }

  .products-enter {
    opacity: 0;
  }

  .products-leave-active {
    opacity: 0;
  }

  .products-leave-to {
    opacity: 0;
  }

  .slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to,
  .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }

  .expand {
    overflow: hidden;
    transition: height 0.3s linear;

    &[aria-expanded='false'] {
      height: 0 !important;
    }
  }

  .u-no-transition {
    transition: none !important;
  }
}
</style>
