<template>
  <div class="WaSideBySide">
    <div class="sideBySideWrapper container-fluid">
      <div class="row">
        <!-- Immagine step mobile e desktop left -->
        <div
          v-if="
            hasImageSlot &&
            ($currentStep === 'xs' ||
              ($currentStep !== 'xs' && dataVariantClass === 'left-image'))
          "
          class="col-12 col-sm-6 d-sm-flex align-items-sm-center justify-content-sm-end"
        >
          <div
            ref="$left"
            :class="
              $currentStep === 'xs'
                ? ['imageRatio169']
                : [
                    'sideImage left',
                    getFadeAnimation(dataVariantClass, 'sideImage'),
                  ]
            "
          >
            <slot name="image" />
          </div>
        </div>

        <!-- Contenuto left o right -->
        <div
          :class="[
            'col-12 col-sm-5 col-lg-4 offset-lg-1 d-sm-flex align-items-sm-center',
            {
              'offset-sm-1': dataVariantClass === 'left-image',
              'padding-top-m': $currentStep === 'xs',
            },
          ]"
        >
          <div
            :ref="dataVariantClass === 'right-image' ? '$left' : '$right'"
            :class="
              $currentStep === 'xs'
                ? null
                : [
                    'sideText',
                    getFadeAnimation(dataVariantClass, 'sideText'),
                    {
                      right: dataVariantClass === 'left-image',
                      left: dataVariantClass === 'right-image',
                    },
                  ]
            "
          >
            <div
              v-if="hasSmallTitleSlot"
              class="small_title typo-heavy typo-a-7 margin-bottom-xs"
            >
              <slot name="small_title" />
            </div>
            <div
              v-if="hasTitleSlot"
              :class="[
                'title typo-bold margin-bottom-r',
                dataSubvariantClass === 'simple' ? 'typo-w-4' : 'typo-w-3',
              ]"
            >
              <slot name="title" />
            </div>
            <div v-if="hasWysiwygSlot" class="contentText typo-light typo-a-7">
              <slot name="wysiwyg" />
            </div>
            <template v-if="hasCtaSlot">
              <slot name="cta" />
            </template>
          </div>
        </div>

        <!-- Immagine step desktop right -->
        <div
          v-if="
            hasImageSlot &&
            $currentStep !== 'xs' &&
            dataVariantClass === 'right-image'
          "
          class="col-6 offset-1 d-flex align-items-center justify-content-start"
        >
          <div
            ref="$right"
            :class="[
              'sideImage right',
              getFadeAnimation(dataVariantClass, 'sideImage'),
            ]"
          >
            <slot name="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imagesLoaded from 'imagesloaded';
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'WaSideBySide',
  props: {
    dataVariantClass: {
      type: String,
      default: 'left-image',
    },
    dataSubvariantClass: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      onGoingAnimations: [],
    };
  },
  computed: {
    // SLOTS CHECKERS
    hasSmallTitleSlot() {
      return (
        this.dataSubvariantClass !== 'simple' &&
        !!(this.$slots['small_title'] || [])[0]
      );
    },
    hasTitleSlot() {
      return !!(this.$slots['title'] || [])[0];
    },
    hasWysiwygSlot() {
      return !!(this.$slots['wysiwyg'] || [])[0];
    },
    hasImageSlot() {
      return !!(this.$slots['image'] || [])[0];
    },
    hasCtaSlot() {
      return !!(this.$slots['cta'] || [])[0];
    },
  },
  watch: {
    $currentStep: {
      handler() {
        this.handleScroll();
      },
      immediate: true,
    },
  },
  created() {
    // SETTING LISTENER
    window.addEventListener('scroll', this.handleScroll);
  },
  beforedestroy() {
    // DESTROY LISTENER
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    //  WRAPPER METHOD FOR ANIMATIONS
    handleScroll() {
      // animations during scroll
      this.$nextTick(() => {
        imagesLoaded(this.$el, () => {
          this.handleAnimations();
        });
      });
    },
    //  ANIMATES ELEMENT
    handleAnimations() {
      if (this.dataVariantClass === 'right-image') {
        this.animateElement(this.$refs.$left, 'sideText');
        this.animateElement(this.$refs.$right, 'sideImage');
      } else {
        this.animateElement(this.$refs.$left, 'sideImage');
        this.animateElement(this.$refs.$right, 'sideText');
      }
    },
    animateElement(element, type) {
      if (element && !this.onGoingAnimations.includes(element)) {
        const isElementInViewport = this.isElementXPercentInViewport(
          element,
          33
        );
        const isElementAnimable = element.classList.contains(type);

        if (isElementInViewport && isElementAnimable) {
          const animClass = this.getFadeAnimation(this.dataVariantClass, type);
          this.onGoingAnimations.push(element);
          animClass === 'fadeRight'
            ? anime({
                targets: element,
                right: 0,
                opacity: 1,
                duration: 1000,
                easing: 'cubicBezier(.455, .030, .515, .955)',
                complete: () => {
                  element.classList.remove('fadeRight');
                },
              })
            : anime({
                targets: element,
                left: 0,
                opacity: 1,
                duration: 1000,
                easing: 'cubicBezier(.455, .030, .515, .955)',
                complete: () => {
                  element.classList.remove('fadeLeft');
                },
              });
        }
      }
    },
    // SAYS IF ELEMENT IS IN VIEWPORT
    isElementXPercentInViewport(el, percentVisible) {
      const rect = el.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      return !(
        Math.floor(
          100 - ((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100
        ) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
          percentVisible
      );
    },
    // CHOOSE ANIMATION DIRECTION
    getFadeAnimation(variant, type) {
      let classToAdd = null;

      if (variant === 'right-image') {
        if (type === 'sideText') {
          if (!this.onGoingAnimations.includes(this.$refs.$left)) {
            classToAdd = 'fadeLeft';
          }
        } else if (type === 'sideImage') {
          if (!this.onGoingAnimations.includes(this.$refs.$right)) {
            classToAdd = 'fadeRight';
          }
        }
      } else if (variant === 'left-image') {
        if (type === 'sideText') {
          if (!this.onGoingAnimations.includes(this.$refs.$right)) {
            classToAdd = 'fadeRight';
          }
        } else if (type === 'sideImage') {
          if (!this.onGoingAnimations.includes(this.$refs.$left)) {
            classToAdd = 'fadeLeft';
          }
        }
      }

      return classToAdd;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables.scss';

.WaSideBySide {
  .sideBySideWrapper {
    /* All step */
    .small_title {
      color: $color-secondary-cyan;
    }

    .title {
      font-family: $font-secondary;
      color: $color-secondary-cyan;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      div {
        font-size: inherit;
      }
    }

    .contentText {
      /deep/ *:last-child {
        margin-bottom: 0;
      }
    }

    /* Step xs */
    .imageRatio169 {
      position: relative;
      width: auto; /* this is crucial in this situation */
      height: 0;
      padding-bottom: 56.25%;
      margin: 0 -#{map_get($bootstrap-container-side-spacings, xs) / 2 + map_get(
          $bootstrap-gutters,
          xs
        ) / 2};
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    /* Step sm md lg xl */
    .sideImage,
    .sideText {
      position: relative;

      /* ANIMATION CLASSES (starters, will be animated with animejs on scroll) */
      &.fadeRight,
      &.fadeLeft {
        opacity: 0;
      }

      &.fadeRight {
        right: -100%;
      }

      &.fadeLeft {
        left: -100%;
      }
    }

    .sideImage {
      height: 320px;

      @media (min-width: $bootstrap-md) {
        height: 400px;
      }

      @media (min-width: $bootstrap-lg) {
        height: 530px;
      }

      img {
        position: absolute;
        object-fit: cover;
        width: 50vw;
        height: 100%;
        top: 0;
      }

      &.right {
        img {
          left: 0;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }

      &.left {
        img {
          right: 0;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}
</style>
