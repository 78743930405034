<template>
  <form ref="$form" class="WaContactFormGeneric" @submit.prevent>
    <div
      v-if="formConfig && formConfig.picture && formConfig.picture.is_visible"
      class="row justify-content-center"
    >
      <div class="col-12 col-md-8 padding-bottom-r">
        <wa-upload
          ref="$waUpload"
          v-model.trim="$v.picture.$model"
          name="picture"
          :label="
            dataLabel('picture', contentData.form_input_file_picture_label)
          "
          :label-smaller="
            dataLabel(
              'picture',
              contentData.form_input_file_picture_label_small
            )
          "
          :label-on-hover="
            dataLabel('picture', contentData.form_input_file_picture_hover)
          "
          :cta-text="contentData.form_input_file_picture_cta"
          :helper-text="contentData.form_input_file_picture_helper"
          :helper-text-smaller="
            contentData.form_input_file_picture_helper_small
          "
          :data-error-occurred="$v.picture.$error"
          :errors="
            dataErrors('picture', {}, [
              {
                condition: !$v.picture.mustBeImage,
                message: contentData.form_input_file_picture_error_format,
              },
              {
                condition: !$v.picture.validSize,
                message: contentData.form_input_file_picture_error_size,
              },
            ])
          "
          @change="onFieldChange('picture', $event)"
        />
      </div>
    </div>
    <div
      v-if="
        formConfig &&
        formConfig.description &&
        formConfig.description.is_visible
      "
      class="row justify-content-center"
    >
      <div class="col-12 col-md-8">
        <wa-textarea
          v-model.trim="$v.description.$model"
          data-name="description"
          :data-label="
            dataLabel(
              'description',
              contentData.form_textarea_description_label
            )
          "
          :data-error-occurred="$v.description.$error"
          :data-errors="
            dataErrors(
              'description',
              {
                required: contentData.form_textarea_description_error_required,
              },
              [
                {
                  condition: !$v.description.maxLength,
                  message:
                    contentData.form_textarea_description_error_max_length,
                },
              ]
            )
          "
          @input="onFieldChange('description', $event)"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 padding-top-xs padding-bottom-r">
        <span class="typo-a-9 typo-light text-color-grey-8">
          {{ contentData.mandatory_field_indication_placeholder }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <wa-cta
          class="primary"
          data-type="submit"
          :data-disabled="disableSubmit"
          @click.prevent="onSubmitBtnClick"
        >
          {{ contentData.continue_cta_label }}
        </wa-cta>
      </div>
    </div>
  </form>
</template>

<script>
import WaContactFormFieldsUpdate from '@mixins/wa-contact-form-fields-update';
import { helpers, required, maxLength } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

export default {
  name: 'WaContactFormGeneric',
  mixins: [WaContactFormFieldsUpdate],
  data() {
    return {
      /* form field */
      picture: null,
      description: null,
      fields: ['picture', 'description'],
    };
  },
  computed: {
    ...mapState('contact', ['formData', 'contentData', 'fieldsInfos']),
  },
  validations() {
    let validations = {};
    this.fields.forEach((prop) => {
      //added because on prod is crashing [WaContactFormGeneric] -> [render] -> Cannot read properties of undefined (reading 'is_visible')
      //console.log('- -');
      if (
        this.formConfig &&
        this.formConfig[prop] &&
        this.formConfig[prop].is_visible
      ) {
        validations[prop] = {};
      }
    });
    //console.log('- - -');
    Object.keys(validations).forEach((prop) => {
      if (this.formConfig[prop].required) {
        validations[prop] = {
          ...validations[prop],
          required,
        };
      }

      if (this.formConfig[prop].regex) {
        validations[prop] = {
          ...validations[prop],
          regex: (val) =>
            !helpers.req(val) ||
            new RegExp(this.formConfig[prop].regex).test(val),
        };
      }
    });

    //console.log('- - - -');
    if (
      //added because on prod is crashing [WaContactFormGeneric] -> [render] -> Cannot read properties of undefined (reading 'is_visible')
      this.formConfig &&
      this.formConfig.description &&
      this.formConfig.description.is_visible
    ) {
      validations.description = {
        ...validations.description,
        //it seems that the way be and fe are counting the characters of the string are different
        //so validation frontend count less than 500 or 500 and let the user go trough the flow
        //while backend count them like they are more than 500 so returns an error.
        //because we do not have this handling in a proper way because of 'storie' we just limit
        //this string to 490. sorry
        maxLength: maxLength(490),
      };
    }

    //console.log('- - - - -');
    if (
      //added because on prod is crashing [WaContactFormGeneric] -> [render] -> Cannot read properties of undefined (reading 'is_visible')
      this.formConfig &&
      this.formConfig.picture &&
      this.formConfig.picture.is_visible
    ) {
      validations.picture = {
        ...validations.picture,
        mustBeImage: (val) => {
          return (
            !helpers.req(val) ||
            val.type === 'image/jpeg' ||
            val.type === 'image/jpg' ||
            val.type === 'image/png'
          );
        },
        validSize: (val) => {
          const mb = 3 * 1024 * 1024;
          return !helpers.req(val) || val.size <= mb;
        },
      };
    }

    return validations;
  },
  mounted() {
    window.cfStepsContent = window.cfStepsContent || [];
    window.cfStepsContent.push('WaContactFormGeneric');
    this.fields.forEach((f) => {
      if (this.formData[f]) {
        this[f] = this.formData[f];
      }
    });
    this.$nextTick(() => {
      if (this.picture && this.$refs && this.$refs.$waUpload) {
        this.$refs.$waUpload.setFile(this.picture);
      }
    });
  },
};
</script>

<style scoped></style>
