import * as Sentry from '@sentry/vue';
import axios from 'axios';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { colorLog } from '@helpers/utils.js';
import { ignoreErrors, beforeSend, denyUrls } from '@helpers/sentry_utils';

export default function init(Vue, sentryDsn, environment, siteName) {
  const canLogOnSentry =
    !window.location.hostname.includes('localhost') &&
    !window.location.hostname.includes('develop');

  Sentry.init({
    Vue: Vue,
    dsn: sentryDsn,
    ignoreErrors: ignoreErrors,
    denyUrls: denyUrls,
    logErrors: true,
    environment: `[FE] - ${environment}`,
    attachStacktrace: true,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        //tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        // ... other options
      }),
    ],
    //release to configure in automation if we have a release version in the project
    beforeSend: beforeSend,
  });
  // console.log('Sentry init with ', window.sentryDsn);

  axios.interceptors.request.use(
    function (config) {
      return config;
    },
    function (err) {
      if (err === void 0) {
        //unhandled reject
        return;
      }

      const properties = Object.getOwnPropertyNames(err),
        extra = {};

      properties.forEach((p) => {
        const prop = Object.getOwnPropertyDescriptor(err, p);
        if (prop && prop.value) {
          extra[p] = prop.value;
        }
      });

      if (canLogOnSentry) {
        Sentry.withScope(function (scope) {
          scope.setLevel('error');
          Sentry.setTags({
            axios_source: 'request',
            logger: 'Axios',
            site: `${siteName}.${environment}`,
            //...extra
          });
          Sentry.captureMessage(`[Axios] -> [Request]`, {
            level: 'error',
            extra,
          });
        });
      }

      colorLog({
        label: 'axios error',
        message: `Our team has been informed`,
        themeName: 'red',
      });
      console.error(err);
      return Promise.reject(err);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (err) {
      if (err === void 0) {
        //unhandled reject
        return;
      }

      const properties = Object.getOwnPropertyNames(err),
        extra = {};

      properties.forEach((p) => {
        const prop = Object.getOwnPropertyDescriptor(err, p);
        if (prop && prop.value) {
          extra[p] = prop.value;
        }
      });

      if (
        extra &&
        extra.config &&
        extra.response &&
        extra.config.method &&
        extra.config.url &&
        extra.response.status
      ) {
        const title = `[ ${extra.config.method.toUpperCase()} ] on [ ${
          extra.config.url
        } ] returned [ ${extra.response.status} ]`;
        if (canLogOnSentry) {
          Sentry.withScope(function (scope) {
            scope.setLevel('warning');
            Sentry.setTags({
              axios_source: 'response',
              logger: 'Axios',
              requestMethod: extra.config.method,
              requestUrl: extra.config.url,
              responseSttatus: extra.response.status,
              site: `${siteName}.${environment}`,
            });
            const serverResponse = {};
            try {
              serverResponse.status = extra.response.status;
            } catch (e) {
              console.log(e);
              serverResponse.status = 'cannot get it';
            }
            try {
              serverResponse.statusText = extra.response.statusText;
            } catch (e) {
              console.log(e);
              serverResponse.statusText = 'cannot get it';
            }
            try {
              if (window.cfData) {
                serverResponse.requestData = {};
                for (const pair of window.cfData.entries()) {
                  serverResponse.requestData[pair[0]] = pair[1];
                }
              }
              serverResponse.stepsContent =
                window.cfStepsContent &&
                Array.isArray(window.cfStepsContent) &&
                window.cfStepsContent.join(', ');
              //be careful all step are mounted at beginning so you will have
              //all the step in front and after the path, and in the path some steps
              //could be missing because already mounted
              serverResponse.steps =
                window.cfSteps &&
                Array.isArray(window.cfSteps) &&
                window.cfSteps.join(', ');
            } catch (e) {
              serverResponse.steps = 'Cannot get steps';
              serverResponse.stepsContent = 'Cannot get steps content';
              serverResponse.requestData = 'Cannot get requestData';
            }
            try {
              if (
                typeof extra.response.data === 'string' &&
                extra.response.data.length > 500
              ) {
                serverResponse.data =
                  extra.response.data.slice(0, 500) +
                  '\n...........................\n' +
                  '...........................\n' +
                  '...........................\n' +
                  '...........................\n' +
                  extra.response.data.slice(-500);
              } else {
                if (typeof extra.response.data === 'object') {
                  serverResponse.data = {};
                  Object.keys(extra.response.data).forEach((k) => {
                    if (Array.isArray(extra.response.data[k])) {
                      serverResponse.data[k] =
                        extra.response.data[k].join(', ');
                    } else {
                      serverResponse.data[k] = extra.response.data[k];
                    }
                  });
                } else {
                  serverResponse.data = extra.response.data;
                }
              }
            } catch (e) {
              console.log(e);
              serverResponse.data = 'cannot get it';
            }

            Sentry.captureMessage(title, {
              level: 'warning',
              extra: {
                extra: extra,
                serverResponse: serverResponse,
              },
            });
          });
        }
      } else {
        const title = `[Axios] -> [Request] -> ${extra.message}`;
        if (canLogOnSentry) {
          Sentry.withScope(function (scope) {
            scope.setLevel('error');
            Sentry.setTags({
              axios_source: 'response',
              logger: 'Axios',
              site: `${siteName}.${environment}`,
            });
            Sentry.captureMessage(title, {
              level: 'error',
              extra: extra,
            });
          });
        }
        colorLog({
          label: 'axios error',
          message: `Our team has been informed`,
          themeName: 'red',
        });
        console.error(err);
      }

      return Promise.reject(err);
    }
  );

  Vue.config.errorHandler = function VueErrorHandler(err, vm, info) {
    if (err === void 0) {
      //unhandled reject
      return;
    }

    const properties = Object.getOwnPropertyNames(err),
      extra = {};

    properties.forEach((p) => {
      const prop = Object.getOwnPropertyDescriptor(err, p);

      if (prop && prop.value) {
        if (p !== 'stack') {
          extra[p] = prop.value;
        }
      }
    });

    extra['EXTRA-vue-options-name'] = vm?.$options?.name;
    extra['EXTRA-vue-info'] = info;
    extra['site'] = `${siteName}.${environment}`;

    if (properties.includes('message')) {
      const message = Object.getOwnPropertyDescriptor(err, 'message');
      const stack = Object.getOwnPropertyDescriptor(err, 'stack');
      if (canLogOnSentry) {
        Sentry.withScope(function (scope) {
          scope.setLevel('error');

          Sentry.setTags({
            logger: 'javascript',
            ...extra,
          });

          if (
            message.value !== 'Failed to fetch' &&
            message.value !== 'Load failed'
          ) {
            Sentry.captureMessage(
              `[${vm?.$options?.name || 'App'}] -> [${info}] -> ${
                message.value
              }`,
              {
                level: 'error',
                extra: {
                  stack: stack ? stack.value : '---',
                },
                //extra: extra
              }
            );
          }
        });
      }
      colorLog({
        label: 'javascript error',
        message: `Our team has been informed`,
        themeName: 'red',
      });
      console.error(err);
    } else {
      if (canLogOnSentry) {
        Sentry.withScope(function (scope) {
          scope.setLevel('error');
          Sentry.setTags({
            logger: 'javascript',
            source: 'unknown',
            site: `${siteName}.${environment}`,
          });
          Sentry.captureMessage('Generic untracked error', {
            level: 'error',
            extra: extra,
          });
        });
      }
      console.error('[LOGGER VUE GLOBAL ERROR HANDLER] Ops!');
      console.error(err);
    }
  };

  Vue.config.catchHandler = function VueErrorHandler(err, vm, info) {
    if (err === void 0) {
      //unhandled reject
      return;
    }

    const properties = Object.getOwnPropertyNames(err),
      extra = {};

    properties.forEach((p) => {
      const prop = Object.getOwnPropertyDescriptor(err, p);
      if (prop && prop.value) {
        if (p !== 'stack') {
          extra[p] = prop.value;
        }
      }
    });

    extra['EXTRA-vue-options-name'] = vm?.$options?.name;
    extra['EXTRA-vue-info'] = info;
    extra['site'] = `${siteName}.${environment}`;

    if (properties.includes('message')) {
      const message = Object.getOwnPropertyDescriptor(err, 'message');
      const stack = Object.getOwnPropertyDescriptor(err, 'stack');
      if (message.value.indexOf('failed with status code') === -1) {
        if (canLogOnSentry) {
          Sentry.withScope(function (scope) {
            scope.setLevel('error');

            Sentry.setTags({
              logger: 'javascript',
              ...extra,
            });

            if (
              message.value !== 'Failed to fetch' &&
              message.value !== 'Load failed'
            ) {
              Sentry.captureMessage(
                `[${vm?.$options?.name || 'App'}] -> [${info}] -> ${
                  message.value
                }`,
                {
                  level: 'error',
                  extra: {
                    stack: stack ? stack.value : '---',
                  },
                }
              );
            }
          });
        }
        colorLog({
          label: 'javascript error',
          message: `Our team has been informed`,
          themeName: 'red',
        });
        console.error(err);
      } else {
        //already managed in axios
      }
    } else {
      //you can easly comment this
      if (properties.includes('title')) {
        const title = Object.getOwnPropertyDescriptor(err, 'title');
        if (canLogOnSentry) {
          Sentry.withScope(function (scope) {
            scope.setLevel('info');

            Sentry.setTags({
              logger: 'Promises',
              ...extra,
            });

            Sentry.captureMessage(`Promise rejected - ${title.value}`, {
              level: 'error',
            });
          });
        }
      } else {
        if (canLogOnSentry) {
          Sentry.withScope(function (scope) {
            scope.setLevel('warning');

            Sentry.setTags({
              logger: 'Promises',
              ...extra,
            });

            Sentry.captureMessage(`Promise rejected`, {
              level: 'error',
            });
          });
        }
      }
    }
  };

  (function (Promise) {
    var originalCatch = Promise.prototype.catch;
    Promise.prototype.catch = function (onRejected) {
      return originalCatch.call(this, function (value) {
        Vue.config.catchHandler.call(null, value, this);
        return onRejected(value);
      });
    };
  })(Promise);
}
