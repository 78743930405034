<template>
  <tr :class="['WaRow']">
    <slot />
  </tr>
</template>

<script>
export default {
  name: 'WaRow',
};
</script>
