var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"WaTitleTextCta"},[(_vm.dataVariant === 'badge')?_c('div',[_c('div',{class:{ 'container-fluid': !_vm.fullWidth }},[_c('div',{class:{ 'row justify-content-md-center': !_vm.fullWidth }},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"content col-12 col-sm-4 offset-sm-1"},[_c('div',{staticClass:"text typo-a-6 typo-light"},[_vm._t("title_text"),_vm._t("content_wysiwyg"),(_vm.isLower)?_vm._t("social-share"):_vm._e(),_vm._t("cta")],2)]),_c('div',{staticClass:"badge col-12 offset-sm-4 col-sm-2 d-flex justify-content-sm-end"},[_vm._t("badge")],2)])])])])]):_c('div',[_c('div',{class:{ 'container-fluid': !_vm.fullWidth }},[_vm._t("title_text"),(_vm.dataHasReviewsWidget)?_c('div',{attrs:{"id":"pwr-snippets"}},[_c('div',{attrs:{"id":("pr-reviewsnippet-" + _vm._uid)}})]):_vm._e(),_c('div',{class:{ row: !_vm.fullWidth }},[_c('div',{class:[
            _vm.fullWidth ? null : 'col-12',
            _vm.fullWidth ? null : ("col-sm-" + (_vm.isLower ? '6' : '7')),
            _vm.fullWidth ? null : ("col-md-" + (_vm.isLower ? '4' : '7')),
            'text',
            'typo-a-6',
            'typo-light' ]},[_vm._t("content_wysiwyg")],2),_c('div',{class:[
            _vm.fullWidth ? null : 'col-12',
            _vm.fullWidth ? null : ("col-sm-" + (_vm.isLower ? '12' : '5')),
            ("text-sm-" + (_vm.isLower ? 'left' : 'right')),
            ("align-self-" + (_vm.isLower ? 'start' : 'end')),
            'typo-a-8',
            'typo-light' ]},[(_vm.isLower)?_vm._t("social-share"):_vm._e(),_vm._t("cta")],2)])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }