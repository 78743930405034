import * as types from '../mutation_types';

export default {
  [types.SET_CONFIG_PROPERTY](state, payload) {
    state[payload.key] = payload.value;
  },
  [types.UPDATE_PAGINATION](
    state,
    { count, current_page, final_page, next, previous }
  ) {
    state.pagination = { count, current_page, final_page, next, previous };
  },
  [types.ADD_PAGE](state, { page_number, data }) {
    state.pages.push({ page_number, data });
  },
  [types.RESET_PAGES](state) {
    state.pages = [];
  },
  [types.SET_DETAIL](state, data) {
    state.detail = data;
  },
  [types.SET_SELECTED_MEAL](state, data) {
    state.selected_meal = data;
  },
  [types.SET_SELECTED_MOOD](state, data) {
    state.selected_mood = data;
  },
  [types.SET_API_URL](state, data) {
    state.api = data;
  },
};
