<template>
  <div class="oneTrust">
    <div :id="dataDivId" class="otnotice"></div>
  </div>
</template>

<script>
export default {
  name: 'OneTrust',
  props: {
    dataDivId: { type: String, required: true },
    dataSettings: { type: String, required: true },
    dataNotices: { type: String, required: true },
  },
  computed: {
    notices() {
      let allnotices = '[';
      this.dataNotices.split(',').forEach((notice) => {
        allnotices == '['
          ? (allnotices = allnotices + '"' + notice + '"')
          : (allnotices = allnotices + ',"' + notice + '"');
      });
      return allnotices + ']';
    },
  },
  created() {
    // first script
    let scriptTag = document.createElement('script');
    scriptTag.innerHTML = 'settings=' + this.dataSettings;
    scriptTag.setAttribute(
      'src',
      'https://privacyportalde-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js'
    );
    scriptTag.setAttribute('id', 'otprivacy-notice-script');
    scriptTag.setAttribute('type', 'text/javascript');
    scriptTag.setAttribute('charset', 'UTF-8');
    // second script
    let scriptTag_notices = document.createElement('script');
    scriptTag_notices.innerHTML =
      'window.OneTrust.NoticeApi.Initialized.then(function() { window.OneTrust.NoticeApi.LoadNotices(' +
      this.notices +
      ');});';
    scriptTag_notices.setAttribute('type', 'text/javascript');
    scriptTag_notices.setAttribute('charset', 'UTF-8');

    this.$nextTick(() => {
      // this.$el.appendChild(scriptTag);
      document.body.appendChild(scriptTag);
      window.expCounter = 0;
      (() => {
        const doCheck = () => {
          if (window.OneTrust && window.OneTrust.NoticeApi) {
            document.body.appendChild(scriptTag_notices);
          } else {
            window.expCounter++;
            if (window.expCounter !== 10) {
              setTimeout(doCheck, Math.exp(window.expCounter));
            }
          }
        };
        doCheck();
      })();
    });
  },
};
</script>

<style lang="scss" scoped>
.oneTrust::v-deep .otnotice-menu {
  @media only screen and (min-width: 768px) {
    position: absolute !important;
  }
}
</style>
