import Entrypoints from '@mixins/entrypoints';
import Vue from 'vue';

if (window.CMS) {
  if (window.VueVM) {
    delete window.VueVM;
  }
}

const VM = new Vue({
  el: '#app-root',
  mixins: [Entrypoints],
});

if (window.CMS) {
  window.VueVM = VM;
}
