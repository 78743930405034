<template>
  <div class="ContactFormTicketingSecondLevelCmp">
    <router-link
      v-for="item in items"
      :key="`list-item-${item.slug}`"
      class="list-item"
      :to="{
        name: routes.ticketing_second_level.controls.selectionCtasFrom
          .selectionCtasGoToName,
        query: {
          ...queryString,
          ticketing_second_level: item.slug,
          page_order: `${queryString.page_order},${routes.ticketing_second_level.name}`,
          canSkipSelection: true,
        },
      }"
    >
      <wa-cta-box class="contact-form-cta-box text-color-blue-logo">
        <template #title>
          <h4 class="typo-a typo-a-6 typo-medium margin-bottom-0">
            {{ item.label }}
          </h4>
        </template>
      </wa-cta-box>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactFormTicketingSecondLevelCmp',
  computed: {
    ...mapState('contactForm2024', ['routes', 'queryString']),
    items() {
      const modelName =
        this.routes?.ticketing_second_level?.controls?.selectionCtasFrom
          ?.selectionCtasItems;

      const second_level_group = modelName
        ? this.$store?.state?.contactForm2024[modelName]
        : [];

      return second_level_group.filter(
        (e) => e.first_level_group === this.queryString?.ticketing_first_level
      );
    },
  },
  mounted() {
    if (this.queryString.hasSkippedStep3) {
      delete this.queryString.hasSkippedStep3;
    }
  },
};
</script>

<style lang="scss" scoped>
.ContactFormTicketingSecondLevelCmp {
  .list-item {
    display: block;
    text-decoration: none;

    & ~ .list-item {
      padding-top: var(--contact-form-list-item-padding-top, 0);
    }
  }
}
</style>
