import * as types from '../mutation_types';
import axios from 'axios';

/* eslint-disable */
export default {
  setConfig({commit}, payload) {
    return new Promise((resolve, reject) => {
      try {
        Object.keys(payload).forEach(k => {
          commit(types.SET_CONFIG_PROPERTY, {key: k, value: payload[k]});
        });
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  setSelectedMeal({ commit, state }, value){
    return new Promise((resolve, reject) => {
      commit(types.SET_SELECTED_MEAL, value)
      resolve()
    })
  },
  setSelectedMood({ commit, state }, value){
    return new Promise((resolve, reject) => {
      commit(types.SET_SELECTED_MOOD, value)
      resolve()
    })
  },
  setApiUrl({commit, state}, value){
    return new Promise((resolve, reject) => {
      commit(types.SET_API_URL, value)
      resolve()
    })
  },
  initPagination ({commit, state}, {page_size}) { // Get first page
    return new Promise((resolve, reject) => {
      let params = {page_size};
      if(state.selected_meal!==''){
        params['meal_types'] = state.selected_meal
      }
      if(state.selected_mood!==''){
        params['moods'] = state.selected_mood
      }
      axios.get(state.api, {params})
        .then(response => {
          let count, current_page, final_page,
            next, previous, page
          ({count, current_page, final_page, next, previous} = response.data)
          page = {
            page_number: current_page,
            data: response.data.results
          }

          commit(types.RESET_PAGES)
          commit(types.UPDATE_PAGINATION, {count, current_page, final_page, next, previous, page_size})
          commit(types.ADD_PAGE, page)

          resolve()
        })
        .catch(error => {
          console.warn('[RECIPES API] error', error);
          console.warn(error);
          reject(error)
        })
    })
  },
  loadMore ({commit, state}) {
    return new Promise((resolve, reject) => {
      if (!!state.pagination.next) {
        axios.get(state.pagination.next)
          .then(response => {
            let count, current_page, final_page,
              next, previous, page
            ({count, current_page, final_page, next, previous} = response.data)
            page = {
              page_number: current_page,
              data: response.data.results
            }

            commit(types.UPDATE_PAGINATION, {count, current_page, final_page, next, previous})
            commit(types.ADD_PAGE, page)

            resolve()
          })
          .catch(reject)
      } else {
        reject(new Error('No more pages available'))
      }
    })
  },
  setDetail({ commit, state, rootState }, obj){
    return new Promise((resolve, reject) => {
      commit(types.SET_DETAIL, obj)
      resolve()
    })
  },
  getDetail ({commit, state, rootState}, slug) {
    return new Promise((resolve, reject) => {
      axios.get(rootState.api['recipes'] + slug + '/')
        .then(response => {
          commit(types.SET_DETAIL, response.data)
          resolve()
        })
        .catch(reject)
    })
  }
};