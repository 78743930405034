export const getGigyaDetails = () => {
  return new Promise((resolve, reject) => {
    //there is no fail callback because also when user
    //is not logged in this function goes in succes cb
    //we do not need any reject
    window.gigya.accounts.getAccountInfo({
      include: 'id_token,data,profile,preferences',
      callback: async (data) => {
        resolve(data);
      },
    });
  });
};

export const getGigyaAdditionalDataForWinningCalls = async () => {
  const gigyaData = await getGigyaDetails();
  // return {
  //   auth_user_email: 'juanburst@gmail.com',
  //   auth_user_first_name: 'Giovanni',
  //   auth_user_last_name: 'Bottalico',
  //   user_profiling: +gigyaData.preferences.privacy_profiling.isConsentGranted,
  //
  // };
  const payloadGigya = {
    auth_user_email: gigyaData.profile.email,
    user_uid: gigyaData.UID,
  };
  return payloadGigya;
};
