<template>
  <div v-if="active" class="WaGigyaScreen">
    <div>
      <div v-if="isUpdateProfileScreenTheStartScreen">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div
                :class="[
                  'tabs-btns',
                  {
                    'd-flex': $viewportWidth >= 768,
                    'justify-content-center': $viewportWidth >= 768,
                  },
                ]"
              >
                <div>
                  <!-- unfortunately the cls check doesn not work in functional cmp -->
                  <template v-if="isUpdateProfileScreen">
                    <wa-cta
                      class="primary"
                      data-href="#"
                      data-rel="nofollow"
                      @click.prevent=""
                    >
                      {{ dataProfileCtaLabel }}
                    </wa-cta>
                  </template>
                  <template v-else>
                    <wa-cta
                      class="secondary"
                      data-href="#"
                      data-rel="nofollow"
                      @click.prevent="
                        switchScreen(screensDestinationsNames.updateProfile)
                      "
                    >
                      {{ dataProfileCtaLabel }}
                    </wa-cta>
                  </template>
                </div>
                <div :class="[{ 'padding-top-r': $viewportWidth < 768 }]">
                  <!-- unfortunately the cls check doesn not work in functional cmp -->
                  <template v-if="isUnsubScreen">
                    <wa-cta
                      class="primary"
                      data-href="#"
                      data-rel="nofollow"
                      @click.prevent=""
                    >
                      {{ dataPrivacyCtaLabel }}
                    </wa-cta>
                  </template>
                  <template v-else>
                    <wa-cta
                      class="secondary"
                      data-href="#"
                      data-rel="nofollow"
                      @click.prevent="
                        switchScreen(screensDestinationsNames.unsubscription)
                      "
                    >
                      {{ dataPrivacyCtaLabel }}
                    </wa-cta>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :id="relatedId"
        :class="[
          'container-fluid',
          {
            'padding-top-l': isUpdateProfileScreenTheStartScreen,
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
import { debug, qsObj } from '@helpers/utils';
import { mapState } from 'vuex';

export default {
  name: 'WaGigyaScreen',
  props: {
    dataScreenSet: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return (
          ['Barilla-RegistrationLogin', 'Barilla-ProfileUpdate'].indexOf(
            value
          ) !== -1
        );
      },
    },
    dataStartScreen: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            'gigya-login-screen',
            'gigya-register-screen',
            'gigya-reset-password-screen',
            'gigya-update-profile-screen',
            'gigya-unsubscription-screen',
            'gigya-login-screen-v2',
            'gigya-register-screen-v2',
            'gigya-reset-password-screen-v2',
            'gigya-update-profile-screen-v2',
            'gigya-unsubscription-screen-v2',
          ].indexOf(value) !== -1
        );
      },
    },
    dataProfileCtaLabel: {
      type: String,
      required: false,
      default: '',
    },
    dataPrivacyCtaLabel: {
      type: String,
      required: false,
      default: '',
    },
    dataGoTo: {
      type: String,
      default: null,
    },
    dataAdditionalContext: {
      type: [String, Object],
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      current: '',
      relatedId: 'WaGigyaScreenContent',
      redirect: null,
      screenLoaded: false,
      globalContext: {
        noovleRaasGlobalCH: 'CH_WASA',
      },
    };
  },
  computed: {
    ...mapState('config', ['home_page_url']),
    ...mapState('gigya', ['active', 'loaded', 'user']),
    ...mapState('contestPlantATree', ['reg-source']),
    ...mapState('pm9LeisureAndFun', ['reg-source-pm9-leisure-and-fun']),
    ...mapState('pm56BrunchInNature', ['reg-source-pm5-6-brunch-in-nature']),
    ...mapState('pm56SandwichSurf', ['reg-source-pm5-6-sandwich-surf']),
    ...mapState('pm9SaveTheInsects', ['reg-source-pm9-save-the-insects']),
    ...mapState('pm56SeptemberGame', ['reg-source-pm5-6-september-game']),
    ...mapState('pm56Biscuits', ['reg-source-pm5-6-biscuits']),
    ...mapState('pm9LeisureAndFunTt', [
      'reg-source-pm9-leisure-and-fun-tt',
      'ch-key-pm9-leisure-and-fun-tt',
    ]),
    screensDestinationsNames() {
      if (
        [
          'gigya-update-profile-screen-v2',
          'gigya-unsubscription-screen-v2',
        ].includes(this.current)
      ) {
        return {
          updateProfile: 'gigya-update-profile-screen-v2',
          unsubscription: 'gigya-unsubscription-screen-v2',
        };
      } else {
        return {
          updateProfile: 'gigya-update-profile-screen',
          unsubscription: 'gigya-unsubscription-screen',
        };
      }
    },
    isUnsubScreen() {
      return [
        'gigya-unsubscription-screen-v2',
        'gigya-unsubscription-screen',
      ].includes(this.current);
    },
    isUpdateProfileScreen() {
      return [
        'gigya-update-profile-screen-v2',
        'gigya-update-profile-screen',
      ].includes(this.current);
    },
    isUpdateProfileScreenTheStartScreen() {
      return [
        'gigya-update-profile-screen-v2',
        'gigya-update-profile-screen',
      ].includes(this.dataStartScreen);
    },
    additionalContext() {
      return this.$_cmsSupport_castPropToType(
        this.dataAdditionalContext,
        'object'
      );
    },
    context() {
      const ret = {
        ...this.additionalContext,
        ...this.globalContext,
      };
      // we keep this for retrocompatibility with plant a tree
      if ('isContext' in qsObj && qsObj.isContext === 'true') {
        ret.noovleRaasGlobalRegSource = this['reg-source'];
      }
      // this will be the new implementation for all the contests
      /**
       * the naming convention for contestName is
       * pm(promotion module)9(module number) - leisure-and-fun (slugified name) => exemple : pm9-leisure-and-fun
       */
      debug && console.log(`'contestName' in qsObj`, 'contestName' in qsObj);
      debug && console.log(`qsObj.contestName`, qsObj.contestName);
      debug &&
        console.log(
          `REGSOURCE FROM CONFIG`,
          this['reg-source-pm9-leisure-and-fun-tt']
        );
      debug &&
        console.log(
          `CHKEY FROM CONFIG TO USE ON TY PAGE`,
          this['ch-key-pm9-leisure-and-fun-tt']
        );

      if ('contestName' in qsObj) {
        switch (qsObj.contestName) {
          case 'pm9-leisure-and-fun':
            ret.noovleRaasGlobalRegSource =
              this['reg-source-pm9-leisure-and-fun'];
            break;
          case 'pm9-leisure-and-fun-tt':
            ret.noovleRaasGlobalRegSource =
              this['reg-source-pm9-leisure-and-fun-tt'];
            break;
          case 'pm5-6-brunch-in-nature':
            ret.noovleRaasGlobalRegSource =
              this['reg-source-pm5-6-brunch-in-nature'];
            break;
          case 'pm5-6-sandwich-summer-campaign':
            ret.noovleRaasGlobalRegSource =
              this['reg-source-pm5-6-sandwich-summer-campaign'];
            break;
          case 'pm5-6-sandwich-surf':
            ret.noovleRaasGlobalRegSource =
              this['reg-source-pm5-6-sandwich-surf'];
            break;
          case 'pm9-save-the-insects':
            ret.noovleRaasGlobalRegSource =
              this['reg-source-pm9-save-the-insects'];
            break;
          case 'pm5-6-september-game':
            ret.noovleRaasGlobalRegSource =
              this['reg-source-pm5-6-september-game'];
            break;
          case 'pm5-6-biscuits':
            ret.noovleRaasGlobalRegSource = this['reg-source-pm5-6-biscuits'];
            break;
          default:
            ret.noovleRaasGlobalRegSource = '';
        }
      }
      return ret;
    },
  },
  watch: {
    loaded: {
      handler(val) {
        const info = String.fromCodePoint('0x2139');
        const styles = [
          'padding: 4px',
          'background-color: cornflowerblue',
          'color:darkblue',
        ].join(';');

        const toCheck = this.current ? this.current : this.startScreen;

        if (val && !this.screenLoaded) {
          if (this.dataScreenSet === 'Barilla-RegistrationLogin') {
            if (
              toCheck === 'gigya-login-screen-v2' ||
              toCheck === 'gigya-register-screen-v2' ||
              toCheck === 'gigya-login-screen' ||
              toCheck === 'gigya-register-screen'
            ) {
              if (this.user) {
                window.location.href = this.redirect
                  ? this.redirect
                  : this.dataGoTo
                  ? this.dataGoTo
                  : this.home_page_url;
              }
            }
          } else if (this.dataScreenSet === 'Barilla-ProfileUpdate') {
            if (!this.user && !window.CMS) {
              debug &&
                console.log(
                  `%c${info} [CDC Profile page; No user; No edit mode; Do redirect]`,
                  styles
                );
              window.location.href = this.redirect
                ? this.redirect
                : this.dataGoTo
                ? this.dataGoTo
                : this.home_page_url;
            } else {
              debug &&
                console.log(
                  `%c${info} [CDC Profile page; No user; CMS EDIT MODE; Skip redirect]`,
                  styles
                );
            }
          }

          window.addEventListener('customonAfterScreenLoad', () => {
            this.screenLoaded = true;
          });

          debug && console.log('context', this.context);
          window.gigya.accounts.showScreenSet({
            screenSet: this.dataScreenSet,
            startScreen: toCheck,
            containerID: this.relatedId,
            context: this.context,
          });
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (this.dataGoTo) {
      await this.$store.dispatch('gigya/setGigyaGoTo', this.dataGoTo);
    }
    this.current = this.dataStartScreen;
    this.redirect = 'goTo' in qsObj && qsObj.goTo ? qsObj.goTo : null;
  },
  methods: {
    switchScreen(related) {
      if (related === this.current) {
        return false;
      }

      this.current = related;

      window.gigya.accounts.showScreenSet({
        screenSet: this.dataScreenSet,
        startScreen: this.current,
        containerID: this.relatedId,
        context: this.context,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaGigyaScreen {
  /deep/ .WaCta {
    transition: color 0.3s, background-color 0.3s;
  }
}

.tabs-btns {
  > div + div {
    @media (min-width: $bootstrap-sm) {
      margin-left: 30px;
    }
  }
}
</style>
