<template>
  <wa-hero
    class="ContactFormHeroCmp"
    data-variant-class="center"
    :data-bg-src-image="hero[dataType].imagePathDesktop"
    :data-bg-mobile-src-image="hero[dataType].imagePathMobile"
  >
    <template #title-small>
      <h5 class="text-color-white">{{ hero[dataType].label }}</h5>
    </template>
    <template #title>
      <h2 class="text-color-white">{{ hero[dataType].title }}</h2>
    </template>
  </wa-hero>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactFormHeroCmp',
  props: {
    dataType: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState('contactForm2024', ['hero']),
  },
};
</script>

<style lang="scss" scoped>
.ContactFormHeroCmp {
}
</style>
