var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataProducts)?_c('div',{class:[
    'WaMultiSelectListItem',
    {
      dynamicListItem: _vm.dynamicList,
    } ]},[_c('ul',{staticClass:"listItems my-0"},_vm._l((_vm.productsList),function(product,idx){return _c('li',{key:("product-" + idx + "-" + _vm._uid + "-" + (_vm.selectedProductsId.length)),staticClass:"listItem"},[_c('div',{class:[
          'label typo-a-6 typo-medium label-wrapper',
          _vm.isDisabled(product.id) ? 'text-color-grey-4' : 'text-color-grey-8' ]},[(!_vm.dynamicList)?[_c('span',[_vm._v(_vm._s(product.name))])]:[_c('wa-select',{staticClass:"text-color-blue-logo typo-medium typo-a-6",attrs:{"data-show-label":false,"data-label":"","data-name":("category-" + idx + "-" + _vm._uid + "-" + (_vm.selectedProductsId.length)),"data-option-list":_vm.mappedCategoriesForSelect,"data-placeholder":_vm.dataCategoriesPlaceholder},on:{"change":function($event){_vm.availableProducts[idx].choosenProduct = '';
              _vm.availableProducts[idx].amount = 0;}},model:{value:(_vm.availableProducts[idx].choosenCategory),callback:function ($$v) {_vm.$set(_vm.availableProducts[idx], "choosenCategory", $$v)},expression:"availableProducts[idx].choosenCategory"}},[_c('template',{slot:"icon"},[_c('svg',{attrs:{"width":"24","height":"24"}},[_c('use',{attrs:{"href":"#icon-arrow-angle-down","fill":"currentColor"}})])])],2),_c('wa-select',{staticClass:"text-color-blue-logo typo-medium typo-a-6",attrs:{"data-show-label":false,"data-label":"","data-name":("product-" + idx + "-" + _vm._uid + "-" + (_vm.selectedProductsId.length)),"data-option-list":_vm.mappedProductsForSelect(_vm.availableProducts[idx].choosenCategory),"data-placeholder":_vm.dataProductsPlaceholder},on:{"change":function($event){_vm.availableProducts[idx].amount = 0}},model:{value:(_vm.availableProducts[idx].choosenProduct),callback:function ($$v) {_vm.$set(_vm.availableProducts[idx], "choosenProduct", $$v)},expression:"availableProducts[idx].choosenProduct"}},[_c('template',{slot:"icon"},[_c('svg',{attrs:{"width":"24","height":"24"}},[_c('use',{attrs:{"href":"#icon-arrow-angle-down","fill":"currentColor"}})])])],2)]],2),_c('div',{staticClass:"elements-wrapper"},[_c('div',{staticClass:"elements"},[_c('span',{class:[
              'icon',
              (
                !_vm.dynamicList
                  ? _vm.isDisabled(product.id)
                  : _vm.isDynamicDisabled(_vm.availableProducts[idx])
              )
                ? 'disabled'
                : null ],on:{"click":function($event){!_vm.dynamicList
                ? _vm.removeProduct(product.id)
                : _vm.removeDynamicProduct(idx)},"mouseover":function($event){return _vm.onMouseOver(idx, 'remove', product.id)},"mouseleave":function($event){_vm.isHoverIndexRemove = null}}},[_c('svg',{attrs:{"width":"24","height":"25"}},[(_vm.isHoverIndexRemove === idx)?_c('use',{attrs:{"href":"#icon-minus-circle-select-hover","fill":"currentColor"}}):_c('use',{attrs:{"href":"#icon-minus-circle-select","fill":"currentColor"}})])]),_c('div',{class:['quantity typo-a-6 typo-medium', 'text-color-blue-logo']},[(
                (!_vm.dynamicList && _vm.amount(product.id) > 0) ||
                (_vm.dynamicList && _vm.availableProducts[idx].amount > 0)
              )?_c('span',[(!_vm.dynamicList)?[_vm._v(" "+_vm._s(_vm.amount(product.id))+" ")]:[_vm._v(_vm._s(_vm.availableProducts[idx].amount))]],2):_c('span',{class:[
                (
                  !_vm.dynamicList
                    ? _vm.isDisabled(product.id)
                    : _vm.isDynamicDisabled(_vm.availableProducts[idx])
                )
                  ? 'text-color-grey-4'
                  : 'text-color-blue-logo' ]},[_vm._v(" 0 ")])]),_c('span',{class:[
              'icon',
              (
                !_vm.dynamicList
                  ? _vm.isDisabled(product.id)
                  : _vm.isDynamicDisabled(_vm.availableProducts[idx])
              )
                ? 'disabled'
                : null ],on:{"click":function($event){!_vm.dynamicList ? _vm.addProduct(product.id) : _vm.addDynamicProduct(idx)},"mouseover":function($event){return _vm.onMouseOver(idx, 'add', product.id)},"mouseleave":function($event){_vm.isHoverIndexAdd = null}}},[_c('svg',{attrs:{"width":"24","height":"25"}},[(_vm.isHoverIndexAdd === idx)?_c('use',{attrs:{"href":"#icon-plus-circle-select-hover","fill":"currentColor"}}):_c('use',{attrs:{"href":"#icon-plus-circle-select","fill":"currentColor"}})])])]),(_vm.dynamicList)?_c('div',{staticClass:"removeFromList typo-extra-bold text-color-secondary-red typo-a-8"},[_c('a',{staticClass:"text-color-secondary-red typo-a-8 typo-extra-bold",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeFromList(idx)}}},[_vm._v(" "+_vm._s(_vm.dataProductsRemoveText)+" ")]),_c('svg',{attrs:{"width":"16","height":"16"}},[_c('use',{attrs:{"href":"#icon-close","fill":"currentColor"}})])]):_vm._e()])])}),0),(_vm.selectedProductsId.length === _vm.dataTotalProducts)?_c('div',{staticClass:"margin-top-s typo-a-10 text-color-grey-8",domProps:{"innerHTML":_vm._s(_vm.interpolatedInfoTextRemove)}}):_c('div',{staticClass:"margin-top-s typo-a-10 text-color-grey-8",domProps:{"innerHTML":_vm._s(_vm.interpolatedInfoText)}}),(_vm.dynamicList)?_c('div',{staticClass:"add-cta-wrapper text-center margin-top-m"},[_c('wa-cta',{staticClass:"primary add-cta",on:{"click":function($event){$event.preventDefault();return _vm.addProductToList($event)}}},[_vm._v(" "+_vm._s(_vm.dataProductsAddMoreText)+" "),_c('svg',{attrs:{"width":"24","height":"25"}},[_c('use',{attrs:{"href":"#icon-add","fill":"currentColor"}})])])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }