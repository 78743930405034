<template>
  <div :class="['WaTable']">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-8">
          <div ref="$scroller" class="table-wrapper swiper-container">
            <div class="swiper-wrapper">
              <div class="table-container swiper-slide">
                <table>
                  <thead>
                    <wa-row v-if="dataRelatedProductData">
                      <wa-cell
                        v-for="col in dataRelatedProductData.cols"
                        :key="col.slug"
                        :data-header="true"
                      >
                        {{ col.label }}
                      </wa-cell>
                    </wa-row>
                    <slot v-else name="thead" />
                  </thead>
                  <tbody>
                    <template v-if="dataRelatedProductData">
                      <wa-row
                        v-for="(row, index) in dataRelatedProductData.rows"
                        :key="index"
                        :class="{ [dataAlternateColor]: !(index % 2) }"
                      >
                        <wa-cell
                          v-for="col in dataRelatedProductData.cols"
                          :key="col.slug"
                        >
                          <template v-if="row[col.slug]">
                            {{ row[col.slug] }}
                          </template>
                        </wa-cell>
                      </wa-row>
                    </template>
                    <slot v-else name="tbody" />
                  </tbody>
                </table>
              </div>
            </div>
            <div
              ref="$scrollbar"
              :class="['swiper-scrollbar', dataScrollbarBg, dataScrollbarFg]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';

export default {
  name: 'WaTable',
  props: {
    dataRelatedProductData: {
      type: Object,
      default: null,
    },
    dataAlternateColor: {
      type: String,
      default: 'bg-color-grey-2-50',
    },
    dataScrollbarBg: {
      type: String,
      default: 'bg-color-grey-2',
    },
    dataScrollbarFg: {
      type: String,
      default: 'text-color-grey-8',
    },
  },
  data() {
    return {
      swiper: null,
    };
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.$scroller, {
      slidesPerView: 'auto',
      freeMode: true,
      watchOverflow: true,
      scrollbar: {
        el: this.$refs.$scrollbar,
        draggable: true,
        hide: false,
        snapOnRelease: false,
      },
      breakpoints: {
        768: {
          allowTouchMove: false,
        },
      },
    });
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

table {
  width: 100%;
  table-layout: fixed;
}

@include media-breakpoint-down(xs) {
  .table-wrapper {
    overflow: hidden;
    margin: 0 -38px;
  }

  .table-container {
    padding: 0 38px;
    box-sizing: content-box;
    min-width: 690px;
  }

  table {
    box-sizing: border-box;
  }
}

.three-cols-leading {
  ::v-deep {
    td,
    th {
      &:first-child {
        width: 50%;
      }
    }
  }
}

.four-cols-leading {
  ::v-deep {
    td,
    th {
      &:first-child {
        width: 34%;
      }
    }
  }
}

.swiper-scrollbar {
  height: 8px;
  border-radius: 4px;
  margin: 16px 38px 0;
  position: relative;

  &.swiper-scrollbar-lock {
    display: none;
  }

  ::v-deep {
    .swiper-scrollbar-drag {
      height: 8px;
      border-radius: 4px;
      background: currentColor;
    }
  }
}
</style>
