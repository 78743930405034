import * as types from './mutation_types';

export default {
  [types.SET_APP_READY](state, value) {
    state.appReady = value;
  },
  [types.SET_STORE_SETUP_COMPLETED](state, value) {
    state.storeSetupCompleted = value;
  },
  [types.SET_PAGE_SETUP_COMPLETED](state, value) {
    state.pageSetupCompleted = value;
  },
  [types.SET_LOADING](state, value) {
    if (value) {
      state.loading.push(value);
    } else {
      state.loading.pop();
    }
  },
  [types.SET_UNLOCKED_VIEW](state, value) {
    state.unlockedView = value;
  },
  [types.SET_UNLOCKED_WAITING_VIEW](state, value) {
    state.unlockedWaitingView = value;
  },
};
