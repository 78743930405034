import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    'contest-published': false,
    'landing-page-url': '',
    'participate-page-url': '',
    'redeem-prize-page-url': '',
    'legal-age': '',
    'brunch-cities-api': '',
    'products-api': '',
    'stores-api': '',
    'privacy-check': '',
    'join-api': '',
    'province-api': '',
    'city-api': '',
    'check-winner-api': '',
    'check-winner-debug': '',
    'redeem-prize-api': '',
    'ch-key-pmextra-instagram': '',
    'reg-source-pmextra-instagram': '',
    'i18n': {
      prize: {
        pluginpacks: [
          {
            label: 'premio 1',
            pack_id: '1',
            pack_slug: 'premio-1',
          },
          {
            label: 'premio 2',
            pack_id: '2',
            pack_slug: 'premio-2',
          },
        ],
        hero_title: 'Remplir pour recevoir le prix',
        hero_small_title: 'Contest',
        hero_description:
          'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise.',
        title: 'Your contact information',
        description:
          'Leave your contact information and we will get back to you.',
        name_label: 'Prénom*',
        name_error_required: 'This field is required',
        name_error_minLength: 'Incorrect min length',
        name_error_maxLength: 'Incorrect max length',
        name_error_regex: 'An error has occured',
        surname_label: 'Nom*',
        surname_error_required: 'This field is required',
        surname_error_minLength: 'Incorrect min length',
        surname_error_maxLength: 'Incorrect max length',
        surname_error_regex: 'An error has occured',
        email_label: 'Email*',
        tooltip_personal_area_text: "Modificabile dall'area personale",
        prefix_label: 'Prefix',
        prefix_default: '+33',
        phone_label: 'Téléphone*',
        phone_error_required: 'This field is required',
        phone_error_minLength: 'Incorrect min length',
        phone_error_maxLength: 'Incorrect max length',
        phone_error_regex: 'An error has occured',
        province_label: 'Provincia*',
        province_placeholder: 'Choose a province',
        province_error_required: 'This field is required',
        prize_label: 'Prize*',
        prize_placeholder: 'Choose a prize',
        prize_error_required: 'This field is required',
        city_label: 'Ville*',
        city_placeholder: 'Choose a city',
        city_placeholder_no_province: 'Choose a province first',
        city_error_required: 'This field is required',
        postal_code_label: 'Cap*',
        postal_code_error_required: 'This field is required',
        postal_code_error_regex: 'An error has occured',
        address_label: 'Rue*',
        address_error_required: 'This field is required',
        address_error_minLength: 'Incorrect min length',
        address_error_maxLength: 'Incorrect max length',
        address_error_regex: 'An error has occured',
        house_number_label: 'Numéro de maison*',
        house_number_error_required: 'This field is required',
        intercom_label: "Numéro d'appartement/Autre",
        intercom_error_required: 'This field is required',
        mandatory_field_indication_placeholder:
          '* Ces champs sont obligatoires',
        submit_cta: 'Continue',
        global_error_logged:
          'Error occurred: You must be logged in to participate in the contest',
        global_error_legal_age:
          'Error occurred: You must be of legal age to participate in the contest',
        global_error_inactive_contest:
          'Error occurred: The contest is inactive',
        global_error_external_server_communication_failed:
          'Error occurred: Communication with external server has failed',
        global_error_unable_to_retrieve_user_token:
          'Error occurred: User token not retrived',
        global_error_missing_parameters_in_configuration:
          'Error occurred: Missing some parameters in configuration',
        thank_you_title: 'Lorem ipsum',
        thank_you_description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.',
        thank_you_cta: 'Zurück zur Aktion',
      },
    },
  },
  actions,
  mutations,
  getters,
};
