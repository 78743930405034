<template>
  <div class="WaPm56BrunchInNaturePrize">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WaPm56BrunchInNaturePrize',
  props: {
    dataBrunchInNature: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    i18n() {
      return this.$_cmsSupport_castPropToType(
        this.dataBrunchInNature,
        'object'
      );
    },
  },
  created() {
    this.setPm56BrunchInNatureI18nPrize(this.i18n);
  },
  methods: {
    ...mapActions('pm56BrunchInNature', ['setPm56BrunchInNatureI18nPrize']),
  },
};
</script>

<style scoped></style>
