<template>
  <div class="ContactFormHeadContentCmp">
    <div class="overtitle-wrapper">
      <span class="typo-a typo-a-9 typo-medium text-color-secondary-item">
        <slot name="overtitle" />
      </span>
    </div>
    <div class="title-wrapper">
      <h3 class="typo-a typo-a-4 typo-heavy text-color-secondary-item">
        <slot name="title" />
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactFormHeadContentCmp',
};
</script>

<style lang="scss" scoped>
.ContactFormHeadContentCmp {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .overtitle-wrapper {
    * {
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  .title-wrapper {
    padding-top: var(--contact-form-head-title-padding-top, 0);

    * {
      display: block;
      margin: 0;
      padding: 0;
    }
  }
}
</style>
