<template>
  <div class="ContactFormLayoutCmp">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ContactFormLayoutCmp',
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~$scss/utility';
@import '~bootstrap/scss/mixins/breakpoints';

.ContactFormLayoutCmp {
  --contact-form-view-wrapper-padding-bottom: 60px;
  --contact-form-legal-text-padding-bottom: 20px;
  --contact-form-opening-time-wrapper-padding-top: 80px;
  --contact-form-opening-time-wrapper-padding-bottom: 80px;
  --contact-form-head-padding-top: 108px;
  --contact-form-head-with-bradcrumb-padding-top: 24px;
  --contact-form-head-padding-bottom: 32px;
  --contact-form-head-title-padding-top: 16px;
  --contact-form-head-breadcrumb-content-row-gap: 16px;
  --contact-form-head-breadcrumb-items-gap: 10px;
  --contact-form-list-item-padding-top: 24px;
  --contact-form-accordion-wrapper-padding-top: 56px;
  --contact-form-accordion-item-margin-top: 24px;
  --contact-form-ticketing-country-submit-wrapper-padding-top: 50px;
  --contact-form-thank-you-padding-top: 100px;
  --contact-form-not-found-padding-top: 100px;

  @include media-breakpoint-up(md) {
    --contact-form-view-wrapper-padding-bottom: 160px;
    --contact-form-legal-text-padding-bottom: 40px;
    --contact-form-opening-time-wrapper-padding-top: 60px;
    --contact-form-opening-time-wrapper-padding-bottom: 176px;
    --contact-form-head-padding-top: 200px;
    --contact-form-head-with-bradcrumb-padding-top: 56px;
    --contact-form-head-padding-bottom: 64px;
    --contact-form-head-breadcrumb-items-gap: 16px;
    --contact-form-list-item-padding-top: 20px;
    --contact-form-accordion-wrapper-padding-top: 64px;
    --contact-form-accordion-item-margin-top: 20px;
    --contact-form-ticketing-country-submit-wrapper-padding-top: 66px;
    --contact-form-thank-you-padding-top: 200px;
    --contact-form-not-found-padding-top: 200px;
  }

  @include media-breakpoint-up(lg) {
    --contact-form-thank-you-padding-top: 240px;
    --contact-form-not-found-padding-top: 240px;
  }
}
</style>
