import { render, staticRenderFns } from "./wa-pm5-6-brunch-in-nature-chef-card.vue?vue&type=template&id=147b2e89&scoped=true&"
import script from "./wa-pm5-6-brunch-in-nature-chef-card.vue?vue&type=script&lang=js&"
export * from "./wa-pm5-6-brunch-in-nature-chef-card.vue?vue&type=script&lang=js&"
import style0 from "./wa-pm5-6-brunch-in-nature-chef-card.vue?vue&type=style&index=0&id=147b2e89&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147b2e89",
  null
  
)

export default component.exports