<template>
  <div class="WaAccordion">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="accordion-column col-12 col-md-10 col-lg-8">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaAccordion',
  data() {
    return {
      openEls: [],
    };
  },
  mounted() {
    //this.$nextTick(()=>{
    //console.log(this)
    //console.log(this.$slots.default)
    //})
  },
  methods: {
    //to implement in the future
    // moveFocus(index, direction) {
    //   let next = direction === "down" ? `item-${index + 1}` : `item-${index - 1}`
    //   if (typeof this.$refs[next] === "undefined") {
    //     next = direction === "down" ? `item-0` : `item-${this.itemsCount - 1}`
    //   }
    //   this.$refs[next][0].focus()
    // },
    // focusHomeEnd(e) {
    //   e.keyCode === 36 ? this.moveFocus(this.itemsCount - 1, "down") :
    //   e.keyCode === 35 ? this.moveFocus(0, "up") : void(0);
    // },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaAccordion {
  /deep/ .accordion-column > .item {
    .chevron {
      transition: transform 0.3s ease;
    }

    &.open {
      .chevron {
        transform: rotate(180deg);
      }
    }

    /deep/ p {
      margin-bottom: 0;
    }
  }

  &.fullwidth {
    .accordion-column {
      flex-basis: 100%;
      max-width: none;
    }
  }
}
</style>
