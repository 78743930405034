<template>
  <div class="WaSideBackgrounds">
    <img
      v-if="dataBackgroundLeft"
      :style="dataLeftStyles"
      class="leftBg"
      :src="dataBackgroundLeft"
      alt=""
      aria-hidden="true"
      :width="dataWidthLeft"
      :height="dataHeightLeft"
    />
    <div class="overContent">
      <slot />
    </div>
    <img
      v-if="dataBackgroundRight"
      class="rightBg"
      :style="dataRightStyles"
      :src="dataBackgroundRight"
      alt=""
      aria-hidden="true"
      :width="dataWidthRight"
      :height="dataHeightRight"
    />
  </div>
</template>

<script>
export default {
  name: 'WaSideBackgrounds',
  components: {},
  props: {
    dataBackgroundLeft: {
      type: String,
      default: null,
      required: true,
    },
    dataBackgroundRight: {
      type: String,
      default: null,
      required: true,
    },
    dataWidthLeft: {
      type: String,
      default: null,
      required: true,
    },
    dataHeightLeft: {
      type: String,
      default: null,
      required: true,
    },
    dataWidthRight: {
      type: String,
      default: null,
      required: true,
    },
    dataHeightRight: {
      type: String,
      default: null,
      required: true,
    },
    dataRightStyles: {
      type: String,
      default: null,
      required: false,
    },
    dataLeftStyles: {
      type: String,
      default: null,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaSideBackgrounds {
  position: relative;
  .leftBg,
  .rightBg {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
  .overContent {
    position: relative;
    z-index: 5;
  }
  .leftBg {
    left: 0;
  }
  .rightBg {
    right: 0;
  }
}
</style>
