<template>
  <div>
    <wa-side-backgrounds
      class="padding-top-xxl padding-bottom-l"
      :data-background-right="
        require('@images/sandwich-surf/thank-you-bg-right.png')
      "
      :data-background-left="
        require('@images/sandwich-surf/thank-you-bg-left.png')
      "
      data-width-right="302"
      data-height-right="400"
      data-width-left="302"
      data-height-left="400"
    >
      <div class="WaPm56SandwichSurfThankYou padding-top-r">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div
              class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-sport-blue"
            >
              <svg class="icon margin-top-r margin-bottom-r">
                <use href="#icon-email-send" fill="currentColor" />
              </svg>
              <div
                class="title typo-w-4 typo-bold text-center margin-bottom-r noMBLastChild"
                v-html="i18nParticipate.thank_you_title"
              />
              <p
                class="content typo-a-6 typo-light text-center text-color-black margin-bottom-m"
              >
                {{ i18nParticipate.thank_you_description }}
              </p>
              <wa-cta class="primary" :data-href="home_page_url">
                {{ i18nParticipate.thank_you_cta }}
              </wa-cta>
            </div>
          </div>
        </div>
      </div>
    </wa-side-backgrounds>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
// import { debug } from '@helpers/utils';

export default {
  name: 'WaPm56SandwichSurfThankYou',
  computed: {
    ...mapState('config', ['home_page_url']),
    ...mapState('pm56SandwichSurf', ['ch-key-pm5-6-sandwich-surf']),
    ...mapGetters('pm56SandwichSurf', ['i18nParticipate']),
  },
  created() {
    this.setInvertedHeader(false);
  },
  mounted() {
    this.$nextTick(() => {
      this.pushGenericCh(this['ch-key-pm5-6-sandwich-surf']);
      // TODO UPDATE CODE WHEN WE HAVE CORRECT INSTRUCTION
      // try {
      //   window.dataLayer.push({event: 'TYP_sandwich-summer-campaign'});
      // } catch (error) {
      //   debug && console.log('%c[CANNOT PUSH ON DATA LAYER]', 'padding:4px;background-color:red;color:white;');
      //   debug && console.error(error);
      // }
    });
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    ...mapActions('gigya', ['pushGenericCh']),
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaPm56SandwichSurfThankYou {
  .icon {
    width: 48px;
    height: 48px;
  }
}
</style>
