import Vue from 'vue';
import * as types from '../mutation_types';

export default {
  [types.SET_CURRENT_STEP](state, payload) {
    state.currentStep = payload;
  },
  [types.SET_FORM_DATA](state, payload) {
    let k = Object.keys(payload)[0];
    Vue.set(state.formData, k, payload[k]);
  },
  [types.SET_PRODUCT_DETAIL](state, payload) {
    state.productDetail = payload;
  },
  [types.SET_SELECTS_API](state, payload) {
    Object.keys(payload).forEach((k) => {
      Vue.set(state.selectsApi, k, payload[k]);
    });
  },
  [types.SET_MIN_HEIGHT](state, payload) {
    state.height = payload;
  },
  [types.SET_HOME_INFO](state, payload) {
    state.homeInfo = payload;
  },
  [types.SET_HOME_VISITED](state, payload) {
    state.homeVisited = payload;
  },
  [types.SET_CONTENT_DATA](state, payload) {
    Object.keys(payload).forEach((k) => {
      state.contentData[k] = payload[k];
    });
  },
  [types.SET_FIELDS_INFOS](state, payload) {
    Object.keys(payload).forEach((k) => {
      state.fieldsInfos[k] = payload[k];
    });
  },
  [types.SET_JEKO_INTEGRATION](state, payload) {
    Object.keys(payload).forEach((k) => {
      state.jekoIntegration[k] = payload[k];
    });
  },
};
