<template>
  <div class="ContactFormNotFoundCmp">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div
          class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-informative-red"
        >
          <svg class="icon" width="48" height="48">
            <use href="#icon-alert" fill="currentColor" />
          </svg>
          <span
            class="d-block typo-w typo-w-4 typo-bold text-center padding-top-xs"
          >
            {{ common.pageNotFound }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ContactFormNotFoundCmp',
  computed: {
    ...mapState('contactForm2024', ['common']),
  },
};
</script>

<style lang="scss" scoped>
.ContactFormNotFoundCmp {
  padding-top: var(--contact-form-not-found-padding-top, 0);
}
</style>
