<template>
  <div class="WaRadioChooser form-group__wrapper container-fluid">
    <div
      :class="[
        'form-group row',
        { 'form-group--error': dataErrorOccurred, 'disabled': dataDisabled },
      ]"
    >
      <div
        v-for="(radio, i) in dataRadioList"
        :key="`radio-${i}-${_uid}`"
        class="form-check__wrapper col-12 col-sm-6"
      >
        <label
          :class="[
            'form-check',
            {
              disabled: dataDisabled || radio.disabled,
              checked: radio.value === value,
            },
          ]"
        >
          <svg
            v-if="radio.iconId"
            class="form-check-icon"
            width="48"
            height="48"
          >
            <use :href="`#${radio.iconId}`" fill="currentColor" />
          </svg>
          <span
            class="form-check-label typo-a-6 typo-medium"
            :for="`input-radio-${i}-${_uid}`"
            v-html="radio.label"
          />
          <input
            :id="`input-radio-${i}-${_uid}`"
            type="radio"
            :name="dataName"
            :value="radio.value"
            :checked="radio.value === value"
            class="form-check-input"
            :disabled="dataDisabled || radio.disabled"
            @change="onUpdate"
          />
          <span class="checkmark" />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span
          v-for="(error, i) in hasErrors"
          :key="`error-${i}-${_uid}`"
          class="error typo-a-10 typo-medium"
          v-html="error.message"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaRadioChooser',
  props: {
    dataName: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    dataRadioList: {
      type: Array,
      required: true,
    },
    dataDisabled: {
      type: Boolean,
      default: null,
    },
    dataErrorOccurred: {
      type: Boolean,
      default: null,
    },
    dataErrors: {
      type: Array,
      default: null,
    },
  },
  computed: {
    hasErrors() {
      if (this.dataErrorOccurred) {
        return this.dataErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  methods: {
    onUpdate(e) {
      this.$emit('change', e.target.value);
      this.$emit('input', e.target.value); //to handle v-model on parent
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaRadioChooser.form-group__wrapper {
  .error {
    display: block;
    padding-top: 14px;
    color: $color-informative-red;
  }

  .form-group {
    .form-check__wrapper {
      & ~ .form-check__wrapper {
        padding-top: 12px;

        @media (min-width: $bootstrap-sm) {
          padding-top: 0;
        }
      }

      &:not(:first-child) ~ .form-check__wrapper {
        @media (min-width: $bootstrap-sm) {
          padding-top: 16px;
        }

        @media (min-width: $bootstrap-md) {
          padding-top: 24px;
        }
      }

      .form-check {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0;
        padding: 20px 16px;
        border: 2px solid $color-white;
        box-shadow: 0 4px 16px rgba($color-black, 0.15);
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.1s ease-out;

        @media (min-width: $bootstrap-md) {
          padding: 24px;
        }

        .form-check-icon {
          margin-right: 8px;
          color: $color-blue-logo;
          opacity: 0.5;

          @media (min-width: $bootstrap-sm) {
            margin-right: 12px;
          }

          @media (min-width: $bootstrap-md) {
            margin-right: 16px;
          }
        }

        .form-check-label {
          flex: 1;
          color: $color-grey-8;
        }

        .form-check-input {
          display: none;

          /* when radio button is checked */
          &:checked ~ .checkmark {
            background-color: $color-blue-logo;

            /* Show the indicator (dot/circle) when checked */
            &:after {
              display: block;
            }
          }
        }

        .checkmark {
          position: relative;
          justify-self: flex-end;
          height: 20px;
          width: 20px;
          background-color: $color-white;
          border: 1px solid $color-grey-6;
          border-radius: 50%;

          /* Create the indicator (the dot/circle - hidden when not checked) */
          &:after {
            display: none;
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $color-white;
          }
        }

        &.checked,
        &:hover:not(.disabled) {
          .form-check-label {
            color: $color-blue-logo;
          }

          .form-check-input ~ .checkmark {
            border-color: $color-blue-logo;
          }
        }

        &:hover:not(.disabled):not(.checked) {
          border-color: $color-grey-4;
        }

        &.checked {
          border-color: $color-blue-logo;
        }

        &.disabled {
          cursor: not-allowed;

          .checkmark {
            background-color: $color-grey-2-75;
            border-color: $color-grey-6;
          }
        }
      }
    }
  }
}
</style>
