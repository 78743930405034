<script>
import { mapState } from 'vuex';
import axios from 'axios';

import {
  debug,
  qsObj,
  colorLog,
  prohibited,
  ok,
  brain,
  wait,
  bell,
} from '@helpers/utils';

export default {
  name: 'WaGigyaGtmHandler',
  data() {
    return {
      counter: 0,
      pushUserInfoPending: false,
    };
  },
  computed: {
    ...mapState('gigya', [
      'version',
      'loaded',
      'user',
      'preferences',
      'otActiveGroups',
      'hashGuidApi',
    ]),
  },
  watch: {
    loaded(value) {
      if (value) {
        this.pushUserInfo();
      }
    },
    otActiveGroups() {
      debug &&
        colorLog({
          icon: bell,
          label: 'Privacy Profiling',
          message: `WATCHER otActiveGroups ${this.otActiveGroups}`,
          themeName: 'green',
        });
      this.pushUserInfo();
    },
    $route(to, from) {
      this.pushUserInfo();
    },
  },
  async mounted() {
    const otReady = await this.isOneTrustReady();
    if (otReady) {
      window.OneTrust.OnConsentChanged(() => {
        this.$store.dispatch(
          'gigya/setOnetrustActiveGroups',
          window.OnetrustActiveGroups
        );
      });
    }
  },
  methods: {
    async isOneTrustReady() {
      debug &&
        colorLog({
          icon: wait,
          label: 'Privacy Profiling',
          message: `OTREADY WAITING`,
          themeName: 'green',
        });
      return new Promise((resolve, reject) => {
        (() => {
          let checkOneTrust = () => {
            if (window.OneTrust && window.OneTrust.OnConsentChanged) {
              debug &&
                colorLog({
                  icon: ok,
                  label: 'Privacy Profiling',
                  message: `OTREADY SUCCESS`,
                  themeName: 'green',
                });
              resolve(true);
            } else {
              this.counter = this.counter + 1;
              if (this.counter !== 10) {
                setTimeout(checkOneTrust, Math.exp(this.counter));
              }
            }
          };
          checkOneTrust();
        })();
      });
    },
    async isGigyaReady() {
      debug &&
        colorLog({
          icon: wait,
          label: 'Privacy Profiling',
          message: `CDCREADY WAITING`,
          themeName: 'green',
        });
      return new Promise((resolve, reject) => {
        (() => {
          let checkGigya = () => {
            if (this.loaded) {
              debug &&
                colorLog({
                  icon: ok,
                  label: 'Privacy Profiling',
                  message: `CDCREADY SUCCESS`,
                  themeName: 'green',
                });
              resolve(true);
            } else {
              setTimeout(checkGigya, 2000);
            }
          };
          checkGigya();
        })();
      });
    },
    canPushUserInfo() {
      // console.log("c0004:", window.OnetrustActiveGroups && window.OnetrustActiveGroups.includes("C0004"));
      // console.log("isConsentGranted:", this.preferences?.privacy_profiling?.isConsentGranted);
      const ret =
        this.user &&
        (this.otActiveGroups?.includes('C0004') ||
          (window.OnetrustActiveGroups &&
            window.OnetrustActiveGroups.includes('C0004'))) &&
        (this.preferences?.privacy_profiling?.isConsentGranted ||
          this.preferences?.profiling?.isConsentGranted);
      debug &&
        colorLog({
          icon: brain,
          label: 'Privacy Profiling',
          message: `canPushUserInfo ${ret}`,
          themeName: 'green',
        });
      return ret;
    },
    async pushUserInfo() {
      debug &&
        colorLog({
          icon: brain,
          label: 'Privacy Profiling',
          message: `dataLayer Push: Starting`,
          themeName: 'green',
        });
      if (!this.pushUserInfoPending) {
        this.pushUserInfoPending = true;
        debug &&
          colorLog({
            icon: brain,
            label: 'Privacy Profiling',
            message: `LOADING OT & GIGYA...`,
            themeName: 'green',
          });
        const otReady = await this.isOneTrustReady();
        // facciamo questo ulteriore controllo perche il metodo può essere chiamato anche prima che gigya sia loaded
        const gigyaReady = await this.isGigyaReady();
        if (otReady && gigyaReady) {
          debug &&
            colorLog({
              icon: brain,
              label: 'Privacy Profiling',
              message: `LOADED OT & GIGYA`,
              themeName: 'green',
            });
          if (this.canPushUserInfo()) {
            debug &&
              colorLog({
                icon: brain,
                label: 'Privacy Profiling',
                message: `dataLayer Push: Authorized`,
                themeName: 'green',
              });
            try {
              let resultHashGuidApi = await axios.get(this['hashGuidApi']);
              if (resultHashGuidApi?.data?.hashed_guid) {
                const event = this.version === 2020 ? 'userInfo' : 'user_info';
                window.dataLayer.push({
                  event,
                  userId: resultHashGuidApi.data.hashed_guid.toLowerCase(),
                });
                debug &&
                  colorLog({
                    icon: ok,
                    label: 'Privacy Profiling',
                    message: `dataLayer Push: Done`,
                    themeName: 'green',
                  });
              }
            } catch (e) {
              console.error(
                `%c[Catch hashGuidApi something wrong with hash guid api]`,
                e
              );
            }
          }
          this.pushUserInfoPending = false;
        } else {
          console.warning('otReady && gigyaReady', otReady, gigyaReady);
        }
      }
    },
  },

  render: () => null,
};
</script>
