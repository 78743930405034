<template>
  <div class="WaPm56SandwichSummerCampaignVerify">
    <wa-hero
      data-variant-class="center"
      :data-bg-src-image="
        require('@images/contest-sandwich-summer-campaign/landing-contest-blue.jpeg')
      "
      :data-bg-mobile-src-image="
        require('@images/contest-sandwich-summer-campaign/landing-contest-blue-mobile.jpeg')
      "
      :data-has-down-arrow="true"
    >
      <h1 slot="title" class="text-color-white">
        {{ i18nParticipate.hero_title }}
      </h1>

      <h4 slot="title-small" class="text-color-white">
        {{ i18nParticipate.hero_title_small }}
      </h4>

      <p slot="description" class="text-color-white">
        {{ i18nParticipate.hero_description }}
      </p>
    </wa-hero>
    <div class="container-fluid padding-top-xxl padding-bottom-xxl">
      <form
        ref="$form"
        class="WaPm56SandwichSummerCampaignVerifyForm"
        @submit.prevent
      >
        <div>
          <div class="row justify-content-center">
            <div
              class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-sport-blue padding-bottom-r"
            >
              <svg class="icon margin-bottom-r">
                <use href="#icon-wasa-boxed" fill="currentColor" />
              </svg>
              <span class="typo-w-4 typo-bold text-center">
                {{ i18nParticipate.product_heading }}
              </span>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 padding-bottom-xl">
              <wa-multi-select-list-item
                ref="$products"
                :data-total-products="i18nParticipate.data_total_products"
                :data-products="products"
                :data-info-text="i18nParticipate.data_info_text"
                :data-info-text-remove="i18nParticipate.data_info_text_remove"
                @add-product="onAddProduct"
                @remove-product="onRemoveProduct"
              />
              <span
                v-if="show_selected_products_error"
                class="global-error products-error"
              >
                {{ i18nParticipate.products_error_required }}
              </span>
            </div>
          </div>
          <div class="row justify-content-center">
            <div
              class="col-12 col-md-10 col-lg-8 d-flex flex-column align-items-center justify-content-center text-color-sport-blue padding-bottom-l"
            >
              <span class="typo-w-4 typo-bold text-center margin-bottom-r">
                {{ i18nParticipate.shop_type_heading }}
              </span>
              <div
                class="typo-a-8 typo-light text-color-grey-9 text-center noMBLastChild"
                v-html="i18nParticipate.shop_type_description"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
              <wa-radio-chooser
                v-model.trim="$v.receipt_type.$model"
                data-name="receipt_type"
                :data-radio-list="[
                  {
                    value: 'standard',
                    label: i18nParticipate.shop_type_store_label,
                    iconId: 'icon-market',
                  },
                  {
                    value: 'electronic',
                    label: i18nParticipate.shop_type_online_label,
                    iconId: 'icon-world-hand-drawn',
                  },
                ]"
                :data-error-occurred="$v.receipt_type.$error"
                :data-errors="[
                  {
                    condition: !$v.receipt_type.required,
                    message: i18nParticipate.shop_type_error_required,
                  },
                  {
                    condition: !$v.receipt_type.serverError,
                    message: serverErrors.receipt_type,
                  },
                ]"
                @change="onSetShopType"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 padding-top-r">
              <wa-dropdown
                v-model.trim="$v.receipt_store.$model"
                data-name="receipt_store"
                :data-label="i18nParticipate.shop_label"
                :data-placeholder="i18nParticipate.shop_placeholder"
                :data-option-list="filteredShops"
                :data-error-occurred="$v.receipt_store.$error"
                :data-errors="[
                  {
                    condition: !$v.receipt_store.required,
                    message: i18nParticipate.shop_error_required,
                  },
                  {
                    condition: !$v.receipt_store.serverError,
                    message: serverErrors.receipt_store,
                  },
                ]"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
              <wa-regular-divider class="padding-top-m padding-bottom-m" />
            </div>
          </div>
          <div class="row justify-content-center">
            <div
              class="col-12 col-md-10 col-lg-8 d-flex flex-column padding-bottom-xs"
            >
              <span
                class="typo-a-6 typo-extra-bold margin-bottom-r text-color-grey-9"
              >
                {{ i18nParticipate.picture_heading }}
              </span>
              <span class="typo-a-9 typo-medium text-color-grey-8">
                {{ i18nParticipate.picture_description }}
              </span>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 padding-bottom-r">
              <wa-upload
                ref="$waUpload"
                v-model.trim="$v.receipt_front_photo.$model"
                name="receipt_front_photo"
                :label="i18nParticipate.picture_label"
                :label-smaller="i18nParticipate.picture_label_small"
                :label-on-hover="i18nParticipate.picture_label_hover"
                :cta-text="i18nParticipate.picture_cta"
                :helper-text="i18nParticipate.picture_helper"
                :helper-text-smaller="i18nParticipate.picture_helper_small"
                :data-error-occurred="$v.receipt_front_photo.$error"
                :errors="[
                  {
                    condition: !$v.receipt_front_photo.required,
                    message: i18nParticipate.picture_error_required,
                  },
                  {
                    condition: !$v.receipt_front_photo.mustBeImage,
                    message: i18nParticipate.picture_error_format,
                  },
                  {
                    condition: !$v.receipt_front_photo.validSize,
                    message: i18nParticipate.picture_error_size,
                  },
                  {
                    condition: !$v.receipt_front_photo.serverError,
                    message: serverErrors.receipt_front_photo,
                  },
                ]"
                @change="onFileChange"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 padding-bottom-m">
              <wa-accordion>
                <wa-accordion-item class="text-color-blue-logo margin-bottom-0">
                  <template slot="title">
                    <h4 class="typo-a-6 typo-medium margin-bottom-0">
                      {{ i18nParticipate.picture_instruction_heading }}
                    </h4>
                  </template>
                  <template slot="content">
                    <wa-free-text>
                      <template slot="content_wysiwyg">
                        <ul class="padding-top-s margin-top-0 margin-bottom-0">
                          <li class="typo-a-7 typo-light text-color-black">
                            {{ i18nParticipate.picture_instruction_hint_1 }}
                          </li>
                          <li class="typo-a-7 typo-light text-color-black">
                            {{ i18nParticipate.picture_instruction_hint_2 }}
                          </li>
                          <li
                            class="typo-a-7 typo-light text-color-black margin-bottom-0"
                          >
                            {{ i18nParticipate.picture_instruction_hint_3 }}
                          </li>
                        </ul>
                        <wa-regular-divider
                          class="padding-top-r padding-bottom-r"
                        />
                        <p>
                          <span
                            class="d-block typo-a-7 typo-medium text-color-grey-8 padding-bottom-xs"
                          >
                            {{
                              i18nParticipate.picture_instruction_info_heading
                            }}
                          </span>
                          <span
                            class="d-block typo-a-9 typo-light text-color-black padding-bottom-xs"
                          >
                            {{ i18nParticipate.picture_instruction_info_1 }}
                          </span>
                          <span
                            class="d-block typo-a-9 typo-light text-color-black"
                          >
                            {{ i18nParticipate.picture_instruction_info_2 }}
                          </span>
                        </p>
                      </template>
                    </wa-free-text>
                  </template>
                </wa-accordion-item>
              </wa-accordion>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 padding-bottom-r">
              <wa-textarea
                v-model.trim="$v.eligible_text.$model"
                :data-name="'text'"
                :data-textarea-id="'textarea'"
                :data-label="i18nParticipate.eligible_text_label"
                :data-helper-text="i18nParticipate.eligible_text_helper"
                :data-error-occurred="$v.eligible_text.$error"
                :data-errors="[
                  {
                    condition: !$v.eligible_text.required,
                    message: i18nParticipate.eligible_text_required,
                  },
                ]"
              />
            </div>
          </div>
        </div>
        <div v-if="show_rules" class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 padding-bottom-m">
            <wa-checkbox
              v-model.trim="$v.contest_rules_accepted.$model"
              data-name="contest_rules_accepted"
              :data-checkbox-list="[
                {
                  label: rulesLabel,
                },
              ]"
              :data-error-occurred="$v.contest_rules_accepted.$error"
              :data-errors="[
                {
                  condition: !$v.contest_rules_accepted.mustBeChecked,
                  message: i18nParticipate.rules_error_required,
                },
                {
                  condition: !$v.contest_rules_accepted.serverError,
                  message: serverErrors.contest_rules_accepted,
                },
              ]"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <div class="d-block d-sm-inline-block">
              <wa-cta
                class="primary"
                data-type="submit"
                :data-icon-id="spinner ? 'icon-ellipsis-loader' : ''"
                :data-disabled="disableSubmit || submitted || !canParticipate"
                @click.prevent="onSubmit"
              >
                {{ i18nParticipate.submit_cta }}
              </wa-cta>
              <span
                v-for="(error, i) in hasGlobalErrors"
                :key="`error-${i}-${_uid}`"
                class="global-error typo-a-10 typo-medium"
                v-html="error.message"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { helpers, required } from 'vuelidate/lib/validators';
import LanguageSupport from '@mixins/language-support';
import { debug } from '@helpers/utils';
import axios from 'axios';

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'WaPm56SandwichSummerCampaignVerify',
  mixins: [LanguageSupport],
  data() {
    return {
      canParticipate: false,
      products: null,
      shops: null,
      show_rules: false,
      show_selected_products_error: false,
      /* form field */
      receipt_product: null,
      receipt_type: null,
      receipt_store: null,
      receipt_front_photo: null,
      contest_rules_accepted: false,
      eligible_text: null,
      /* after submit */
      submitted: false,
      spinner: false,
      serverErrors: {},
      globalErrors: [],
    };
  },
  computed: {
    ...mapState(['storeSetupCompleted']),
    ...mapState('gigya', ['loaded', 'user']),
    ...mapState('pm56SandwichSummerCampaign', [
      'legal-age',
      'products-api',
      'stores-api',
      'privacy-check',
      'join-api',
    ]),
    ...mapGetters('pm56SandwichSummerCampaign', ['i18nParticipate']),
    selectedProductsId() {
      return this.$refs.$products.selectedProductsId;
    },
    receiptImg() {
      try {
        return require(`@images/contest-brunch-in-nature/receipt-img-${this.currentLang}.png`);
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    disableSubmit() {
      if (this.$v && (this.$v.$anyError || this.$v.$invalid)) {
        return true;
      }

      return false;
    },
    filteredShops() {
      return this.shops && this.receipt_type
        ? this.shops
            .filter((e) =>
              this.receipt_type === 'electronic' ? e.is_online : !e.is_online
            )
            .map((e) => {
              return { key: e.id, dataValue: e.name };
            })
        : [];
    },
    rulesLabel() {
      // reworked in component library
      let k = 0;
      let j = 0;
      return this.i18nParticipate.rules_label
        .replace(/%/g, () => {
          k++;
          if (k === 1) {
            return `<a class="typo-medium text-color-blue-logo" href='${this.i18nParticipate.rules_href}' target='_blank'>`;
          } else {
            return '</a>';
          }
        })
        .replace(/£/g, () => {
          j++;
          if (j === 1) {
            return `<a class="typo-medium text-color-blue-logo" href='${this.i18nParticipate.privacy_href}' target='_blank'>`;
          } else {
            return '</a>';
          }
        });
    },
    hasGlobalErrors() {
      if (this.globalErrors.length) {
        return this.globalErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  watch: {
    storeSetupCompleted: {
      handler(val) {
        debug && console.log('WATCH storeSetupCompleted', val);
        if (val) {
          const endpointProducts = this['products-api']
            ? this['products-api']
            : 'https://run.mocky.io/v3/8273bebc-3996-4e14-bc97-dddef7273caf';

          axios
            .get(endpointProducts)
            .then((response) => {
              if (response.data) {
                //console.log("response.data", response.data);
                this.products = response.data.data.map((e) => {
                  return { id: e.id, name: e.name };
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });

          const endpointStores = this['stores-api']
            ? this['stores-api']
            : 'https://run.mocky.io/v3/6e69bc05-dbe4-4ae7-a5da-1464ce9e24b6';

          axios
            .get(endpointStores)
            .then((response) => {
              if (response.data) {
                this.shops = response.data.data;
              }
            })
            .catch((e) => {
              console.log(e);
            });

          axios
            .get(this['privacy-check'])
            .then((response) => {
              if (response.data) {
                this.show_rules = !response.data.contest_rule_flag; // return true if the user has already accepted the rules, false otherwise, show the rules only when the returned value is false
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      },
      immediate: true,
    },
    loaded: {
      handler(val) {
        if (val) {
          if (!this.user) {
            this.globalErrors.push({
              condition: true,
              message: this.i18nParticipate.global_error_logged,
            });
          } else if (this.user.age < this['legal-age']) {
            this.globalErrors.push({
              condition: true,
              message: this.i18nParticipate.global_error_legal_age,
            });
          } else {
            this.canParticipate = true;
          }
        }
      },
      immediate: true,
    },
  },
  validations() {
    let validations = {
      // receipt_product: {
      //   required,
      //   serverError: checkServerErrors('receipt_product')
      // },
      receipt_type: {
        required,
        serverError: checkServerErrors('receipt_type'),
      },
      receipt_store: {
        required,
        serverError: checkServerErrors('receipt_store'),
      },
      receipt_front_photo: {
        required,
        mustBeImage: (val) => {
          return (
            !helpers.req(val) ||
            val.type === 'image/jpeg' ||
            val.type === 'image/jpg' ||
            val.type === 'image/png'
          );
        },
        validSize: (val) => {
          const mb = 3 * 1024 * 1024;
          return !helpers.req(val) || val.size <= mb;
        },
        serverError: checkServerErrors('receipt_front_photo'),
      },
      eligible_text: {
        required,
      },
    };

    if (this.show_rules) {
      validations = {
        ...validations,
        contest_rules_accepted: {
          mustBeChecked: (val) => !!val,
          serverError: checkServerErrors('contest_rules_accepted'),
        },
      };
    } else {
      validations = {
        ...validations,
        contest_rules_accepted: {},
      };
    }

    return validations;
  },
  created() {
    this.setInvertedHeader(true);
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    onSetShopType() {
      this.receipt_store = null;
    },
    onFileChange(f) {
      this.receipt_front_photo = f;
      this.$v.receipt_front_photo.$touch();
    },
    onAddProduct() {
      this.show_selected_products_error = false;
    },
    onRemoveProduct() {
      if (this.selectedProductsId.length === 0) {
        this.show_selected_products_error = true;
      }
    },
    async onSubmit() {
      if (
        this.selectedProductsId.length <
        this.i18nParticipate.data_total_products
      ) {
        this.show_selected_products_error = true;
      } else {
        this.show_selected_products_error = false;
      }

      if (!this.submitted && !this.disableSubmit) {
        this.submitted = true;
        this.spinner = true;
        this.globalErrors = [];

        try {
          let formData = {};

          formData.receipt_type = this.receipt_type;
          formData.receipt_store = this.receipt_store;
          formData.eligible_text = this.eligible_text;
          formData.receipt_front_photo = this.$refs.$waUpload.imagePreview;
          formData.receipt_products = this.selectedProductsId;
          formData.contest_rules_accepted = this.contest_rules_accepted;

          //console.log(this.selectedProductsId);
          let result = await axios.post(this['join-api'], formData);

          if (result.data) {
            // Contest completed
            await this.$store.dispatch('setUnlockedView', 'thank-you');
            // Navigate
            await this.$router.push({ name: 'thank-you' });
          }
        } catch (e) {
          let properties = Object.getOwnPropertyNames(e);
          properties.forEach((p) => {
            let prop = Object.getOwnPropertyDescriptor(e, p);
            if (prop && prop.value) {
              if (p === 'response') {
                if (prop.value.status === 400) {
                  // Validation errors
                  /* expected response
                  {"username":["Questo campo non può essere omesso","Questo campo non può essere omesso 2"],"description":["Questo campo non può essere omesso"]};
                   */
                  let remoteErrors = false;
                  let data = prop.value.data;
                  if ('errors' in data) {
                    remoteErrors = true;
                    data = data.errors;
                  }

                  for (const [property, value] of Object.entries(data)) {
                    if (property == 'non_field_errors') {
                      // Global validation errors
                      this.globalErrors.push({
                        condition: true,
                        message: this.i18nParticipate[value]
                          ? this.i18nParticipate[value]
                          : value,
                      });
                    } else {
                      let temp = {};
                      if (!remoteErrors) {
                        temp[property] = this.i18nParticipate[value]
                          ? this.i18nParticipate[value]
                          : value;
                      } else {
                        temp[property] = value;
                      }
                      this.serverErrors = { ...this.serverErrors, ...temp };
                    }
                  }
                  Object.keys(this.serverErrors).forEach((key) => {
                    const unwatch = this.$watch(key, (newVal, oldVal) => {
                      if (newVal !== oldVal) {
                        Vue.delete(this.serverErrors, key);
                        setTimeout(() => {
                          this.$v[key].$touch();
                        }, 0);
                        if (unwatch) {
                          unwatch();
                        }
                      }
                    });
                  });

                  // Renew the submission
                } else if (
                  prop.value.status === 401 ||
                  prop.value.status === 403
                ) {
                  this.globalErrors.push({
                    condition: true,
                    message: prop.value.data.message,
                  });
                } else {
                  // Other errors
                  this.globalErrors.push({
                    condition: true,
                    message: prop.value.statusText,
                  });
                }
                this.submitted = false;
              }
            }
          });
        } finally {
          this.spinner = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaPm56SandwichSummerCampaignVerify {
  .icon {
    width: 48px;
    height: 48px;

    @media (min-width: $bootstrap-md) {
      width: 64px;
      height: 64px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .img-fluid {
    @include media-breakpoint-up(sm) {
      width: 70%;
    }
  }

  .global-error {
    display: block;
    padding-top: 14px;
    color: $color-informative-red;
    text-align: left;

    &.products-error {
      font-size: 13px;
    }
  }
}
</style>
