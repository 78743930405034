export default {
  orderedLanguages: (state) => {
    if (state.languages.length) {
      //we put as the first element of the array the current instance language obj
      const current = state.languages.find(
        (l) => l.site_code === l.language_code
      );
      let filteredArray = state.languages.filter(
        (item) => item.language_code !== current.language_code
      );
      filteredArray.unshift(current);
      return filteredArray;
    } else {
      return [];
    }
  },
  flatData: (state) => {
    return state.pages.reduce((accumulator, page) => {
      return accumulator.concat(page.data);
    }, []);
  },
};
