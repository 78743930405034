import * as types from '../mutation_types';

export default {
  [types.SET_PM5_6_SANDWICH_SURF_INIT_DATA](state, payload) {
    state[payload.key] = payload.value;
  },
  [types.SET_PM5_6_SANDWICH_SURF_I18N_PARTICIPATE](state, payload) {
    Object.keys(payload).forEach((k) => {
      state.i18n.participate[k] = payload[k];
    });
  },
  [types.SET_PM5_6_SANDWICH_SURF_I18N_PRIZE](state, payload) {
    Object.keys(payload).forEach((k) => {
      state.i18n.prize[k] = payload[k];
    });
  },
  [types.SET_PM5_6_SANDWICH_SURF_REGEX](state, payload) {
    Object.keys(payload).forEach((k) => {
      state.regex[k] = payload[k];
    });
  },
};
