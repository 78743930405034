<template>
  <component
    :is="isVariantSmall ? 'a' : 'section'"
    ref="bannerOuter"
    :class="outerClasses"
    :style="outerStyles"
    :href="dataLink !== '' ? dataLink : null"
  >
    <slot name="seo-title" />
    <div ref="bannerInner" :class="innerClasses" :style="innerStyles">
      <div :class="{ overlay: isVariantCenterPhoto || isVariantLeft }">
        <div v-if="!isVariantCenterEmpty" class="container-fluid">
          <div class="row">
            <div v-if="dataUpperSrcImage" class="banner-upper-image">
              <img :src="dataUpperSrcImage" :alt="dataUpperAltImage" />
            </div>

            <div :class="['banner-content', contentCols]">
              <div
                v-if="$_cmsSupport_hasContentSlotWithName('title-small')"
                class="title-small"
              >
                <slot name="title-small" />
              </div>

              <div
                v-if="$_cmsSupport_hasContentSlotWithName('title')"
                class="banner-product-title"
              >
                <slot name="title" />
              </div>

              <div
                v-if="$_cmsSupport_hasContentSlotWithName('wysiwyg')"
                class="banner-desc margin-bottom-s"
              >
                <slot name="wysiwyg" />
              </div>

              <slot />

              <div
                v-if="$_cmsSupport_hasContentSlotWithName('cta')"
                class="banner-cta"
              >
                <slot name="cta" />
              </div>
            </div>

            <div
              v-if="$_cmsSupport_hasContentSlotWithName('product')"
              :class="['banner-product-image', imageCols]"
            >
              <ratio-size-box
                v-if="$_cmsSupport_hasContentSlotWithName('product')"
                data-width-ratio-component="4"
                data-height-ratio-component="3"
                data-vertical-align-content="center"
              >
                <slot name="product" />
              </ratio-size-box>
            </div>
          </div>

          <div v-if="dataLowerSrcImage" class="banner-lower-image">
            <img :src="dataLowerSrcImage" :alt="dataLowerAltImage" />
          </div>
          <div
            v-if="$_cmsSupport_hasContentSlotWithName('vertical-name')"
            class="banner-vertical-name"
          >
            <slot name="vertical-name" />
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
export default {
  name: 'WaBanner',
  components: {},
  props: {
    dataBackgroundColor: {
      type: String,
      default: null,
      required: false,
    },
    dataVariantClass: {
      type: String,
      default: null,
      required: false,
      validator(value) {
        return (
          [
            'small',
            'left',
            'center',
            'product',
            'center-empty',
            'center-photo',
            'empty-repeat-bottom-bg',
          ].indexOf(value) !== -1
        );
      },
    },
    dataHasTopSpecialBorder: {
      type: [String, Boolean],
      default: 'false',
    },
    dataHasBottomSpecialBorder: {
      type: [String, Boolean],
      default: 'false',
    },
    dataBgSrcImage: {
      type: String,
      default: null,
      required: false,
    },
    dataUpperSrcImage: {
      type: String,
      default: null,
      required: false,
    },
    dataUpperAltImage: {
      type: String,
      default: null,
      required: false,
    },
    dataLowerSrcImage: {
      type: String,
      default: null,
      required: false,
    },
    dataLowerAltImage: {
      type: String,
      default: null,
      required: false,
    },
    dataLink: {
      type: String,
      default: null,
      required: false,
    },
    dataBackgroundSize: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      hasTopSpecialBorder: false,
      hasBottomSpecialBorder: false,
    };
  },
  computed: {
    name() {
      return this.$options.name;
    },
    outerClasses() {
      return ['banner-outer', this.$options.name, this.dataVariantClass];
    },
    outerStyles() {
      return {};
    },
    innerClasses() {
      return [
        'banner-inner',
        this
          .dataBackgroundColor /* @TODO modify spec we need data-background-color instead a class for this to apply bg on inner */,
        {
          'has-top-special-border': this.hasTopSpecialBorder,
          'has-bottom-special-border': this.hasBottomSpecialBorder,
          'has-background-image': this.dataBgSrcImage,
          'has-background-color': this.dataBackgroundColor,
          'fixed-height':
            this.isVariantCenterEmpty || this.isVariantEmptyRepeatBottomBg,
        },
      ];
    },
    innerStyles() {
      const styles = {};
      styles.backgroundImage = this.dataBgSrcImage
        ? `url(${this.dataBgSrcImage})`
        : null;

      if (this.dataBackgroundSize.includes('x')) {
        const w =
          this.$currentStep === 'xs'
            ? +this.dataBackgroundSize.split('x')[0] / 2
            : this.dataBackgroundSize.split('x')[0];
        const h =
          this.$currentStep === 'xs'
            ? +this.dataBackgroundSize.split('x')[1] / 2
            : this.dataBackgroundSize.split('x')[1];
        styles.backgroundSize = `${w}px ${h}px`;
      } else {
        styles.backgroundSize = `cover`;
      }

      if (this.isVariantEmptyRepeatBottomBg) {
        styles.height =
          this.$currentStep === 'xs'
            ? `${+this.dataBackgroundSize.split('x')[1] / 2}px`
            : `${this.dataBackgroundSize.split('x')[1]}px`;
      }

      return styles;
    },
    contentCols() {
      let cols = '';
      if (this.isVariantSmall) {
        cols = 'col-12 col-sm-6';
      } else if (this.isVariantLeft) {
        cols = 'col-12 col-sm-8 col-md-6 col-lg-5';
      } else if (this.isVariantProduct) {
        cols = 'col-12 col-md-5';
      } else if (this.isVariantCenter) {
        cols = 'col-12 col-md-10 mx-auto';
      } else if (this.isVariantCenterPhoto) {
        cols = 'col-12 col-sm-10 col-lg-8 mx-auto';
      }
      return cols;
    },
    imageCols() {
      let cols = '';
      if (this.isVariantSmall) {
        cols = 'col-12 col-sm-6';
      } else if (this.isVariantLeft) {
        cols = 'col-12 col-md-6';
      } else if (this.isVariantProduct) {
        cols = 'col-12 col-sm-8 offset-sm-2 offset-md-0 col-md-7';
      } else if (this.isVariantCenter) {
        cols = 'col-12';
      }
      return cols;
    },
    isVariantSmall() {
      return this.dataVariantClass === 'small';
    },
    isVariantLeft() {
      return this.dataVariantClass === 'left';
    },
    isVariantCenter() {
      return this.dataVariantClass === 'center';
    },
    isVariantProduct() {
      return this.dataVariantClass === 'product';
    },
    isVariantCenterEmpty() {
      return this.dataVariantClass === 'center-empty';
    },
    isVariantEmptyRepeatBottomBg() {
      return this.dataVariantClass === 'empty-repeat-bottom-bg';
    },
    isVariantCenterPhoto() {
      return this.dataVariantClass === 'center-photo';
    },
  },
  watch: {
    dataHasTopSpecialBorder: {
      immediate: true,
      handler() {
        this.updateBorders();
      },
    },
    dataHasBottomSpecialBorder: {
      immediate: true,
      handler() {
        this.updateBorders();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      //needed for reactivity in storybook because we use the props just here
      //so on change it doesn't update the data cause it's not a computed
      this.updateBorders();
    });
  },
  methods: {
    updateBorders() {
      if (this.$refs.bannerOuter) {
        this.hasTopSpecialBorder =
          this.$_cmsSupport_castPropToType(
            this.dataHasTopSpecialBorder,
            'boolean'
          ) && !this.$refs.bannerOuter.classList.contains('padding-top-0');
        this.hasBottomSpecialBorder =
          this.$_cmsSupport_castPropToType(
            this.dataHasBottomSpecialBorder,
            'boolean'
          ) && !this.$refs.bannerOuter.classList.contains('padding-bottom-0');
      } else {
        this.hasTopSpecialBorder = this.$_cmsSupport_castPropToType(
          this.dataHasTopSpecialBorder,
          'boolean'
        );
        this.hasBottomSpecialBorder = this.$_cmsSupport_castPropToType(
          this.dataHasBottomSpecialBorder,
          'boolean'
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~$scss/utility';
@import '~bootstrap/scss/mixins/breakpoints';

.WaBanner {
  background-color: $color-white;
  display: block;

  &[href]:focus,
  &[href]:active {
    outline: none;
  }

  &[href]:hover {
    text-decoration: none;
  }

  .banner-inner {
    position: relative;
    display: block;

    // ---------------------------------------
    //  Backgrounds
    // ---------------------------------------

    &.has-background-image {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .overlay {
      background-color: rgba(0, 0, 0, 0.35);
    }
    // ---------------------------------------
    //  Borders
    // ---------------------------------------

    &.has-top-special-border::before,
    &.has-bottom-special-border::after {
      z-index: 1;
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      display: block;
      height: 6px;
      background-size: 2560px 100%;
      background-position: 0 50%;
      background-repeat: no-repeat;
      background-color: transparent;
      background-image: url('~@images/border-jagged-2560.svg');
    }

    &.has-top-special-border::before {
      top: 0;
    }

    &.has-bottom-special-border::after {
      bottom: 0;
      transform: rotate(180deg);
    }
  }

  .container-fluid {
    position: relative;
  }

  // ---------------------------------------
  // Elements
  // ---------------------------------------

  img {
    display: block;
    margin: 0 auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .banner-upper-image {
    z-index: 2;
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translate(-50%);
    width: 170px;
    height: 128px;

    @media (min-width: $bootstrap-sm) {
      left: 122px;
      width: 150px;
      height: 112px;
    }

    @media (min-width: $bootstrap-md) {
      top: -32px;
      left: 125px;
      width: 208px;
      height: 156px;
    }

    @media (min-width: $bootstrap-lg) {
      left: 164px;
      width: 302px;
      height: 226px;
    }
  }

  .banner-lower-image {
    width: 60px;

    @media (max-width: $bootstrap-sm - 1px) {
      margin-top: 32px;
    }

    @media (min-width: $bootstrap-sm) {
      position: absolute;
      bottom: 60px;
      right: 0px;
      width: 80px;
    }

    @media (min-width: $bootstrap-md) {
      bottom: 80px;
      right: 0px;
      width: 100px;
    }

    @media (min-width: $bootstrap-lg) {
      bottom: 80px;
      right: 0px;
      width: 100px;
    }
  }

  .banner-product-image {
    align-items: center;
    justify-content: center;
    /deep/ img {
      filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.2));
    }
  }

  // .banner-vertical-name {
  //   position: absolute;
  //   right: 0;
  //   bottom: 30px;
  //   width: max-content;
  // }

  // ::v-deep .verticalName {
  //   transform-origin: 100% 0;
  //   text-transform: uppercase;
  // }

  // ---------------------------------------
  //  Variants
  // ---------------------------------------

  &.left,
  &.center,
  &.product,
  &.center-photo {
    .container-fluid {
      padding-top: 80px;
      padding-bottom: 80px;

      @media (min-width: $bootstrap-sm) {
        padding-top: 120px;
        padding-bottom: 120px;
      }

      @media (min-width: $bootstrap-md) {
        padding-top: 200px;
        padding-bottom: 200px;
      }
    }

    .banner-product-title {
      margin-bottom: 16px;
      @media (min-width: $bootstrap-sm) {
        margin-bottom: 24px;
      }

      @media (min-width: $bootstrap-md) {
        margin-bottom: 32px;
      }
    }
  }

  &.product,
  &.small {
    @media (max-width: $bootstrap-sm - 1px) {
      text-align: center;
    }
  }

  &.center {
    .container-fluid {
      padding-top: 120px;

      @media (min-width: $bootstrap-md) {
        padding-top: 200px;
      }
    }
  }

  &.center,
  &.center-photo {
    text-align: center;

    ::v-deep {
      .WaCta {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }

  &.product {
    //filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2));
    .container-fluid {
      > .row {
        @media (min-width: $bootstrap-sm) {
          align-items: center;
        }
      }
      @media (min-width: $bootstrap-sm) {
        padding-top: 120px;
        padding-bottom: 120px;
      }
      @media (min-width: $bootstrap-md) {
        padding-top: 200px;
        padding-bottom: 200px;
      }
      @media (min-width: $bootstrap-lg) {
        padding-top: 134px;
        padding-bottom: 134px;
      }
    }

    .banner-content {
      @media (max-width: $bootstrap-md - 1px) {
        order: 1;
        margin-top: 24px;
        text-align: center;
      }
    }

    .banner-product-image {
      @media (min-width: $bootstrap-md) {
        display: flex;
        align-self: ce;
      }
    }

    .banner-cta {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      @media (min-width: $bootstrap-md) {
        align-items: flex-start;
      }
      .WaCta + .WaCta {
        margin-top: 16px;
        @media (min-width: $bootstrap-md) {
          margin-top: 24px;
        }
      }
    }
  }

  &.small {
    text-decoration: none;
    .banner-inner {
      cursor: pointer;
      overflow: hidden;
    }

    .container-fluid {
      padding-top: 40px;
      padding-bottom: 0px;

      > .row {
        @media (min-width: $bootstrap-sm) {
          align-items: center;
        }
      }

      @media (min-width: $bootstrap-sm) {
        padding-top: 64px;
        padding-bottom: 64px;
      }
      @media (min-width: $bootstrap-md) {
        padding-top: 80px;
        padding-bottom: 80px;

        &:hover {
          .banner-product-image {
            transform: translateY(4px);
          }
        }
      }
    }

    .banner-product-image {
      // width: 260px;
      // height: 184px;
      transition: transform 0.3s ease;
      margin-bottom: -22.5vw;
      //filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.2));

      @media (min-width: $bootstrap-sm) {
        margin-bottom: 0;
        // width: 370px;
        // height: 168px;
        //position: absolute;
        //right: 0;
        //top:0;
        //margin-bottom: 0;
        //transform: translateY(24px);
        //bottom: 80px;
      }

      @media (min-width: $bootstrap-md) {
        // top:12px;
        // width: 517px;
        // height: 234px;
        // position: absolute;
        position: absolute;
        right: 0;
        top: 0;
        margin-bottom: 0;
        transform: translateY(24px);
      }

      @media (min-width: $bootstrap-lg) {
        // width: 629px;
        // height: 285px;
        // position: absolute;
      }
    }

    .title-small {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @include media-breakpoint-down(xs) {
          margin-bottom: 8px;
        }
      }
    }
    .banner-product-title {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 24px;
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }
  }

  &.center-empty,
  &.empty-repeat-bottom-bg {
    .fixed-height {
      height: 200px;
      background: no-repeat 50% 0;
      background-size: cover;

      @include media-breakpoint-up(sm) {
        height: 340px;
      }

      @include media-breakpoint-up(md) {
        height: 480px;
      }

      @include media-breakpoint-up(lg) {
        height: 600px;
      }
    }
  }
  &.empty-repeat-bottom-bg {
    .banner-inner.has-background-image {
      background: repeat-x center bottom;
    }
  }

  &.contact-form-banner {
    .container-fluid {
      padding-top: 80px !important;
      padding-bottom: 80px !important;
    }

    .banner-product-title {
      margin-bottom: 18px !important;

      @include media-breakpoint-up(sm) {
        margin-bottom: 28px !important;
      }
    }

    .banner-desc {
      margin-bottom: 38px !important;
    }
  }
}
</style>
