<template>
  <div class="ContactFormThankYouCmp">
    <wa-side-backgrounds
      :data-background-right="`${$root.$context.staticRoot}project/img/contact-form/img-right.jpg`"
      :data-background-left="`${$root.$context.staticRoot}project/img/contact-form/img-left.jpg`"
      data-width-left="302"
      data-height-left="400"
      data-width-right="302"
      data-height-right="400"
    >
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center"
          >
            <svg class="icon text-color-secondary-item" width="48" height="48">
              <use href="#icon-email-send" fill="currentColor" />
            </svg>
            <div
              :class="[
                'title-wrapper',
                'typo-w',
                'typo-w-4',
                'typo-bold',
                'text-center',
                'text-color-secondary-item',
                'padding-top-xs',
                { 'padding-bottom-m': !routes.thank_you.image },
              ]"
              v-html="routes.thank_you.texts.title"
            />
            <div
              v-if="routes.thank_you.image"
              class="image-wrapper padding-top-r padding-bottom-r"
            >
              <img :src="routes.thank_you.image" />
            </div>
            <div
              class="typo-a-7 typo-light text-center text-color-black padding-bottom-m"
            >
              <template v-if="unlockedThankYou.submission_number">
                <span class="d-block">
                  {{ routes.thank_you.texts.numberLabel }}
                </span>
                <span
                  class="d-block typo-w typo-w-4 typo-bold text-color-secondary-item padding-top-s"
                >
                  {{ unlockedThankYou.submission_number }}
                </span>
              </template>
              <span v-else>{{ routes.thank_you.texts.noNumberLabel }}</span>
            </div>
            <wa-cta
              class="primary"
              :data-href="routes.thank_you.controls.doneCta.goToUrl"
            >
              {{ routes.thank_you.controls.doneCta.label }}
            </wa-cta>
          </div>
        </div>
      </div>
    </wa-side-backgrounds>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactFormThankYouCmp',
  computed: {
    ...mapState('contactForm2024', ['routes', 'unlockedThankYou']),
  },
};
</script>

<style lang="scss" scoped>
.ContactFormThankYouCmp {
  padding-top: var(--contact-form-thank-you-padding-top, 0);

  ::v-deep .title-wrapper {
    * {
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 208px;

    img {
      max-width: 100%;
      height: 100%;
    }
  }
}
</style>
