<template>
  <div class="WaCookiePolicy">
    <div id="ot-sdk-cookie-policy"></div>
  </div>
</template>

<script>
/*
PREVIOUS IMPLEMENTATION IN THE TEMPLATE
<template>
  <div class="WaCookiePolicy">
    <js-loader
      class="wa-wysiwyg"
      data-async="true"
      data-extra-attrs="{
        &quot;id&quot;: &quot;wasa-cookie-bot-script-loader&quot;
      }"
      data-src="https://consent.cookiebot.com/5e690b24-b7e5-49ed-a67a-ab00272c8230/cd.js"
    />
  </div>
</template>
*/
export default {
  name: 'WaCookiePolicy',
};
</script>
