var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"WaDropdown form-group__wrapper"},[_c('div',{class:['form-group', { disabled: _vm.dataDisabled }]},[_c('div',{class:['form-control__wrapper', 'typo-a-9', 'typo-light']},[_c('label',{class:['form__label', { focus: _vm.isFocus || _vm.value }]},[_vm._v(" "+_vm._s(_vm.dataLabel)+" ")]),_c('div',{class:[
          'frame',
          { focus: _vm.isFocus, filled: _vm.value, error: _vm.dataErrorOccurred } ]},[_c('select',{class:[
            'typo-a-9',
            'typo-light',
            'form-control',
            'form__select',
            {
              'focus': _vm.isFocus,
              'filled': _vm.value,
              'error': _vm.dataErrorOccurred,
              'alter-selected-variant': _vm.dataAlternativeSelectedValue,
            } ],attrs:{"id":_vm.dataId,"aria-label":_vm.dataLabel,"name":_vm.dataName,"disabled":_vm.dataDisabled,"required":_vm.dataPlaceholder},on:{"focus":function($event){_vm.isFocus = true},"blur":function($event){_vm.isFocus = false},"change":_vm.onUpdate}},[(_vm.dataPlaceholder)?_c('option',{attrs:{"value":""}},[_vm._v(" "+_vm._s(_vm.dataPlaceholder)+" ")]):_vm._e(),_vm._l((_vm.dataOptionList),function(option,i){return _c('option',{key:("option-" + i),domProps:{"value":option.key,"selected":_vm.useInt ? +option.key === +_vm.value : option.key === _vm.value}},[_vm._v(" "+_vm._s(option.dataValue)+" ")])})],2)]),(_vm.dataAlternativeSelectedValue)?_c('div',{staticClass:"alter-selected"},[(_vm.value)?_c('span',[_vm._v(" "+_vm._s(_vm.dataOptionList.find(function (e) { return +e.key === +_vm.value; }) .dataAlternativeSelectedValue)+" ")]):_vm._e()]):_vm._e(),(!_vm.dataErrorOccurred)?_c('svg',{staticClass:"form__icon",attrs:{"width":"24","height":"24"}},[_c('use',{attrs:{"href":"#icon-arrow-angle-down","fill":"currentColor"}})]):_vm._e(),(_vm.dataErrorOccurred)?_c('div',{staticClass:"form__error"},[_c('svg',{attrs:{"width":"24","height":"24"}},[_c('use',{attrs:{"href":"#icon-triangle-error","fill":"currentColor"}})]),_vm._l((_vm.hasErrors),function(error,i){return _c('span',{key:("error-" + i),staticClass:"form__error--text",domProps:{"innerHTML":_vm._s(error.message)}})})],2):_vm._e(),(_vm.dataHelperText)?_c('div',{staticClass:"form__helperText"},[_vm._v(" "+_vm._s(_vm.dataHelperText)+" ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }