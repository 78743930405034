<template>
  <div class="WaSideBySideRecipes">
    <div class="sideBySideWrapper container-fluid">
      <div class="row">
        <!-- Immagine step mobile e desktop left -->
        <div
          v-if="
            hasImageSlot &&
            ($currentStep === 'xs' ||
              ($currentStep !== 'xs' && vcls.includes('left-image')))
          "
          class="col-12 col-sm-5 col-md-4 d-sm-flex align-items-sm-center justify-content-sm-end"
        >
          <div
            ref="$left"
            :class="[imageStylesBySide, getFadeAnimation(vcls, 'sideImage')]"
          >
            <ratio-size-box
              data-crop="cover"
              :data-width-ratio-component="
                $currentStep === 'xs' ? dataRatioWidthMobile : dataRatioWidth
              "
              :data-height-ratio-component="
                $currentStep === 'xs' ? dataRatioHeightMobile : dataRatioHeight
              "
              data-vertical-align-content="center"
            >
              <slot name="image" />
            </ratio-size-box>
          </div>
        </div>

        <!-- Contenuto left o right -->
        <div
          :class="[
            'col-12 col-sm-6 d-sm-flex align-items-sm-center',
            {
              'offset-sm-1': vcls.includes('left-image'),
              'offset-md-1':
                vcls.includes('right-image') &&
                (imageRatioIsVertical || $currentStep === 'lg'),
              'padding-top-m': $currentStep === 'xs',
            },
          ]"
        >
          <div
            :ref="vcls.includes('right-image') ? '$left' : '$right'"
            :class="
              $currentStep === 'xs'
                ? null
                : ['sideText', getFadeAnimation(vcls, 'sideText'), vcls]
            "
          >
            <div
              v-if="hasTitleSlot"
              :class="['title typo-light margin-bottom-r', 'typo-w-4']"
            >
              <slot name="title" />
            </div>
            <div v-if="hasWysiwygSlot" class="contentText typo-light typo-a-7">
              <slot name="wysiwyg" />
            </div>
          </div>
        </div>

        <!-- Immagine step desktop right -->
        <div
          v-if="
            hasImageSlot &&
            $currentStep !== 'xs' &&
            vcls.includes('right-image')
          "
          class="col-12 col-sm-5 col-md-4 offset-1 d-flex align-items-center justify-content-start"
        >
          <div
            ref="$right"
            :class="[imageStylesBySide, getFadeAnimation(vcls, 'sideImage')]"
          >
            <ratio-size-box
              data-crop="cover"
              :data-width-ratio-component="
                $currentStep === 'xs' ? dataRatioWidthMobile : dataRatioWidth
              "
              :data-height-ratio-component="
                $currentStep === 'xs' ? dataRatioHeightMobile : dataRatioHeight
              "
              data-vertical-align-content="center"
            >
              <slot name="image" />
            </ratio-size-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imagesLoaded from 'imagesloaded';
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'WaSideBySideRecipes',
  props: {
    dataVariantClass: {
      type: String,
      default: 'left-image',
    },
    dataRatioWidth: {
      type: [Number, String],
      default: 2,
    },
    dataRatioHeight: {
      type: [Number, String],
      default: 3,
    },
    dataRatioWidthMobile: {
      type: [Number, String],
      default: 1,
    },
    dataRatioHeightMobile: {
      type: [Number, String],
      default: 1,
    },
  },
  data() {
    return {
      onGoingAnimations: [],
    };
  },
  computed: {
    vcls() {
      return this.dataVariantClass || '';
    },
    imageRatioIsVertical() {
      const ratioW = this.$_cmsSupport_castPropToType(
        this.dataRatioWidth,
        'number'
      );
      const ratioH = this.$_cmsSupport_castPropToType(
        this.dataRatioHeight,
        'number'
      );
      return ratioW / ratioH < 1;
    },
    imageStylesBySide() {
      if (this.$currentStep !== 'xs') {
        return `sideImage ${this.vcls}`;
      }
      return '';
    },
    // SLOTS CHECKERS
    hasTitleSlot() {
      return !!(this.$slots['title'] || [])[0];
    },
    hasWysiwygSlot() {
      return !!(this.$slots['wysiwyg'] || [])[0];
    },
    hasImageSlot() {
      return !!(this.$slots['image'] || [])[0];
    },
  },
  watch: {
    $currentStep: {
      handler() {
        this.handleScroll();
      },
      immediate: true,
    },
  },
  created() {
    // SETTING LISTENER
    window.addEventListener('scroll', this.handleScroll);
  },
  beforedestroy() {
    // DESTROY LISTENER
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    //  WRAPPER METHOD FOR ANIMATIONS
    handleScroll() {
      // animations during scroll
      this.$nextTick(() => {
        imagesLoaded(this.$el, () => {
          this.handleAnimations();
        });
      });
    },
    //  ANIMATES ELEMENT
    handleAnimations() {
      if (this.vcls.includes('right-image')) {
        this.animateElement(this.$refs.$left, 'sideText');
        this.animateElement(this.$refs.$right, 'sideImage');
      } else {
        this.animateElement(this.$refs.$left, 'sideImage');
        this.animateElement(this.$refs.$right, 'sideText');
      }
    },
    animateElement(element, type) {
      if (element && !this.onGoingAnimations.includes(element)) {
        const isElementInViewport = this.isElementXPercentInViewport(
          element,
          33
        );
        const isElementAnimable = element.classList.contains(type);

        if (isElementInViewport && isElementAnimable) {
          const animClass = this.getFadeAnimation(this.vcls, type);
          this.onGoingAnimations.push(element);
          animClass === 'fadeRight'
            ? anime({
                targets: element,
                right: 0,
                opacity: 1,
                duration: 1000,
                easing: 'cubicBezier(.455, .030, .515, .955)',
                complete: () => {
                  element.classList.remove('fadeRight');
                },
              })
            : anime({
                targets: element,
                left: 0,
                opacity: 1,
                duration: 1000,
                easing: 'cubicBezier(.455, .030, .515, .955)',
                complete: () => {
                  element.classList.remove('fadeLeft');
                },
              });
        }
      }
    },
    // SAYS IF ELEMENT IS IN VIEWPORT
    isElementXPercentInViewport(el, percentVisible) {
      const rect = el.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      return !(
        Math.floor(
          100 - ((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100
        ) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
          percentVisible
      );
    },
    // CHOOSE ANIMATION DIRECTION
    getFadeAnimation(variant, type) {
      let classToAdd = null;

      if (variant.includes('right-image')) {
        if (type === 'sideText') {
          if (!this.onGoingAnimations.includes(this.$refs.$left)) {
            classToAdd = 'fadeLeft';
          }
        } else if (type === 'sideImage') {
          if (!this.onGoingAnimations.includes(this.$refs.$right)) {
            classToAdd = 'fadeRight';
          }
        }
      } else if (variant.includes('left-image')) {
        if (type === 'sideText') {
          if (!this.onGoingAnimations.includes(this.$refs.$right)) {
            classToAdd = 'fadeRight';
          }
        } else if (type === 'sideImage') {
          if (!this.onGoingAnimations.includes(this.$refs.$left)) {
            classToAdd = 'fadeLeft';
          }
        }
      }

      return classToAdd;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables.scss';

.WaSideBySideRecipes {
  .sideBySideWrapper {
    /* All step */
    .small_title {
      color: $color-secondary-cyan;
    }

    .title {
      font-family: $font-secondary;
      color: $color-secondary-cyan;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      div {
        font-size: inherit;
      }
    }

    .contentText {
      /deep/ *:last-child {
        margin-bottom: 0;
      }
    }

    /* Step xs */
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    /* Step sm md lg xl */
    .sideImage,
    .sideText {
      position: relative;

      /* ANIMATION CLASSES (starters, will be animated with animejs on scroll) */
      &.fadeRight,
      &.fadeLeft {
        opacity: 0;
      }

      &.fadeRight {
        right: -100%;
      }

      &.fadeLeft {
        left: -100%;
      }
    }

    .sideImage {
      height: auto;
      width: 100%;
      border-radius: 4px;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &.right-image {
        img {
          left: 0;
          border-radius: 4px;
        }
      }

      &.left-image {
        img {
          right: 0;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
