var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"heroOuter",class:_vm.outerClasses,style:(_vm.outerStyles)},[_vm._t("seo-title"),_c('div',{directives:[{name:"view",rawName:"v-view",value:(_vm.visibilityChanged),expression:"visibilityChanged"}],ref:"$heroInner",class:_vm.innerClasses},[(_vm.bgImage && (!_vm.isVariantCenterSmall || _vm.$viewportWidth >= 768))?_c('div',{staticClass:"bg"},[_c('img',{ref:"$background",attrs:{"src":_vm.bgImage,"alt":"background"}})]):_vm._e(),(_vm.isVariantProduct && _vm.$viewportWidth > 767)?_c('div',{class:['product-image-effect', { noTransition: _vm.noTransition, fxEndActive: _vm.fxEndActive }],style:(_vm.fxPosition)},[_c('div',{staticClass:"image",style:({ backgroundImage: ("url(" + (_vm.relatedProduct.image_unpacked) + ")") })},[_c('span',{staticClass:"sr-only"},[_vm._v(" "+_vm._s(_vm.relatedProduct.image_unpacked_alternate)+" ")])])]):_vm._e(),_c('div',{staticClass:"hero-container container-fluid"},[(
          _vm.$_cmsSupport_hasContentSlotWithName('title') && _vm.isVariantProduct
        )?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('div',{ref:"heroTitle",class:[
              'hero-title typo-w text-center typo-heavy margin-bottom-0',
              _vm.isVariantProduct ? 'typo-w-1' : 'typo-w-2' ]},[_vm._t("title")],2)]),_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"product-image"},[_c('img',{class:[{ hideProduct: _vm.hideProduct }],attrs:{"src":_vm.relatedProduct.image_packed,"alt":_vm.relatedProduct.image_packed_alternate}}),(_vm.isVariantProduct && _vm.$viewportWidth > 767)?_c('div',{ref:"$fxStart",staticClass:"fx-start-placeholder",style:(_vm.fx)}):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"row justify-content-between"},[_c('div',{ref:"heroContent",class:['hero-content', _vm.contentCols]},[(_vm.$_cmsSupport_hasContentSlotWithName('title-small'))?_c('div',{staticClass:"hero-title-small typo-medium",class:{
              'typo-w typo-w-4': _vm.isVariantProduct,
              'typo-a typo-a-6': !_vm.isVariantProduct,
            }},[_vm._t("title-small")],2):_vm._e(),(
              _vm.$_cmsSupport_hasContentSlotWithName('title') &&
              !_vm.isVariantProduct
            )?_c('div',{class:[
              'hero-title typo-bold margin-top-xs',
              {
                'typo-w-2':
                  (_vm.isVariantCenter && !_vm.isSlide) ||
                  (_vm.isVariantCenterSmall && !_vm.isSlide),
                'typo-w-3': (_vm.isVariantLeft && !_vm.isSlide) || _vm.isSlide,
              } ]},[_vm._t("title")],2):_vm._e(),(_vm.$_cmsSupport_hasContentSlotWithName('description'))?_c('div',{staticClass:"hero-description typo-a typo-a-6 typo-light margin-top-r"},[(_vm.isVariantLeft)?[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-8"},[_vm._t("description")],2)])])]:[_vm._t("description")]],2):_vm._e(),(_vm.$_cmsSupport_hasContentSlotWithName('social-share'))?_c('div',{staticClass:"hero-social-share margin-top-s"},[_vm._t("social-share")],2):_vm._e(),(_vm.$_cmsSupport_hasContentSlotWithName('cta'))?_c('div',{class:[
              'cta-wrapper',
              {
                'margin-top-m': _vm.isVariantCenter,
                'margin-top-s': _vm.isVariantLeft,
              } ]},[_vm._t("cta")],2):_vm._e(),_vm._t("common"),(_vm.$_cmsSupport_hasContentSlotWithName('cta-buy-now'))?_c('div',{class:[
              'cta-wrapper',
              {
                'margin-top-m': _vm.isVariantCenter,
                'margin-top-s': _vm.isVariantLeft,
              } ]},[_vm._t("cta-buy-now")],2):_vm._e()],2),(_vm.isVariantProduct && _vm.$viewportWidth > 767)?_c('div',{staticClass:"col-12 col-sm-6 col-sm-offset-1 col-lg-offset-2"},[_c('div',{directives:[{name:"view",rawName:"v-view",value:(_vm.fxEndVisibilityChanged),expression:"fxEndVisibilityChanged"}],ref:"$fxEnd",staticClass:"fx-end-placeholder"})]):_vm._e()]),(_vm.hasArrow)?_c('figure',{ref:"arrowDown",class:[
          'link-arrow-down',
          _vm.dataArrowDownColorClass,
          { 'is-visible': _vm.isVisible, 'has-been-shown': _vm.hasBeenShown } ]},[_c('svg',{staticClass:"icon-arrow-down",attrs:{"width":"24","height":"24"}},[_c('use',{attrs:{"href":"#icon-arrow-down","fill":"currentColor"}})])]):_vm._e(),(_vm.$_cmsSupport_hasContentSlotWithName('vertical-name'))?_c('div',[_vm._t("vertical-name")],2):_vm._e()])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }