<template>
  <div class="ContactFormTicketingFirstLevelCmp">
    <template v-if="skipToFormCtas">
      <router-link
        v-for="cta in skipToFormCtas"
        :key="`list-item-${cta.id}`"
        :to="{
          name: getRouteName(
            ['triage_type', 'triage_topic'],
            queryString,
            routes,
            cta
          ),
          query: {
            ...queryString,
            ticketing_first_level: cta.slug,
            ticketing_third_level: cta.product_id,
            page_order: `${queryString.page_order},${routes.ticketing_first_level.name}`,
          },
        }"
        class="list-item"
      >
        <wa-cta-box class="contact-form-cta-box text-color-blue-logo">
          <template #title>
            <h4 class="typo-a typo-a-6 typo-medium margin-bottom-0">
              {{ cta.label }}
            </h4>
          </template>
        </wa-cta-box>
      </router-link>
    </template>
    <router-link
      v-for="item in items"
      :key="`list-item-${item.slug}`"
      class="list-item"
      :to="{
        name: routes.ticketing_first_level.controls.selectionCtasFrom
          .selectionCtasGoToName,
        query: {
          ...queryString,
          ticketing_first_level: item.slug,
          page_order: `${queryString.page_order},${routes.ticketing_first_level.name}`,
        },
      }"
    >
      <wa-cta-box class="contact-form-cta-box text-color-blue-logo">
        <template #title>
          <h4 class="typo-a typo-a-6 typo-medium margin-bottom-0">
            {{ item.label }}
          </h4>
        </template>
      </wa-cta-box>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContactForm2024Mixin from '../../mixins/contact_form_2024_mixin';

export default {
  name: 'ContactFormTicketingFirstLevelCmp',
  mixins: [ContactForm2024Mixin],
  computed: {
    ...mapState('contactForm2024', ['routes', 'queryString']),
    items() {
      const modelName =
        this.routes?.ticketing_first_level?.controls?.selectionCtasFrom
          ?.selectionCtasItems;

      const first_level_group = modelName
        ? this.$store?.state?.contactForm2024[modelName]
        : [];

      return first_level_group.find(
        (e) => e.country === this.queryString?.ticketing_country
      )?.items;
    },
    skipToFormCtas() {
      return this.getActiveSkipCtas(
        'ticketing_first_level',
        ['triage_type', 'triage_topic'],
        this.queryString,
        this.routes
      );
    },
  },
  mounted() {
    // redirect to home if no products
    if (this.items && this.items.length == 0) {
      this.$router.replace({
        path: '/',
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.ContactFormTicketingFirstLevelCmp {
  .list-item {
    display: block;
    text-decoration: none;

    & ~ .list-item {
      padding-top: var(--contact-form-list-item-padding-top, 0);
    }
  }
}
</style>
