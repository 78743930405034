import Vue from 'vue';
import { debug, colorLog, prohibited, pop } from '@helpers/utils.js';
const localDebug = false; //debug
const PowerReviewsSupport = Vue.extend({
  data() {
    return {
      isPowerReviewActive: true,
      arePowerReviewConfsReady: false,
      allComponentsMounted: false,
    };
  },
  created() {
    window.widgetToMount = window.widgetToMount || [];
    window.widgetToUnmount = window.widgetToUnmount || [];
  },
  mounted() {
    window.addEventListener(
      'allComponentsMounted',
      () => {
        this.allComponentsMounted = true;
      },
      false
    );
    this.updateBasicConfs();
    this.$watch(
      () => this.allComponentsMounted,
      (newVal) => {
        if (newVal) {
          this.arePowerReviewConfsReady = true;
          setTimeout(() => {
            this.mountWidgets();
          }, 0);
        }
      },
      { immediate: true }
    );
  },
  methods: {
    unmountWidgets() {
      if (this.getWidgetsToUnmount().length) {
        colorLog({
          icon: prohibited,
          label: 'POWERREVIEWS',
          message: `Unmount`,
          theme: {
            backgroundColorLeft: '#cf4330',
            backgroundColorRight: '#e8a522',
            colorLeft: '#ffffff',
            colorRight: '#ffffff',
          },
        });
        localDebug && console.log(this.getWidgetsToUnmount());
        const pwr = this.getPwr();
        const confs = this.getWidgetsToUnmount();
        confs.forEach((id) => {
          pwr('unmount', id);
        });
        this.setWidgetsToUnmount([]);
      }
    },
    mountWidgets() {
      console.log(this.getWidgetsToMount());
      if (this.getWidgetsToMount().length) {
        colorLog({
          icon: pop,
          label: 'POWERREVIEWS',
          message: `Render`,
          theme: {
            backgroundColorLeft: '#63b6e1',
            backgroundColorRight: '#6db0a5',
            colorLeft: '#ffffff',
            colorRight: '#ffffff',
          },
        });
        localDebug && console.log(this.getWidgetsToMount());
        const pwr = this.getPwr();
        const confs = this.getWidgetsToMount();
        /**
         * render: function(e)
         * unmount: function(e)
         * data:
         * redraw: function() {
         * debug: function() {
         * configure: function(e) {
         */

        pwr('render', confs);
        this.setWidgetsToMount([]);
      }
    },
    getPwr() {
      return window.pwr;
    },
    getWidgetsToUnmount() {
      return window.widgetToUnmount;
    },
    getWidgetsToMount() {
      return window.widgetToMount;
    },
    setWidgetsToUnmount(widgetToUnmount) {
      window.widgetToUnmount = widgetToUnmount;
      localDebug && console.log('unsettati', window.widgetToUnmount);
    },
    setWidgetsToMount(widgetToMount) {
      window.widgetToMount = widgetToMount;
      localDebug && console.log('settati', window.widgetToMount);
    },
    getBaseConfParams() {
      return {
        api_key: window.prConfHelper.api_key,
        locale: window.prConfHelper.locale,
        merchant_group_id: window.prConfHelper.merchant_group_id,
        merchant_id: window.prConfHelper.merchant_id,
        on_render: function (config, data) {
          localDebug && console.log('PR->on_render');
          localDebug && console.log(config);
          localDebug && console.log(data);
        },
      };
    },
    getFeedlessBaseConf() {
      return window.prConfHelper.product
        ? {
            product: {
              name: window.prConfHelper.product.name,
              url: window.prConfHelper.product.url,
              image_url: window.prConfHelper.product.image_url,
              description: window.prConfHelper.product.description,
              category_name: window.prConfHelper.product.category_name,
              manufacturer_id: window.prConfHelper.product.manufacturer_id,
              upc: window.prConfHelper.product.upc,
              brand_name: window.prConfHelper.product.brand_name,
              //price: window.prConfHelper.product.name /* optional */,
              //in_stock: window.prConfHelper.product.name /* optional */,
            },
          }
        : {};
    },
    getReviewDisplayBaseConf() {
      return {
        ...this.getBaseConfParams(),
        page_id: window.prConfHelper.page_id,
        review_wrapper_url: window.review_wrapper_url,
        // REVIEW_DISPLAY_SNAPSHOT_TYPE:
        //   window.prConfHelper.REVIEW_DISPLAY_SNAPSHOT_TYPE,
        // ENABLE_CLIENT_SIDE_STRUCTURED_DATA:
        //   window.prConfHelper.ENABLE_CLIENT_SIDE_STRUCTURED_DATA,
        // ENABLE_DEMOGRAPHIC_DISPLAY:
        //   window.prConfHelper.ENABLE_DEMOGRAPHIC_DISPLAY,
        // ENABLE_REVIEW_SNIPPET_SLIDER:
        //   window.prConfHelper.ENABLE_REVIEW_SNIPPET_SLIDER,
        // ENABLE_ENHANCED_SNAPSHOT_STYLES:
        //   window.prConfHelper.ENABLE_ENHANCED_SNAPSHOT_STYLES,
        // cd4_review_image_gallery_style:
        //   window.prConfHelper.cd4_review_image_gallery_style,
        // ENABLE_CONTENT_COLLECTION_MODAL:
        //   window.prConfHelper.ENABLE_CONTENT_COLLECTION_MODAL,
        // REVIEW_MEDIA_MODAL_STYLE: window.prConfHelper.REVIEW_MEDIA_MODAL_STYLE,
        // pr_ENABLE_REVIEW_FILTERING:
        //   window.prConfHelper.pr_ENABLE_REVIEW_FILTERING,
        ...this.getFeedlessBaseConf(),
      };
    },
    getCategorySnippetBaseConf() {
      return {
        ...this.getBaseConfParams(),
      };
    },
    updateBasicConfs() {
      /**
       * https://help.powerreviews.com/hc/en-us/articles/7305388503579-JavaScript-Reference-Guide
       * CategorySnippet
       * ReviewSnippet
       * ReviewDisplay
       * ReviewSnapshot
       * ReviewList
       * QuestionSnippet
       * QuestionDisplay
       * ReviewImageSnippet
       * ReviewImageDisplay
       * Write
       * AddToCart
       */
    },
  },
});

export default PowerReviewsSupport;
