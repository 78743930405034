<template>
  <div class="WaContestPrivateArea">
    <div class="container-fluid padding-bottom-m padding-top-m">
      <div class="row justify-content-center">
        <div
          class="col-12 d-flex flex-column align-items-center justify-content-center"
        >
          <p
            v-if="!selectedContest"
            class="label-divider no-back text-color-secondary-item typo-a-9"
          >
            {{ $store.state.config.personal_area_camper.contest_title_1 }}
          </p>

          <p
            v-if="selectedContest && !selectedChance"
            class="label-divider text-color-secondary-item typo-a-9"
            @click="selectedContest = null"
          >
            <svg width="16" height="16">
              <use href="#icon-arrow-left" fill="currentColor" />
            </svg>
            {{ $store.state.config.personal_area_camper.contest_title_2 }}
          </p>

          <p
            v-if="selectedContest && selectedChance"
            class="label-divider text-color-secondary-item typo-a-9"
            @click="selectedChance = null"
          >
            <svg width="16" height="16">
              <use href="#icon-arrow-left" fill="currentColor" />
            </svg>
            {{ $store.state.config.personal_area_camper.contest_title_3 }}
          </p>
          <wa-regular-divider
            style="width: 100%"
            class="padding-top-xs padding-bottom-s text-color-secondary-item"
          />
          <span class="typo-a-9 text-color-grey-9 typo-light">
            {{ $store.state.config.personal_area_camper.contest_heading }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="!selectedChance" class="container-fluid">
      <div v-if="!selectedContest" class="row justify-content-center">
        <div
          v-for="contest in dataContests.contests"
          :key="'contest-' + contest.id"
          class="col-12 col-md-5 d-flex flex-column align-items-left justify-content-center text-color-sport-blue margin-bottom-r"
        >
          <wa-generic-card
            class="contest-card"
            :data-conf="{
              card_img_src: contest.img,
              card_title: contest.name,
              card_small_title: contest.start_date + ' - ' + contest.end_date,
            }"
            :data-alternative-ratio="true"
          />
          <wa-cta
            class="link margin-top-s"
            @click.prevent="selectContest(contest)"
          >
            {{ $store.state.config.personal_area_camper.detail_button }}
            <svg style="margin-left: 10px" width="16" height="16">
              <use href="#icon-arrow-angle-right" fill="currentColor" />
            </svg>
          </wa-cta>
        </div>
      </div>
      <div v-if="selectedContest" class="row justify-content-center">
        <div
          v-for="(chance, i) in selectedContest.receipts"
          :key="'chance-' + chance.playdata.code"
          class="col-12 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-sport-blue"
        >
          <wa-cta-box
            :key="`button-${i}-${_uid}`"
            style="width: 100%"
            class="text-color-blue-logo big margin-bottom-r"
            data-id-icon="icon-contest"
            @click.prevent="selectedChance = chance"
          >
            <template slot="title">
              <h4 class="typo-a typo-a-6 typo-regular margin-bottom-0">
                {{ selectedContest.name }}
              </h4>
            </template>
            <template slot="text">
              <p
                class="typo-a typo-a-9 typo-light text-color-black margin-bottom-0"
              >
                {{ displayDateFull(chance) }}
              </p>
            </template>
          </wa-cta-box>
        </div>
      </div>
    </div>
    <div v-if="selectedChance" class="container-fluid">
      <div class="row justify-content-center">
        <div class="container-fluid padding-bottom-m">
          <div class="row justify-content-center">
            <div
              class="col-12 col-md-10 d-flex flex-column align-items-center justify-content-center"
            >
              <p
                class="typo-a-9 typo-heavy text-color-blue-logo detail-list-title"
              >
                Winning description
              </p>
              <div
                v-if="displayDate(selectedChance)"
                class="detail-wrapper padding-bottom-s"
              >
                <div class="detail-heading typo-a-9 padding-top-s">
                  <div class="detail-title text-color-grey-9">Winning date</div>
                  <div class="detail-date-cta">
                    <div class="detail-date text-color-grey-8">
                      {{ displayDate(selectedChance) }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="displayTime(selectedChance)"
                class="detail-wrapper padding-bottom-s"
              >
                <div class="detail-heading typo-a-9 padding-top-s">
                  <div class="detail-title text-color-grey-9">Winning hour</div>
                  <div class="detail-date-cta">
                    <div class="detail-date text-color-grey-8">
                      {{ displayTime(selectedChance) }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- HIDDEN -->
              <div
                v-if="
                  selectedChance.playdata.euros &&
                  selectedChance.playdata.cents &&
                  false
                "
                class="detail-wrapper padding-bottom-s"
              >
                <div class="detail-heading typo-a-9 padding-top-s">
                  <div class="detail-title text-color-grey-9">
                    Winning Amount
                  </div>
                  <div class="detail-date-cta">
                    <div class="detail-date text-color-grey-8">
                      € {{ selectedChance.playdata.euros }},{{
                        selectedChance.playdata.cents
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- HIDDEN -->
              <div
                v-if="selectedChance.playdata.number && false"
                class="detail-wrapper padding-bottom-s"
              >
                <div class="detail-heading typo-a-9 padding-top-s">
                  <div class="detail-title text-color-grey-9">
                    Winning number
                  </div>
                  <div class="detail-date-cta">
                    <div class="detail-date text-color-grey-8">
                      {{ selectedChance.playdata.number }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="selectedChance.status"
                class="detail-wrapper padding-bottom-s"
              >
                <div class="detail-heading typo-a-9 padding-top-s">
                  <div class="detail-title text-color-grey-9">
                    Winning status
                  </div>
                  <div class="detail-date-cta">
                    <div class="detail-cta">
                      {{ selectedChance.status }}
                      <!--
                      <wa-cta class="primary loadMoreBtn">submit form</wa-cta>
                      -->
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="selectedChance.prize"
                class="detail-wrapper padding-bottom-s"
              >
                <div class="detail-heading typo-a-9 padding-top-s">
                  <div class="detail-title text-color-grey-9">Prize</div>
                </div>
                <div
                  class="detail-desc text-color-grey-9 typo-light typo-a-7 padding-top-s"
                >
                  {{ selectedChance.prize }}
                </div>
              </div>
              <div
                v-if="selectedChance.info"
                class="detail-wrapper padding-bottom-s"
              >
                <div class="detail-heading typo-a-9 padding-top-s">
                  <div class="detail-title text-color-grey-9">Info to know</div>
                </div>
                <div
                  class="col-10 detail-desc text-color-grey-9 typo-light typo-a-9 padding-top-s"
                >
                  {{ selectedChance.info }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debug, qsObj } from '@helpers/utils';
import { mapState } from 'vuex';

export default {
  name: 'WaContestPrivateArea',
  props: {
    dataContests: { type: Object, required: true },
  },
  data() {
    return {
      selectedContest: null,
      selectedChance: null,
    };
  },
  methods: {
    displayDate(chance) {
      return chance?.play_date?.user_friendly_format?.partial
        ? chance.play_date.user_friendly_format.partial
        : '';
    },
    displayTime(chance) {
      if (chance?.play_date?.user_friendly_format?.full) {
        const time_split =
          chance.play_date.user_friendly_format.full.split(' ')[1];
        return time_split;
      }
      return '';
    },
    displayDateFull(chance) {
      return chance?.play_date?.user_friendly_format?.full
        ? chance.play_date.user_friendly_format.full
        : '';
    },
    selectContest(contestName) {
      this.selectedContest = contestName;
    },
    getGenericCardConf(item) {
      return Object.assign({}, item, {
        card_href: item.detail_url,
        card_img_src: item.img_small,
        card_img_alt: item.alt_text,
        card_title: item.title,
        card_small_title: item.publication_start,
        card_title_color: 'text-color-black',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaContestPrivateArea {
  .detail-list-title {
    width: 100%;
    text-align: left;
  }
  .detail-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid $color-grey-4;
    .detail-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .detail-title {
      }
      .detail-date-cta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
    .detail-desc {
      padding-left: 0px;
      padding-right: 0px;
      width: 100%;
    }
  }
  .label-divider {
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    &.no-back {
      cursor: default;
    }
    svg {
      margin-right: 5px;
    }
  }
  .contest-card {
    width: 100%;
  }
}
</style>
