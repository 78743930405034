import Vue from 'vue';

const HeaderSupport = Vue.extend({
  data() {
    return {
      header: null,
      localH: 0,
      headerH: 0,
    };
  },
  computed: {
    computedStyles() {
      return {
        marginTop: `-${this.localH}`,
      };
    },
  },
  watch: {
    $viewportWidth: {
      immediate: true,
      handler() {
        this.setHeight();
      },
    },
  },
  methods: {
    enableUnderHeaderPositioning() {
      const headerEl = this.header.$el;
      this.localH = this.header.currentOverlappingHeight;
      if (this.headerH !== this.localH) {
        this.headerH = this.localH;
        if (
          document.getElementById('wa-main-content') &&
          !this.$root.isPrintPage
        ) {
          document.getElementById(
            'wa-main-content'
          ).style.marginTop = `-${this.localH}`;
        }
      }
    },
    setHeight() {
      if (this.header === null) {
        (() => {
          let doCheck = () => {
            const headerCmp = this.$root.$children.find(
              (c) => c && c.$options && c.$options.name === 'WaHeader'
            );
            if (!headerCmp) {
              setTimeout(doCheck, 100);
            } else {
              this.header = headerCmp;
              this.enableUnderHeaderPositioning();
            }
          };
          doCheck();
        })();
      } else {
        this.enableUnderHeaderPositioning();
      }
    },
  },
});

export default HeaderSupport;
