import { render, staticRenderFns } from "./wa-free-text.vue?vue&type=template&id=118cb20e&scoped=true&functional=true&"
import script from "./wa-free-text.vue?vue&type=script&lang=js&"
export * from "./wa-free-text.vue?vue&type=script&lang=js&"
import style0 from "./wa-free-text.vue?vue&type=style&index=0&id=118cb20e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "118cb20e",
  null
  
)

export default component.exports