<template>
  <div class="WaLocalConfig d-none" aria-hidden="true" />
</template>

<script>
export default {
  name: 'WaLocalConfig',
  props: {
    dataConfig: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    conf() {
      return typeof this.dataConfig === 'string'
        ? JSON.parse(this.dataConfig)
        : this.dataConfig;
    },
  },
  mounted() {
    this.$store.dispatch('config/setConfig', this.conf);
    this.$store.dispatch('setPageSetupCompleted', true);
  },
};
</script>

<style scoped></style>
