/* Mutations, actions and getters */
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = () => ({
  appInitialized: false,
  currentStep: null,
  homeInfo: null,
  homeVisited: false,
  height: 0,
  selectsApi: {},
  formData: {},
  productDetail: null,
  jekoIntegration: {
    success: null,
    general_errors: [],
    file_errors: [],
  },
  fieldsInfos: {},
  contentData: {
    back_cta_label: '',
    mandatory_field_indication_placeholder: '',
    continue_cta_label: '',
    dont_update_cta_label: '',
    update_cta_label: '',
    back_to_homepage_cta_label: '',
    contact_us_label: '',
    step_current_of_total_label: '',
    step_zero_title: '',
    step_zero_description: '',
    step_zero_sign_up_cta_label: '',
    step_zero_sign_in_cta_label: '',
    step_zero_continue_without_login_link_label: '',
    step_one_step_label: '',
    step_one_step_title: '',
    step_one_suggestion_cta_title: '',
    step_one_suggestion_cta_subtitle: '',
    step_one_suggestion_icon: '',
    step_one_suggestion_cta_active: true,
    step_one_question_cta_title: '',
    step_one_question_cta_subtitle: '',
    step_one_question_icon: '',
    step_one_question_cta_active: true,
    step_one_problem_cta_title: '',
    step_one_problem_cta_subtitle: '',
    step_one_problem_icon: '',
    step_one_problem_cta_active: true,
    step_two_suggestion_step_label: '',
    step_two_suggestion_step_title: '',
    step_two_question_step_label: '',
    step_two_question_step_title: '',
    step_two_problem_step_label: '',
    step_two_problem_step_title: '',
    step_two_products_cta_label: '',
    step_two_products_cta_active: true,
    step_two_products_icon: '',
    step_two_contests_cta_label: '',
    step_two_contests_cta_active: true,
    step_two_contests_icon: '',
    step_two_advertising_cta_label: '',
    step_two_advertising_cta_active: true,
    step_two_advertising_icon: '',
    step_two_shops_cta_label: '',
    step_two_shops_cta_active: true,
    step_two_shops_icon: '',
    step_two_company_cta_label: '',
    step_two_company_cta_active: true,
    step_two_company_icon: '',
    step_two_sponsorship_cta_label: '',
    step_two_sponsorship_cta_active: true,
    step_two_sponsorship_icon: '',
    step_two_other_cta_label: '',
    step_two_other_cta_active: true,
    step_two_other_icon: '',
    step_three_package_data_label: '',
    step_three_store_info_label: '',
    step_four_step_title: '',
    step_thank_you_title: '',
    step_thank_you_subtitle: '',
    step_thank_you_update_title: '',
    step_thank_you_update_text: '',
    step_thank_you_update_results: '',
    step_error_title: '',
    form_select_product_placeholder: '',
    form_select_product_error_required: '',
    form_select_contest_placeholder: '',
    form_select_contest_error_required: '',
    form_select_question_placeholder: '',
    form_select_question_error_required: '',
    form_select_prefix_placeholder: '',
    form_input_text_url_label: '',
    form_input_text_name_label: '',
    form_input_text_name_error_required: '',
    form_input_text_name_error_minLength: '',
    form_input_text_name_error_maxLength: '',
    form_input_text_name_error_regex: '',
    form_input_text_surname_label: '',
    form_input_text_surname_error_required: '',
    form_input_text_surname_error_minLength: '',
    form_input_text_surname_error_maxLength: '',
    form_input_text_surname_error_regex: '',
    form_input_text_email_label: '',
    form_input_text_email_error_required: '',
    form_input_text_email_error_format: '',
    form_input_text_phone_label: '',
    form_input_text_phone_error_required: '',
    form_input_text_phone_error_minLength: '',
    form_input_text_phone_error_maxLength: '',
    form_input_text_phone_error_regex: '',
    form_select_country_label: '',
    form_select_country_placeholder: '',
    form_select_country_error_required: '',
    form_input_text_province_label: '',
    form_input_text_province_error_minLength: '',
    form_input_text_province_error_maxLength: '',
    form_input_text_province_error_regex: '',
    form_input_text_city_label: '',
    form_input_text_city_error_minLength: '',
    form_input_text_city_error_maxLength: '',
    form_input_text_city_error_regex: '',
    form_input_text_cap_label: '',
    form_input_text_cap_error_regex: '',
    form_input_text_address_label: '',
    form_input_text_address_error_minLength: '',
    form_input_text_address_error_maxLength: '',
    form_input_text_address_error_regex: '',
    form_input_file_picture_label: '',
    form_input_file_picture_label_small: '',
    form_input_file_picture_helper: '',
    form_input_file_picture_helper_small: '',
    form_input_file_picture_cta: '',
    form_input_file_picture_hover: '',
    form_input_file_picture_error_format: '',
    form_input_file_picture_error_size: '',
    form_textarea_description_label: '',
    form_textarea_description_error_required: '',
    form_textarea_description_error_max_length: '',
    form_send_cv_text: '',
    form_send_cv_link_text: '',
    form_preserve_product_text: '',
    form_tooltip_personal_area_text: '',
    form_input_text_barcode_label: '',
    form_input_text_batch_label: '',
    form_input_text_bestbefore_label: '',
    date_picker_ok_label: '',
    date_picker_cancel_label: '',
    form_google_autocomplete_shop_label: '',
    form_google_autocomplete_your_address_label: '',
    form_terms_and_conditions_text: '',
    form_input_checkbox_privacy_label: '',
    form_input_checkbox_privacy_error_required: '',
    form_recaptcha_error_required: '',
    form_date_expired_error_valid: '',
    form_input_prefix_default: '',
    form_field_generic_error_required: '',
    form_field_generic_error_regex: '',
    jeko_retry_modal_description_label: 'Lorem Ipsum',
    jeko_retry_modal_button_retry_label: 'Retry',
    jeko_retry_modal_button_cancel_label: 'Cancel',
    jeko_retry_modal_button_send_to_customer_service_label:
      'Send to customer service',
    jeko_thank_you_page_title: 'thank you',
    jeko_thank_you_page_description: 'thank you',
  },
  links: {
    cvLink: '',
  },
  views: [
    {
      name: 'home',
      slug: 'home',
      next: 'step-1',
      prev: null,
    },
    {
      name: 'step5',
      slug: 'step-5',
      num: 5,
      next: null,
      prev: 'step-4',
    },
    {
      name: 'step4',
      slug: 'step-4',
      num: 4,
      next: 'step-5',
      prev: 'step-3',
    },
    {
      name: 'step3',
      slug: 'step-3',
      num: 3,
      next: 'step-4',
      prev: 'step-2',
    },
    {
      name: 'step2',
      slug: 'step-2',
      num: 2,
      next: 'step-3',
      prev: 'step-1',
    },
    {
      name: 'step1',
      slug: 'step-1',
      num: 1,
      next: 'step-2',
      prev: 'home',
    },
  ],
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
