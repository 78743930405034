<template>
  <div v-if="active" class="WaGigyaScreen2024v1">
    <div>
      <div v-if="isUpdateProfileScreenTheStartScreen">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div
                :class="[
                  'tabs-btns',
                  {
                    'd-flex': $viewportWidth >= 768,
                    'justify-content-center': $viewportWidth >= 768,
                  },
                ]"
              >
                <div>
                  <!-- unfortunately the cls check doesn not work in functional cmp -->
                  <template v-if="isUpdateProfileScreen">
                    <wa-cta
                      class="primary"
                      data-href="#"
                      data-rel="nofollow"
                      @click.prevent=""
                    >
                      {{ gigya_profile_cta_label }}
                    </wa-cta>
                  </template>
                  <template v-else>
                    <wa-cta
                      class="secondary"
                      data-href="#"
                      data-rel="nofollow"
                      @click.prevent="
                        switchScreen(screensDestinationsNames.updateProfile)
                      "
                    >
                      {{ gigya_profile_cta_label }}
                    </wa-cta>
                  </template>
                </div>
                <div :class="[{ 'padding-top-r': $viewportWidth < 768 }]">
                  <!-- unfortunately the cls check doesn not work in functional cmp -->
                  <template v-if="isPreferencesScreen">
                    <wa-cta
                      class="primary"
                      data-href="#"
                      data-rel="nofollow"
                      @click.prevent=""
                    >
                      {{ gigya_privacy_cta_label }}
                    </wa-cta>
                  </template>
                  <template v-else>
                    <wa-cta
                      class="secondary"
                      data-href="#"
                      data-rel="nofollow"
                      @click.prevent="
                        switchScreen(screensDestinationsNames.preferences)
                      "
                    >
                      {{ gigya_privacy_cta_label }}
                    </wa-cta>
                  </template>
                </div>
                <div
                  v-if="show_personal_area"
                  :class="[{ 'padding-top-r': $viewportWidth < 768 }]"
                >
                  <template v-if="isContestScreen">
                    <wa-cta
                      class="primary"
                      data-href="#"
                      data-rel="nofollow"
                      @click.prevent=""
                    >
                      {{ $store.state.config.personal_area_camper.cta_label }}
                    </wa-cta>
                  </template>
                  <template v-else>
                    <wa-cta
                      class="secondary"
                      data-href="#"
                      data-rel="nofollow"
                      @click.prevent="switchScreen('contest')"
                    >
                      {{ $store.state.config.personal_area_camper.cta_label }}
                    </wa-cta>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="current == 'contest'">
        <wa-contest-private-area
          :data-contests="data_contests"
        ></wa-contest-private-area>
      </div>
      <div
        v-else
        :id="relatedId"
        :class="[
          'container-fluid',
          {
            'padding-top-l': isUpdateProfileScreenTheStartScreen,
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
import { debug, qsObj } from '@helpers/utils';
import { mapState } from 'vuex';
import waContestPrivateArea from './wa-contest-private-area.vue';
import moment from 'moment';
import axios from 'axios';

export default {
  name: 'WaGigyaScreen2024v1',
  components: { waContestPrivateArea },
  props: {
    dataScreenSet: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return (
          ['Barilla-RegistrationLogin', 'Barilla-ProfileUpdate'].indexOf(
            value
          ) !== -1
        );
      },
    },
    dataStartScreen: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            'gigya-login-screen',
            'gigya-register-screen',
            'gigya-reset-password-screen',
            'gigya-update-profile-screen',
            'gigya-preferences-screen',
            'gigya-forgot-password-screen',
            'gigya-lite-preference-center-screen',
            'gigya-verify-email-profile-screen',
            'gigya-lite-account-progression-screen',
          ].indexOf(value) !== -1
        );
      },
    },
    dataGoTo: {
      type: String,
      default: null,
    },
    dataAdditionalContext: {
      type: [String, Object],
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      current: '',
      relatedId: 'WaGigyaScreenContent',
      redirect: null,
      screenLoaded: false,
      showContestButton: false,
      data_contests: { contests: [] },
      globalContext: {
        //it seems that with the new implementation the ch is not needed
        //if is needed they should give us the name of the param to include
        //inside the context to specify the CH
        //noovleRaasGlobalCH: 'CH_WASA',
      },
    };
  },

  computed: {
    ...mapState('config', ['home_page_url']),
    ...mapState('gigya', [
      'active',
      'loaded',
      'user',
      'gigya_profile_cta_label',
      'gigya_privacy_cta_label',
    ]),
    ...mapState('pm56Camper', [
      'show_personal_area',
      'personal_area_api',
      'reg-source-camper',
      'personal_area_contest_cta_label',
      'contest_name',
      'enter-contest-page-url',
    ]),
    screensDestinationsNames() {
      return {
        updateProfile: 'gigya-update-profile-screen',
        preferences: 'gigya-preferences-screen',
      };
    },
    isContestScreen() {
      return ['contest'].includes(this.current);
    },
    isPreferencesScreen() {
      return ['gigya-preferences-screen'].includes(this.current);
    },
    isUpdateProfileScreen() {
      return ['gigya-update-profile-screen'].includes(this.current);
    },
    isUpdateProfileScreenTheStartScreen() {
      return ['gigya-update-profile-screen'].includes(this.dataStartScreen);
    },
    additionalContext() {
      return this.$_cmsSupport_castPropToType(
        this.dataAdditionalContext,
        'object'
      );
    },
    context() {
      const ret = {
        ...this.additionalContext,
        ...this.globalContext,
      };

      if ('contestName' in qsObj) {
        /**
         * the naming convention for contestName is
         * pm(promotion module)9(module number) - leisure-and-fun (slugified name) => exemple : pm9-leisure-and-fun
         */
        debug && console.log(`'contestName' in qsObj`, 'contestName' in qsObj);
        debug && console.log(`qsObj.contestName`, qsObj.contestName);
        debug && console.log(`REGSOURCE FROM CONFIG 1111`, this['id-contest']);
        debug &&
          console.log(
            `CHKEY FROM CONFIG TO USE ON TY PAGE`,
            this['ch-key-id-contest']
          );
        debug && console.log('GIG2024 CONTEXT CONTESTNAME', qsObj.contestName);
        switch (qsObj.contestName) {
          case 'pm5-6-camper':
            ret.regSourceInitiative = this['reg-source-camper'];
            ret.redirectOnSuccess = this['enter-contest-page-url'];
            break;
          default:
        }
      }
      return ret;
    },
  },
  watch: {
    loaded: {
      handler(val) {
        const info = String.fromCodePoint('0x2139');
        const styles = [
          'padding: 4px',
          'background-color: cornflowerblue',
          'color:darkblue',
        ].join(';');

        const toCheck = this.current ? this.current : this.startScreen;

        if (val && !this.screenLoaded) {
          if (this.dataScreenSet === 'Barilla-RegistrationLogin') {
            if (
              toCheck === 'gigya-login-screen' ||
              toCheck === 'gigya-register-screen'
            ) {
              if (this.user) {
                window.location.href = this.redirect
                  ? this.redirect
                  : this.dataGoTo
                  ? this.dataGoTo
                  : this.home_page_url;
              }
            }
          } else if (this.dataScreenSet === 'Barilla-ProfileUpdate') {
            if (!this.user && !window.CMS) {
              debug &&
                console.log(
                  `%c${info} [CDC Profile page; No user; No edit mode; Do redirect]`,
                  styles
                );
              window.location.href = this.redirect
                ? this.redirect
                : this.dataGoTo
                ? this.dataGoTo
                : this.home_page_url;
            } else {
              debug &&
                console.log(
                  `%c${info} [CDC Profile page; No user; CMS EDIT MODE; Skip redirect]`,
                  styles
                );
            }
          }

          window.addEventListener('customonAfterScreenLoad', () => {
            this.screenLoaded = true;
          });

          debug && console.log('context', this.context);
          window.gigya.accounts.showScreenSet({
            screenSet: this.dataScreenSet,
            startScreen: toCheck,
            containerID: this.relatedId,
            context: this.context,
          });
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (this.dataGoTo) {
      await this.$store.dispatch('gigya/setGigyaGoTo', this.dataGoTo);
    }
    this.current = this.dataStartScreen;
    this.redirect = 'goTo' in qsObj && qsObj.goTo ? qsObj.goTo : null;
  },
  methods: {
    formatDate(mydate) {
      if (mydate) {
        return moment(mydate).format('DD-MM-YYYY');
      }
      return '';
    },
    async callPrivateAreaApiCamper() {
      // Call for  camper contest prvate area data
      try {
        await axios
          .post(this.personal_area_api, {
            user_profiling: '1',
          })
          .then((response) => {
            this.data_contests.contests = [
              ...this.data_contests.contests,
              {
                id: 'camper',
                img: require('@images/promo-camper/banner-bg.jpg'),
                name: this.$store.state.config.personal_area_camper
                  .contest_name,
                start_date: this.formatDate(
                  this.$store.state.config.personal_area_camper
                    .personal_area_contest_start_date
                ),
                end_date: this.formatDate(
                  this.$store.state.config.personal_area_camper
                    .personal_area_contest_end_date
                ),
                cta_detail:
                  this.$store.state.config.personal_area_camper.detail_button,
                receipts: [...response.data.data.receipts],
              },
            ];
          });
      } catch (e) {
        console.error(e);
      }
    },
    switchScreen(related) {
      if (related === this.current) {
        return false;
      }

      this.current = related;

      if (related !== 'contest') {
        window.gigya.accounts.showScreenSet({
          screenSet: this.dataScreenSet,
          startScreen: this.current,
          containerID: this.relatedId,
          context: this.context,
        });
      }
      if (this.current === 'contest') {
        this.data_contests.contests = [];
        this.callPrivateAreaApiCamper();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaGigyaScreen2024v1 {
  /deep/ .WaCta {
    transition: color 0.3s, background-color 0.3s;
  }
}

.tabs-btns {
  > div + div {
    @media (min-width: $bootstrap-sm) {
      margin-left: 30px;
    }
  }
}
</style>
