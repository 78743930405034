<template>
  <ratio-size-box
    class="WaStaticMap"
    :data-width-ratio-component="$viewportWidth < 768 ? 4 : 16"
    :data-height-ratio-component="$viewportWidth < 768 ? 3 : 9"
    data-vertical-align-content="center"
  >
    <div ref="$gmap" class="gmap" />
    <js-loader
      data-async="true"
      data-defer="true"
      :data-id="dataKey"
      :data-src="`https://maps.googleapis.com/maps/api/js?key=${dataKey}&language=${dataLanguage}`"
    ></js-loader>
  </ratio-size-box>
</template>

<script>
/*
  At the moment the map will not be configurable but we defined a set of props
  to help future development. The handling of markers and alt should be changed in future
*/
import MapWidgetPopupMixin from '@/mixins/map_widget_popup_mixin';
export default {
  name: 'WaStaticMap',
  mixins: [MapWidgetPopupMixin],
  props: {
    dataCenter: {
      type: String,
      default: '59.709295,14.156828',
    },
    dataMarkerIconUrl: {
      type: String,
      required: true,
    },
    dataLanguage: {
      type: String,
      default: 'en',
    },
    dataKey: {
      type: String,
      required: true,
    },
    dataMarkerPosition: {
      type: String,
      default: '59.709295,14.156828',
    },
    dataMarkerLabel: {
      type: String,
      default: 'Wasa Knäckebröd Butik och Museum, Filipstad, Svezia',
    },
  },
  data() {
    return {
      map: null,
      zoom: 8,
    };
  },
  computed: {
    mapCenter() {
      const [lat, lng] = this.dataCenter.split(',');
      return { lat: +lat, lng: +lng };
    },
    markerPosition() {
      const [lat, lng] = this.dataMarkerPosition.split(',');
      return { lat: +lat, lng: +lng };
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    markerClicked(marker) {
      this.refreshPopup(marker.getPosition(), {
        label: this.dataMarkerLabel,
      });
    },
    initMap() {
      (() => {
        let doCheck = async () => {
          if (window.google) {
            this.map = new window.google.maps.Map(this.$refs.$gmap, {
              center: this.mapCenter,
              zoom: +this.zoom /*,
              mapTypeId: this.dataMaptype*/,
            });

            this.setMarker();
            this.createPopupClass();
          } else {
            setTimeout(doCheck, 50);
          }
        };
        doCheck();
      })();
    },
    setMarker() {
      //console.log(this)
      //console.log('window.google.maps.SymbolPath.CIRCLE',window.google.maps.SymbolPath.CIRCLE)
      // const pinColor = "FE7569";
      // const pinImage = new google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + pinColor,
      //     new google.maps.Size(21, 34),
      //     new google.maps.Point(0,0),
      //     new google.maps.Point(10, 34));
      // const pinShadow = new google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_shadow",
      //     new google.maps.Size(40, 37),
      //     new google.maps.Point(0, 0),
      //     new google.maps.Point(12, 35));
      //https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png
      const image = {
        url: this.dataMarkerIconUrl,
        size: new window.google.maps.Size(54, 86),
        scaledSize: new window.google.maps.Size(27, 43),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new window.google.maps.Point(13, 43),
      };
      const marker = new window.google.maps.Marker({
        position: this.markerPosition,
        map: this.map,
        icon: image,
        //shadow: pinShadow
        //icon: {
        //  path: window.google.maps.SymbolPath.CIRCLE,
        //  scale:10,
        //  strokeColor:'#002F6C',
        //  fillColor: '#002F6C',
        //},
        //
      });
      marker.addListener('click', () => {
        this.markerClicked(marker);
      });
      setTimeout(() => {
        this.markerClicked(marker);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.WaStaticMap {
  &.RatioSizeBox {
    > .gmap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
