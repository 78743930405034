<template>
  <!--
    props:
      dataSocial -> the name of the social ['facebook','twitter','linkedin','youtube','instagram','mail']
      dataIsShare -> (bool) set if the button is a share button or a link (the share is only for facebook, linkedin and twitter)
      dataHref -> url for external page
      dataTarget -> (_blank default) set where to open the link
      dataRel-> set the rel
      dataTitleShare ->  title of the share
      dataSubjectMail (only for mail) -> subject of the mail
      dataShareUrl -> url of the share
  -->
  <a
    class="WaSocialShareItem"
    :href="getUrl"
    :target="dataTarget !== '' ? dataTarget : null"
    :rel="dataRel !== '' ? dataRel : null"
    @click="onButtonClick"
  >
    <svg width="24" height="24" :class="['socialIcon', dataSocial]">
      <use :href="`#icon-social-${dataSocial}`" fill="currentColor" />
    </svg>
    <js-loader
      v-if="dataSocial === 'pinterest'"
      :data-async="true"
      :data-defer="true"
      data-id="pinterest-initializer"
      data-src="//assets.pinterest.com/js/pinit.js"
    />
    <!--
      buttonPin if you are using a “one Pin” button, or
      buttonBookmark if you are using an “any Pin” button
    -->
  </a>
</template>

<script>
import SmcSupport from '@mixins/smc-support';

export default {
  name: 'WaSocialShareItem',
  mixins: [SmcSupport],
  props: {
    dataSocial: {
      type: String,
      default: '',
    },
    dataIsShare: {
      type: [Boolean, String],
      default: false,
    },
    dataHref: {
      type: String,
      default: '',
    },
    dataTarget: {
      type: String,
      default: '_blank',
    },
    dataRel: {
      type: String,
      default: '',
    },
    dataTitleShare: {
      type: String,
      default: '',
    },
    dataSubjectMail: {
      type: String,
      default: '',
    },
    dataContentMail: {
      type: String,
      default: '',
    },
    dataShareUrl: {
      type: String,
      default: '',
    },
    dataShareTrackingActive: {
      type: [String, Boolean],
      default: false,
    },
    dataShareTrackingContentType: {
      type: String,
      default: '',
    },
    dataShareTrackingTitle: {
      type: String,
      default: '',
    },
    dataShareSmcEnabled: {
      type: [Boolean, String],
      default: false,
    },
    dataShareSmcClickForced: {
      type: [Boolean, String],
      default: false,
    },
    dataShareSmcClickEvent: {
      type: String,
      default: '',
    },
    dataShareSmcRecipeId: {
      type: [String, Number],
      default: '',
    },
    dataShareSmcProductCode: {
      type: String,
      default: '',
    },
    dataShareSmcHighlightedContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      socialShareHref: '',
    };
  },
  computed: {
    shareTrackingActive() {
      return this.$_cmsSupport_castPropToType(
        this.dataShareTrackingActive,
        'boolean'
      );
    },
    shareSmcEnabled() {
      return this.$_cmsSupport_castPropToType(
        this.dataShareSmcEnabled,
        'boolean'
      );
    },
    shareSmcClickForced() {
      return this.$_cmsSupport_castPropToType(
        this.dataShareSmcClickForced,
        'boolean'
      );
    },
    twitterShareUrl() {
      return `https://twitter.com/intent/tweet?url=${encodeURI(
        this.dataShareUrl
      )}`;
    },
    fbShareUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
        this.dataShareUrl
      )}`;
    },
    linkedinShareUrl() {
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(
        this.dataShareUrl
      )}&title=${encodeURIComponent(this.dataTitleShare)}&source=LinkedIn`;
    },
    mailUrl() {
      return `mailto:?subject=${this.dataSubjectMail}&body=${encodeURIComponent(
        this.dataContentMail
      )} ${encodeURI(this.dataShareUrl)}`;
    },
    isShare() {
      return this.$_cmsSupport_castPropToType(this.dataIsShare, 'boolean');
    },
    getUrl() {
      if (this.isShare) {
        if (this.dataSocial === 'twitter') {
          return this.twitterShareUrl;
        } else if (this.dataSocial === 'facebook') {
          return this.fbShareUrl;
        } else if (this.dataSocial === 'linkedin') {
          return this.linkedinShareUrl;
        } else if (this.dataSocial === 'mail') {
          return this.mailUrl;
        } else {
          return '#';
        }
      } else {
        return this.dataHref;
      }
    },
  },
  methods: {
    openPopup(event) {
      this.popupCenter(event.currentTarget.href, this.dataTitleShare, 580, 470);
    },
    popupCenter(url, title, w, h) {
      // Fixes dual-screen position                            Most browsers       Firefox
      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : screen.left;
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : screen.top;

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

      const left = width / 2 - w / 2 + dualScreenLeft;
      const top = height / 3 - h / 3 + dualScreenTop;

      const newWindow = window.open(
        url,
        title,
        'scrollbars=yes, width=' +
          w +
          ', height=' +
          h +
          ', top=' +
          top +
          ', left=' +
          left
      );

      // Puts focus on the newWindow
      if (newWindow && newWindow.focus) {
        newWindow.focus();
      }
    },
    onButtonClick(event) {
      if (this.isShare && this.dataSocial !== 'mail') {
        event.preventDefault();
        if (this.dataSocial === 'pinterest') {
          window.PinUtils.pinAny();
        } else {
          this.openPopup(event);
        }
      }

      /*
        WE DIDN'T USE ANY THE SAME OF SMC BECAUSE SMC IS ALSO ACTIVE IN THE FOOTER
        WHILE WE NEED THIS TRACKING JUST IN PRODUCT PAGES (HANDLED BY CMS PLUGIN) AND
        RECIPE PAGES (HANDLED BY APPHOOK)
       */
      if (this.shareTrackingActive && window.dataLayer) {
        window.dataLayer.push({
          'event': 'social_share',
          'data-name-product': this.dataShareTrackingTitle,
          'data-name-method': this.dataSocial,
          'data-content-type': this.dataShareTrackingContentType,
        });
      }

      /* smc */
      if (this.shareSmcEnabled) {
        let clickEvent;
        let payload = {};

        if (this.dataShareSmcClickEvent !== '' && !this.shareSmcClickForced) {
          /*
          This code is executed if dataShareSmcClickEvent is PROD_SHARED or ZRECIPE_SHARED
          and if !shareSmcClickForced
          */

          clickEvent = this.dataShareSmcClickEvent;
          payload.productCode = this.dataShareSmcProductCode;
          payload.highlightedContent = this.dataShareSmcHighlightedContent;
          if (this.dataShareSmcClickEvent === 'ZRECIPE_SHARED') {
            payload.interactionDetail = this.dataShareSmcRecipeId;
          }
          payload.sharedPlatform = this.dataSocial;
        } else {
          switch (this.dataSocial) {
            case 'facebook':
              clickEvent = 'ZFACEBOOK_CLICK';
              break;
            case 'instagram':
              clickEvent = 'ZINSTAGRAM_CLICK';
              break;
            case 'youtube':
              clickEvent = 'ZYOUTUBE_CLICK';
              break;
          }
        }

        if (clickEvent) {
          if (!this.isShare && this.dataTarget !== '_blank') {
            event.preventDefault();
          }
          this.$_smcSupport_sendEvent(clickEvent, payload)
            .then(() => {
              if (!this.isShare && this.dataTarget !== '_blank') {
                window.location.href = this.dataHref;
              }
            })
            .catch(() => {
              if (!this.isShare && this.dataTarget !== '_blank') {
                window.location.href = this.dataHref;
              }
            });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '~$scss/_variables';

.WaSocialShareItem {
  display: flex;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: flex-start;
  color: inherit;
}

.socialIcon {
  display: block;
}
</style>
