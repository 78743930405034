<template functional>
  <span
    :ref="data.ref"
    :class="['WaTag typo-a-10 typo-heavy', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'WaTag',
  functional: 'True',
  data: function () {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
.WaTag {
  padding: 3px 8px;
  border-radius: 100px;
  display: inline-block;
}
</style>
