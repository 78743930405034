import { render, staticRenderFns } from "./wa-highlight-carousel-item.vue?vue&type=template&id=6b5b2018&scoped=true&"
import script from "./wa-highlight-carousel-item.vue?vue&type=script&lang=js&"
export * from "./wa-highlight-carousel-item.vue?vue&type=script&lang=js&"
import style0 from "./wa-highlight-carousel-item.vue?vue&type=style&index=0&id=6b5b2018&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b5b2018",
  null
  
)

export default component.exports