<template>
  <wa-modal
    class="WaModalToast"
    :data-name="dataName"
    :data-has-close-button="dataHasCloseButton"
    :data-click-to-close="dataClickToClose"
  >
    <div class="modal-content">
      <svg v-if="dataIcon" class="icon" width="48" height="48">
        <use :href="`#${dataIcon}`" fill="currentColor" />
      </svg>
      <span class="title typo-w typo-w-4 typo-bold text-center">
        <slot name="title" />
      </span>
      <div class="content typo-a-7 typo-light text-center">
        <slot name="content" />
      </div>
      <div
        class="buttons w-100 d-flex flex-column flex-sm-row align-items-center justify-content-center"
      >
        <slot name="buttons" />
      </div>
    </div>
  </wa-modal>
</template>

<script>
export default {
  name: 'WaModalToast',
  props: {
    dataName: {
      type: String,
      default: 'modal-toast',
    },
    dataIcon: {
      type: String,
      default: null,
    },
    dataHasCloseButton: {
      type: Boolean,
      default: true,
    },
    dataClickToClose: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaModalToast {
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 30px;
    padding-left: 30px;

    @media (min-width: $bootstrap-sm) {
      padding-right: 46px;
      padding-left: 46px;
    }

    @media (min-width: $bootstrap-md) {
      padding-right: 60px;
      padding-left: 60px;
    }

    @media (min-width: $bootstrap-lg) {
      padding-right: 110px;
      padding-left: 110px;
    }

    .icon,
    .title {
      margin: 0 0 16px;

      @media (min-width: $bootstrap-sm) {
        margin: 0 0 24px;
      }

      @media (min-width: $bootstrap-md) {
        margin: 0 0 32px;
      }
    }

    .content {
      & > :last-child {
        margin: 0 !important;
      }
    }

    .buttons {
      /deep/ .WaCta {
        margin: 32px 16px 0;

        @media (min-width: $bootstrap-sm) {
          margin: 24px 12px 0;
        }

        @media (min-width: $bootstrap-md) {
          margin: 32px 16px 0;
        }
      }
    }
  }
}
</style>
