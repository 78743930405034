var render = function (_h,_vm) {var _c=_vm._c;return _c('div',_vm._g(_vm._b({class:[
    'WaFooterCol',
    ("" + (_vm.$options.getBoolean(_vm.props.dataCleanMarginClasses)
        ? ''
        : 'margin-top-col')),
    ("" + (_vm.$options.getBoolean(_vm.props.dataCleanBaseClasses) ? '' : 'col-sm-4')),
    ("" + (_vm.$options.getBoolean(_vm.props.dataIsFullSmartphone) ? 'col-12' : 'col-6')),
    ("" + (_vm.$options.getBoolean(_vm.props.dataIsSmallText) ? 'typo-a-9' : 'typo-a-6')),
    _vm.data.staticClass,
    _vm.data.class ],style:([_vm.data.style, _vm.data.staticStyle])},'div',_vm.data.attrs,false),_vm.listeners),[_c('ul',[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }