<template>
  <div class="WaSelect form-group__wrapper">
    <div
      :class="[
        'form-group',
        { 'form-group--error': dataErrorOccurred, 'disabled': dataDisabled },
      ]"
    >
      <label v-if="dataShowLabel" class="form__label" :for="`select-${_uid}`">
        {{ dataLabel }}
      </label>
      <div class="form-control__wrapper">
        <!-- only for screenreaders -->
        <select
          :id="`select-${_uid}`"
          ref="$select"
          :aria-label="!dataShowLabel ? dataLabel : null"
          :name="dataName"
          :value="value"
          class="form-control form__select"
          :disabled="dataDisabled"
          :required="dataPlaceholder"
          @change="onUpdate"
        >
          <option v-if="dataPlaceholder" value="">
            {{ dataPlaceholder }}
          </option>
          <option
            v-for="(option, i) in optionList"
            :key="`option-${i}-${_uid}`"
            :value="option.key"
            :selected="+option.key === +value"
          >
            {{ option.dataValue }}
          </option>
        </select>
        <div class="selectbox" tabindex="-1">
          <div class="label" tabindex="-1">
            {{ label }}
          </div>
          <div v-if="hasIcon" class="icon" tabindex="-1">
            <slot name="icon" />
          </div>
        </div>
      </div>
    </div>
    <span
      v-for="(error, i) in hasErrors"
      :key="`error-${i}-${_uid}`"
      class="error"
      v-html="error.message"
    />
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WaSelect',
  props: {
    dataLabel: {
      type: String,
      required: true,
    },
    dataShowLabel: {
      type: Boolean,
      default: false,
    },
    dataName: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    dataPlaceholder: {
      type: String,
      default: null,
    },
    dataDisabled: {
      type: Boolean,
      default: null,
    },
    dataOptionList: {
      type: Array,
      default: null,
    },
    dataOptionApiUrl: {
      type: String,
      default: null,
    },
    dataErrorOccurred: {
      type: Boolean,
      default: null,
    },
    dataErrors: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      optionList: [],
    };
  },
  computed: {
    label() {
      if (!this.value) {
        return this.dataPlaceholder;
      } else {
        const currentOption = this.optionList.find(
          (e) => '' + e.key === '' + this.value
        );
        return currentOption && currentOption.dataValue
          ? currentOption.dataValue
          : '';
      }
    },
    hasIcon() {
      return !!this.$slots['icon'];
    },
    hasErrors() {
      if (this.dataErrorOccurred) {
        return this.dataErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  watch: {
    dataOptionList: {
      handler(val) {
        if (val) {
          this.loadOptions('list');
        }
      },
      immediate: true,
    },
    dataOptionApiUrl: {
      handler(newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.loadOptions('api');
        }
      },
      immediate: true,
    },
  },
  methods: {
    onUpdate(e) {
      this.$emit('change', e.target.value);
      this.$emit('input', e.target.value); // to handle v-model on parent
    },
    loadOptions(type) {
      if (type === 'api') {
        axios
          .get(this.dataOptionApiUrl)
          .then((response) => {
            if (response.data) {
              this.optionList = response.data.map((e) => {
                return { key: e.id, dataValue: e.contact_form_label };
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (type === 'list') {
        this.optionList = this.dataOptionList;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaSelect {
  &.form-group__wrapper {
    width: auto;

    .error {
      display: block;
      font-size: 13px;
      font-weight: $fh-medium;
      margin-top: 8px;
      color: $color-informative-red;
    }
  }

  .form-control__wrapper {
    position: relative;
    overflow: hidden;

    .form__select {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      appearance: none;
      border: 0;
      background: transparent;
      opacity: 0;
      z-index: 99;
    }

    .selectbox {
      display: flex;
      font-weight: $fh-medium;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      @-moz-document url-prefix() {
        svg {
          vertical-align: baseline;
        }
      }
    }
  }

  /* theme classes */
  &.big {
    .selectbox {
      font-size: 24px;
      @include media-breakpoint-up(sm) {
        font-size: 32px;
      }
      @include media-breakpoint-up(md) {
        font-size: 40px;
      }

      .icon {
        margin-left: 8px;
        @include media-breakpoint-up(sm) {
          margin-left: 16px;
        }
      }
    }
  }

  &.medium {
    .selectbox {
      font-size: 16px;
      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }
      @include media-breakpoint-up(md) {
        font-size: 24px;
      }

      .icon {
        margin-left: 4px;
        @include media-breakpoint-up(sm) {
          margin-left: 8px;
        }
      }
    }
  }

  &.small {
    .selectbox {
      font-size: 16px;

      .icon {
        margin-left: 4px;
      }
    }
  }
}
</style>
