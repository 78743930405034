export default {
  methods: {
    getActiveCtas: function (
      activeRoute,
      routeDependencies = [],
      queryString,
      routes
    ) {
      // 4 casi positivi:
      // caso 1: cta con activeIn avvalorato -> combinazione percorso rispettata + positioning
      // caso 2: cta con activeIn avvalorato -> combinazione percorso rispettata + senza positioning
      // caso 3: cta con activeIn avvalorato -> senza combinazione percorso rispettata + positioning (sempre attiva con posizionamento)
      // caso 4: cta con activeIn non avvalorato (sempre attiva senza posizionamento)
      // cta già aggiunta all'array (coperto dall'uso di activeIn.some + return true)

      let retArray = [];
      routes[activeRoute].controls.selectionCtas.map((cta) => {
        if (cta.activeIn?.length && routeDependencies.length) {
          cta.activeIn.some((activeInObj) => {
            if (
              routeDependencies.every(
                (dep) =>
                  !!activeInObj[dep] &&
                  !!queryString[dep] &&
                  activeInObj[dep] === queryString[dep]
              )
            ) {
              if (activeInObj.position) {
                // caso 1
                retArray.push([cta, activeInObj.position]);
                return true;
              } else {
                // caso 2
                retArray.push([cta, null]);
                return true;
              }
            }
          });
        } else if (cta.activeIn?.length && !routeDependencies.length) {
          cta.activeIn.map((activeInObj) => {
            //caso 3
            retArray.push([cta, activeInObj.position]);
            return true;
          });
        } else {
          // caso 4
          retArray.push([cta, null]);
        }
      });

      if (retArray.every((el) => Array.isArray(el))) {
        retArray = this.sortArrayFromPosition(retArray);
        retArray = this.flattenArray(retArray);
      }
      return retArray;
    },
    getRouteName: function (routeDependencies = [], queryString, routes, cta) {
      if (!routeDependencies.length) {
        return cta.goToName[0].name;
      } else {
        let retEl = '';
        cta.goToName.map((element) => {
          if (
            routeDependencies.every(
              (dep) =>
                !!element[dep] &&
                !!queryString[dep] &&
                element[dep] === queryString[dep]
            )
          ) {
            retEl = element.name;
          }
        });
        return retEl;
      }
    },
    sortArrayFromPosition: function (array) {
      array = array.sort((x, y) => {
        if (x[1] === null) {
          return 1;
        } else if (y[1] === null) {
          return -1;
        } else {
          return x[1] - y[1];
        }
      });
      return array;
    },
    flattenArray: function (array) {
      return (array = array.map(([cta, position]) => cta));
    },
    getQuestionsList: function (storeQuestions, queryString) {
      // 4 casi positivi:
      // caso 1: question con activeIn avvalorato -> combinazioni di percorsi + positioning
      // caso 2: question con activeIn avvalorato -> combinazioni di percorsi + senza positioning
      // caso 3: question con activeIn avvalorato -> senza combinazioni di percorsi + positioning (sempre attiva con posizionamento)
      // caso 4: question con activeIn non avvalorato (sempre attiva senza posizionamento)
      // question già aggiunta all'array (coperto dall'uso di activeIn.some + return true)
      let retArray = [];
      if (storeQuestions.length) {
        storeQuestions.map((question) => {
          if (question.activeIn?.length) {
            question.activeIn.some((activeInObj) => {
              if (
                queryString.triage_topic === activeInObj.triage_topic &&
                queryString.triage_type === activeInObj.triage_type
              ) {
                if (activeInObj.position) {
                  // caso 1
                  retArray.push([
                    {
                      value: question.value,
                      label: question.label,
                      description: question.description,
                    },
                    activeInObj.position,
                  ]);
                  return true;
                } else {
                  // caso 2
                  retArray.push([
                    {
                      value: question.value,
                      label: question.label,
                      description: question.description,
                    },
                    null,
                  ]);
                  return true;
                }
              } else if (
                activeInObj.position &&
                !activeInObj.triage_topic &&
                !activeInObj.triage_type
              ) {
                //caso 3
                retArray.push([
                  {
                    value: question.value,
                    label: question.label,
                    description: question.description,
                  },
                  activeInObj.position,
                ]);
                return true;
              }
            });
          } else {
            //caso 4
            retArray.push([
              {
                value: question.value,
                label: question.label,
                description: question.description,
              },
              null,
            ]);
          }
        });
      }
      if (retArray.every((el) => Array.isArray(el))) {
        retArray = this.sortArrayFromPosition(retArray);
        retArray = this.flattenArray(retArray);
      }
      return retArray;
    },
    getThirdLevelGoToName: function (queryString, routes) {
      return routes.ticketing_third_level.controls.selectionCtasFrom.selectionCtasGoToName.find(
        (obj) => {
          return (
            obj.triage_topic === queryString.triage_topic &&
            obj.triage_type === queryString.triage_type
          );
        }
      ).name;
    },
    getActiveSkipCtas: function (
      activeRoute,
      routeDependencies = [],
      queryString,
      routes
    ) {
      // 4 casi positivi:
      // caso 1: cta con activeIn avvalorato -> combinazione percorso rispettata + positioning
      // caso 2: cta con activeIn avvalorato -> combinazione percorso rispettata + senza positioning
      // caso 3: cta con activeIn avvalorato -> senza combinazione percorso rispettata + positioning (sempre attiva con posizionamento)
      // caso 4: cta con activeIn non avvalorato (sempre attiva senza posizionamento)
      // cta già aggiunta all'array (coperto dall'uso di activeIn.some + return true)

      let retArray = [];
      routes[activeRoute].controls.selectionCtasFrom.optionalSkipCtaItems.map(
        (cta) => {
          if (cta.activeIn?.length && routeDependencies.length) {
            cta.activeIn.some((activeInObj) => {
              if (
                routeDependencies.every(
                  (dep) =>
                    !!activeInObj[dep] &&
                    !!queryString[dep] &&
                    activeInObj[dep] === queryString[dep]
                )
              ) {
                if (activeInObj.position) {
                  // caso 1
                  retArray.push([cta, activeInObj.position]);
                  return true;
                } else {
                  // caso 2
                  retArray.push([cta, null]);
                  return true;
                }
              }
            });
          } else if (cta.activeIn?.length && !routeDependencies.length) {
            cta.activeIn.map((activeInObj) => {
              //caso 3
              retArray.push([cta, activeInObj.position]);
              return true;
            });
          } else {
            // caso 4
            retArray.push([cta, null]);
          }
        }
      );

      if (retArray.every((el) => Array.isArray(el))) {
        retArray = this.sortArrayFromPosition(retArray);
        retArray = this.flattenArray(retArray);
      }
      return retArray;
    },
  },
};
