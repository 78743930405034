<template>
  <div class="WaContactFormHome">
    <wa-text-module-cta v-if="active && loaded && !user">
      <template slot="title">
        <h3 class="text-color-secondary-item typo-w typo-w-3">
          {{ contentData.step_zero_title }}
        </h3>
      </template>
      <template slot="wysiwyg">
        <p>
          {{ contentData.step_zero_description }}
        </p>
      </template>
      <template slot="ctas-buttons">
        <wa-cta
          class="secondary"
          data-type="button"
          :data-href="`${contentData.step_zero_sign_up_page_url}?goTo=${currentPageUrl}`"
        >
          {{ contentData.step_zero_sign_up_cta_label }}
        </wa-cta>
        <wa-cta
          class="primary"
          data-type="button"
          :data-href="`${contentData.step_zero_sign_in_page_url}?goTo=${currentPageUrl}`"
        >
          {{ contentData.step_zero_sign_in_cta_label }}
        </wa-cta>
      </template>
      <template slot="ctas-links">
        <router-link :to="{ name: 'step-1' }" tag="span">
          <wa-cta
            class="link padding-top-s text-color-blue-logo"
            data-icon-id="icon-arrow-angle-right"
          >
            {{ contentData.step_zero_continue_without_login_link_label }}
          </wa-cta>
        </router-link>
      </template>
    </wa-text-module-cta>
  </div>
</template>

<script>
import WaContactFormCurrentStepUpdate from '@mixins/wa-contact-form-current-step-update';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'WaContactFormHome',
  mixins: [WaContactFormCurrentStepUpdate],
  computed: {
    ...mapState('gigya', ['active', 'loaded', 'user']),
    ...mapState('contact', ['homeInfo', 'contentData']),
    currentPageUrl() {
      return window.location.href;
    },
  },
  watch: {
    loaded: {
      handler(val) {
        if (val) {
          if (this.user) {
            this.$router.replace({ name: 'step-1' });
          }
        }
      },
    },
  },
  mounted() {
    this.setHomeVisited(true);
    this.current = this.steps.home;
    if (!this.active) {
      this.$router.replace({ name: 'step-1' });
    }
  },
  methods: {
    ...mapActions('contact', ['setHomeVisited']),
  },
};
</script>

<style scoped></style>
