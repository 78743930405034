<template>
  <div class="WaYoutubeMediaPlayer">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div
            :class="['responsive-video', { playing }]"
            :style="{ backgroundImage: `url(${dataBackgroundImageSrc})` }"
          >
            <div ref="$player" />
            <div
              v-if="
                $_cmsSupport_hasContentSlotWithName('duration') ||
                $_cmsSupport_hasContentSlotWithName('title')
              "
              class="info d-none d-sm-flex"
            >
              <slot name="duration" />
              <slot name="title" />
            </div>
            <button v-if="ready" class="play" type="button" @click="playVideo">
              <svg class="icon">
                <use href="#icon-play" />
              </svg>
            </button>
          </div>
          <div
            v-if="$_cmsSupport_hasContentSlotWithName('title')"
            class="d-sm-none title-mobile"
          >
            <slot name="title" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmcSupport from '@mixins/smc-support';
import { debug, colorLog } from '@helpers/utils';

let sequence = [];
let date = null;
let bufferTime = null;
let timer = null;
let progressTimer = null;

export default {
  name: 'WaYoutubeMediaPlayer',
  mixins: [SmcSupport],
  props: {
    dataBackgroundImageSrc: { type: String, required: true },
    dataYoutubeVideoId: { type: String, required: true },
  },
  data() {
    return {
      initialized: false,
      player: null,
      ready: false,
      playing: false,
      playedOnce: false,
      videoData: {
        10: false,
        25: false,
        50: false,
        75: false,
        90: false,
      },
    };
  },
  watch: {
    playing: {
      immediate: false,
      handler(val) {
        if (val) {
          clearTimeout(progressTimer);
          (() => {
            let checkProgress = () => {
              this.handleProgress();
              progressTimer = setTimeout(checkProgress, 500);
            };
            checkProgress();
          })();
        } else {
          clearTimeout(progressTimer);
        }
      },
    },
  },
  mounted() {
    if (typeof window.YT === 'object' && window.wasaYT === 'done') {
      this.init();
    } else {
      window.wasaYT = 'done';
      this.$root.$on('on-you-tube-iframe-apiready', this.init);
      if (!document.getElementById('get-youtube-script-api')) {
        window.onYouTubeIframeAPIReady = () => {
          this.$root.$emit('on-you-tube-iframe-apiready');
        };
        const tag = document.createElement('script');
        tag.src = this.$store.state.config.apis['get-youtube-script-api']; //'https://www.youtube.com/iframe_api';
        tag.id = 'get-youtube-script-api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    }
  },
  // mounted() {
  //   window.onYouTubeIframeAPIReady = undefined; // fix
  //   if (typeof window.YT === 'object') {
  //     this.init();
  //   } else {
  //     this.$root.$on('on-you-tube-iframe-apiready', this.init);
  //     if (typeof window.onYouTubeIframeAPIReady === 'undefined') {
  //       window.onYouTubeIframeAPIReady = () => {
  //         this.$root.$emit('on-you-tube-iframe-apiready');
  //       };
  //       const tag = document.createElement('script');
  //       tag.src = this.$store.state.config.apis['get-youtube-script-api'] //'https://www.youtube.com/iframe_api';
  //       const firstScriptTag = document.getElementsByTagName('script')[0];
  //       firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  //     }
  //   }
  // },
  beforeDestroy() {
    if (this.player) {
      this.player.stopVideo();
      this.player.destroy();
      this.player = null;
      this.playing = false;
      this.ready = false;
      this.initialized = false;
      this.$root.$off('on-you-tube-iframe-apiready', this.init);
    }
  },
  methods: {
    init() {
      try {
        if (this.initialized) {
          return false;
        }
        this.initialized = true;
        this.player = new window.YT.Player(this.$refs.$player, {
          videoId: this.dataYoutubeVideoId,
          host: 'https://www.youtube-nocookie.com',
          //'enablejsapi': 1 we do not enable because we make manual tracking
          playerVars: {
            playsInline: 1,
            controls: 1,
            origin: encodeURIComponent(window.location.origin),
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
          },
          events: {
            onReady: this.onPlayerReady,
            onStateChange: this.handleStateChange,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    onPlayerReady() {
      this.ready = true;
    },
    handleProgress() {
      this.videoData.title = this.player.playerInfo.videoData.title;
      this.videoData.currentTime = parseInt(
        this.player.playerInfo.currentTime,
        10
      );
      this.videoData.duration = parseInt(this.player.playerInfo.duration, 10);
      this.videoData.videoUrl = this.player.playerInfo.videoUrl;
      this.videoData.amount = parseInt(
        (this.player.playerInfo.currentTime / this.player.playerInfo.duration) *
          100,
        10
      );
      this.videoData.percent = this.videoData.amount + '%';

      if (!this.videoData['10'] && this.videoData.amount >= 10) {
        this.videoData['10'] = true;
        debug &&
          colorLog({
            label: 'video',
            message: `PROGRESS 10%`,
            themeName: 'blue',
          });

        const payload = Object.assign({}, this.getBasicEventPayload(), {
          'gtm.videoStatus': 'watched',
          'gtm.videoPercent': '10%',
        });
        debug && console.log('payload', payload);
        window.dataLayer.push(payload);
      }
      if (!this.videoData['25'] && this.videoData.amount >= 25) {
        this.videoData['25'] = true;
        debug &&
          colorLog({
            label: 'video',
            message: `PROGRESS 25%`,
            themeName: 'blue',
          });

        const payload = Object.assign({}, this.getBasicEventPayload(), {
          'gtm.videoStatus': 'watched',
          'gtm.videoPercent': '25%',
        });
        debug && console.log('payload', payload);
        window.dataLayer.push(payload);
      }
      if (!this.videoData['50'] && this.videoData.amount >= 50) {
        this.videoData['50'] = true;
        debug &&
          colorLog({
            label: 'video',
            message: `PROGRESS 50%`,
            themeName: 'blue',
          });

        const payload = Object.assign({}, this.getBasicEventPayload(), {
          'gtm.videoStatus': 'watched',
          'gtm.videoPercent': '50%',
        });
        debug && console.log('payload', payload);
        window.dataLayer.push(payload);
      }
      if (!this.videoData['75'] && this.videoData.amount >= 75) {
        this.videoData['75'] = true;
        debug &&
          colorLog({
            label: 'video',
            message: `PROGRESS 75%`,
            themeName: 'blue',
          });

        const payload = Object.assign({}, this.getBasicEventPayload(), {
          'gtm.videoStatus': 'watched',
          'gtm.videoPercent': '75%',
        });
        debug && console.log('payload', payload);
        window.dataLayer.push(payload);
      }
      if (!this.videoData['90'] && this.videoData.amount >= 90) {
        this.videoData['90'] = true;
        debug &&
          colorLog({
            label: 'video',
            message: `PROGRESS 90%`,
            themeName: 'blue',
          });

        const payload = Object.assign({}, this.getBasicEventPayload(), {
          'gtm.videoStatus': 'watched',
          'gtm.videoPercent': '90%',
        });
        debug && console.log('payload', payload);
        window.dataLayer.push(payload);
      }
    },
    handleEvent(type) {
      debug &&
        colorLog({
          label: 'yotube',
          message: `type = ${type}`,
          themeName: 'red',
        });
      /*
        -1 (unstarted)
        0 (ended)
        1 (playing)
        2 (paused)
        3 (buffering)
        5 (video cued).
      */
      // Update sequence with current state change event
      sequence = [...sequence, type];
      if (type === 3) {
        if (!date) {
          date = new Date();
          (() => {
            let checkIfIsBuffering = () => {
              if (date) {
                const newDate = new Date();
                const bufferTime = newDate - date;
                const isBuffering = bufferTime > 400;
                if (isBuffering) {
                  this.handleBuffer();
                  date = null;
                }
                setTimeout(checkIfIsBuffering, 100);
              }
            };
            checkIfIsBuffering();
          })();
        }
      }
      if (date && type === 1) {
        date = null;
      }
      const isStarted = type === -1;
      const isMouseSeek = type === 1 && this.isSubArrayEnd(sequence, [2, 3, 1]);
      //-1 is needed for first start
      const isArrowSeek =
        type === 1 &&
        !sequence.includes(-1) &&
        this.isSubArrayEnd(sequence, [3, 1]);
      const isEnded = type === 0;
      if (isStarted) {
        this.handleStart(); // Start
      } else if (isEnded) {
        this.handleEnd(); // Mouse seek
        sequence = []; // Reset event sequence
      } else if (isMouseSeek) {
        this.handleSeek(); // Mouse seek
        sequence = []; // Reset event sequence
      } else if (isArrowSeek) {
        this.handleSeek(); // Mouse seek
        sequence = []; // Reset event sequence
      } else {
        clearTimeout(timer); // Cancel previous event
        if (type !== 3) {
          // If we're not buffering,
          let timeout = setTimeout(() => {
            // Start timer
            if (type === 1 && !sequence.includes(-1)) this.handlePlay();
            else if (type === 2) this.handlePause();
            sequence = []; // Reset event sequence
          }, 250);
          timer = timeout;
        }
      }
      this.handleProgress();
    },
    getBasicEventPayload() {
      return {
        'event': 'videoEvent',
        'gtm.videoTitle': this.videoData.title,
        //'gtm.videoStatus':'started',
        'gtm.videoProvider': 'youtube',
        'gtm.videoCurrentTime': this.videoData.currentTime + 's',
        'gtm.videoPercent': this.videoData.percent,
        'gtm.videoDuration': this.videoData.duration + 's',
        'gtm.videoUrl': this.videoData.videoUrl,
      };
    },
    handleStart() {
      debug &&
        colorLog({ label: 'video', message: `START!`, themeName: 'blue' });
      this.playing = true;

      const payload = Object.assign({}, this.getBasicEventPayload(), {
        'gtm.videoStatus': 'started',
        'gtm.videoCurrentTime': '0s',
        'gtm.videoPercent': '0%',
      });
      debug && console.log('payload', payload);
      window.dataLayer.push(payload);
    },
    handlePlay() {
      debug &&
        colorLog({ label: 'video', message: `PLAY!`, themeName: 'blue' });
      this.playing = true;

      const payload = Object.assign({}, this.getBasicEventPayload(), {
        'gtm.videoStatus': 'played',
      });
      debug && console.log('payload', payload);
      window.dataLayer.push(payload);
    },
    handlePause() {
      debug &&
        colorLog({ label: 'video', message: `PAUSE!`, themeName: 'blue' });
      this.playing = false;

      const payload = Object.assign({}, this.getBasicEventPayload(), {
        'gtm.videoStatus': 'paused',
      });
      debug && console.log('payload', payload);
      window.dataLayer.push(payload);
    },
    handleBuffer() {
      debug &&
        colorLog({ label: 'video', message: `BUFFER!`, themeName: 'blue' });

      const payload = Object.assign({}, this.getBasicEventPayload(), {
        'gtm.videoStatus': 'buffering',
      });
      debug && console.log('payload', payload);
      window.dataLayer.push(payload);
    },
    handleSeek() {
      debug &&
        colorLog({ label: 'video', message: `SEEK!`, themeName: 'blue' });

      const payload = Object.assign({}, this.getBasicEventPayload(), {
        'gtm.videoStatus': 'sought',
      });
      debug && console.log('payload', payload);
      window.dataLayer.push(payload);
    },
    handleEnd() {
      debug && colorLog({ label: 'video', message: `END!`, themeName: 'blue' });
      this.playing = false;

      const payload = Object.assign({}, this.getBasicEventPayload(), {
        'gtm.videoStatus': 'completed',
        'gtm.videoPercent': '100%',
        'gtm.videoCurrentTime': this.videoData.duration + 's',
      });
      debug && console.log('payload', payload);
      window.dataLayer.push(payload);
    },
    isSubArrayEnd(A, B) {
      if (A.length < B.length) return false;
      let i = 0;
      while (i < B.length) {
        if (A[A.length - i - 1] !== B[B.length - i - 1]) return false;
        i++;
      }
      return true;
    },
    handleStateChange(event) {
      this.handleEvent(event.data);
    },
    playVideo() {
      this.player.playVideo();
      this.playing = true;

      /* smc */
      if (!this.playedOnce) {
        this.playedOnce = true;

        this.$_smcSupport_sendEvent('WEBSITE_VIDEO', {
          title: this.$_cmsSupport_hasContentSlotWithName('title')
            ? this.$slots.title[0].elm.outerText
            : '',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.responsive-video {
  width: 100%;
  background: $color-black no-repeat 50% 50%;
  background-size: cover;
  height: 0;
  padding-bottom: (3 * 100% / 4);
  position: relative;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba($color-black, 0.5);
  }

  @include media-breakpoint-only(md) {
    height: 590px;
    padding-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: (9 * 100% / 16);
  }

  ::v-deep {
    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      opacity: 0;
      transition: opacity 0.6s ease-in-out;
      pointer-events: none;
    }
  }

  &.playing {
    ::v-deep {
      iframe {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.info {
  position: absolute;
  left: 24px;
  bottom: 24px;
  z-index: 2;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(md) {
    left: 32px;
    bottom: 32px;
  }

  > p {
    margin: 0 0 0 16px;
    position: relative;
    padding-left: 17px;

    &:before {
      content: ' ';
      display: block;
      width: 1px;
      height: 18px;
      background: currentColor;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -9px;
    }

    &:first-child {
      margin: 0;
      padding: 0;

      &:before {
        display: none;
      }
    }
  }
}

.title-mobile {
  margin-top: 15px;

  /deep/ [class^='typo-'],
  /deep/ [class^='text-color-'],
  /deep/ p {
    color: $color-black !important;
  }
}

.play {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
  color: rgba($color-white, 0.75);

  .icon {
    display: block;
    width: 64px;
    height: 64px;
    fill: rgba($color-white, 0.75);

    @media (min-width: $bootstrap-sm) {
      width: 96px;
      height: 96px;
    }
  }
}
</style>
