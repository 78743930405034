import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    //checked from data attribute of wa-config component
    'list-stores-api': '',
    'list-products-api': '',
    'privacy-check-api': '',
    'enter-contest-api': '',
    'check-winner-api': '',
    'redeem-prize-api': '',
    'ch-key-pm5-6-biscuits': '',
    'reg-source-pm5-6-biscuits': '',
    'landing-page-url': '',
    'enter-contest-page-url': '',
    'redeem-prize-page-url': '',
    'json': '',
    'iw_won': null,
    'prize_label': '',
    'relatedProducts': [],
    //checked from data attribute of contest component
    'regex': {
      address: {
        regex: '',
        required: true,
      },
      city: {
        regex: '',
        required: true,
      },
      email: {
        regex: '',
        required: true,
      },
      first_name: {
        regex: '',
        required: true,
      },
      last_name: {
        regex: '',
        required: true,
      },
      phone: {
        regex: '',
        required: true,
      },
      postal_code: {
        regex: '',
        required: true,
      },
    },
    'i18n': {
      participate: {
        hero_title: '',
        hero_title_small: '',
        hero_description: '',
        product_heading: '',
        products_error_required: '',
        data_info_text: '',
        data_info_text_remove: '',
        data_total_products: '',
        shop_type_heading: '',
        shop_type_description: '',
        shop_type_store_label: '',
        shop_type_online_label: '',
        shop_type_error_required: '',
        shop_label: '',
        shop_placeholder: '',
        shop_error_required: '',
        picture_heading: '',
        picture_description: '',
        picture_label: '',
        picture_label_small: '',
        picture_label_hover: '',
        picture_cta: '',
        picture_helper: '',
        picture_helper_small: '',
        picture_error_format: '',
        picture_error_size: '',
        picture_error_required: '',
        picture_instruction_heading: '',
        picture_instruction_legend_1: '',
        picture_instruction_legend_2: '',
        picture_instruction_legend_3: '',
        picture_instruction_legend_4: '',
        picture_instruction_legend_5: '',
        picture_instruction_hint_1: '',
        picture_instruction_hint_2: '',
        picture_instruction_hint_3: '',
        picture_instruction_info_heading: '',
        picture_instruction_info_1: '',
        picture_instruction_info_2: '',
        rules_label: '',
        rules_error_required: '',
        submit_cta: '',
        global_error_logged: '',
        global_error_modal_fail_title: '',
        global_error_modal_fail_content: '',
        global_error_missing_parameters_in_configuration: '',
        global_error_inactive_contest: '',
        global_error_external_server_communication_failed: '',
        global_error_fix_fields: '',
        categories_placeholder: '',
        products_placeholder: '',
        eligible_text_label: '',
        eligible_text_helper: '',
        eligible_text_required: '',
        waiting_text: '',
        win_title: '',
        win_description: '',
        lose_title: '',
        lose_description: '',
        related_title: '',
        related_content: '',
        check_winner_error_title: '',
        check_winner_error_description: '',
        check_winner_error_link: {
          aria_label: '',
          link: '',
          name: '',
          rel: '',
          target: '',
        },
        rules_link: {
          aria_label: '',
          link: '',
          name: '',
          rel: '',
          target: '',
        },
        privacy_link: {
          aria_label: '',
          link: '',
          name: '',
          rel: '',
          target: '',
        },
        win_cta_link: {
          aria_label: '',
          link: '',
          name: '',
          rel: '',
          target: '',
        },
        lose_cta_link: {
          aria_label: '',
          link: '',
          name: '',
          rel: '',
          target: '',
        },
        related_cta_link: {
          aria_label: '',
          link: '',
          name: '',
          rel: '',
          target: '',
        },
      },
      prize: {
        hero_title: '',
        hero_title_small: '',
        hero_description: '',
        title: '',
        description_0: '',
        description_1: '',
        name_label: '',
        name_error_required: '',
        name_error_minLength: '',
        name_error_maxLength: '',
        name_error_regex: '',
        surname_label: '',
        surname_error_required: '',
        surname_error_minLength: '',
        surname_error_maxLength: '',
        surname_error_regex: '',
        email_label: '',
        tooltip_personal_area_text: '',
        prefix_label: '',
        prefix_default: '',
        phone_label: '',
        phone_error_required: '',
        phone_error_minLength: '',
        phone_error_maxLength: '',
        phone_error_regex: '',
        city_label: '',
        city_error_required: '',
        postal_code_label: '',
        postal_code_error_required: '',
        postal_code_error_regex: '',
        address_label: '',
        address_error_required: '',
        address_error_minLength: '',
        address_error_maxLength: '',
        address_error_regex: '',
        mandatory_field_indication_placeholder: '',
        submit_cta: '',
        global_error_logged: '',
        global_error_legal_age: '',
        global_error_inactive_contest: '',
        global_error_external_server_communication_failed: '',
        global_error_unable_to_retrieve_user_token: '',
        global_error_missing_parameters_in_configuration: '',
        global_error_fix_fields: '',
        thank_you_title: '',
        thank_you_description: '',
        thank_you_cta: '',
      },
    },
  },
  actions,
  mutations,
  getters,
};
