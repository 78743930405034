<template>
  <div class="WaCtaBuyNow d-inline-flex">
    <!-- eslint-disable -->
    <div
      :id="`buy-now-${_uid}`"
      :class="[
        dataVariantClass,
        dataBackgroundColor,
        dataTextColor,
        'destini-locator-class',
      ]"
      append-to-body
      :APO="dataUpcCode"
      locator-id="3262"
      alpha-code="CBE"
      locator-name="Wasa Product Widget"
      client-id="wasa"
      destini-header-name=""
      destini-header-image=""
      @click.prevent
    ></div>
    <js-loader
      data-async="true"
      data-defer="true"
      :data-src="`https://lets.shop/productWidgetSnippet.js?id=buy-now-${_uid}`"
    />
  </div>
</template>

<script>
import { debug } from '@helpers/utils';
export default {
  name: 'WaCtaBuyNow',
  props: {
    dataUpcCode: {
      type: String,
      required: true,
    },
    dataVariantClass: {
      type: String,
      required: true,
    },
    dataBackgroundColor: {
      type: String,
      default: null,
    },
    dataTextColor: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped></style>
