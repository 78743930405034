<template>
  <div class="WaPm56CamperPrize">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WaPm56CamperPrize',
  props: {
    dataCamper: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    i18n() {
      return this.$_cmsSupport_castPropToType(this.dataCamper, 'object');
    },
  },
  created() {
    this.setPm56CamperI18nPrize(this.i18n);
  },
  methods: {
    ...mapActions('pm56Camper', ['setPm56CamperI18nPrize']),
  },
};
</script>

<style scoped></style>
