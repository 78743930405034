<template>
  <div
    :class="['WaHistory', { 'lock': animation, 'replace-cursor': mouseHover }]"
  >
    <div :class="['swipe-icon', { visible: mouseHover }]" :style="cursorStyle">
      <svg width="48" height="48">
        <use href="#icon-horizontal-swipe" fill="currentColor" />
      </svg>
    </div>
    <div class="container-fluid">
      <div ref="$scroller" class="swiper-container">
        <div class="swiper-wrapper">
          <swiper-slide
            v-for="(node, index) of $slots.slides"
            :key="index"
            :value="node"
            :container-width="width"
            :step="step"
            :last="index === $slots.slides.length - 1"
          />
        </div>
        <div
          ref="$pagination"
          :class="[
            'swiper-pagination',
            'margin-top-l',
            dataNavigationActiveColor,
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import debounce from 'lodash.debounce';

export default {
  name: 'WaHistory',
  components: {
    SwiperSlide: {
      props: {
        value: { type: [Object, Array], required: true },
        containerWidth: { type: Number, required: true },
        step: { type: String, required: true },
        last: { type: Boolean, default: false },
      },
      data() {
        return {
          variant: 'image-up',
        };
      },
      render: function (createElement) {
        let nodes = this.value;
        if (!Array.isArray(nodes)) {
          nodes = [nodes];
        }
        return createElement(
          'div',
          {
            class: 'swiper-slide',
            style: {
              width: `${this.slideWidth}px`,
              paddingRight: `${this.slidePadding}px`,
            },
          },
          nodes
        );
      },
      mounted() {
        (() => {
          let doCheck = () => {
            if (this.$children[0]) {
              if (this.$children[0].$el.classList.contains('image-right')) {
                this.variant = 'image-right';
              } else if (
                this.$children[0].$el.classList.contains('image-down')
              ) {
                this.variant = 'image-down';
              }
            } else {
              setTimeout(doCheck, 100);
            }
            //implementation
          };
          doCheck();
        })();
      },
      computed: {
        colsNumber() {
          switch (this.step) {
            case 'xs':
              return 9;
            case 'sm':
            case 'md':
              switch (this.variant) {
                case 'image-right':
                  return 8;
                case 'image-down':
                  return 4;
              }
              return 5;
            case 'lg':
            case 'xl':
              switch (this.variant) {
                case 'image-right':
                  return 7;
                case 'image-down':
                  return 4;
              }
              return 5;
          }
        },
        slideWidth() {
          return (
            (this.containerWidth / 12) * (this.colsNumber + this.colsPadding)
          );
        },
        colsPadding() {
          if (this.last) {
            return 12 - this.colsNumber;
          } else {
            return 2;
          }
        },
        slidePadding() {
          switch (this.step) {
            case 'md':
            case 'lg':
            case 'xl':
              return (this.containerWidth / 12) * this.colsPadding + 12;
            default:
              return (this.containerWidth / 12) * this.colsPadding + 8;
          }
        },
      },
    },
  },
  props: {
    dataNavigationActiveColor: {
      type: String,
      default: 'text-color-secondary-item',
    },
  },
  data() {
    return {
      swiper: null,
      animation: false,
      width: 0,
      step: 'xs',
      resizeHandler: null,
      mouseHover: false,
      mouseX: 0,
      mouseY: 0,
    };
  },
  computed: {
    cursorStyle() {
      return {
        top: `${this.mouseY}px`,
        left: `${this.mouseX}px`,
      };
    },
  },
  mounted() {
    this.resizeHandler = debounce(this.onResize, 50);
    window.addEventListener('resize', this.resizeHandler);
    this.onResize();
    this.$nextTick(() => {
      this.swiper = new Swiper(this.$refs.$scroller, {
        touchEventsTarget: 'container',
        slidesPerView: 'auto',
        watchOverflow: true,
        pagination: {
          el: this.$refs.$pagination,
          type: 'bullets',
          clickable: true,
          renderBullet: (index, className) => {
            return `<button type="button" class="${className}"><span class="sr-only">${
              index + 1
            }</span></button>`;
          },
        },
        on: {
          transitionStart: () => {
            this.animation = true;
          },
          transitionEnd: () => {
            this.$nextTick(() => {
              this.animation = false;
            });
          },
          touchMove: (swiper, e) => {
            this.onMouseMove(e);
          },
        },
      });
    });
    window.addEventListener('mousemove', this.onMouseMove);
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    onMouseMove(e) {
      const rect = this.$el.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      if (x >= 48 && y >= 48 && x <= rect.width - 48 && y <= rect.height - 48) {
        this.mouseHover = true;
        this.mouseX = x;
        this.mouseY = y;
      } else {
        this.mouseHover = false;
      }
    },
    onResize() {
      if (window.innerWidth >= 9999) {
        this.step = 'xl';
      } else if (window.innerWidth >= 1440) {
        this.step = 'lg';
      } else if (window.innerWidth >= 1024) {
        this.step = 'md';
      } else if (window.innerWidth >= 768) {
        this.step = 'sm';
      } else {
        this.step = 'xs';
      }
      this.width = this.$refs.$scroller.clientWidth;
      this.$nextTick(() => {
        if (this.swiper) {
          this.swiper.update();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaHistory {
  overflow: hidden;
  position: relative;

  @include media-breakpoint-up(md) {
    &.replace-cursor {
      cursor: none;
    }
  }
}

.swipe-icon {
  border-radius: 50%;
  color: $color-secondary-item;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  margin: -24px 0 0 -24px;
  pointer-events: none;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);

  svg {
    display: block;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 0.5s ease, transform 0.5s ease;

    &.visible {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.swiper-container {
  overflow: visible;
  margin: 0 -8px;

  .swiper-slide {
    padding: 0 8px;
  }

  @include media-breakpoint-up(md) {
    margin: 0 -12px;

    .swiper-slide {
      padding: 0 12px;
    }
  }
}

.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep {
    .swiper-pagination-bullet {
      display: block;
      width: 40px;
      height: 44px;
      margin: 0 4px;
      border: 0;
      padding: 0;
      background: none;
      color: inherit;
      cursor: pointer;
      position: relative;
      outline-width: 0;

      &:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1px;
        border-radius: 2px;
        background: $color-grey-8-50;
        transition: background-color 0.3s ease;
      }

      &-active {
        &:after {
          background: currentColor;
        }
      }
    }
  }
}
</style>
