<template>
  <div class="WaRecipeLabelsConf d-none" aria-hidden="true" />
</template>

<script>
export default {
  name: 'WaRecipeLabelsConf',
  props: {
    dataLabelsConf: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    labelsConf() {
      return this.$_cmsSupport_castPropToType(this.dataLabelsConf, 'object');
    },
  },
  mounted() {
    this.$store.dispatch('config/setConfig', {
      target: 'recipe_labels',
      payload: this.labelsConf,
    });
  },
};
</script>

<style scoped></style>
