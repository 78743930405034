var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['WaCheckbox form-group__wrapper', { disabled: _vm.dataDisabled }]},[_c('div',{class:[
      'form-group',
      { 'form-group--error': _vm.dataErrorOccurred, 'disabled': _vm.dataDisabled } ]},[(_vm.dataLabel)?_c('label',{staticClass:"form__label",attrs:{"for":("form-check__wrapper-" + _vm._uid)}},[_vm._v(" "+_vm._s(_vm.dataLabel)+" ")]):_vm._e(),_c('div',{staticClass:"form-check__wrapper",attrs:{"id":("form-check__wrapper-" + _vm._uid)}},_vm._l((_vm.dataCheckboxList),function(checkbox,i){return _c('div',{key:("checkbox-" + i + "-" + _vm._uid),class:['form-check', { disabled: checkbox.dataDisabled }]},[(checkbox.label)?_c('label',{class:['form-check-label', { disabled: _vm.dataDisabled }],attrs:{"role":_vm.isCustom ? 'checkbox' : null,"aria-checked":_vm.isCustom
              ? _vm.dataCheckboxList.length > 1
                ? '' + (_vm.value.indexOf(checkbox.value) !== -1)
                : '' + _vm.value
              : null,"tabindex":_vm.isCustom ? 0 : null,"for":("input-checkbox-" + i + "-" + _vm._uid)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }_vm.isCustom ? $event.target.click() : null}}},[_c('span',{domProps:{"innerHTML":_vm._s(checkbox.label)}}),_c('input',{staticClass:"form-check-input",attrs:{"id":("input-checkbox-" + i + "-" + _vm._uid),"type":"checkbox","name":_vm.dataName,"disabled":_vm.dataDisabled || checkbox.dataDisabled},domProps:{"value":checkbox.value,"checked":_vm.dataCheckboxList.length > 1
                ? _vm.value.indexOf(checkbox.value) !== -1
                : checkbox.value === _vm.value},on:{"change":_vm.onUpdate}}),_c('span',{class:['checkmark', { disabled: _vm.dataDisabled }]})]):_vm._e()])}),0)]),_vm._l((_vm.hasErrors),function(error,i){return _c('span',{key:("error-" + i + "-" + _vm._uid),staticClass:"error typo-a-10",domProps:{"innerHTML":_vm._s(error.message)}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }