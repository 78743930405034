<template>
  <section class="WaTitleTextCta">
    <div v-if="dataVariant === 'badge'">
      <div :class="{ 'container-fluid': !fullWidth }">
        <div :class="{ 'row justify-content-md-center': !fullWidth }">
          <div class="col-12">
            <div class="row">
              <div class="content col-12 col-sm-4 offset-sm-1">
                <div class="text typo-a-6 typo-light">
                  <!-- title_text -->
                  <slot name="title_text" />

                  <!-- content_wysiwyg -->
                  <slot name="content_wysiwyg" />

                  <!-- social_share -->
                  <slot v-if="isLower" name="social-share" />
                  <!-- cta -->
                  <slot name="cta" />
                </div>
              </div>
              <div
                class="badge col-12 offset-sm-4 col-sm-2 d-flex justify-content-sm-end"
              >
                <!-- slot badge -->
                <slot name="badge" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div :class="{ 'container-fluid': !fullWidth }">
        <!-- title_text -->
        <slot name="title_text" />
        <div v-if="dataHasReviewsWidget" id="pwr-snippets">
          <div :id="`pr-reviewsnippet-${_uid}`"></div>
          <!-- <div :id="`pr-qasnippet-${_uid}`"></div> -->
        </div>
        <div :class="{ row: !fullWidth }">
          <div
            :class="[
              fullWidth ? null : 'col-12',
              fullWidth ? null : `col-sm-${isLower ? '6' : '7'}`,
              fullWidth ? null : `col-md-${isLower ? '4' : '7'}`,
              'text',
              'typo-a-6',
              'typo-light',
            ]"
          >
            <!-- content_wysiwyg -->
            <slot name="content_wysiwyg" />
          </div>
          <div
            :class="[
              fullWidth ? null : 'col-12',
              fullWidth ? null : `col-sm-${isLower ? '12' : '5'}`,
              `text-sm-${isLower ? 'left' : 'right'}`,
              `align-self-${isLower ? 'start' : 'end'}`,
              'typo-a-8',
              'typo-light',
            ]"
          >
            <!-- social_share -->
            <slot v-if="isLower" name="social-share" />
            <!-- cta -->
            <slot name="cta" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WaTitleTextCta',
  props: {
    dataFullWidth: { type: [Boolean, String], default: false },
    dataHasReviewsWidget: { type: [Boolean, String], default: false },
    dataVariant: { type: String, default: '' },
  },
  data() {
    return {
      isLower: false,
    };
  },
  computed: {
    fullWidth() {
      return this.$_cmsSupport_castPropToType(this.dataFullWidth, 'boolean');
    },
    hasReviewsWidget() {
      return this.$_cmsSupport_castPropToType(
        this.dataHasReviewsWidget,
        'boolean'
      );
    },
  },
  mounted() {
    this.isLower = this.$el.classList.contains('lower-cta');
    if (this.hasReviewsWidget) {
      this.$watch(
        () => this.$root.arePowerReviewConfsReady,
        (confsAreReady) => {
          if (confsAreReady) {
            this.addPrWidget();
          }
        },
        { immediate: true }
      );
    }
  },
  methods: {
    addPrWidget() {
      //272079137513752 	Sesam & Vollkorn
      this.$root.setWidgetsToMount([
        ...this.$root.getWidgetsToMount(),
        {
          ...this.$root.getReviewDisplayBaseConf(),
          components: {
            ReviewSnippet: `pr-reviewsnippet-${this._uid}`,
            //QuestionSnippet: `pr-qasnippet-${this._uid}`,
          },
        },
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaTitleTextCta {
  &.side-cta {
    .text {
      /deep/ p:last-child {
        margin-bottom: 0;
      }

      margin-bottom: 24px;

      @media (min-width: $bootstrap-sm) {
        margin-bottom: 0;
      }
    }
  }

  /deep/ .badge {
    img {
      width: 64px;
      height: 64px;
      margin-top: 32px;

      @media (min-width: $bootstrap-sm) {
        width: 80px;
        height: 80px;
        margin-top: 0px;
      }
      @media (min-width: $bootstrap-md) {
        width: 120px;
        height: 120px;
      }
    }
  }
}
</style>
