<template functional>
  <div
    :ref="data.ref"
    :class="['WaPm56SeptemberGame noMBLastChild', data.staticClass, data.class]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WaPm56SeptemberGame',
  functional: true,
};
</script>

<style scoped></style>
