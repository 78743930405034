<template>
  <div class="WaPm56BiscuitsThankYou">
    <wa-side-backgrounds
      class="padding-top-xxl padding-bottom-0"
      :data-background-right="
        require('@images/biscuits/thank-you-bg-right.png')
      "
      :data-background-left="require('@images/biscuits/thank-you-bg-left.png')"
      data-width-right="305"
      data-height-right="417"
      data-width-left="302"
      data-height-left="425"
    >
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-8 col-md-8 d-flex flex-column align-items-center justify-content-center text-color-sport-blue"
          >
            <div ref="$animation"></div>
          </div>
        </div>
      </div>
    </wa-side-backgrounds>
    <div class="container-fluid padding-top-0 padding-bottom-xxl">
      <div class="row justify-content-center">
        <div
          class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-sport-blue"
        >
          <transition name="fade" mode="out-in">
            <div v-if="!showText" key="waiting">
              <div
                class="content typo-a-6 typo-light text-center text-color-black padding-bottom-0 margin-bottom-0"
                v-html="i18nParticipate.waiting_for_win_text"
              ></div>
            </div>
            <div v-if="showText" key="results">
              <div
                v-if="title"
                class="typo-w-3 typo-bold text-center text-color-black padding-bottom-0 margin-bottom-0"
                v-html="title"
              ></div>
              <div
                v-if="text"
                class="typo-a-6 typo-light text-center text-color-black padding-bottom-0 margin-bottom-0"
                v-html="text"
              ></div>
              <div
                v-if="ctaLabel && ctaHref"
                class="text-center text-color-black margin-top-m padding-bottom-0 margin-bottom-0"
              >
                <wa-cta
                  class="primary"
                  :data-href="ctaHref"
                  data-rel="nofollow"
                >
                  {{ ctaLabel }}
                </wa-cta>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="!showText" key="waiting-related">
        <div>&nbsp;</div>
      </div>
      <div v-if="showText && iw_won === false" key="related">
        <wa-irregular-divider
          class="padding-top-xl padding-bottom-0 text-color-secondary-item"
        ></wa-irregular-divider>

        <wa-title-text-cta
          class="padding-top-xl padding-bottom-0 side-cta"
          data-variant="side-cta"
        >
          <template slot="title_text">
            <h3
              class="typo-w typo-w-4 typo-bold padding-bottom-r margin-bottom-0 text-color-secondary-item"
            >
              {{ related.title }}
            </h3>
          </template>
          <template slot="content_wysiwyg">
            <div class="typo-light" v-html="related.content" />
          </template>
          <template slot="cta">
            <wa-cta
              class="link"
              data-variant-class="link"
              data-icon-id="icon-arrow-angle-right"
              :data-href="related.ctaHref"
              data-target="_self"
            >
              {{ related.ctaLabel }}
            </wa-cta>
          </template>
        </wa-title-text-cta>
        <wa-grid-container
          class="container-fluid padding-top-m padding-bottom-xxl"
        >
          <template slot="wa-grid-row">
            <wa-grid-row class="row justify-content-center">
              <template slot="wa-grid-column">
                <wa-grid-column
                  v-for="product in relatedProducts"
                  :key="product.href"
                  class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-12"
                >
                  <template slot="wa-grid-column-childs">
                    <wa-product-card :data-conf="product" />
                  </template>
                </wa-grid-column>
              </template>
            </wa-grid-row>
          </template>
        </wa-grid-container>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import lottie from 'lottie-web';

export default {
  name: 'WaPm56BiscuitsThankYou',
  data() {
    return {
      showText: false,
    };
  },
  computed: {
    ...mapState('config', ['home_page_url']),
    ...mapState('pm56Biscuits', [
      'ch-key-pm5-6-biscuits',
      'json',
      'iw_won',
      'relatedProducts',
      'prize_label',
    ]),
    ...mapGetters('pm56Biscuits', ['i18nParticipate']),
    title() {
      return this.iw_won
        ? this.i18nParticipate.win_title
        : this.i18nParticipate.lose_title;
    },
    text() {
      return this.iw_won
        ? this.i18nParticipate.win_description
        : this.i18nParticipate.lose_description;
    },
    ctaLabel() {
      return this.iw_won
        ? this.i18nParticipate.win_cta_link.name
        : this.i18nParticipate.lose_cta_link.name;
    },
    ctaHref() {
      return this.iw_won
        ? this.i18nParticipate.win_cta_link.link
        : this.i18nParticipate.lose_cta_link.link;
    },
    related() {
      return {
        title: this.i18nParticipate.related_title,
        content: this.i18nParticipate.related_content,
        ctaHref: this.i18nParticipate.related_cta_link.link,
        ctaLabel: this.i18nParticipate.related_cta_link.name,
      };
    },
  },
  created() {
    this.setInvertedHeader(false);
  },
  mounted() {
    (() => {
      let doCheck = () => {
        if (!this.$refs.$animation) {
          setTimeout(doCheck, 50);
        } else {
          this.animation = lottie.loadAnimation({
            container: this.$refs.$animation, // the dom element that will contain the animation
            path: this.json, // the path to the animation json
            renderer: 'svg',
            loop: false,
            autoplay: true,
          });
          this.animation.addEventListener('complete', () => {
            this.showText = true;
            this.pushGenericCh(this['ch-key-pm5-6-biscuits']);
          });
        }
      };
      doCheck();
    })();
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    ...mapActions('gigya', ['pushGenericCh']),
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaPm56BiscuitsThankYou {
  .icon {
    width: 48px;
    height: 48px;
  }
}
</style>
