<template>
  <section ref="heroOuter" :class="outerClasses" :style="outerStyles">
    <slot name="seo-title" />
    <div ref="$heroInner" v-view="visibilityChanged" :class="innerClasses">
      <div
        v-if="bgImage && (!isVariantCenterSmall || $viewportWidth >= 768)"
        class="bg"
      >
        <img ref="$background" :src="bgImage" alt="background" />
      </div>
      <div
        v-if="isVariantProduct && $viewportWidth > 767"
        :class="['product-image-effect', { noTransition, fxEndActive }]"
        :style="fxPosition"
      >
        <div
          class="image"
          :style="{ backgroundImage: `url(${relatedProduct.image_unpacked})` }"
        >
          <span class="sr-only">
            {{ relatedProduct.image_unpacked_alternate }}
          </span>
        </div>
      </div>
      <div class="hero-container container-fluid">
        <div
          v-if="
            $_cmsSupport_hasContentSlotWithName('title') && isVariantProduct
          "
          class="row justify-content-center"
        >
          <div class="col-12">
            <div
              ref="heroTitle"
              :class="[
                'hero-title typo-w text-center typo-heavy margin-bottom-0',
                isVariantProduct ? 'typo-w-1' : 'typo-w-2',
              ]"
            >
              <slot name="title" />
            </div>
          </div>
          <div class="col-sm-8">
            <div class="product-image">
              <img
                :class="[{ hideProduct }]"
                :src="relatedProduct.image_packed"
                :alt="relatedProduct.image_packed_alternate"
              />
              <div
                v-if="isVariantProduct && $viewportWidth > 767"
                ref="$fxStart"
                class="fx-start-placeholder"
                :style="fx"
              />
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div ref="heroContent" :class="['hero-content', contentCols]">
            <div
              v-if="$_cmsSupport_hasContentSlotWithName('title-small')"
              class="hero-title-small typo-medium"
              :class="{
                'typo-w typo-w-4': isVariantProduct,
                'typo-a typo-a-6': !isVariantProduct,
              }"
            >
              <slot name="title-small" />
            </div>
            <div
              v-if="
                $_cmsSupport_hasContentSlotWithName('title') &&
                !isVariantProduct
              "
              :class="[
                'hero-title typo-bold margin-top-xs',
                {
                  'typo-w-2':
                    (isVariantCenter && !isSlide) ||
                    (isVariantCenterSmall && !isSlide),
                  'typo-w-3': (isVariantLeft && !isSlide) || isSlide,
                },
              ]"
            >
              <slot name="title" />
            </div>
            <div
              v-if="$_cmsSupport_hasContentSlotWithName('description')"
              class="hero-description typo-a typo-a-6 typo-light margin-top-r"
            >
              <template v-if="isVariantLeft">
                <div class="container-fluid p-0">
                  <div class="row">
                    <div class="col-12 col-sm-8">
                      <slot name="description" />
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <slot name="description" />
              </template>
            </div>
            <div
              v-if="$_cmsSupport_hasContentSlotWithName('social-share')"
              class="hero-social-share margin-top-s"
            >
              <slot name="social-share" />
            </div>
            <div
              v-if="$_cmsSupport_hasContentSlotWithName('cta')"
              :class="[
                'cta-wrapper',
                {
                  'margin-top-m': isVariantCenter,
                  'margin-top-s': isVariantLeft,
                },
              ]"
            >
              <slot name="cta" />
            </div>
            <slot name="common" />
            <div
              v-if="$_cmsSupport_hasContentSlotWithName('cta-buy-now')"
              :class="[
                'cta-wrapper',
                {
                  'margin-top-m': isVariantCenter,
                  'margin-top-s': isVariantLeft,
                },
              ]"
            >
              <slot name="cta-buy-now" />
            </div>
          </div>
          <div
            v-if="isVariantProduct && $viewportWidth > 767"
            class="col-12 col-sm-6 col-sm-offset-1 col-lg-offset-2"
          >
            <div
              ref="$fxEnd"
              v-view="fxEndVisibilityChanged"
              class="fx-end-placeholder"
            />
          </div>
        </div>
        <figure
          v-if="hasArrow"
          ref="arrowDown"
          :class="[
            'link-arrow-down',
            dataArrowDownColorClass,
            { 'is-visible': isVisible, 'has-been-shown': hasBeenShown },
          ]"
        >
          <svg class="icon-arrow-down" width="24" height="24">
            <use href="#icon-arrow-down" fill="currentColor" />
          </svg>
        </figure>
        <div v-if="$_cmsSupport_hasContentSlotWithName('vertical-name')">
          <slot name="vertical-name" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import imagesLoaded from 'imagesloaded';
import debounce from 'lodash.debounce';
import simpleParallax from 'simple-parallax-js';
import anime from 'animejs/lib/anime.es.js';
import SmcSupport from '@mixins/smc-support';

export default {
  name: 'WaHero',
  mixins: [SmcSupport],
  props: {
    dataProductType: {
      type: String,
      default: '1',
    },
    dataBackgroundColor: {
      type: String,
      default: null,
    },
    dataVariantClass: {
      type: String,
      default: null,
      validator(value) {
        return (
          ['center', 'center-small', 'left', 'product'].indexOf(value) !== -1
        );
      },
    },
    dataBgSrcImage: {
      type: String,
      default: null,
    },
    dataBgMobileSrcImage: {
      type: String,
      default: null,
    },
    dataHasDownArrow: {
      type: [Boolean, String],
      default: false,
    },
    dataArrowDownColorClass: {
      type: String,
      default: null,
    },
    dataRelatedProduct: {
      type: [String, Object],
      default: () => {
        return {};
      },
    },
    dataFxStartStyle: {
      type: [String, Object],
      default: () => {
        return {
          left: '0',
          width: '100%',
          top: '0',
          height: '100%',
        };
      },
    },
    dataHasParallax: {
      type: [Boolean, String],
      default: false,
    },
    dataIsSlide: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      hideProduct: false,
      hasBeenShown: false,
      isVisible: false,
      hasPlayedTransition: false,
      startPosition: {
        opacity: 0,
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
      endPosition: {
        opacity: 1,
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
      noTransition: false,
      fxEndActive: false,
      resizeHandler: null,
      parallax: null,
    };
  },
  computed: {
    hasParallax() {
      return this.$_cmsSupport_castPropToType(this.dataHasParallax, 'boolean');
    },
    hasArrow() {
      return this.$_cmsSupport_castPropToType(this.dataHasDownArrow, 'boolean');
    },
    relatedProduct() {
      return this.$_cmsSupport_castPropToType(
        this.dataRelatedProduct,
        'object'
      );
    },
    fx() {
      return this.$_cmsSupport_castPropToType(this.dataFxStartStyle, 'object');
    },
    name() {
      return this.$options.name;
    },
    outerClasses() {
      return ['hero-outer', this.$options.name, this.dataVariantClass];
    },
    outerStyles() {
      return {};
    },
    innerClasses() {
      return ['hero-inner', this.dataBackgroundColor];
    },
    contentCols() {
      let cols = '';
      if (this.isVariantCenter || this.isVariantCenterSmall) {
        cols = 'col-12 col-md-10 mx-auto';
      } else if (this.isVariantLeft) {
        cols = 'col-12 col-sm-6';
      } else if (this.isVariantProduct) {
        cols = 'col-12 col-sm-5 col-lg-4';
      }
      return cols;
    },
    isVariantCenter() {
      return this.dataVariantClass === 'center';
    },
    isVariantCenterSmall() {
      return this.dataVariantClass === 'center-small';
    },
    isVariantLeft() {
      return this.dataVariantClass === 'left';
    },
    isVariantProduct() {
      return this.dataVariantClass === 'product';
    },
    checkOrientation() {
      return this.$viewportWidth < this.$viewportHeight
        ? 'portrait'
        : 'landscape';
    },
    bgImage() {
      return this.hasMobileBg ? this.dataBgMobileSrcImage : this.dataBgSrcImage;
    },
    hasMobileBg() {
      return ['xs'].includes(this.$currentStep);
    },
    fxPosition() {
      if (this.fxEndActive) {
        return this.endPosition;
      } else {
        return this.startPosition;
      }
    },
    isSlide() {
      return this.$_cmsSupport_castPropToType(this.dataIsSlide, 'boolean');
    },
  },
  watch: {
    fxEndActive: {
      immediate: true,
      handler(oldVal, newVal) {
        // console.log('oldVal',oldVal)
        // console.log('newVal',newVal)
        // console.log('this.dataProductType',this.dataProductType)
        // console.log('this.hideProduct',this.hideProduct)
        // console.log('this.noTransition',this.noTransition)
        if (oldVal && !newVal && !this.hasPlayedTransition) {
          if (this.dataProductType === '1' || this.dataProductType === '7') {
            this.hideProduct = true;
          }

          setTimeout(() => {
            this.noTransition = true;
            this.hasPlayedTransition = true;
          }, 1100);
        }
      },
    },
    $currentStep: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.handleParallax();
        }, 200);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      imagesLoaded(this.$el, { background: true }, () => {
        if (this.isVariantProduct) {
          this.resizeHandler = debounce(this.updatePositions, 20);
          window.addEventListener('resize', this.resizeHandler);
          this.updatePositions();
          anime({
            targets: this.$refs.heroTitle,
            opacity: 1,
            delay: 500,
            duration: 750,
            easing: 'easeInOutQuad',
          });

          /* smc */
          this.$watch(
            () => this.smcReady,
            (val) => {
              if (val) {
                //console.log('this.relatedProduct',this.relatedProduct)
                this.$_smcSupport_sendEvent('ZPRODUCT_VISIT', {
                  productCode: this.relatedProduct.smc_id,
                  highlightedContent:
                    this.relatedProduct.smc_highlighted_content,
                });
              }
            },
            { immediate: true }
          );
        } else {
          anime({
            targets: this.$refs.heroContent,
            opacity: 1,
            delay: 400,
            duration: 350,
            easing: 'easeInOutQuad',
          });
        }
      });
    });
  },
  beforeDestroy() {
    if (this.resizeHandler) {
      window.removeEventListener('resize', this.resizeHandler);
    }
    if (this.parallax) {
      this.parallax.destroy();
    }
  },
  methods: {
    handleParallax() {
      if (this.hasParallax) {
        if (!this.dataIsSlide && this.$refs.$background) {
          if (['md', 'lg', 'xl'].includes(this.$currentStep)) {
            if (!this.parallax) {
              this.parallax = new simpleParallax(this.$refs.$background, {
                delay: 0.1,
                scale: 1.3,
                transition: 'cubic-bezier(0,0,0,1)',
              });
            }
          } else {
            if (this.parallax) {
              this.parallax.destroy();
              this.parallax = null;
            }
          }
        }
      }
    },
    visibilityChanged(e) {
      // if (e.type === 'enter') {
      //   this.isVisible = true;
      // } else if (e.type === 'exit') {
      //   this.isVisible = false;
      //   if (!this.hasBeenShown) {
      //     this.hasBeenShown = true;
      //   }
      // }
    },
    fxEndVisibilityChanged(e) {
      this.fxEndActive = this.hasPlayedTransition ? true : e.percentCenter < 1;
    },
    updateElemPosition(elem, data) {
      if (elem) {
        data.width = `${elem.clientWidth}px`;
        data.height = `${elem.clientHeight}px`;
        let top = 0,
          left = 0;
        while (elem !== this.$refs.$heroInner) {
          top = top + elem.offsetTop;
          left = left + elem.offsetLeft;
          elem = elem.parentNode;
        }
        data.top = `${top}px`;
        data.left = `${left}px`;
      }
    },
    updatePositions() {
      this.noTransition = true;
      this.$nextTick(() => {
        this.updateElemPosition(this.$refs.$fxStart, this.startPosition);
        this.updateElemPosition(this.$refs.$fxEnd, this.endPosition);
        setTimeout(() => {
          this.noTransition = false;
        }, 50);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~$scss/utility';
@import '~bootstrap/scss/mixins/breakpoints';

.fx-bounce {
  animation-name: fx-bounce;
  animation-timing-function: ease;
}

@keyframes fx-bounce {
  0% {
    transform: translateY(30%);
  }

  50% {
    transform: translateY(-30%);
  }

  100% {
    transform: translateY(30%);
  }
}

.WaHero {
  background-color: $color-white;

  .hero-inner {
    position: relative;
    display: flex;

    > .bg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      overflow: hidden;

      ::v-deep .simpleParallax,
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .hero-title,
  .hero-title-small,
  .hero-description {
    * {
      margin-top: 0;
      margin-bottom: 0;
      font-size: inherit;
    }
  }

  .hero-content {
    > *:first-child {
      margin-top: 0 !important;
    }
  }

  &:not(.product) {
    .hero-content {
      opacity: 0;
      transition: opacity 0.8s ease-in-out;

      &.active {
        opacity: 1;
        transition-delay: 0.8s;
      }
    }
  }

  // ---------------------------------------
  // Elements
  // ---------------------------------------

  .hero-container {
    position: relative;
    z-index: 1;
  }

  img {
    display: block;
    margin: 0 auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .link-arrow-down {
    z-index: 1;
    position: absolute;
    bottom: 32px;
    left: 50%;
    margin: 0 0 0 -20px;
    color: $color-white;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-arrow-down {
      display: block;
    }

    &:focus,
    &:active {
      outline: none;
    }

    &.has-been-shown {
      display: none;
    }

    @media (min-width: $bootstrap-sm) {
      bottom: 40px;
    }

    @media (min-width: $bootstrap-md) {
      bottom: 64px;
    }
  }

  // ---------------------------------------
  //  Variants
  // ---------------------------------------

  &.center {
    text-align: center;

    .hero-container {
      display: flex;
      flex-direction: column;
      height: 568px;
      padding-top: 98px;

      @media (min-width: $bootstrap-sm) {
        height: 600px;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 156px;
      }

      @media (min-width: $bootstrap-md) {
        height: 768px;
        padding-bottom: 122px;
      }

      @media (min-width: $bootstrap-lg) {
        height: 800px;
        padding-bottom: 69px;
      }
    }
  }

  &.center-small {
    text-align: center;

    .hero-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 280px;

      @media (min-width: $bootstrap-sm) {
        height: 400px;
      }

      @media (min-width: $bootstrap-md) {
        height: 560px;
      }

      @media (min-width: $bootstrap-lg) {
        height: 640px;
      }
    }
  }

  &.left {
    .hero-container {
      display: flex;
      flex-direction: column;
      height: 568px;
      padding-top: 98px;

      @media (min-width: $bootstrap-sm) {
        height: 600px;
        justify-content: center;
        padding-top: 51px;
      }

      @media (min-width: $bootstrap-md) {
        height: 768px;
        padding-top: 126px;
      }

      @media (min-width: $bootstrap-lg) {
        height: 800px;
        padding-top: 138px;
      }
    }

    .link-arrow-down {
      left: 0;
      margin-left: 0;

      @media (min-width: $bootstrap-sm) {
        left: auto;
        right: 0;
      }
    }
  }

  &.product {
    overflow: hidden;

    .product-image-effect {
      position: absolute;
      z-index: 2;
      transition-property: top, width, left, height, opacity;
      transition-duration: 1s, 1s, 1s, 1s, 0.3s;
      transition-timing-function: ease-in-out, ease-in-out, ease-in-out,
        ease-in-out, ease-in-out;
      transition-delay: 0s, 0s, 0s, 0s, 0.7s;

      &.fxEndActive {
        transition-delay: 0s, 0s, 0s, 0s, 0s;
      }

      &.noTransition {
        transition: none;
      }

      > .image {
        width: 100%;
        height: 100%;
        background: no-repeat 50% 50%;
        background-size: contain;
      }
    }

    .hero-container {
      padding-top: 98px;
      padding-bottom: 120px;

      @media (min-width: $bootstrap-sm) {
        padding-top: 148px;
        padding-bottom: 140px;
      }

      @media (min-width: $bootstrap-md) {
        padding-top: 222px;
        padding-bottom: 200px;
      }

      @media (min-width: $bootstrap-lg) {
        padding-top: 258px;
        padding-bottom: 200px;
      }

      > .row {
        position: relative;
      }
    }

    .hero-title {
      opacity: 0;
    }

    .hero-title-small {
      text-transform: uppercase;
    }

    .product-image {
      position: relative;
      width: 260px;
      height: 195px;
      margin: -28px auto 0;

      @media (min-width: $bootstrap-sm) {
        width: 400px;
        height: 300px;
        margin: -40px auto 0;
      }

      @media (min-width: $bootstrap-md) {
        width: 560px;
        height: 420px;
        margin: -56px auto 0;
      }

      @media (min-width: $bootstrap-lg) {
        width: 800px;
        height: 600px;
      }

      > img {
        display: block;
        width: 100%;
        height: auto;
        max-width: none;
        max-height: none;
        position: relative;
        z-index: 1;
        filter: drop-shadow(0 0 12px rgba($color-black, 0.15));

        @media (min-width: $bootstrap-sm) {
          filter: drop-shadow(0 0 16px rgba($color-black, 0.15));
        }

        @media (min-width: $bootstrap-md) {
          filter: drop-shadow(0 0 24px rgba($color-black, 0.15));
        }

        opacity: 1;
        transition: all 0.7s ease;

        &.hideProduct {
          opacity: 0;
        }
      }

      .fx-start-placeholder {
        position: absolute;
        z-index: 2;
      }
    }

    .fx-end-placeholder {
      position: relative;
      height: 178px;

      @media (min-width: $bootstrap-md) {
        height: 246px;
      }

      @media (min-width: $bootstrap-lg) {
        height: 352px;
      }
    }

    .WaTitleTextCta {
      ::v-deep {
        .WaCta {
          width: auto;
        }
      }
    }
  }
}

.cta-wrapper {
  ::v-deep {
    .WaCta {
      width: auto;

      @include media-breakpoint-down(sm) {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}
</style>
