<template>
  <contact-form-layout-cmp
    :class="[
      'ContactFormSpa',
      {
        loading: isLoading.length,
      },
    ]"
  >
    <contact-form-hero-cmp v-if="heroType" :data-type="heroType" />

    <transition name="fade" mode="out-in">
      <router-view class="view-wrapper" />
    </transition>

    <contact-form-grid-cmp v-if="!!common.legalText">
      <div class="legal-text" v-html="common.legalText" />
    </contact-form-grid-cmp>
  </contact-form-layout-cmp>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactFormSpa',
  computed: {
    ...mapState('contactForm2024', ['isLoading', 'common']),
    currentRoute() {
      return this.$route.meta.routeId;
    },
    heroType() {
      if (this.currentRoute?.split('_')[0] === 'triage') {
        return 'triage';
      } else if (this.currentRoute?.split('_')[0] === 'ticketing') {
        return 'ticketing';
      } else {
        return 'triage';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormSpa {
  &.loading {
    &:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw !important;
      height: 100vh !important;
      background: rgba(0, 0, 0, 0.2);
      z-index: 8888888888888;
    }

    &:after {
      content: '';
      display: block;
      position: fixed;
      top: 50%;
      left: 50%;
      width: 48px !important;
      height: 48px !important;
      min-width: 0;
      min-height: 0;
      color: rgba(255, 255, 255, 1);
      border: 5px solid;
      border-right-color: rgba(0, 0, 0, 0.8);
      border-radius: 50%;
      background: none;
      transform: translateX(-50%) translateY(-50%);
      animation: rotate 1s linear infinite;
      z-index: 88888888888889;
    }
  }
  .view-wrapper {
    padding-bottom: var(--contact-form-view-wrapper-padding-bottom, 0);
  }

  /* transition */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.25s ease;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .legal-text {
    padding-bottom: var(--contact-form-legal-text-padding-bottom);
  }
}
</style>
