<template>
  <div class="WaContactFormBack">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <span class="cta" @click.prevent="onBackBtnClick(prev)">
            <slot />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'WaContactFormBack',
  props: {
    prev: {
      type: String,
      default: null,
    },
    prevParam: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState('contact', ['formData']),
  },
  methods: {
    onBackBtnClick(prev) {
      const s = this.prevParam ? this.formData[this.prevParam] : null;

      this.$emit('before-route-push', s);

      if (s) {
        this.$router.push({ name: prev, params: { choice: s } });
      } else {
        this.$router.push({ name: prev });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaContactFormBack {
  @include media-breakpoint-up(md) {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .cta {
    position: relative;
    z-index: map_get($z, base);
  }
}
</style>
