export default {
  install(Vue) {
    const expandDirectiveInit = (el, binding) => {
      el.classList.add('expand');
      el.setAttribute('aria-expanded', binding.value ? 'true' : 'false');
      el.setAttribute('aria-hidden', binding.value ? 'false' : 'true');
      if (binding.value) {
        el.classList.remove('d-none');
      } else {
        el.classList.add('d-none');
      }
      el.addEventListener('transitionend', () => {
        el.style.height = null;
        if (el.getAttribute('aria-expanded') === 'true') {
          el.classList.remove('d-none');
        } else {
          el.classList.add('d-none');
        }
      });
    };

    Vue.directive('expand', {
      inserted: function (el, binding) {
        if (binding.value !== null) {
          expandDirectiveInit(el, binding);
        }
      },
      update: function (el, binding) {
        if (binding.value !== null) {
          if (!el.classList.contains('expand'))
            expandDirectiveInit(el, binding);

          const currentState = el.getAttribute('aria-expanded');
          if ((currentState === 'true') === !!binding.value) {
            return;
          }

          el.classList.remove('d-none');
          el.classList.add('u-no-transition');
          el.removeAttribute('aria-expanded');
          el.style.height = null;
          el.style.height = el.clientHeight + 'px';
          el.setAttribute('aria-expanded', currentState);
          setTimeout(() => {
            el.classList.remove('u-no-transition');
            el.setAttribute('aria-expanded', binding.value ? 'true' : 'false');
            el.setAttribute('aria-hidden', binding.value ? 'false' : 'true');
          }, 10);
        }
      },
    });
  },
};
