<template>
  <div :class="['WaCheckbox form-group__wrapper', { disabled: dataDisabled }]">
    <div
      :class="[
        'form-group',
        { 'form-group--error': dataErrorOccurred, 'disabled': dataDisabled },
      ]"
    >
      <label
        v-if="dataLabel"
        class="form__label"
        :for="`form-check__wrapper-${_uid}`"
      >
        {{ dataLabel }}
      </label>
      <div :id="`form-check__wrapper-${_uid}`" class="form-check__wrapper">
        <div
          v-for="(checkbox, i) in dataCheckboxList"
          :key="`checkbox-${i}-${_uid}`"
          :class="['form-check', { disabled: checkbox.dataDisabled }]"
        >
          <label
            v-if="checkbox.label"
            :class="['form-check-label', { disabled: dataDisabled }]"
            :role="isCustom ? 'checkbox' : null"
            :aria-checked="
              isCustom
                ? dataCheckboxList.length > 1
                  ? '' + (value.indexOf(checkbox.value) !== -1)
                  : '' + value
                : null
            "
            :tabindex="isCustom ? 0 : null"
            :for="`input-checkbox-${i}-${_uid}`"
            @keyup.space="isCustom ? $event.target.click() : null"
          >
            <span v-html="checkbox.label" />
            <input
              :id="`input-checkbox-${i}-${_uid}`"
              type="checkbox"
              :name="dataName"
              :value="checkbox.value"
              :checked="
                dataCheckboxList.length > 1
                  ? value.indexOf(checkbox.value) !== -1
                  : checkbox.value === value
              "
              class="form-check-input"
              :disabled="dataDisabled || checkbox.dataDisabled"
              @change="onUpdate"
            />
            <span :class="['checkmark', { disabled: dataDisabled }]" />
          </label>
        </div>
      </div>
    </div>
    <span
      v-for="(error, i) in hasErrors"
      :key="`error-${i}-${_uid}`"
      class="error typo-a-10"
      v-html="error.message"
    />
  </div>
</template>

<script>
export default {
  name: 'WaCheckbox',
  props: {
    dataLabel: {
      type: String,
      default: null,
    },
    dataName: {
      type: String,
      required: true,
    },
    value: {
      type: [Boolean, Array],
      required: true,
    },
    dataCheckboxList: {
      type: Array,
      required: true,
    },
    dataDisabled: {
      type: Boolean,
      default: null,
    },
    dataErrorOccurred: {
      type: Boolean,
      default: null,
    },
    dataErrors: {
      type: Array,
      default: null,
    },
    dataIndeterminate: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      isCustom: null,
    };
  },
  computed: {
    hasErrors() {
      if (this.dataErrorOccurred) {
        return this.dataErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.isCustom =
      this.$el.classList.contains('custom') ||
      this.$el.classList.contains('switch');
  },
  methods: {
    onUpdate(e) {
      if (this.dataCheckboxList.length > 1) {
        const checkedEl = this.value.slice();
        const found = checkedEl.indexOf(e.target.value);
        if (found !== -1) {
          checkedEl.splice(found, 1);
        } else {
          checkedEl.push(e.target.value);
        }
        this.$emit('input', checkedEl);
      } else {
        this.$emit('input', e.target.checked);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.form-group__wrapper {
  display: inline-block;

  .error {
    color: $color-informative-red;
    font: $font-primary, $font-system;
    font-weight: $fh-medium;
    display: inline-block;
    padding-top: 8px;
  }

  .form-check__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 -20px;
  }

  //label(container)
  .form-check-label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 16px;
    margin: 0;
    font: $font-primary, $font-system;
    font-weight: $fh-light;
    user-select: none;
  }

  .form__label {
    font: $font-primary, $font-system;
    font-size: 16px;
  }

  .form-check {
    margin: 0 20px;
  }

  //Hide default checkbox
  .form-check-label input {
    position: absolute;
    //opacity: 0;
    display: none;
    cursor: pointer;
  }

  /*custom checkbox */
  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid $color-grey-6;
    border-radius: 4px;
    transform: translateY(-50%);
  }

  .form-check-label:hover input ~ .checkmark {
    border: 1px solid $color-blue-logo;
  }

  .form-check-label input:checked ~ .checkmark {
    background-color: $color-blue-logo;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .form-check-label input:checked ~ .checkmark:after {
    display: block;
  }

  .form-check-label .checkmark:after {
    left: 6px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid $color-white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  //disables custom rules
  &.disabled {
    .form-check-label,
    .form-check-input {
      cursor: not-allowed;
    }

    .checkmark {
      background-color: $color-grey-2-75;
      border: 1px solid $color-grey-4-75;
    }

    .form-check-label:hover input ~ .checkmark {
      border: 1px solid $color-grey-6;
    }
  }

  &.hover {
    .form-check-label input ~ .checkmark {
      border: 1px solid $color-blue-logo;
    }
  }
  //indeterminate custom rules
  &.indeterminate {
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .form-check-label .checkmark:after {
      left: 5px;
      top: 4px;
      height: 6px;
      width: 8px;
      border: solid $color-white;
      border-width: 0 0 2px 0;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
}
</style>
