<template>
  <wa-cta
    class="ContactFormBackCmp inverted-link text-color-blue-logo"
    data-type="router-link"
    :data-to="dataTo"
    data-icon-id="icon-back"
  >
    {{ common.back }}
  </wa-cta>
</template>

<script>
import { mapState } from 'vuex';
const debug = false;

export default {
  name: 'ContactFormBackCmp',
  computed: {
    ...mapState('contactForm2024', ['routes', 'common', 'queryString']),
    dataTo() {
      debug && console.log(`CALCOLO INDIETRO`);

      const qs = { ...this.queryString };
      const getDataTo = (qs) => {
        const pageOrderArray = qs.page_order.split(',');
        const lastPage = pageOrderArray[pageOrderArray.length - 1];
        debug && console.log(`lastPage -> ${lastPage}`);
        qs.page_order = qs.page_order
          .replace(lastPage, '')
          .replace(/,\s*$/, '');
        if (qs.page_order === '') {
          delete qs.page_order;
        }

        const [key, value] = Object.entries(this.routes).find(
          ([key, value]) => value.name === lastPage
        );
        delete qs[key];

        if (key === 'ticketing_third_level' && qs.hasSkippedStep3) {
          debug && console.log(`RICALCOLO INTERNO`);
          delete qs.hasSkippedStep3;
          return getDataTo(qs);
        } else {
          debug && console.log(`INDIETRO CALCOLATO A ${lastPage}`);
          return {
            name: lastPage,
            query: qs,
          };
        }
      };

      return getDataTo(qs);
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormBackCmp {
}
</style>
