<template>
  <div class="WaPm9LeisureAndFunForm">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WaPm9LeisureAndFunForm',
  props: {
    dataPromoCeForm: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    contentData() {
      return this.$_cmsSupport_castPropToType(this.dataPromoCeForm, 'object');
    },
  },
  created() {
    this.setPromoCeContentData(this.contentData);
  },
  methods: {
    ...mapActions('pm9LeisureAndFun', ['setPromoCeContentData']),
  },
};
</script>

<style scoped></style>
