var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.active)?_c('div',{staticClass:"WaGigyaScreen"},[_c('div',[(_vm.isUpdateProfileScreenTheStartScreen)?_c('div',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{class:[
                'tabs-btns',
                {
                  'd-flex': _vm.$viewportWidth >= 768,
                  'justify-content-center': _vm.$viewportWidth >= 768,
                } ]},[_c('div',[(_vm.isUpdateProfileScreen)?[_c('wa-cta',{staticClass:"primary",attrs:{"data-href":"#","data-rel":"nofollow"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.dataProfileCtaLabel)+" ")])]:[_c('wa-cta',{staticClass:"secondary",attrs:{"data-href":"#","data-rel":"nofollow"},on:{"click":function($event){$event.preventDefault();return _vm.switchScreen(_vm.screensDestinationsNames.updateProfile)}}},[_vm._v(" "+_vm._s(_vm.dataProfileCtaLabel)+" ")])]],2),_c('div',{class:[{ 'padding-top-r': _vm.$viewportWidth < 768 }]},[(_vm.isUnsubScreen)?[_c('wa-cta',{staticClass:"primary",attrs:{"data-href":"#","data-rel":"nofollow"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.dataPrivacyCtaLabel)+" ")])]:[_c('wa-cta',{staticClass:"secondary",attrs:{"data-href":"#","data-rel":"nofollow"},on:{"click":function($event){$event.preventDefault();return _vm.switchScreen(_vm.screensDestinationsNames.unsubscription)}}},[_vm._v(" "+_vm._s(_vm.dataPrivacyCtaLabel)+" ")])]],2)])])])])]):_vm._e(),_c('div',{class:[
        'container-fluid',
        {
          'padding-top-l': _vm.isUpdateProfileScreenTheStartScreen,
        } ],attrs:{"id":_vm.relatedId}})])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }