<template>
  <div :class="['WaRadio form-group__wrapper', { disabled: dataDisabled }]">
    <div
      :class="[
        'form-group',
        { 'form-group--error': dataErrorOccurred, 'disabled': dataDisabled },
      ]"
    >
      <label
        v-if="dataShowLabel"
        :id="`form__label-${_uid}`"
        class="form__label"
        :for="`form-check__wrapper-${_uid}`"
      >
        {{ dataLabel }}
      </label>
      <div
        :id="`form-check__wrapper-${_uid}`"
        class="form-check__wrapper"
        :role="isCustom ? 'radiogroup' : null"
        :aria-label="isCustom ? (!showLabel ? dataLabel : null) : null"
        :aria-labelledby="
          isCustom ? (showLabel ? `form__label-${_uid}` : null) : null
        "
      >
        <div
          v-for="(radio, i) in dataRadioList"
          :key="`radio-${i}-${_uid}`"
          :class="['form-check', { disabled: radio.dataDisabled }]"
        >
          <label
            v-if="radio.label"
            :class="['form-check-label', { disabled: dataDisabled }]"
            :role="isCustom ? 'radio' : null"
            :aria-checked="isCustom ? '' + (radio.value === value) : null"
            :tabindex="isCustom ? 0 : null"
            :for="`input-radio-${i}-${_uid}`"
            @keyup.space="isCustom ? $event.target.click() : null"
          >
            {{ radio.label }}

            <input
              :id="`input-radio-${i}-${_uid}`"
              type="radio"
              :name="dataName"
              :value="radio.value"
              :checked="radio.value === value"
              :disabled="dataDisabled || radio.dataDisabled"
              @change="onUpdate"
            />
            <span :class="['checkmark', { disabled: dataDisabled }]" />
          </label>
        </div>
      </div>
    </div>
    <span
      v-for="(error, i) in hasErrors"
      :key="`error-${i}-${_uid}`"
      class="error typo-a-10"
      v-html="error.message"
    />
  </div>
</template>

<script>
export default {
  name: 'WaRadio',
  props: {
    dataLabel: {
      type: String,
      default: null,
    },
    dataShowLabel: {
      type: Boolean,
      default: true,
    },
    dataName: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    dataRadioList: {
      type: Array,
      required: true,
    },
    dataDisabled: {
      type: Boolean,
      default: null,
    },
    dataErrorOccurred: {
      type: Boolean,
      default: null,
    },
    dataErrors: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isCustom: null,
    };
  },
  computed: {
    hasErrors() {
      //console.log('this.dataErrorOccurred',this.dataErrorOccurred)
      if (this.dataErrorOccurred) {
        return this.dataErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.isCustom =
      this.$el.classList.contains('custom') ||
      this.$el.classList.contains('switch');
  },
  methods: {
    onUpdate(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.form-group__wrapper {
  display: inline-block;

  .error {
    color: $color-informative-red;
    font: $font-primary, $font-system;
    font-weight: $fh-medium;
    display: inline-block;
    padding-top: 8px;
  }

  .form-check__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 -20px;
  }

  .form__label {
    font: $font-primary, $font-system;
    font-size: 16px;
  }

  .form-check {
    margin: 0 20px;
  }

  .form-check-label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    position: relative;
    padding-left: 34px;
    margin-bottom: 0;
    cursor: pointer;
    font: $font-primary, $font-system;
    font-size: 16px;
    font-weight: $fh-light;
    user-select: none;
  }

  .form-check-label input {
    position: absolute;

    display: none;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $color-white;
    border: 1px solid $color-grey-6;
    border-radius: 50%;
  }

  .form-check-label:hover input ~ .checkmark {
    border: 1px solid $color-blue-logo;
  }

  //when radio button is checked
  .form-check-label input:checked ~ .checkmark {
    background-color: $color-blue-logo;
  }

  //Create the indicator (the dot/circle - hidden when not checked)
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .form-check-label:hover input ~ .checkmark &:not(.disabled) {
    border: 1px solid $color-blue-logo;
  }

  /* Show the indicator (dot/circle) when checked */
  .form-check-label input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .form-check-label .checkmark:after {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $color-white;
  }

  //disabled rules
  &.disabled {
    .form-check-label,
    .form-check-input {
      cursor: not-allowed;
    }

    .checkmark {
      background-color: $color-grey-2-75;
      border: 1px solid $color-grey-6;
    }

    .form-check-label:hover input ~ .checkmark {
      border: 1px solid $color-grey-6;
    }
  }

  &.hover {
    //hover
    .form-check-label input ~ .checkmark {
      border: 1px solid $color-blue-logo;
    }
  }
}
</style>
