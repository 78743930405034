<template>
  <div class="WaPm9LeisureAndFunMap">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-7">
          <div
            v-if="$store.state.config.language && conf.google_map_api_key"
            class="googleMap"
          >
            <div ref="$gmap" class="gmap" />
            <js-loader
              data-async="true"
              data-defer="true"
              :data-id="conf.dataKey"
              :data-src="`https://maps.googleapis.com/maps/api/js?key=${conf.google_map_api_key}&language=${$store.state.config.language}`"
            ></js-loader>
          </div>
        </div>
        <div class="col-12 col-sm-5 offset-md-1 col-md-4">
          <h3
            :class="[
              'typo-a-5 typo-heavy margin-bottom-0 padding-bottom-r formTitle text-color-sea-salt-blue',
            ]"
          >
            {{ conf.map_title }}
          </h3>
          <form class="WaPm9LeisureAndFunMap__filters" @submit.prevent>
            <wa-input
              v-model.trim="address"
              :data-id="conf.address_label"
              :data-label="conf.address_label"
              :data-placeholder="conf.address_label"
              :data-disabled="false"
              :data-name="conf.address_label"
            />
            <div class="selectWrapper">
              <wa-dropdown
                v-model.trim="radius"
                :data-name="conf.distance_label"
                :data-id="conf.distance_label"
                :data-disabled="false"
                :data-label="conf.distance_label"
                :data-placeholder="conf.distance_label"
                :data-option-list="optionsRadius"
              />
              <wa-dropdown
                v-model.trim="land"
                :data-name="conf.land_label"
                :data-id="conf.land_label"
                :data-disabled="false"
                :data-label="conf.land_label"
                :data-placeholder="conf.land_label"
                :data-option-list="optionsLands"
              />
              <wa-dropdown
                v-model.trim="category"
                :data-name="conf.category_label"
                :data-id="conf.category_label"
                :data-disabled="false"
                :data-label="conf.category_label"
                :data-placeholder="conf.category_label"
                :data-option-list="optionsCategories"
              />
            </div>
            <div v-if="optionsType.length" class="checkboxWrapper">
              <wa-checkbox
                v-model.trim="is_online"
                data-name="online"
                :data-checkbox-list="[
                  {
                    label: optionsType.find((o) => o.key === '1').dataValue,
                  },
                ]"
              />
              <wa-checkbox
                v-model.trim="is_offline"
                data-name="offline"
                class="offline-checkbox"
                :data-checkbox-list="[
                  {
                    label: optionsType.find((o) => o.key === '2').dataValue,
                  },
                ]"
              />
            </div>
            <wa-cta
              class="primary"
              data-type="submit"
              :data-icon-id="spinner ? 'icon-ellipsis-loader' : ''"
              @click.prevent="searchPartners"
            >
              {{ conf.search_label }}
            </wa-cta>
          </form>
        </div>
      </div>
    </div>
    <div class="nonFieldErrorsWrapper">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="nonFieldErrors text-color-secondary-red">
              {{ mapsErrors }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="filteredPartnersToShowOnStart.length"
      class="partnerList bg-color-grey-2-50 padding-bottom-xxl"
    >
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-12">
            <wa-accordion class="padding-top-l padding-bottom-l">
              <wa-accordion-item
                v-for="partner in filteredPartnersToShowOnStart"
                :key="partner.id"
                :ref="`$accordionItem-${partner.id}`"
                data-variant="with-logo"
                :class="[{ clicked: isClicked }]"
                :data-anchor-id="currentClickedPartnerdId"
              >
                <template slot="image">
                  <img :src="partner.logo" />
                </template>
                <template slot="title">
                  <h4 class="typo-a typo-a-6 typo-medium margin-bottom-0">
                    {{ partner.partner_name }}
                  </h4>
                </template>
                <template slot="content">
                  <div class="accordionItemContent padding-top-r">
                    <h4
                      v-if="partner.title_upper"
                      class="title_upper typo-a-6 typo-medium"
                    >
                      {{ partner.title_upper }}
                    </h4>
                    <wa-free-text v-if="partner.description_upper">
                      <template slot="content_wysiwyg">
                        <div v-html="partner.description_upper"></div>
                      </template>
                    </wa-free-text>
                    <h4
                      v-if="partner.title_middle"
                      class="title_middle typo-a-6 typo-medium"
                    >
                      {{ partner.title_middle }}
                    </h4>
                    <wa-free-text v-if="partner.description_middle">
                      <template slot="content_wysiwyg">
                        <div v-html="partner.description_middle"></div>
                      </template>
                    </wa-free-text>
                    <h4
                      v-if="partner.title_lower"
                      class="title_lower typo-a-6 typo-medium"
                    >
                      {{ partner.title_lower }}
                    </h4>
                    <wa-free-text v-if="partner.description_lower">
                      <template slot="content_wysiwyg">
                        <div v-html="partner.description_lower"></div>
                      </template>
                    </wa-free-text>
                  </div>
                  <div class="accordionLinks">
                    <wa-cta
                      v-if="conf.route_label"
                      class="margin-top-r margin-right-cta"
                      :data-href="partner.maps_directions_url"
                      data-target="_blank"
                      data-rel="nofollow"
                      data-type=""
                      data-icon-id="icon-location"
                    >
                      {{ conf.route_label }}
                    </wa-cta>
                    <wa-cta
                      v-if="conf.website_label"
                      class="margin-top-r margin-right-cta"
                      :data-href="partner.partner_website_url"
                      data-target="_blank"
                      data-rel="nofollow"
                      data-type=""
                      data-icon-id="icon-arrow-up-right"
                    >
                      {{ conf.website_label }}
                    </wa-cta>
                    <wa-pm9-leisure-and-fun-cta v-if="partner.is_online">
                      <wa-cta
                        v-if="isPublished"
                        class="margin-top-r margin-right-cta link"
                        :data-href="joinUrl + `?partnerId=${partner.id}`"
                        data-target="_self"
                        data-rel="nofollow"
                        data-icon-id="icon-arrow-angle-right"
                        data-contest-name="pm9-leisure-and-fun"
                        data-is-context="true"
                        data-force-click="true"
                      >
                        {{ conf.join_promo_label }}
                      </wa-cta>
                      <div
                        v-if="!isPublished"
                        class="typo-a-8 typo-heavy text-color-blue-logo margin-top-r margin-right-cta"
                      >
                        {{ conf.not_active_promo_label }}
                      </div>
                    </wa-pm9-leisure-and-fun-cta>
                  </div>
                </template>
              </wa-accordion-item>
            </wa-accordion>
          </div>
          <wa-cta
            v-if="
              filteredPartnersFirstSelected.length >
              numberOfPartnersToShowOnStart
            "
            class="primary"
            data-type="submit"
            @click.prevent="loadAllItems"
          >
            {{ conf.load_more_label }}
          </wa-cta>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MapWidgetPopupMixin from '@/mixins/map_widget_popup_mixin';
import { debug } from '@helpers/utils';
import axios from 'axios';

export default {
  name: 'WaPm9LeisureAndFunMap',
  mixins: [MapWidgetPopupMixin],
  props: {
    dataJson: {
      type: [String, Object],
      default: null,
    },
    dataIsPublished: {
      type: [String, Boolean],
      required: true,
    },
  },
  data() {
    return {
      map: null,
      zoom: 5,
      markerIconUrl: require('@images/pin-icon@2x.png'),
      address: null,
      radius: null,
      land: null,
      optionsLands: [],
      optionsCategories: [],
      optionsRadius: [],
      optionsType: [],
      //filters: [],
      category: null,
      is_online: false,
      is_offline: false,
      proxy_is_online: false,
      proxy_is_offline: false,
      type: null,
      partnersList: [],
      isClicked: false,
      currentClickedPartnerdId: null,
      markersInstances: [],
      numberOfPartnersToShowOnStart: 9,
      initial: true,
      spinner: false,
      nonFieldErrors: [],
    };
  },
  computed: {
    ...mapState(['storeSetupCompleted']),
    ...mapState('pm9LeisureAndFun', ['map-filters-api', 'partners-api']),
    mapsErrors() {
      if (Array.isArray(this.nonFieldErrors)) {
        return this.nonFieldErrors.join(', ');
      } else {
        return this.nonFieldErrors;
      }
    },
    conf() {
      return this.dataJson
        ? this.$_cmsSupport_castPropToType(this.dataJson, 'object')
        : null;
    },
    isPublished() {
      return this.dataIsPublished
        ? this.$_cmsSupport_castPropToType(this.dataIsPublished, 'boolean')
        : null;
    },
    mapCenter() {
      // 52.5067614,13.2846508,11
      const [lat, lng] = [52.5067614, 13.2846508];
      return { lat: +lat, lng: +lng };
    },
    markerPosition() {
      const [lat, lng] = this.dataMarkerPosition.split(',');
      return { lat: +lat, lng: +lng };
    },
    filteredPartnersToShowOnStart() {
      return this.filteredPartnersFirstSelected
        ? this.filteredPartnersFirstSelected.slice(
            0,
            this.numberOfPartnersToShowOnStart
          )
        : [];
    },
    filteredPartnersFirstSelected() {
      return this.filteredPartners
        ? [...this.filteredPartners].sort((x) =>
            x.id === this.currentClickedPartnerdId ? -1 : 1
          )
        : [];
    },
    filteredPartners() {
      return (
        this.partnersList &&
        [...this.partnersList]
          .sort((a, b) => b.is_featured - a.is_featured)
          .filter((p) => {
            if (
              (this.proxy_is_offline === true &&
                this.proxy_is_online === true) ||
              (this.proxy_is_online === false &&
                this.proxy_is_offline === false)
            ) {
              return true;
            } else if (this.proxy_is_online) {
              return p.is_online;
            } else if (this.proxy_is_offline === true) {
              return !p.is_online;
            }
          })
      );
    },
    joinUrl() {
      return this.$store.state.pm9LeisureAndFun['form-page-url'];
    },
  },
  watch: {
    storeSetupCompleted: {
      handler(val) {
        debug && console.log('WATCH storeSetupCompleted', val);
        if (val) {
          this.initMap();
          this.getMapFiltersData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    getPartnersData() {
      // const randomEndpoint = this.getRandomEndpoint();
      let params = {
        address: this.address ? this.address : null,
        land: this.land ? this.land : null,
        category: this.category ? this.category : null,
        radius: this.radius ? this.radius : null,
      };
      if (this.initial) {
        params = { ...params, initial: true };
      }

      this.nonFieldErrors = '';
      return axios.get(this['partners-api'], { params }).then((response) => {
        // qua copiamo i valori in maniera da aggiornare i filtri della lista
        this.proxy_is_online = this.is_online;
        this.proxy_is_offline = this.is_offline;
        this.partnersList = response.data.data;
        if (response.data.errors) {
          this.nonFieldErrors = response.data.errors.non_field_error;
        }
        if (this.initial) {
          this.initial = false;
        }
      });
    },
    async getMapFiltersData() {
      try {
        const result = await axios.get(this['map-filters-api']);

        if (result.data) {
          this.optionsLands = result.data.data.lands.map((el) => {
            return {
              key: el.id,
              dataValue: el.label,
            };
          });
          this.optionsCategories = result.data.data.categories.map((el) => {
            return {
              key: el.id,
              dataValue: el.label,
            };
          });
          this.optionsRadius = result.data.data.radius.map((el) => {
            return {
              key: el.id,
              dataValue: el.label,
            };
          });
          this.optionsType = result.data.data.type.map((el) => {
            return {
              key: el.id,
              dataValue: el.label,
            };
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    getRandomEndpoint() {
      let endpoints = [
        'https://run.mocky.io/v3/0a53743e-1680-4763-9bc9-bc41b98e5c54',
        'https://run.mocky.io/v3/0a53743e-1680-4763-9bc9-bc41b98e5c54',
      ];
      let randomEnpoint =
        endpoints[Math.floor(Math.random() * endpoints.length)];

      return randomEnpoint;
    },
    markerClicked(marker, partnerId, partnerName, partnerAddress) {
      this.refreshPopup(marker.getPosition(), {
        title: partnerName,
        content: partnerAddress,
        href_url: partnerId,
        href_label: this.conf.detail_label,
        type: 'leisureAndFun',
      });
      this.isClicked = true;
      this.currentClickedPartnerdId = partnerId;

      this.$nextTick(() => {
        this.$refs[`$accordionItem-${partnerId}`][0].toggle();
      });
    },
    initMap() {
      (() => {
        let doCheck = async () => {
          if (window.google) {
            this.map = new window.google.maps.Map(this.$refs.$gmap, {
              center: this.mapCenter,
              zoom: +this.zoom,
              /*, mapTypeId: this.dataMaptype */
            });
            this.getPartnersData()
              .then(() => {
                this.createMarkers();
                this.createPopupClass();
              })
              .catch((e) => {
                console.error(e);
              });
          } else {
            setTimeout(doCheck, 50);
          }
        };
        doCheck();
      })();
    },
    createMarkers() {
      if (this.markersInstances.length) {
        this.markersInstances.forEach((el) => el.setMap(null));
        this.closePopup();
      }
      this.filteredPartnersFirstSelected.forEach((m, idx) => {
        const marker = new window.google.maps.Marker({
          position: { lat: +m.latitude, lng: +m.longitude },
          map: this.map,
          //optimized: true,
          icon: {
            url: this.markerIconUrl,
            //size: new window.google.maps.Size(32, 44), //it seems not working properly please investigate
            scaledSize: new window.google.maps.Size(32, 44),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new window.google.maps.Point(16, 44),
          },
        });
        debug &&
          console.log(
            `BINDO [${m.is_online ? 'ONLINE' : 'OFFLINE'}] ${m.id} ${
              m.partner_name
            }  ${m.latitude} ${m.longitude}`
          );

        marker.addListener('click', () => {
          this.markerClicked(marker, m.id, m.partner_name, m.address);
        });

        this.markersInstances = [...this.markersInstances, marker];
        // setTimeout(() => {
        //   this.markerClicked(marker, m.id);
        // }, 500);
      });
    },
    searchPartners() {
      this.spinner = true;
      this.numberOfPartnersToShowOnStart = 9;
      this.getPartnersData()
        .then(() => {
          this.createMarkers();
          this.createPopupClass();
          this.spinner = false;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    loadAllItems() {
      this.numberOfPartnersToShowOnStart =
        this.filteredPartnersFirstSelected.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaPm9LeisureAndFunMap {
  .nonFieldErrors {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    min-height: 3.2rem;
    @include media-breakpoint-up(sm) {
      min-height: 4rem;
    }
    @include media-breakpoint-up(sm) {
      min-height: 6rem;
    }
  }
  .formTitle {
    padding-top: 24px;

    @include media-breakpoint-up(sm) {
      padding-top: 0;
    }
  }

  .title_middle,
  .title_lower {
    padding-top: 16px;

    @include media-breakpoint-up(md) {
      padding-top: 24px;
    }
  }

  ::v-deep {
    .margin-right-cta {
      margin-right: 40px;
    }
  }

  .selectWrapper {
    padding-bottom: 32px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 40px;
    }

    .WaDropdown {
      padding-top: 42px;

      @include media-breakpoint-up(sm) {
        padding-top: 49px;
      }
    }
  }

  .checkboxWrapper {
    padding-top: 10px;
    padding-bottom: 32px;

    @include media-breakpoint-up(lg) {
      padding-top: 2px;
      padding-bottom: 40px;
    }
  }

  .googleMap {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 75%;
    overflow: hidden;

    > .gmap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .offline-checkbox {
    padding-left: 40px;
  }

  ::v-deep .WaAccordionItem {
    &.open {
      border: 1px solid $color-secondary-cyan;
    }
  }

  .accordionLinks {
    display: flex;
    flex-direction: column;
  }
}
</style>
