<template>
  <div class="ContactFormTicketingGenericFormCmp">
    <form @submit.prevent>
      <div v-if="questionsList.length > 0" class="row">
        <div class="col-12 padding-bottom-m">
          <wa-select
            v-model.trim="$v.questions.$model"
            class="medium text-color-blue-logo"
            :data-label="forms.form_fields.questions.label"
            :data-show-label="false"
            data-name="questions"
            :data-placeholder="forms.form_fields.questions.label"
            :data-option-list="questionsList"
            :data-error-occurred="$v.questions.$error"
            :data-errors="dataErrors('questions')"
          >
            <template #icon>
              <svg width="24" height="24">
                <use href="#icon-arrow-angle-down" fill="currentColor" />
              </svg>
            </template>
          </wa-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6 padding-bottom-r">
          <wa-upload
            ref="$attachment_1"
            v-model.trim="$v.attachment_1.$model"
            name="attachment_1"
            :label="forms.form_fields.attachment_1.label"
            :label-smaller="forms.form_fields.attachment_1.label"
            :label-on-hover="forms.form_fields.attachment_1.label"
            :cta-text="forms.form_fields.attachment_1.ctaText"
            :helper-text="forms.form_fields.attachment_1.helperText"
            :helper-text-smaller="forms.form_fields.attachment_1.helperText"
            :data-error-occurred="$v.attachment_1.$error"
            :errors="dataErrors('attachment_1')"
            @change="(attachment_1 = $event), $v.attachment_1.$touch()"
          />
        </div>
        <div class="col-12 col-lg-6 padding-bottom-r">
          <wa-upload
            ref="$attachment_2"
            v-model.trim="$v.attachment_2.$model"
            name="attachment_2"
            :label="forms.form_fields.attachment_2.label"
            :label-smaller="forms.form_fields.attachment_2.label"
            :label-on-hover="forms.form_fields.attachment_2.label"
            :cta-text="forms.form_fields.attachment_2.ctaText"
            :helper-text="forms.form_fields.attachment_2.helperText"
            :helper-text-smaller="forms.form_fields.attachment_2.helperText"
            :data-error-occurred="$v.attachment_2.$error"
            :errors="dataErrors('attachment_2')"
            @change="(attachment_2 = $event), $v.attachment_2.$touch()"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 padding-top-r padding-bottom-s">
          <h5 class="typo-a typo-a-8 typo-heavy margin-bottom-0">
            {{ forms.form_titles.issueTextarea }}
          </h5>
        </div>
        <div class="col-12">
          <wa-textarea
            v-model.trim="$v.description.$model"
            data-name="description"
            :data-label="forms.form_fields.description.label"
            :data-error-occurred="$v.description.$error"
            :data-errors="dataErrors('description')"
          />
        </div>
      </div>
      <div v-if="!userLoggedIn" class="row">
        <div class="col-12 padding-top-m padding-bottom-m">
          <h4
            class="typo-a typo-a-4 typo-heavy text-color-secondary-item text-center margin-bottom-0"
          >
            {{ forms.form_titles.personalDetail }}
          </h4>
        </div>
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-input
            v-model.trim="$v.first_name.$model"
            :data-label="forms.form_fields.first_name.label"
            data-name="first_name"
            :data-error-occurred="$v.first_name.$error"
            :data-errors="dataErrors('first_name')"
          />
        </div>
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-input
            v-model.trim="$v.last_name.$model"
            :data-label="forms.form_fields.last_name.label"
            data-name="last_name"
            :data-error-occurred="$v.last_name.$error"
            :data-errors="dataErrors('last_name')"
          />
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12 padding-top-m padding-bottom-m">
          <h4
            class="typo-a typo-a-4 typo-heavy text-color-secondary-item text-center margin-bottom-0"
          >
            {{ forms.form_titles.personalDetail }}
          </h4>
        </div>
        <div v-if="gigya_profile_page_url" class="col-12">
          <p>
            <wa-cta
              class="link print_cta w-100"
              data-variant-class="link"
              @click.prevent="navToProfilePage"
            >
              {{ common.gigyaPersonalAreaLink }}
            </wa-cta>
          </p>
        </div>
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-input
            v-model.trim="$v.first_name.$model"
            :data-label="forms.form_fields.first_name.label"
            data-name="first_name"
            :data-error-occurred="$v.first_name.$error"
            :data-errors="[]"
            :data-disabled="true"
          />
        </div>
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-input
            v-model.trim="$v.last_name.$model"
            :data-label="forms.form_fields.last_name.label"
            data-name="last_name"
            :data-error-occurred="$v.last_name.$error"
            :data-errors="[]"
            :data-disabled="true"
          />
        </div>
      </div>
      <div v-if="!userLoggedIn" class="row">
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-input
            v-model.trim="$v.email.$model"
            :data-label="forms.form_fields.email.label"
            data-name="email"
            :data-error-occurred="$v.email.$error"
            :data-errors="dataErrors('email')"
          />
        </div>
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-input
            v-model.trim="$v.confirm_email.$model"
            :data-label="forms.form_fields.confirm_email.label"
            data-name="confirm_email"
            :data-error-occurred="$v.confirm_email.$error"
            :data-errors="dataErrors('confirm_email')"
          />
        </div>
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-input
            v-model.trim="$v.phone.$model"
            :data-label="forms.form_fields.phone.label"
            data-name="phone"
            :data-error-occurred="$v.phone.$error"
            :data-errors="dataErrors('phone')"
          />
        </div>
      </div>

      <div v-else class="row">
        <div class="col-12 col-md-6">
          <div class="form-group-wrapper">
            <div
              :class="['form-group', { 'form-group--error': $v.email.$error }]"
            >
              <label class="form__label">
                {{ forms.form_fields.email.label }}
              </label>
              <wa-input
                v-model.trim="$v.email.$model"
                :data-label="forms.form_fields.email.label"
                data-name="email"
                :data-error-occurred="$v.email.$error"
                :data-errors="dataErrors('email')"
                :data-disabled="true"
              />
              <p v-if="userLoggedIn && userEmail" class="emailActionText">
                <span
                  v-if="userMailStatus === 'notverified'"
                  class="emailActionWarning"
                >
                  {{ common.attention }}
                </span>
                <wa-cta
                  id="changeUserMail"
                  class="link print_cta w-100"
                  data-variant-class="link"
                  @click.prevent="userMailAction"
                >
                  {{ emailActionText }}
                </wa-cta>
              </p>
              <p v-else-if="userLoggedIn && !userEmail">
                {{ common.insertMail }}
              </p>
            </div>
            <div v-if="$v.email.$error" class="error">
              <span
                v-for="(error, i) in dataErrors('email')"
                :key="`error-${i}`"
                v-html="error.message"
              ></span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group-wrapper">
            <div
              :class="[
                'form-group',
                {
                  'form-group--error':
                    $v.phone.$error || userMailStatus === 'notverified',
                },
              ]"
            >
              <label class="form__label">
                {{ forms.form_fields.phone.label }}
              </label>
              <wa-input
                v-model.trim="$v.phone.$model"
                :data-label="forms.form_fields.phone.label"
                data-name="phone"
                :data-error-occurred="$v.phone.$error"
                :data-errors="dataErrors('phone')"
                :data-disabled="true"
              />
              <p class="phoneActionText">
                <wa-cta
                  id="changeUserPhone"
                  class="link print_cta w-100"
                  data-variant-class="link"
                  @click.prevent="userPhoneAction"
                >
                  {{ phoneActionText }}
                </wa-cta>
              </p>
            </div>
            <div v-if="$v.phone.$error" class="error">
              <span
                v-for="(error, i) in dataErrors('phone')"
                :key="`error-${i}`"
                v-html="error.message"
              ></span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 padding-bottom-r">
          <h5 class="typo-a typo-a-8 typo-heavy margin-bottom-0">
            {{ forms.form_titles.addressInfo }}
          </h5>
        </div>
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-input
            v-model.trim="$v.address_street.$model"
            :data-label="forms.form_fields.address_street.label"
            data-name="address_street"
            :data-error-occurred="$v.address_street.$error"
            :data-errors="dataErrors('address_street')"
          />
        </div>
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-input
            v-model.trim="$v.address_house_number.$model"
            :data-label="forms.form_fields.address_house_number.label"
            data-name="address_house_number"
            :data-error-occurred="$v.address_house_number.$error"
            :data-errors="dataErrors('address_house_number')"
          />
        </div>
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-input
            v-model.trim="$v.city.$model"
            :data-label="forms.form_fields.city.label"
            data-name="city"
            :data-error-occurred="$v.city.$error"
            :data-errors="dataErrors('city')"
          />
        </div>
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-input
            v-model.trim="$v.state.$model"
            :data-label="forms.form_fields.state.label"
            data-name="state"
            :data-error-occurred="$v.state.$error"
            :data-errors="dataErrors('state')"
          />
        </div>
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-input
            v-model.trim="$v.address_street_postal_code.$model"
            :data-label="forms.form_fields.address_street_postal_code.label"
            data-name="address_street_postal_code"
            :data-error-occurred="$v.address_street_postal_code.$error"
            :data-errors="dataErrors('address_street_postal_code')"
          />
        </div>
        <div class="col-12 col-sm-6 padding-bottom-r">
          <wa-dropdown
            v-model.trim="$v.country.$model"
            data-name="country"
            :data-use-int="false"
            :data-label="forms.form_fields.country.label"
            :data-option-list="
              countries.items && countries.items.length
                ? [
                    { key: '', dataValue: '----------' },
                    ...countries.items.map((e) => {
                      if (e.value.includes('-')) {
                        return {
                          key: e.value.split('-')[1],
                          dataValue: e.label,
                        };
                      } else {
                        return {
                          key: e.value,
                          dataValue: e.label,
                        };
                      }
                    }),
                  ]
                : [{ key: '', dataValue: '----------' }]
            "
            :data-error-occurred="$v.country.$error"
            :data-errors="dataErrors('country')"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-center mt-3">
          <div class="turnstile-wrapper">
            <div class="form-group-wrapper">
              <div
                :class="[
                  'form-group',
                  {
                    'form-group--error':
                      $v.recaptcha &&
                      $v.recaptcha.$dirty &&
                      $v.recaptcha.$error,
                  },
                ]"
              >
                <turnstile-cmp
                  :data-key="apiKeys.turnstile"
                  @setFormData="recaptcha = $event"
                ></turnstile-cmp>
              </div>
              <div
                v-if="
                  $v.recaptcha && $v.recaptcha.$dirty && $v.recaptcha.$error
                "
                class="error"
              >
                <span
                  v-for="(error, i) in dataErrors('recaptcha')"
                  :key="`error-${i}`"
                  v-html="error.message"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col text-center padding-top-r">
          <wa-cta
            class="primary"
            data-type="submit"
            :data-disabled="disableSubmit || submitted"
            :data-icon-id="loading ? 'icon-ellipsis-loader' : ''"
            @click.prevent="onSubmitForm"
          >
            {{ routes.ticketing_product_form.controls.submitCta.label }}
          </wa-cta>
          <div
            v-if="!!globalErrors.length || !!Object.keys(serverErrors).length"
            class="form__error-global text-center"
          >
            <span
              v-for="(error, i) in globalErrors
                .map((e) => {
                  if (Array.isArray(e.message)) {
                    e.message = e.message.join('<br>');
                  }
                  return e;
                })
                .filter((e) => e.condition)"
              :key="`global-error-${i}-${_uid}`"
              class="form__error--text"
              v-html="error.message"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
  helpers,
} from 'vuelidate/lib/validators';

import FormElementsSupport from '../../mixins/form-elements-support';
import ContactForm2024Mixin from '../../mixins/contact_form_2024_mixin';
import TurnstileCmp from '@components/contact-form-2024/turnstile-cmp';

import { mapActions, mapState } from 'vuex';
import axios from 'axios';

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'ContactFormTicketingGenericFormCmp',
  components: {
    TurnstileCmp,
  },
  mixins: [FormElementsSupport, ContactForm2024Mixin],
  props: {
    dataSubmitEnabledOnMounted: {
      type: Boolean,
      default: true,
    },
    dataHandledStatusCodeErrors: {
      type: Array,
      default: () => [400, 401, 403],
    },
  },
  data() {
    return {
      questions: '',
      attachment_1: null,
      attachment_2: null,
      description: '',
      first_name: '',
      last_name: '',
      email: '',
      confirm_email: '',
      phone: '',
      address_street: '',
      address_house_number: '',
      city: '',
      state: '',
      address_street_postal_code: '',
      country: '',
      recaptcha: '',
      userMailStatus: '',
      userPhoneStatus: '',
    };
  },
  computed: {
    ...mapState('contactForm2024', [
      'common',
      'api',
      'routes',
      'promotions',
      'forms',
      'queryString',
      'countries',
      'apiKeys',
    ]),
    ...mapState('contactForm2024', { storeGenericQuestions: 'questions' }),
    ...mapState('gigya', [
      'uid',
      'user',
      'phoneNumber',
      'gigya_profile_page_url',
    ]),
    questionsList() {
      return this.queryString.triage_topic === 'PROMOTIONS'
        ? this.promotionsQuestionsList
        : this.genericQuestionsList;
    },
    genericQuestionsList() {
      return this.getQuestionsList(this.storeGenericQuestions, this.queryString)
        ? this.getQuestionsList(
            this.storeGenericQuestions,
            this.queryString
          ).map((e) => {
            return {
              key: e.value,
              dataValue: e.label,
            };
          })
        : null;
    },
    promotionsQuestionsList() {
      const promotion = this.promotions.find(
        (e) => e.id === this.queryString.triage_promotions
      );

      return promotion?.questions.length
        ? promotion.questions.map((e) => {
            return {
              key: e.value,
              dataValue: e.label,
              description: e.description,
            };
          })
        : null;
    },
    isInvalid() {
      return this.$v && (this.$v.$anyError || this.$v.$invalid);
    },
    disableSubmit() {
      if (this.dataSubmitEnabledOnMounted) {
        return false;
      } else {
        if (this.isInvalid) {
          return true;
        }

        return false;
      }
    },
    userPhone() {
      if (this.user && this.response) {
        if (this.user.phoneNumber) {
          return this.user.phoneNumber;
        }
      }
      return '';
    },
    userEmail() {
      return this.user ? this.user.email : '';
    },
    emailActionText() {
      if (this.userEmail) {
        return this.userMailStatus === 'notverified'
          ? this.common.verifyMail
          : this.common.changeMail;
      }
      return '';
    },
    phoneActionText() {
      return this.userPhoneStatus === 'present'
        ? this.common.changePhone
        : this.common.insertPhone;
    },
    userLoggedIn() {
      return this.user ?? false;
    },
  },
  watch: {
    user: {
      handler: function (newUser, oldUser) {
        if (newUser?.firstName) {
          this.first_name = newUser.firstName;
          this.$v.first_name.$dirty;
        }
        if (newUser?.lastName) {
          this.last_name = newUser.lastName;
          this.$v.last_name.$dirty;
        }
        if (newUser?.email) {
          this.email = newUser.email;
          this.confirm_email = newUser.email;
          this.$v.email.$dirty;
        }
        if (newUser?.phoneNumber) {
          this.phone = newUser.phoneNumber;
          this.$v.phone.$dirty;
        }
        this.checkUserMailAndPhoneStatus();
      },
      immediate: true,
    },
    phoneNumber: {
      handler: function (newPhoneNumber, oldUser) {
        if (newPhoneNumber) {
          this.phone = newPhoneNumber;
          this.$v.phone.$dirty;
        }
      },
      immediate: true,
    },
  },
  created() {
    if (
      !(
        this.queryString.triage_topic === 'PROMOTIONS' &&
        this.queryString.triage_promotions
      ) &&
      this.queryString.triage_topic !== 'WEBSITE'
    ) {
      this.questions = null;
    }
  },
  mounted() {
    this.country = this.countries.items.filter(
      (item) => item.value === this.countries.currentCountry
    )[0].value;
    document.addEventListener('screensetGigyaReady', () => {
      this.checkUserMailAndPhoneStatus();
    });
  },
  validations() {
    let validations = {};

    Object.entries(this.forms.form_fields).forEach(([field, value]) => {
      if (this[field] !== undefined) {
        validations[field] = {
          serverError: checkServerErrors(field),
        };

        Object.entries(value.validations).forEach(([condition, value]) => {
          if (condition === 'required') {
            if (
              field !== 'questions' ||
              (field === 'questions' && this.questionsList.length)
            ) {
              validations[field] = {
                ...validations[field],
                required,
              };
            }
          }

          if (condition === 'email') {
            validations[field] = {
              ...validations[field],
              email,
            };
          }

          if (condition === 'sameAs' && value.field) {
            validations[field] = {
              ...validations[field],
              sameAs: sameAs(value.field),
            };
          }

          if (condition === 'minLength' && value.length >= 0) {
            validations[field] = {
              ...validations[field],
              minLength: minLength(value.length),
            };
          }

          if (condition === 'maxLength' && value.length > 0) {
            validations[field] = {
              ...validations[field],
              maxLength: maxLength(value.length),
            };
          }

          if (condition === 'regex' && value.expression) {
            validations[field] = {
              ...validations[field],
              regex: (val) =>
                !helpers.req(val) || new RegExp(value.expression).test(val),
            };
          }

          if (condition === 'validSize' && value.size > 0) {
            validations[field] = {
              ...validations[field],
              validSize: (val) => {
                const mb = value.size * 1024 * 1024;
                return !helpers.req(val) || val.size <= mb;
              },
            };
          }

          if (condition === 'validExtension' && value.extensions) {
            validations[field] = {
              ...validations[field],
              validExtension: (val) => {
                return !helpers.req(val) || value.extensions.includes(val.type);
              },
            };
          }
        });
      }
    });

    return validations;
  },
  methods: {
    ...mapActions('contactForm2024', ['setUnlockedThankYou', 'setLoading']),
    dataErrors(field) {
      const errors = [
        {
          condition: !this.$v[field].serverError,
          message: this.serverErrors[field],
        },
      ];

      Object.entries(this.forms.form_fields[field].validations).forEach(
        ([condition, value]) => {
          let valid = false;
          let message = value.message;

          if (
            condition === 'required' ||
            condition === 'email' ||
            (condition === 'sameAs' && value.field) ||
            (condition === 'regex' && value.expression)
          ) {
            valid = true;
          } else if (condition === 'minLength' && value.length >= 0) {
            valid = true;
            message = message.replace('$length$', value.length);
          } else if (condition === 'maxLength' && value.length > 0) {
            valid = true;
            message = message.replace('$length$', value.length);
          } else if (condition === 'validSize' && value.size > 0) {
            valid = true;
            message = message.replace('$size$', `${value.size}MB`);
          } else if (condition === 'validExtension' && value.extensions) {
            valid = true;
            const extensions = value.extensions;
            message = message.replace(
              '$extensions$',
              `(${extensions
                .replaceAll('image/', '')
                .replaceAll('application/', '')
                .replaceAll(',', ', ')})`
            );
          }

          if (valid) {
            errors.push({
              condition: !this.$v[field][condition],
              message: message,
            });
          }
        }
      );

      return errors;
    },
    async onSubmitForm() {
      //console.log('this.$v', this.$v);
      if (this.dataSubmitEnabledOnMounted) {
        this.$v.$touch();
      }
      if (!this.submitted && !this.disableSubmit && !this.isInvalid) {
        this.submitted = true;
        this.loading = true;
        this.globalErrors = [];
        this.toastErrors = [];

        try {
          const formData = {
            request_type_code: this.queryString.triage_type,
            request_topic_code: this.queryString.triage_topic,
            market_country_id: this.countryForApex('ticketing_country'),
            portal_country_id: this.countryForApex('initial_country')
              ? this.countryForApex('initial_country')
              : this.countryForApex('ticketing_country'),
            product: this.queryString.ticketing_third_level ?? null,
            attachment_1: this.attachment_1,
            attachment_2: this.attachment_2,
            description: this.description,
            adv_title: '',
            vendor_name: '',
            gigya_id: this.userLoggedIn ? this.user?.UID : '',
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            phone: this.phone,
            address_street: this.address_street,
            address_house_number: this.address_house_number,
            city: this.city,
            state: this.state,
            address_street_postal_code: this.address_street_postal_code,
            country: this.country,
            question_classification_code: this.questions ? this.questions : '',
            recaptcha: this.recaptcha,
          };
          if (this.queryString.triage_promotions) {
            formData.promo_event = this.queryString.triage_promotions;
          }

          const payload = new FormData();

          for (let k in formData) {
            if (formData[k]) {
              payload.set(k, formData[k]);
            }
          }
          this.setLoading(true);
          const response = await axios.post(this.api.submit, payload);

          // Implement 200
          // Effettuare  nell'if tutti i controlli del caso per verificare che il payload di risposta
          // ricevuto sia conforme a quello che ci aspettiamo
          if (response.data.success) {
            await this.setUnlockedThankYou({
              submission_number: response.data.c4_ticket_id
                ? response.data.c4_ticket_id
                : '',
            });
            await this.$router.replace({
              name: this.routes.ticketing_product_form.controls.submitCta
                .goToName,
            });
          } else {
            this.$_formElementsSupport_handleApiError(response);
          }
        } catch (e) {
          // Controlliamo se siamo entrati nella catch per una risposta del server non di successo
          // se response non è incluso o non abbiamo lo status code aspettato allora si è rotto
          // qualcosa lato codice nostro lo gestiamo subito
          const hasResponse =
            Object.getOwnPropertyNames(e).includes('response');
          if (
            !hasResponse ||
            (hasResponse &&
              !this.dataHandledStatusCodeErrors.includes(e.response.status))
          ) {
            // 500 or unknown status code
            this.$_formElementsSupport_handleUnknownError(e);
          } else {
            this.$_formElementsSupport_handleErrors(e);
          }
        } finally {
          this.setLoading(false);
          this.loading = false;
        }
      } else {
        if (this.isInvalid && this.scrollToError) {
          this.$nextTick(() => {
            this.$scrollTo(
              document.querySelectorAll('.form-group--error')[0],
              500,
              {
                cancelable: false,
                easing: 'ease',
                offset: -30,
              }
            );
          });
        }
      }
    },
    countryForApex(field) {
      return this.queryString[field].includes('-')
        ? this.queryString[field].split('-')[1]
        : this.queryString[field];
    },
    navToProfilePage() {
      if (this.gigya_profile_page_url) {
        location.href = this.gigya_profile_page_url;
      }
    },
    userPhoneAction() {
      let phone = this.userPhone ?? '';
      window.gigya.accounts.showScreenSet({
        screenSet: 'Barilla-ProfileUpdate',
        startScreen: 'gigya-mobile-confirm-screen',
        context: {
          mobileToConfirm: phone,
        },
      });
      document.addEventListener('screensetPhoneVerified', (event) => {
        let phoneNumber = event.detail.phoneNumber;
        this.$store.dispatch('gigya/updateProfile', {
          type: 'PHONE',
          value: phoneNumber,
        });
        this.checkUserMailAndPhoneStatus();
        this.$forceUpdate();
      });
    },
    checkUserMailAndPhoneStatus() {
      if (this.userLoggedIn) {
        window.gigya.accounts.getAccountInfo({
          callback: (data) => {
            if (data.errorCode === 0) {
              if (data.emails.verified.includes(data.profile.email)) {
                this.$nextTick(() => {
                  this.userMailStatus = 'verified';
                });
              } else {
                this.$nextTick(() => {
                  this.userMailStatus = 'notverified';
                });
              }
              if (data.phoneNumber) {
                this.$nextTick(() => {
                  this.userPhoneStatus = 'present';
                });
              } else {
                this.$nextTick(() => {
                  this.userPhoneStatus = 'notpresent';
                });
              }
            } else {
              if (data.errorCode === 403005) {
                // console.log("user not logged in");
              } else {
                // console.log("api call error");
              }
            }
            this.response = data;
          },
          include: 'emails, profile, data, preferences',
        });
      }
    },
    userMailAction() {
      if (this.userEmail) {
        window.gigya.accounts.showScreenSet({
          screenSet: 'Barilla-ProfileUpdate',
          startScreen: 'gigya-confirm-email-screen',
          context: {
            emailToVerify: this.userEmail,
          },
        });
      } else {
        //add mail logic
        // provato a cercare di fare registrazione nuovo utente senza inserire la mail ma impossibile da portare a termine, quindi la mail dovrebbe esserci per forza
      }
      document.addEventListener('screensetEmailVerified', (event) => {
        let email = event.detail.email;
        this.$store.dispatch('gigya/updateProfile', {
          type: 'EMAIL',
          value: email,
        });
        this.checkUserMailAndPhoneStatus();
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.ContactFormTicketingGenericFormCmp {
  .form__error-global {
    color: $color-informative-red;

    .form__error--text {
      display: block;
      font-size: 13px;
      font-weight: $fh-medium;
      margin-top: 8px;

      &:last-child {
        padding-bottom: 7px;
      }
    }
  }
  .error > span {
    display: block;
    font-size: 13px;
    font-weight: 500;
    margin-top: 8px;
    color: $color-informative-red;
  }
}
</style>
