import moment from 'moment';

export default {
  created() {
    moment.locale(this.currentLang.toLowerCase());
  },
  computed: {
    langs() {
      return this.$store.state.config.languages;
    },
    currentCountry() {
      const country = this.langs.length
        ? this.langs.find((l) => l.site_code === l.language_code)
        : null;

      if (country && 'country_label' in country) {
        return country.country_label;
      } else {
        return '';
      }
    },
    currentSiteCode() {
      const country = this.langs.length
        ? this.langs.find((l) => l.site_code === l.language_code)
        : null;

      if (country && 'country_label' in country) {
        return country.language_code;
      } else {
        return '';
      }
    },
    currentLang() {
      if (this.langs.length) {
        const country = this.langs.length
          ? this.langs.find((l) => l.site_code === l.language_code)
          : null;

        if (country && 'language_code' in country) {
          const l = country.language_code;
          if (l.indexOf('-') !== -1) {
            return l.split('-')[0].toUpperCase();
          } else if (l.toLowerCase() === 'global') {
            return 'EN';
          } else {
            return l;
          }
        } else {
          return 'EN';
        }
      } else {
        return 'EN';
      }
    },
    siteCodeForHeader() {
      const codesToShowFully = ['fr-ch', 'de-ch', 'fr-be', 'nl-be', 'global'];
      if (this.currentSiteCode) {
        if (codesToShowFully.includes(this.currentSiteCode)) {
          return this.currentSiteCode.toUpperCase();
        } else {
          return this.currentSiteCode.split('-')[1].toUpperCase();
        }
      } else {
        return '';
      }
    },
  },
};
