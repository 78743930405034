import Vue from 'vue';
import { mapState } from 'vuex';
import axios from 'axios';
import { deviceType, osName } from 'mobile-device-detect';

const SmcSupport = Vue.extend({
  data() {
    return {
      smcReady: false,
    };
  },
  computed: {
    ...mapState(['storeSetupCompleted']),
    ...mapState('gigya', ['loaded', 'user']),
    ...mapState('smc', ['active', 'send-event-data-api']),
    smcInit() {
      return this.storeSetupCompleted && this.loaded && this.active;
    },
  },
  created() {
    const unwatch = this.$watch(
      () => this.smcInit,
      (val) => {
        if (val) {
          if (this.user) {
            this.smcReady = true;
            if (unwatch) {
              unwatch();
            }
          }
        }
      },
      { immediate: true }
    );
  },
  methods: {
    $_smcSupport_sendEvent(eventName, payload) {
      return new Promise((resolve, reject) => {
        if (this.smcReady) {
          window.gigya.accounts.getAccountInfo({
            include: 'preferences',
            callback: (data) => {
              const json = {
                Message: {
                  Interactions: [
                    {
                      ContactIdOrigin: 'GIGYA_ID',
                      ContactId: data.UID,
                      InitiativeId: '',
                      InteractionType: eventName,
                      CommunicationMedium: 'WEB',
                      Brands: ['WASA'],
                      SourceURL: window.location.href,
                      DeviceType: deviceType,
                      DeviceOS: osName,
                      PrivacyProfiling:
                        data.preferences.privacy_profiling.isConsentGranted,
                    },
                  ],
                },
              };

              switch (eventName) {
                case 'PROD_SHARED':
                case 'ZPRODUCT_VISIT':
                  //console.log('ZPRODUCT_VISIT',payload)
                  json.Message.Interactions[0] = {
                    ...json.Message.Interactions[0],
                    InteractionOutcome: '', // campo libero, mettere l'argomento di interesse relativo al prodotto
                    Products: [
                      {
                        ProductCode: payload.productCode, // non necessario che ci inviino la category, c'è già a sistema
                        Quantity: 1,
                        HighlightedContent: payload.highlightedContent,
                      },
                    ],
                  };
                  break;
                case 'WEBSITE_VIDEO':
                  json.Message.Interactions[0] = {
                    ...json.Message.Interactions[0],
                    InteractionDetail: payload.title,
                  };
                  break;
                case 'ZCONTACT_FORM_REQ':
                  json.Message.Interactions[0] = {
                    ...json.Message.Interactions[0],
                    InitiativeId: payload.initiativeId, // mettere ID della promozione se e solo se la tipologia scelta è PROMOZIONE, altrimenti lasciare vuoto
                    InteractionDetail: payload.interactionDetail, // aggiungere INTERACTION DETAIL PER TIPOLOGIA GENERALE (prodotto, altro, promozione, eccetera)
                    InteractionOutcome: payload.interactionOutcome, // aggiungere INTERACTION OUTCOME se e solo se la tipologia è PROMOZIONE, mettere il problema specifico (c'è un set di valori per ogni tipologia di promo), altrimenti lasciare vuoto
                  };
                  if (payload.products && payload.products.length > 0) {
                    json.Message.Interactions[0].Products = payload.products;
                  }
                  break;
                case 'ZRECIPE_SHARED':
                case 'ZRECIPE_VISIT':
                  //console.log('ZRECIPE_VISIT',payload)
                  json.Message.Interactions[0] = {
                    ...json.Message.Interactions[0],
                    InteractionDetail: payload.interactionDetail,
                    InteractionOutcome: '', // campo libero, mettere l'argomento di interesse relativo al prodotto
                    Products: [
                      {
                        ProductCode: payload.productCode, // non necessario che ci inviino la category, c'è già a sistema
                        Quantity: 1,
                        HighlightedContent: payload.highlightedContent,
                      },
                    ],
                  };
                  break;
              }
              if (
                eventName === 'PROD_SHARED' ||
                eventName === 'ZRECIPE_SHARED'
              ) {
                json.Message.Interactions[0] = {
                  ...json.Message.Interactions[0],
                  SharedPlatform: payload.sharedPlatform,
                };
              }

              axios
                .post(this['send-event-data-api'], json)
                .then(() => {
                  resolve();
                })
                .catch((e) => {
                  console.log(e);
                  reject(e);
                });
            },
          });
        } else {
          resolve();
        }
      });
    },
  },
});

export default SmcSupport;
