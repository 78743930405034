<template>
  <div class="WaAllRecipes">
    <div class="container-fluid">
      <!-- Meals & Moods Select -->
      <div
        v-if="dataVariantClass === 'all_recipes'"
        class="row filters padding-bottom-l"
      >
        <div class="col-12 col-md-6 d-flex justify-content-center">
          <div class="moodSelect">
            <wa-select
              v-model="mood"
              class="big"
              :data-show-label="false"
              data-label=""
              data-name="moodSelect"
              :data-option-list="moods"
              :data-placeholder="dataSelectMoodPlaceholder"
              @change="onMoodChange"
            >
              <template slot="icon">
                <svg width="24" height="24">
                  <use href="#icon-arrow-angle-down" fill="currentColor" />
                </svg>
              </template>
            </wa-select>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center">
          <div class="mealSelect">
            <wa-select
              v-model="meal"
              class="big"
              :data-show-label="false"
              data-label=""
              :data-disabled="false"
              data-name="mealSelect"
              :data-placeholder="dataSelectMealPlaceholder"
              :data-option-list="meals"
              @change="onMealChange"
            >
              <template slot="icon">
                <svg width="24" height="24">
                  <use href="#icon-arrow-angle-down" fill="currentColor" />
                </svg>
              </template>
            </wa-select>
          </div>
        </div>
      </div>
      <!-- All Recipes -->
      <div>
        <transition-group
          class="recipesTransition row"
          name="slide"
          mode="out-in"
          :duration="{ enter: 500, leave: 0 }"
        >
          <div
            v-for="(recipe, i) in recipes"
            :key="`recipe_card_${i}`"
            :class="[
              {
                'col-12 col-sm-6 col-md-4 padding-bottom-l':
                  dataVariantClass === 'all_recipes',
                'col-12 col-sm-6 padding-top-l':
                  dataVariantClass === 'chefs_recipes',
                'col-md-6': dataVariantClass === 'chefs_recipes' && i % 5 < 2,
                'col-md-4': dataVariantClass === 'chefs_recipes' && i % 5 >= 2,
              },
            ]"
          >
            <template v-if="$root.isPowerReviewActive">
              <pr-category-review-wrapper ref="prCRWs" :data-item="recipe">
                <wa-recipe-card
                  :data-conf="recipe"
                  :data-alternative-ratio="
                    dataVariantClass === 'chefs_recipes' && i % 5 < 2
                      ? true
                      : false
                  "
                />
              </pr-category-review-wrapper>
            </template>
            <template v-else>
              <wa-recipe-card
                :data-conf="recipe"
                :data-alternative-ratio="
                  dataVariantClass === 'chefs_recipes' && i % 5 < 2
                    ? true
                    : false
                "
              />
            </template>
          </div>
        </transition-group>
      </div>
      <div v-if="dataVariantClass === 'all_recipes'" class="row loadmore">
        <!-- Load more button -->
        <div class="col-12 text-center">
          <wa-cta
            v-if="showLoadMore"
            class="primary loadMoreBtn"
            @click.prevent="loadMoreAndRenderReviewsWidgets"
          >
            {{ $store.state.config.load_more_label }}
          </wa-cta>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'WaAllRecipes',
  props: {
    dataSelectMoodPlaceholder: {
      type: String,
      default: '',
    },
    dataSelectMealPlaceholder: {
      type: String,
      default: '',
    },
    dataMealConf: {
      type: [Array, String],
      required: false,
      default: '',
    },
    dataMoodConf: {
      type: [Array, String],
      required: false,
      default: '',
    },
    dataPaginatedApi: {
      type: String,
      default: '',
    },
    dataVariantClass: {
      type: String,
      default: 'all_recipes',
    },
  },
  data() {
    return {
      //renderCounter: 0,
      meal: '',
      mood: '',
      numberOfRecipes: 10,
      collectedIds: [],
    };
  },
  computed: {
    ...mapState('recipes', ['pagination']),
    ...mapGetters('recipes', ['flatData']),
    recipes() {
      const recipes = this.flatData.length
        ? this.flatData.map((el) => {
            return {
              ...el,
              meal_types: this.meals.filter(
                (m) => el?.meals_slugs.indexOf(m.key) !== -1
              ),
            };
          })
        : [];

      if (this.dataVariantClass === 'chefs_recipes') {
        return recipes.slice(0, this.numberOfRecipes);
      } else {
        return recipes;
      }
    },
    meals() {
      return this.$_cmsSupport_castPropToType(this.dataMealConf, 'array');
    },
    moods() {
      return this.$_cmsSupport_castPropToType(this.dataMoodConf, 'array');
    },
    showLoadMore() {
      return this.pagination.current_page !== this.pagination.final_page;
    },
  },
  created() {
    this.$store.dispatch(`recipes/setApiUrl`, this.dataPaginatedApi);
    if (this.dataVariantClass === 'chefs_recipes') {
      this.$store.dispatch(`recipes/initPagination`, {
        page_size: this.numberOfRecipes,
      });
    } else {
      this.$store.dispatch(`recipes/initPagination`, { page_size: 9 });
    }
  },
  methods: {
    ...mapActions('recipes', ['loadMore']),
    getMountedCategorySnippetIds() {
      if (this.$root.isPowerReviewActive) {
        return this.$refs.prCRWs.map(
          (component) => `category-snippet-${component._uid}`
        );
      } else {
        return [];
      }
    },
    collectId(id) {
      if (!this.collectedIds.includes(id)) {
        this.collectedIds = [...this.collectedIds, id];
      }
    },
    readdWidgets() {
      if (this.$root.isPowerReviewActive) {
        this.$refs.prCRWs.forEach((element) => {
          element && element.$el && element.addPrWidget();
        });
      }
    },
    loadMoreAndRenderReviewsWidgets() {
      if (this.$root.isPowerReviewActive) {
        const ids = this.getMountedCategorySnippetIds();
        this.$root.setWidgetsToUnmount(ids);
        this.readdWidgets();
      }
      this.loadMore().then(() => {
        if (this.$root.isPowerReviewActive) {
          setTimeout(this.$root.unmountWidgets, 0);
          setTimeout(this.$root.mountWidgets, 0);
        }
      });
    },
    onMealChange(value) {
      //this.renderCounter++;
      if (this.$root.isPowerReviewActive) {
        const ids = this.getMountedCategorySnippetIds();
        this.$root.setWidgetsToUnmount(ids);
        setTimeout(this.$root.unmountWidgets, 0);
      }
      setTimeout(() => {
        this.$store.dispatch(`recipes/setSelectedMeal`, value);
        this.$store
          .dispatch(`recipes/initPagination`, { page_size: 9 })
          .then(() => {
            //this.renderCounter++;
            if (this.$root.isPowerReviewActive) {
              this.readdWidgets();
              setTimeout(this.$root.mountWidgets, 0);
            }
          });
      }, 0);
    },
    onMoodChange(value) {
      //this.renderCounter++;
      if (this.$root.isPowerReviewActive) {
        const ids = this.getMountedCategorySnippetIds();
        this.$root.setWidgetsToUnmount(ids);
        setTimeout(this.$root.unmountWidgets, 0);
      }
      setTimeout(() => {
        this.$store.dispatch(`recipes/setSelectedMood`, value);
        this.$store
          .dispatch(`recipes/initPagination`, { page_size: 9 })
          .then(() => {
            //this.renderCounter++;
            if (this.$root.isPowerReviewActive) {
              this.readdWidgets();
              setTimeout(this.$root.mountWidgets, 0);
            }
          });
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaAllRecipes {
  .filters {
    color: $color-blue-logo;

    /deep/ .selectbox {
      font-weight: $fh-extra-bold;
    }

    .mealSelect {
      margin-top: 24px;
      @include media-breakpoint-up(sm) {
        margin-top: 37px;
      }
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  .loadmore {
    .slide-enter-active {
      -moz-transition-duration: 0.3s;
      -webkit-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -moz-transition-timing-function: ease-in;
      -webkit-transition-timing-function: ease-in;
      -o-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
    }

    .slide-leave-active {
      -moz-transition-duration: 0.3s;
      -webkit-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    .slide-enter-to,
    .slide-leave {
      max-height: 100px;
      overflow: hidden;
    }

    .slide-enter,
    .slide-leave-to {
      overflow: hidden;
      max-height: 0;
    }

    /* needed for directive */
    .expand {
      overflow: hidden;
      transition: height 0.3s linear;

      &[aria-expanded='false'] {
        height: 0 !important;
      }
    }

    /* needed for directive */
    .u-no-transition {
      transition: none !important;
    }
  }
}
</style>
