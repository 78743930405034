<template>
  <div class="PrQuestionOrReview">
    <div v-if="!ops" class="pr-form-wrapper">
      <div :id="`pr-write-${_uid}`"></div>
    </div>
    <div v-else>
      <wa-grid-container
        class="no-container bg-color-secondary-item-10 padding-top-xl padding-bottom-xl"
      >
        <template slot="wa-grid-row">
          <wa-grid-container class="container padding-top-0 padding-bottom-0">
            <template slot="wa-grid-row">
              <wa-grid-row class="row justify-content-center">
                <template slot="wa-grid-column">
                  <wa-grid-column
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  >
                    <template slot="wa-grid-column-childs">
                      <wa-media-image
                        class="padding-top-0 padding-bottom-0"
                        data-variant="full"
                      >
                        <img :src="require('@images/404.png')" alt="404" />
                      </wa-media-image>
                    </template>
                  </wa-grid-column>
                  <wa-grid-column
                    class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-12"
                  >
                    <template slot="wa-grid-column-childs">
                      <wa-free-text class="padding-top-0 padding-bottom-0">
                        <template slot="content_wysiwyg">
                          <p style="text-align: center">
                            <span
                              class="text-color-secondary-item typo-w-3 typo-semi-bold"
                            >
                              oOPS!
                            </span>
                          </p>
                        </template>
                      </wa-free-text>
                    </template>
                  </wa-grid-column>
                </template>
              </wa-grid-row>
            </template>
          </wa-grid-container>
        </template>
      </wa-grid-container>
    </div>
  </div>
</template>

<script>
import { debug, qsObj } from '@helpers/utils';
export default {
  name: 'PrQuestionOrReview',
  data() {
    return {
      ops: false,
    };
  },
  mounted() {
    if (
      !qsObj.pr_page_id &&
      !qsObj.pr_merchant_group_id &&
      !qsObj.pr_merchant_id
    ) {
      this.ops = true;
    }
    //page_id, merchant_group_id, merchant_id;
    if (!this.ops) {
      this.$watch(
        () => this.$root.arePowerReviewConfsReady,
        (confsAreReady) => {
          if (confsAreReady) {
            this.addPrWidget();
          }
        },
        { immediate: true }
      );
    }
  },
  methods: {
    addPrWidget() {
      //272079137513752 	Sesam & Vollkorn
      this.$root.setWidgetsToMount([
        ...this.$root.getWidgetsToMount(),
        {
          ...this.$root.getBaseConfParams(),
          on_submit: function (/*config, data*/) {
            window.scrollTo(0, 0);
          },
          components: {
            Write: `pr-write-${this._uid}`,
          },
        },
      ]);
    },
  },
};
</script>
