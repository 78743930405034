<template>
  <wa-banner
    class="ContactFormBannerCmp contact-form-banner"
    data-variant-class="center"
    data-background-color="bg-color-crayola-yellow"
  >
    <template #title>
      <span class="typo-a typo-a-4 typo-heavy text-color-blue-logo">
        {{ currentBanner.title }}
      </span>
    </template>
    <template #wysiwyg>
      <span class="typo-a typo-a-9 typo-light">
        {{ currentBanner.content }}
      </span>
    </template>
    <template #cta>
      <router-link
        :to="{
          name: currentBanner.goToName.find(
            (gotoObj) => gotoObj.triage_type === queryString.triage_type
          ).name,
          query: {
            ...queryString,
            page_order: `${queryString.page_order},${routes.triage_faq.name}`,
          },
        }"
      >
        <wa-cta class="primary" data-type="button">
          {{ currentBanner.ctaLabel }}
        </wa-cta>
      </router-link>
    </template>
  </wa-banner>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactFormBannerCmp',
  computed: {
    ...mapState('contactForm2024', ['routes', 'banner', 'queryString']),
    currentBanner() {
      return this.queryString?.triage_topic
        ? this.banner[`${this.queryString.triage_topic}`]
        : this.banner.PRODUCTS;
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormBannerCmp {
}
</style>
