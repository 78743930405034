import { render, staticRenderFns } from "./wa-pm5-6-brunch-in-nature-participate.vue?vue&type=template&id=0829c754&scoped=true&"
import script from "./wa-pm5-6-brunch-in-nature-participate.vue?vue&type=script&lang=js&"
export * from "./wa-pm5-6-brunch-in-nature-participate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0829c754",
  null
  
)

export default component.exports