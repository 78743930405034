<template functional>
  <div
    :class="[
      'WaFooterCol',
      `${
        $options.getBoolean(props.dataCleanMarginClasses)
          ? ''
          : 'margin-top-col'
      }`,
      `${$options.getBoolean(props.dataCleanBaseClasses) ? '' : 'col-sm-4'}`,
      `${$options.getBoolean(props.dataIsFullSmartphone) ? 'col-12' : 'col-6'}`,
      `${$options.getBoolean(props.dataIsSmallText) ? 'typo-a-9' : 'typo-a-6'}`,
      data.staticClass,
      data.class,
    ]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <ul>
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'WaFooterCol',
  functional: true,
  props: {
    dataIsFullSmartphone: {
      type: [Boolean, String],
      default: false,
    },
    dataIsSmallText: {
      type: [Boolean, String],
      default: false,
    },
    dataCleanBaseClasses: {
      type: [Boolean, String],
      default: false,
    },
    dataCleanMarginClasses: {
      type: [Boolean, String],
      default: true,
    },
  },
  getBoolean(value) {
    return typeof value === 'boolean' ? value : value === 'true' ? true : false;
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
</style>
