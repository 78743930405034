<template>
  <div class="WaConfigPersonalArea d-none" aria-hidden="true" />
</template>

<script>
export default {
  name: 'WaConfigPersonalArea',
  props: {
    dataPersonalAreaCamper: {
      type: [Object, String],
      required: true,
    },
    dataPersonalAreaContestCtaLabel: {
      type: String,
      required: true,
    },
    dataPersonalAreaContestSectionLabel: {
      type: String,
      required: true,
    },
    dataPersonalAreaChanceSectionLabel: {
      type: String,
      required: true,
    },
    dataPersonalAreaDetailSectionLabel: {
      type: String,
      required: true,
    },
    dataPersonalAreaContestHeading: {
      type: String,
      required: true,
    },
    dataPersonalAreaDetailButtonText: {
      type: String,
      required: true,
    },
  },
  computed: {
    personalAreaCamper() {
      return this.dataPersonalAreaCamper
        ? this.$_cmsSupport_castPropToType(
            this.dataPersonalAreaCamper,
            'object'
          )
        : null;
    },
  },
  mounted() {
    const promises = [];
    if (this.personalAreaCamper) {
      promises.push(
        this.$store.dispatch('config/setConfig', {
          personal_area_camper: {
            ...this.personalAreaCamper,
            cta_label: this.dataPersonalAreaContestCtaLabel,
            contest_title_1: this.dataPersonalAreaContestSectionLabel,
            contest_title_2: this.dataPersonalAreaChanceSectionLabel,
            contest_title_3: this.dataPersonalAreaDetailSectionLabel,
            contest_heading: this.dataPersonalAreaContestHeading,
            detail_button: this.dataPersonalAreaDetailButtonText,
          },
        })
      );
    }
    Promise.all(promises)
      .then(() => {
        this.$store.dispatch('setStoreSetupCompleted', true);
      })
      .catch((e) => {
        console.error(e);
      });
  },
};
</script>

<style scoped></style>
