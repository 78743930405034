import { render, staticRenderFns } from "./wa-product-card.vue?vue&type=template&id=388844e8&scoped=true&"
import script from "./wa-product-card.vue?vue&type=script&lang=js&"
export * from "./wa-product-card.vue?vue&type=script&lang=js&"
import style0 from "./wa-product-card.vue?vue&type=style&index=0&id=388844e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388844e8",
  null
  
)

export default component.exports