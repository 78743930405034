<template functional>
  <!-- Variant classes
  - big -> Show the text in the component
  - small -> hide the text in the component
  -->
  <div
    :ref="data.ref"
    :class="['WaCtaBox', data.staticClass, data.class]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <div v-if="props.dataIdIcon" class="firstIcon">
      <svg width="48" height="48">
        <use :href="`#${props.dataIdIcon}`" fill="currentColor" />
      </svg>
    </div>
    <div class="title">
      <slot name="title" />
    </div>
    <div class="text">
      <slot name="text" />
    </div>
    <div class="lastIcon">
      <svg width="24" height="24">
        <use href="#icon-arrow-angle-right" fill="currentColor" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaCtaBox',
  functional: true,
  props: {
    dataIdIcon: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaCtaBox {
  position: relative;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 20px 16px;
  transition: all 0.1s ease-out;

  @media (min-width: $bootstrap-md) {
    padding: 24px;
  }

  & ~ .WaCtaBox {
    margin-top: 16px;

    @media (min-width: $bootstrap-md) {
      margin-top: 24px;
    }
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    border: 2px solid $color-grey-4;

    @include media-breakpoint-up(md) {
      text-decoration: none;
    }
  }

  .firstIcon {
    padding-right: 8px;
    float: left;
    opacity: 0.5;

    @include media-breakpoint-up(sm) {
      padding-right: 12px;
    }

    @include media-breakpoint-up(md) {
      padding-right: 16px;
    }
  }

  .title {
    margin-top: 5px;
  }

  .text {
    padding-top: 16px;
    clear: both;

    @include media-breakpoint-up(sm) {
      clear: none;
      padding-top: 8px;
      padding-left: 45px;
    }

    @include media-breakpoint-up(md) {
      padding-left: 64px;
    }
  }

  .lastIcon {
    display: none;
  }

  &.big {
    .lastIcon {
      @include media-breakpoint-up(md) {
        display: block;
        position: absolute;
        right: 24px;
        top: 40px;
      }
    }
  }

  &.small {
    .title {
      padding: 5px;
    }

    .text {
      display: none;
    }

    .lastIcon {
      @include media-breakpoint-up(md) {
        display: block;
        position: absolute;
        right: 24px;
        top: 30px;
      }
    }
  }

  &.contact-form-cta-box:not(.with-text) {
    display: flex;
    column-gap: 16px;
    align-items: center;
    padding: 32px 16px;

    @include media-breakpoint-up(md) {
      column-gap: 24px;
      padding: 32px 24px;
    }

    .firstIcon {
      display: flex;
      flex: 0 0 auto;
      float: none;
      padding: 0;

      svg {
        width: 40px;
        height: 40px;
      }
    }

    .title {
      flex-grow: 1;
      margin: 0;
      padding: 0;
    }

    .text {
      display: none;
    }

    .lastIcon {
      display: flex;
      flex: 0 0 auto;
    }
  }

  &.with-text {
    display: grid;
    column-gap: 16px;
    row-gap: 10px;
    align-items: center;
    padding: 32px 16px;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: 1fr 1fr;

    @include media-breakpoint-up(md) {
      column-gap: 24px;
      padding: 32px 24px;
    }

    .firstIcon {
      display: flex;
      padding: 0;
      grid-row-start: 1;
      grid-row-end: -1;

      svg {
        width: 40px;
        height: 40px;
      }
    }

    .title {
      margin: 0;
      padding: 0;
      grid-row-start: 1;
      grid-column-start: 2;
    }

    .lastIcon {
      display: flex;
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 3;
    }

    .text {
      grid-row-start: 2;
      grid-column-start: 2;
      padding: 0;
      font-weight: 400;
    }
  }
}
</style>
