<template>
  <div class="WaPm56BiscuitsFailedCheckWinner">
    <wa-side-backgrounds
      class="padding-top-xxl padding-bottom-xxl"
      :data-background-right="
        require('@images/biscuits/thank-you-bg-right.png')
      "
      :data-background-left="require('@images/biscuits/thank-you-bg-left.png')"
      data-width-right="305"
      data-height-right="417"
      data-width-left="302"
      data-height-left="425"
    >
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-8 col-md-8 d-flex flex-column align-items-center justify-content-center text-color-sport-blue padding-top-l padding-bottom-l"
          >
            <svg class="icon margin-top-r margin-bottom-r">
              <use href="#icon-triangle-error" fill="currentColor" />
            </svg>
            <div
              class="title typo-w-4 typo-bold text-center margin-bottom-r noMBLastChild"
              v-html="i18nParticipate.check_winner_error_title"
            />
            <div
              class="content typo-a-6 typo-light text-center text-color-black margin-bottom-m"
              v-html="i18nParticipate.check_winner_error_description"
            ></div>
            <wa-cta
              class="primary"
              :data-href="i18nParticipate.check_winner_error_link.link"
            >
              {{ i18nParticipate.check_winner_error_link.name }}
            </wa-cta>
          </div>
        </div>
      </div>
    </wa-side-backgrounds>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'WaPm56BiscuitsFailedCheckWinner',
  data() {
    return {
      showText: false,
    };
  },
  computed: {
    ...mapState('config', ['home_page_url']),
    ...mapState('pm56Biscuits', ['ch-key-pm5-6-biscuits']),
    ...mapGetters('pm56Biscuits', ['i18nParticipate']),
  },
  created() {
    this.setInvertedHeader(false);
  },
  mounted() {
    this.pushGenericCh(this['ch-key-pm5-6-biscuits']);
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    ...mapActions('gigya', ['pushGenericCh']),
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaPm56BiscuitsFailedCheckWinner {
  .icon {
    width: 48px;
    height: 48px;
  }
}
</style>
