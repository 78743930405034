<template>
  <div class="WaHighlightProductsCarousel">
    <div class="component-wrapper padding-top-m">
      <div v-if="!!($slots['text'] || [])[0]" class="text">
        <slot name="text" />
      </div>
      <div class="container-fluid">
        <div ref="$scroller" class="swiper-container">
          <button
            ref="$prev"
            type="button"
            class="swiper-button swiper-button-prev"
            :style="arrowsStyle"
          >
            <svg width="24" height="24">
              <use href="#icon-arrow-angle-right" fill="currentColor" />
            </svg>
          </button>
          <button
            ref="$next"
            type="button"
            class="swiper-button swiper-button-next"
            :style="arrowsStyle"
          >
            <svg width="24" height="24">
              <use href="#icon-arrow-angle-right" fill="currentColor" />
            </svg>
          </button>
          <div class="swiper-wrapper">
            <div
              v-for="(product, index) in products"
              :key="index"
              class="swiper-slide"
            >
              <a :href="product.href" class="product">
                <div ref="$image" class="image">
                  <img :src="product.image_packed" :alt="product.label" />
                </div>
                <div class="info">
                  <h4 class="title typo-a-6 typo-medium">
                    {{ product.label }}
                    <span
                      v-if="product.is_new"
                      :class="[
                        'tag',
                        'typo-heavy',
                        'typo-a-10',
                        'bg-color-secondary-red',
                        'text-color-white',
                      ]"
                    >
                      {{ $store.state.config.new_label }}
                    </span>
                  </h4>
                  <p
                    v-if="product.main_category_label"
                    class="category text-uppercase typo-medium typo-a-10"
                  >
                    {{ product.main_category_label }}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          v-if="!!($slots['cta'] || [])[0]"
          class="cta-wrapper padding-top-l"
        >
          <slot name="cta" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';

export default {
  name: 'WaHighlightProductsCarousel',
  props: {
    dataProductsJson: { type: [Array, String], required: true },
  },
  data() {
    return {
      swiper: null,
      imageHeight: 0,
    };
  },
  computed: {
    products() {
      return typeof this.dataProductsJson === 'string'
        ? JSON.parse(this.dataProductsJson)
        : this.dataProductsJson;
    },
    arrowsStyle() {
      return { top: `${this.imageHeight / 2}px` };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.swiper = new Swiper(this.$refs.$scroller, {
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        slideToClickedSlide: true,
        navigation: {
          prevEl: this.$refs.$prev,
          nextEl: this.$refs.$next,
        },
        breakpoints: {
          768: {
            slidesPerView: 2.05,
          },
          1024: {
            slidesPerView: 2.495,
          },
          1440: {
            slidesPerView: 2.465,
          },
        },
        on: {
          init: () => {
            this.updateImageHeight();
          },
          resize: () => {
            this.updateImageHeight();
          },
        },
      });
    });
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  },
  methods: {
    updateImageHeight() {
      this.imageHeight = this.$refs.$image[0].clientHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaHighlightProductsCarousel {
  overflow: hidden;
}

.component-wrapper {
  position: relative;

  > .container-fluid {
    position: relative;
    z-index: 2;
  }
}

.swiper-container {
  overflow: visible;

  > .swiper-wrapper {
    position: relative;
    z-index: 1;
  }
}

.text {
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;

  .container-fluid {
    * {
      margin: 0;
      padding: 0;
    }
  }
}

.swiper-slide {
  box-sizing: border-box;
}

.image {
  width: 100%;
  height: 0;
  padding-bottom: (3 * 100% / 4);
  position: relative;
  transition: transform 0.6s ease-in-out;

  img {
    display: block;
    width: 79%;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.6s ease, margin-left 0.3s ease;
    margin-left: 0;
    filter: drop-shadow(0 0 12px rgba($color-black, 0.15));

    @media (min-width: $bootstrap-sm) {
      filter: drop-shadow(0 0 16px rgba($color-black, 0.15));
    }

    @media (min-width: $bootstrap-md) {
      filter: drop-shadow(0 0 24px rgba($color-black, 0.15));
    }
  }
}

.product {
  display: block;
  pointer-events: none;
  text-decoration: none;
  text-align: center;

  .title {
    color: $color-black;
    margin: 0;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .tag {
    display: inline-block;
    padding: 3px 8px;
    color: $color-white;
    background-color: $color-secondary-red;
    border-radius: 12px;
    vertical-align: baseline;
    margin-left: 8px;
  }

  .category {
    color: $color-grey-8-75;
    margin: 8px 0 0;
  }

  .info {
    opacity: 0;
    transform: translate(0, 30px);
    transition: all 0.6s ease-in-out;
  }
}

.swiper-slide-duplicate-prev,
.swiper-slide-prev {
  .image {
    img {
      margin-left: -5%;
    }
  }
}

.swiper-slide-duplicate-next,
.swiper-slide-next {
  .image {
    img {
      margin-left: 5%;
    }
  }
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
  .image {
    img {
      width: 100%;
    }
  }

  .product {
    pointer-events: auto;

    &:hover {
      .image {
        transform: scale(1.05);
      }
    }

    .info {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}

.swiper-button {
  padding: 0;
  border: 0;
  background: rgba($color-black, 0.5);
  color: rgba($color-white, 0.75);
  font-size: 14px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  position: absolute;
  right: -30px;
  top: 50%;
  z-index: 2;
  margin-top: -20px;
  margin-right: 16px;

  @include media-breakpoint-up(sm) {
    right: -60px;
    margin-right: 24px;
  }

  @include media-breakpoint-up(md) {
    margin-right: 32px;
  }

  @include media-breakpoint-up(lg) {
    right: -80px;
  }

  &:hover {
    background-color: $color-black;
  }

  svg {
    display: block;
    margin-right: -1px;
    pointer-events: none;
  }

  &.swiper-button-prev {
    right: auto;
    left: -30px;
    margin-right: 0;
    margin-left: 16px;

    @include media-breakpoint-up(sm) {
      left: -60px;
      margin-left: 24px;
    }

    @include media-breakpoint-up(md) {
      margin-left: 32px;
    }

    @include media-breakpoint-up(lg) {
      left: -80px;
    }

    svg {
      transform: rotate(180deg);
      margin-right: 2px;
    }
  }
}

.cta-wrapper {
  text-align: center;

  @include media-breakpoint-up(sm) {
    text-align: right;
  }
}
</style>
