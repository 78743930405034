import * as types from '../mutation_types';

export default {
  [types.SET_CONTEST_PLANT_A_TREE_INIT_DATA](state, payload) {
    state[payload.key] = payload.value;
  },
  [types.SET_CONTEST_PLANT_A_TREE_CONTENT_DATA](state, payload) {
    Object.keys(payload).forEach((k) => {
      state.contentData[k] = payload[k];
    });
  },
};
