export default {
  data: [
    {
      name: 'San Marino',
      id: '111',
      province_id: '1',
    },
    {
      name: 'Agrigento',
      id: '1',
      province_id: '1',
    },
    {
      name: 'Alessandria',
      id: '2',
      province_id: '1',
    },
    {
      name: 'Ancona',
      id: '3',
      province_id: '1',
    },
    {
      name: 'Aosta',
      id: '4',
      province_id: '1',
    },
    {
      name: 'Arezzo',
      id: '5',
      province_id: '1',
    },
    {
      name: 'Ascoli Piceno',
      id: '6',
      province_id: '1',
    },
    {
      name: 'Asti',
      id: '7',
      province_id: '1',
    },
    {
      name: 'Avellino',
      id: '8',
      province_id: '1',
    },
    {
      name: 'Bari',
      id: '9',
      province_id: '1',
    },
    {
      name: 'Barletta-Andria-Trani',
      id: '10',
      province_id: '1',
    },
    {
      name: 'Belluno',
      id: '11',
      province_id: '1',
    },
    {
      name: 'Benevento',
      id: '12',
      province_id: '1',
    },
    {
      name: 'Bergamo',
      id: '13',
      province_id: '1',
    },
    {
      name: 'Biella',
      id: '14',
      province_id: '1',
    },
    {
      name: 'Bologna',
      id: '15',
      province_id: '1',
    },
    {
      name: 'Bolzano',
      id: '16',
      province_id: '1',
    },
    {
      name: 'Brescia',
      id: '17',
      province_id: '1',
    },
    {
      name: 'Brindisi',
      id: '18',
      province_id: '1',
    },
    {
      name: 'Cagliari',
      id: '19',
      province_id: '1',
    },
    {
      name: 'Caltanissetta',
      id: '20',
      province_id: '1',
    },
    {
      name: 'Campobasso',
      id: '21',
      province_id: '1',
    },
    {
      name: 'Carbonia-Iglesias',
      id: '22',
      province_id: '1',
    },
    {
      name: 'Caserta',
      id: '23',
      province_id: '1',
    },
    {
      name: 'Catania',
      id: '24',
      province_id: '1',
    },
    {
      name: 'Catanzaro',
      id: '25',
      province_id: '1',
    },
    {
      name: 'Chieti',
      id: '26',
      province_id: '1',
    },
    {
      name: 'Como',
      id: '27',
      province_id: '1',
    },
    {
      name: 'Cosenza',
      id: '28',
      province_id: '1',
    },
    {
      name: 'Cremona',
      id: '29',
      province_id: '1',
    },
    {
      name: 'Crotone',
      id: '30',
      province_id: '1',
    },
    {
      name: 'Cuneo',
      id: '31',
      province_id: '1',
    },
    {
      name: 'Enna',
      id: '32',
      province_id: '1',
    },
    {
      name: 'Fermo',
      id: '33',
      province_id: '1',
    },
    {
      name: 'Ferrara',
      id: '34',
      province_id: '1',
    },
    {
      name: 'Firenze',
      id: '35',
      province_id: '1',
    },
    {
      name: 'Foggia',
      id: '36',
      province_id: '1',
    },
    {
      name: 'Forl&igrave;-Cesena',
      id: '37',
      province_id: '1',
    },
    {
      name: 'Frosinone',
      id: '38',
      province_id: '1',
    },
    {
      name: 'Genova',
      id: '39',
      province_id: '1',
    },
    {
      name: 'Gorizia',
      id: '40',
      province_id: '1',
    },
    {
      name: 'Grosseto',
      id: '41',
      province_id: '1',
    },
    {
      name: 'Imperia',
      id: '42',
      province_id: '1',
    },
    {
      name: 'Isernia',
      id: '43',
      province_id: '1',
    },
    {
      name: "L'Aquila",
      id: '45',
      province_id: '1',
    },
    {
      name: 'La Spezia',
      id: '44',
      province_id: '1',
    },
    {
      name: 'Latina',
      id: '46',
      province_id: '1',
    },
    {
      name: 'Lecce',
      id: '47',
      province_id: '1',
    },
    {
      name: 'Lecco',
      id: '48',
      province_id: '2',
    },
    {
      name: 'Livorno',
      id: '49',
      province_id: '2',
    },
    {
      name: 'Lodi',
      id: '50',
      province_id: '2',
    },
    {
      name: 'Lucca',
      id: '51',
      province_id: '2',
    },
    {
      name: 'Macerata',
      id: '52',
      province_id: '2',
    },
    {
      name: 'Mantova',
      id: '53',
      province_id: '2',
    },
    {
      name: 'Massa-Carrara',
      id: '54',
      province_id: '2',
    },
    {
      name: 'Matera',
      id: '55',
      province_id: '2',
    },
    {
      name: 'Medio Campidano',
      id: '87',
      province_id: '2',
    },
    {
      name: 'Messina',
      id: '56',
      province_id: '2',
    },
    {
      name: 'Milano',
      id: '57',
      province_id: '2',
    },
    {
      name: 'Modena',
      id: '58',
      province_id: '2',
    },
    {
      name: 'Monza e della Brianza',
      id: '59',
      province_id: '2',
    },
    {
      name: 'Napoli',
      id: '60',
      province_id: '2',
    },
    {
      name: 'Novara',
      id: '61',
      province_id: '2',
    },
    {
      name: 'Nuoro',
      id: '62',
      province_id: '2',
    },
    {
      name: 'Ogliastra',
      id: '97',
      province_id: '2',
    },
    {
      name: 'Olbia-Tempio',
      id: '63',
      province_id: '2',
    },
    {
      name: 'Oristano',
      id: '64',
      province_id: '2',
    },
    {
      name: 'Padova',
      id: '65',
      province_id: '2',
    },
    {
      name: 'Palermo',
      id: '66',
      province_id: '2',
    },
    {
      name: 'Parma',
      id: '67',
      province_id: '2',
    },
    {
      name: 'Pavia',
      id: '68',
      province_id: '2',
    },
    {
      name: 'Perugia',
      id: '69',
      province_id: '3',
    },
    {
      name: 'Pesaro e Urbino',
      id: '70',
      province_id: '3',
    },
    {
      name: 'Pescara',
      id: '71',
      province_id: '3',
    },
    {
      name: 'Piacenza',
      id: '72',
      province_id: '3',
    },
    {
      name: 'Pisa',
      id: '73',
      province_id: '3',
    },
    {
      name: 'Pistoia',
      id: '74',
      province_id: '3',
    },
    {
      name: 'Pordenone',
      id: '75',
      province_id: '3',
    },
    {
      name: 'Potenza',
      id: '76',
      province_id: '3',
    },
    {
      name: 'Prato',
      id: '77',
      province_id: '3',
    },
    {
      name: 'Ragusa',
      id: '78',
      province_id: '3',
    },
    {
      name: 'Ravenna',
      id: '79',
      province_id: '3',
    },
    {
      name: 'Reggio Calabria',
      id: '80',
      province_id: '3',
    },
    {
      name: 'Reggio Emilia',
      id: '81',
      province_id: '3',
    },
    {
      name: 'Rieti',
      id: '82',
      province_id: '3',
    },
    {
      name: 'Rimini',
      id: '83',
      province_id: '3',
    },
    {
      name: 'Roma',
      id: '84',
      province_id: '3',
    },
    {
      name: 'Rovigo',
      id: '85',
      province_id: '3',
    },
    {
      name: 'Salerno',
      id: '86',
      province_id: '3',
    },
    {
      name: 'Sassari',
      id: '88',
      province_id: '3',
    },
    {
      name: 'Savona',
      id: '89',
      province_id: '3',
    },
    {
      name: 'Siena',
      id: '90',
      province_id: '3',
    },
    {
      name: 'Siracusa',
      id: '91',
      province_id: '3',
    },
    {
      name: 'Sondrio',
      id: '92',
      province_id: '3',
    },
    {
      name: 'Taranto',
      id: '93',
      province_id: '3',
    },
    {
      name: 'Teramo',
      id: '94',
      province_id: '3',
    },
    {
      name: 'Terni',
      id: '95',
      province_id: '3',
    },
    {
      name: 'Torino',
      id: '96',
      province_id: '3',
    },
    {
      name: 'Trapani',
      id: '98',
      province_id: '3',
    },
    {
      name: 'Trento',
      id: '99',
      province_id: '3',
    },
    {
      name: 'Treviso',
      id: '100',
      province_id: '3',
    },
    {
      name: 'Trieste',
      id: '101',
      province_id: '4',
    },
    {
      name: 'Udine',
      id: '102',
      province_id: '4',
    },
    {
      name: 'Varese',
      id: '103',
      province_id: '4',
    },
    {
      name: 'Venezia',
      id: '104',
      province_id: '4',
    },
    {
      name: 'Verbano-Cusio-Ossola',
      id: '105',
      province_id: '4',
    },
    {
      name: 'Vercelli',
      id: '106',
      province_id: '4',
    },
    {
      name: 'Verona',
      id: '107',
      province_id: '4',
    },
    {
      name: 'Vibo Valentia',
      id: '108',
      province_id: '4',
    },
    {
      name: 'Vicenza',
      id: '109',
      province_id: '4',
    },
    {
      name: 'Viterbo',
      id: '110',
      province_id: '4',
    },
  ],
};
