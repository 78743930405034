import { render, staticRenderFns } from "./wa-pm5-6-sandwich-surf-prize.vue?vue&type=template&id=c9d80df4&scoped=true&"
import script from "./wa-pm5-6-sandwich-surf-prize.vue?vue&type=script&lang=js&"
export * from "./wa-pm5-6-sandwich-surf-prize.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9d80df4",
  null
  
)

export default component.exports