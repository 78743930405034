<template functional>
  <!-- button or a -->
  <!--
    Mandatory functional
      :ref="data.ref"
      :class="[data.class, data.staticClass]"
      :style="[data.style, data.staticStyle]"
      v-bind="data.attrs"
      v-on="listeners"
    Params
      dataHref
      dataTarget
      dataRel
      dataType
      dataIconId
    -->
  <component
    :is="
      $options.isButton(data)
        ? 'button'
        : props.dataType === 'router-link'
        ? 'router-link'
        : 'a'
    "
    :ref="data.ref"
    :class="[
      'WaCta typo-a-8 typo-heavy',
      data.staticClass,
      data.class,
      $options.isButton(data)
        ? 'fullWidthOnMobile buttonComponent'
        : 'linkComponent',
    ]"
    :href="
      $options.isButton(data)
        ? null
        : props.dataForceClick === 'true'
        ? null
        : props.dataHref
    "
    :to="props.dataType === 'router-link' ? props.dataTo : null"
    :target="$options.isButton(data) ? null : props.dataTarget"
    :rel="$options.isButton(data) ? null : props.dataRel"
    :type="$options.isButton(data) ? props.dataType : null"
    :disabled="$options.isButton(data) ? props.dataDisabled : null"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
    @click="
      props.dataHref
        ? $options.navigate(
            $event,
            $options.isButton(data),
            props.dataHref,
            props.dataTarget,
            props.dataIsContext,
            props.dataContestName,
            props.dataForceClick
          )
        : null
    "
  >
    <span>
      <svg
        v-if="props.dataIconId !== '' && $options.hasIconBefore(data)"
        class="inverted-link svgWrapper"
        :width="$options.getIconSize($options.isButton(data)).width"
        :height="$options.getIconSize($options.isButton(data)).height"
      >
        <use :href="`#${props.dataIconId}`" fill="currentColor" />
      </svg>
      <slot />
      <svg
        v-if="props.dataIconId !== '' && !$options.hasIconBefore(data)"
        :width="$options.getIconSize($options.isButton(data)).width"
        :height="$options.getIconSize($options.isButton(data)).height"
        class="svgWrapper"
      >
        <use :href="`#${props.dataIconId}`" fill="currentColor" />
      </svg>
    </span>
  </component>
</template>

<script>
import {
  classChecker,
  dirtySearch,
  pureSearch,
  getQsObject,
  cleanUrl,
} from '@helpers/utils';
export default {
  name: 'WaCta',
  functional: true,
  props: {
    dataHref: {
      type: String,
      default: null,
    },
    dataForceClick: {
      //use this to handle behaviour on click and put a # on href also if it is not a button
      type: String,
      default: null,
    },
    dataIsContext: {
      type: String,
      default: null,
    },
    dataContestName: {
      type: String,
      default: null,
    },
    dataTarget: {
      type: String,
      default: null,
    },
    dataRel: {
      type: String,
      default: '',
    },
    dataType: {
      type: String,
      default: 'button',
    },
    dataTo: {
      type: [Object, String],
      default: null,
    },
    dataIconId: {
      type: String,
      default: '',
    },
    dataDisabled: {
      type: Boolean,
      default: null,
    },
  },
  isButton(data) {
    return (
      (data.staticClass &&
        (classChecker(data.staticClass, 'primary') ||
          classChecker(data.staticClass, 'secondary'))) ||
      (data.class &&
        (classChecker(data.class, 'primary') ||
          classChecker(data.class, 'secondary')))
    );
  },
  hasIconBefore(data) {
    return (
      (data.staticClass && classChecker(data.staticClass, 'inverted-link')) ||
      (data.class && classChecker(data.class, 'inverted-link'))
    );
  },
  getIconSize(isButton) {
    return isButton ? { width: 24, height: 24 } : { width: 16, height: 16 };
  },
  navigate($event, isBtn, href, target, isContext, contestName, forceClick) {
    const cleanedUrl = cleanUrl(href);
    const dirty = dirtySearch(href);
    const pure = pureSearch(dirty);
    let qsObj = getQsObject(pure);

    if (isContext === 'true') {
      qsObj.isContext = true;
    }

    if (contestName) {
      qsObj.contestName = contestName;
    }

    const qs = Object.keys(qsObj)
      .map((key) => `${key}=${window.encodeURIComponent(qsObj[key])}`)
      .join('&');

    const parsedHref = `${cleanedUrl}?${qs}`;

    if (isBtn || forceClick === 'true') {
      $event.preventDefault();
      if (target === '_blank') {
        window.open(href, target);
      } else {
        window.location.href = parsedHref;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/mixins/breakpoints';
@import '~$scss/_variables.scss';

.WaCta {
  display: inline-block;
  cursor: pointer;
}

.linkComponent {
  color: $color-blue-logo;
  transition: all 0.3s;

  span {
    display: flex;
    align-items: center;
  }

  &.disabled,
  &:disabled {
    color: $color-grey-8;
    pointer-events: none;
    opacity: 0.75;
  }

  &:hover {
    color: $color-oxford-blue;
    text-decoration: underline;
  }

  .svgWrapper {
    margin-left: 14px;
    flex-shrink: 0;
  }

  > span {
    align-items: center;
  }

  &.inverted-link {
    .svgWrapper {
      margin-left: 0;
      margin-right: 14px;
    }
  }
}

.buttonComponent {
  border-radius: 4px;
  border: 0;
  transition: all 0.3s;
  border: 0;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .svgWrapper {
    margin: 0 0 0 8px;

    @include media-breakpoint-up(md) {
      margin: 0 0 0 16px;
    }
  }

  &.inverted-link {
    .svgWrapper {
      margin: 0 8px 0 0;

      @include media-breakpoint-up(md) {
        margin: 0 16px 0 0;
      }
    }
  }

  &.primary {
    background-color: $color-blue-logo;
    padding: 14.7px 14.7px;
    color: $color-white;

    &:hover {
      background-color: $color-oxford-blue;
    }

    &.disabled,
    &:disabled {
      background: $color-grey-4;
      color: $color-grey-8;
      pointer-events: none;
    }

    @media (min-width: $bootstrap-sm) {
      padding: 14.4px 64px;
    }
  }

  &.secondary {
    background-color: $color-white;
    color: $color-blue-logo;
    padding: 12.7px 12.7px;
    border: 2px $color-blue-logo solid;

    &:hover {
      background-color: $color-oxford-blue;
      color: $color-white;
    }

    &.disabled,
    &:disabled {
      background: $color-grey-4;
      border: 0;
      color: $color-grey-8;
      opacity: 0.5;
      pointer-events: none;
    }

    @media (min-width: $bootstrap-sm) {
      padding: 12.4px 62px;
    }
  }

  &.small {
    padding: 8px 8px;

    @media (min-width: $bootstrap-sm) {
      padding: 8px 32px;
    }
  }
}

.fullWidthOnMobile {
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.print_cta {
  width: 24px;
  height: 24px;
  color: inherit;
  &:hover {
    color: $color-cobalt-blue;
  }
  svg {
    width: 24px;
    height: 24px;
  }
  .svgWrapper {
    margin-left: 3px;
  }
}
</style>
