var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"WaProductCard",attrs:{"href":_vm.JsonDataConf.href}},[_c('div',{staticClass:"animatedImage"},[_c('ratio-size-box',{attrs:{"data-width-ratio-component":"4","data-height-ratio-component":"3","data-vertical-align-content":"center","data-crop":"contain"}},[_c('img',{ref:"prodPicture",staticClass:"cardImage riseThis",attrs:{"src":_vm.JsonDataConf.card_src,"alt":_vm.JsonDataConf.card_alt}})])],1),_c('div',{staticClass:"content"},[_c('p',{staticClass:"description"},[_c('span',{ref:"productLabel",class:[
          'label',
          _vm.JsonDataConf.variant_class === 'big_title'
            ? 'typo-a-6'
            : 'typo-a-7' ]},[_vm._v(" "+_vm._s(_vm.JsonDataConf.label)+" ")]),(_vm.JsonDataConf.is_new)?_c('wa-tag',{staticClass:"tag text-color-white bg-color-secondary-red"},[_vm._v(" "+_vm._s(_vm.$store.state.config.new_label)+" ")]):(_vm.JsonDataConf.wa_tag)?_c('wa-tag',{class:[
          'tag',
          _vm.JsonDataConf.wa_tag.label_color,
          _vm.JsonDataConf.wa_tag.label_background_color ]},[_vm._v(" "+_vm._s(_vm.JsonDataConf.wa_tag.label)+" ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }