<template>
  <div class="WaRecipePage">
    <wa-recipe-hero-detail
      :class="[
        'padding-top-xxl padding-bottom-xl',
        `bg-color-${recipeConf.main_color}`,
      ]"
      :data-img-src="recipeConf.img_src"
      :data-img-alt="recipeConf.img_alt"
      :data-label-gray-one-text="oneText"
      :data-label-gray-two-text="twoText"
      :data-label-gray-three-text="threeText"
      :data-label-gray-four-text="fourText"
    >
      <template slot="small_title">
        <h4 :class="[`text-color-${recipeConf.sub_color}`]">
          {{ recipeConf.meal_types.map((m) => m.dataValue).join(' - ') }}
        </h4>
      </template>
      <template slot="title_text">
        <h1
          :class="[
            'typo-w-3 typo-extra-bold margin-bottom-0',
            `text-color-${recipeConf.sub_color}`,
          ]"
        >
          {{ recipeConf.title }}
        </h1>
      </template>
      <template slot="description_wysiwyg">
        <div v-html="recipeConf.description" />
      </template>
      <template slot="social-share">
        <wa-social-share>
          <template slot="wa-social-share-item">
            <wa-social-share-item
              data-is-share="true"
              data-social="twitter"
              :data-share-tracking-active="true"
              :data-share-tracking-title="recipeConf.title"
              data-share-tracking-content-type="recipe"
              :data-share-url="recipeConf.href"
              :data-share-smc-enabled="true"
              data-share-smc-click-event="ZRECIPE_SHARED"
              :data-share-smc-recipe-id="recipeConf.id"
              :data-share-smc-product-code="recipeConf.product.smc_id"
              :data-share-smc-highlighted-content="
                recipeConf.product.smc_highlighted_content
              "
            />
            <wa-social-share-item
              v-if="currentSiteCode !== 'ru-ru'"
              data-is-share="true"
              data-social="facebook"
              :data-share-tracking-active="true"
              :data-share-tracking-title="recipeConf.title"
              data-share-tracking-content-type="recipe"
              :data-share-url="recipeConf.href"
              :data-share-smc-enabled="true"
              data-share-smc-click-event="ZRECIPE_SHARED"
              :data-share-smc-recipe-id="recipeConf.id"
              :data-share-smc-product-code="recipeConf.product.smc_id"
              :data-share-smc-highlighted-content="
                recipeConf.product.smc_highlighted_content
              "
            />
            <wa-social-share-item
              data-is-share="true"
              data-social="mail"
              :data-share-tracking-active="true"
              :data-share-tracking-title="recipeConf.title"
              data-share-tracking-content-type="recipe"
              :data-title-share="recipeConf.title"
              :data-share-url="recipeConf.href"
              :data-share-smc-enabled="true"
              data-share-smc-click-event="ZRECIPE_SHARED"
              :data-share-smc-recipe-id="recipeConf.id"
              :data-share-smc-product-code="recipeConf.product.smc_id"
              :data-share-smc-highlighted-content="
                recipeConf.product.smc_highlighted_content
              "
              :data-subject-mail="recipeConf.subject_email"
              :data-content-mail="recipeConf.content_email"
            />
            <wa-cta
              class="link print_cta"
              data-variant-class="link"
              data-icon-id="icon-print"
              :data-href="printRecipeHref"
              data-target="_blank"
              data-rel="nofollow"
            />
          </template>
        </wa-social-share>
      </template>
    </wa-recipe-hero-detail>
    <wa-recipe-info-list
      class="padding-top-xxl padding-bottom-xxl"
      :data-cta-link="
        recipeConf.is_chef_recipe &&
        $store.state.pm56BrunchInNature &&
        $store.state.pm56BrunchInNature['landing-page-url']
          ? $store.state.pm56BrunchInNature['landing-page-url']
          : $store.state.config.recipe_labels.all_recipes_link
      "
      :data-cta-text="$store.state.config.recipe_labels.back_link_label"
      :data-ingredients-label="
        $store.state.config.recipe_labels.ingredients_label
      "
      :data-steps-label="$store.state.config.recipe_labels.how_to_label"
      :data-ingredients="recipeConf.ingredients.map((i) => i.description)"
      :data-steps="recipeConf.cooking_steps.map((i) => i.description)"
    />
    <wa-banner
      data-variant-class="product"
      :data-background-color="`bg-color-${recipeConf.product.main_color}`"
      data-has-top-special-border="true"
      data-has-bottom-special-border="true"
      data-bg-src-image=""
      data-upper-src-image=""
      data-upper-alt-image=""
      data-lower-src-image=""
      data-lower-alt-image=""
      data-link=""
    >
      <!-- product variant -->
      <img
        slot="product"
        :src="recipeConf.product.image_packed"
        :alt="recipeConf.product.label"
      />
      <h3
        slot="title"
        :class="[
          'typo-w-3 typo-bold',
          `text-color-${recipeConf.product.sub_color}`,
        ]"
      >
        {{ recipeConf.product.label }}
      </h3>
      <h5
        slot="title-small"
        :class="[
          'typo-a-7 typo-extra-bold ',
          `text-color-${recipeConf.product.sub_color}`,
        ]"
      >
        {{ $store.state.config.recipe_labels.made_with_label }}
      </h5>
      <div
        slot="wysiwyg"
        :class="[`text-color-${recipeConf.product.sub_color}`]"
        v-html="recipeConf.product.description"
      />
      <template slot="vertical-name">
        <wa-vertical-name
          data-vertical="position-bottom"
          :class="[`text-color-${recipeConf.product.sub_color}`]"
        >
          {{ recipeConf.product.main_category_label }}
        </wa-vertical-name>
      </template>
      <template slot="cta">
        <wa-cta
          class="link"
          data-variant-class="link"
          data-icon-id="icon-arrow-angle-right"
          :data-href="recipeConf.product.href"
          data-target="_self"
        >
          {{ $store.state.config.recipe_labels.discover_more_label }}
        </wa-cta>
        <template v-if="currentSiteCode === 'en-us'">
          <wa-cta-buy-now
            v-if="recipeConf.product.upc_code"
            :data-upc-code="recipeConf.product.upc_code"
            data-variant-class="link"
            class="margin-top-s"
          />
        </template>
        <template v-else>
          <wa-cta
            v-if="recipeConf.product.buy_link"
            class="link"
            data-variant-class="link"
            data-icon-id="icon-arrow-angle-right"
            :data-href="recipeConf.product.buy_link"
            data-target="_blank"
          >
            {{ $store.state.config.buy_now_label }}
          </wa-cta>
        </template>
      </template>
    </wa-banner>
    <template v-for="item in recipeConf.recipe_free_texts">
      <template v-if="item.img_src">
        <wa-side-by-side-recipes
          :key="`${item.title_tag}${item.title_text}`"
          :class="`padding-top-l ${item.variant_class}`"
          :data-variant-class="item.variant_class"
          :data-ratio-width="!item.variant_class.includes('square') ? 5 : 1"
          :data-ratio-height="!item.variant_class.includes('square') ? 7.5 : 1"
          :data-ratio-width-mobile="
            !item.variant_class.includes('square') ? 5 : 1
          "
          :data-ratio-height-mobile="
            !item.variant_class.includes('square') ? 7.5 : 1
          "
        >
          <component
            :is="item.title_tag"
            v-if="item.title_tag"
            slot="title"
            class="margin-bottom-r"
          >
            {{ item.title_text }}
          </component>

          <template v-if="item.content" slot="wysiwyg" class="contentText">
            <div v-html="item.content"></div>
          </template>

          <img slot="image" :src="item.img_src" :alt="item.img_alt" />
        </wa-side-by-side-recipes>
      </template>
      <template v-else>
        <wa-grid-container
          v-if="item.content"
          :key="`${item.title_tag}${item.title_text}`"
          class="container padding-top-l"
        >
          <template slot="wa-grid-row">
            <wa-grid-row class="row justify-content-center">
              <template slot="wa-grid-column">
                <wa-grid-column class="col-12 col-md-10 col-lg-8">
                  <template slot="wa-grid-column-childs">
                    <component
                      :is="item.title_tag"
                      v-if="item.title_tag"
                      class="title text-center margin-bottom-r typo-w-4 text-color-secondary-cyan"
                    >
                      {{ item.title_text }}
                    </component>
                    <wa-free-text>
                      <template
                        v-if="item.content"
                        slot="content_wysiwyg"
                        class="contentText"
                      >
                        <div v-html="item.content"></div>
                      </template>
                    </wa-free-text>
                  </template>
                </wa-grid-column>
              </template>
            </wa-grid-row>
          </template>
        </wa-grid-container>
      </template>
    </template>
    <div v-if="$root.isPowerReviewActive">
      <pr-review-display class="padding-top-xxl"></pr-review-display>
    </div>
    <wa-title-text-cta
      class="padding-top-xxl padding-bottom-0 side-cta"
      data-variant="side-cta"
    >
      <template slot="title_text">
        <h3
          class="typo-w typo-w-3 typo-bold padding-bottom-r margin-bottom-0 text-color-secondary-item"
        >
          {{ $store.state.config.recipe_labels.related_title_label }}
        </h3>
      </template>
      <template slot="content_wysiwyg">
        <div
          class="typo-light"
          v-html="$store.state.config.recipe_labels.related_description_label"
        />
      </template>
      <template slot="cta">
        <wa-cta
          class="link"
          data-variant-class="link"
          data-icon-id="icon-arrow-angle-right"
          :data-href="
            recipeConf.is_chef_recipe &&
            $store.state.pm56BrunchInNature &&
            $store.state.pm56BrunchInNature['landing-page-url']
              ? $store.state.pm56BrunchInNature['landing-page-url']
              : $store.state.config.recipe_labels.all_recipes_link
          "
          data-target="_self"
        >
          {{ $store.state.config.recipe_labels.see_more_link_label }}
        </wa-cta>
      </template>
    </wa-title-text-cta>

    <wa-grid-container class="container-fluid padding-top-l padding-bottom-xxl">
      <template slot="wa-grid-row">
        <wa-grid-row class="row">
          <template slot="wa-grid-column">
            <wa-grid-column
              v-for="(recipe, idx) in recipeConf.related_recipes"
              :key="`${idx}-${_uid}`"
              class="col-12 col-sm-4"
            >
              <template slot="wa-grid-column-childs">
                <wa-recipe-card :data-conf="recipe" class="padding-bottom-l" />
              </template>
            </wa-grid-column>
          </template>
        </wa-grid-row>
      </template>
    </wa-grid-container>
  </div>
</template>

<script>
import LanguageSupport from '@mixins/language-support';
import SmcSupport from '@mixins/smc-support';
import moment from 'moment';

export default {
  name: 'WaRecipePage',
  mixins: [LanguageSupport, SmcSupport],
  props: {
    dataRecipeConf: {
      type: [Object, String],
      required: true,
    },
    dataPrint: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    oneText() {
      if (this.recipeConf.ingredients.length) {
        const label_hero =
          this.$store.state.config.recipe_labels.ingredients_label_hero;
        const label_content =
          this.$store.state.config.recipe_labels.ingredients_label;
        const label = label_hero.length ? label_hero : label_content;

        return `${this.recipeConf.ingredients.length} ${label}`;
      } else {
        return '';
      }
    },
    twoText() {
      return this.recipeConf.people
        ? this.recipeConf.people +
            ' ' +
            this.$store.state.config.recipe_labels.people_label
        : null;
    },
    threeText() {
      return this.recipeConf.kcalories
        ? this.recipeConf.kcalories +
            ' ' +
            this.$store.state.config.recipe_labels.kcal_label
        : null;
    },
    fourText() {
      return this.recipeConf.cooking_time
        ? this.cookingTime(this.recipeConf.cooking_time)
        : null;
    },
    recipeConf() {
      return this.$_cmsSupport_castPropToType(this.dataRecipeConf, 'object');
    },
    printRecipeHref() {
      // TODO: update this code after promo implementation merge
      return window.location.href.includes('?')
        ? `${window.location.href}&version=print`
        : `${window.location.href}?version=print`;
    },
  },
  mounted() {
    /*
    this.recipe_free_texts
    content: "Immagine verticale"
img_alt: "alt"
img_src: "http://localhost:8010/media/filer_public/5e/cc/5ecce368-d6a0-4504-895a-141e23a9be9a/img_3299.jpg"
title_tag: "h3"
title_text: "Titolo 1"
*/
    /* print */
    if (this.dataPrint) {
      this.$nextTick(() => {
        setTimeout(window.print, 500);
      });
    }

    /* smc */
    const unwatch = this.$watch(
      () => this.smcReady,
      (val) => {
        if (val) {
          this.$_smcSupport_sendEvent('ZRECIPE_VISIT', {
            interactionDetail: this.recipeConf.title,
            productCode: this.recipeConf.product.smc_id,
            highlightedContent: this.recipeConf.product.smc_highlighted_content,
          });
          if (unwatch) {
            unwatch();
          }
        }
      },
      { immediate: true }
    );
  },
  methods: {
    cookingTime(minutes) {
      /* il commentato funziona con certezza fino ai 30 giorni, quando scatta il mese potrebbero volerci ulteriori modifiche */
      /* l'eseguito stampa sempre il tempo in minuti */
      let ct;
      const roundingDefault = moment.relativeTimeRounding();
      // const thresholdMM = moment.relativeTimeThreshold('M');
      // const thresholdD = moment.relativeTimeThreshold('d');
      // const thresholdH = moment.relativeTimeThreshold('h');
      const thresholdM = moment.relativeTimeThreshold('m');
      moment.relativeTimeRounding(Math.floor);
      // moment.relativeTimeThreshold('M', 12);
      // moment.relativeTimeThreshold('d', 31);
      // moment.relativeTimeThreshold('h', 24);
      // moment.relativeTimeThreshold('m', 60);
      moment.relativeTimeThreshold('m', 525600);

      // if (minutes > 59) {
      //   const h = minutes / 60;
      //   ct = moment.duration(h, "hours").humanize();
      //   if (h > 24) {
      //     const h2 = h % 24;
      //     if (parseInt('' + h2) % 1 === 0 && h2 >= 1) {
      //       ct = ct + ', ' + moment.duration(h2, "hours").humanize();
      //     }
      //   }
      //   const m = minutes % 60;
      //   if (m > 0) {
      //     ct = ct + ', ' + moment.duration(m, "minutes").humanize();
      //   }
      // } else {
      //   ct = moment.duration(minutes, "minutes").humanize();
      // }
      ct = moment.duration(minutes, 'minutes').humanize();

      // Restore default
      moment.relativeTimeRounding(roundingDefault);
      // moment.relativeTimeThreshold('M', +thresholdMM);
      // moment.relativeTimeThreshold('d', +thresholdD);
      // moment.relativeTimeThreshold('h', +thresholdH);
      moment.relativeTimeThreshold('m', +thresholdM);

      return ct;
    },
  },
};
</script>

<style lang="scss" scoped></style>
