<template>
  <div :class="['WaAccordionItem item', { open: inOpenAccordions }]">
    <div class="header">
      <a
        :id="`${prefix}-header-${_uid}`"
        :ref="`item-${_uid}`"
        :href="`#${prefix}-item-${_uid}`"
        class="header-link"
        role="button"
        :aria-controls="`${prefix}-item-${_uid}`"
        :aria-expanded="inOpenAccordions ? 'true' : 'false'"
        @click.prevent="toggle()"
        @keydown.space="toggle()"
      >
        <!--
          //to implement in the future
          @keydown.up="moveFocus(getIndex(), 'up')"
          @keydown.down="moveFocus(getIndex(), 'down')"
          @keydown="focusHomeEnd"
        -->
        <div
          :class="[
            'outline-wrapper d-flex',
            { 'align-items-center': dataVariant === 'with-logo' },
          ]"
        >
          <div
            v-if="$_cmsSupport_hasContentSlotWithName('image')"
            class="image"
          >
            <slot name="image" />
          </div>
          <div class="title flex-grow-1">
            <slot name="title" />
          </div>
          <div class="arrow">
            <svg width="24" height="24" class="chevron">
              <use href="#icon-arrow-angle-down" fill="currentColor" />
            </svg>
          </div>
        </div>
      </a>
    </div>
    <div
      v-if="$_cmsSupport_hasContentSlotWithName('content')"
      :id="`${prefix}-item-${_uid}`"
      :ref="`${prefix}-item-${_uid}`"
      v-expand="inOpenAccordions"
      role="region"
      :aria-labelledby="`${prefix}-header-${_uid}`"
      :aria-hidden="!inOpenAccordions ? 'true' : 'false'"
      class="typo-a typo-a-7 typo-light"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaAccordionItem',
  props: {
    dataVariant: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
      prefix: 'wa-accordion',
      transition: false,
      autoclose: true,
    };
  },
  computed: {
    inOpenAccordions() {
      //console.log('inOpenAccordions',this.$parent.openEls.indexOf(this.$el) !== -1)
      return this.$parent.openEls.indexOf(this.$el) !== -1;
    },
    //itemsCount() {
    //to implement in the future
    //  return Object.keys(this.$refs).filter((value) => value.indexOf("item-") === 0).length
    //},
  },
  methods: {
    toggle() {
      if (this.transition) {
        return false;
      }
      this.transition = true;

      if (
        this.autoclose &&
        this.$parent.openEls.length &&
        this.$parent.openEls.indexOf(this.$el) === -1
      ) {
        this.$parent.openEls = [];
      }
      const idx = this.$parent.openEls.indexOf(this.$el);
      if (idx !== -1) {
        this.$parent.openEls.splice(idx, 1);
        this.open = false;
      } else {
        this.$parent.openEls.push(this.$el);
        this.open = true;
      }
      const end = () => {
        this.$el.removeEventListener('transitionend', end);
        this.$root.$emit('content-update');
        this.transition = false;
      };
      this.$el.addEventListener('transitionend', end);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaAccordionItem {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  @media (min-width: $bootstrap-sm) {
    padding: 24px;
  }
  @media (min-width: $bootstrap-md) {
    padding: 32px;
  }
  .header {
    cursor: pointer;
    padding: 0;

    .header-link {
      color: inherit;
      &:hover {
        text-decoration: none;
      }
    }

    .header-link:focus,
    .outline-wrapper:focus {
      outline: none;
    }

    .image {
      padding-right: 16px;
      @media (min-width: $bootstrap-sm) {
        padding-right: 24px;
      }
      @media (min-width: $bootstrap-md) {
        padding-right: 32px;
      }
      img {
        width: 60px;
        height: 50px;
        object-fit: contain;
        @media (min-width: $bootstrap-sm) {
          width: 96px;
          height: 80px;
        }
        @media (min-width: $bootstrap-md) {
          width: 120px;
          height: 100px;
        }
      }
    }
    .title {
      margin: 0;
      padding-right: 16px;
      @media (min-width: $bootstrap-sm) {
        padding-right: 24px;
      }
      @media (min-width: $bootstrap-md) {
        padding-right: 32px;
      }
    }
    .arrow {
      display: inline-flex;
      align-self: flex-start;
      top: 6px;
      position: relative;
      @media (min-width: $bootstrap-md) {
        align-self: center;
        top: auto;
      }
    }
  }

  /* needed for directive */
  .expand {
    overflow: hidden;
    transition: height 0.3s linear;

    &[aria-expanded='false'] {
      height: 0 !important;
    }
  }
  /* needed for directive */
  .u-no-transition {
    transition: none !important;
  }
}
</style>
