import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    'contest-published': false,
    'json': '',
    'iw_won': null,
    'prize_label': '',
    'relatedProducts': [],
    // 'landing-page-url': '',
    // 'participate-page-url': '',
    //'redeem-prize-page-url': '',
    //'legal-age': '',
    //'products-api': '',
    //'stores-api': '',
    //'privacy-check': '',
    'enter-contest-api': '',
    //'check-winner-api': '',
    //'check-winner-debug': '',
    //'redeem-prize-api': '',
    //'ch-key-pm5-6-sandwich-summer-campaign': '',
    //'reg-source-pm5-6-sandwich-summer-campaign': '',
    'i18n': {
      participate: {
        hero_title: '',
        hero_title_small: '',
        hero_description: '',
        contest_title: '',
        contest_description: '',
        picture_heading: '',
        picture_description: '',
        picture_label: '',
        picture_label_small: '',
        picture_label_hover: '',
        picture_cta: '',
        picture_helper: '',
        picture_helper_small: '',
        picture_error_format: '',
        picture_error_size: '',
        picture_error_required: '',
        picture_instruction_heading: '',
        picture_instruction_legend_1: '',
        picture_instruction_legend_2: '',
        picture_instruction_legend_3: '',
        picture_instruction_legend_4: '',
        picture_instruction_legend_5: '',
        picture_instruction_hint_1: '',
        picture_instruction_hint_2: '',
        picture_instruction_hint_3: '',
        picture_instruction_info_heading: '',
        picture_instruction_info_1: '',
        picture_instruction_info_2: '',
        contact_title: '',
        contact_subtitle: '',
        phone_placeholder: '',
        phone_error_required: '',
        phone_error_regex: '',
        rules_label: '',
        rules_href: '',
        privacy_href: '',
        rules_error_required: '',
        submit_cta: '',
        global_error_logged: '',
        global_error_legal_age: '',
        global_error_inactive_contest: '',
        global_error_external_server_communication_failed: '',
        global_error_unable_to_retrieve_user_token: '',
        global_error_missing_parameters_in_configuration: '',
        global_error_rule_acceptance_flag_false: '',
        global_error_fix_fields: '',
        thank_you_title: '',
        thank_you_description: '',
        thank_you_cta: '',
        eligible_text_label: '',
        eligible_text_helper: '',
        eligible_text_required: '',
        waiting_text: '',
        win_title: '',
        win_description: '',
        win_cta: '',
        win_cta_href: '',
        win_title_alternative: '',
        win_description_alternative: '',
        win_cta_alternative: '',
        win_cta_href_alternative: '',
        lose_title: '',
        lose_description: '',
        lose_cta: '',
        lose_cta_href: '',
        related_title: '',
        related_content: '',
        related_cta: '',
        related_cta_href: '',
      },
    },
  },
  actions,
  mutations,
  getters,
};
