<template>
  <div class="WaPm56SandwichSurfEnterContest">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WaPm56SandwichSurfEnterContest',
  props: {
    dataPromo: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    i18n() {
      return this.$_cmsSupport_castPropToType(this.dataPromo, 'object');
    },
  },
  created() {
    this.setPm56SandwichSurfI18nParticipate(this.i18n);
  },
  methods: {
    ...mapActions('pm56SandwichSurf', ['setPm56SandwichSurfI18nParticipate']),
  },
};
</script>

<style scoped></style>
