<template>
  <div class="WaPm9SaveTheInsectsVerify">
    <wa-hero
      data-variant-class="center"
      :data-bg-src-image="require('@images/save-the-insects/banner-bg.png')"
      :data-bg-mobile-src-image="
        require('@images/save-the-insects/banner-bg.png')
      "
      :data-has-down-arrow="true"
    >
      <h1 slot="title" class="text-color-white">
        {{ i18nParticipate.hero_title }}
      </h1>

      <h4 slot="title-small" class="text-color-white">
        {{ i18nParticipate.hero_title_small }}
      </h4>

      <p slot="description" class="text-color-white">
        {{ i18nParticipate.hero_description }}
      </p>
    </wa-hero>
    <div class="container-fluid padding-top-xxl padding-bottom-xxl">
      <form ref="$form" class="WaPm9SaveTheInsectsVerifyForm" @submit.prevent>
        <div>
          <div class="row justify-content-center">
            <div
              class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-green-ragi padding-bottom-r"
            >
              <svg class="icon margin-bottom-r">
                <use href="#icon-wasa-boxed" fill="currentColor" />
              </svg>
              <span class="typo-w-4 typo-bold text-center">
                {{ i18nParticipate.product_heading }}
              </span>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 padding-bottom-xl">
              <wa-multi-select-list-item
                ref="$products"
                class="padding-top-xxl"
                :data-info-text="i18nParticipate.data_info_text"
                :data-info-text-remove="i18nParticipate.data_info_text_remove"
                :data-total-products="i18nParticipate.data_total_products"
                :data-products="promoProducts"
                :data-dynamic-list="true"
                :data-categories-placeholder="
                  i18nParticipate.categories_placeholder
                "
                :data-products-placeholder="
                  i18nParticipate.products_placeholder
                "
                :data-products-remove-text="
                  i18nParticipate.remove_product_label
                "
                :data-products-add-more-text="i18nParticipate.add_product_label"
                @add-product="onAddProduct"
                @remove-product="onRemoveProduct"
              ></wa-multi-select-list-item>
              <span
                v-if="show_selected_products_error"
                class="global-error products-error"
              >
                {{ i18nParticipate.products_error_required }}
              </span>
            </div>
          </div>
          <div class="row justify-content-center">
            <div
              class="col-12 col-md-10 col-lg-8 d-flex flex-column align-items-center justify-content-center text-color-green-ragi padding-bottom-l"
            >
              <span class="typo-w-4 typo-bold text-center margin-bottom-r">
                {{ i18nParticipate.shop_type_heading }}
              </span>
              <div
                class="typo-a-8 typo-light text-color-grey-9 text-center noMBLastChild"
                v-html="i18nParticipate.shop_type_description"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
              <wa-radio-chooser
                v-model.trim="$v.receipt_type.$model"
                data-name="receipt_type"
                :data-radio-list="[
                  {
                    value: 'standard',
                    label: i18nParticipate.shop_type_store_label,
                    iconId: 'icon-market',
                  },
                  {
                    value: 'electronic',
                    label: i18nParticipate.shop_type_online_label,
                    iconId: 'icon-world-hand-drawn',
                  },
                ]"
                :data-error-occurred="$v.receipt_type.$error"
                :data-errors="[
                  {
                    condition: !$v.receipt_type.required,
                    message: i18nParticipate.shop_type_error_required,
                  },
                  {
                    condition: !$v.receipt_type.serverError,
                    message: serverErrors.receipt_type,
                  },
                ]"
                @change="onSetShopType"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 padding-top-r">
              <wa-dropdown
                v-model.trim="$v.receipt_store.$model"
                data-name="receipt_store"
                :data-label="i18nParticipate.shop_label"
                :data-placeholder="i18nParticipate.shop_placeholder"
                :data-option-list="filteredShops"
                :data-error-occurred="$v.receipt_store.$error"
                :data-errors="[
                  {
                    condition: !$v.receipt_store.required,
                    message: i18nParticipate.shop_error_required,
                  },
                  {
                    condition: !$v.receipt_store.serverError,
                    message: serverErrors.receipt_store,
                  },
                ]"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
              <wa-regular-divider class="padding-top-m padding-bottom-m" />
            </div>
          </div>
          <div class="row justify-content-center">
            <div
              class="col-12 col-md-10 col-lg-8 d-flex flex-column padding-bottom-xs"
            >
              <span
                class="typo-a-6 typo-extra-bold margin-bottom-r text-color-grey-9"
              >
                {{ i18nParticipate.picture_heading }}
              </span>
              <span class="typo-a-9 typo-medium text-color-grey-8">
                {{ i18nParticipate.picture_description }}
              </span>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 padding-bottom-r">
              <wa-upload
                ref="$waUpload"
                v-model.trim="$v.receipt_front_photo.$model"
                name="receipt_front_photo"
                :label="i18nParticipate.picture_label"
                :label-smaller="i18nParticipate.picture_label_small"
                :label-on-hover="i18nParticipate.picture_label_hover"
                :cta-text="i18nParticipate.picture_cta"
                :helper-text="i18nParticipate.picture_helper"
                :helper-text-smaller="i18nParticipate.picture_helper_small"
                :data-error-occurred="$v.receipt_front_photo.$error"
                :errors="[
                  {
                    condition: !$v.receipt_front_photo.required,
                    message: i18nParticipate.picture_error_required,
                  },
                  {
                    condition: !$v.receipt_front_photo.mustBeImageOrPdf,
                    message: i18nParticipate.picture_error_format,
                  },
                  {
                    condition: !$v.receipt_front_photo.validSize,
                    message: i18nParticipate.picture_error_size,
                  },
                  {
                    condition: !$v.receipt_front_photo.serverError,
                    message: serverErrors.receipt_front_photo,
                  },
                ]"
                @change="onFileChange"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 padding-bottom-m">
              <wa-accordion>
                <wa-accordion-item class="text-color-blue-logo margin-bottom-0">
                  <template slot="title">
                    <h4 class="typo-a-6 typo-medium margin-bottom-0">
                      {{ i18nParticipate.picture_instruction_heading }}
                    </h4>
                  </template>
                  <template slot="content">
                    <wa-free-text>
                      <template slot="content_wysiwyg">
                        <div class="padding-top-m padding-bottom-m text-center">
                          <img
                            v-if="receiptImg"
                            :src="receiptImg"
                            class="img-fluid"
                          />
                        </div>
                        <ol class="margin-top-0 margin-bottom-0">
                          <template v-for="(legend, idx) in legends">
                            <li
                              :key="`legend-${idx}`"
                              :class="[
                                'typo-a-7 typo-medium text-color-healty-green',
                                {
                                  'margin-bottom-0': idx === legends.length - 1,
                                },
                              ]"
                            >
                              {{ legend }}
                            </li>
                          </template>
                        </ol>
                        <wa-regular-divider
                          v-if="legends.length > 0"
                          class="padding-top-r padding-bottom-r"
                        />
                        <ul class="margin-top-0 margin-bottom-0">
                          <template v-for="(hint, idx) in hints">
                            <li
                              :key="`hint-${idx}`"
                              :class="[
                                'typo-a-7 typo-light text-color-black',
                                {
                                  'margin-bottom-0': idx === hints.length - 1,
                                },
                              ]"
                            >
                              {{ hint }}
                            </li>
                          </template>
                        </ul>
                        <wa-regular-divider
                          v-if="hints.length > 0"
                          class="padding-top-r padding-bottom-r"
                        />
                        <p>
                          <template v-for="(info, idx) in infos">
                            <span
                              :key="`info-${idx}`"
                              :class="[
                                {
                                  'd-block typo-a-7 typo-medium text-color-grey-8 padding-bottom-xs':
                                    idx === 0,
                                  'd-block typo-a-9 typo-light text-color-black padding-bottom-xs':
                                    idx === 1,
                                  'd-block typo-a-9 typo-light text-color-black':
                                    idx === 2,
                                },
                              ]"
                            >
                              {{ info }}
                            </span>
                          </template>
                        </p>
                      </template>
                    </wa-free-text>
                  </template>
                </wa-accordion-item>
              </wa-accordion>
            </div>
          </div>
        </div>
        <div v-if="show_rules" class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 padding-bottom-m">
            <wa-checkbox
              v-model.trim="$v.contest_rules_accepted.$model"
              data-name="contest_rules_accepted"
              :data-checkbox-list="[
                {
                  label: rulesLabel,
                },
              ]"
              :data-error-occurred="$v.contest_rules_accepted.$error"
              :data-errors="[
                {
                  condition: !$v.contest_rules_accepted.mustBeChecked,
                  message: i18nParticipate.rules_error_required,
                },
                {
                  condition: !$v.contest_rules_accepted.serverError,
                  message: serverErrors.contest_rules_accepted,
                },
              ]"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <div class="d-block d-sm-inline-block">
              <wa-cta
                class="primary"
                data-type="submit"
                :data-icon-id="spinner ? 'icon-ellipsis-loader' : ''"
                :data-disabled="disableSubmit || submitted"
                @click.prevent="onSubmit"
              >
                {{ i18nParticipate.submit_cta }}
              </wa-cta>
              <span
                v-for="(error, i) in hasGlobalErrors"
                :key="`error-${i}-${_uid}`"
                class="global-error typo-a-10 typo-medium"
                v-html="error.message"
              />
              <span
                v-if="showGlobalErrorFixFields"
                class="global-error typo-a-10 typo-medium"
                v-html="i18nParticipate.global_error_fix_fields"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <wa-modal-toast
      class="padding-top-l padding-bottom-l text-color-secondary-red"
      data-icon="icon-alert"
    >
      <template slot="title">
        <div class="text-color-green-ragi">
          {{ i18nParticipate.global_error_modal_fail_title }}
        </div>
      </template>
      <template slot="content">
        <div class="text-color-green-ragi" v-html="apiErrors"></div>
      </template>
    </wa-modal-toast>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { helpers, required } from 'vuelidate/lib/validators';
import LanguageSupport from '@mixins/language-support';
import { debug, prettyPrintErrorProperties } from '@helpers/utils';
import axios from 'axios';

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'WaPm9SaveTheInsectsVerify',
  mixins: [LanguageSupport],
  data() {
    return {
      /* local data */
      show_selected_products_error: false,
      productsRetrieved: false,
      storesRetrieved: false,
      privacyRetrieved: false,
      errorCodes: [],

      /* api data */
      promoProducts: null,
      shops: null,
      show_rules: false,

      /* form field */
      receipt_type: null,
      receipt_store: null,
      receipt_front_photo: null,
      contest_rules_accepted: false,

      /* after submit */
      submitted: false,
      spinner: false,
      serverErrors: {},
      globalErrors: [],
      showGlobalErrorFixFields: false,
    };
  },
  computed: {
    ...mapGetters('pm9SaveTheInsects', ['i18nParticipate']),
    ...mapState(['storeSetupCompleted']),
    ...mapState('gigya', ['loaded', 'user']),
    ...mapState('pm9SaveTheInsects', [
      'list-products-api',
      'list-stores-api',
      'privacy-check-api',
      'enter-contest-api',
    ]),
    apiErrors() {
      return `${
        this.i18nParticipate.global_error_modal_fail_content
      }<br />REF: ${this.errorCodes.join('; ')}.`;
    },
    gigyaAndStoreReady() {
      return {
        storeLoaded: this.storeSetupCompleted,
        gigyaLoaded: this.loaded,
        gigyaUserLoaded: this.user,
      };
    },
    legends() {
      const items = [
        this.i18nParticipate.picture_instruction_legend_1,
        this.i18nParticipate.picture_instruction_legend_2,
        this.i18nParticipate.picture_instruction_legend_3,
        this.i18nParticipate.picture_instruction_legend_4,
        this.i18nParticipate.picture_instruction_legend_5,
      ];

      return items.filter((i) => i);
    },
    hints() {
      const items = [
        this.i18nParticipate.picture_instruction_hint_1,
        this.i18nParticipate.picture_instruction_hint_2,
        this.i18nParticipate.picture_instruction_hint_3,
      ];

      return items.filter((i) => i);
    },
    infos() {
      const items = [
        this.i18nParticipate.picture_instruction_info_heading,
        this.i18nParticipate.picture_instruction_info_1,
        this.i18nParticipate.picture_instruction_info_2,
      ];

      return items.filter((i) => i);
    },

    selectedProductsId() {
      return this.$refs.$products.selectedProductsId;
    },
    receiptImg() {
      try {
        return require(`@images/save-the-insects/receipt-img-${this.currentLang}.png`);
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    disableSubmit() {
      if (
        (this.$v && (this.$v.$anyError || this.$v.$invalid)) ||
        this.selectedProductsId.length === 0 ||
        !this.user
      ) {
        return true;
      }

      return false;
    },
    filteredShops() {
      return this.shops && this.receipt_type
        ? this.shops
            .filter((e) =>
              this.receipt_type === 'electronic' ? e.is_online : !e.is_online
            )
            .map((e) => {
              return { key: e.store_id, dataValue: e.name };
            })
        : [];
    },
    rulesLabel() {
      // reworked in component library
      let k = 0;
      let j = 0;
      return this.i18nParticipate.rules_label
        .replace(/%/g, () => {
          k++;
          if (k === 1) {
            return `<a class="typo-medium text-color-blue-logo" href='${this.i18nParticipate.rules_href}' target='_blank'>`;
          } else {
            return '</a>';
          }
        })
        .replace(/£/g, () => {
          j++;
          if (j === 1) {
            return `<a class="typo-medium text-color-blue-logo" href='${this.i18nParticipate.privacy_href}' target='_blank'>`;
          } else {
            return '</a>';
          }
        });
    },
    hasGlobalErrors() {
      if (this.globalErrors.length) {
        return this.globalErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  watch: {
    gigyaAndStoreReady: {
      async handler(val) {
        const { storeLoaded, gigyaLoaded, gigyaUserLoaded } = val;
        const canDoAsyncCalls = storeLoaded && gigyaLoaded;
        const canDoAsyncCallsThatNeedsUser =
          canDoAsyncCalls && !!gigyaUserLoaded;

        debug && console.log('WATCH gigyaAndStoreReady', val);
        debug && console.log('canDoAsyncCalls', canDoAsyncCalls);
        debug &&
          console.log(
            'canDoAsyncCallsThatNeedsUser',
            canDoAsyncCallsThatNeedsUser
          );

        if (canDoAsyncCalls) {
          if (!this.productsRetrieved) {
            const apiUrl = this['list-products-api'];
            try {
              const response = await axios.get(`${apiUrl}`);
              if (response.data) {
                this.promoProducts = response.data.data.map((p) => {
                  return {
                    id: +p.product_id,
                    name: p.name,
                    category: p.category,
                    category_id: p.category,
                  };
                });
              }
            } catch (e) {
              this.errorCodes.push('EP1');
              prettyPrintErrorProperties(e);
              this.$modal.show('modal-toast');
            }
            this.productsRetrieved = true;
          }

          if (!this.storesRetrieved) {
            try {
              const apiUrl = this['list-stores-api'];
              const response = await axios.get(`${apiUrl}`);
              if (response.data) {
                this.shops = response.data.data;
              }
            } catch (e) {
              this.errorCodes.push('ES1');
              prettyPrintErrorProperties(e);
              this.$modal.show('modal-toast');
            }
            this.storesRetrieved = true;
          }
        }
        if (canDoAsyncCallsThatNeedsUser) {
          if (!this.privacyRetrieved) {
            try {
              const apiUrl = this['privacy-check-api'];
              const response = await axios.get(`${apiUrl}`);
              if (response.data) {
                this.show_rules = !response.data.contest_rule_flag;
              }
            } catch (e) {
              this.errorCodes.push('EU1');
              prettyPrintErrorProperties(e);
              this.$modal.show('modal-toast');
            }
            this.privacyRetrieved = true;
          }
        }
      },
      immediate: true,
    },
    loaded: {
      /* this is used to show the error message under the cta to submit the form in case no user is logged (edit mode) */
      handler(val) {
        if (val) {
          if (!this.user) {
            this.globalErrors.push({
              condition: true,
              message: this.i18nParticipate.global_error_logged,
            });
          }
        }
      },
      immediate: true,
    },
  },
  validations() {
    let validations = {
      // receipt_product: {
      //   required,
      //   serverError: checkServerErrors('receipt_product')
      // },
      receipt_type: {
        required,
        serverError: checkServerErrors('receipt_type'),
      },
      receipt_store: {
        required,
        serverError: checkServerErrors('receipt_store'),
      },
      receipt_front_photo: {
        required,
        mustBeImageOrPdf: (val) => {
          return (
            !helpers.req(val) ||
            val.type === 'image/jpeg' ||
            val.type === 'image/jpg' ||
            val.type === 'image/png' ||
            val.type === 'application/pdf'
          );
        },
        validSize: (val) => {
          const mb = 4 * 1024 * 1024;
          return !helpers.req(val) || val.size <= mb;
        },
        serverError: checkServerErrors('receipt_front_photo'),
      },
    };

    if (this.show_rules) {
      validations = {
        ...validations,
        contest_rules_accepted: {
          mustBeChecked: (val) => !!val,
          serverError: checkServerErrors('contest_rules_accepted'),
        },
      };
    } else {
      validations = {
        ...validations,
        contest_rules_accepted: {},
      };
    }

    return validations;
  },
  created() {
    this.setInvertedHeader(true);
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    onSetShopType() {
      this.receipt_store = null;
    },
    onFileChange(f) {
      this.receipt_front_photo = f;
      this.$v.receipt_front_photo.$touch();
    },
    onAddProduct() {
      this.show_selected_products_error = false;
    },
    onRemoveProduct() {
      if (this.selectedProductsId.length === 0) {
        this.show_selected_products_error = true;
      }
    },
    async onSubmit() {
      if (this.selectedProductsId.length === 0) {
        this.show_selected_products_error = true;
      } else {
        this.show_selected_products_error = false;
      }

      if (!this.submitted && !this.disableSubmit) {
        this.submitted = true;
        this.spinner = true;
        this.showGlobalErrorFixFields = false;
        this.globalErrors = [];

        try {
          let formData = {};

          formData.receipt_type = this.receipt_type;
          formData.receipt_store = this.receipt_store;
          formData.receipt_front_photo = this.$refs.$waUpload.base64;
          formData.receipt_products = this.selectedProductsId;
          formData.contest_rules_accepted = this.contest_rules_accepted;

          //console.log(this.selectedProductsId);
          let result = await axios.post(this['enter-contest-api'], formData);

          if (result.data) {
            // Contest completed
            await this.$store.dispatch('setUnlockedView', 'thank-you');
            // Navigate
            await this.$router.push({ name: 'thank-you' });
          }
        } catch (e) {
          let properties = Object.getOwnPropertyNames(e);
          properties.forEach((p) => {
            let prop = Object.getOwnPropertyDescriptor(e, p);
            if (prop && prop.value) {
              if (p === 'response') {
                if (prop.value.status === 400) {
                  // All errors are handled has 400
                  // promo engine status codes 40X 50X are proxed as 400

                  /* expected response for validation
                    {"username":["Questo campo non può essere omesso","Questo campo non può essere omesso 2"],"description":["Questo campo non può essere omesso"]};
                  */

                  /* expected response for other type of errors or generic non field errors
                    {“non_field_errors”:[“global_error_external_server_communication_failed”]}
                  */
                  let data = prop.value.data;
                  if ('errors' in data) {
                    data = data.errors;
                  }

                  for (const [property, value] of Object.entries(data)) {
                    if (property == 'non_field_errors') {
                      // Global validation errors
                      //this is an old code used for all the promos, luckly arrives always just one value in the array 'value' so
                      //this.i18nParticipate[value] works but if multiple values will arrives it will brake
                      this.globalErrors.push({
                        condition: true,
                        message: this.i18nParticipate[value]
                          ? this.i18nParticipate[value]
                          : value,
                      });
                    } else {
                      if (property === 'receipt_products') {
                        this.show_selected_products_error = true;
                      } else {
                        let temp = {};
                        //Here we removed some dead code about temp[property] = this.i18nParticipate[value] ? this.i18nParticipate[value]: value;
                        //First of all because this.i18nParticipate[value] will be always undefined, value is a string and not the key for the object i18nParticipate
                        //Second because we are not handling client errors between our frontend and our backend
                        //just between our frontend and proxied messages from promo engine backend
                        temp[property] = value;
                        this.serverErrors = { ...this.serverErrors, ...temp };
                        this.$v[property].$touch();
                      }
                    }
                  }

                  //receipt_products

                  Object.keys(this.serverErrors).forEach((key) => {
                    const unwatch = this.$watch(key, (newVal, oldVal) => {
                      if (newVal !== oldVal) {
                        Vue.delete(this.serverErrors, key);
                        setTimeout(() => {
                          this.$v[key].$touch();
                        }, 0);
                        if (unwatch) {
                          unwatch();
                        }
                      }
                    });
                  });

                  // Renew the submission
                } else if (
                  prop.value.status === 401 ||
                  prop.value.status === 403
                ) {
                  // wrong configuration on our side because 401 & 403 are not implemented on the contest side
                  // if you receive one of this probably you should consider a mistake in settings.py (gigya wrongly enabled?)
                  // the following code is just for that but it should never happen
                  this.globalErrors.push({
                    condition: true,
                    message: prop.value.data.detail,
                  });
                } else {
                  // Other errors we just print the statusText that is always available in the response
                  // object and is auto generated from server responses
                  this.globalErrors.push({
                    condition: true,
                    message: prop.value.statusText,
                  });
                }
                this.showGlobalErrorFixFields = true;
                this.submitted = false;
              }
            }
          });
        } finally {
          this.spinner = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaPm9SaveTheInsectsVerify {
  * {
    user-select: none;
  }
  .icon {
    width: 48px;
    height: 48px;

    @media (min-width: $bootstrap-md) {
      width: 64px;
      height: 64px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .img-fluid {
    @include media-breakpoint-up(sm) {
      width: 70%;
    }
  }

  .global-error {
    display: block;
    padding-top: 14px;
    color: $color-informative-red;
    text-align: left;

    &.products-error {
      font-size: 13px;
    }
  }
}
</style>
