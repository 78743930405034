import Vue from 'vue';
import store from '@store';

import VueBootstrapper from 'vue-bootstrapper';
import VueViewportManager from 'vue-viewport-manager';
import VueDjangoCMSPatch from '@helpers/vue_djangocms_patch';
import '@helpers/load-components';
import Swiper, {
  Navigation,
  Pagination,
  Scrollbar,
  EffectFade,
  Autoplay,
} from 'swiper';
import VModal from 'vue-js-modal';
import VueExpand from '@plugins/vue-expand';
import Vuelidate from 'vuelidate';
import VTooltip from 'v-tooltip';
import checkView from 'vue-check-view';

import A11Support from '@mixins/a11-support';
import CmsSupport from '@mixins/cms-support';
import StringSupport from '@mixins/string-support';
import HeaderSupport from '@mixins/header-support';
import PowerReviewsSupport from '@mixins/power-reviews-support';

import { debug, colorLog, brain, pop } from '@helpers/utils.js';

import UAParser from 'ua-parser-js';

const ua = new UAParser();

import SentryInitializer from '@helpers/sentry_initializer';
SentryInitializer(
  Vue,
  process.env.VUE_APP_SENTRY,
  process.env.VUE_APP_ENV,
  process.env.VUE_APP_SITENAME
);

/* eslint-disable */
// Adding plugins
Vue.mixin(A11Support);
Vue.mixin(CmsSupport);
Vue.mixin(StringSupport);
Vue.mixin({
  mounted() {
    window.vueComponentsLib = window.vueComponentsLib || [];
    if (
      !window.vueComponentsLib.includes(this.$options.name) &&
      this.$options.name != void 0
    ) {
      window.vueComponentsLib.push(this.$options.name);
    }
  },
});
Vue.use(VueBootstrapper, { store });
Vue.use(VueViewportManager, {
  store,
  breakpoints: { xs: 0, sm: 768, md: 1024, lg: 1440, xl: 9999 }
});
Vue.use(VModal);
Vue.use(VueExpand);
Vue.use(Vuelidate);
Swiper.use([Navigation, Pagination, Scrollbar, EffectFade, Autoplay]);
Vue.use(VTooltip);
Vue.use(checkView);

// Ignore Django CMS template tags
Vue.config.ignoredElements = [
  'django-cms-template',
  'django-cms-plugin',
  'cms-template',
  'cms-plugin'
];

colorLog({icon:pop, label:'welcome',message:`wasa`, theme : {
          backgroundColorLeft  : "#002F6C",
          backgroundColorRight : "#ffc72c",
          colorLeft            : "#ffc72c",
          colorRight           : "#002e6c"
        }});


// Print some debug messages
if (debug) {
  debug && colorLog({icon:brain, label:'environment',message:`${process.env.VUE_APP_ENV}`, themeName:"orange"});
  debug && colorLog({icon:brain, label:'environment',message:`Vue added to window `, themeName:"orange"});
  debug && colorLog({icon:brain, label:'environment',message:`Devtools enabled `, themeName:"orange"});
  window.Vue = Vue;
  Vue.config.devtools = true;
}

let DjangoPatch = null;
/* eslint-enable */

//wa-main-content
const app = document.getElementById('app-root');
app.removeAttribute('data-v-cloak');
app.setAttribute('v-cloak', '');

export const progressBar = function ({
  done = 0,
  missing = 100,
  emptyOrFull = false,
  label = '',
  isLabelBeforeBar = true,
}) {
  const currentTheme = {
    backgroundColorLeft: 'rgb(65, 184, 131)',
    backgroundColorRight: 'rgb(0, 0, 0)',
    colorLeft: 'rgb(255, 255, 255)',
    colorRight: 'rgb(255, 255, 255)',
  };

  const bLeft = emptyOrFull
    ? 'border-radius: 3px'
    : 'border-radius: 3px 0px 0px 3px;';
  const pLeft = emptyOrFull ? 'padding: 2px 12px' : 'padding: 2px 6px';
  const bRight = emptyOrFull
    ? 'border-radius: 3px'
    : 'border-radius: 0px 3px 3px 0px;';
  const pRight = emptyOrFull ? 'padding: 2px 12px' : 'padding: 2px 6px';
  const styles1 = `background: ${currentTheme.backgroundColorLeft}; ${pLeft}; ${bLeft}; color: ${currentTheme.colorLeft};`;
  const styles2 = `background: ${currentTheme.backgroundColorRight}; ${pRight}; ${bRight}; color: ${currentTheme.colorRight};`;
  const styles3 = `background: transparent;color:black;`;
  const bar = `%c${' '.repeat(done)}%c${' '.repeat(missing)}`;
  const content = label
    ? isLabelBeforeBar
      ? `${label} ${bar}`
      : `${bar}%c ${label}`
    : `${bar}%c`;
  // eslint-disable-next-line
  console.log(content, styles1, styles2, styles3);
};

const mixins = [HeaderSupport];
const dataIsPowerReviewActive = app.getAttribute('data-is-power-review-active');
if (dataIsPowerReviewActive) {
  mixins.push(PowerReviewsSupport);
}

const Entrypoints = Vue.extend({
  mixins,
  store: store,
  data() {
    return {
      count: 3,
      isPrintPage: false,
      ua: ua,
      isIos: ua.getOS().name === 'iOS',
      hasIosNotch: false,
    };
  },
  watch: {
    $currentScrollPosition: {
      handler() {
        document.documentElement.style.setProperty(
          '--scroll-y',
          `${window.scrollY}px`
        );
      },
      immediate: true,
    },
  },
  //router,
  created() {
    /* eslint-disable */
    DjangoPatch = new VueDjangoCMSPatch(this, '#app-root', Vue);
    /* eslint-enable */

    if (typeof Event === 'function') {
      // modern browsers
      window.dispatchEvent(new Event('vueAppReady'));
    } else {
      //This will be executed on old browsers and especially IE
      var resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('vueAppReady', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }
  },
  mounted() {
    const dpp = this.$el.getAttribute('data-print-page');
    this.mirageKiller();
    this.hasIosNotch = this.isIphoneWithNotch();
    if (dpp) {
      this.isPrintPage = this.$_cmsSupport_castPropToType(
        this.$el.getAttribute('data-print-page'),
        'boolean'
      );
    } else {
      this.isPrintPage = false;
    }
    // we decided to moce to an approch that checks if the loaded component stops changing
    window.lastCycleNumberOfLoadedComponents = window.vueComponentsLib.length;
    window.stopCheckingCount = 24;
    window.currentCount = 0;
    (() => {
      const doCheck = () => {
        if (debug) {
          if (window.currentCount === 0) {
            const percentageCount =
              (window.lastCycleNumberOfLoadedComponents /
                window.vueComponentsLib.length) *
              100;
            const percentage = isNaN(percentageCount) ? 0 : percentageCount;
            const done = parseInt(percentage / 2, 10);
            const missing = 100 / 2 - done;
            const emptyOrFull = percentage === 0 || percentage === 100;
            const label = `Componenti montati -> ${percentage.toFixed(0)}% (${
              window.lastCycleNumberOfLoadedComponents
            } / ${window.vueComponentsLib.length})`;
            const isLabelBeforeBar = false;

            progressBar({
              done,
              missing,
              emptyOrFull,
              label,
              isLabelBeforeBar,
            });
          } else {
            const percentageCount =
              (window.currentCount / window.stopCheckingCount) * 100;
            const percentage = isNaN(percentageCount) ? 0 : percentageCount;
            const done = parseInt(percentage / 2, 10);
            const missing = 100 / 2 - done;
            const emptyOrFull = percentage === 0 || percentage === 100;
            const label = `Stabilizzazione -> ${percentage.toFixed(0)}% (${
              window.currentCount
            } / ${window.stopCheckingCount})`;
            const isLabelBeforeBar = false;
            progressBar({
              done,
              missing,
              emptyOrFull,
              label,
              isLabelBeforeBar,
            });
          }
        }

        if (
          window.vueComponentsLib.length !==
            window.lastCycleNumberOfLoadedComponents ||
          window.currentCount < window.stopCheckingCount
        ) {
          if (
            window.lastCycleNumberOfLoadedComponents ===
              window.vueComponentsLib.length &&
            window.vueComponentsLib.length > 1
          ) {
            window.currentCount = window.currentCount + 1;
          } else {
            window.currentCount = 0;
            window.lastCycleNumberOfLoadedComponents =
              window.vueComponentsLib.length;
          }
          setTimeout(doCheck, 50);
        } else {
          debug &&
            console.log(
              '%c[cmsEntrypointsMixin mounted]%c dispatch -> allComponentsMounted',
              'font-weight:bold;',
              'font-weight:normal;'
            );
          window.dispatchEvent(new Event('allComponentsMounted'));
          //because of the nature of the async load of the chunks the scroll to anchor in chrome
          //do not work properly
          //so we made this workaround to have the scroll
          const anchor = document.getElementById(window.location.hash.slice(1));
          if (anchor) {
            setTimeout(() => {
              window.scrollTo({
                top: anchor.offsetTop - 20,
                //behavior: 'smooth',
              });
            }, 0);
          }
        }
      };
      doCheck();
    })();
  },
  methods: {
    isIphoneWithNotch() {
      //SE             -> 640x1136 @2x
      //6s/7/8         -> 750x1334 @2x
      //6s+/7+/8+      -> 1080x1920 @3x
      //X/XR/11pro     -> 1125x2436 @3x
      //XS/11          -> 828x1792 @3x
      //XSmax/11promax -> 1242x2688 @3x

      const iOS = this.isIos;
      const ratio = window.devicePixelRatio || 1;
      const screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio,
      };

      return (
        (iOS && screen.width === 1125 && screen.height === 2436) || //X & XR & 11 pro
        (iOS && screen.width === 828 && screen.height === 1792) || //xs & 11
        (iOS && screen.width === 1242 && screen.height === 2688) || //xs max & 11 Pro Max
        (iOS && screen.width === 1284 && screen.height === 2778) || //12 Pro Max
        (iOS && screen.width === 1170 && screen.height === 2532) || //12 Pro
        (iOS && screen.width === 1080 && screen.height === 2340)
      ); //12 mini
    },
    mirageKiller() {
      (() => {
        let doCheck = () => {
          // feature working suppressing debug
          // if (debug) {
          //   console.log(
          //     '%c[KILLING MIRAGE]',
          //     'padding:4px;background-color:red;color:white;'
          //   );
          // }
          var images = Array.prototype.slice.call(
              document.getElementsByTagName('img')
            ),
            cfSrc,
            cfStyle;

          for (
            var i = 0, image = images[i];
            i < images.length;
            image = images[++i]
          ) {
            if ('getAttribute' in image) {
              cfSrc = image.getAttribute('data-cfsrc');
              cfStyle = image.getAttribute('data-cfstyle');
            }

            cfSrc = cfSrc || image.attributes['data-cfsrc'];
            cfStyle = cfStyle || image.attributes['data-cfstyle'];

            if (cfSrc) {
              image.src = cfSrc;
            }

            if (cfStyle) {
              image.style.cssText = cfStyle;
            }
          }
          this.count++;
          if (this.count !== 10) {
            setTimeout(doCheck, Math.exp(this.count));
          }
        };
        doCheck();
      })();
    },
  },
});

export default Entrypoints;
