var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{class:[
    'WaGenericCard',
    'cardLink',
    { hasTag: _vm.isVisibleTag, hasBorder: _vm.isVisibleBorder } ],attrs:{"href":_vm.conf.card_href}},[(_vm.conf.card_img_src)?_c('figure',{staticClass:"imgWrapper"},[(_vm.isVisibleTag)?_c('span',{staticClass:"cardLabel"},[_c('wa-tag',{class:[("" + (_vm.conf.label_color)), ("" + (_vm.conf.label_background_color))]},[_vm._v(" "+_vm._s(_vm.conf.label)+" ")])],1):_vm._e(),_c('ratio-size-box',{attrs:{"data-width-ratio-component":_vm.alternativeRatio ? 16 : 4,"data-height-ratio-component":_vm.alternativeRatio ? 9 : 3,"data-vertical-align-content":"center","data-crop":"cover"}},[_c('img',{staticClass:"pic imageHover",attrs:{"src":_vm.conf.card_img_src,"alt":_vm.conf.card_img_alt}})])],1):_vm._e(),_c('div',{staticClass:"contentCard"},[_c(_vm.titleTag,{tag:"component",class:[
        'genericCardTitle',
        'typo-a-5',
        'margin-top-s',
        _vm.conf.card_title_color ]},[_vm._v(" "+_vm._s(_vm.conf.card_title)+" ")]),(_vm.conf.card_small_title)?_c(_vm.titleSmallTag,{tag:"component",class:['smallTitle margin-top-xs', _vm.smallTitleColorClass]},[_vm._v(" "+_vm._s(_vm.titleSmall)+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }