<template>
  <component :is="tag" :class="['WaCell', 'typo-a-7', classes]">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'WaCell',
  props: {
    dataHeader: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    is_header() {
      return typeof this.dataHeader === 'string'
        ? this.dataHeader === 'true'
          ? true
          : false
        : this.dataHeader;
    },
    tag() {
      return this.is_header ? 'th' : 'td';
    },
    classes() {
      return [this.is_header ? 'typo-heavy' : 'typo-light'];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaCell {
  padding: 16px;
  /deep/ p:last-child {
    margin-bottom: 0;
  }
}
</style>
