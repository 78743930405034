<template>
  <div class="WaPm56BrunchInNatureVerify">
    <wa-hero
      data-variant-class="left"
      :data-bg-src-image="
        require('@images/contest-brunch-in-nature/hero-bg.jpg')
      "
      :data-bg-mobile-src-image="
        require('@images/contest-brunch-in-nature/hero-bg-mobile.jpg')
      "
      :data-has-down-arrow="true"
    >
      <h1 slot="title" class="text-color-white">
        {{ i18nParticipate.hero_title }}
      </h1>

      <h4 slot="title-small" class="text-color-white">
        {{ i18nParticipate.hero_title_small }}
      </h4>

      <p slot="description" class="text-color-white">
        {{ i18nParticipate.hero_description }}
      </p>
    </wa-hero>
    <div class="container-fluid padding-top-xxl padding-bottom-xxl">
      <form ref="$form" class="WaPm56BrunchInNatureVerifyForm" @submit.prevent>
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-sport-blue padding-bottom-r"
          >
            <svg class="icon margin-bottom-r">
              <use href="#icon-wasa-boxed" fill="currentColor" />
            </svg>
            <span class="typo-w-4 typo-bold text-center">
              {{ i18nParticipate.city_heading }}
            </span>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto padding-bottom-xl">
            <wa-select
              v-model.trim="$v.city.$model"
              class="medium text-color-blue-logo"
              :data-label="i18nParticipate.city_label"
              :data-show-label="false"
              data-name="city"
              :data-placeholder="i18nParticipate.city_placeholder"
              :data-option-list="brunch_cities"
              :data-error-occurred="$v.city.$error"
              :data-errors="[
                {
                  condition: !$v.city.required,
                  message: i18nParticipate.city_error_required,
                },
                {
                  condition: !$v.city.serverError,
                  message: serverErrors.city,
                },
              ]"
            >
              <template slot="icon">
                <svg width="24" height="24">
                  <use href="#icon-arrow-angle-down" fill="currentColor" />
                </svg>
              </template>
            </wa-select>
          </div>
        </div>
        <div class="row justify-content-center">
          <div
            class="col-12 col-md-10 col-lg-8 d-flex flex-column align-items-center justify-content-center text-color-sport-blue padding-bottom-l"
          >
            <span class="typo-w-4 typo-bold text-center margin-bottom-r">
              {{ i18nParticipate.participate_type_heading }}
            </span>
            <div
              class="typo-a-8 typo-light text-color-grey-9 text-center noMBLastChild"
              v-html="i18nParticipate.participate_type_description"
            />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 padding-bottom-xl">
            <wa-radio-chooser
              v-model.trim="$v.participate_type.$model"
              data-name="participate_type"
              :data-radio-list="[
                {
                  value: 'without_receipt',
                  label: i18nParticipate.participate_type_without_receipt_label,
                  iconId: 'icon-no-option-hand-drawn',
                },
                {
                  value: 'with_receipt',
                  label: i18nParticipate.participate_type_with_receipt_label,
                  iconId: 'icon-receipt-hand-drawn',
                },
              ]"
              :data-error-occurred="$v.participate_type.$error"
              :data-errors="[
                {
                  condition: !$v.participate_type.required,
                  message: i18nParticipate.participate_type_error_required,
                },
                {
                  condition: !$v.participate_type.serverError,
                  message: serverErrors.participate_type,
                },
              ]"
            />
          </div>
        </div>
        <transition name="fade">
          <div v-if="participate_type === 'with_receipt'">
            <div class="row justify-content-center">
              <div
                class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-sport-blue padding-bottom-r"
              >
                <svg class="icon margin-bottom-r">
                  <use href="#icon-wasa-boxed" fill="currentColor" />
                </svg>
                <span class="typo-w-4 typo-bold text-center">
                  {{ i18nParticipate.product_heading }}
                </span>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-auto padding-bottom-xl">
                <wa-select
                  v-model.trim="$v.receipt_product.$model"
                  class="medium text-color-blue-logo"
                  :data-label="i18nParticipate.product_label"
                  :data-show-label="false"
                  data-name="receipt_product"
                  :data-placeholder="i18nParticipate.product_placeholder"
                  :data-option-list="products"
                  :data-error-occurred="$v.receipt_product.$error"
                  :data-errors="[
                    {
                      condition: !$v.receipt_product.required,
                      message: i18nParticipate.product_error_required,
                    },
                    {
                      condition: !$v.receipt_product.serverError,
                      message: serverErrors.receipt_product,
                    },
                  ]"
                >
                  <template slot="icon">
                    <svg width="24" height="24">
                      <use href="#icon-arrow-angle-down" fill="currentColor" />
                    </svg>
                  </template>
                </wa-select>
              </div>
            </div>
            <div class="row justify-content-center">
              <div
                class="col-12 col-md-10 col-lg-8 d-flex flex-column align-items-center justify-content-center text-color-sport-blue padding-bottom-l"
              >
                <span class="typo-w-4 typo-bold text-center margin-bottom-r">
                  {{ i18nParticipate.shop_type_heading }}
                </span>
                <div
                  class="typo-a-8 typo-light text-color-grey-9 text-center noMBLastChild"
                  v-html="i18nParticipate.shop_type_description"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-10 col-lg-8">
                <wa-radio-chooser
                  v-model.trim="$v.receipt_type.$model"
                  data-name="receipt_type"
                  :data-radio-list="[
                    {
                      value: 'standard',
                      label: i18nParticipate.shop_type_store_label,
                      iconId: 'icon-market',
                    },
                    {
                      value: 'electronic',
                      label: i18nParticipate.shop_type_online_label,
                      iconId: 'icon-world-hand-drawn',
                    },
                  ]"
                  :data-error-occurred="$v.receipt_type.$error"
                  :data-errors="[
                    {
                      condition: !$v.receipt_type.required,
                      message: i18nParticipate.shop_type_error_required,
                    },
                    {
                      condition: !$v.receipt_type.serverError,
                      message: serverErrors.receipt_type,
                    },
                  ]"
                  @change="onSetShopType"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-10 col-lg-8 padding-top-r">
                <wa-dropdown
                  v-model.trim="$v.receipt_store.$model"
                  data-name="receipt_store"
                  :data-label="i18nParticipate.shop_label"
                  :data-placeholder="i18nParticipate.shop_placeholder"
                  :data-option-list="filteredShops"
                  :data-error-occurred="$v.receipt_store.$error"
                  :data-errors="[
                    {
                      condition: !$v.receipt_store.required,
                      message: i18nParticipate.shop_error_required,
                    },
                    {
                      condition: !$v.receipt_store.serverError,
                      message: serverErrors.receipt_store,
                    },
                  ]"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-10 col-lg-8">
                <wa-regular-divider class="padding-top-m padding-bottom-m" />
              </div>
            </div>
            <div class="row justify-content-center">
              <div
                class="col-12 col-md-10 col-lg-8 d-flex flex-column padding-bottom-xs"
              >
                <span
                  class="typo-a-6 typo-extra-bold margin-bottom-r text-color-grey-9"
                >
                  {{ i18nParticipate.picture_heading }}
                </span>
                <span class="typo-a-9 typo-medium text-color-grey-8">
                  {{ i18nParticipate.picture_description }}
                </span>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-10 col-lg-8 padding-bottom-r">
                <wa-upload
                  ref="$waUpload"
                  v-model.trim="$v.receipt_front_photo.$model"
                  name="receipt_front_photo"
                  :label="i18nParticipate.picture_label"
                  :label-smaller="i18nParticipate.picture_label_small"
                  :label-on-hover="i18nParticipate.picture_label_hover"
                  :cta-text="i18nParticipate.picture_cta"
                  :helper-text="i18nParticipate.picture_helper"
                  :helper-text-smaller="i18nParticipate.picture_helper_small"
                  :data-error-occurred="$v.receipt_front_photo.$error"
                  :errors="[
                    {
                      condition: !$v.receipt_front_photo.required,
                      message: i18nParticipate.picture_error_required,
                    },
                    {
                      condition: !$v.receipt_front_photo.mustBeImage,
                      message: i18nParticipate.picture_error_format,
                    },
                    {
                      condition: !$v.receipt_front_photo.validSize,
                      message: i18nParticipate.picture_error_size,
                    },
                    {
                      condition: !$v.receipt_front_photo.serverError,
                      message: serverErrors.receipt_front_photo,
                    },
                  ]"
                  @change="onFileChange"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 padding-bottom-m">
                <wa-accordion>
                  <wa-accordion-item
                    class="text-color-blue-logo margin-bottom-0"
                  >
                    <template slot="title">
                      <h4 class="typo-a-6 typo-medium margin-bottom-0">
                        {{ i18nParticipate.picture_instruction_heading }}
                      </h4>
                    </template>
                    <template slot="content">
                      <wa-free-text>
                        <template slot="content_wysiwyg">
                          <div
                            class="padding-top-m padding-bottom-m text-center"
                          >
                            <img
                              v-if="receiptImg"
                              :src="receiptImg"
                              class="img-fluid"
                            />
                          </div>
                          <ol class="margin-top-0 margin-bottom-0">
                            <li
                              class="typo-a-7 typo-medium text-color-sport-blue"
                            >
                              {{ i18nParticipate.picture_instruction_legend_1 }}
                            </li>
                            <li
                              class="typo-a-7 typo-medium text-color-sport-blue"
                            >
                              {{ i18nParticipate.picture_instruction_legend_2 }}
                            </li>
                            <li
                              class="typo-a-7 typo-medium text-color-sport-blue"
                            >
                              {{ i18nParticipate.picture_instruction_legend_3 }}
                            </li>
                            <li
                              class="typo-a-7 typo-medium text-color-sport-blue"
                            >
                              {{ i18nParticipate.picture_instruction_legend_4 }}
                            </li>
                            <li
                              class="typo-a-7 typo-medium text-color-sport-blue margin-bottom-0"
                            >
                              {{ i18nParticipate.picture_instruction_legend_5 }}
                            </li>
                          </ol>
                          <wa-regular-divider
                            class="padding-top-r padding-bottom-r"
                          />
                          <ul class="margin-top-0 margin-bottom-0">
                            <li class="typo-a-7 typo-light text-color-black">
                              {{ i18nParticipate.picture_instruction_hint_1 }}
                            </li>
                            <li class="typo-a-7 typo-light text-color-black">
                              {{ i18nParticipate.picture_instruction_hint_2 }}
                            </li>
                            <li
                              class="typo-a-7 typo-light text-color-black margin-bottom-0"
                            >
                              {{ i18nParticipate.picture_instruction_hint_3 }}
                            </li>
                          </ul>
                          <wa-regular-divider
                            class="padding-top-r padding-bottom-r"
                          />
                          <p>
                            <span
                              class="d-block typo-a-7 typo-medium text-color-grey-8 padding-bottom-xs"
                            >
                              {{
                                i18nParticipate.picture_instruction_info_heading
                              }}
                            </span>
                            <span
                              class="d-block typo-a-9 typo-light text-color-black padding-bottom-xs"
                            >
                              {{ i18nParticipate.picture_instruction_info_1 }}
                            </span>
                            <span
                              class="d-block typo-a-9 typo-light text-color-black"
                            >
                              {{ i18nParticipate.picture_instruction_info_2 }}
                            </span>
                          </p>
                        </template>
                      </wa-free-text>
                    </template>
                  </wa-accordion-item>
                </wa-accordion>
              </div>
            </div>
          </div>
        </transition>
        <div v-if="show_rules" class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 padding-bottom-m">
            <wa-checkbox
              v-model.trim="$v.contest_rules_accepted.$model"
              data-name="contest_rules_accepted"
              :data-checkbox-list="[
                {
                  label: rulesLabel,
                },
              ]"
              :data-error-occurred="$v.contest_rules_accepted.$error"
              :data-errors="[
                {
                  condition: !$v.contest_rules_accepted.mustBeChecked,
                  message: i18nParticipate.rules_error_required,
                },
                {
                  condition: !$v.contest_rules_accepted.serverError,
                  message: serverErrors.contest_rules_accepted,
                },
              ]"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <div class="d-block d-sm-inline-block">
              <wa-cta
                class="primary"
                data-type="submit"
                :data-icon-id="spinner ? 'icon-ellipsis-loader' : ''"
                :data-disabled="disableSubmit || submitted || !canParticipate"
                @click.prevent="onSubmit"
              >
                {{ i18nParticipate.submit_cta }}
              </wa-cta>
              <span
                v-for="(error, i) in hasGlobalErrors"
                :key="`error-${i}-${_uid}`"
                class="global-error typo-a-10 typo-medium"
                v-html="error.message"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { helpers, required } from 'vuelidate/lib/validators';
import LanguageSupport from '@mixins/language-support';
import { debug } from '@helpers/utils';
import brunchCityFixture from '@fixtures/brunch-city-fixture';
import axios from 'axios';

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'WaPm56BrunchInNatureVerify',
  mixins: [LanguageSupport],
  data() {
    return {
      canParticipate: false,
      brunch_cities: null,
      products: null,
      shops: null,
      show_rules: false,
      /* form field */
      city: null,
      participate_type: 'with_receipt',
      receipt_product: null,
      receipt_type: null,
      receipt_store: null,
      receipt_front_photo: null,
      contest_rules_accepted: false,
      /* after submit */
      submitted: false,
      spinner: false,
      serverErrors: {},
      globalErrors: [],
    };
  },
  computed: {
    ...mapState(['storeSetupCompleted']),
    ...mapState('gigya', ['loaded', 'user']),
    ...mapState('pm56BrunchInNature', [
      'legal-age',
      'brunch-cities-api',
      'products-api',
      'stores-api',
      'privacy-check',
      'join-api',
    ]),
    ...mapGetters('pm56BrunchInNature', ['i18nParticipate']),
    receiptImg() {
      try {
        return require(`@images/contest-brunch-in-nature/receipt-img-${this.currentLang}.png`);
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    disableSubmit() {
      if (this.$v && (this.$v.$anyError || this.$v.$invalid)) {
        return true;
      }

      return false;
    },
    filteredShops() {
      return this.shops && this.receipt_type
        ? this.shops
            .filter((e) =>
              this.receipt_type === 'electronic' ? e.is_online : !e.is_online
            )
            .map((e) => {
              return { key: e.id, dataValue: e.name };
            })
        : [];
    },
    rulesLabel() {
      // reworked in component library
      let k = 0;
      let j = 0;
      return this.i18nParticipate.rules_label
        .replace(/%/g, () => {
          k++;
          if (k === 1) {
            return `<a class="typo-medium text-color-blue-logo" href='${this.i18nParticipate.rules_href}' target='_blank'>`;
          } else {
            return '</a>';
          }
        })
        .replace(/£/g, () => {
          j++;
          if (j === 1) {
            return `<a class="typo-medium text-color-blue-logo" href='${this.i18nParticipate.privacy_href}' target='_blank'>`;
          } else {
            return '</a>';
          }
        });
    },
    hasGlobalErrors() {
      if (this.globalErrors.length) {
        return this.globalErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  watch: {
    storeSetupCompleted: {
      handler(val) {
        debug && console.log('WATCH storeSetupCompleted', val);
        if (val) {
          if (this['brunch-cities-api']) {
            axios
              .get(this['brunch-cities-api'])
              .then((response) => {
                if (response.data) {
                  this.brunch_cities = response.data.data.map((e) => {
                    return { key: e.id, dataValue: e.name };
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            this.brunch_cities = brunchCityFixture.data.map((e) => {
              return { key: e.id, dataValue: e.name };
            });
          }

          const endpointProducts = this['products-api']
            ? this['products-api']
            : 'https://run.mocky.io/v3/8273bebc-3996-4e14-bc97-dddef7273caf';

          axios
            .get(endpointProducts)
            .then((response) => {
              if (response.data) {
                this.products = response.data.data.map((e) => {
                  return { key: e.id, dataValue: e.name };
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });

          const endpointStores = this['stores-api']
            ? this['stores-api']
            : 'https://run.mocky.io/v3/6e69bc05-dbe4-4ae7-a5da-1464ce9e24b6';

          axios
            .get(endpointStores)
            .then((response) => {
              if (response.data) {
                this.shops = response.data.data;
              }
            })
            .catch((e) => {
              console.log(e);
            });

          axios
            .get(this['privacy-check'])
            .then((response) => {
              if (response.data) {
                this.show_rules = !response.data.contest_rule_flag; // return true if the user has already accepted the rules, false otherwise, show the rules only when the returned value is false
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      },
      immediate: true,
    },
    loaded: {
      handler(val) {
        if (val) {
          if (!this.user) {
            this.globalErrors.push({
              condition: true,
              message: this.i18nParticipate.global_error_logged,
            });
          } else if (this.user.age < this['legal-age']) {
            this.globalErrors.push({
              condition: true,
              message: this.i18nParticipate.global_error_legal_age,
            });
          } else {
            this.canParticipate = true;
          }
        }
      },
      immediate: true,
    },
  },
  validations() {
    let validations = {
      city: {
        required,
        serverError: checkServerErrors('city'),
      },
      participate_type: {
        required,
        serverError: checkServerErrors('participate_type'),
      },
    };

    if (this.participate_type === 'with_receipt') {
      validations = {
        ...validations,
        receipt_product: {
          required,
          serverError: checkServerErrors('receipt_product'),
        },
        receipt_type: {
          required,
          serverError: checkServerErrors('receipt_type'),
        },
        receipt_store: {
          required,
          serverError: checkServerErrors('receipt_store'),
        },
        receipt_front_photo: {
          required,
          mustBeImage: (val) => {
            return (
              !helpers.req(val) ||
              val.type === 'image/jpeg' ||
              val.type === 'image/jpg' ||
              val.type === 'image/png'
            );
          },
          validSize: (val) => {
            const mb = 3 * 1024 * 1024;
            return !helpers.req(val) || val.size <= mb;
          },
          serverError: checkServerErrors('receipt_front_photo'),
        },
      };
    } else {
      validations = {
        ...validations,
        receipt_product: {},
        receipt_type: {},
        receipt_store: {},
        receipt_front_photo: {},
      };
    }
    if (this.show_rules) {
      validations = {
        ...validations,
        contest_rules_accepted: {
          mustBeChecked: (val) => !!val,
          serverError: checkServerErrors('contest_rules_accepted'),
        },
      };
    } else {
      validations = {
        ...validations,
        contest_rules_accepted: {},
      };
    }

    return validations;
  },
  created() {
    this.setInvertedHeader(true);
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    onSetShopType() {
      this.receipt_store = null;
    },
    onFileChange(f) {
      this.receipt_front_photo = f;
      this.$v.receipt_front_photo.$touch();
    },
    async onSubmit() {
      if (!this.submitted && !this.disableSubmit) {
        this.submitted = true;
        this.spinner = true;
        this.globalErrors = [];

        try {
          let formData = new FormData();
          this.city && formData.append('city', this.city);
          formData.append(
            'is_receipt_flow',
            this.participate_type === 'with_receipt'
          );
          if (this.participate_type === 'with_receipt') {
            this.receipt_product &&
              formData.append('receipt_product', this.receipt_product);
            this.receipt_type &&
              formData.append('receipt_type', this.receipt_type);
            this.receipt_store &&
              formData.append('receipt_store', this.receipt_store);
            this.$refs.$waUpload.imagePreview &&
              formData.append(
                'receipt_front_photo',
                this.$refs.$waUpload.imagePreview
              );
          }
          formData.append(
            'contest_rules_accepted',
            this.contest_rules_accepted
          );

          let result = await axios.post(this['join-api'], formData);

          if (result.data) {
            // Contest completed
            await this.$store.dispatch('setUnlockedView', 'thank-you');
            // Navigate
            await this.$router.push({ name: 'thank-you' });
          }
        } catch (e) {
          let properties = Object.getOwnPropertyNames(e);
          properties.forEach((p) => {
            let prop = Object.getOwnPropertyDescriptor(e, p);
            if (prop && prop.value) {
              if (p === 'response') {
                if (prop.value.status === 400) {
                  // Validation errors
                  /* expected response
                  {"username":["Questo campo non può essere omesso","Questo campo non può essere omesso 2"],"description":["Questo campo non può essere omesso"]};
                   */
                  let remoteErrors = false;
                  let data = prop.value.data;
                  if ('errors' in data) {
                    remoteErrors = true;
                    data = data.errors;
                  }

                  for (const [property, value] of Object.entries(data)) {
                    if (property == 'non_field_errors') {
                      // Global validation errors
                      this.globalErrors.push({
                        condition: true,
                        message: this.i18nParticipate[value]
                          ? this.i18nParticipate[value]
                          : value,
                      });
                    } else {
                      let temp = {};
                      if (!remoteErrors) {
                        temp[property] = this.i18nParticipate[value]
                          ? this.i18nParticipate[value]
                          : value;
                      } else {
                        temp[property] = value;
                      }
                      this.serverErrors = { ...this.serverErrors, ...temp };
                    }
                  }
                  Object.keys(this.serverErrors).forEach((key) => {
                    const unwatch = this.$watch(key, (newVal, oldVal) => {
                      if (newVal !== oldVal) {
                        Vue.delete(this.serverErrors, key);
                        setTimeout(() => {
                          this.$v[key].$touch();
                        }, 0);
                        if (unwatch) {
                          unwatch();
                        }
                      }
                    });
                  });

                  // Renew the submission
                } else if (
                  prop.value.status === 401 ||
                  prop.value.status === 403
                ) {
                  this.globalErrors.push({
                    condition: true,
                    message: prop.value.data.message,
                  });
                } else {
                  // Other errors
                  this.globalErrors.push({
                    condition: true,
                    message: prop.value.statusText,
                  });
                }
                this.submitted = false;
              }
            }
          });
        } finally {
          this.spinner = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaPm56BrunchInNatureVerify {
  .icon {
    width: 48px;
    height: 48px;

    @media (min-width: $bootstrap-md) {
      width: 64px;
      height: 64px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .img-fluid {
    @include media-breakpoint-up(sm) {
      width: 70%;
    }
  }

  .global-error {
    display: block;
    padding-top: 14px;
    color: $color-informative-red;
    text-align: left;
  }
}
</style>
