<template>
  <div class="WaPm56BiscuitsPrize">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WaPm56BiscuitsPrize',
  props: {
    dataContest: {
      type: [Object, String],
      required: true,
    },
    dataRegex: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    i18n() {
      return this.$_cmsSupport_castPropToType(this.dataContest, 'object');
    },
    regex() {
      return this.$_cmsSupport_castPropToType(this.dataRegex, 'object');
    },
  },
  created() {
    this.setPm56BiscuitsI18nPrize(this.i18n);
    this.setPm56BiscuitsRegex(this.regex);
  },
  methods: {
    ...mapActions('pm56Biscuits', [
      'setPm56BiscuitsI18nPrize',
      'setPm56BiscuitsRegex',
    ]),
  },
};
</script>

<style scoped></style>
