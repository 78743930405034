<template>
  <div class="PrCategoryReviewWrapper">
    <slot />
    <div :id="`category-snippet-${_uid}`"></div>
  </div>
</template>

<script>
export default {
  name: 'PrCategoryReviewWrapper',
  props: {
    dataItem: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    item() {
      return typeof this.dataItem === 'string'
        ? JSON.parse(this.dataItem)
        : this.dataItem;
    },
  },
  mounted() {
    this.$watch(
      () => this.$root.arePowerReviewConfsReady,
      (confsAreReady) => {
        if (confsAreReady) {
          this.addPrWidget();
        }
      },
      { immediate: true }
    );
  },
  beforeDestroy() {
    this.$root.setWidgetsToUnmount([
      ...this.$root.getWidgetsToUnmount(),
      `category-snippet-${this._uid}`,
    ]);
  },
  methods: {
    addPrWidget() {
      //272079137513752 	Product with review -> Sesam & Vollkorn
      //'Portobello trifft Grünkohl' -> recipe with review
      if (this.item && this.item.detail_page_id) {
        this.$root.setWidgetsToMount([
          ...this.$root.getWidgetsToMount(),
          {
            ...this.$root.getCategorySnippetBaseConf(),
            page_id: this.item.detail_page_id,
            components: {
              CategorySnippet: `category-snippet-${this._uid}`,
            },
          },
        ]);
      }
    },
  },
};
</script>
