<template>
  <div class="WaPmextraInstagramRedeem">
    <div v-if="showPage">
      <wa-hero
        data-variant-class="left"
        :data-bg-src-image="
          require('@images/contest-brunch-in-nature/redeem-hero-bg.jpg')
        "
        :data-bg-mobile-src-image="
          require('@images/contest-brunch-in-nature/redeem-hero-bg-mobile.jpg')
        "
        :data-has-down-arrow="true"
      >
        <h1 slot="title" class="text-color-white">
          {{ i18nPrize.hero_title }}
        </h1>

        <h4 slot="title-small" class="text-color-white">
          {{ i18nPrize.hero_small_title }}
        </h4>

        <p slot="description" class="text-color-white">
          {{ i18nPrize.hero_description }}
        </p>
      </wa-hero>
      <div class="container-fluid padding-top-xxl padding-bottom-xxl">
        <h3
          class="text-color-secondary-item text-center typo-a-4 typo-extra-bold margin-bottom-0"
        >
          {{ i18nPrize.title }}
        </h3>
        <p class="text-center typo-a-7 typo-light margin-top-m margin-bottom-m">
          {{ i18nPrize.description }}
        </p>
        <form ref="$form" class="WaPmextraInstagramRedeemForm" @submit.prevent>
          <div class="row justify-content-center">
            <div class="col-12 col-md-4 padding-bottom-r">
              <wa-input
                v-model.trim="$v.first_name.$model"
                :data-label="i18nPrize.name_label"
                data-name="first_name"
                :data-error-occurred="$v.first_name.$error"
                :data-errors="[
                  {
                    condition: !$v.first_name.required,
                    message: i18nPrize.name_error_required,
                  },
                  {
                    condition: !$v.first_name.minLength,
                    message: i18nPrize.name_error_minLength,
                  },
                  {
                    condition: !$v.first_name.maxLength,
                    message: i18nPrize.name_error_maxLength,
                  },
                  {
                    condition: !$v.first_name.regex,
                    message: i18nPrize.name_error_regex,
                  },
                  {
                    condition: !$v.first_name.serverError,
                    message: serverErrors.first_name,
                  },
                ]"
              />
            </div>
            <div class="col-12 col-md-4 padding-bottom-r">
              <wa-input
                v-model.trim="$v.last_name.$model"
                :data-label="i18nPrize.surname_label"
                data-name="last_name"
                :data-error-occurred="$v.last_name.$error"
                :data-errors="[
                  {
                    condition: !$v.last_name.required,
                    message: i18nPrize.surname_error_required,
                  },
                  {
                    condition: !$v.last_name.minLength,
                    message: i18nPrize.surname_error_minLength,
                  },
                  {
                    condition: !$v.last_name.maxLength,
                    message: i18nPrize.surname_error_maxLength,
                  },
                  {
                    condition: !$v.last_name.regex,
                    message: i18nPrize.surname_error_regex,
                  },
                  {
                    condition: !$v.last_name.serverError,
                    message: serverErrors.last_name,
                  },
                ]"
              />
            </div>
          </div>
          <div class="row align-items-start justify-content-center">
            <div
              class="col-12 col-md-4 padding-bottom-r d-flex align-items-center"
            >
              <svg
                v-tooltip.auto="{
                  content: loggedDataTooltip,
                  autoHide: true,
                  hideOnTargetClick: true,
                  trigger: 'hover click',
                }"
                class="info"
              >
                <use href="#icon-info-circle" fill="currentColor" />
              </svg>
              <wa-input
                v-model.trim="$v.email.$model"
                :data-label="i18nPrize.email_label"
                data-name="email"
                :data-disabled="true"
              />
            </div>
            <div class="col-12 col-md-4 padding-bottom-r phone-container">
              <wa-input
                v-model.trim="$v.phone_prefix.$model"
                :data-label="i18nPrize.prefix_label"
                data-name="phone_prefix"
                class="phone-prefix"
                :data-disabled="true"
              />
              <wa-input
                v-model.trim="$v.phone_number.$model"
                :data-label="i18nPrize.phone_label"
                data-name="phone_number"
                class="phone-number"
                :data-error-occurred="$v.phone_number.$error"
                :data-errors="[
                  {
                    condition: !$v.phone_number.required,
                    message: i18nPrize.phone_error_required,
                  },
                  {
                    condition: !$v.phone_number.minLength,
                    message: i18nPrize.phone_error_minLength,
                  },
                  {
                    condition: !$v.phone_number.maxLength,
                    message: i18nPrize.phone_error_maxLength,
                  },
                  {
                    condition: !$v.phone_number.regex,
                    message: i18nPrize.phone_error_regex,
                  },
                  {
                    condition: !$v.phone_number.serverError,
                    message: serverErrors.phone_number,
                  },
                ]"
              />
            </div>
          </div>
          <div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-4 padding-bottom-r">
                <wa-input
                  v-model.trim="$v.province.$model"
                  :data-label="i18nPrize.province_label"
                  data-name="province"
                  :data-error-occurred="$v.province.$error"
                  :data-errors="[
                    {
                      condition: !$v.province.required,
                      message: i18nPrize.name_error_required,
                    },
                    {
                      condition: !$v.province.serverError,
                      message: serverErrors.first_name,
                    },
                  ]"
                />
              </div>
              <div class="col-12 col-md-4 padding-bottom-r">
                <wa-input
                  v-model.trim="$v.city.$model"
                  :data-label="i18nPrize.city_label"
                  data-name="city"
                  :data-error-occurred="$v.city.$error"
                  :data-errors="[
                    {
                      condition: !$v.city.required,
                      message: i18nPrize.name_error_required,
                    },
                    {
                      condition: !$v.city.serverError,
                      message: serverErrors.first_name,
                    },
                  ]"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-4 padding-bottom-r">
                <wa-input
                  v-model.trim="$v.postal_code.$model"
                  :data-label="i18nPrize.postal_code_label"
                  data-name="postal_code"
                  :data-error-occurred="$v.postal_code.$error"
                  :data-errors="[
                    {
                      condition: !$v.postal_code.required,
                      message: i18nPrize.postal_code_error_required,
                    },
                    {
                      condition: !$v.postal_code.regex,
                      message: i18nPrize.postal_code_error_regex,
                    },
                    {
                      condition: !$v.postal_code.serverError,
                      message: serverErrors.postal_code,
                    },
                  ]"
                />
              </div>
              <div class="col-12 col-md-4 padding-bottom-r">
                <wa-input
                  v-model.trim="$v.address.$model"
                  :data-label="i18nPrize.address_label"
                  data-name="address"
                  :data-error-occurred="$v.address.$error"
                  :data-errors="[
                    {
                      condition: !$v.address.required,
                      message: i18nPrize.address_error_required,
                    },
                    {
                      condition: !$v.address.minLength,
                      message: i18nPrize.address_error_minLength,
                    },
                    {
                      condition: !$v.address.maxLength,
                      message: i18nPrize.address_error_maxLength,
                    },
                    {
                      condition: !$v.address.regex,
                      message: i18nPrize.address_error_regex,
                    },
                    {
                      condition: !$v.address.serverError,
                      message: serverErrors.address,
                    },
                  ]"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-4 padding-bottom-r">
                <wa-input
                  v-model.trim="$v.house_number.$model"
                  :data-label="i18nPrize.house_number_label"
                  data-name="house_number"
                  :data-error-occurred="$v.house_number.$error"
                  :data-errors="[
                    {
                      condition: !$v.house_number.required,
                      message: i18nPrize.house_number_error_required,
                    },
                    {
                      condition: !$v.house_number.serverError,
                      message: serverErrors.house_number,
                    },
                  ]"
                />
              </div>
              <div class="col-12 col-md-4 padding-bottom-r">
                <wa-input
                  v-model.trim="$v.intercom.$model"
                  :data-label="i18nPrize.intercom_label"
                  data-name="intercom"
                  :data-error-occurred="$v.intercom.$error"
                  :data-errors="[
                    {
                      condition: !$v.intercom.serverError,
                      message: serverErrors.intercom,
                    },
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-8 padding-top-xs padding-bottom-m">
              <span class="typo-a-9 typo-light text-color-grey-8">
                {{ i18nPrize.mandatory_field_indication_placeholder }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <div class="d-block d-sm-inline-block">
                <wa-cta
                  class="primary"
                  data-type="submit"
                  :data-icon-id="spinner ? 'icon-ellipsis-loader' : ''"
                  :data-disabled="disableSubmit || submitted"
                  @click.prevent="onSubmit"
                >
                  {{ i18nPrize.submit_cta }}
                </wa-cta>
                <span
                  v-for="(error, i) in hasGlobalErrors"
                  :key="`error-${i}-${_uid}`"
                  class="global-error typo-a-10 typo-medium"
                  v-html="error.message"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
      <div class="padding-top-xxl padding-bottom-xxl">
        <div class="dimmer" />
        <div class="loader" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import LanguageSupport from '@mixins/language-support';
import { debug } from '@helpers/utils';
import provinceFixture from '@fixtures/province-fixture';
import cityFixture from '@fixtures/city-fixture';
import axios from 'axios';

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'WaPmextraInstagramRedeem',
  mixins: [LanguageSupport],
  data() {
    return {
      showPage: false,

      provinceList: null,
      cityList: null,
      /* form field */
      first_name: null,
      last_name: null,
      email: null,
      phone_prefix: null,
      phone_number: null,
      province: null,
      city: null,
      postal_code: null,
      address: null,
      house_number: null,
      intercom: null,
      /* after submit */
      submitted: false,
      spinner: false,
      serverErrors: {},
      globalErrors: [],
    };
  },
  computed: {
    ...mapState(['storeSetupCompleted']),
    ...mapState('config', ['home_page_url', 'apis']),
    ...mapState('gigya', ['loaded', 'user']),
    ...mapState('pmextraInstagram', [
      'check-completed-redeem-api',
      'join-api',
      'redeem-prize-page-url',
    ]),
    ...mapGetters('pmextraInstagram', ['i18nPrize']),
    extraInstagram() {
      return this.dataExtraInstagram
        ? this.$_cmsSupport_castPropToType(this.dataextraInstagram, 'object')
        : null;
    },
    disableSubmit() {
      if (this.$v && (this.$v.$anyError || this.$v.$invalid)) {
        return true;
      }

      return false;
    },

    loggedDataTooltip() {
      return (
        '<p style="font-size: 1rem; line-height: 1; margin: 0; padding: 10px 5px;">' +
        this.i18nPrize.tooltip_personal_area_text +
        '</p>'
      );
    },
    hasGlobalErrors() {
      if (this.globalErrors.length) {
        return this.globalErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  watch: {
    storeSetupCompleted: {
      handler(val) {
        debug && console.log('WATCH storeSetupCompleted', val);
        if (val) {
          this.phone_prefix = this.i18nPrize.prefix_default;
        }
      },
      immediate: true,
    },
    loaded: {
      async handler(val) {
        if (val) {
          // se sono pronto
          try {
            const editModeCheckRequest = await axios.get(
              this.apis['edit-mode-api']
            );
            if (editModeCheckRequest.data.edit_mode) {
              // sono in edit mode
              this.handlePage(true);
            } else {
              // sono sul sito pubblico
              this.handlePage(false);
            }
          } catch (e) {
            debug && console.log(e);
          }
        }
      },
      immediate: true,
    },
    filteredCity: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.city = null;
        }
      },
    },
  },
  validations() {
    let validations = {
      first_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(60),
        // eslint-disable-next-line no-misleading-character-class
        regex: (val) =>
          /^[a-zA-ZàáâäãåèéêëìíîïòóôöõøùúûüÿýñçčšžœæÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆČŠŽ∂ð .'-]+$/.test(
            val
          ),
        serverError: checkServerErrors('first_name'),
      },
      last_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(60),
        // eslint-disable-next-line no-misleading-character-class
        regex: (val) =>
          /^[a-zA-ZàáâäãåèéêëìíîïòóôöõøùúûüÿýñçčšžœæÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆČŠŽ∂ð .'-]+$/.test(
            val
          ),
        serverError: checkServerErrors('last_name'),
      },
      email: {},
      phone_prefix: {},
      phone_number: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(18),
        regex: (val) => /^[0-9 ]+$/.test(val),
        serverError: checkServerErrors('phone_number'),
      },
    };

    validations = {
      ...validations,
      province: {
        required,
        serverError: checkServerErrors('province'),
      },
      city: {
        required,
        serverError: checkServerErrors('city'),
      },
      postal_code: {
        required,
        regex: (val) => /^\d{5}(?:[-\s]\d{4})?$/.test(val),
        serverError: checkServerErrors('postal_code'),
      },
      address: {
        required,
        serverError: checkServerErrors('address'),
      },
      house_number: {
        required,
        serverError: checkServerErrors('house_number'),
      },
      intercom: {
        serverError: checkServerErrors('intercom'),
      },
    };

    return validations;
  },
  created() {
    this.setInvertedHeader(true);
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    async handlePage(editMode) {
      if (!this.user) {
        this.showPage = true;
        this.globalErrors.push({
          condition: true,
          message: this.i18nPrize.global_error_logged,
        });
      } else {
        try {
          const result = await axios.get(this['check-completed-redeem-api']);

          if (result && 'has_done_registration' in result?.data) {
            const redirectToThankYouPage = result.data.has_done_registration;
            if (redirectToThankYouPage) {
              await this.redirectToPage();
              this.showPage = true;
            } else {
              this.showPage = true;
            }
          } else {
            //alert('check-completed-redeem-api returned not compatible payload')
            window.location.href = this['redeem-prize-page-url'];
          }
        } catch (e) {
          //alert('check-completed-redeem-api logic crash')
          window.location.href = this['redeem-prize-page-url'];
        }

        if (this.user.firstName) {
          this.first_name = this.user.firstName;
        }

        if (this.user.lastName) {
          this.last_name = this.user.lastName;
        }

        if (this.user.email) {
          this.email = this.user.email;
        }
      }
    },
    groupByProvinceReduce(data) {
      return data.reduce((acc, curr) => {
        const currentProv = acc[+curr.province_id];
        if (!currentProv) {
          acc[+curr.province_id] = [curr];
        } else {
          acc[+curr.province_id].push(curr);
        }

        return acc;
      }, {});
    },
    async redirectToPage() {
      // Already Redeemed or just redeemed after successfully form submit
      await this.$store.dispatch('setUnlockedView', 'redeemed');
      // Navigate
      await this.$router.push({ name: 'redeemed' });
    },
    async onSubmit() {
      if (!this.submitted && !this.disableSubmit) {
        this.submitted = true;
        this.spinner = true;
        this.globalErrors = [];

        try {
          let formData = new FormData();
          formData.append('first_name', this.first_name);
          formData.append('last_name', this.last_name);
          this.phone_prefix &&
            this.phone_number &&
            formData.append('phone', this.phone_prefix + this.phone_number);
          formData.append('province', this.province);
          formData.append('city', this.city);
          formData.append('postal_code', this.postal_code);
          formData.append('address', this.address);
          formData.append('house_number', this.house_number);
          formData.append('interphone_name', this.intercom);

          let result = await axios.patch(this['join-api'], formData);

          if (result.data) {
            await this.redirectToPage();
          }
        } catch (e) {
          let properties = Object.getOwnPropertyNames(e);
          properties.forEach((p) => {
            let prop = Object.getOwnPropertyDescriptor(e, p);
            if (prop && prop.value) {
              if (p === 'response') {
                if (prop.value.status === 400) {
                  // Validation errors
                  /* expected response
                  {"username":["Questo campo non può essere omesso","Questo campo non può essere omesso 2"],"description":["Questo campo non può essere omesso"]};
                   */
                  let remoteErrors = false;
                  let data = prop.value.data;
                  if ('errors' in data) {
                    remoteErrors = true;
                    data = data.errors;
                  }
                  for (const [property, value] of Object.entries(data)) {
                    if (property == 'non_field_errors') {
                      // Global validation errors
                      this.globalErrors.push({
                        condition: true,
                        message: this.i18nPrize[value]
                          ? this.i18nPrize[value]
                          : value,
                      });
                    } else {
                      let temp = {};
                      if (!remoteErrors) {
                        temp[property] = this.i18nPrize[value]
                          ? this.i18nPrize[value]
                          : value;
                      } else {
                        temp[property] = value;
                      }
                      this.serverErrors = { ...this.serverErrors, ...temp };
                    }
                  }
                  Object.keys(this.serverErrors).forEach((key) => {
                    const unwatch = this.$watch(key, (newVal, oldVal) => {
                      if (newVal !== oldVal) {
                        Vue.delete(this.serverErrors, key);
                        setTimeout(() => {
                          this.$v[key].$touch();
                        }, 0);
                        if (unwatch) {
                          unwatch();
                        }
                      }
                    });
                  });

                  // Renew the submission
                  this.submitted = false;
                } else if (
                  prop.value.status === 401 ||
                  prop.value.status === 403
                ) {
                  this.globalErrors.push({
                    condition: true,
                    message: prop.value.data.message,
                  });
                } else {
                  // Other errors
                  this.globalErrors.push({
                    condition: true,
                    message: prop.value.statusText,
                  });
                }
              }
            }
          });
        } finally {
          this.spinner = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaPmextraInstagramRedeem {
  .dimmer {
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw !important;
    height: 100vh !important;
  }

  .loader {
    height: 48px !important;
    width: 48px !important;
    color: rgba(255, 255, 255, 1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: rgba(0, 0, 0, 0.8);
    animation: rotate 1s linear infinite;
    min-width: 0;
    min-height: 0;
    background: none;
  }

  @keyframes rotate {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0);
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(360deg);
    }
  }

  .icon {
    width: 48px;
    height: 48px;

    @media (min-width: $bootstrap-md) {
      width: 64px;
      height: 64px;
    }
  }

  /deep/ svg.info {
    color: $color-blue-logo;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
  }

  /deep/ .phone-container {
    display: flex;

    .phone-prefix {
      max-width: 70px;
      flex-grow: 0;

      input[type='text'] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0 !important;
      }
    }

    .phone-number {
      width: auto;
      flex-grow: 1;

      input[type='text'] {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .global-error {
    display: block;
    padding-top: 14px;
    color: $color-informative-red;
    text-align: left;
  }
}
</style>
