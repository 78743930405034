import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    'contest-published': false,
    'landing-page-url': '',
    'participate-page-url': '',
    'redeem-prize-page-url': '',
    'legal-age': '',
    'brunch-cities-api': '',
    'products-api': '',
    'stores-api': '',
    'privacy-check': '',
    'join-api': '',
    'province-api': '',
    'city-api': '',
    'check-winner-api': '',
    'check-winner-debug': '',
    'redeem-prize-api': '',
    'ch-key-pm5-6-brunch-in-nature': '',
    'reg-source-pm5-6-brunch-in-nature': '',
    'i18n': {
      participate: {
        hero_title: "Vos détails d'achat",
        hero_title_small: 'Contest',
        hero_description:
          'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise.',
        city_heading:
          'Sélectionnez votre ville pour le brunch en pleine nature',
        city_label: 'Choisissez votre ville',
        city_placeholder: 'Choisissez votre ville',
        city_error_required: 'The ville is mandatory',
        product_heading:
          'Sélectionnez votre produit pour le brunch en pleine nature',
        product_label: 'Choisissez votre produit',
        product_placeholder: 'Choisissez votre produit',
        product_error_required: 'The produit is mandatory',
        participate_type_heading: 'Participate Type',
        participate_type_description:
          "<p>Chaque reçu ne permet qu'une seule participation<br>(même si vous achetez plus de packages dans le même document)</p>",
        participate_type_without_receipt_label: 'Senza Scontrino',
        participate_type_with_receipt_label: 'Con lo scontrino',
        participate_type_error_required: 'The participate type is mandatory',
        shop_type_heading: 'Où avez-vous acheté votre produit Wasa?',
        shop_type_description:
          "<p>Chaque reçu ne permet qu'une seule participation<br>(même si vous achetez plus de packages dans le même document)</p>",
        shop_type_store_label: 'Entreprise',
        shop_type_online_label: 'Online',
        shop_type_error_required: 'The shop type is mandatory',
        shop_label: 'Insert store name',
        shop_placeholder: 'Veuillez choisir votre boutique',
        shop_error_required: 'Veuillez choisir votre boutique',
        picture_heading: 'Télécharger le reçu',
        picture_description: '',
        picture_label: 'Drag & Drop',
        picture_label_small: 'Upload an image',
        picture_label_hover: 'téléchargez-le ici',
        picture_cta: 'browse',
        picture_helper: 'jpg ou png (max.3 Mo) ou',
        picture_helper_small: 'jpg ou png (max.3 Mo)',
        picture_error_required: 'The picture is mandatory',
        picture_error_format: 'File format not allowed',
        picture_error_size: 'Le fichier dépasse la taille maximale',
        picture_instruction_heading:
          'Comment photographier correctement le reçu',
        picture_instruction_legend_1: 'Store name',
        picture_instruction_legend_2: 'Wasa product',
        picture_instruction_legend_3: 'Date and time',
        picture_instruction_legend_4: 'Total sum',
        picture_instruction_legend_5: 'Etc (tbc with Reinbold Rost)',
        picture_instruction_hint_1: 'Place the receipt of a flat surface',
        picture_instruction_hint_2:
          'Frame the receipt so taht it is fully visible',
        picture_instruction_hint_3:
          'Make sure yuor smartphone is parallel to the receipt',
        picture_instruction_info_heading: 'Info to know',
        picture_instruction_info_1:
          'You can take a picture of a long receipt by folding it.',
        picture_instruction_info_2:
          'If the back of the receipt also has data on it, photograph it and upload the photo.',
        rules_label: "J'ai lu les %conditions de participation%",
        rules_href: '',
        privacy_href: '',
        rules_error_required: 'This field is mandatory',
        submit_cta: 'Participez maintenant',
        global_error_logged:
          'Error occurred: You must be logged in to participate in the contest',
        global_error_legal_age:
          'Error occurred: You must be of legal age to participate in the contest',
        global_error_inactive_contest:
          'Error occurred: The contest is inactive',
        global_error_external_server_communication_failed:
          'Error occurred: Communication with external server has failed',
        global_error_unable_to_retrieve_user_token:
          'Error occurred: User token not retrived',
        global_error_missing_parameters_in_configuration:
          'Error occurred: Missing some parameters in configuration',
        global_error_rule_acceptance_flag_false:
          'Error occurred: Rule acceptance missing',
        thank_you_title: 'Merci d’avoir participé a notre jeu',
        thank_you_description:
          'Vous découvrirez si vous etes le grand gagnant du Brunch en plein nature de votre ville lors du ritage au sort fin septembre. Ce n’est pas tout! Un tirage au sort Chaque mois pour tentez de gagner una Brunch Box wasa. Double voic chances de gagner en achetant un produit wasa. Nous vous souhaitons encore de nombreoux moment de plasir avec nos profuits Wasa.',
        thank_you_cta: 'Zurück zur Aktion',
      },
      prize: {
        hero_title: 'Remplir pour recevoir le prix',
        hero_title_small: 'Contest',
        hero_description:
          'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise.',
        title: 'Your contact information',
        description:
          'Leave your contact information and we will get back to you.',
        name_label: 'Prénom*',
        name_error_required: 'This field is required',
        name_error_minLength: 'Incorrect min length',
        name_error_maxLength: 'Incorrect max length',
        name_error_regex: 'An error has occured',
        surname_label: 'Nom*',
        surname_error_required: 'This field is required',
        surname_error_minLength: 'Incorrect min length',
        surname_error_maxLength: 'Incorrect max length',
        surname_error_regex: 'An error has occured',
        email_label: 'Email*',
        tooltip_personal_area_text: "Modificabile dall'area personale",
        prefix_label: 'Prefix',
        prefix_default: '+39',
        phone_label: 'Téléphone*',
        phone_error_required: 'This field is required',
        phone_error_minLength: 'Incorrect min length',
        phone_error_maxLength: 'Incorrect max length',
        phone_error_regex: 'An error has occured',
        province_label: 'Provincia*',
        province_placeholder: 'Choose a province',
        province_error_required: 'This field is required',
        city_label: 'Ville*',
        city_placeholder: 'Choose a city',
        city_placeholder_no_province: 'Choose a province first',
        city_error_required: 'This field is required',
        postal_code_label: 'Cap*',
        postal_code_error_required: 'This field is required',
        postal_code_error_regex: 'An error has occured',
        address_label: 'Rue*',
        address_error_required: 'This field is required',
        address_error_minLength: 'Incorrect min length',
        address_error_maxLength: 'Incorrect max length',
        address_error_regex: 'An error has occured',
        house_number_label: 'Numéro de maison*',
        house_number_error_required: 'This field is required',
        intercom_label: "Numéro d'appartement/Autre",
        intercom_error_required: 'This field is required',
        mandatory_field_indication_placeholder:
          '* Ces champs sont obligatoires',
        submit_cta: 'Continue',
        global_error_logged:
          'Error occurred: You must be logged in to participate in the contest',
        global_error_legal_age:
          'Error occurred: You must be of legal age to participate in the contest',
        global_error_inactive_contest:
          'Error occurred: The contest is inactive',
        global_error_external_server_communication_failed:
          'Error occurred: Communication with external server has failed',
        global_error_unable_to_retrieve_user_token:
          'Error occurred: User token not retrived',
        global_error_missing_parameters_in_configuration:
          'Error occurred: Missing some parameters in configuration',
        thank_you_title: 'Lorem ipsum',
        thank_you_description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.',
        thank_you_cta: 'Zurück zur Aktion',
      },
    },
  },
  actions,
  mutations,
  getters,
};
