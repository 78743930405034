var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"WaPm56BrunchInNatureRedeem"},[(_vm.showPage)?_c('div',[_c('wa-hero',{attrs:{"data-variant-class":"left","data-bg-src-image":require('@images/contest-brunch-in-nature/redeem-hero-bg.jpg'),"data-bg-mobile-src-image":require('@images/contest-brunch-in-nature/redeem-hero-bg-mobile.jpg'),"data-has-down-arrow":true}},[_c('h1',{staticClass:"text-color-white",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.i18nPrize.hero_title)+" ")]),_c('p',{staticClass:"text-color-white",attrs:{"slot":"description"},slot:"description"},[_vm._v(" "+_vm._s(_vm.i18nPrize.hero_description)+" ")])]),_c('div',{staticClass:"container-fluid padding-top-xxl padding-bottom-xxl"},[_c('h3',{staticClass:"text-color-secondary-item text-center typo-a-4 typo-extra-bold margin-bottom-0"},[_vm._v(" "+_vm._s(_vm.i18nPrize.title)+" ")]),_c('p',{staticClass:"text-center typo-a-7 typo-light margin-top-m margin-bottom-m"},[_vm._v(" "+_vm._s(_vm.i18nPrize.description)+" ")]),_c('form',{ref:"$form",staticClass:"WaPm56BrunchInNatureRedeemForm",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-4 padding-bottom-r"},[_c('wa-input',{attrs:{"data-label":_vm.i18nPrize.name_label,"data-name":"first_name","data-error-occurred":_vm.$v.first_name.$error,"data-errors":[
                {
                  condition: !_vm.$v.first_name.required,
                  message: _vm.i18nPrize.name_error_required,
                },
                {
                  condition: !_vm.$v.first_name.minLength,
                  message: _vm.i18nPrize.name_error_minLength,
                },
                {
                  condition: !_vm.$v.first_name.maxLength,
                  message: _vm.i18nPrize.name_error_maxLength,
                },
                {
                  condition: !_vm.$v.first_name.regex,
                  message: _vm.i18nPrize.name_error_regex,
                },
                {
                  condition: !_vm.$v.first_name.serverError,
                  message: _vm.serverErrors.first_name,
                } ]},model:{value:(_vm.$v.first_name.$model),callback:function ($$v) {_vm.$set(_vm.$v.first_name, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.first_name.$model"}})],1),_c('div',{staticClass:"col-12 col-md-4 padding-bottom-r"},[_c('wa-input',{attrs:{"data-label":_vm.i18nPrize.surname_label,"data-name":"last_name","data-error-occurred":_vm.$v.last_name.$error,"data-errors":[
                {
                  condition: !_vm.$v.last_name.required,
                  message: _vm.i18nPrize.surname_error_required,
                },
                {
                  condition: !_vm.$v.last_name.minLength,
                  message: _vm.i18nPrize.surname_error_minLength,
                },
                {
                  condition: !_vm.$v.last_name.maxLength,
                  message: _vm.i18nPrize.surname_error_maxLength,
                },
                {
                  condition: !_vm.$v.last_name.regex,
                  message: _vm.i18nPrize.surname_error_regex,
                },
                {
                  condition: !_vm.$v.last_name.serverError,
                  message: _vm.serverErrors.last_name,
                } ]},model:{value:(_vm.$v.last_name.$model),callback:function ($$v) {_vm.$set(_vm.$v.last_name, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.last_name.$model"}})],1)]),_c('div',{staticClass:"row align-items-start justify-content-center"},[_c('div',{staticClass:"col-12 col-md-4 padding-bottom-r d-flex align-items-center"},[_c('svg',{directives:[{name:"tooltip",rawName:"v-tooltip.auto",value:({
                content: _vm.loggedDataTooltip,
                autoHide: true,
                hideOnTargetClick: true,
                trigger: 'hover click',
              }),expression:"{\n                content: loggedDataTooltip,\n                autoHide: true,\n                hideOnTargetClick: true,\n                trigger: 'hover click',\n              }",modifiers:{"auto":true}}],staticClass:"info"},[_c('use',{attrs:{"href":"#icon-info-circle","fill":"currentColor"}})]),_c('wa-input',{attrs:{"data-label":_vm.i18nPrize.email_label,"data-name":"email","data-disabled":true},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.email.$model"}})],1),_c('div',{staticClass:"col-12 col-md-4 padding-bottom-r phone-container"},[_c('wa-input',{staticClass:"phone-prefix",attrs:{"data-label":_vm.i18nPrize.prefix_label,"data-name":"phone_prefix","data-disabled":true},model:{value:(_vm.$v.phone_prefix.$model),callback:function ($$v) {_vm.$set(_vm.$v.phone_prefix, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.phone_prefix.$model"}}),_c('wa-input',{staticClass:"phone-number",attrs:{"data-label":_vm.i18nPrize.phone_label,"data-name":"phone_number","data-error-occurred":_vm.$v.phone_number.$error,"data-errors":[
                {
                  condition: !_vm.$v.phone_number.required,
                  message: _vm.i18nPrize.phone_error_required,
                },
                {
                  condition: !_vm.$v.phone_number.minLength,
                  message: _vm.i18nPrize.phone_error_minLength,
                },
                {
                  condition: !_vm.$v.phone_number.maxLength,
                  message: _vm.i18nPrize.phone_error_maxLength,
                },
                {
                  condition: !_vm.$v.phone_number.regex,
                  message: _vm.i18nPrize.phone_error_regex,
                },
                {
                  condition: !_vm.$v.phone_number.serverError,
                  message: _vm.serverErrors.phone_number,
                } ]},model:{value:(_vm.$v.phone_number.$model),callback:function ($$v) {_vm.$set(_vm.$v.phone_number, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.phone_number.$model"}})],1)]),(_vm.canRedeem === 'pack' || _vm.canRedeem === 'both')?_c('div',[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-4 padding-bottom-r"},[_c('wa-dropdown',{attrs:{"data-name":"province","data-label":_vm.i18nPrize.province_label,"data-placeholder":_vm.i18nPrize.province_placeholder,"data-option-list":_vm.provinceList,"data-error-occurred":_vm.$v.province.$error,"data-errors":[
                  {
                    condition: !_vm.$v.province.required,
                    message: _vm.i18nPrize.province_error_required,
                  },
                  {
                    condition: !_vm.$v.province.serverError,
                    message: _vm.serverErrors.province,
                  } ]},model:{value:(_vm.$v.province.$model),callback:function ($$v) {_vm.$set(_vm.$v.province, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.province.$model"}})],1),_c('div',{staticClass:"col-12 col-md-4 padding-bottom-r"},[_c('wa-dropdown',{attrs:{"data-name":"city","data-label":_vm.i18nPrize.city_label,"data-placeholder":_vm.province
                    ? _vm.i18nPrize.city_placeholder
                    : _vm.i18nPrize.city_placeholder_no_province,"data-option-list":_vm.filteredCity,"data-error-occurred":_vm.$v.city.$error,"data-errors":[
                  {
                    condition: !_vm.$v.city.required,
                    message: _vm.i18nPrize.city_error_required,
                  },
                  {
                    condition: !_vm.$v.city.serverError,
                    message: _vm.serverErrors.city,
                  } ]},model:{value:(_vm.$v.city.$model),callback:function ($$v) {_vm.$set(_vm.$v.city, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.city.$model"}})],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-4 padding-bottom-r"},[_c('wa-input',{attrs:{"data-label":_vm.i18nPrize.postal_code_label,"data-name":"postal_code","data-error-occurred":_vm.$v.postal_code.$error,"data-errors":[
                  {
                    condition: !_vm.$v.postal_code.required,
                    message: _vm.i18nPrize.postal_code_error_required,
                  },
                  {
                    condition: !_vm.$v.postal_code.regex,
                    message: _vm.i18nPrize.postal_code_error_regex,
                  },
                  {
                    condition: !_vm.$v.postal_code.serverError,
                    message: _vm.serverErrors.postal_code,
                  } ]},model:{value:(_vm.$v.postal_code.$model),callback:function ($$v) {_vm.$set(_vm.$v.postal_code, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.postal_code.$model"}})],1),_c('div',{staticClass:"col-12 col-md-4 padding-bottom-r"},[_c('wa-input',{attrs:{"data-label":_vm.i18nPrize.address_label,"data-name":"address","data-error-occurred":_vm.$v.address.$error,"data-errors":[
                  {
                    condition: !_vm.$v.address.required,
                    message: _vm.i18nPrize.address_error_required,
                  },
                  {
                    condition: !_vm.$v.address.minLength,
                    message: _vm.i18nPrize.address_error_minLength,
                  },
                  {
                    condition: !_vm.$v.address.maxLength,
                    message: _vm.i18nPrize.address_error_maxLength,
                  },
                  {
                    condition: !_vm.$v.address.regex,
                    message: _vm.i18nPrize.address_error_regex,
                  },
                  {
                    condition: !_vm.$v.address.serverError,
                    message: _vm.serverErrors.address,
                  } ]},model:{value:(_vm.$v.address.$model),callback:function ($$v) {_vm.$set(_vm.$v.address, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.address.$model"}})],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-4 padding-bottom-r"},[_c('wa-input',{attrs:{"data-label":_vm.i18nPrize.house_number_label,"data-name":"house_number","data-error-occurred":_vm.$v.house_number.$error,"data-errors":[
                  {
                    condition: !_vm.$v.house_number.required,
                    message: _vm.i18nPrize.house_number_error_required,
                  },
                  {
                    condition: !_vm.$v.house_number.serverError,
                    message: _vm.serverErrors.house_number,
                  } ]},model:{value:(_vm.$v.house_number.$model),callback:function ($$v) {_vm.$set(_vm.$v.house_number, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.house_number.$model"}})],1),_c('div',{staticClass:"col-12 col-md-4 padding-bottom-r"},[_c('wa-input',{attrs:{"data-label":_vm.i18nPrize.intercom_label,"data-name":"intercom","data-error-occurred":_vm.$v.intercom.$error,"data-errors":[
                  {
                    condition: !_vm.$v.intercom.serverError,
                    message: _vm.serverErrors.intercom,
                  } ]},model:{value:(_vm.$v.intercom.$model),callback:function ($$v) {_vm.$set(_vm.$v.intercom, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.intercom.$model"}})],1)])]):_vm._e(),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8 padding-top-xs padding-bottom-m"},[_c('span',{staticClass:"typo-a-9 typo-light text-color-grey-8"},[_vm._v(" "+_vm._s(_vm.i18nPrize.mandatory_field_indication_placeholder)+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('div',{staticClass:"d-block d-sm-inline-block"},[(_vm.canRedeem !== null)?_c('wa-cta',{staticClass:"primary",attrs:{"data-type":"submit","data-icon-id":_vm.spinner ? 'icon-ellipsis-loader' : '',"data-disabled":_vm.disableSubmit ||
                  _vm.submitted ||
                  !(
                    _vm.canRedeem === 'pack' ||
                    _vm.canRedeem === 'final' ||
                    _vm.canRedeem === 'both'
                  )},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_vm._v(" "+_vm._s(_vm.i18nPrize.submit_cta)+" ")]):_vm._e(),_vm._l((_vm.hasGlobalErrors),function(error,i){return _c('span',{key:("error-" + i + "-" + _vm._uid),staticClass:"global-error typo-a-10 typo-medium",domProps:{"innerHTML":_vm._s(error.message)}})})],2)])])])])],1):_c('div',[_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"padding-top-xxl padding-bottom-xxl"},[_c('div',{staticClass:"dimmer"}),_c('div',{staticClass:"loader"})])}]

export { render, staticRenderFns }