export const PRODUCTS = {
  data: [
    {
      code: '1100321001270',
      description: 'WASA LEGER 270 G',
      category: {
        id: '1',
        description: 'Wasa',
      },
    },
    {
      code: '1100321002230',
      description: 'WASA FIBRES 230 G',
      category: {
        id: '1',
        description: 'Wasa',
      },
    },
    {
      code: '1100321003275',
      description: 'WASA AUTHENTIQUE 275 G',
      category: {
        id: '1',
        description: 'Wasa',
      },
    },
    {
      code: '1100321004290',
      description: 'WASA RUNDA SÉSAME ET SEL DE MER 290 G',
      category: {
        id: '1',
        description: 'Wasa',
      },
    },
    {
      code: '1081120001250',
      description: 'WASA SESAM 250 G',
      category: {
        id: '1',
        description: 'Wasa',
      },
    },
    {
      code: '1070321007190',
      description: 'WASA DELICATE CRISP SESAM & SALT 190G WC',
      category: {
        id: '1',
        description: 'Wasa',
      },
    },
    {
      code: '1080321001190',
      description: 'WASA DELICATE CRISP ROSEMARY & SALT 190G WC',
      category: {
        id: '1',
        description: 'Wasa',
      },
    },
    {
      code: '1090521052260',
      description: 'WASA COMPLET 260G FR',
      category: {
        id: '1',
        description: 'Wasa',
      },
    },
  ],
};

export const ONLINE_STORES = {
  data: [
    {
      tag: 'store-online-1',
      description: 'Auchan Drive',
    },
    {
      tag: 'store-online-2',
      description: 'Leclerc Drive',
    },
    {
      tag: 'store-online-3',
      description: 'Carrefour Drive',
    },
    {
      tag: 'store-online-4',
      description: 'Casino Drive',
    },
    {
      tag: 'store-online-5',
      description: 'Intermarché Drive',
    },
    {
      tag: 'store-online-6',
      description: 'Système U Drive',
    },
  ],
};

export const OFFLINE_STORES = {
  data: [
    {
      tag: 'store-traditional-1',
      description: 'Auchan',
    },
    {
      tag: 'store-traditional-2',
      description: 'Carrefour',
    },
    {
      tag: 'store-traditional-3',
      description: 'Cora',
    },
    {
      tag: 'store-traditional-4',
      description: 'Leclerc',
    },
    {
      tag: 'store-traditional-5',
      description: 'Casino ',
    },
    {
      tag: 'store-traditional-6',
      description: 'Systeme U',
    },
    {
      tag: 'store-traditional-7',
      description: 'Intermarché Hyper',
    },
    {
      tag: 'store-traditional-8',
      description: 'Géant',
    },
    {
      tag: 'store-traditional-9',
      description: 'Carrefour Market',
    },
    {
      tag: 'store-traditional-11',
      description: 'Intermarché Super',
    },
    {
      tag: 'store-traditional-13',
      description: 'Monoprix',
    },
    {
      tag: 'store-traditional-14',
      description: 'Franprix',
    },
    {
      tag: 'store-traditional-15',
      description: 'Match',
    },
  ],
};
