/* Store initialization */
export const SET_APP_READY = 'SET_APP_READY';
export const SET_STORE_SETUP_COMPLETED = 'SET_STORE_SETUP_COMPLETED';
export const SET_PAGE_SETUP_COMPLETED = 'SET_PAGE_SETUP_COMPLETED';
export const SET_LOADING = 'SET_LOADING';
export const SET_UNLOCKED_VIEW = 'SET_UNLOCKED_VIEW';
export const SET_UNLOCKED_WAITING_VIEW = 'SET_UNLOCKED_WAITING_VIEW';

/* Config */
export const SET_CONFIG_PROPERTY = 'SET_CONFIG_PROPERTY';
export const SET_CONFIG_PROPERTY_WITH_TARGET =
  'SET_CONFIG_PROPERTY_WITH_TARGET';
export const SET_INVERTED_HEADER = 'SET_INVERTED_HEADER';

/* Gigya */
export const SET_GIGYA_INIT_DATA = 'SET_GIGYA_INIT_DATA';
export const SET_GIGYA_GO_TO = 'SET_GIGYA_GO_TO';
export const SET_GIGYA_LOADED = 'SET_GIGYA_LOADED';
export const SET_GIGYA_USER = 'SET_GIGYA_USER';
export const SET_GIGYA_PREFERENCES = 'SET_GIGYA_PREFERENCES';
export const SET_OT_ACTIVE_GROUPS = 'SET_OT_ACTIVE_GROUPS';
export const SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN';
export const SET_GIGYA_VERSION = 'SET_GIGYA_VERSION';
export const SET_GIGYA_UID = 'SET_GIGYA_UID';
export const SET_GIGYA_PHONE = 'SET_GIGYA_PHONE';

/* Smc */
export const SET_SMC_INIT_DATA = 'SET_SMC_INIT_DATA';

/* Contact */
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_PRODUCT_DETAIL = 'SET_PRODUCT_DETAIL';
export const SET_HOME_VISITED = 'SET_HOME_VISITED';
export const SET_MIN_HEIGHT = 'SET_MIN_HEIGHT';
export const SET_SELECTS_API = 'SET_SELECTS_API';
export const SET_HOME_INFO = 'SET_HOME_INFO';
export const SET_CONTENT_DATA = 'SET_CONTENT_DATA';
export const SET_FIELDS_INFOS = 'SET_FIELDS_INFOS';
export const SET_JEKO_INTEGRATION = 'SET_JEKO_INTEGRATION';

/* Recipes */
export const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
export const ADD_PAGE = 'ADD_PAGE';
export const RESET_PAGES = 'RESET_PAGES';
export const SET_DETAIL = 'SET_DETAIL';
export const SET_API_URL = 'SET_API_URL';
export const SET_SELECTED_MEAL = 'SET_SELECTED_MEAL';
export const SET_SELECTED_MOOD = 'SET_SELECTED_MOOD';

/* Contest Plant a Tree */
export const SET_CONTEST_PLANT_A_TREE_INIT_DATA =
  'SET_CONTEST_PLANT_A_TREE_INIT_DATA';
export const SET_CONTEST_PLANT_A_TREE_CONTENT_DATA =
  'SET_CONTEST_PLANT_A_TREE_CONTENT_DATA';

/* Contest Leisure and Fun */
export const SET_PROMO_CE_INIT_DATA = 'SET_PROMO_CE_INIT_DATA';
export const SET_PROMO_CE_CONTENT_DATA = 'SET_PROMO_CE_CONTENT_DATA';

/* Contest Brunch in Nature */
export const SET_PM5_6_BRUNCH_IN_NATURE_INIT_DATA =
  'SET_PM5_6_BRUNCH_IN_NATURE_INIT_DATA';
export const SET_PM5_6_BRUNCH_IN_NATURE_I18N_PARTICIPATE =
  'SET_PM5_6_BRUNCH_IN_NATURE_I18N_PARTICIPATE';
export const SET_PM5_6_BRUNCH_IN_NATURE_I18N_PRIZE =
  'SET_PM5_6_BRUNCH_IN_NATURE_I18N_PRIZE';

/* Contest Sandwich Summer Campaign */
export const SET_PM5_6_SANDWICH_SUMMER_CAMPAIGN_INIT_DATA =
  'SET_PM5_6_SANDWICH_SUMMER_CAMPAIGN_INIT_DATA';
export const SET_PM5_6_SANDWICH_SUMMER_CAMPAIGN_I18N_PARTICIPATE =
  'SET_PM5_6_SANDWICH_SUMMER_CAMPAIGN_I18N_PARTICIPATE';
export const SET_PM5_6_SANDWICH_SUMMER_CAMPAIGN_I18N_PRIZE =
  'SET_PM5_6_SANDWICH_SUMMER_CAMPAIGN_I18N_PRIZE';

/* Contest Instagram */
export const SET_PMEXTRA_INSTAGRAM_INIT_DATA =
  'SET_PMEXTRA_INSTAGRAM_INIT_DATA';
export const SET_PMEXTRA_INSTAGRAM_I18N_PARTICIPATE =
  'SET_PMEXTRA_INSTAGRAM_I18N_PARTICIPATE';
export const SET_PMEXTRA_INSTAGRAM_I18N_PRIZE =
  'SET_PMEXTRA_INSTAGRAM_I18N_PRIZE';

/* Contest NORWAY SANDWICH */
export const SET_PMEXTRA_NORWAY_SANDWICH_INIT_DATA =
  'SET_PMEXTRA_NORWAY_SANDWICH_INIT_DATA';
export const SET_PMEXTRA_NORWAY_SANDWICH_I18N_PARTICIPATE =
  'SET_PMEXTRA_NORWAY_SANDWICH_I18N_PARTICIPATE';
export const SET_PMEXTRA_NORWAY_SANDWICH_RELATED_PRODUCTS =
  'SET_PMEXTRA_NORWAY_SANDWICH_RELATED_PRODUCTS';
export const SET_PMEXTRA_NORWAY_SANDWICH_I18N_PRIZE =
  'SET_PMEXTRA_NORWAY_SANDWICH_I18N_PRIZE';

/**Contest Save The insects de-de de-at */
export const SET_PM9_SAVE_THE_INSECTS_INIT_DATA =
  'SET_PM9_SAVE_THE_INSECTS_INIT_DATA';
export const SET_PM9_SAVE_THE_INSECTS_I18N_PARTICIPATE =
  'SET_PM9_SAVE_THE_INSECTS_I18N_PARTICIPATE';
export const SET_PM9_SAVE_THE_INSECTS_I18N_PRIZE =
  'SET_PM9_SAVE_THE_INSECTS_I18N_PRIZE';

/* Contest Sandwich Surf */
export const SET_PM5_6_SANDWICH_SURF_INIT_DATA =
  'SET_PM5_6_SANDWICH_SURF_INIT_DATA';
export const SET_PM5_6_SANDWICH_SURF_I18N_PARTICIPATE =
  'SET_PM5_6_SANDWICH_SURF_I18N_PARTICIPATE';
export const SET_PM5_6_SANDWICH_SURF_I18N_PRIZE =
  'SET_PM5_6_SANDWICH_SURF_I18N_PRIZE';
export const SET_PM5_6_SANDWICH_SURF_REGEX = 'SET_PM5_6_SANDWICH_SURF_REGEX';

/* Contest Sandwich September Game */
export const SET_PM5_6_SEPTEMBER_GAME_INIT_DATA =
  'SET_PM5_6_SEPTEMBER_GAME_INIT_DATA';
export const SET_PM5_6_SEPTEMBER_GAME_I18N_PARTICIPATE =
  'SET_PM5_6_SEPTEMBER_GAME_I18N_PARTICIPATE';
export const SET_PM5_6_SEPTEMBER_GAME_I18N_PRIZE =
  'SET_PM5_6_SEPTEMBER_GAME_I18N_PRIZE';
export const SET_PM5_6_SEPTEMBER_GAME_REGEX = 'SET_PM5_6_SEPTEMBER_GAME_REGEX';

/* Contest Biscuits */
export const SET_PM5_6_BISCUITS_INIT_DATA = 'SET_PM5_6_BISCUITS_INIT_DATA';
export const SET_PM5_6_BISCUITS_I18N_PARTICIPATE =
  'SET_PM5_6_BISCUITS_I18N_PARTICIPATE';
export const SET_PM5_6_BISCUITS_I18N_PRIZE = 'SET_PM5_6_BISCUITS_I18N_PRIZE';
export const SET_PM5_6_BISCUITS_REGEX = 'SET_PM5_6_BISCUITS_REGEX';
export const SET_PM5_6_BISCUITS_RELATED_PRODUCTS =
  'SET_PM5_6_BISCUITS_RELATED_PRODUCTS';

/* Contact Form 2024 */
export const SET_QUERY_STRING = 'SET_QUERY_STRING';
export const SET_CURRENT_COUNTRY = 'SET_CURRENT_COUNTRY';
export const SET_FIRST_LEVEL_GROUP = 'SET_FIRST_LEVEL_GROUP';
export const SET_SECOND_LEVEL_GROUP = 'SET_SECOND_LEVEL_GROUP';
export const SET_THIRD_LEVEL_GROUP = 'SET_THIRD_LEVEL_GROUP';
export const SET_UNLOCKED_THANK_YOU = 'SET_UNLOCKED_THANK_YOU';
export const SET_CF_LOADING = 'SET_CF_LOADING';

/* Camper contest */
export const SET_PM5_6_CAMPER_I18N_PARTICIPATE =
  'SET_PM5_6_CAMPER_I18N_PARTICIPATE';
export const SET_PM5_6_CAMPER_I18N_PRIZE = 'SET_PM5_6_CAMPER_I18N_PRIZE';
export const SET_PM5_6_CAMPER_INIT_DATA = 'SET_PM5_6_CAMPER_INIT_DATA';
export const SET_PM5_6_CAMPER_REGEX = 'SET_PM5_6_CAMPER_REGEX';
