<template>
  <div class="WaTextarea form-group__wrapper">
    <div :class="['form-group', { disabled: dataDisabled }]">
      <div :class="['form-control__wrapper', 'typo-a-9', 'typo-light']">
        <label :class="['form__label', { focus: isFocus || value }]">
          {{ dataLabel }}
        </label>
        <textarea
          :id="dataTextareaId"
          ref="textarea"
          :class="[
            'typo-a-9',
            'typo-light',
            'form-control',
            'form__textarea',
            { error: dataErrorOccurred },
          ]"
          :maxlength="dataMaxLength"
          :name="dataName"
          :value="value"
          :placeholder="dataPlaceholder"
          :disabled="dataDisabled"
          :rows="dataRows"
          @focus="isFocus = true"
          @blur="isFocus = false"
          @input="onUpdate"
        />
        <div v-if="dataErrorOccurred" class="form__error">
          <!-- <svg
            width="20"
            height="20"
          >
            <use
              href="#icon-triangle-error"
              fill="currentColor"
            />
          </svg> -->

          <span
            v-for="(error, i) in hasErrors"
            :key="`error-${i}`"
            class="form__error--text"
            v-html="error.message"
          />
        </div>
        <div v-if="dataHelperText" class="form__helperText typo-a-9 typo-light">
          {{ dataHelperText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaTextarea',
  props: {
    dataLabel: {
      type: String,
      default: '',
    },
    dataTextareaId: {
      type: String,
      default: null,
    },
    dataName: {
      type: String,
      required: true,
    },
    dataMaxLength: {
      type: Number,
      default: null,
    },
    dataRows: {
      type: Number,
      default: 2,
    },
    value: {
      type: String,
      default: null,
    },
    dataPlaceholder: {
      type: String,
      default: null,
    },
    dataDisabled: {
      type: Boolean,
      default: null,
    },
    dataErrorOccurred: {
      type: Boolean,
      default: null,
    },
    dataErrors: {
      type: Array,
      default: null,
    },
    dataHelperText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    hasErrors() {
      if (this.dataErrorOccurred) {
        return this.dataErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  methods: {
    onUpdate(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.form-group__wrapper {
  width: 100%;
  position: relative;
  color: $color-grey-8;

  .form-control__wrapper {
    .form__label {
      margin: auto;
      position: absolute;
      top: 16px;
      left: 16px;
      color: $color-grey-6;
      pointer-events: none;
      opacity: 1;
      font-weight: $fh-light;

      &.focus {
        opacity: 0;
        transition: all 0.1s linear;
      }
    }

    .form__textarea {
      min-height: 150px;
      max-height: 100%;
      border: 1px solid $color-grey-6;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 16px;
      width: 100%;
      resize: none;
      font-weight: $fh-light;

      &:disabled {
        border: 1px solid $color-grey-6;
        background: $color-grey-2-50;
        color: inherit;
      }

      &::placeholder {
        opacity: 0;
      }

      &:hover:not([disabled]) {
        border: 1px solid $color-blue-logo;
        cursor: pointer;
      }

      &.error {
        border: 1px solid $color-informative-red !important;
      }

      &:focus {
        border: 1px solid $color-grey-9;
        outline: none !important;

        &::-webkit-credentials-auto-fill-button {
          visibility: hidden;
        }

        &::placeholder {
          color: $color-grey-6;
          opacity: 1;
          transition: all 0.1s ease-in-out;
        }
      }
    }

    .form__error {
      color: $color-informative-red;

      svg {
        position: absolute;
        right: 16px;
        top: 16px;
      }

      .form__error--text {
        display: block;
        font-size: 13px;
        font-weight: $fh-medium;
        margin-top: 8px;

        &:last-child {
          padding-bottom: 7px;
        }
      }
    }

    .form__helperText {
      color: $color-grey-8;
      margin-top: 16px;
    }
  }
}
</style>
