<template>
  <div class="WaCarouselSlide">
    <figure
      v-if="$_cmsSupport_hasContentSlotWithName('image')"
      class="image-wrapper"
    >
      <slot name="image" />
    </figure>
    <div
      v-if="$_cmsSupport_hasContentSlotWithName('title')"
      :class="['title-wrapper', titleSpacing]"
    >
      <slot name="title" />
    </div>
    <div
      v-if="$_cmsSupport_hasContentSlotWithName('text')"
      :class="['text-wrapper', textSpacing]"
    >
      <slot name="text" />
    </div>
    <div
      v-if="$_cmsSupport_hasContentSlotWithName('link')"
      class="link-wrapper"
    >
      <slot name="link" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaCarouselSlide',
  computed: {
    titleSpacing() {
      if (this.$_cmsSupport_hasContentSlotWithName('image')) {
        return 'padding-top-s';
      }
      return null;
    },
    textSpacing() {
      if (this.$_cmsSupport_hasContentSlotWithName('title')) {
        return 'padding-top-xs';
      } else if (this.$_cmsSupport_hasContentSlotWithName('image')) {
        return 'padding-top-s';
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~$scss/utility';
@import '~bootstrap/scss/mixins/breakpoints';

.WaCarouselSlide {
  position: relative;

  &:has(.link-wrapper:hover) {
    .image-wrapper {
      /deep/ .image {
        transform: scale(1.1);
      }
    }
  }
}

.image-wrapper {
  margin: 0;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  border-radius: 4px;
  overflow: hidden;

  /deep/ .image {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease;
  }
}

.title-wrapper {
  /deep/ .title {
    margin: 0;
  }
}

.text-wrapper {
  /deep/ .text {
    margin: 0;
  }
}

.link-wrapper {
  height: 0;
  position: static;

  /deep/ a {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    font-size: 0;
    line-height: 1;
    opacity: 0;
  }
}
</style>
