var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ContactFormTicketingFirstLevelCmp"},[(_vm.skipToFormCtas)?_vm._l((_vm.skipToFormCtas),function(cta){return _c('router-link',{key:("list-item-" + (cta.id)),staticClass:"list-item",attrs:{"to":{
        name: _vm.getRouteName(
          ['triage_type', 'triage_topic'],
          _vm.queryString,
          _vm.routes,
          cta
        ),
        query: Object.assign({}, _vm.queryString,
          {ticketing_first_level: cta.slug,
          ticketing_third_level: cta.product_id,
          page_order: ((_vm.queryString.page_order) + "," + (_vm.routes.ticketing_first_level.name))}),
      }}},[_c('wa-cta-box',{staticClass:"contact-form-cta-box text-color-blue-logo",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h4',{staticClass:"typo-a typo-a-6 typo-medium margin-bottom-0"},[_vm._v(" "+_vm._s(cta.label)+" ")])]},proxy:true}],null,true)})],1)}):_vm._e(),_vm._l((_vm.items),function(item){return _c('router-link',{key:("list-item-" + (item.slug)),staticClass:"list-item",attrs:{"to":{
      name: _vm.routes.ticketing_first_level.controls.selectionCtasFrom
        .selectionCtasGoToName,
      query: Object.assign({}, _vm.queryString,
        {ticketing_first_level: item.slug,
        page_order: ((_vm.queryString.page_order) + "," + (_vm.routes.ticketing_first_level.name))}),
    }}},[_c('wa-cta-box',{staticClass:"contact-form-cta-box text-color-blue-logo",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h4',{staticClass:"typo-a typo-a-6 typo-medium margin-bottom-0"},[_vm._v(" "+_vm._s(item.label)+" ")])]},proxy:true}],null,true)})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }