import * as types from '../mutation_types';

export default {
  setPm56SandwichSurfInitData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        Object.keys(payload).forEach((k) => {
          commit(types.SET_PM5_6_SANDWICH_SURF_INIT_DATA, {
            key: k,
            value: payload[k],
          });
        });
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  async setPm56SandwichSurfI18nParticipate({ commit }, obj) {
    commit(types.SET_PM5_6_SANDWICH_SURF_I18N_PARTICIPATE, obj);
  },
  async setPm56SandwichSurfI18nPrize({ commit }, obj) {
    commit(types.SET_PM5_6_SANDWICH_SURF_I18N_PRIZE, obj);
  },
  async setPm56SandwichSurfRegex({ commit }, obj) {
    commit(types.SET_PM5_6_SANDWICH_SURF_REGEX, obj);
  },
};
