<template>
  <div class="WaPm9LeisureAndFunVerify">
    <wa-hero
      data-variant-class="center"
      :data-bg-src-image="require('@images/promo-ce/hero-bg.jpeg')"
      :data-bg-mobile-src-image="
        require('@images/promo-ce/hero-bg-mobile.jpeg')
      "
      :data-has-down-arrow="true"
    >
      <h1 slot="title" class="text-color-white">
        {{ contentData.hero_title }}
      </h1>

      <h4 slot="title-small" class="text-color-white">
        {{ contentData.hero_title_small }}
      </h4>

      <p slot="description" class="text-color-white">
        {{ contentData.hero_description }}
      </p>
    </wa-hero>

    <div
      class="container-fluid padding-top-xxl padding-bottom-xxl position-relative"
    >
      <wa-vertical-name data-vertical="position-top" class="text-color-grey-6">
        Wasa Freizeitspass
        <!-- {{ contentData.product_heading }} -->
      </wa-vertical-name>
      <form ref="$form" class="WaPm9LeisureAndFunVerifyForm" @submit.prevent>
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-sea-salt-blue padding-bottom-r"
          >
            <svg class="icon margin-bottom-r">
              <use href="#icon-wasa-boxed" fill="currentColor" />
            </svg>
            <span class="typo-w-4 typo-bold text-center">
              {{ contentData.product_heading }}
            </span>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto padding-bottom-xl">
            <wa-select
              v-model.trim="$v.receipt_product.$model"
              class="medium text-color-blue-logo"
              :data-label="contentData.product_label"
              :data-show-label="false"
              data-name="receipt_product"
              :data-placeholder="contentData.product_placeholder"
              :data-option-list="products"
              :data-error-occurred="$v.receipt_product.$error"
              :data-errors="[
                {
                  condition: !$v.receipt_product.required,
                  message: contentData.product_error_required,
                },
                {
                  condition: !$v.receipt_product.serverError,
                  message: serverErrors.receipt_product,
                },
              ]"
            >
              <template slot="icon">
                <svg width="24" height="24">
                  <use href="#icon-arrow-angle-down" fill="currentColor" />
                </svg>
              </template>
            </wa-select>
          </div>
        </div>
        <div class="row justify-content-center">
          <div
            class="col-12 col-md-10 col-lg-8 d-flex flex-column align-items-center justify-content-center text-color-sea-salt-blue padding-bottom-l"
          >
            <span class="typo-w-4 typo-bold text-center margin-bottom-r">
              {{ contentData.shop_type_heading }}
            </span>
            <div
              class="typo-a-8 typo-light text-color-grey-9 text-center noMBLastChild"
              v-html="contentData.shop_type_description"
            />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8">
            <wa-radio-chooser
              v-model.trim="$v.receipt_type.$model"
              data-name="receipt_type"
              :data-radio-list="[
                {
                  value: 'standard',
                  label: contentData.shop_type_store_label,
                  iconId: 'icon-market',
                },
                {
                  value: 'electronic',
                  label: contentData.shop_type_online_label,
                  iconId: 'icon-world-hand-drawn',
                },
              ]"
              :data-error-occurred="$v.receipt_type.$error"
              :data-errors="[
                {
                  condition: !$v.receipt_type.required,
                  message: contentData.shop_type_error_required,
                },
                {
                  condition: !$v.receipt_type.serverError,
                  message: serverErrors.receipt_type,
                },
              ]"
              @change="onSetShopType"
            />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 padding-top-r">
            <wa-dropdown
              v-model.trim="$v.receipt_store.$model"
              data-name="receipt_store"
              :data-label="contentData.shop_label"
              :data-placeholder="contentData.shop_placeholder"
              :data-option-list="filteredShops"
              :data-error-occurred="$v.receipt_store.$error"
              :data-errors="[
                {
                  condition: !$v.receipt_store.required,
                  message: contentData.shop_error_required,
                },
                {
                  condition: !$v.receipt_store.serverError,
                  message: serverErrors.receipt_store,
                },
              ]"
            />
          </div>
        </div>
        <div class="row justify-content-center">
          <div
            class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-sea-salt-blue padding-top-xl padding-bottom-r"
          >
            <svg class="icon margin-bottom-r">
              <use href="#icon-favorite-hand-drawn" fill="currentColor" />
            </svg>
            <span class="typo-w-4 typo-bold text-center">
              {{ contentData.partner_name }}
            </span>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto padding-bottom-xl">
            <wa-select
              v-model.trim="$v.selected_partner.$model"
              class="medium text-color-blue-logo"
              :data-label="contentData.partner_placeholder"
              :data-show-label="false"
              :data-name="contentData.partner_placeholder"
              :data-placeholder="contentData.partner_placeholder"
              :data-option-list="partners"
              :data-error-occurred="$v.selected_partner.$error"
              :data-errors="[
                {
                  condition: !$v.selected_partner.required,
                  message: contentData.selected_partner_error_required,
                },
                {
                  condition: !$v.selected_partner.serverError,
                  message: serverErrors.selected_partner,
                },
              ]"
            >
              <template slot="icon">
                <svg width="24" height="24">
                  <use href="#icon-arrow-angle-down" fill="currentColor" />
                </svg>
              </template>
            </wa-select>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8">
            <wa-regular-divider class="padding-bottom-m" />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 d-flex flex-column">
            <span
              class="typo-a-6 typo-extra-bold margin-bottom-r text-color-grey-9"
            >
              {{ contentData.picture_heading }}
            </span>
            <span
              v-if="contentData.picture_description"
              class="typo-a-9 typo-medium text-color-grey-8 padding-bottom-xs"
            >
              {{ contentData.picture_description }}
            </span>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 padding-bottom-r">
            <wa-upload
              ref="$waUpload"
              v-model.trim="$v.receipt_front_photo.$model"
              name="receipt_front_photo"
              :label="contentData.picture_label"
              :label-smaller="contentData.picture_label_small"
              :label-on-hover="contentData.picture_label_hover"
              :cta-text="contentData.picture_cta"
              :helper-text="contentData.picture_helper"
              :helper-text-smaller="contentData.picture_helper_small"
              :data-error-occurred="$v.receipt_front_photo.$error"
              :errors="[
                {
                  condition: !$v.receipt_front_photo.required,
                  message: contentData.picture_error_required,
                },
                {
                  condition: !$v.receipt_front_photo.mustBeImage,
                  message: contentData.picture_error_format,
                },
                {
                  condition: !$v.receipt_front_photo.validSize,
                  message: contentData.picture_error_size,
                },
                {
                  condition: !$v.receipt_front_photo.serverError,
                  message: serverErrors.receipt_front_photo,
                },
              ]"
              @change="onFileChange"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 padding-bottom-m">
            <wa-accordion>
              <wa-accordion-item class="text-color-blue-logo margin-bottom-0">
                <template slot="title">
                  <h4 class="typo-a-6 typo-medium margin-bottom-0">
                    {{ contentData.picture_instruction_heading }}
                  </h4>
                </template>
                <template slot="content">
                  <wa-free-text>
                    <template slot="content_wysiwyg">
                      <div class="padding-top-m padding-bottom-m text-center">
                        <img
                          v-if="receiptImg"
                          :src="receiptImg"
                          class="img-fluid"
                        />
                      </div>
                      <ol class="margin-top-0 margin-bottom-0">
                        <li
                          class="typo-a-7 typo-medium text-color-sea-salt-blue"
                        >
                          {{ contentData.picture_instruction_legend_1 }}
                        </li>
                        <li
                          class="typo-a-7 typo-medium text-color-sea-salt-blue"
                        >
                          {{ contentData.picture_instruction_legend_2 }}
                        </li>
                        <li
                          class="typo-a-7 typo-medium text-color-sea-salt-blue"
                        >
                          {{ contentData.picture_instruction_legend_3 }}
                        </li>
                        <li
                          class="typo-a-7 typo-medium text-color-sea-salt-blue"
                        >
                          {{ contentData.picture_instruction_legend_4 }}
                        </li>
                        <li
                          class="typo-a-7 typo-medium text-color-sea-salt-blue margin-bottom-0"
                        >
                          {{ contentData.picture_instruction_legend_5 }}
                        </li>
                      </ol>
                      <wa-regular-divider
                        class="padding-top-r padding-bottom-r"
                      />
                      <ul class="margin-top-0 margin-bottom-0">
                        <li class="typo-a-7 typo-light text-color-black">
                          {{ contentData.picture_instruction_hint_1 }}
                        </li>
                        <li class="typo-a-7 typo-light text-color-black">
                          {{ contentData.picture_instruction_hint_2 }}
                        </li>
                        <li
                          class="typo-a-7 typo-light text-color-black margin-bottom-0"
                        >
                          {{ contentData.picture_instruction_hint_3 }}
                        </li>
                      </ul>
                      <wa-regular-divider
                        class="padding-top-r padding-bottom-r"
                      />
                      <p>
                        <span
                          class="d-block typo-a-7 typo-medium text-color-grey-8 padding-bottom-xs"
                        >
                          {{ contentData.picture_instruction_info_heading }}
                        </span>
                        <span
                          class="d-block typo-a-9 typo-light text-color-black padding-bottom-xs"
                        >
                          {{ contentData.picture_instruction_info_1 }}
                        </span>
                        <span
                          class="d-block typo-a-9 typo-light text-color-black"
                        >
                          {{ contentData.picture_instruction_info_2 }}
                        </span>
                      </p>
                    </template>
                  </wa-free-text>
                </template>
              </wa-accordion-item>
            </wa-accordion>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 padding-bottom-r">
            <span class="text-color-grey-8 typo-heavy typo-a-8">
              {{ contentData.global_error_legal_age }}
            </span>
          </div>
        </div>
        <div v-if="show_rules" class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 padding-bottom-m">
            <wa-checkbox
              v-model.trim="$v.contest_rules_accepted.$model"
              data-name="contest_rules_accepted"
              :data-checkbox-list="[
                {
                  label: rulesLabel,
                },
              ]"
              :data-error-occurred="$v.contest_rules_accepted.$error"
              :data-errors="[
                {
                  condition: !$v.contest_rules_accepted.mustBeChecked,
                  message: contentData.rules_error_required,
                },
                {
                  condition: !$v.contest_rules_accepted.serverError,
                  message: serverErrors.contest_rules_accepted,
                },
              ]"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <div class="d-block d-sm-inline-block">
              <wa-cta
                class="primary"
                data-type="submit"
                :data-icon-id="spinner ? 'icon-ellipsis-loader' : ''"
                :data-disabled="disableSubmit || submitted || !canParticipate"
                @click.prevent="onSubmit"
              >
                {{ contentData.submit_cta }}
              </wa-cta>
              <span
                v-for="(error, i) in hasGlobalErrors"
                :key="`error-${i}-${_uid}`"
                class="global-error typo-a-10 typo-medium"
                v-html="error.message"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { helpers, required } from 'vuelidate/lib/validators';
import LanguageSupport from '@mixins/language-support';
import { debug, qsObj } from '@helpers/utils';
import axios from 'axios';

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'WaPm9LeisureAndFunVerify',
  mixins: [LanguageSupport],
  data() {
    return {
      canParticipate: false,
      products: null,
      partners: null,
      shops: null,
      show_rules: false,
      /* form field */
      receipt_product: null,
      selected_partner: null,
      receipt_type: null,
      receipt_store: null,
      receipt_front_photo: null,
      contest_rules_accepted: false,
      /* after submit */
      submitted: false,
      spinner: false,
      serverErrors: {},
      globalErrors: [],
    };
  },
  computed: {
    ...mapState(['storeSetupCompleted']),
    ...mapState('gigya', ['loaded', 'user']),
    ...mapState('pm9LeisureAndFun', [
      'legal-age',
      'products-api',
      'partners-api',
      'stores-api',
      'privacy-check',
      'join-api',
      'contentData',
    ]),
    receiptImg() {
      try {
        return require(`@images/promo-ce/receipt-img-${this.currentLang}.png`);
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    disableSubmit() {
      if (this.$v && (this.$v.$anyError || this.$v.$invalid)) {
        return true;
      }

      return false;
    },
    filteredShops() {
      return this.shops && this.receipt_type
        ? this.shops
            .filter((e) =>
              this.receipt_type === 'electronic' ? e.is_online : !e.is_online
            )
            .map((e) => {
              return { key: e.id, dataValue: e.name };
            })
        : [];
    },
    rulesLabel() {
      // reworked in component library
      let k = 0;
      let j = 0;
      return this.contentData.rules_label
        .replace(/%/g, () => {
          k++;
          if (k === 1) {
            return `<a class="typo-medium text-color-blue-logo" href='${this.contentData.rules_href}' target='_blank'>`;
          } else {
            return '</a>';
          }
        })
        .replace(/£/g, () => {
          j++;
          if (j === 1) {
            return `<a class="typo-medium text-color-blue-logo" href='${this.contentData.privacy_href}' target='_blank'>`;
          } else {
            return '</a>';
          }
        });
    },
    hasGlobalErrors() {
      if (this.globalErrors.length) {
        return this.globalErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  watch: {
    storeSetupCompleted: {
      async handler(val) {
        debug && console.log('WATCH storeSetupCompleted', val);
        if (val) {
          const endpointProducts = this['products-api']
            ? this['products-api']
            : 'https://run.mocky.io/v3/8273bebc-3996-4e14-bc97-dddef7273caf';
          const endpointPartners = this['partners-api']
            ? this['partners-api']
            : 'https://run.mocky.io/v3/0a53743e-1680-4763-9bc9-bc41b98e5c54';
          const endpointStores = this['stores-api']
            ? this['stores-api']
            : 'https://run.mocky.io/v3/6e69bc05-dbe4-4ae7-a5da-1464ce9e24b6';

          try {
            const resultPartnersApi = await axios.get(endpointPartners);

            if (resultPartnersApi.data) {
              this.partners = resultPartnersApi.data.data
                .filter((e) => {
                  return e.is_online;
                })
                .map((e) => {
                  return { key: e.id, dataValue: e.partner_name };
                });
              if ('partnerId' in qsObj && qsObj.partnerId) {
                this.selected_partner = this.partners
                  .map((el) => el)
                  .find((e) => e.key === qsObj.partnerId).key;
              }
            }
          } catch (e) {
            console.log(e);
          }

          try {
            const resultProductsApi = await axios.get(endpointProducts);

            if (resultProductsApi.data) {
              this.products = resultProductsApi.data.data.map((e) => {
                return { key: e.id, dataValue: e.name };
              });
            }
          } catch (e) {
            console.log(e);
          }

          try {
            const resultStoresApi = await axios.get(endpointStores);

            if (resultStoresApi.data) {
              this.shops = resultStoresApi.data.data;
            }
          } catch (e) {
            console.log(e);
          }

          try {
            let result = await axios.get(this['privacy-check']);

            if (result.data) {
              this.show_rules = !result.data.contest_rule_flag; // return true if the user has already accepted the rules, false otherwise, show the rules only when the returned value is false
            }
          } catch (e) {
            console.log(e);
          }
        }
      },
      immediate: true,
    },
    loaded: {
      handler(val) {
        if (val) {
          if (!this.user) {
            this.globalErrors.push({
              condition: true,
              message: this.contentData.global_error_logged,
            });
          } else if (this.user.age < this['legal-age']) {
            this.globalErrors.push({
              condition: true,
              message: this.contentData.global_error_legal_age,
            });
          } else {
            this.canParticipate = true;
          }
        }
      },
      immediate: true,
    },
  },
  validations() {
    let validations = {
      receipt_product: {
        required,
        serverError: checkServerErrors('receipt_product'),
      },
      selected_partner: {
        required,
        serverError: checkServerErrors('selected_partner'),
      },
      receipt_type: {
        required,
        serverError: checkServerErrors('receipt_type'),
      },
      receipt_store: {
        required,
        serverError: checkServerErrors('receipt_store'),
      },
      receipt_front_photo: {
        required,
        mustBeImage: (val) => {
          return (
            !helpers.req(val) ||
            val.type === 'image/jpeg' ||
            val.type === 'image/jpg' ||
            val.type === 'image/png'
          );
        },
        validSize: (val) => {
          const mb = 3 * 1024 * 1024;
          return !helpers.req(val) || val.size <= mb;
        },
        serverError: checkServerErrors('receipt_front_photo'),
      },
    };
    if (this.show_rules) {
      validations = {
        ...validations,
        contest_rules_accepted: {
          mustBeChecked: (val) => !!val,
          serverError: checkServerErrors('contest_rules_accepted'),
        },
      };
    } else {
      validations = {
        ...validations,
        contest_rules_accepted: {},
      };
    }

    return validations;
  },
  created() {
    this.setInvertedHeader(true);
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    onSetShopType() {
      this.receipt_store = null;
    },
    onFileChange(f) {
      this.receipt_front_photo = f;
      this.$v.receipt_front_photo.$touch();
    },
    async onSubmit() {
      if (!this.submitted && !this.disableSubmit) {
        this.submitted = true;
        this.spinner = true;
        this.globalErrors = [];

        try {
          let formData = new FormData();
          formData.append('receipt_product', this.receipt_product);
          formData.append('receipt_type', this.receipt_type);
          formData.append('receipt_store', this.receipt_store);
          formData.append('partner_id', this.selected_partner);
          formData.append(
            'receipt_front_photo',
            this.$refs.$waUpload.imagePreview
          );
          formData.append(
            'contest_rules_accepted',
            this.contest_rules_accepted
          );

          let result = await axios.post(this['join-api'], formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (result.data) {
            // Contest completed
            await this.$store.dispatch('setUnlockedView', 'thank-you');
            // Navigate
            await this.$router.push({ name: 'thank-you' });
          }
        } catch (e) {
          let properties = Object.getOwnPropertyNames(e);
          properties.forEach((p) => {
            let prop = Object.getOwnPropertyDescriptor(e, p);
            if (prop && prop.value) {
              if (p === 'response') {
                if (prop.value.status === 400) {
                  // Validation errors
                  /* expected response
                  {"username":["Questo campo non può essere omesso","Questo campo non può essere omesso 2"],"description":["Questo campo non può essere omesso"]};
                   */
                  for (const [property, value] of Object.entries(
                    prop.value.data
                  )) {
                    if (property == 'non_field_errors') {
                      // Global validation errors
                      this.globalErrors.push({
                        condition: true,
                        message: this.contentData[value],
                      });
                    } else {
                      let temp = {};
                      temp[property] = value;
                      this.serverErrors = { ...this.serverErrors, ...temp };
                    }
                  }
                  Object.keys(this.serverErrors).forEach((key) => {
                    const unwatch = this.$watch(key, (newVal, oldVal) => {
                      if (newVal !== oldVal) {
                        Vue.delete(this.serverErrors, key);
                        setTimeout(() => {
                          this.$v[key].$touch();
                        }, 0);
                        if (unwatch) {
                          unwatch();
                        }
                      }
                    });
                  });

                  // Renew the submission
                  this.submitted = false;
                } else if (
                  prop.value.status === 401 ||
                  prop.value.status === 403
                ) {
                  this.globalErrors.push({
                    condition: true,
                    message: prop.value.data.message,
                  });
                } else {
                  // Other errors
                  this.globalErrors.push({
                    condition: true,
                    message: prop.value.statusText,
                  });
                }
              }
            }
          });
        } finally {
          this.spinner = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaPm9LeisureAndFunVerify {
  .icon {
    width: 48px;
    height: 48px;

    @media (min-width: $bootstrap-md) {
      width: 64px;
      height: 64px;
    }
  }

  .global-error {
    display: block;
    padding-top: 14px;
    color: $color-informative-red;
    text-align: left;
  }

  .img-fluid {
    @include media-breakpoint-up(sm) {
      width: 70%;
    }
  }

  ::v-deep .form-check-label {
    a {
      text-decoration: underline;
    }
  }
}
</style>
