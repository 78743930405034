<template>
  <div class="WaHighlightCarousel">
    <div class="component-wrapper padding-top-m">
      <div v-if="!!($slots['text'] || [])[0]" class="text">
        <slot name="text" />
      </div>
      <div class="container-fluid">
        <div ref="$scroller" class="swiper-container">
          <button
            ref="$prev"
            type="button"
            class="swiper-button swiper-button-prev"
            :style="arrowsStyle"
          >
            <svg width="24" height="24">
              <use href="#icon-arrow-angle-right" fill="currentColor" />
            </svg>
          </button>
          <button
            ref="$next"
            type="button"
            class="swiper-button swiper-button-next"
            :style="arrowsStyle"
          >
            <svg width="24" height="24">
              <use href="#icon-arrow-angle-right" fill="currentColor" />
            </svg>
          </button>
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) in items"
              :key="index"
              class="swiper-slide"
            >
              <wa-highlight-carousel-item ref="$item" :data-conf="item" />
            </div>
          </div>
        </div>
        <div
          v-if="!!($slots['cta'] || [])[0]"
          class="cta-wrapper padding-top-l"
        >
          <slot name="cta" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import waHighlightCarouselItem from './wa-highlight-carousel-item.vue';

export default {
  name: 'WaHighlightCarousel',
  components: { waHighlightCarouselItem },
  props: {
    dataItemsJson: { type: [Array, String], required: true },
  },
  data() {
    return {
      swiper: null,
      imageHeight: 0,
    };
  },
  computed: {
    items() {
      return typeof this.dataItemsJson === 'string'
        ? JSON.parse(this.dataItemsJson)
        : this.dataItemsJson;
    },
    arrowsStyle() {
      return { top: `${this.imageHeight / 2}px` };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.swiper = new Swiper(this.$refs.$scroller, {
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        slideToClickedSlide: true,
        navigation: {
          prevEl: this.$refs.$prev,
          nextEl: this.$refs.$next,
        },
        breakpoints: {
          768: {
            slidesPerView: 2.05,
          },
          1024: {
            slidesPerView: 2.495,
          },
          1440: {
            slidesPerView: 2.465,
          },
        },
        on: {
          init: () => {
            this.updateImageHeight();
          },
          resize: () => {
            this.updateImageHeight();
          },
        },
      });
    });
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  },
  methods: {
    updateImageHeight() {
      this.imageHeight = this.$refs.$item[0].$refs.$image.clientHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaHighlightCarousel {
  overflow: hidden;
}

.component-wrapper {
  position: relative;

  > .container-fluid {
    position: relative;
    z-index: 2;
  }
}

.swiper-container {
  overflow: visible;

  > .swiper-wrapper {
    position: relative;
    z-index: 1;
  }
}

.text {
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;

  .container-fluid {
    * {
      margin: 0;
      padding: 0;
    }
  }
}

.swiper-slide {
  box-sizing: border-box;
}

.swiper-slide-duplicate-prev,
.swiper-slide-prev {
  ::v-deep {
    .image {
      img {
        margin-left: -5%;
      }
    }
  }
}

.swiper-slide-duplicate-next,
.swiper-slide-next {
  ::v-deep {
    .image {
      img {
        margin-left: 5%;
      }
    }
  }
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
  ::v-deep {
    .image {
      img {
        width: 100%;
      }
    }

    .WaHighlightCarouselItem {
      pointer-events: auto;
      cursor: pointer;

      &:hover {
        .image {
          transform: scale(1.05);
        }
      }

      .info {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
  }
}

.swiper-button {
  padding: 0;
  border: 0;
  background: rgba($color-black, 0.5);
  color: rgba($color-white, 0.75);
  font-size: 14px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  position: absolute;
  right: -30px;
  top: 50%;
  z-index: 2;
  margin-top: -20px;
  margin-right: 16px;

  @include media-breakpoint-up(sm) {
    right: -60px;
    margin-right: 24px;
  }

  @include media-breakpoint-up(md) {
    margin-right: 32px;
  }

  @include media-breakpoint-up(lg) {
    right: -80px;
  }

  &:hover {
    background-color: $color-black;
  }

  svg {
    display: block;
    margin-right: -1px;
    pointer-events: none;
  }

  &.swiper-button-prev {
    right: auto;
    left: -30px;
    margin-right: 0;
    margin-left: 16px;

    @include media-breakpoint-up(sm) {
      left: -60px;
      margin-left: 24px;
    }

    @include media-breakpoint-up(md) {
      margin-left: 32px;
    }

    @include media-breakpoint-up(lg) {
      left: -80px;
    }

    svg {
      transform: rotate(180deg);
      margin-right: 2px;
    }
  }
}

.cta-wrapper {
  text-align: center;

  @include media-breakpoint-up(sm) {
    text-align: right;
  }
}
</style>
