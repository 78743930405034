var render = function (_h,_vm) {var _c=_vm._c;return _c(_vm.$options.isButton(_vm.data)
      ? 'button'
      : _vm.props.dataType === 'router-link'
      ? 'router-link'
      : 'a',_vm._g(_vm._b({ref:_vm.data.ref,tag:"component",class:[
    'WaCta typo-a-8 typo-heavy',
    _vm.data.staticClass,
    _vm.data.class,
    _vm.$options.isButton(_vm.data)
      ? 'fullWidthOnMobile buttonComponent'
      : 'linkComponent' ],style:([_vm.data.style, _vm.data.staticStyle]),attrs:{"href":_vm.$options.isButton(_vm.data)
      ? null
      : _vm.props.dataForceClick === 'true'
      ? null
      : _vm.props.dataHref,"to":_vm.props.dataType === 'router-link' ? _vm.props.dataTo : null,"target":_vm.$options.isButton(_vm.data) ? null : _vm.props.dataTarget,"rel":_vm.$options.isButton(_vm.data) ? null : _vm.props.dataRel,"type":_vm.$options.isButton(_vm.data) ? _vm.props.dataType : null,"disabled":_vm.$options.isButton(_vm.data) ? _vm.props.dataDisabled : null},on:{"click":function($event){_vm.props.dataHref
      ? _vm.$options.navigate(
          $event,
          _vm.$options.isButton(_vm.data),
          _vm.props.dataHref,
          _vm.props.dataTarget,
          _vm.props.dataIsContext,
          _vm.props.dataContestName,
          _vm.props.dataForceClick
        )
      : null}}},'component',_vm.data.attrs,false),_vm.listeners),[_c('span',[(_vm.props.dataIconId !== '' && _vm.$options.hasIconBefore(_vm.data))?_c('svg',{staticClass:"inverted-link svgWrapper",attrs:{"width":_vm.$options.getIconSize(_vm.$options.isButton(_vm.data)).width,"height":_vm.$options.getIconSize(_vm.$options.isButton(_vm.data)).height}},[_c('use',{attrs:{"href":("#" + (_vm.props.dataIconId)),"fill":"currentColor"}})]):_vm._e(),_vm._t("default"),(_vm.props.dataIconId !== '' && !_vm.$options.hasIconBefore(_vm.data))?_c('svg',{staticClass:"svgWrapper",attrs:{"width":_vm.$options.getIconSize(_vm.$options.isButton(_vm.data)).width,"height":_vm.$options.getIconSize(_vm.$options.isButton(_vm.data)).height}},[_c('use',{attrs:{"href":("#" + (_vm.props.dataIconId)),"fill":"currentColor"}})]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }