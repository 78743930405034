import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    //checked from data attribute of wa-config component
    'ch-key-pm9-save-the-insects': '',
    'enter-contest-api': '',
    'landing-page-url': '',
    'list-map-pois-api': '',
    'list-products-api': '',
    'list-store-api': '',
    'participate-page-url': '',
    'privacy-check-api': '',
    'reg-source-pm9-save-the-insects': '',
    //checked from data attribute of contest component
    'i18n': {
      participate: {
        hero_title: '',
        hero_title_small: '',
        hero_description: '',
        product_heading: '',
        products_error_required: '',
        data_info_text: '',
        data_info_text_remove: '',
        data_total_products: '',
        shop_type_heading: '',
        shop_type_description: '',
        shop_type_store_label: '',
        shop_type_online_label: '',
        shop_type_error_required: '',
        shop_label: '',
        shop_placeholder: '',
        shop_error_required: '',
        picture_heading: '',
        picture_description: '',
        picture_label: '',
        picture_label_small: '',
        picture_label_hover: '',
        picture_cta: '',
        picture_helper: '',
        picture_helper_small: '',
        picture_error_format: '',
        picture_error_size: '',
        picture_error_required: '',
        picture_instruction_heading: '',
        picture_instruction_legend_1: '',
        picture_instruction_legend_2: '',
        picture_instruction_legend_3: '',
        picture_instruction_legend_4: '',
        picture_instruction_legend_5: '',
        picture_instruction_hint_1: '',
        picture_instruction_hint_2: '',
        picture_instruction_hint_3: '',
        picture_instruction_info_heading: '',
        picture_instruction_info_1: '',
        picture_instruction_info_2: '',
        rules_label: '',
        rules_href: '',
        privacy_href: '',
        rules_error_required: '',
        submit_cta: '',
        global_error_logged: '',
        global_error_modal_fail_title: '',
        global_error_modal_fail_content: '',
        global_error_missing_parameters_in_configuration: '',
        global_error_inactive_contest: '',
        global_error_external_server_communication_failed: '',
        categories_placeholder: '',
        products_placeholder: '',
      },
    },
  },
  actions,
  mutations,
  getters,
};
