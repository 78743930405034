<template>
  <wa-side-backgrounds
    :data-background-right="`${$root.$context.staticRoot}project/img/contact-form/img-right.jpg`"
    :data-background-left="`${$root.$context.staticRoot}project/img/contact-form/img-left.jpg`"
    data-width-left="302"
    data-height-left="400"
    data-width-right="302"
    data-height-right="400"
  >
    <div class="WaContactFormError">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-12 d-flex flex-column align-items-center justify-content-center text-color-secondary-item"
          >
            <svg class="icon">
              <use href="#icon-triangle-error" fill="currentColor" />
            </svg>
            <div
              class="title typo-w typo-w-4 typo-bold text-center"
              v-html="contentData.step_error_title"
            />
            <div
              class="buttons d-flex flex-column flex-sm-row align-items-center justify-content-center"
            >
              <wa-cta class="primary" :data-href="home_page_url">
                {{ contentData.back_to_homepage_cta_label }}
              </wa-cta>
            </div>
          </div>
        </div>
      </div>
    </div>
  </wa-side-backgrounds>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'WaContactFormError',
  computed: {
    ...mapState('config', ['home_page_url']),
    ...mapState('contact', ['contentData']),
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaContactFormError {
  .icon {
    width: 48px;
    height: 48px;
  }

  .icon,
  .title {
    margin: 0 0 16px;

    @media (min-width: $bootstrap-sm) {
      margin: 0 0 24px;
    }

    @media (min-width: $bootstrap-md) {
      margin: 0 0 32px;
    }
  }

  .buttons {
    /deep/ .WaCta {
      margin: 48px 16px 0;

      @media (min-width: $bootstrap-sm) {
        margin: 40px 12px 0;
      }
    }
  }
}
</style>
