var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ContactFormTriagePromotionsCmp"},_vm._l((_vm.items),function(item){return _c('router-link',{key:("list-item-" + (item.id)),staticClass:"list-item",attrs:{"to":{
      name: _vm.routes.triage_promotions.controls.selectionCtaFrom.selectionCtaGoToName.find(
        function (gotoObj) { return gotoObj.triage_type === _vm.queryString.triage_type; }
      ).name,
      query: Object.assign({}, _vm.queryString,
        {triage_promotions: item.id,
        page_order: ((_vm.queryString.page_order) + "," + (_vm.routes.triage_promotions.name))}),
    }}},[_c('wa-cta-box',{staticClass:"contact-form-cta-box text-color-blue-logo",attrs:{"data-id-icon":item.icon.svgId},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h4',{staticClass:"typo-a typo-a-6 typo-medium margin-bottom-0"},[_vm._v(" "+_vm._s(item.label)+" ")])]},proxy:true}],null,true)})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }