import Vue from 'vue';

const StringSupport = Vue.extend({
  methods: {
    $_stringSupport_interpolate(fmt, obj) {
      return fmt.replace(/%ph%/g, () => {
        return String(obj.shift());
      });
    },
  },
});

export default StringSupport;
