import * as types from '../mutation_types';

export default {
  async setCurrentStep({ commit }, currentStep) {
    commit(types.SET_CURRENT_STEP, currentStep);
  },
  async setFormData({ commit }, obj) {
    commit(types.SET_FORM_DATA, obj);
  },
  async setProductDetail({ commit }, obj) {
    commit(types.SET_PRODUCT_DETAIL, obj);
  },
  async setHeight({ commit }, value) {
    commit(types.SET_MIN_HEIGHT, value);
  },
  async setHomeVisited({ commit }, value) {
    commit(types.SET_HOME_VISITED, value);
  },
  async setSelectsApi({ commit }, value) {
    commit(types.SET_SELECTS_API, value);
  },
  async setHomeInfo({ commit }, value) {
    commit(types.SET_HOME_INFO, value);
  },
  async setContentData({ commit }, obj) {
    commit(types.SET_CONTENT_DATA, obj);
  },
  async setFieldsInfos({ commit }, obj) {
    commit(types.SET_FIELDS_INFOS, obj);
  },
  async setJekoIntegration({ commit }, obj) {
    commit(types.SET_JEKO_INTEGRATION, obj);
  },
};
