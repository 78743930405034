<template>
  <div class="ContactFormTicketingThirdLevelCmp">
    <router-link
      v-for="item in items"
      :key="`list-item-${item.slug}`"
      class="list-item"
      :to="{
        name: getThirdLevelGoToName(queryString, routes),
        query: {
          ...queryString,
          ticketing_third_level: item.id,
          page_order: `${queryString.page_order},${routes.ticketing_third_level.name}`,
        },
      }"
    >
      <wa-cta-box class="contact-form-cta-box text-color-blue-logo">
        <template #title>
          <h4 class="typo-a typo-a-6 typo-medium margin-bottom-0">
            {{ item.label }}
          </h4>
        </template>
      </wa-cta-box>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContactForm2024Mixin from '@mixins/contact_form_2024_mixin';
const debug = false;

export default {
  name: 'ContactFormTicketingThirdLevelCmp',
  mixins: [ContactForm2024Mixin],
  computed: {
    ...mapState('contactForm2024', ['routes', 'queryString']),
    shouldSkipSelection() {
      /**
       * commento(03) + prodotti sempre
       * domanda(01) + prodotti solo se uno
       * problema(02) + prodotti solo se uno
       **/
      const items = this.items;
      const qs = this.queryString;
      debug && console.log('items', items);
      return (
        (items.length === 1 &&
          qs.triage_topic === 'PRODUCTS' &&
          qs.triage_type === '01' &&
          qs.canSkipSelection) ||
        (items.length === 1 &&
          qs.triage_type === '02' &&
          qs.triage_topic === 'PRODUCTS' &&
          qs.canSkipSelection) ||
        (qs.triage_type === '03' &&
          qs.triage_topic === 'PRODUCTS' &&
          qs.canSkipSelection)
      );
    },
    valueToPassIfSkipping() {
      return this.items.length === 1
        ? this.items[0].id
        : this.items.find((cta) => cta.is_default_for_suggestion).id;
    },
    items() {
      const modelName =
        this.routes?.ticketing_third_level?.controls?.selectionCtasFrom
          ?.selectionCtasItems;

      const third_level_group = modelName
        ? this.$store?.state?.contactForm2024[modelName]
        : [];

      return third_level_group.filter(
        (e) => e.second_level_group === this.queryString?.ticketing_second_level
      );
    },
  },
  mounted: function () {
    if (this.shouldSkipSelection) {
      this.queryString.hasSkippedStep3 = true;
      delete this.queryString.canSkipSelection;
      this.navigateForward();
    } else {
      this.queryString.hasSkippedStep3 = true;
      delete this.queryString.canSkipSelection;
    }
  },
  methods: {
    navigateForward: function () {
      this.$router.push({
        name: this.getThirdLevelGoToName(this.queryString, this.routes),
        query: {
          ...this.queryString,
          ticketing_third_level: this.valueToPassIfSkipping,
          page_order: `${this.queryString.page_order},${this.routes.ticketing_third_level.name}`,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormTicketingThirdLevelCmp {
  .list-item {
    display: block;
    text-decoration: none;

    & ~ .list-item {
      padding-top: var(--contact-form-list-item-padding-top, 0);
    }
  }
}
</style>
