import * as types from './mutation_types';

export default {
  setAppReady({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_APP_READY, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  setStoreSetupCompleted({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_STORE_SETUP_COMPLETED, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  setPageSetupCompleted({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_PAGE_SETUP_COMPLETED, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  setLoading({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_LOADING, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  setUnlockedView({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_UNLOCKED_VIEW, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
  setUnlockedWaitingView({ commit }, payload) {
    return new Promise((resolve, reject) => {
      try {
        commit(types.SET_UNLOCKED_WAITING_VIEW, payload);
        resolve();
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },
};
