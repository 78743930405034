<template>
  <div
    id="turnstile-container"
    ref="turnstile"
    class="cf-turnstile"
    :data-sitekey="dataKey"
  ></div>
</template>

<script>
export default {
  name: 'TurnstileCmp',
  props: {
    dataKey: {
      type: String,
      default: '1x00000000000000000000AA',
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.initTurnstile();
  },
  beforeDestroy() {
    this.turnstileRemoved('#turnstile-container');
  },
  methods: {
    turnstileRender(element) {
      this.recaptcha = null;
      this.$emit('setFormData', this.recaptcha);

      //   this.setFormData({
      //     recaptcha: this.recaptcha,
      //   });
      window.turnstile.render(element, {
        // sitekey: '<YOUR_SITE_KEY>',
        'error-callback': () => {
          document.querySelector('#turnstile-container')
            ? window.turnstile.reset('#turnstile-container')
            : null;
        },
        'callback': (token) => {
          this.callbackTurnstile(token);
        },
      });
    },
    turnstileRemoved(element) {
      this.recaptcha = null;
      this.$emit('setFormData', this.recaptcha);
      window.turnstile.remove(element);
    },
    callbackTurnstile(token) {
      this.recaptcha = token;
      this.$emit('setFormData', this.recaptcha);
    },
    initTurnstile() {
     //console.log('INIT ----- initTurnstile', window.turnstile);
      if (window.turnstile) {
        this.turnstileRender('#turnstile-container');
        return;
      }
      window.onloadTurnstileCallback = () => {
        //console.log('CALLBACK ----- initTurnstile', window.turnstile);
        this.turnstileRender('#turnstile-container');
      };
      const script = document.createElement('script');
      const turnstileSrc =
        'https://challenges.cloudflare.com/turnstile/v0/api.js';
      const callback = 'onloadTurnstileCallback';
      const compat = ''; // "&compat=recaptcha";
      const render = '&render=explicit';

      script.src = `${turnstileSrc}?onload=${callback}${compat}${render}`;
      script.async = true;
      script.defer = true;

      document.head.appendChild(script);
    },
  },
};
</script>
