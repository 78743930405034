<template functional>
  <div
    :ref="data.ref"
    :class="['WaFreeText', 'wa-wysiwyg', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot name="content_wysiwyg" />
  </div>
</template>

<script>
export default {
  name: 'WaFreeText',
  functional: true,
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables.scss';
</style>
