import { render, staticRenderFns } from "./wa-pm5-6-sandwich-summer-campaign-cta.vue?vue&type=template&id=43fdfad0&scoped=true&functional=true&"
import script from "./wa-pm5-6-sandwich-summer-campaign-cta.vue?vue&type=script&lang=js&"
export * from "./wa-pm5-6-sandwich-summer-campaign-cta.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "43fdfad0",
  null
  
)

export default component.exports