<template>
  <a
    :href="conf.card_href"
    :class="[
      'WaGenericCard',
      'cardLink',
      { hasTag: isVisibleTag, hasBorder: isVisibleBorder },
    ]"
  >
    <!--
      Variants:
      - ratio-4-3 (default) set the aspect ratio at 4:3
      - ratio-16-9 set the aspect ratio at 16:9

      Support Classes:
      - 'hasTag' set the component tag visible
      - 'hasBorder' set the border around image visible
    -->

    <figure v-if="conf.card_img_src" class="imgWrapper">
      <!-- Component Tag if prop label is full -->
      <span v-if="isVisibleTag" class="cardLabel">
        <wa-tag
          :class="[`${conf.label_color}`, `${conf.label_background_color}`]"
        >
          {{ conf.label }}
        </wa-tag>
      </span>
      <ratio-size-box
        :data-width-ratio-component="alternativeRatio ? 16 : 4"
        :data-height-ratio-component="alternativeRatio ? 9 : 3"
        data-vertical-align-content="center"
        data-crop="cover"
      >
        <img
          class="pic imageHover"
          :src="conf.card_img_src"
          :alt="conf.card_img_alt"
        />
      </ratio-size-box>
    </figure>

    <div class="contentCard">
      <component
        :is="titleTag"
        :class="[
          'genericCardTitle',
          'typo-a-5',
          'margin-top-s',
          conf.card_title_color,
        ]"
      >
        <!-- Title text  -->
        {{ conf.card_title }}
      </component>

      <!-- Meal Type  -->
      <component
        :is="titleSmallTag"
        v-if="conf.card_small_title"
        :class="['smallTitle margin-top-xs', smallTitleColorClass]"
      >
        {{ titleSmall }}
      </component>
    </div>
  </a>
</template>

<script>
import moment from 'moment';
export default {
  name: 'WaGenericCard',
  props: {
    dataConf: {
      type: [Object, String],
      required: true,
    },
    dataType: {
      type: String,
      required: false,
      default: 'generic',
    },
    dataAlternativeRatio: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
  },
  computed: {
    smallTitleColorClass() {
      return this.conf.title_small_color ? this.conf.title_small_color : '';
    },
    alternativeRatio() {
      return this.$_cmsSupport_castPropToType(
        this.dataAlternativeRatio,
        'boolean'
      );
    },
    titleTag() {
      return this.conf.title_tag ? this.conf.title_tag : 'h3';
    },
    titleSmallTag() {
      return this.conf.title_small_tag ? this.conf.title_small_tag : 'div';
    },
    isVisibleTag() {
      return this.conf.has_label;
    },
    isVisibleBorder() {
      return this.conf.has_border;
    },
    titleSmall() {
      return this.dataType === 'news'
        ? moment(this.conf.card_small_title).format('LL')
        : this.conf.card_small_title;
    },
    conf() {
      return this.$_cmsSupport_castPropToType(this.dataConf, 'object');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaGenericCard {
  display: block;
  .imgWrapper {
    position: relative;
  }
  .imageHover {
    transition: all 0.4s ease;
  }

  &.cardLink {
    text-decoration: none;
    color: $color-black;
    border-radius: 4px;

    &:hover {
      text-decoration: none;
      cursor: pointer;

      .imageHover {
        transform: scale(1.1);
        border-radius: 4px;
      }
    }
  }

  /deep/.RatioSizeBox {
    border-radius: 4px;
  }

  .contentCard {
    .genericCardTitle {
      display: block;
      font-weight: $fh-medium;
    }

    .smallTitle {
      color: $color-grey-8;
      font-size: 16px;
    }
  }

  //Variant
  //show component tag
  &.hasTag {
    .cardLabel {
      display: block;
      position: absolute;
      right: 8px;
      top: 8px;
      z-index: 1;

      @media (min-width: $bootstrap-sm) {
        right: 12px;
        top: 12px;
      }

      @media (min-width: $bootstrap-md) {
        right: 16px;
        top: 16px;
      }
    }
  }

  //show border
  &.hasBorder {
    .imgWrapper {
      border: 1px solid $color-grey-4;
      border-radius: 4px;
    }
  }
}
</style>
