import { render, staticRenderFns } from "./wa-alert-banner.vue?vue&type=template&id=2f6d3c6b&scoped=true&"
import script from "./wa-alert-banner.vue?vue&type=script&lang=js&"
export * from "./wa-alert-banner.vue?vue&type=script&lang=js&"
import style0 from "./wa-alert-banner.vue?vue&type=style&index=0&id=2f6d3c6b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f6d3c6b",
  null
  
)

export default component.exports