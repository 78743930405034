var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"WaAllRecipes"},[_c('div',{staticClass:"container-fluid"},[(_vm.dataVariantClass === 'all_recipes')?_c('div',{staticClass:"row filters padding-bottom-l"},[_c('div',{staticClass:"col-12 col-md-6 d-flex justify-content-center"},[_c('div',{staticClass:"moodSelect"},[_c('wa-select',{staticClass:"big",attrs:{"data-show-label":false,"data-label":"","data-name":"moodSelect","data-option-list":_vm.moods,"data-placeholder":_vm.dataSelectMoodPlaceholder},on:{"change":_vm.onMoodChange},model:{value:(_vm.mood),callback:function ($$v) {_vm.mood=$$v},expression:"mood"}},[_c('template',{slot:"icon"},[_c('svg',{attrs:{"width":"24","height":"24"}},[_c('use',{attrs:{"href":"#icon-arrow-angle-down","fill":"currentColor"}})])])],2)],1)]),_c('div',{staticClass:"col-12 col-md-6 d-flex justify-content-center"},[_c('div',{staticClass:"mealSelect"},[_c('wa-select',{staticClass:"big",attrs:{"data-show-label":false,"data-label":"","data-disabled":false,"data-name":"mealSelect","data-placeholder":_vm.dataSelectMealPlaceholder,"data-option-list":_vm.meals},on:{"change":_vm.onMealChange},model:{value:(_vm.meal),callback:function ($$v) {_vm.meal=$$v},expression:"meal"}},[_c('template',{slot:"icon"},[_c('svg',{attrs:{"width":"24","height":"24"}},[_c('use',{attrs:{"href":"#icon-arrow-angle-down","fill":"currentColor"}})])])],2)],1)])]):_vm._e(),_c('div',[_c('transition-group',{staticClass:"recipesTransition row",attrs:{"name":"slide","mode":"out-in","duration":{ enter: 500, leave: 0 }}},_vm._l((_vm.recipes),function(recipe,i){return _c('div',{key:("recipe_card_" + i),class:[
            {
              'col-12 col-sm-6 col-md-4 padding-bottom-l':
                _vm.dataVariantClass === 'all_recipes',
              'col-12 col-sm-6 padding-top-l':
                _vm.dataVariantClass === 'chefs_recipes',
              'col-md-6': _vm.dataVariantClass === 'chefs_recipes' && i % 5 < 2,
              'col-md-4': _vm.dataVariantClass === 'chefs_recipes' && i % 5 >= 2,
            } ]},[(_vm.$root.isPowerReviewActive)?[_c('pr-category-review-wrapper',{ref:"prCRWs",refInFor:true,attrs:{"data-item":recipe}},[_c('wa-recipe-card',{attrs:{"data-conf":recipe,"data-alternative-ratio":_vm.dataVariantClass === 'chefs_recipes' && i % 5 < 2
                    ? true
                    : false}})],1)]:[_c('wa-recipe-card',{attrs:{"data-conf":recipe,"data-alternative-ratio":_vm.dataVariantClass === 'chefs_recipes' && i % 5 < 2
                  ? true
                  : false}})]],2)}),0)],1),(_vm.dataVariantClass === 'all_recipes')?_c('div',{staticClass:"row loadmore"},[_c('div',{staticClass:"col-12 text-center"},[(_vm.showLoadMore)?_c('wa-cta',{staticClass:"primary loadMoreBtn",on:{"click":function($event){$event.preventDefault();return _vm.loadMoreAndRenderReviewsWidgets($event)}}},[_vm._v(" "+_vm._s(_vm.$store.state.config.load_more_label)+" ")]):_vm._e()],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }