import qs from 'qs';

export const classChecker = function (from, to) {
  let fromIsArray = false;
  let toIsArray = false;
  let allClasses = [];
  let toPush = [];
  let toCheck = [];

  if (Array.isArray(from)) {
    fromIsArray = true;
  } else {
    if (typeof from !== 'string') {
      throw '[ERROR classChecker] From is not a string or an Array!';
    }
  }

  if (Array.isArray(to)) {
    toIsArray = true;
  } else {
    if (typeof to !== 'string') {
      throw '[ERROR classChecker] To is not a string or an Array!';
    }
  }

  fromIsArray ? (toPush = from.filter(Boolean)) : (toPush = [from]);

  toPush.forEach((el) => (allClasses = [...allClasses, ...el.split(' ')]));

  toIsArray ? (toCheck = to.filter(Boolean)) : (toCheck = [to]);

  return toCheck.map((cls) => allClasses.includes(cls)).indexOf(true) !== -1;
};

export const cleanUrl = (url) => {
  return url.indexOf('?') !== -1 ? url.split('?')[0] : url;
};

export const dirtySearch = (url) => {
  return url.indexOf('?') !== -1 ? url.split('?')[1] : '';
};
export const pureSearch = (dirtySearch) => {
  return dirtySearch.indexOf('#') !== -1
    ? dirtySearch.split('#')[0]
    : dirtySearch;
};
export const getQsObject = (querystring) => {
  return qs.parse(querystring);
};

export const urlDirtySearch = dirtySearch(window.location.href);
export const querystring = pureSearch(urlDirtySearch);
export const qsObj = getQsObject(querystring);

/*https://unicode.org/emoji/charts/full-emoji-list.html*/
/*https://www.w3schools.com/cssref/css_colors.asp*/
/* some icons for messages */
export const zzz = String.fromCodePoint('0x1F4A4');
export const bomb = String.fromCodePoint('0x1F4A3');
export const prohibited = String.fromCodePoint('0x1F6AB');
export const ok = String.fromCodePoint('0x2705');
export const rightArrow = String.fromCodePoint('0x1F4AB');
export const info = String.fromCodePoint('0x2139');
export const brain = String.fromCodePoint('0x1F9E0');
export const pop = String.fromCodePoint('0x1F389');
export const bell = String.fromCodePoint('0x1F514');
export const wait = String.fromCodePoint('0x23F8');

/*
    icon supports both strings '0x1F4A4' or unicodestrings String.fromCodePoint('0x1F4A4')
    available themeName: vue orange red blue
    message, label strings
    custom theme can be provided via theme params example:
        {
            theme : {
              backgroundColorLeft  : "#CCFF00",
              backgroundColorRight : "#FFCC00",
              colorLeft            : "#FF66CC",
              colorRight           : "#000000"
            }
        }

    usage: colorLog({label: 'router', message: `Router customer care`, themeName: 'orange'});
*/
export const colorLog = function ({
  icon = '',
  label = 'label',
  message = 'Message',
  status = '', //-1,0,1 -> rejected,pending,passed
  themeName,
  theme,
}) {
  const selectedTheme = themeName ? themeName : 'vue';
  const availableThemes = {
    vue: {
      backgroundColorLeft: 'rgb(53, 73, 94)',
      backgroundColorRight: 'rgb(65, 184, 131)',
      colorLeft: 'rgb(255, 255, 255)',
      colorRight: 'rgb(255, 255, 255)',
    },
    green: {
      backgroundColorLeft: 'rgb(65, 184, 131)',
      backgroundColorRight: '#ffffff',
      colorLeft: '#ffffff',
      colorRight: 'rgb(65, 184, 131)',
    },
    progress: {
      backgroundColorLeft: 'rgb(65, 184, 131)',
      backgroundColorRight: 'rgb(0, 0, 0)',
      colorLeft: 'rgb(255, 255, 255)',
      colorRight: 'rgb(255, 255, 255)',
    },
    orange: {
      backgroundColorLeft: 'rgb(94, 53, 53)',
      backgroundColorRight: 'rgb(236, 167, 10)',
      colorLeft: 'rgb(255, 255, 255)',
      colorRight: 'rgb(255, 255, 255)',
    },
    purple: {
      backgroundColorLeft: 'rgb(178, 105, 191)',
      backgroundColorRight: 'rgb(225, 200, 230)',
      colorLeft: 'rgb(255, 255, 255)',
      colorRight: 'rgb(0, 0, 0)',
    },
    purple2: {
      backgroundColorLeft: 'rgb(178, 105, 191)',
      backgroundColorRight: '#ffffff',
      colorLeft: '#ffffff',
      colorRight: 'rgb(178, 105, 191)',
    },
    red2: {
      backgroundColorLeft: 'rgb(94, 53, 53)',
      backgroundColorRight: 'rgb(255, 51, 17)',
      colorLeft: 'rgb(255, 255, 255)',
      colorRight: 'rgb(255, 255, 255)',
    },
    red: {
      backgroundColorLeft: 'rgb(156, 127, 61)',
      backgroundColorRight: 'rgb(255, 51, 17)',
      colorLeft: 'rgb(255, 255, 255)',
      colorRight: 'rgb(255, 255, 255)',
    },
    blue: {
      backgroundColorLeft: 'rgb(31, 53, 30)',
      backgroundColorRight: 'rgb(17, 136, 255)',
      colorLeft: 'rgb(255, 255, 255)',
      colorRight: 'rgb(255, 255, 255)',
    },
    blue2: {
      backgroundColorLeft: 'rgb(17, 136, 255)',
      backgroundColorRight: '#ffffff',
      colorLeft: '#ffffff',
      colorRight: 'rgb(17, 136, 255)',
    },
  };
  const statusColors = {
    pending: 'rgba(255, 205, 0)',
    rejected: 'rgba(255, 50, 0)',
    passed: 'rgba(76, 195, 0)',
  };
  let currentTheme;

  if (selectedTheme in availableThemes) {
    currentTheme = Object.assign({}, availableThemes[selectedTheme]);
  }
  if (theme) {
    currentTheme = Object.assign({}, currentTheme, theme);
  }

  const parsedIcon =
    icon !== ''
      ? icon.indexOf('0x') !== -1
        ? String.fromCodePoint(icon)
        : /\p{Extended_Pictographic}/u.test(icon)
        ? icon
        : ''
      : '';
  const styles = {};
  styles.styles1 = `background: ${currentTheme.backgroundColorLeft}; padding: 2px 6px; border-radius: 3px 0px 0px 3px; color: ${currentTheme.colorLeft};`;
  if (status !== '') {
    styles.styles2 = `background: ${currentTheme.backgroundColorRight}; padding: 2px 6px; border-radius: 0px 3px 3px 0px; color: ${currentTheme.colorRight};`;
    styles.styles3 = `background: #fff; padding: 2px 6px; color: #000;`;
    if (status === -1) {
      styles.styles4 = `background: ${statusColors.rejected}; padding: 2px; border-radius: 50%; color: #fff;`;
    } else if (status === 0) {
      styles.styles4 = `background: ${statusColors.pending}; padding: 2px; border-radius: 50%; color: #fff;`;
    } else {
      styles.styles4 = `background: ${statusColors.passed}; padding: 2px; border-radius: 50%; color: #fff;`;
    }
  } else {
    styles.styles2 = `background: ${currentTheme.backgroundColorRight}; padding: 2px 6px; border-radius: 0px 3px 3px 0px; color: ${currentTheme.colorRight};`;
  }
  const statusLabel = '  ';
  const content =
    parsedIcon !== ''
      ? status !== ''
        ? `${parsedIcon} %c${label}%c${message}%c %c${statusLabel}`
        : `${parsedIcon} %c${label}%c${message}`
      : status !== ''
      ? `%c${label}%c${message}%c %c${statusLabel}`
      : `%c${label}%c${message}`;

  status !== ''
    ? console.log(
        content,
        styles.styles1,
        styles.styles2,
        styles.styles3,
        styles.styles4
      )
    : console.log(content, styles.styles1, styles.styles2);
};

export const stringToFloatRounded = function (number, decimals) {
  return Number(
    Math.round(parseFloat(number) + 'e' + decimals) + 'e-' + decimals
  );
};

/* Errors Utils */
export class UnknownError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnknownError';
  }
}
export class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
  }
}
export class ApiError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ApiError';
  }
}
export const prettyPrintErrorProperties = function (e) {
  let properties = Object.getOwnPropertyNames(e);
  properties.forEach((p) => {
    let prop = Object.getOwnPropertyDescriptor(e, p);
    // eslint-disable-next-line
    console.log(p, '=>', prop);
  });
};

export let debug = false;
/* eslint-disable */
if(process.env && process.env.VUE_APP_ENV==='development' || process.env.VUE_APP_ENV==='stage'){
  debug = true;
}
