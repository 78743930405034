<template functional>
  <div
    :ref="data.ref"
    :class="['WaCtaInline', data.staticClass, data.class]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WaCtaInline',
  functional: true,
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaCtaInline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: $bootstrap-md) {
    flex-direction: row;
  }

  & > :first-child {
    margin: 0 0 16px 0;

    @media (min-width: $bootstrap-sm) {
      margin: 0 0 24px 0;
    }

    @media (min-width: $bootstrap-md) {
      margin: 0 32px 0 0;
    }
  }
}
</style>
