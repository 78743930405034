<template>
  <div class="WaPmextraNorwaySandwich">
    <wa-hero
      data-variant-class="center"
      :data-bg-src-image="require('@images/contest-norway-sandwich/hero.png')"
      :data-bg-mobile-src-image="
        require('@images/contest-norway-sandwich/hero.png')
      "
      :data-has-down-arrow="true"
    >
      <h1 slot="title" class="text-color-white">
        {{ i18nParticipate.hero_title }}
      </h1>

      <h4 slot="title-small" class="text-color-white">
        {{ i18nParticipate.hero_title_small }}
      </h4>

      <p slot="description" class="text-color-white">
        {{ i18nParticipate.hero_description }}
      </p>
    </wa-hero>
    <div class="container-fluid padding-top-xxl padding-bottom-xxl">
      <form ref="$form" class="WaPmextraNorwaySandwichForm" @submit.prevent>
        <div>
          <div class="row justify-content-center">
            <div
              class="col-12 col-md-10 col-lg-8 d-flex flex-column align-items-center justify-content-center text-color-sport-blue padding-bottom-l"
            >
              <span class="typo-w-4 typo-bold text-center margin-bottom-r">
                {{ i18nParticipate.contest_title }}
              </span>
              <div
                class="typo-a-8 typo-light text-color-grey-9 text-center noMBLastChild"
                v-html="i18nParticipate.contest_description"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
              <wa-regular-divider class="padding-top-m padding-bottom-m" />
            </div>
          </div>
          <div class="row justify-content-center">
            <div
              class="col-12 col-md-10 col-lg-8 d-flex flex-column padding-bottom-xs"
            >
              <span
                class="typo-a-6 typo-extra-bold margin-bottom-r text-color-grey-9"
              >
                {{ i18nParticipate.picture_heading }}
              </span>
              <span class="typo-a-9 typo-medium text-color-grey-8">
                {{ i18nParticipate.picture_description }}
              </span>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 padding-bottom-r">
              <wa-upload
                ref="$waUpload"
                v-model.trim="$v.user_contest_picture.$model"
                name="user_contest_picture"
                :label="i18nParticipate.picture_label"
                :label-smaller="i18nParticipate.picture_label_small"
                :label-on-hover="i18nParticipate.picture_label_hover"
                :cta-text="i18nParticipate.picture_cta"
                :helper-text="i18nParticipate.picture_helper"
                :helper-text-smaller="i18nParticipate.picture_helper_small"
                :data-error-occurred="$v.user_contest_picture.$error"
                :errors="[
                  {
                    condition: !$v.user_contest_picture.required,
                    message: i18nParticipate.picture_error_required,
                  },
                  {
                    condition: !$v.user_contest_picture.mustBeImage,
                    message: i18nParticipate.picture_error_format,
                  },
                  {
                    condition: !$v.user_contest_picture.validSize,
                    message: i18nParticipate.picture_error_size,
                  },
                  {
                    condition: !$v.user_contest_picture.serverError,
                    message: serverErrors.user_contest_picture,
                  },
                ]"
                @change="onFileChange"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 padding-bottom-m">
              <wa-accordion>
                <wa-accordion-item class="text-color-blue-logo margin-bottom-0">
                  <template slot="title">
                    <h4 class="typo-a-6 typo-medium margin-bottom-0">
                      {{ i18nParticipate.picture_instruction_heading }}
                    </h4>
                  </template>
                  <template slot="content">
                    <wa-free-text>
                      <template slot="content_wysiwyg">
                        <div class="padding-top-m padding-bottom-m text-center">
                          <img
                            v-if="receiptImg"
                            :src="receiptImg"
                            class="img-fluid"
                          />
                        </div>
                        <ol class="margin-top-0 margin-bottom-0">
                          <template v-for="(legend, idx) in legends">
                            <li
                              :key="`legend-${idx}`"
                              :class="[
                                'typo-a-7 typo-medium text-color-healty-green',
                                {
                                  'margin-bottom-0': idx === legends.length - 1,
                                },
                              ]"
                            >
                              {{ legend }}
                            </li>
                          </template>
                        </ol>
                        <wa-regular-divider
                          v-if="legends.length > 0"
                          class="padding-top-r padding-bottom-r"
                        />
                        <ul class="margin-top-0 margin-bottom-0">
                          <template v-for="(hint, idx) in hints">
                            <li
                              :key="`hint-${idx}`"
                              :class="[
                                'typo-a-7 typo-light text-color-black',
                                {
                                  'margin-bottom-0': idx === hints.length - 1,
                                },
                              ]"
                            >
                              {{ hint }}
                            </li>
                          </template>
                        </ul>
                        <wa-regular-divider
                          v-if="hints.length > 0"
                          class="padding-top-r padding-bottom-r"
                        />
                        <p>
                          <template v-for="(info, idx) in infos">
                            <span
                              :key="`info-${idx}`"
                              :class="[
                                {
                                  'd-block typo-a-7 typo-medium text-color-grey-8 padding-bottom-xs':
                                    idx === 0,
                                  'd-block typo-a-9 typo-light text-color-black padding-bottom-xs':
                                    idx === 1,
                                  'd-block typo-a-9 typo-light text-color-black':
                                    idx === 2,
                                },
                              ]"
                            >
                              {{ info }}
                            </span>
                          </template>
                        </p>
                      </template>
                    </wa-free-text>
                  </template>
                </wa-accordion-item>
              </wa-accordion>
            </div>
          </div>
          <div class="row justify-content-center">
            <div
              class="col-12 col-md-10 col-lg-8 d-flex flex-column align-items-flex-start justify-content-center text-color-sport-blue"
            >
              <span class="typo-a-6 typo-bold padding-bottom-r">
                {{ i18nParticipate.contact_title }}
              </span>
              <div
                class="typo-a-7 typo-light text-color-grey-9 padding-bottom-r"
                v-html="i18nParticipate.contact_subtitle"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 padding-bottom-r">
              <div class="row justify-content-flex-start">
                <div class="col-12 col-md-6 col-lg-6 padding-bottom-r">
                  <wa-input
                    v-model.trim="$v.phone_number.$model"
                    :data-label="i18nParticipate.phone_placeholder"
                    data-name="phone_number"
                    class="phone-number"
                    :data-error-occurred="$v.phone_number.$error"
                    :data-errors="[
                      {
                        condition: !$v.phone_number.required,
                        message: i18nParticipate.phone_error_required,
                      },
                      {
                        condition: !$v.phone_number.regex,
                        message: i18nParticipate.phone_error_regex,
                      },
                      {
                        condition: !$v.phone_number.serverError,
                        message: serverErrors.phone_number,
                      },
                    ]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 padding-bottom-m">
            <wa-checkbox
              v-model.trim="$v.contest_rules_accepted.$model"
              data-name="contest_rules_accepted"
              :data-checkbox-list="[
                {
                  label: rulesLabel,
                },
              ]"
              :data-error-occurred="$v.contest_rules_accepted.$error"
              :data-errors="[
                {
                  condition: !$v.contest_rules_accepted.mustBeChecked,
                  message: i18nParticipate.rules_error_required,
                },
                {
                  condition: !$v.contest_rules_accepted.serverError,
                  message: serverErrors.contest_rules_accepted,
                },
              ]"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <div class="d-block d-sm-inline-block">
              <wa-cta
                class="primary"
                data-type="submit"
                :data-icon-id="spinner ? 'icon-ellipsis-loader' : ''"
                :data-disabled="disableSubmit || submitted"
                @click.prevent="onSubmit"
              >
                {{ i18nParticipate.submit_cta }}
              </wa-cta>
              <span
                v-for="(error, i) in hasGlobalErrors"
                :key="`error-${i}-${_uid}`"
                class="global-error typo-a-10 typo-medium"
                v-html="error.message"
              />
              <span
                v-if="showGlobalErrorFixFields"
                class="global-error typo-a-10 typo-medium"
                v-html="i18nParticipate.global_error_fix_fields"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { helpers, required } from 'vuelidate/lib/validators';
import LanguageSupport from '@mixins/language-support';
import { debug } from '@helpers/utils';
import axios from 'axios';

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'WaPmextraNorwaySandwich',
  mixins: [LanguageSupport],
  data() {
    return {
      /* form field */
      user_contest_picture: null,
      contest_rules_accepted: false,
      phone_number: null,
      /* after submit */
      submitted: false,
      spinner: false,
      serverErrors: {},
      globalErrors: [],
      showGlobalErrorFixFields: false,
    };
  },
  computed: {
    ...mapState(['storeSetupCompleted']),
    ...mapState('pmextraNorwaySandwich', ['enter-contest-api']),
    ...mapGetters('pmextraNorwaySandwich', ['i18nParticipate']),
    legends() {
      const items = [
        this.i18nParticipate.picture_instruction_legend_1,
        this.i18nParticipate.picture_instruction_legend_2,
        this.i18nParticipate.picture_instruction_legend_3,
        this.i18nParticipate.picture_instruction_legend_4,
        this.i18nParticipate.picture_instruction_legend_5,
      ];

      return items.filter((i) => i);
    },
    hints() {
      const items = [
        this.i18nParticipate.picture_instruction_hint_1,
        this.i18nParticipate.picture_instruction_hint_2,
        this.i18nParticipate.picture_instruction_hint_3,
      ];

      return items.filter((i) => i);
    },
    infos() {
      const items = [
        this.i18nParticipate.picture_instruction_info_heading,
        this.i18nParticipate.picture_instruction_info_1,
        this.i18nParticipate.picture_instruction_info_2,
      ];

      return items.filter((i) => i);
    },
    receiptImg() {
      try {
        return require(`@images/contest-norway-sandwich/picture.png`);
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    disableSubmit() {
      if (this.$v && (this.$v.$anyError || this.$v.$invalid)) {
        return true;
      }

      return false;
    },
    rulesLabel() {
      // reworked in component library
      let k = 0;
      let j = 0;
      return this.i18nParticipate.rules_label
        .replace(/%/g, () => {
          k++;
          if (k === 1) {
            return `<a class="typo-medium text-color-blue-logo" href='${this.i18nParticipate.rules_href}' target='_blank'>`;
          } else {
            return '</a>';
          }
        })
        .replace(/£/g, () => {
          j++;
          if (j === 1) {
            return `<a class="typo-medium text-color-blue-logo" href='${this.i18nParticipate.privacy_href}' target='_blank'>`;
          } else {
            return '</a>';
          }
        });
    },
    hasGlobalErrors() {
      if (this.globalErrors.length) {
        return this.globalErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  validations() {
    let validations = {
      user_contest_picture: {
        required,
        mustBeImage: (val) => {
          return (
            !helpers.req(val) ||
            val.type === 'image/jpeg' ||
            val.type === 'image/jpg' ||
            val.type === 'image/png'
          );
        },
        validSize: (val) => {
          const mb = 4 * 1024 * 1024;
          return !helpers.req(val) || val.size <= mb;
        },
        serverError: checkServerErrors('user_contest_picture'),
      },
      phone_number: {
        required,
        serverError: checkServerErrors('phone_number'),
        regex: (val) =>
          !helpers.req(val) || new RegExp(/^((4|9|5))[0-9]{7,11}$/).test(val),
      },
    };

    validations = {
      ...validations,
      contest_rules_accepted: {
        mustBeChecked: (val) => !!val,
        serverError: checkServerErrors('contest_rules_accepted'),
      },
    };

    return validations;
  },
  created() {
    this.setInvertedHeader(true);
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    onFileChange(f) {
      this.user_contest_picture = f;
      this.$v.user_contest_picture.$touch();
    },
    async onSubmit() {
      if (!this.submitted && !this.disableSubmit) {
        this.submitted = true;
        this.spinner = true;
        this.showGlobalErrorFixFields = false;
        this.globalErrors = [];

        try {
          let formData = {};

          formData.phone_number = this.phone_number;
          formData.user_contest_picture = this.$refs.$waUpload.imagePreview;
          formData.contest_rules_accepted = this.contest_rules_accepted;

          const result = await axios.post(this['enter-contest-api'], formData);
          if (result.data) {
            // Navigate
            // await this.$store.dispatch('setUnlockedWaitingView', 'waiting');
            // await this.$router.push({ name: 'waiting' });

            await this.$store.dispatch(
              'pmextraNorwaySandwich/setPmextraNorwaySandwichInitData',
              {
                json: result.data.data.animation_path,
                iw_won: result.data.data.iw_won,
                prize_label: result.data.data.prize_label,
              }
            );
            await this.$store.dispatch('setUnlockedView', 'thank-you');
            await this.$router.push({ name: 'thank-you' });
          }
        } catch (e) {
          let properties = Object.getOwnPropertyNames(e);
          properties.forEach((p) => {
            let prop = Object.getOwnPropertyDescriptor(e, p);
            if (prop && prop.value) {
              if (p === 'response') {
                if (prop.value.status === 400) {
                  // All errors are handled has 400
                  // promo engine status codes 40X 50X are proxed as 400

                  /* expected response for validation
                    {"username":["Questo campo non può essere omesso","Questo campo non può essere omesso 2"],"description":["Questo campo non può essere omesso"]};
                  */

                  /* expected response for other type of errors or generic non field errors
                    {“non_field_errors”:[“global_error_external_server_communication_failed”]}
                  */

                  let data = prop.value.data;
                  if ('errors' in data) {
                    data = data.errors;
                  }

                  for (const [property, value] of Object.entries(data)) {
                    if (property == 'non_field_errors') {
                      // Global validation errors
                      //this is an old code used for all the promos, luckly arrives always just one value in the array 'value' so
                      //this.i18nParticipate[value] works but if multiple values will arrives it will brake
                      this.globalErrors.push({
                        condition: true,
                        message: this.i18nParticipate[value]
                          ? this.i18nParticipate[value]
                          : value,
                      });
                    } else {
                      let temp = {};
                      //Here we removed some dead code about temp[property] = this.i18nParticipate[value] ? this.i18nParticipate[value]: value;
                      //First of all because this.i18nParticipate[value] will be always undefined, value is a string and not the key for the object i18nParticipate
                      //Second because we are not handling client errors between our frontend and our backend
                      //just between our frontend and proxied messages from promo engine backend
                      temp[property] = value;
                      this.serverErrors = { ...this.serverErrors, ...temp };
                      this.$v[property].$touch();
                    }
                  }
                  Object.keys(this.serverErrors).forEach((key) => {
                    const unwatch = this.$watch(key, (newVal, oldVal) => {
                      if (newVal !== oldVal) {
                        Vue.delete(this.serverErrors, key);
                        setTimeout(() => {
                          this.$v[key].$touch();
                        }, 0);
                        if (unwatch) {
                          unwatch();
                        }
                      }
                    });
                  });

                  // Renew the submission
                } else if (
                  prop.value.status === 401 ||
                  prop.value.status === 403
                ) {
                  // wrong configuration on our side because 401 & 403 are not implemented on the contest side
                  // if you receive one of this probably you should consider a mistake in settings.py (gigya wrongly enabled?)
                  // the following code is just for that but it should never happen
                  this.globalErrors.push({
                    condition: true,
                    message: prop.value.data.detail,
                  });
                } else {
                  // Other errors we just print the statusText that is always available in the response
                  // object and is auto generated from server responses
                  this.globalErrors.push({
                    condition: true,
                    message: prop.value.statusText,
                  });
                }
                this.showGlobalErrorFixFields = true;
                this.submitted = false;
              }
            }
          });
        } finally {
          this.spinner = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaPmextraNorwaySandwich {
  .icon {
    width: 48px;
    height: 48px;

    @media (min-width: $bootstrap-md) {
      width: 64px;
      height: 64px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .img-fluid {
    @include media-breakpoint-up(sm) {
      width: 70%;
    }
  }

  .global-error {
    display: block;
    padding-top: 14px;
    color: $color-informative-red;
    text-align: left;

    &.products-error {
      font-size: 13px;
    }
  }
}
</style>
