import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    //checked from data attribute of wa-config component

    'play': '',
    'personal_area_api': '',
    'all_products': '',
    'stores': '',
    'upload_file': '',
    'status': '',

    'privacy_check': '',
    'ch-key-camper': '',
    'reg-source-camper': '',
    'landing-page-url': '',
    'enter-contest-page-url': '',
    'show_personal_area': '',
    //'redeem-prize-page-url': '',

    //checked from data attribute of contest component
    'regex': {
      email: {
        regex: '',
        required: true,
      },
      first_name: {
        regex: '',
        required: true,
      },
      last_name: {
        regex: '',
        required: true,
      },
      phone: {
        regex: '',
        required: true,
      },
    },
    'i18n': {
      participate: {
        hero_title: '',
        hero_title_small: '',
        hero_description: '',
        product_heading: '',
        product_description: '',
        products_error_required: '',
        data_total_products: '',
        data_info_text: '',
        shop_type_heading: '',
        shop_type_description: '',
        shop_type_store_label: '',
        shop_type_online_label: '',
        shop_type_error_required: '',
        shop_label: '',
        shop_placeholder: '',
        shop_error_required: '',
        picture_heading: '',
        picture_description: '',
        picture_back_heading: '',
        picture_back_description: '',
        picture_label: '',
        picture_label_small: '',
        picture_label_hover: '',
        picture_cta: '',
        picture_helper: '',
        picture_helper_small: '',
        picture_error_format: '',
        picture_error_size: '',
        picture_error_required: '',
        picture_instruction_heading: '',
        picture_instruction_legend_1: '',
        picture_instruction_legend_2: '',
        picture_instruction_legend_3: '',
        picture_instruction_legend_4: '',
        picture_instruction_legend_5: '',
        picture_instruction_hint_1: '',
        picture_instruction_hint_2: '',
        picture_instruction_hint_3: '',
        picture_instruction_info_heading: '',
        picture_instruction_info_1: '',
        picture_instruction_info_2: '',
        date_label: '',
        date_helper: '',
        date_required: '',
        date_format: '',
        date_range: '',
        hours_label: '',
        hours_helper: '',
        hours_required: '',
        hours_format: '',
        hours_range: '',
        number_label: '',
        number_helper: '',
        number_required: '',
        number_format: '',
        number_length: '',
        total_amount_label: '',
        total_amount_helper: '',
        total_amount_required: '',
        total_amount_format: '',
        rules_label: '',
        rules_href: '',
        privacy_href: '',
        rules_error_required: '',
        submit_label: '',
        submit_cta: '',
        thank_you_title: '',
        thank_you_description: '',
        thank_you_cta: '',
        global_error_logged: '',
        global_error_fix_fields: '',
        global_server_error: '',
        store_not_found: '',
        receipt_number_fail: '',
        receipt_date_mismatch: '',
        duplicated_receipt: '',
        file_upload_error: '',
        promo_end: '',
        user_sess_error: '',
        day_digits_fail: '',
        month_digits_fail: '',
        year_digits_fail: '',
        hour_digits_fail: '',
        minute_digits_fail: '',
        cents_digits_fail: '',
        euro_fail: '',
        day_number_fail: '',
        month_number_fail: '',
        year_number_fail: '',
        date_fail: '',
        hour_fail: '',
        minutes_fail: '',
        time_fail: '',
        cents_fail: '',
        contest_start_date: '',
        contest_end_date: '',
      },
      prize: {
        hero_title: '',
        hero_small_title: '',
        hero_description: '',
        title: '',
        description: '',
        name_label: '',
        name_error_required: '',
        name_error_minLength: '',
        name_error_maxLength: '',
        name_error_regex: '',
        surname_label: '',
        surname_error_required: '',
        surname_error_minLength: '',
        surname_error_maxLength: '',
        surname_error_regex: '',
        email_label: '',
        tooltip_personal_area_text: '',
        prefix_label: '',
        prefix_default: '',
        phone_label: '',
        phone_error_required: '',
        phone_error_minLength: '',
        phone_error_maxLength: '',
        phone_error_regex: '',
        submit_label: '',
        submit_cta: '',
        thank_you_title: '',
        thank_you_description: '',
        thank_you_cta: '',
        global_error_logged: '',
        global_error_fix_fields: '',
        global_server_error: '',
        empty_phone: '',
        phone_not_numeric: '',
        promo_end: '',
        user_sess_error: '',
        unauthorized_token_ip: '',
      },
    },
  },
  actions,
  mutations,
  getters,
};
