<template>
  <div class="WaPm56SeptemberGameEnterContest">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WaPm56SeptemberGameEnterContest',
  props: {
    dataPromo: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    i18n() {
      return this.$_cmsSupport_castPropToType(this.dataPromo, 'object');
    },
  },
  created() {
    this.setPm56SeptemberGameI18nParticipate(this.i18n);
  },
  methods: {
    ...mapActions('pm56SeptemberGame', ['setPm56SeptemberGameI18nParticipate']),
  },
};
</script>

<style scoped></style>
