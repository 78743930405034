var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"$form",staticClass:"WaContactFormGeneric",on:{"submit":function($event){$event.preventDefault();}}},[(_vm.formConfig && _vm.formConfig.picture && _vm.formConfig.picture.is_visible)?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8 padding-bottom-r"},[_c('wa-upload',{ref:"$waUpload",attrs:{"name":"picture","label":_vm.dataLabel('picture', _vm.contentData.form_input_file_picture_label),"label-smaller":_vm.dataLabel(
            'picture',
            _vm.contentData.form_input_file_picture_label_small
          ),"label-on-hover":_vm.dataLabel('picture', _vm.contentData.form_input_file_picture_hover),"cta-text":_vm.contentData.form_input_file_picture_cta,"helper-text":_vm.contentData.form_input_file_picture_helper,"helper-text-smaller":_vm.contentData.form_input_file_picture_helper_small,"data-error-occurred":_vm.$v.picture.$error,"errors":_vm.dataErrors('picture', {}, [
            {
              condition: !_vm.$v.picture.mustBeImage,
              message: _vm.contentData.form_input_file_picture_error_format,
            },
            {
              condition: !_vm.$v.picture.validSize,
              message: _vm.contentData.form_input_file_picture_error_size,
            } ])},on:{"change":function($event){return _vm.onFieldChange('picture', $event)}},model:{value:(_vm.$v.picture.$model),callback:function ($$v) {_vm.$set(_vm.$v.picture, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.picture.$model"}})],1)]):_vm._e(),(
      _vm.formConfig &&
      _vm.formConfig.description &&
      _vm.formConfig.description.is_visible
    )?_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('wa-textarea',{attrs:{"data-name":"description","data-label":_vm.dataLabel(
            'description',
            _vm.contentData.form_textarea_description_label
          ),"data-error-occurred":_vm.$v.description.$error,"data-errors":_vm.dataErrors(
            'description',
            {
              required: _vm.contentData.form_textarea_description_error_required,
            },
            [
              {
                condition: !_vm.$v.description.maxLength,
                message:
                  _vm.contentData.form_textarea_description_error_max_length,
              } ]
          )},on:{"input":function($event){return _vm.onFieldChange('description', $event)}},model:{value:(_vm.$v.description.$model),callback:function ($$v) {_vm.$set(_vm.$v.description, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.description.$model"}})],1)]):_vm._e(),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8 padding-top-xs padding-bottom-r"},[_c('span',{staticClass:"typo-a-9 typo-light text-color-grey-8"},[_vm._v(" "+_vm._s(_vm.contentData.mandatory_field_indication_placeholder)+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('wa-cta',{staticClass:"primary",attrs:{"data-type":"submit","data-disabled":_vm.disableSubmit},on:{"click":function($event){$event.preventDefault();return _vm.onSubmitBtnClick($event)}}},[_vm._v(" "+_vm._s(_vm.contentData.continue_cta_label)+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }