<template>
  <div class="WaPm56CamperVerify">
    <wa-hero
      data-variant-class="center"
      :data-bg-src-image="require('@images/promo-camper/banner-bg.jpg')"
      :data-bg-mobile-src-image="
        require('@images/promo-camper/banner-bg-mobile.jpeg')
      "
      :data-has-down-arrow="true"
    >
      <h1 slot="title" class="text-color-white">
        {{ i18nParticipate.hero_title }}
      </h1>

      <h4 slot="title-small" class="text-color-white">
        {{ i18nParticipate.hero_title_small }}
      </h4>

      <p slot="description" class="text-color-white">
        {{ i18nParticipate.hero_description }}
      </p>
    </wa-hero>
    <div ref="scroll_here_after_ocr" class="container-fluid padding-top-xxl">
      <form ref="$form" class="WaPm56CamperVerifyForm" @submit.prevent>
        <div>
          <div class="row justify-content-center">
            <div
              class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-sport-blue"
            >
              <svg class="icon">
                <use href="#icon-wasa-boxed" fill="currentColor" />
              </svg>
              <span
                v-if="i18nParticipate.partecipate_heading && !ocr_result"
                class="margin-top-r typo-w-4 typo-bold text-center"
              >
                {{ i18nParticipate.partecipate_heading }}
              </span>
              <span
                v-if="i18nParticipate.product_heading && ocr_result"
                class="margin-top-r typo-w-4 typo-bold text-center"
              >
                {{ i18nParticipate.product_heading }}
              </span>
              <div
                v-if="i18nParticipate.product_description && ocr_result"
                class="margin-top-r typo-a-8 typo-light text-color-grey-9 text-center noMBLastChild"
                v-html="i18nParticipate.product_description"
              />
            </div>
          </div>
          <div v-if="!ocr_result" class="row">
            <div class="col-12 padding-bottom-m">
              <wa-accordion>
                <wa-accordion-item
                  class="text-color-blue-logo margin-bottom-0 margin-top-m"
                >
                  <template slot="title">
                    <h4 class="typo-a-6 typo-medium margin-bottom-0">
                      {{ i18nParticipate.picture_step_instruction_heading }}
                    </h4>
                  </template>
                  <template slot="content">
                    <div class="row padding-top-m">
                      <div
                        class="step-wrapper col-12 col-md-6 padding-bottom-xs"
                      >
                        <div class="step-img">
                          <img :src="stepImg('1')" alt="" />
                        </div>
                        <p
                          class="text-color-blue-logo typo-w-4 margin-top-xs margin-bottom-s"
                        >
                          1
                        </p>
                        <p class="text-color-black text-center">
                          {{ i18nParticipate.picture_instruction_hint_1 }}
                        </p>
                      </div>
                      <div
                        class="step-wrapper col-12 col-md-6 padding-bottom-xs"
                      >
                        <div class="step-img">
                          <img :src="stepImg('2')" alt="" />
                        </div>
                        <p
                          class="text-color-blue-logo typo-w-4 margin-top-xs margin-bottom-s"
                        >
                          2
                        </p>
                        <p class="text-color-black text-center">
                          {{ i18nParticipate.picture_instruction_hint_2 }}
                        </p>
                      </div>
                      <div
                        class="step-wrapper col-12 col-md-6 padding-bottom-xs"
                      >
                        <div class="step-img">
                          <img :src="stepImg('3')" alt="" />
                        </div>
                        <p
                          class="text-color-blue-logo typo-w-4 margin-top-xs margin-bottom-s"
                        >
                          3
                        </p>
                        <p class="text-color-black text-center">
                          {{ i18nParticipate.picture_instruction_hint_3 }}
                        </p>
                      </div>
                      <div
                        class="step-wrapper col-12 col-md-6 padding-bottom-xs"
                      >
                        <div class="step-img">
                          <img :src="stepImg('4')" alt="" />
                        </div>
                        <svg
                          class="info-step text-color-blue-logo typo-w-4 margin-top-xs margin-bottom-s"
                        >
                          <use
                            href="#icon-info-circle-fill"
                            fill="currentColor"
                          />
                        </svg>
                        <p class="text-color-black text-center">
                          {{ i18nParticipate.picture_instruction_info_1 }}
                        </p>
                      </div>
                    </div>
                  </template>
                </wa-accordion-item>
                <wa-accordion-item
                  class="text-color-blue-logo margin-bottom-0 margin-top-s"
                >
                  <template slot="title">
                    <h4 class="typo-a-6 typo-medium margin-bottom-0">
                      {{ i18nParticipate.picture_instruction_heading }}
                    </h4>
                  </template>
                  <template slot="content">
                    <wa-free-text>
                      <template slot="content_wysiwyg">
                        <div class="padding-top-m padding-bottom-m text-center">
                          <img
                            v-if="receiptImg"
                            :src="receiptImg"
                            class="img-fluid"
                          />
                        </div>
                        <ol class="margin-top-0 margin-bottom-0">
                          <li
                            class="typo-a-7 typo-medium text-color-sea-salt-blue"
                          >
                            {{ i18nParticipate.picture_instruction_legend_1 }}
                          </li>
                          <li
                            class="typo-a-7 typo-medium text-color-sea-salt-blue"
                          >
                            {{ i18nParticipate.picture_instruction_legend_2 }}
                          </li>
                          <li
                            class="typo-a-7 typo-medium text-color-sea-salt-blue"
                          >
                            {{ i18nParticipate.picture_instruction_legend_3 }}
                          </li>
                          <li
                            class="typo-a-7 typo-medium text-color-sea-salt-blue"
                          >
                            {{ i18nParticipate.picture_instruction_legend_4 }}
                          </li>
                          <li
                            class="typo-a-7 typo-medium text-color-sea-salt-blue margin-bottom-0"
                          >
                            {{ i18nParticipate.picture_instruction_legend_5 }}
                          </li>
                        </ol>
                        <wa-regular-divider
                          class="padding-top-r padding-bottom-r"
                        />
                        <ul class="margin-top-0 margin-bottom-0">
                          <li class="typo-a-7 typo-light text-color-black">
                            {{ i18nParticipate.picture_instruction_hint_1 }}
                          </li>
                          <li class="typo-a-7 typo-light text-color-black">
                            {{ i18nParticipate.picture_instruction_hint_2 }}
                          </li>
                          <li
                            class="typo-a-7 typo-light text-color-black margin-bottom-0"
                          >
                            {{ i18nParticipate.picture_instruction_hint_3 }}
                          </li>
                        </ul>
                        <wa-regular-divider
                          class="padding-top-r padding-bottom-r"
                        />
                        <p>
                          <span
                            class="d-block typo-a-7 typo-medium text-color-grey-8 padding-bottom-xs"
                          >
                            {{
                              i18nParticipate.picture_instruction_info_heading
                            }}
                          </span>
                          <span
                            class="d-block typo-a-9 typo-light text-color-black padding-bottom-xs"
                          >
                            {{ i18nParticipate.picture_instruction_info_1 }}
                          </span>
                          <span
                            class="d-block typo-a-9 typo-light text-color-black"
                          >
                            {{ i18nParticipate.picture_instruction_info_2 }}
                          </span>
                        </p>
                      </template>
                    </wa-free-text>
                  </template>
                </wa-accordion-item>
              </wa-accordion>
            </div>
          </div>
          <template v-if="ocr_result">
            <div class="row justify-content-center">
              <div class="col-auto padding-bottom-xl padding-top-xs">
                <wa-select
                  v-model.trim="$v.receipt_product.$model"
                  class="medium text-color-blue-logo"
                  :data-label="i18nParticipate.product_heading"
                  :data-show-label="false"
                  data-name="receipt_product"
                  :data-placeholder="i18nParticipate.product_placeholder"
                  :data-option-list="promoProducts"
                  :data-error-occurred="$v.receipt_product.$error"
                  :data-errors="[
                    {
                      condition: !$v.receipt_product.required,
                      message: i18nParticipate.products_error_required,
                    },
                    {
                      condition: !$v.receipt_product.serverError,
                      message: serverErrors.receipt_product,
                    },
                  ]"
                >
                  <template slot="icon">
                    <svg width="24" height="24">
                      <use href="#icon-arrow-angle-down" fill="currentColor" />
                    </svg>
                  </template>
                </wa-select>
              </div>
            </div>
            <div class="row justify-content-center margin-bottom-l">
              <div
                class="col-12 col-sm-8 col-md-6 d-flex flex-column align-items-center justify-content-center text-color-sport-blue"
              >
                <span
                  v-if="i18nParticipate.shop_type_heading"
                  class="margin-top-r typo-w-4 typo-bold text-center"
                >
                  {{ i18nParticipate.shop_type_heading }}
                </span>
                <div
                  v-if="i18nParticipate.shop_type_description"
                  class="margin-top-r typo-a-8 typo-light text-color-grey-9 text-center noMBLastChild"
                  v-html="i18nParticipate.shop_type_description"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-10 col-lg-8">
                <wa-radio-chooser
                  v-model.trim="$v.receipt_type.$model"
                  data-name="receipt_type"
                  :data-radio-list="[
                    {
                      value: 'standard',
                      label: i18nParticipate.shop_type_store_label,
                      iconId: 'icon-market',
                    },
                    {
                      value: 'electronic',
                      label: i18nParticipate.shop_type_online_label,
                      iconId: 'icon-world-hand-drawn',
                    },
                  ]"
                  :data-error-occurred="$v.receipt_type.$error"
                  :data-errors="[
                    {
                      condition: !$v.receipt_type.required,
                      message: i18nParticipate.shop_type_error_required,
                    },
                    {
                      condition: !$v.receipt_type.serverError,
                      message: serverErrors.receipt_type,
                    },
                  ]"
                  @change="onSetShopType"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-10 col-lg-8 padding-top-r">
                <wa-dropdown
                  v-model.trim="$v.receipt_store.$model"
                  data-name="receipt_store"
                  :data-label="i18nParticipate.shop_label"
                  :data-placeholder="i18nParticipate.shop_placeholder"
                  :data-option-list="filteredShops"
                  :data-error-occurred="$v.receipt_store.$error"
                  :data-errors="[
                    {
                      condition: !$v.receipt_store.required,
                      message: i18nParticipate.shop_error_required,
                    },
                    {
                      condition: !$v.receipt_store.serverError,
                      message: serverErrors.receipt_store,
                    },
                  ]"
                />
              </div>
            </div>
            <div class="row justify-content-center padding-top-r">
              <div class="col-12 col-md-5 col-lg-4 padding-bottom-r">
                <wa-input
                  v-model.trim="$v.date.$model"
                  :data-label="i18nParticipate.date_label"
                  data-name="date"
                  :data-helper-text="i18nParticipate.date_helper"
                  :data-error-occurred="$v.date.$error"
                  :data-errors="[
                    {
                      condition: !$v.date.serverError,
                      message: serverErrors.date,
                    },
                    {
                      condition: !$v.date.required,
                      message: i18nParticipate.date_required,
                    },
                    {
                      condition: !$v.date.regex,
                      message: i18nParticipate.date_format,
                    },
                    {
                      condition: !$v.date.range,
                      message: i18nParticipate.date_range,
                    },
                  ]"
                />
              </div>
              <div class="col-12 col-md-5 col-lg-4 padding-bottom-r">
                <wa-input
                  v-model.trim="$v.hours.$model"
                  :data-label="i18nParticipate.hours_label"
                  data-name="hours"
                  :data-helper-text="i18nParticipate.hours_helper"
                  :data-error-occurred="$v.hours.$error"
                  :data-errors="[
                    {
                      condition: !$v.hours.serverError,
                      message: serverErrors.hours,
                    },
                    {
                      condition: !$v.hours.required,
                      message: i18nParticipate.hours_required,
                    },
                    {
                      condition: !$v.hours.regex,
                      message: i18nParticipate.hours_format,
                    },
                  ]"
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-md-5 col-lg-4 padding-bottom-r">
                <wa-input
                  v-model.trim="$v.number.$model"
                  :data-label="i18nParticipate.number_label"
                  data-name="number"
                  :data-helper-text="i18nParticipate.number_helper"
                  :data-error-occurred="$v.number.$error"
                  :data-errors="[
                    {
                      condition: !$v.number.serverError,
                      message: serverErrors.number,
                    },
                    {
                      condition: !$v.number.required,
                      message: i18nParticipate.number_required,
                    },
                    {
                      condition: !$v.number.maxLen,
                      message: i18nParticipate.number_length,
                    },
                  ]"
                />
              </div>
              <div class="col-12 col-md-5 col-lg-4 padding-bottom-r">
                <wa-input
                  v-model.trim="$v.total_amount.$model"
                  :data-label="i18nParticipate.total_amount_label"
                  data-name="total_amount"
                  :data-helper-text="i18nParticipate.total_amount_helper"
                  :data-error-occurred="$v.total_amount.$error"
                  :data-errors="[
                    {
                      condition: !$v.total_amount.serverError,
                      message: serverErrors.total_amount,
                    },
                    {
                      condition: !$v.total_amount.required,
                      message: i18nParticipate.total_amount_required,
                    },
                    {
                      condition: !$v.total_amount.regex,
                      message: i18nParticipate.total_amount_format,
                    },
                  ]"
                />
              </div>
            </div>
          </template>
          <div v-if="!ocr_result" class="row justify-content-center">
            <div
              class="col-12 col-md-10 col-lg-8 d-flex flex-column padding-bottom-xs"
            >
              <span
                class="typo-a-6 typo-extra-bold margin-bottom-r text-color-grey-9"
              >
                {{ i18nParticipate.picture_heading }}
              </span>
              <span class="typo-a-9 typo-medium text-color-grey-8">
                {{ i18nParticipate.picture_description }}
              </span>
            </div>
          </div>
          <div v-if="!ocr_result" class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 padding-bottom-r">
              <wa-upload
                ref="$waUpload"
                v-model.trim="$v.receipt_front_photo.$model"
                name="receipt_front_photo"
                :label="i18nParticipate.picture_label"
                :label-smaller="i18nParticipate.picture_label_small"
                :label-on-hover="i18nParticipate.picture_label_hover"
                :cta-text="i18nParticipate.picture_cta"
                :helper-text="i18nParticipate.picture_helper"
                :helper-text-smaller="i18nParticipate.picture_helper_small"
                :data-error-occurred="$v.receipt_front_photo.$error"
                :errors="[
                  {
                    condition: !$v.receipt_front_photo.required,
                    message: i18nParticipate.picture_error_required,
                  },
                  {
                    condition: !$v.receipt_front_photo.mustBeImageOrPdf,
                    message: i18nParticipate.picture_error_format,
                  },
                  {
                    condition: !$v.receipt_front_photo.validSize,
                    message: i18nParticipate.picture_error_size,
                  },
                  {
                    condition: !$v.receipt_front_photo.serverError,
                    message: serverErrors.receipt_front_photo,
                  },
                ]"
                @change="onFileChange"
              />
            </div>
          </div>
          <!--
          <div class="row justify-content-center">
            <div
              class="col-12 col-md-10 col-lg-8 d-flex flex-column padding-bottom-xs"
            >
              <span
                class="typo-a-6 typo-extra-bold margin-bottom-r text-color-grey-9"
              >
                {{ i18nParticipate.back_picture_heading }}
              </span>
              <span class="typo-a-9 typo-medium text-color-grey-8">
                {{ i18nParticipate.back_picture_description }}
              </span>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 padding-bottom-r">
              <wa-upload
                ref="$waUploadBack"
                v-model.trim="$v.receipt_back_photo.$model"
                name="receipt_back_photo"
                :label="i18nParticipate.back_picture_label"
                :label-smaller="i18nParticipate.back_picture_label_small"
                :label-on-hover="i18nParticipate.back_picture_label_hover"
                :cta-text="i18nParticipate.back_picture_cta"
                :helper-text="i18nParticipate.back_picture_helper"
                :helper-text-smaller="i18nParticipate.back_picture_helper_small"
                :data-error-occurred="$v.receipt_back_photo.$error"
                :errors="[
                  {
                    condition: !$v.receipt_back_photo.mustBeImageOrPdf,
                    message: i18nParticipate.back_picture_error_format,
                  },
                  {
                    condition: !$v.receipt_back_photo.validSize,
                    message: i18nParticipate.back_picture_error_size,
                  },
                  {
                    condition: !$v.receipt_back_photo.serverError,
                    message: serverErrors.receipt_back_photo,
                  },
                ]"
                @change="onBackFileChange"
              />
            </div>
          </div>
-->
        </div>
        <div v-if="show_rules && ocr_result" class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 padding-bottom-r">
            <p class="typo-a-10 typo-light text-color-grey-8">
              {{ i18nParticipate.submit_label }}
            </p>
          </div>
        </div>
        <div v-if="show_rules && ocr_result" class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 padding-bottom-m">
            <wa-checkbox
              v-model.trim="$v.contest_rules_accepted.$model"
              data-name="contest_rules_accepted"
              :data-checkbox-list="[
                {
                  label: rulesLabel,
                },
              ]"
              :data-error-occurred="$v.contest_rules_accepted.$error"
              :data-errors="[
                {
                  condition: !$v.contest_rules_accepted.mustBeChecked,
                  message: i18nParticipate.rules_error_required,
                },
                {
                  condition: !$v.contest_rules_accepted.serverError,
                  message: serverErrors.contest_rules_accepted,
                },
              ]"
            />
          </div>
        </div>
        <!-- submit receipt data -->
        <div v-if="ocr_result" class="row">
          <div class="col text-center padding-bottom-xl">
            <div class="d-block d-sm-inline-block">
              <wa-cta
                class="primary"
                data-type="submit"
                :data-icon-id="spinner ? 'icon-ellipsis-loader' : ''"
                :data-disabled="disableSubmit || submitted"
                @click.prevent="onSubmit"
              >
                {{ i18nParticipate.submit_cta }}
              </wa-cta>
              <span
                v-for="(error, i) in hasGlobalErrors"
                :key="`error-${i}-${_uid}`"
                class="global-error typo-a-10 typo-medium"
                v-html="error.message"
              />
              <span
                v-if="showGlobalErrorFixFields"
                class="global-error typo-a-10 typo-medium"
                v-html="i18nParticipate.global_error_fix_fields"
              />
            </div>
          </div>
        </div>
        <!-- submit images for OCR -->
        <div v-else class="row">
          <div class="col text-center padding-bottom-xl">
            <div class="d-block d-sm-inline-block">
              <wa-cta
                class="primary"
                data-type="submit"
                :data-icon-id="ocr_loading ? 'icon-ellipsis-loader' : ''"
                :data-disabled="hasMissingPictures || ocr_loading || !user"
                @click.prevent="onSubmitOcrImages"
              >
                {{ i18nParticipate.submit_cta }}
              </wa-cta>
              <span
                v-for="(error, i) in hasGlobalErrors"
                :key="`error-${i}-${_uid}`"
                class="global-error typo-a-10 typo-medium"
                v-html="error.message"
              />
              <span
                v-if="showGlobalErrorFixFields"
                class="global-error typo-a-10 typo-medium"
                v-html="i18nParticipate.global_error_fix_fields"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <wa-modal-toast
      class="padding-top-l padding-bottom-l text-color-secondary-red"
      data-icon="icon-alert"
    >
      <template slot="title">
        <div class="text-color-sport-blue">
          {{ i18nParticipate.global_error_modal_fail_title }}
        </div>
      </template>
      <template slot="content">
        <div class="text-color-sport-blue" v-html="apiErrors"></div>
      </template>
    </wa-modal-toast>
    <wa-modal
      v-if="i18nParticipate.ocr_modal_description"
      ref="OCRModal"
      :data-click-to-close="true"
      class="padding-top-m padding-bottom-m text-center"
      data-name="OCR-modal"
      :data-has-close-button="true"
    >
      <svg class="icon padding-bottom-s">
        <use href="#icon-triangle-wasa" fill="currentColor" />
      </svg>
      <div
        style="padding-left: 24px; padding-right: 24px"
        class="content text-center"
        v-html="i18nParticipate.ocr_modal_description"
      />
    </wa-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { helpers, required } from 'vuelidate/lib/validators';
import LanguageSupport from '@mixins/language-support';
import { prettyPrintErrorProperties } from '@helpers/utils';
import axios from 'axios';

import {
  PRODUCTS,
  ONLINE_STORES_DE,
  ONLINE_STORES_AT,
  OFFLINE_STORES_DE,
  OFFLINE_STORES_AT,
} from './static-data';

import {
  getGigyaAdditionalDataForWinningCalls,
  getGigyaDetails,
} from './utils';
import moment from 'moment';
import waGridContainer from '../../wa-grid-container.vue';
import WaGridRow from '../../wa-grid-row.vue';

const debug = false;

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'WaPm56CamperVerify',
  mixins: [LanguageSupport],
  data() {
    return {
      /* local data */
      show_selected_products_error: false,
      productsRetrieved: false,
      storesRetrieved: false,
      privacyRetrieved: false,
      errorCodes: [],

      /* api data */
      promoProducts: null,
      shops: null,
      show_rules: false,

      /* form field */

      receipt_product: null,
      products: [],
      receipt_type: null,
      receipt_store: null,
      receipt_front_photo: null,
      receipt_back_photo: null,
      ocr_result: null,
      ocr_loading: false,
      contest_rules_accepted: false,
      date: null,
      hours: null,
      number: null,
      total_amount: null,

      filename_ocr: '',

      /* after submit */
      submitted: false,
      spinner: false,
      serverErrors: {},
      globalErrors: [],
      showGlobalErrorFixFields: false,
    };
  },
  computed: {
    ...mapGetters('pm56Camper', ['i18nParticipate']),
    ...mapState(['storeSetupCompleted']),
    ...mapState('gigya', ['loaded', 'user']),
    ...mapState('pm56Camper', {
      upload_file: (state) => state['upload_file'],
      play: (state) => state['play'],
      privacy_check: (state) => state['privacy_check'],
    }),
    apiErrors() {
      return `${
        this.i18nParticipate.global_error_modal_fail_content
      }<br />REF: ${this.errorCodes.join('; ')}.`;
    },
    gigyaAndStoreReady() {
      return {
        storeLoaded: this.storeSetupCompleted,
        gigyaLoaded: this.loaded,
        gigyaUserLoaded: this.user,
      };
    },
    legends() {
      const items = [
        this.i18nParticipate.picture_instruction_legend_1,
        this.i18nParticipate.picture_instruction_legend_2,
        this.i18nParticipate.picture_instruction_legend_3,
        this.i18nParticipate.picture_instruction_legend_4,
        this.i18nParticipate.picture_instruction_legend_5,
      ];

      return items.filter((i) => i);
    },
    hints() {
      const items = [
        this.i18nParticipate.picture_instruction_hint_1,
        this.i18nParticipate.picture_instruction_hint_2,
        this.i18nParticipate.picture_instruction_hint_3,
      ];

      return items.filter((i) => i);
    },
    infos() {
      const items = [
        this.i18nParticipate.picture_instruction_info_heading,
        this.i18nParticipate.picture_instruction_info_1,
        this.i18nParticipate.picture_instruction_info_2,
      ];

      return items.filter((i) => i);
    },
    receiptImg() {
      try {
        // TODO lang parameter
        return require(`@images/promo-camper/receipt-img-DE.png`);
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    disableSubmit() {
      if (
        (this.$v && (this.$v.$anyError || this.$v.$invalid)) ||
        !this.receipt_product ||
        !this.user
      ) {
        return true;
      }

      return false;
    },
    filteredShops() {
      return this.shops && this.receipt_type
        ? this.shops
            .filter((e) =>
              this.receipt_type === 'electronic' ? e.is_online : !e.is_online
            )
            .map((e) => {
              return { key: e.store_id, dataValue: e.name };
            })
        : [];
    },
    rulesLabel() {
      // reworked in component library
      let k = 0;
      let j = 0;
      return this.i18nParticipate.rules_label
        .replace(/%/g, () => {
          k++;
          if (k === 1) {
            return `<a class="typo-medium text-color-blue-logo" href='${this.i18nParticipate.rules_href}' target='_blank'>`;
          } else {
            return '</a>';
          }
        })
        .replace(/£/g, () => {
          j++;
          if (j === 1) {
            return `<a class="typo-medium text-color-blue-logo" href='${this.i18nParticipate.privacy_href}' target='_blank'>`;
          } else {
            return '</a>';
          }
        });
    },
    hasGlobalErrors() {
      if (this.globalErrors.length) {
        return this.globalErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
    hasMissingPictures() {
      if (
        (this.$v &&
          (this.$v.receipt_front_photo.$anyError ||
            this.$v.receipt_front_photo.$invalid)) ||
        !this.receipt_front_photo
      ) {
        return true;
      }

      return false;
    },
  },
  watch: {
    gigyaAndStoreReady: {
      async handler(val) {
        const { storeLoaded, gigyaLoaded, gigyaUserLoaded } = val;
        const canDoAsyncCalls = storeLoaded && gigyaLoaded;
        const canDoAsyncCallsThatNeedsUser =
          canDoAsyncCalls && !!gigyaUserLoaded;

        debug && console.log('WATCH gigyaAndStoreReady', val);
        debug && console.log('canDoAsyncCalls', canDoAsyncCalls);
        debug &&
          console.log(
            'canDoAsyncCallsThatNeedsUser',
            canDoAsyncCallsThatNeedsUser
          );

        //if (canDoAsyncCalls) {
        if (!this.productsRetrieved) {
          this.promoProducts = PRODUCTS.data.map((p) => {
            return {
              key: +p.code,
              dataValue: p.description,
              category: p.category.description,
              category_id: p.category.id,
            };
          });

          this.productsRetrieved = true;
        }

        if (!this.storesRetrieved) {
          const mappingFn = (isOnline) => {
            return (store) => {
              return {
                store_id: store.id,
                name: store.description,
                is_online: isOnline,
              };
            };
          };
          let OFFLINE_STORES_LANG = null;
          let ONLINE_STORES_LANG = null;
          switch (this.currentSiteCode) {
            case 'de-de':
              OFFLINE_STORES_LANG = OFFLINE_STORES_DE;
              ONLINE_STORES_LANG = ONLINE_STORES_DE;
              break;
            case 'de-at':
              OFFLINE_STORES_LANG = OFFLINE_STORES_AT;
              ONLINE_STORES_LANG = ONLINE_STORES_AT;
              break;
            default:
              OFFLINE_STORES_LANG = OFFLINE_STORES_DE;
              ONLINE_STORES_LANG = ONLINE_STORES_DE;
          }

          this.shops = [
            ...OFFLINE_STORES_LANG.data.map(mappingFn(false)),
            ...ONLINE_STORES_LANG.data.map(mappingFn(true)),
          ];

          this.storesRetrieved = true;
        }

        //}
        /*
        if (canDoAsyncCallsThatNeedsUser) {
          if (!this.privacyRetrieved) {
            //we can skip wrapping the call in try/catch
            //because when 'canDoAsyncCallsThatNeedsUser' is true
            //window.gigya is available so we can
            //call safetly window.gigya.accounts.getAccountInfo
            const gigyaData = await getGigyaDetails();

            if (
              gigyaData.data &&
              gigyaData.data.CH_765_WAS_WEB_SEPTGAME_DRB_CRS_20220901_20221231
            ) {
              this.show_rules = false;
            } else {
              this.show_rules = true;
            }

            this.privacyRetrieved = true;
          }
        }
        */
      },
      immediate: true,
    },
    loaded: {
      /* this is used to show the error message under the cta to submit the form in case no user is logged (edit mode) */
      handler(val) {
        if (val) {
          if (!this.user) {
            this.globalErrors.push({
              condition: true,
              message: this.i18nParticipate.global_error_logged,
            });
          }
        }
      },
      immediate: true,
    },
  },
  validations() {
    let validations = {
      receipt_product: {
        required,
        serverError: checkServerErrors('receipt_product'),
      },
      receipt_type: {
        required,
        serverError: checkServerErrors('receipt_type'),
      },
      receipt_store: {
        required,
        serverError: checkServerErrors('receipt_store'),
      },
      receipt_back_photo: {
        mustBeImageOrPdf: (val) => {
          return (
            !helpers.req(val) ||
            val.type === 'image/jpeg' ||
            val.type === 'image/jpg' ||
            val.type === 'image/png' ||
            val.type === 'application/pdf'
          );
        },
        validSize: (val) => {
          //changed from 4 to 5 brcause of winning documentation
          const mb = 5 * 1024 * 1024;
          return !helpers.req(val) || val.size <= mb;
        },
        serverError: checkServerErrors('receipt_back_photo'),
      },
      receipt_front_photo: {
        required,
        mustBeImageOrPdf: (val) => {
          return (
            !helpers.req(val) ||
            val.type === 'image/jpeg' ||
            val.type === 'image/jpg' ||
            val.type === 'image/png' ||
            val.type === 'application/pdf'
          );
        },
        validSize: (val) => {
          //changed from 4 to 5 brcause of winning documentation
          const mb = 5 * 1024 * 1024;
          return !helpers.req(val) || val.size <= mb;
        },
        serverError: checkServerErrors('receipt_front_photo'),
      },
      date: {
        required,
        regex: (val) =>
          !helpers.req(val) ||
          new RegExp(
            /^([123]0|[012][1-9]|31)-(0[1-9]|1[012])-(19[0-9]{2}|2[0-9]{3})$/,
            'gim'
          ).test(val),

        range: (val) => {
          const compareDate = moment(val, 'DD-MM-YYYY');
          const startDate = moment(
            this.$store.state.pm56Camper.i18n.participate
              .contest_receipt_start_date
          );
          const endDate = moment(
            this.$store.state.pm56Camper.i18n.participate
              .contest_receipt_end_date
          );
          const now = moment();
          return (
            !helpers.req(val) ||
            (compareDate.isBetween(startDate, endDate, 'minutes', '[]') &&
              compareDate.isBetween(startDate, now, 'day', '[]'))
          );
        },
        serverError: checkServerErrors('date'),
      },
      hours: {
        required,
        regex: (val) =>
          !helpers.req(val) ||
          new RegExp(/^(((0|1)[0-9])|2[0-3]):[0-5][0-9]$/, 'gim').test(val),
        range: (val) => {
          const compareDate = moment(
            this.date + ' ' + val,
            'DD-MM-YYYY HH:mm',
            true
          );
          const startDate = moment(
            this.$store.state.pm56Camper.i18n.participate
              .contest_receipt_start_date
          );
          const endDate = moment(
            this.$store.state.pm56Camper.i18n.participate
              .contest_receipt_end_date
          );
          const now = moment();
          return (
            !helpers.req(val) ||
            (compareDate.isValid() && !compareDate.isAfter(now, 'seconds'))
          );
        },
        serverError: checkServerErrors('hours'),
      },
      number: {
        required,
        regex: (val) =>
          !helpers.req(val) || (val.length > 0 && val.length <= 20),
        serverError: checkServerErrors('number'),
      },
      total_amount: {
        required,
        regex: (val) =>
          !helpers.req(val) || new RegExp(/^[0-9]{1,3},\d{0,2}$/).test(val),
        serverError: checkServerErrors('total_amount'),
      },
    };

    if (this.show_rules) {
      validations = {
        ...validations,
        contest_rules_accepted: {
          mustBeChecked: (val) => !!val,
          serverError: checkServerErrors('contest_rules_accepted'),
        },
      };
    } else {
      validations = {
        ...validations,
        contest_rules_accepted: {},
      };
    }

    return validations;
  },
  created() {
    this.setInvertedHeader(true);
    this.checkRules();
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    showOCRModal() {
      this.$modal.show('OCR-modal');
    },
    hideOCRModal() {
      this.$modal.hide('OCR-modal');
    },
    stepImg(step) {
      try {
        // TODO
        //return require(`@images/promo-camper/step-${step}-img-${this.currentLang}.png`);
        return require(`@images/promo-camper/step-${step}-img-DE.png`);
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async checkRules() {
      // NO https://run.mocky.io/v3/f56ae2d9-580b-4995-aca1-25960677bcaa
      // SI https://run.mocky.io/v3/b2473e31-da1f-4276-8b59-ea6efce4b545
      try {
        await axios.get(this.privacy_check).then((response) => {
          // contest_rule_flag === False -> appare checkbox
          this.show_rules = response.data.contest_rule_flag ? false : true;
        });
      } catch (e) {
        console.error(e);
      }
    },
    onSetShopType() {
      this.receipt_store = null;
    },
    onFileChange(f) {
      this.receipt_front_photo = f;
      this.$v.receipt_front_photo.$touch();
    },
    onBackFileChange(f) {
      this.receipt_back_photo = f;
      this.$v.receipt_back_photo.$touch();
    },
    async onSubmitOcrImages() {
      if (!(this.hasMissingPictures || this.ocr_loading || !this.user)) {
        this.ocr_loading = true;
        try {
          const winningAdditionalPayload =
            await getGigyaAdditionalDataForWinningCalls();
          //MULTIPART richiesta OCR
          const formData = new FormData();
          formData.append('front_file', this.receipt_front_photo);
          formData.append('user_profiling', '1');
          // MOCK OCR CALL
          // https://run.mocky.io/v3/831b950e-5c1c-44a0-82e1-8c051968c3f4
          await axios.post(this.upload_file, formData).then((response) => {
            this.ocr_result = response;
            const ocr_filename = this.ocr_result.data[0]?.data?.filename;
            const front_ocr_read = this.ocr_result.data[0]?.data?.OCR;

            if (ocr_filename) {
              this.filename_ocr = ocr_filename;
            }
            if (front_ocr_read) {
              const receipt_ocr = front_ocr_read.status?.receipt;
              if (receipt_ocr) {
                if (
                  receipt_ocr.receipt_day &&
                  receipt_ocr.receipt_month &&
                  receipt_ocr.receipt_year
                ) {
                  this.date =
                    receipt_ocr.receipt_day +
                    '-' +
                    receipt_ocr.receipt_month +
                    '-' +
                    receipt_ocr.receipt_year;

                  this.$v.date.$touch();
                }
                if (receipt_ocr.receipt_hour && receipt_ocr.receipt_minutes) {
                  this.hours =
                    receipt_ocr.receipt_hour +
                    ':' +
                    receipt_ocr.receipt_minutes;
                  this.$v.hours.$touch();
                }
                if (
                  receipt_ocr.receipt_amount_euros &&
                  receipt_ocr.receipt_amount_cents
                ) {
                  this.total_amount =
                    receipt_ocr.receipt_amount_euros +
                    ',' +
                    receipt_ocr.receipt_amount_cents;
                  this.$v.total_amount.$touch();
                }
                if (receipt_ocr.receipt_number) {
                  this.number = receipt_ocr.receipt_number;
                  this.$v.number.$touch();
                }
              }
            }
            // scroll to prod select title
            const refVar = 'scroll_here_after_ocr';
            this.$refs[refVar].scrollIntoView({ behavior: 'smooth' });
          });
        } catch (e) {
          console.error(e);
        } finally {
          this.ocr_loading = false;
          if (!this.total_amount || !this.date || !this.hours || !this.number) {
            setTimeout(() => {
              this.showOCRModal();
            }, 500);
          }
        }
      }
    },
    async onSubmit() {
      if (!this.submitted && !this.disableSubmit) {
        this.submitted = true;
        this.spinner = true;
        this.showGlobalErrorFixFields = false;
        this.globalErrors = [];

        try {
          const winningAdditionalPayload =
            await getGigyaAdditionalDataForWinningCalls();

          //MULTIPART richiesta partecipazione
          const formData = new FormData();
          formData.append('front_file', this.filename_ocr);
          formData.append(
            'contest_rules_accepted',
            this.contest_rules_accepted
          );
          const prod_selected = [{ code: this.receipt_product, quantity: 1 }];
          // todo check if is good for winnig
          // formData.append('products', prod_selected);
          formData.append('products', JSON.stringify(prod_selected));
          formData.append('user_profiling', '1');
          const euros = this.total_amount.split(',')[0];
          const cents = this.total_amount.split(',')[1];

          if (euros.length === 1) {
            formData.append('receipt_amount_euros', '0' + euros);
          } else {
            formData.append('receipt_amount_euros', euros);
          }
          if (cents.length === 1) {
            formData.append('receipt_amount_cents', cents + '0');
          } else {
            formData.append('receipt_amount_cents', cents);
          }
          formData.append('receipt_day', this.date.split('-')[0]);
          formData.append('receipt_month', this.date.split('-')[1]);
          formData.append('receipt_year', this.date.split('-')[2]);
          formData.append('receipt_hour', this.hours.split(':')[0]);
          formData.append('receipt_minutes', this.hours.split(':')[1]);
          formData.append('receipt_number', this.number);
          formData.append('store', this.receipt_store);
          if (this.$refs.$waUploadBack && this.$refs.$waUploadBack.base64) {
            formData.append('back_file', this.receipt_back_photo);
          }

          //contest_rules_accepted we do not need to send;

          //console.log(this.selectedProductsId);
          let result;
          //MULTIPART
          result = await axios.post(this.play, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          if (result.data) {
            // Contest completed
            await this.$store.dispatch('setUnlockedView', 'thank-you');
            // Navigate
            await this.$router.push({ name: 'thank-you' });
          } else {
            console.warn('Wrong response payload');
            this.globalErrors.push({
              condition: true,
              message: this.i18nParticipate.global_server_error,
            });
          }
        } catch (e) {
          const propertyNames = Object.getOwnPropertyNames(e);
          if (propertyNames.includes('response')) {
            const responsePropertyContent = Object.getOwnPropertyDescriptor(
              e,
              'response'
            );
            const responseCode = responsePropertyContent.value.status;
            const serverResponse = responsePropertyContent.value.data;
            if (responseCode === 400) {
              if (
                'errors' in serverResponse &&
                'idCode' in serverResponse.errors
              ) {
                const code = serverResponse.errors.idCode;
                this.globalErrors.push({
                  condition: true,
                  message: this.i18nParticipate[code]
                    ? this.i18nParticipate[code]
                    : code,
                });
                this.showGlobalErrorFixFields = true;
              } else {
                for (const [property, value] of Object.entries(
                  serverResponse.errors
                )) {
                  if (property == 'non_field_errors') {
                    // Global validation errors
                    //this is an old code used for all the promos, luckly arrives always just one value in the array 'value' so
                    //this.i18nParticipate[value] works but if multiple values will arrives it will brake
                    this.globalErrors.push({
                      condition: true,
                      message: this.i18nParticipate[value]
                        ? this.i18nParticipate[value]
                        : value,
                    });
                  } else {
                    if (property === 'receipt_products') {
                      this.show_selected_products_error = true;
                    } else {
                      let temp = {};
                      //Here we removed some dead code about temp[property] = this.i18nParticipate[value] ? this.i18nParticipate[value]: value;
                      //First of all because this.i18nParticipate[value] will be always undefined, value is a string and not the key for the object i18nParticipate
                      //Second because we are not handling client errors between our frontend and our backend
                      //just between our frontend and proxied messages from promo engine backend

                      //6/9/2022 we fixed implementation and now we can take the correct translations
                      //let's comment old code and put new implementation
                      // temp[property] = value;
                      // this.serverErrors = { ...this.serverErrors, ...temp };
                      // this.$v[property] && this.$v[property].$touch();
                      const translatedMessagesConcatenatedString = value.reduce(
                        (acc, curr) => {
                          if (!acc) {
                            return this.i18nParticipate[curr]
                              ? this.i18nParticipate[curr]
                              : curr;
                          } else {
                            return `${acc}, ${
                              this.i18nParticipate[curr]
                                ? this.i18nParticipate[curr]
                                : curr
                            }`;
                          }
                        },
                        ''
                      );
                      temp[property] = translatedMessagesConcatenatedString;
                      this.serverErrors = { ...this.serverErrors, ...temp };
                      this.$v[property] && this.$v[property].$touch();
                    }
                  }
                }

                //receipt_products

                Object.keys(this.serverErrors).forEach((key) => {
                  const unwatch = this.$watch(key, (newVal, oldVal) => {
                    if (newVal !== oldVal) {
                      Vue.delete(this.serverErrors, key);
                      setTimeout(() => {
                        this.$v[key] && this.$v[key].$touch();
                      }, 0);
                      if (unwatch) {
                        unwatch();
                      }
                    }
                  });
                });

                // Renew the submission
              }
            } else if (responseCode === 401 || responseCode === 403) {
              if ('detail' in serverResponse) {
                this.globalErrors.push({
                  condition: true,
                  message: this.i18nParticipate.global_error_logged,
                });
              } else {
                console.error(
                  'Unhandled 401 or 403 error, wrong response payload'
                );
              }
            } else if (responseCode === 500) {
              this.globalErrors.push({
                condition: true,
                message: this.i18nParticipate.global_server_error,
              });
            } else {
              console.warn('Unknown response code');
              this.globalErrors.push({
                condition: true,
                message: this.i18nParticipate.global_server_error,
              });
            }
          } else {
            console.error('Unhandled error, wrong format');
          }
          this.submitted = false;
        } finally {
          this.spinner = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaPm56CamperVerify {
  * {
    user-select: none;
  }
  .icon {
    width: 48px;
    height: 48px;

    @media (min-width: $bootstrap-md) {
      width: 64px;
      height: 64px;
    }
  }
  .step-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .step-img {
      flex-grow: 1;
      img {
        width: 100%;
      }
    }
    .step-id {
      p {
        font-family: $font-secondary;
      }
      img {
        width: 100%;
      }
    }
    .info-step {
      width: 50px;
      height: 50px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .img-fluid {
    @include media-breakpoint-up(sm) {
      width: 70%;
    }
  }

  .global-error {
    display: block;
    padding-top: 14px;
    color: $color-informative-red;
    text-align: left;

    &.products-error {
      font-size: 13px;
    }
  }
  /deep/ .WaInput .form__label {
    overflow: hidden !important;
    max-height: 35px !important;
  }
}
</style>
