<template>
  <div
    :class="[
      'ContactFormHeadCmp',
      'container',
      { 'with-breadcrumb': dataWithBreadcrumb },
    ]"
  >
    <div class="row">
      <div class="col-12">
        <div v-if="dataWithBreadcrumb" class="row justify-content-center">
          <div class="col-12 col-md-10">
            <div class="breadcrumb-wrapper">
              <span class="typo-a typo-a-9 typo-heavy text-color-blue-logo">
                {{ common.breadcrumb }}
              </span>
              <contact-form-breadcrumb-cmp class="breadcrumb-items" />
            </div>
          </div>
        </div>
        <div class="main-wrapper">
          <div v-if="canGoBack" class="back-wrapper">
            <contact-form-back-cmp />
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-8">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactFormHeadCmp',
  props: {
    dataWithBreadcrumb: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('contactForm2024', ['common', 'queryString']),
    canGoBack() {
      return this.queryString?.page_order?.split(',').length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~$scss/utility';
@import '~bootstrap/scss/mixins/breakpoints';

.ContactFormHeadCmp {
  padding-top: var(--contact-form-head-padding-top, 0);
  padding-bottom: var(--contact-form-head-padding-bottom, 0);

  &.with-breadcrumb {
    padding-top: var(--contact-form-head-with-bradcrumb-padding-top, 0);
  }

  .breadcrumb-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: var(--contact-form-head-breadcrumb-content-row-gap, 0);
    padding-bottom: 108px;

    @include media-breakpoint-up(md) {
      padding-bottom: 80px;
    }

    .breadcrumb-items {
      display: flex;
      flex-wrap: wrap;
      gap: var(--contact-form-head-breadcrumb-items-gap, 0);
    }
  }

  .main-wrapper {
    position: relative;

    .back-wrapper {
      position: absolute;
      left: 0;
      top: -32px;
      transform: translateY(-100%);

      @include media-breakpoint-up(md) {
        top: auto;
        bottom: 0;
        transform: none;
      }
    }
  }
}
</style>
