<template lang="html">
  <div :class="['WaModal', { 'has-inner-scroll': dataHasInnerScroll }]">
    <modal
      :adaptive="true"
      :pivot-y="0.2"
      width="100%"
      height="100%"
      :click-to-close="dataClickToClose"
      :name="dataName"
      @before-open="beforeOpen"
      @before-close="beforeClose"
      @closed="closed"
      @opened="opened"
    >
      <!--
        For invoking the modal you should add `@click="$modal.show('modal-name')"` on the element that should open the modal

        Example:
        <button @click="$modal.show('modal-name')">APRI</button>
      -->
      <div
        class="modal-container"
        @click.self="dataClickToClose && $modal.hide(dataName)"
      >
        <div
          ref="$modalBox"
          :class="[
            'modal-box',
            overwriteClasses,
            {
              'has-button': dataHasBackButton || dataHasCloseButton,
              'hasIosNotch': $root.hasIosNotch,
            },
          ]"
          :style="forceHeight ? { height: forceHeight } : null"
        >
          <slot />
          <button
            v-if="dataHasBackButton"
            class="modal-back"
            type="button"
            @click="$modal.hide(dataName)"
          >
            <span class="sr-only">Back</span>
          </button>
          <button
            v-else-if="dataHasCloseButton"
            class="modal-close"
            type="button"
            @click="$modal.hide(dataName)"
          >
            <span class="sr-only">Close</span>
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'WaModal',
  props: {
    dataName: {
      type: String,
      default: 'modal-name',
    },
    dataHasBackButton: {
      type: Boolean,
      default: false,
    },
    dataHasCloseButton: {
      type: Boolean,
      default: true,
    },
    dataHasInnerScroll: {
      type: Boolean,
      default: false,
    },
    dataClickToClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      forceHeight: null,
      overwriteClasses: [],
    };
  },
  watch: {
    $viewportWidth: {
      handler() {
        if (this.$refs.$modalBox) {
          this.setForceHeight();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.onClassChange(this.$el.classList.value);
    this.observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        const newValue = mutation.target.getAttribute(mutation.attributeName);
        this.$nextTick(() => {
          this.onClassChange(newValue, mutation.oldValue);
        });
      }
    });

    this.observer.observe(this.$el, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class'],
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    onClassChange(classAttrValue) {
      //console.log('onClassChange');
      const overwriteClassesPrefix = [
        'bg-color-',
        'padding-top-',
        'padding-bottom-',
      ];
      overwriteClassesPrefix.forEach((prefix) => {
        const index = this.overwriteClasses.findIndex((e) =>
          e.includes(prefix)
        );
        if (index !== -1) {
          this.overwriteClasses.splice(index, 1);
        }
        if (classAttrValue.includes(prefix)) {
          this.overwriteClasses.push(
            classAttrValue
              .trim()
              .split(' ')
              .find((e) => e.includes(prefix))
          );
        }
      });
    },
    setForceHeight() {
      //console.log('setForceHeight');
      if (this.dataHasInnerScroll) {
        const element = this.$el.querySelector('.modal-box');
        const boxMaxHeight =
          +getComputedStyle(element).maxHeight.split('px')[0];
        const boxPaddingTop =
          +getComputedStyle(element).paddingTop.split('px')[0];
        const contentHeight = element.firstChild.offsetHeight;
        // empiric value 150
        const iosFixOffset = this.$root.hasIosNotch ? 150 : 0;

        if (boxPaddingTop + contentHeight + iosFixOffset >= boxMaxHeight) {
          this.forceHeight = `${boxMaxHeight}px`;
        } else {
          this.forceHeight = null;
        }
      }
    },
    beforeOpen() {
      //console.log('beforeOpen');
      //console.log('beforeOpen')
      /*
          remember to put a watch on the entrypoint
          watch: {
            $currentScrollPosition: {
              handler() {
                document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
              },
              immediate: true
            }
          }
          https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
          https://vuilaptrinh.com/2019-06-07-huong-dang-xu-ly-modal-va-scroll/
        */
      const scrollY =
        document.documentElement.style.getPropertyValue('--scroll-y');
      const body = document.body;
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}`;
    },
    opened() {
      this.$_a11Support_clearAllBodyScrollLocks();
      this.$nextTick(() => {
        this.setForceHeight();
      });
      // this.$nextTick(() => {
      //   //modal-box
      //   const element = document.querySelector(
      //     '.vm--modal .modal-box'
      //   ).firstChild;
      //   element.focus();
      //   const wrapper = document.querySelector('.vm--modal .modal-box');
      //   //we do not set focus on the scrolling element fo a strange bug on ios
      //   this.$_a11Support_setVoiceOverFocus(wrapper);
      //   this.$_a11Support_trapScreenReader({ $el: element, _uid: this._uid });
      //   //we do set scrollable the correct element
      //   this.$_a11Support_disableScrollOuterElement({
      //     $el: element,
      //     enable: true,
      //   });
      // });
    },
    closed() {
      //console.log('closed');
      // this.$_a11Support_untrapScreenReader({ _uid: this._uid });
      // this.$_a11Support_clearAllBodyScrollLocks();
      this.$emit('closed');
    },
    beforeClose() {
      //console.log('beforeClose');
      //this.$_a11Support_clearAllBodyScrollLocks();
      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

@mixin on-custom-hover() {
  @media (hover: hover),
    (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    &:hover {
      @content;
    }
  }

  @media (hover: none) {
    &:active {
      @content;
    }
  }
}

@mixin modal-box-sizes {
  width: 100%;
  height: auto;
  min-height: 100vh;
  max-height: 100vh;

  @media (min-width: $bootstrap-sm) {
    width: 83.333333%; /* col-10 */
    height: auto;
    min-height: auto;
    max-height: 80vh;
  }

  @media (min-width: $bootstrap-md) {
    width: 66.666667%; /* col-8 */
    height: auto;
    min-height: auto;
    max-height: 80vh;
  }
}

@mixin scrollbar-crossbrowser-style {
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba($color-grey-6, 0.5) transparent;

  /* Works on Chrome/Edge/Safari */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px rgba($color-grey-6, 0.75);
    border-radius: 20px;
    border: 4px solid transparent;
  }
}

.WaModal {
  background: none !important;
  padding: 0 !important;

  /deep/ .vm--container {
    z-index: 99999999999;
  }

  /deep/ .vm--modal {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
  }

  &:not(.has-inner-scroll) {
    /deep/ .vm--modal {
      overflow-y: auto;
    }
  }

  /deep/ .vm--overlay {
    background: transparent;

    @media (min-width: $bootstrap-sm) {
      background: rgba($color-grey-8, 0.75);
    }
  }

  .modal-container {
    min-height: 100%;

    @media (min-width: $bootstrap-sm) {
      width: calc(100% - #{map_get($bootstrap-container-side-spacings, sm)});
      margin: 0 auto;
    }

    @media (min-width: $bootstrap-md) {
      width: calc(100% - #{map_get($bootstrap-container-side-spacings, md)});
    }

    @media (min-width: $bootstrap-lg) {
      width: calc(100% - #{map_get($bootstrap-container-side-spacings, lg)});
      max-width: 1440px;
    }

    @media (min-width: $bootstrap-sm) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: transparent;
    }

    .modal-box {
      position: relative;
      margin: 0 auto;
      background: $color-white;
      overflow: auto;
      @include scrollbar-crossbrowser-style;
      @include modal-box-sizes;

      @media (min-width: $bootstrap-sm) {
        border-radius: 4px;
        filter: drop-shadow(0 4px 16px rgba(0, 0, 0, 0.15));
      }
    }
  }

  &.has-inner-scroll {
    .modal-container {
      .modal-box {
        overflow: hidden;

        &.has-button {
          padding: 72px 0 0;
        }
        &.hasIosNotch {
          //110 empiric value i think we should sum safety area plus bottom bar height + top bar height
          padding: 72px 0 110px;
        }
      }
    }

    .modal-box > :first-child {
      /* needs a fixed height in .modal-box-sizes forced by setForceHeight() method */
      height: 100%;
      border-top: 1px solid $color-grey-4;
      overflow: auto;
      @include scrollbar-crossbrowser-style;
    }
  }

  /* Buttons */
  $color-modal-close: $color-grey-9;
  $color-modal-close-hover: $color-grey-8;

  .modal-back,
  .modal-close {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    z-index: 999999;

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      border: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
    }
  }

  .modal-back {
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      padding: 3.5px;
      border-style: solid;
      border-width: 0 2px 2px 0;
      border-color: $color-modal-close;
      transition: border-color 0.3s ease;
      transform: translate(-33%, -50%) rotate(135deg);
    }

    @include on-custom-hover() {
      &:before {
        border-color: $color-modal-close-hover;
      }
    }
  }

  .modal-close {
    left: auto;
    right: 24px;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 16px;
      height: 2px;
      background: $color-modal-close;
      margin: -1px 0 0 -8px; /* -half-height 0 0 -half-width */
      transition: background-color 0.3s ease;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    @include on-custom-hover() {
      &:before,
      &:after {
        background: $color-modal-close-hover;
      }
    }
  }
}
</style>
