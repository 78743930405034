<template>
  <div class="WaNewsDetailPage padding-top-xxl padding-bottom-xl">
    <div class="container-fluid">
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-sm-8">
          <wa-free-text>
            <template slot="content_wysiwyg">
              <span :class="['typo-medium text-color-grey-8 typo-a-9']">
                {{ titleSmall }}
              </span>
              <h1
                :class="[
                  'typo-w-3 margin-top-xs margin-bottom-r',
                  newsConf.title_color,
                ]"
              >
                {{ newsConf.title }}
              </h1>
            </template>
          </wa-free-text>
          <wa-social-share>
            <template slot="wa-social-share-item">
              <!-- twitter -->
              <wa-social-share-item
                class="text-color-grey-8"
                data-is-share="true"
                data-social="twitter"
                :data-share-url="newsConf.detail_url"
              />

              <!-- facebook -->
              <wa-social-share-item
                data-social="facebook"
                data-is-share="true"
                class="text-color-grey-8"
                :data-share-url="newsConf.detail_url"
              />

              <!-- mail -->
              <wa-social-share-item
                data-is-share="true"
                data-social="mail"
                class="text-color-grey-8"
                :data-title-share="newsConf.title"
                :data-share-url="newsConf.detail_url"
              />
            </template>
          </wa-social-share>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-12">
          <wa-media-image :class="['margin-top-l']" data-variant="full">
            <img :src="newsConf.img_big" :alt="newsConf.alt_text" />
          </wa-media-image>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-sm-8">
          <wa-free-text :class="['padding-top-m']">
            <template slot="content_wysiwyg">
              <div v-html="newsConf.content_wysiwyg" />
            </template>
          </wa-free-text>
          <wa-cta
            class="inverted-link"
            data-variant-class="link"
            data-icon-id="icon-back"
            :data-href="backHref"
            data-target="_self"
          >
            {{ backLabel }}
          </wa-cta>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'WaNewsDetailPage',
  props: {
    dataNewsConf: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    newsConf() {
      return this.$_cmsSupport_castPropToType(this.dataNewsConf, 'object');
    },
    backHref() {
      return this.$store.state.config.url_cta_all_news;
    },
    backLabel() {
      return this.$store.state.config.label_cta_all_news;
    },
    titleSmall() {
      return moment(this.newsConf.publication_start).format('LL');
    },
  },
};
</script>

<style scoped></style>
