<template>
  <div class="WaHistoryCard">
    <div v-if="!!($slots.image || [])[0] && !imageAfter" class="image-wrapper">
      <figure class="image">
        <slot name="image" />
      </figure>
    </div>
    <div class="content">
      <template v-if="!!($slots.year || [])[0]">
        <slot name="year" />
      </template>
      <template v-if="!!($slots.title || [])[0]">
        <slot name="title" />
      </template>
      <slot name="content_wysiwyg" />
    </div>
    <div v-if="!!($slots.image || [])[0] && imageAfter" class="image-wrapper">
      <figure class="image">
        <slot name="image" />
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaHistoryCard',
  data() {
    return {
      imageAfter: false,
    };
  },
  mounted() {
    this.imageAfter = !this.$el.classList.contains('image-up');
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: (9 * 100% / 16);
  margin: 0;
  border-radius: 4px;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    object-fit: cover;
  }
}

.image-up {
  .content {
    margin-top: 16px;

    @include media-breakpoint-up(md) {
      margin-top: 24px;
    }
  }
}

.image-right {
  @include media-breakpoint-up(sm) {
    display: flex;

    .image-wrapper,
    .content {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @include media-breakpoint-up(lg) {
    .image-wrapper {
      flex: 0 0 (100% / 7 * 4);
      max-width: (100% / 7 * 4);
    }

    .content {
      flex: 0 0 (100% / 7 * 3);
      max-width: (100% / 7 * 3);
    }
  }

  .image {
    padding-bottom: 100%;
  }

  .content {
    margin-bottom: 16px;

    @include media-breakpoint-up(sm) {
      padding-right: 8px;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      padding-right: 12px;
    }
  }

  .image-wrapper {
    @include media-breakpoint-up(sm) {
      padding-left: 8px;
    }

    @include media-breakpoint-up(md) {
      padding-left: 12px;
    }
  }
}

.image-down {
  .image {
    padding-bottom: (3 * 100% / 4);
  }

  .content {
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      margin-bottom: 24px;
    }
  }
}
</style>
