<template>
  <div class="WaCarouselSlider">
    <!--
    implementation sample

    <wa-carousel-slider>
      <wa-carousel-slide slot="slides">
        <img slot="image" src="https://placehold.co/640x480" alt="Image alt" class="image" />
        <h3 slot="title" class="title typo-a-5 typo-medium text-color-black">Sweet potato & Kale</h3>
        <p slot="text" class="text typo-a-6 typo-light text-color-grey-8">Pour the salt in an oven tray, split the tomatoes in half and place on the salt.</p>
        <a slot="link" href="#">Dettagli</a>
      </wa-carousel-slide>
      <wa-carousel-slide slot="slides">
        <img slot="image" src="https://placehold.co/640x480" alt="Image alt" class="image" />
        <h3 slot="title" class="title typo-a-5 typo-medium text-color-black">Colazione salutare</h3>
        <p slot="text" class="text typo-a-6 typo-light text-color-grey-8">Lorem ipsum dolor sit amet consectetur. Risus cras vitae non.</p>
        <a slot="link" href="#">Dettagli</a>
      </wa-carousel-slide>
      <wa-carousel-slide slot="slides">
        <img slot="image" src="https://placehold.co/640x480" alt="Image alt" class="image" />
        <h3 slot="title" class="title typo-a-5 typo-medium text-color-black">Wasa 100</h3>
        <p slot="text" class="text typo-a-6 typo-light text-color-grey-8">Pour the salt in an oven tray, split the tomatoes in half and place on the salt.</p>
        <a slot="link" href="#">Dettagli</a>
      </wa-carousel-slide>
      <wa-carousel-slide slot="slides">
        <img slot="image" src="https://placehold.co/640x480" alt="Image alt" class="image" />
        <h3 slot="title" class="title typo-a-5 typo-medium text-color-black">Sweet potato & Kale</h3>
        <p slot="text" class="text typo-a-6 typo-light text-color-grey-8">Pour the salt in an oven tray, split the tomatoes in half and place on the salt.</p>
        <a slot="link" href="#">Dettagli</a>
      </wa-carousel-slide>
      <wa-carousel-slide slot="slides">
        <img slot="image" src="https://placehold.co/640x480" alt="Image alt" class="image" />
        <h3 slot="title" class="title typo-a-5 typo-medium text-color-black">Colazione salutare</h3>
        <p slot="text" class="text typo-a-6 typo-light text-color-grey-8">Lorem ipsum dolor sit amet consectetur. Risus cras vitae non.</p>
        <a slot="link" href="#">Dettagli</a>
      </wa-carousel-slide>
      <wa-carousel-slide slot="slides">
        <img slot="image" src="https://placehold.co/640x480" alt="Image alt" class="image" />
        <h3 slot="title" class="title typo-a-5 typo-medium text-color-black">Wasa 100</h3>
        <p slot="text" class="text typo-a-6 typo-light text-color-grey-8">Pour the salt in an oven tray, split the tomatoes in half and place on the salt.</p>
        <a slot="link" href="#">Dettagli</a>
      </wa-carousel-slide>
    </wa-carousel-slider>
    -->
    <div class="container-fluid">
      <div class="slider-wrapper">
        <button
          ref="$prev"
          type="button"
          class="swiper-button swiper-button-prev"
          :style="arrowsStyle"
        >
          <svg width="24" height="24">
            <use href="#icon-arrow-angle-right" fill="currentColor" />
          </svg>
        </button>
        <button
          ref="$next"
          type="button"
          class="swiper-button swiper-button-next"
          :style="arrowsStyle"
        >
          <svg width="24" height="24">
            <use href="#icon-arrow-angle-right" fill="currentColor" />
          </svg>
        </button>
        <div ref="$slider" class="swiper-container">
          <div class="swiper-wrapper">
            <swiper-slide
              v-for="(node, index) of $slots.slides"
              :key="`hero-slide-${index}`"
              :value="node"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';

export default {
  name: 'WaCarouselSlider',
  components: {
    SwiperSlide: {
      props: {
        value: { type: [Object, Array], required: true },
      },
      render: function (createElement) {
        let nodes = this.value;
        if (!Array.isArray(nodes)) {
          nodes = [nodes];
        }
        return createElement('div', { class: 'swiper-slide' }, nodes);
      },
    },
  },
  data() {
    return {
      swiper: null,
      imageHeight: 0,
    };
  },
  computed: {
    arrowsStyle() {
      const top = Math.round(this.imageHeight / 2);
      return { top: `${top}px` };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.swiper = new Swiper(this.$refs.$slider, {
        slidesPerView: 1,
        spaceBetween: 16,
        navigation: {
          prevEl: this.$refs.$prev,
          nextEl: this.$refs.$next,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        },
        on: {
          init: () => {
            this.updateImageHeight();
          },
          resize: () => {
            this.updateImageHeight();
          },
        },
      });
    });
  },
  beforeDestroy() {
    if (this.swiper !== null) {
      this.swiper.destroy();
      this.swiper = null;
    }
  },
  methods: {
    updateImageHeight() {
      this.imageHeight =
        this.$el.querySelector('.image-wrapper')?.clientHeight || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~$scss/utility';
@import '~bootstrap/scss/mixins/breakpoints';

.slider-wrapper {
  position: relative;
}

.swiper-button {
  --side-offset: -14px;
  padding: 0;
  border: 0;
  background: rgba($color-black, 0.5);
  color: rgba($color-white, 0.75);
  font-size: 14px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  position: absolute;
  right: var(--side-offset);
  top: 50%;
  z-index: 2;
  margin-top: -20px;

  @include media-breakpoint-up(sm) {
    --side-offset: -36px;
  }

  @include media-breakpoint-up(md) {
    --side-offset: -28px;
  }

  @include media-breakpoint-up(lg) {
    --side-offset: -46px;
  }

  &:not(.swiper-button-disabled):hover {
    background-color: $color-black;
  }

  svg {
    display: block;
    margin-right: -1px;
    pointer-events: none;
  }

  &.swiper-button-prev {
    right: auto;
    left: var(--side-offset);

    svg {
      transform: rotate(180deg);
      margin-right: 2px;
    }
  }

  &.swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }
}
</style>
