<template>
  <div class="PrReviewDisplay">
    <wa-grid-container class="container-fluid padding-top-0 padding-bottom-0">
      <template slot="wa-grid-row">
        <wa-grid-row class="row">
          <template slot="wa-grid-column">
            <wa-grid-column class="col-12">
              <template slot="wa-grid-column-childs">
                <div :id="`pr-reviewsnippet-${_uid}`"></div>
              </template>
            </wa-grid-column>
          </template>
        </wa-grid-row>
      </template>
    </wa-grid-container>
    <wa-grid-container class="container-fluid padding-top-l padding-bottom-0">
      <template slot="wa-grid-row">
        <wa-grid-row class="row">
          <template slot="wa-grid-column">
            <wa-grid-column class="col-12">
              <template slot="wa-grid-column-childs">
                <div :id="`pr-reviewdisplay-${_uid}`"></div>
              </template>
            </wa-grid-column>
          </template>
        </wa-grid-row>
      </template>
    </wa-grid-container>
    <!-- REMOVED QUESTIONS -->
  </div>
</template>

<script>
/**
 * TO READD QUESTION PASTE THIS CODE IN THE TEMPLATE INSTEAD OF THE COMMENT 'REMOVED QUESTIONS' AND UNCOMMENT THE TWO WIDGETS IN ADD PR WIDGETS
 <wa-grid-container class="container-fluid padding-top-l padding-bottom-0">
      <template slot="wa-grid-row">
        <wa-grid-row class="row">
          <template slot="wa-grid-column">
            <wa-grid-column class="col-12">
              <template slot="wa-grid-column-childs">
                <div :id="`pr-questionsnippet-${_uid}`"></div>
              </template>
            </wa-grid-column>
          </template>
        </wa-grid-row>
      </template>
    </wa-grid-container>
    <wa-grid-container class="container-fluid padding-top-l padding-bottom-0">
      <template slot="wa-grid-row">
        <wa-grid-row class="row">
          <template slot="wa-grid-column">
            <wa-grid-column class="col-12">
              <template slot="wa-grid-column-childs">
                <div :id="`pr-questiondisplay-${_uid}`"></div>
              </template>
            </wa-grid-column>
          </template>
        </wa-grid-row>
      </template>
    </wa-grid-container>
 */
export default {
  name: 'PrReviewDisplay',
  mounted() {
    this.$watch(
      () => this.$root.arePowerReviewConfsReady,
      (confsAreReady) => {
        if (confsAreReady) {
          this.addPrWidget();
        }
      },
      { immediate: true }
    );
  },
  methods: {
    addPrWidget() {
      //272079137513752 	Sesam & Vollkorn
      this.$root.setWidgetsToMount([
        ...this.$root.getWidgetsToMount(),
        {
          ...this.$root.getReviewDisplayBaseConf(),
          components: {
            ReviewSnippet: `pr-reviewsnippet-${this._uid}`,
            ReviewDisplay: `pr-reviewdisplay-${this._uid}`,
            // QuestionSnippet: `pr-questionsnippet-${this._uid}`,
            // QuestionDisplay: `pr-questiondisplay-${this._uid}`,
          },
        },
      ]);
    },
  },
};
</script>
