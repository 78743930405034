<template>
  <div class="ContactFormTriageTypeCmp">
    <router-link
      v-for="item in activeCtas"
      :key="`list-item-${item.id}`"
      class="list-item"
      :to="{
        name: getRouteName(routeDependencies, queryString, routes, item),
        query: { triage_type: item.id, page_order: routes.triage_type.name },
      }"
    >
      <wa-cta-box
        :class="[
          'contact-form-cta-box',
          'text-color-blue-logo',
          { 'with-text': !!item.text },
        ]"
        :data-id-icon="item.icon.svgId"
      >
        <template #title>
          <h4 class="typo-a typo-a-6 typo-medium margin-bottom-0">
            {{ item.label }}
          </h4>
        </template>
        <template v-if="!!item.text" #text>
          <p class="typo-a typo-a-9 margin-bottom-0">
            {{ item.text }}
          </p>
        </template>
      </wa-cta-box>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ContactForm2024Mixin from '../../mixins/contact_form_2024_mixin';

export default {
  name: 'ContactFormTriageTypeCmp',
  mixins: [ContactForm2024Mixin],
  data: function () {
    return {
      routeDependencies: [],
    };
  },
  computed: {
    ...mapState('contactForm2024', ['routes', 'queryString']),
    activeCtas() {
      return this.getActiveCtas(
        'triage_type',
        this.routeDependencies,
        this.queryString,
        this.routes
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormTriageTypeCmp {
  .list-item {
    display: block;
    text-decoration: none;

    & ~ .list-item {
      padding-top: var(--contact-form-list-item-padding-top, 0);
    }
  }
}
</style>
