<template>
  <div class="WaPm56BrunchInNatureChefCard">
    <figure v-if="conf.img_src" class="imgWrapper">
      <img class="pic" :src="conf.img_src" :alt="conf.img_alt" />
    </figure>
    <div class="contentCard">
      <!-- Chef name  -->
      <h3
        :class="[
          'chefTitle',
          'typo-w typo-w-4',
          'margin-top-r margin-bottom-0',
        ]"
      >
        {{ conf.chef_title }}
      </h3>

      <!-- Chef Description  -->
      <p class="chefDescription typo-a-7 margin-top-s margin-bottom-0">
        {{ conf.chef_description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaPm56BrunchInNatureChefCard',
  props: {
    dataConf: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    conf() {
      return this.$_cmsSupport_castPropToType(this.dataConf, 'object');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaPm56BrunchInNatureChefCard {
  .imgWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .pic {
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
  }

  .contentCard {
    text-align: center;

    .chefTitle {
      font-weight: $fh-medium; //$fh-regular;
      color: $color-secondary-orange;
    }

    .chefDescription {
      font-weight: $fh-light;
      color: $color-black;
    }
  }
}
</style>
