import { mapActions } from 'vuex';

export default {
  data() {
    return {
      forceDisable: false,
    };
  },
  computed: {
    disableSubmit() {
      if (
        (this.$v && (this.$v.$anyError || this.$v.$invalid)) ||
        this.forceDisable
      ) {
        return true;
      }

      return false;
    },
    formConfig() {
      if (
        this.fieldsInfos &&
        this.formData &&
        this.formData['step-1'] &&
        this.formData['step-2']
      ) {
        return this.fieldsInfos[this.formData['step-1']][
          this.formData['step-2']
        ];
      }

      return {};
    },
  },
  methods: {
    ...mapActions('contact', ['setFormData']),
    // addDisabledToSubmit() {
    //   var me = this;
    //   const refs = me.$refs;
    //   const form = me.$refs.$form;
    //   const submit = me.$refs.$form.querySelector('[type=submit]');
    //   if (refs && form && submit) {
    //     submit.setAttribute('disabled', true);
    //   }
    // },
    // removeDisabledFromSubmit() {
    //   var me = this;
    //   const refs = me.$refs;
    //   const form = me.$refs.$form;
    //   const submit = me.$refs.$form.querySelector('[type=submit]');
    //   if (refs && form && submit) {
    //     submit.removeAttribute('disabled');
    //   }
    // },
    dataLabel(field, txt) {
      if (this.formConfig[field].required) {
        txt += ' *';
      }

      return txt;
    },
    dataErrors(field, message, otherValidation = null) {
      let errorsArray = [];

      if (this.formConfig[field].required) {
        errorsArray.push({
          condition: !this.$v[field].required,
          message:
            message && message.required
              ? message.required
              : this.contentData.form_field_generic_error_required,
        });
      }

      if (this.formConfig[field].regex) {
        errorsArray.push({
          condition: !this.$v[field].regex,
          message:
            message && message.regex
              ? message.regex
              : this.contentData.form_field_generic_error_regex,
        });
      }

      if (otherValidation) {
        errorsArray.push(...otherValidation);
      }

      return errorsArray.length ? errorsArray : null;
    },
    onFieldChange(k, v) {
      this[k] = v ? v : null;

      let obj = {};
      obj[k] = v ? v : null;

      this.setFormData(obj);

      if (this.$v[k]) {
        this.$v[k].$touch();
      }
    },
    async onPhoneChange(k, v) {
      let prefix = this.phone_prefix;
      let number = this.phone_number;
      if (k === 'phone_prefix') {
        prefix = v ? v : null;
      } else if (k === 'phone_number') {
        number = v ? v : null;
      }
      this.setFormData({ phone_prefix: prefix });
      this.setFormData({ phone_number: number });
      this.$v.phone_prefix.$touch();
      this.$v.phone_number.$touch();
    },
    onSubmitBtnClick() {
      const me = this;
      me.$v.$touch();

      if (!me.$v.$invalid) {
        this.forceDisable = true;
        this.$emit('submit');
      } else {
        console.log('invalid data');
      }
    },
  },
};
