<template>
  <div class="WaConfig d-none" aria-hidden="true" />
</template>

<script>
export default {
  name: 'WaConfig',
  props: {
    dataCurrentUri: {
      type: String,
      required: true,
    },
    dataConfig: {
      type: [Object, String],
      required: true,
    },
    dataLanguages: {
      type: [Object, String],
      required: true,
    },
    dataApi: {
      type: [Object, String],
      required: true,
    },
    dataHideRecaptcha: {
      type: [Boolean, String],
      default: false,
    },
    dataIcons: {
      type: [Object, String],
      default: null,
    },
    dataCdcGigya: {
      type: [Object, String],
      required: true,
    },
    dataPlantATreeContest: {
      type: [Object, String],
      required: true,
    },
    dataLeisureAndFunContest: {
      type: [Object, String],
      required: true,
    },
    dataBrunchInNatureContest: {
      type: [Object, String],
      required: true,
    },
    dataSandwichSummerCampaignContest: {
      type: [Object, String],
      required: true,
    },
    dataExtraInstagramContest: {
      type: [Object, String],
      required: true,
    },
    dataExtraNorwaySandwichContest: {
      type: [Object, String],
      required: true,
    },
    dataSaveTheInsects: {
      type: [Object, String],
      required: true,
    },
    dataSandwichSurf: {
      type: [Object, String],
      required: true,
    },
    dataNewsConfig: {
      type: [Object, String],
      required: true,
    },
    dataSmcIntegration: {
      type: [Object, String],
      required: true,
    },
    dataJekoIntegration: {
      type: [Object, String],
      required: true,
    },
    dataSeptemberGame: {
      type: [Object, String],
      required: true,
    },
    dataCamper: {
      type: [Object, String],
      required: true,
    },
    dataBiscuits: {
      type: [Object, String],
      required: true,
    },
    dataLeisureAndFunTtContest: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    hideRecaptcha() {
      return this.dataHideRecaptcha
        ? this.$_cmsSupport_castPropToType(this.dataHideRecaptcha, 'boolean')
        : null;
    },
    conf() {
      return this.dataConfig
        ? this.$_cmsSupport_castPropToType(this.dataConfig, 'object')
        : null;
    },
    langs() {
      return this.dataLanguages
        ? this.$_cmsSupport_castPropToType(this.dataLanguages, 'object')
        : null;
    },
    apis() {
      return this.dataApi
        ? this.$_cmsSupport_castPropToType(this.dataApi, 'object')
        : null;
    },
    icons() {
      return this.dataIcons
        ? this.$_cmsSupport_castPropToType(this.dataIcons, 'object')
        : null;
    },
    cdcGigya() {
      return this.dataCdcGigya
        ? this.$_cmsSupport_castPropToType(this.dataCdcGigya, 'object')
        : null;
    },
    plantATreeContest() {
      return this.dataPlantATreeContest
        ? this.$_cmsSupport_castPropToType(this.dataPlantATreeContest, 'object')
        : null;
    },
    leisureAndFunContest() {
      return this.dataLeisureAndFunContest
        ? this.$_cmsSupport_castPropToType(
            this.dataLeisureAndFunContest,
            'object'
          )
        : null;
    },
    brunchInNatureContest() {
      return this.dataBrunchInNatureContest
        ? this.$_cmsSupport_castPropToType(
            this.dataBrunchInNatureContest,
            'object'
          )
        : null;
    },
    sandwichSummerCampaignContest() {
      return this.dataSandwichSummerCampaignContest
        ? this.$_cmsSupport_castPropToType(
            this.dataSandwichSummerCampaignContest,
            'object'
          )
        : null;
    },
    extraInstagramContest() {
      return this.dataExtraInstagramContest
        ? this.$_cmsSupport_castPropToType(
            this.dataExtraInstagramContest,
            'object'
          )
        : null;
    },
    extraNorwaySandwichContest() {
      return this.dataExtraNorwaySandwichContest
        ? this.$_cmsSupport_castPropToType(
            this.dataExtraNorwaySandwichContest,
            'object'
          )
        : null;
    },
    saveTheInsects() {
      return this.dataSaveTheInsects
        ? this.$_cmsSupport_castPropToType(this.dataSaveTheInsects, 'object')
        : null;
    },
    sandwichSurf() {
      return this.dataSandwichSurf
        ? this.$_cmsSupport_castPropToType(this.dataSandwichSurf, 'object')
        : null;
    },

    newsConfig() {
      return this.dataNewsConfig
        ? this.$_cmsSupport_castPropToType(this.dataNewsConfig, 'object')
        : null;
    },
    smcIntegration() {
      return this.dataSmcIntegration
        ? this.$_cmsSupport_castPropToType(this.dataSmcIntegration, 'object')
        : null;
    },
    jekoIntegration() {
      return this.dataJekoIntegration
        ? this.$_cmsSupport_castPropToType(this.dataJekoIntegration, 'object')
        : null;
    },
    septemberGame() {
      return this.dataSeptemberGame
        ? this.$_cmsSupport_castPropToType(this.dataSeptemberGame, 'object')
        : null;
    },
    camper() {
      return this.dataCamper
        ? this.$_cmsSupport_castPropToType(this.dataCamper, 'object')
        : null;
    },
    biscuits() {
      return this.dataBiscuits
        ? this.$_cmsSupport_castPropToType(this.dataBiscuits, 'object')
        : null;
    },
    leisureAndFunTtContest() {
      return this.dataLeisureAndFunTtContest
        ? this.$_cmsSupport_castPropToType(
            this.dataLeisureAndFunTtContest,
            'object'
          )
        : null;
    },
  },
  mounted() {
    const promises = [];

    promises.push(
      this.$store.dispatch('config/setConfig', {
        currentUri: this.dataCurrentUri,
      })
    );

    if (this.conf) {
      promises.push(this.$store.dispatch('config/setConfig', this.conf));
    }
    if (this.langs) {
      promises.push(
        this.$store.dispatch('config/setConfig', { languages: this.langs })
      );
      promises.push(
        this.$store.dispatch('config/setConfig', {
          language: this.langs[0].site_code.split('-')[0],
        })
      );
    }
    if (this.apis) {
      promises.push(
        this.$store.dispatch('config/setConfig', { apis: this.apis })
      );
    }
    if (this.hideRecaptcha) {
      promises.push(
        this.$store.dispatch('config/setConfig', {
          hideRecaptcha: this.hideRecaptcha,
        })
      );
    }
    if (this.newsConfig) {
      promises.push(this.$store.dispatch('config/setConfig', this.newsConfig));
    }
    if (this.icons) {
      promises.push(
        this.$store.dispatch('icons/setConfig', { icons: this.icons })
      );
    }
    if (this.cdcGigya) {
      promises.push(
        this.$store.dispatch('gigya/setGigyaInitData', this.cdcGigya)
      );
    }
    if (this.smcIntegration) {
      promises.push(
        this.$store.dispatch('smc/setSmcInitData', this.smcIntegration)
      );
    }
    if (this.plantATreeContest) {
      promises.push(
        this.$store.dispatch(
          'contestPlantATree/setContestPlantATreeInitData',
          this.plantATreeContest
        )
      );
    }
    if (this.leisureAndFunContest) {
      promises.push(
        this.$store.dispatch(
          'pm9LeisureAndFun/setPromoCeInitData',
          this.leisureAndFunContest
        )
      );
    }

    if (this.brunchInNatureContest) {
      promises.push(
        this.$store.dispatch(
          'pm56BrunchInNature/setPm56BrunchInNatureInitData',
          this.brunchInNatureContest
        )
      );
    }
    if (this.sandwichSummerCampaignContest) {
      promises.push(
        this.$store.dispatch(
          'pm56SandwichSummerCampaign/setPm56SandwichSummerCampaignInitData',
          this.sandwichSummerCampaignContest
        )
      );
    }
    if (this.extraInstagramContest) {
      promises.push(
        this.$store.dispatch(
          'pmextraInstagram/setPmextraInstagramInitData',
          this.extraInstagramContest
        )
      );
    }
    if (this.extraNorwaySandwichContest) {
      promises.push(
        this.$store.dispatch(
          'pmextraNorwaySandwich/setPmextraNorwaySandwichInitData',
          this.extraNorwaySandwichContest
        )
      );
    }
    if (this.saveTheInsects) {
      promises.push(
        this.$store.dispatch(
          'pm9SaveTheInsects/setPm9SaveTheInsectsInitData',
          this.saveTheInsects
        )
      );
    }
    if (this.sandwichSurf) {
      promises.push(
        this.$store.dispatch(
          'pm56SandwichSurf/setPm56SandwichSurfInitData',
          this.sandwichSurf
        )
      );
    }
    if (this.septemberGame) {
      promises.push(
        this.$store.dispatch(
          'pm56SeptemberGame/setPm56SeptemberGameInitData',
          this.septemberGame
        )
      );
    }
    if (this.camper) {
      promises.push(
        this.$store.dispatch('pm56Camper/setPm56CamperInitData', this.camper)
      );
    }
    if (this.biscuits) {
      promises.push(
        this.$store.dispatch(
          'pm56Biscuits/setPm56BiscuitsInitData',
          this.biscuits
        )
      );
    }
    if (this.leisureAndFunTtContest) {
      promises.push(
        this.$store.dispatch(
          'pm9LeisureAndFunTt/setPromoCeInitData',
          this.leisureAndFunTtContest
        )
      );
    }
    if (this.jekoIntegration) {
      promises.push(
        this.$store.dispatch('contact/setJekoIntegration', this.jekoIntegration)
      );
    }
    Promise.all(promises)
      .then(() => {
        this.$store.dispatch('setStoreSetupCompleted', true);
      })
      .catch((e) => {
        console.error(e);
      });
  },
};
</script>

<style scoped></style>
