export default {
  data: [
    {
      id: '111',
      name: 'RSM',
    },
    {
      id: '1',
      name: 'AG',
    },
    {
      id: '2',
      name: 'AL',
    },
    {
      id: '3',
      name: 'AN',
    },
    {
      id: '4',
      name: 'AO',
    },
    {
      id: '5',
      name: 'AR',
    },
    {
      id: '6',
      name: 'AP',
    },
    {
      id: '7',
      name: 'AT',
    },
    {
      id: '8',
      name: 'AV',
    },
    {
      id: '9',
      name: 'BA',
    },
    {
      id: '10',
      name: 'BT',
    },
    {
      id: '11',
      name: 'BL',
    },
    {
      id: '12',
      name: 'BN',
    },
    {
      id: '13',
      name: 'BG',
    },
    {
      id: '14',
      name: 'BI',
    },
    {
      id: '15',
      name: 'BO',
    },
    {
      id: '16',
      name: 'BZ',
    },
    {
      id: '17',
      name: 'BS',
    },
    {
      id: '18',
      name: 'BR',
    },
    {
      id: '19',
      name: 'CA',
    },
    {
      id: '20',
      name: 'CL',
    },
    {
      id: '21',
      name: 'CB',
    },
    {
      id: '22',
      name: 'CI',
    },
    {
      id: '23',
      name: 'CE',
    },
    {
      id: '24',
      name: 'CT',
    },
    {
      id: '25',
      name: 'CZ',
    },
    {
      id: '26',
      name: 'CH',
    },
    {
      id: '27',
      name: 'CO',
    },
    {
      id: '28',
      name: 'CS',
    },
    {
      id: '29',
      name: 'CR',
    },
    {
      id: '30',
      name: 'KR',
    },
    {
      id: '31',
      name: 'CN',
    },
    {
      id: '32',
      name: 'EN',
    },
    {
      id: '33',
      name: 'FM',
    },
    {
      id: '34',
      name: 'FE',
    },
    {
      id: '35',
      name: 'FI',
    },
    {
      id: '36',
      name: 'FG',
    },
    {
      id: '37',
      name: 'FC',
    },
    {
      id: '38',
      name: 'FR',
    },
    {
      id: '39',
      name: 'GE',
    },
    {
      id: '40',
      name: 'GO',
    },
    {
      id: '41',
      name: 'GR',
    },
    {
      id: '42',
      name: 'IM',
    },
    {
      id: '43',
      name: 'IS',
    },
    {
      id: '45',
      name: 'AQ',
    },
    {
      id: '44',
      name: 'SP',
    },
    {
      id: '46',
      name: 'LT',
    },
    {
      id: '47',
      name: 'LE',
    },
    {
      id: '48',
      name: 'LC',
    },
    {
      id: '49',
      name: 'LI',
    },
    {
      id: '50',
      name: 'LO',
    },
    {
      id: '51',
      name: 'LU',
    },
    {
      id: '52',
      name: 'MC',
    },
    {
      id: '53',
      name: 'MN',
    },
    {
      id: '54',
      name: 'MS',
    },
    {
      id: '55',
      name: 'MT',
    },
    {
      id: '87',
      name: 'VS',
    },
    {
      id: '56',
      name: 'ME',
    },
    {
      id: '57',
      name: 'MI',
    },
    {
      id: '58',
      name: 'MO',
    },
    {
      id: '59',
      name: 'MB',
    },
    {
      id: '60',
      name: 'NA',
    },
    {
      id: '61',
      name: 'NO',
    },
    {
      id: '62',
      name: 'NU',
    },
    {
      id: '97',
      name: 'OG',
    },
    {
      id: '63',
      name: 'OT',
    },
    {
      id: '64',
      name: 'OR',
    },
    {
      id: '65',
      name: 'PD',
    },
    {
      id: '66',
      name: 'PA',
    },
    {
      id: '67',
      name: 'PR',
    },
    {
      id: '68',
      name: 'PV',
    },
    {
      id: '69',
      name: 'PG',
    },
    {
      id: '70',
      name: 'PU',
    },
    {
      id: '71',
      name: 'PE',
    },
    {
      id: '72',
      name: 'PC',
    },
    {
      id: '73',
      name: 'PI',
    },
    {
      id: '74',
      name: 'PT',
    },
    {
      id: '75',
      name: 'PN',
    },
    {
      id: '76',
      name: 'PZ',
    },
    {
      id: '77',
      name: 'PO',
    },
    {
      id: '78',
      name: 'RG',
    },
    {
      id: '79',
      name: 'RA',
    },
    {
      id: '80',
      name: 'RC',
    },
    {
      id: '81',
      name: 'RE',
    },
    {
      id: '82',
      name: 'RI',
    },
    {
      id: '83',
      name: 'RN',
    },
    {
      id: '84',
      name: 'RM',
    },
    {
      id: '85',
      name: 'RO',
    },
    {
      id: '86',
      name: 'SA',
    },
    {
      id: '88',
      name: 'SS',
    },
    {
      id: '89',
      name: 'SV',
    },
    {
      id: '90',
      name: 'SI',
    },
    {
      id: '91',
      name: 'SR',
    },
    {
      id: '92',
      name: 'SO',
    },
    {
      id: '93',
      name: 'TA',
    },
    {
      id: '94',
      name: 'TE',
    },
    {
      id: '95',
      name: 'TR',
    },
    {
      id: '96',
      name: 'TO',
    },
    {
      id: '98',
      name: 'TP',
    },
    {
      id: '99',
      name: 'TN',
    },
    {
      id: '100',
      name: 'TV',
    },
    {
      id: '101',
      name: 'TS',
    },
    {
      id: '102',
      name: 'UD',
    },
    {
      id: '103',
      name: 'VA',
    },
    {
      id: '104',
      name: 'VE',
    },
    {
      id: '105',
      name: 'VB',
    },
    {
      id: '106',
      name: 'VC',
    },
    {
      id: '107',
      name: 'VR',
    },
    {
      id: '108',
      name: 'VV',
    },
    {
      id: '109',
      name: 'VI',
    },
    {
      id: '110',
      name: 'VT',
    },
  ],
};
