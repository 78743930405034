<template>
  <div class="MapPopupWrapper">
    <div
      :class="['MapPopup', { leisureAndFun: marker.type == 'leisureAndFun' }]"
    >
      <span class="closeIcon" @click="closePopup">
        <svg width="16" height="16">
          <use href="#icon-close" fill="currentColor" />
        </svg>
      </span>
      <p>{{ marker.label }}</p>
      <div v-if="marker.type == 'leisureAndFun'" class="text-left">
        <p
          v-if="marker.title"
          class="title typo-a-9 typo-heavy text-color-sea-salt-blue"
        >
          {{ marker.title }}
        </p>
        <p
          v-if="marker.content"
          class="title typo-a-10 typo-medium text-color-black"
        >
          {{ marker.content }}
        </p>
        <div v-if="marker.href_label">
          <wa-cta
            :data-href="marker.href_url"
            class="margin-top-xs"
            data-icon-id="icon-arrow-angle-right"
            @click.prevent="smoothScrollToAnchor($event)"
          >
            {{ marker.href_label }}
          </wa-cta>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';
export default {
  name: 'MapPopup',
  props: {
    marker: { type: Object, required: true },
  },
  methods: {
    closePopup() {
      this.$emit('close-popup');
    },
    smoothScrollToAnchor(e, callback, options = {}) {
      const $link = e.currentTarget;
      //console.log("e.currentTarget-->", e.currentTarget);
      const targetID = $link.getAttribute('href');
      //console.log("targetID->", targetID);
      const targetAnchor = document.querySelector(
        `[data-anchor-id="${targetID}"]`
      );
      // console.log(
      //   'document.querySelector(`[data-anchor-id="${targetID}"]`)',
      //   document.querySelector(`[data-anchor-id="${targetID}"]`)
      // );
      const { offsetMargin = 0 } = options;
      if (!targetAnchor) return;
      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = targetAnchor.getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top;
      let internalPadding = window.getComputedStyle(targetAnchor).paddingTop;
      const scrollElement =
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement;
      if (internalPadding.indexOf('px')) {
        internalPadding = parseInt(internalPadding.split('px')[0], 10);
      } else {
        internalPadding = 0;
      }
      const totalScrollTarget = offset + internalPadding + offsetMargin - 100;
      //ignoring offsetMargin -90
      anime({
        targets: scrollElement,
        scrollTop: totalScrollTarget,
        duration: 350,
        easing: 'easeInOutQuad',
        complete: () => {
          anime({
            targets: scrollElement,
            scrollTop: totalScrollTarget + 1,
            duration: 1,
            complete: () => {
              if (callback && typeof callback === 'function') {
                setTimeout(() => {
                  callback();
                }, 10);
              }
            },
          });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~$scss/utility';

.MapPopupWrapper {
  position: relative;
  width: 0px;
  height: 0px;
}
.MapPopup {
  background: $color-white;
  border-radius: 5px;
  position: absolute;
  width: 280px;
  padding: 20px;
  text-align: center;
  transform: translateX(-50%) translateY(-100%);
  top: -60px;
  left: 0;
  margin: 0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0;
    right: 50%;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    transform: translateX(10px) translateY(10px);
    border-color: $color-white transparent transparent transparent;
  }
  p {
    margin: 0;
    font-family: $font-primary, $font-system;
    font-size: 18px;
  }
  .closeIcon {
    display: block;
    position: absolute;
    top: 4px;
    right: 10px;
    font-family: sans-serif;
    font-size: 15px;
    line-height: 1;
    cursor: pointer;
  }

  &.leisureAndFun {
    width: 193px;
    .title {
      padding-bottom: 8px;
    }
    ::v-deep .WaCta {
      font-size: 16px !important;
    }
  }
}
</style>
