<template functional>
  <div
    :ref="data.ref"
    :class="['WaSocialShare', data.staticClass, data.class]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot name="wa-social-share-item" />
  </div>
</template>

<script>
export default {
  name: 'WaSocialShare',
  functional: true,
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaSocialShare {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  /* color themes
  * blue -> text-color-blue-logo
  * grey -> text-color-grey-8
  * white -> text-color-white
  */
  &.text-color-blue-logo {
    /deep/ .WaSocialShareItem:hover {
      color: $color-cobalt-blue;
    }
  }

  &.text-color-grey-8 {
    /deep/ .WaSocialShareItem:hover {
      color: $color-grey-8-50;
    }
  }

  &.text-color-white {
    /deep/ .WaSocialShareItem:hover {
      color: rgba($color-white, 0.5);
    }
  }
}
</style>
