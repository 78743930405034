<template>
  <div :class="['RatioSizeBox', vAlign, cropType]" :style="styles">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RatioSizeBox',
  props: {
    dataWidthRatioComponent: {
      type: [Number, String],
      required: true,
    },
    dataHeightRatioComponent: {
      type: [Number, String],
      required: true,
    },
    dataVerticalAlignContent: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return ['top', 'center', 'bottom'].indexOf(value) !== -1;
      },
    },
    dataCrop: {
      type: String,
      default: 'contain',
      validator: function (value) {
        // The value must match one of these strings
        return (
          ['fill', 'contain', 'cover', 'none', 'scale-down'].indexOf(value) !==
          -1
        );
      },
    },
  },
  computed: {
    widthRatioComponent() {
      return this.$_cmsSupport_castPropToType(
        this.dataWidthRatioComponent,
        'number'
      );
    },
    heightRatioComponent() {
      return this.$_cmsSupport_castPropToType(
        this.dataHeightRatioComponent,
        'number'
      );
    },
    ratio() {
      return this.widthRatioComponent / this.heightRatioComponent;
    },
    vAlign() {
      return this.dataVerticalAlignContent;
    },
    cropType() {
      return this.dataCrop;
    },
    styles() {
      return {
        position: 'relative',
        width: '100%',
        height: '0',
        paddingBottom: `${((1 / this.ratio) * 100).toFixed(3)}%`,
        overflow: 'hidden',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.RatioSizeBox {
  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.top {
    > img {
      object-position: top;
    }
  }

  &.center {
    > img {
      object-position: center;
    }
  }

  &.bottom {
    > img {
      object-position: bottom;
    }
  }

  &.fill {
    > img {
      object-fit: fill;
    }
  }

  &.contain {
    > img {
      object-fit: contain;
    }
  }

  &.cover {
    > img {
      object-fit: cover;
    }
  }

  &.none {
    > img {
      object-fit: none;
    }
  }

  &.scale-down {
    > img {
      object-fit: scale-down;
    }
  }
}
</style>
