/* eslint-disable */
import Vue from 'vue';
//import { debug } from '@helpers/utils';
import ContactFormSpa from '../views/spa/contact-form-2024/contact-form-spa.vue';

Vue.component("contact-form-spa", ContactFormSpa);
export const components = require.context('@components/', true, /[A-Z]\w+\.vue$/im); // , 'lazy' removed

// eslint-disable-next-line
components.keys().forEach(filename => {
  // eslint-disable-next-line
  const relativePath = filename.split('./')[1];
  // eslint-disable-next-line
  const file = filename.split('/').pop();
  // eslint-disable-next-line
  const name = file.split('.')[0];
  // eslint-disable-next-line
  Vue.component(name, (resolve) => {
    // eslint-disable-next-line
    import(/*webpackChunkName: '[request]'*/ `@components/${relativePath}`)
      .then((Comp) => {
        // feature working suppressing debug
        // if (debug) {
        //   console.log(`%c[COMP CHUNK ${name} OK]`,'padding:4px;background-color:teal;color:white;');
        // }
        resolve(Comp.default);
      });
  });
});
