<template>
  <div class="WaAllNews">
    <div class="container-fluid">
      <!-- Bigger news -->
      <div class="bigNews padding-top-l">
        <transition-group
          class="newsTransition row"
          name="slide"
          mode="out-in"
          :duration="{ enter: 500, leave: 0 }"
        >
          <div
            v-for="(newsItem, i) in higlightNews"
            :key="`news_card_${i}`"
            class="col-12 col-sm-6 padding-bottom-m"
          >
            <wa-generic-card
              data-type="news"
              :data-conf="getGenericCardConf(newsItem)"
              :data-alternative-ratio="true"
            />
          </div>
        </transition-group>
      </div>
      <!-- All news -->
      <div class="news padding-bottom-m">
        <transition-group
          class="newsTransition row"
          name="slide"
          mode="out-in"
          :duration="{ enter: 500, leave: 0 }"
        >
          <div
            v-for="(newsItem, i) in otherNews"
            :key="`news_card_${i}`"
            class="col-12 col-sm-6 col-md-4 padding-bottom-m"
          >
            <wa-generic-card
              data-type="news"
              class="ratio-4-3"
              :data-conf="getGenericCardConf(newsItem)"
              :data-alternative-ratio="false"
            />
          </div>
        </transition-group>
      </div>
      <div class="row loadmore">
        <!-- Load more button -->
        <div class="col-12 text-center">
          <wa-cta
            v-if="showLoadMore"
            class="primary loadMoreBtn"
            @click.prevent="loadMore"
          >
            {{ $store.state.config.load_more_label }}
          </wa-cta>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
//import moment from 'moment';

export default {
  name: 'WaAllNews',
  props: {
    dataPaginatedApi: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('news', ['pagination']),
    ...mapGetters('news', ['flatData']),
    higlightNews() {
      return this.news.slice(0, 2);
    },
    otherNews() {
      return this.news.slice(2);
    },
    news() {
      return this.flatData.length
        ? this.flatData.map((el) => {
            return { ...el };
          })
        : [];
    },
    showLoadMore() {
      return this.pagination.current_page !== this.pagination.final_page;
    },
  },
  created() {
    this.$store.dispatch(`news/setApiUrl`, this.dataPaginatedApi);
    this.$store.dispatch(`news/initPagination`, { page_size: 11 });
  },
  methods: {
    ...mapActions('news', ['loadMore']),
    getGenericCardConf(newsItem) {
      return Object.assign({}, newsItem, {
        card_href: newsItem.detail_url,
        card_img_src: newsItem.img_small,
        card_img_alt: newsItem.alt_text,
        card_title: newsItem.title,
        card_small_title: newsItem.publication_start,
        card_title_color: 'text-color-black',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaAllNews {
  .loadmore {
    .slide-enter-active {
      -moz-transition-duration: 0.3s;
      -webkit-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -moz-transition-timing-function: ease-in;
      -webkit-transition-timing-function: ease-in;
      -o-transition-timing-function: ease-in;
      transition-timing-function: ease-in;
    }

    .slide-leave-active {
      -moz-transition-duration: 0.3s;
      -webkit-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    .slide-enter-to,
    .slide-leave {
      max-height: 100px;
      overflow: hidden;
    }

    .slide-enter,
    .slide-leave-to {
      overflow: hidden;
      max-height: 0;
    }
    /* needed for directive */
    .expand {
      overflow: hidden;
      transition: height 0.3s linear;

      &[aria-expanded='false'] {
        height: 0 !important;
      }
    }
    /* needed for directive */
    .u-no-transition {
      transition: none !important;
    }
  }
}
</style>
