<template>
  <div
    v-if="dataProducts"
    :class="[
      'WaMultiSelectListItem',
      {
        dynamicListItem: dynamicList,
      },
    ]"
  >
    <ul class="listItems my-0">
      <li
        v-for="(product, idx) in productsList"
        :key="`product-${idx}-${_uid}-${selectedProductsId.length}`"
        class="listItem"
      >
        <div
          :class="[
            'label typo-a-6 typo-medium label-wrapper',
            isDisabled(product.id) ? 'text-color-grey-4' : 'text-color-grey-8',
          ]"
        >
          <template v-if="!dynamicList">
            <span>{{ product.name }}</span>
          </template>
          <template v-else>
            <wa-select
              v-model="availableProducts[idx].choosenCategory"
              class="text-color-blue-logo typo-medium typo-a-6"
              :data-show-label="false"
              data-label=""
              :data-name="`category-${idx}-${_uid}-${selectedProductsId.length}`"
              :data-option-list="mappedCategoriesForSelect"
              :data-placeholder="dataCategoriesPlaceholder"
              @change="
                availableProducts[idx].choosenProduct = '';
                availableProducts[idx].amount = 0;
              "
            >
              <template slot="icon">
                <svg width="24" height="24">
                  <use href="#icon-arrow-angle-down" fill="currentColor" />
                </svg>
              </template>
            </wa-select>

            <wa-select
              v-model="availableProducts[idx].choosenProduct"
              class="text-color-blue-logo typo-medium typo-a-6"
              :data-show-label="false"
              data-label=""
              :data-name="`product-${idx}-${_uid}-${selectedProductsId.length}`"
              :data-option-list="
                mappedProductsForSelect(availableProducts[idx].choosenCategory)
              "
              :data-placeholder="dataProductsPlaceholder"
              @change="availableProducts[idx].amount = 0"
            >
              <template slot="icon">
                <svg width="24" height="24">
                  <use href="#icon-arrow-angle-down" fill="currentColor" />
                </svg>
              </template>
            </wa-select>
          </template>
        </div>
        <div class="elements-wrapper">
          <div class="elements">
            <span
              :class="[
                'icon',
                (
                  !dynamicList
                    ? isDisabled(product.id)
                    : isDynamicDisabled(availableProducts[idx])
                )
                  ? 'disabled'
                  : null,
              ]"
              @click="
                !dynamicList
                  ? removeProduct(product.id)
                  : removeDynamicProduct(idx)
              "
              @mouseover="onMouseOver(idx, 'remove', product.id)"
              @mouseleave="isHoverIndexRemove = null"
            >
              <svg width="24" height="25">
                <use
                  v-if="isHoverIndexRemove === idx"
                  href="#icon-minus-circle-select-hover"
                  fill="currentColor"
                />
                <use
                  v-else
                  href="#icon-minus-circle-select"
                  fill="currentColor"
                />
              </svg>
            </span>
            <div
              :class="['quantity typo-a-6 typo-medium', 'text-color-blue-logo']"
            >
              <span
                v-if="
                  (!dynamicList && amount(product.id) > 0) ||
                  (dynamicList && availableProducts[idx].amount > 0)
                "
              >
                <template v-if="!dynamicList">
                  {{ amount(product.id) }}
                </template>
                <template v-else>{{ availableProducts[idx].amount }}</template>
              </span>
              <span
                v-else
                :class="[
                  (
                    !dynamicList
                      ? isDisabled(product.id)
                      : isDynamicDisabled(availableProducts[idx])
                  )
                    ? 'text-color-grey-4'
                    : 'text-color-blue-logo',
                ]"
              >
                0
              </span>
            </div>
            <span
              :class="[
                'icon',
                (
                  !dynamicList
                    ? isDisabled(product.id)
                    : isDynamicDisabled(availableProducts[idx])
                )
                  ? 'disabled'
                  : null,
              ]"
              @click="
                !dynamicList ? addProduct(product.id) : addDynamicProduct(idx)
              "
              @mouseover="onMouseOver(idx, 'add', product.id)"
              @mouseleave="isHoverIndexAdd = null"
            >
              <svg width="24" height="25">
                <use
                  v-if="isHoverIndexAdd === idx"
                  href="#icon-plus-circle-select-hover"
                  fill="currentColor"
                />
                <use
                  v-else
                  href="#icon-plus-circle-select"
                  fill="currentColor"
                />
              </svg>
            </span>
          </div>
          <div
            v-if="dynamicList"
            class="removeFromList typo-extra-bold text-color-secondary-red typo-a-8"
          >
            <a
              class="text-color-secondary-red typo-a-8 typo-extra-bold"
              href="#"
              @click.prevent="removeFromList(idx)"
            >
              {{ dataProductsRemoveText }}
            </a>
            <svg width="16" height="16">
              <use href="#icon-close" fill="currentColor" />
            </svg>
          </div>
        </div>
      </li>
    </ul>
    <div
      v-if="selectedProductsId.length === dataTotalProducts"
      class="margin-top-s typo-a-10 text-color-grey-8"
      v-html="interpolatedInfoTextRemove"
    />
    <div
      v-else
      class="margin-top-s typo-a-10 text-color-grey-8"
      v-html="interpolatedInfoText"
    />
    <div v-if="dynamicList" class="add-cta-wrapper text-center margin-top-m">
      <wa-cta class="primary add-cta" @click.prevent="addProductToList">
        {{ dataProductsAddMoreText }}
        <svg width="24" height="25">
          <use href="#icon-add" fill="currentColor" />
        </svg>
      </wa-cta>
    </div>
  </div>
</template>

<script>
/**
 *     <div class="container">
      <div class="row">
        <div class="col">
          //DYNAMIC VARIANT
          <wa-multi-select-list-item
            class="padding-top-xxl"
              data-info-text="Fuffa"
              data-info-text-remove="Rifuffa"
              :data-total-products="5"
              :data-products="[{id:1,name:'myprod1',category:'mycat1',category_id:'1'},{id:2,name:'myprod2',category:'mycat2',category_id:'2'},{id:3,name:'myprod3',category:'mycat2',category_id:'2'}]"
              :data-dynamic-list="true"
              data-categories-placeholder="Seleziona una categoria"
              data-products-placeholder="Seleziona un prodotto"
            ></wa-multi-select-list-item>

            //STATIC VARIANT
            <wa-multi-select-list-item
            class="padding-top-xxl"
              data-info-text="Fuffa"
              data-info-text-remove="Rifuffa"
              :data-total-products="5"
              :data-products="[{id:1,name:'myprod1',category:'mycat1',category_id:'1'},{id:2,name:'myprod2',category:'mycat2',category_id:'2'},{id:3,name:'myprod3',category:'mycat2',category_id:'2'}]"

            ></wa-multi-select-list-item>
        </div>
      </div>
    </div>

 */
export default {
  name: 'WaMultiSelectListItem',
  props: {
    dataInfoText: {
      type: String,
      required: true,
    },
    dataInfoTextRemove: {
      type: String,
      required: false,
    },
    dataCategoriesPlaceholder: {
      type: String,
      required: false,
      default: 'Missing placeholder',
    },
    dataProductsPlaceholder: {
      type: String,
      required: false,
      default: 'Missing placeholder',
    },
    dataProductsRemoveText: {
      type: String,
      required: false,
      default: 'Missing placeholder',
    },
    dataProductsAddMoreText: {
      type: String,
      required: false,
      default: 'Missing placeholder',
    },
    dataTotalProducts: {
      type: Number,
      required: true,
    },
    dataProducts: {
      type: Array,
      default: null,
    },
    dataDynamicList: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      isHoverIndexAdd: null,
      isHoverIndexRemove: null,
      availableProducts: [],
      selectedProductsId: [], //[1,1,3,3,5]
    };
  },
  computed: {
    // removeIconClasses() {
    //   return !this.dynamicList
    //     ?
    //     : '';
    // },
    products() {
      //console.log(this.dataProducts);
      return this.$_cmsSupport_castPropToType(this.dataProducts, 'array');
    },
    mappedProductsForSelect() {
      return (category_id) => {
        return this.products
          .filter((p) => p.category_id === category_id)
          .map((p) => {
            return {
              dataValue: p.name,
              key: p.id,
            };
          });
      };
    },
    mappedCategoriesForSelect() {
      const mapped = this.products.map((p) => {
        return {
          dataValue: p.category,
          key: p.category_id,
        };
      });

      return mapped.filter(
        (v, i, a) => a.findIndex((item) => item.key === v.key) === i
      );
    },
    dynamicList() {
      //console.log(this.dataProducts);
      return this.$_cmsSupport_castPropToType(this.dataDynamicList, 'boolean');
    },
    productsList() {
      return this.dynamicList ? this.availableProducts : this.products;
    },
    // dynamicProducts() {
    //   return this.availableProducts.map((availableProduct, idx) => {
    //     return {
    //       ...this.products.find(
    //         (product) => product.id === availableProduct.productId
    //       ),
    //       localId: idx,
    //     };
    //   });
    // },
    interpolatedInfoText() {
      let string = this.dataInfoText
        .replace(
          '%selected',
          `<span class="text-color-black">${this.selectedProductsId.length}</span>`
        )
        .replaceAll(
          '%totals',
          `<span class="text-color-black">${this.dataTotalProducts}</span>`
        );
      return `${string}`;
    },
    interpolatedInfoTextRemove() {
      let string = this.dataInfoTextRemove
        .replace(
          '%selected',
          `<span class="text-color-black">${this.selectedProductsId.length}</span>`
        )
        .replace(
          '%totals',
          `<span class="text-color-black">${this.dataTotalProducts}</span>`
        );
      return `${string}`;
    },
  },
  watch: {
    availableProducts: {
      immediate: false,
      handler(olVal, newVal) {
        if (this.dynamicList) {
          this.selectedProductsId = newVal.reduce((acc, curr) => {
            if (curr.amount > 0 && curr.choosenProduct.length > 0) {
              return [
                ...acc,
                ...Array(curr.amount).fill(parseInt(curr.choosenProduct, 10)),
              ];
            } else {
              return [...acc];
            }
          }, []);
        }
      },
    },
  },
  mounted() {
    //to improve it should be only for dynamic versione
    if (this.dataDynamicList === 'true' || this.dataDynamicList === true) {
      for (let i = 0; i < 1; i++) {
        this.availableProducts = [
          ...this.availableProducts,
          {
            choosenCategory: '',
            choosenProduct: '',
            amount: 0,
          },
        ];
      }
    }
  },
  methods: {
    removeFromList(productIndex) {
      this.availableProducts.splice(productIndex, 1);
    },
    addProductToList() {
      this.availableProducts = [
        ...this.availableProducts,
        { choosenCategory: '', choosenProduct: '', amount: 0 },
      ];
    },
    onMouseOver(idx, type, id) {
      if (!this.isDisabled(id)) {
        if (type === 'add') {
          this.isHoverIndexAdd = idx;
        } else {
          this.isHoverIndexRemove = idx;
        }
      }
    },
    amount(id) {
      const arr = this.selectedProductsId;
      const counts = {};

      for (const num of arr) {
        counts[num] = counts[num] ? counts[num] + 1 : 1;
      }

      return counts[id];
    },
    getIndexToRemove(id) {
      return (
        this.selectedProductsId.length > 0 &&
        this.selectedProductsId.indexOf(id)
      );
    },
    canAdd() {
      //ritorna se posso aggiungere un item all'array oppure no controllando quanti item sono stati aggiunti
      //console.log(this.selectedProductsId.length , this.dataTotalProducts)
      //console.log(this.selectedProductsId.length < this.dataTotalProducts)
      return this.selectedProductsId.length < this.dataTotalProducts;
    },
    canAddDynamic() {
      //ritorna se posso aggiungere un item all'array oppure no controllando quanti item sono stati aggiunti
      //console.log(this.selectedProductsId.length , this.dataTotalProducts)
      //console.log(this.selectedProductsId.length < this.dataTotalProducts)
      return (
        this.availableProducts.reduce(
          (accumulator, curr) => accumulator + curr.amount,
          0
        ) < this.dataTotalProducts
      );
    },
    addProduct(id) {
      //chiama can add, se risponde true, aggiunge un prodotto ai prodotti selezionati, altrimenti non fa niente

      if (this.canAdd()) {
        this.selectedProductsId.push(id);
        this.$emit('add-product');
      }
    },
    addDynamicProduct(idx) {
      if (this.canAddDynamic()) {
        const item = { ...this.availableProducts[idx] };
        item.amount++;

        this.availableProducts.splice(idx, 1, item);

        this.$emit('add-product');
      }
    },
    removeProduct(id) {
      const idx = this.getIndexToRemove(id);
      if (this.getIndexToRemove(id) !== -1) {
        this.selectedProductsId.splice(idx, 1);
        this.$emit('remove-product');
      }
    },
    removeDynamicProduct(idx) {
      const item = { ...this.availableProducts[idx] };
      if (item.amount > 0) {
        item.amount--;
        this.availableProducts.splice(idx, 1, item);
        this.$emit('remove-product');
      }
    },
    isDisabled(id) {
      return (
        this.selectedProductsId.length === this.dataTotalProducts &&
        !this.selectedProductsId.includes(id)
      );
    },

    isDynamicDisabled(models) {
      //temp
      return (
        models.choosenCategory === '' ||
        models.choosenProduct === '' ||
        !this.canAddDynamic()
      );
      // return (
      //   this.selectedProductsId.length === this.dataTotalProducts &&
      //   !this.selectedProductsId.includes(id)
      // );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaMultiSelectListItem {
  .listItem {
    margin: 0;
    padding: 24px 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $color-grey-4;

    @media (min-width: $bootstrap-sm) {
      padding: 17px 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @media (min-width: $bootstrap-md) {
      padding: 24px 0;
    }

    .elements-wrapper {
      display: flex;
      justify-content: space-between;
      .elements {
        display: flex;
        align-items: center;
        margin-top: 12px;

        @media (min-width: $bootstrap-sm) {
          margin-top: 0;
        }

        .quantity {
          padding: 0 16px;

          @media (min-width: $bootstrap-md) {
            padding: 0 24px;
          }
        }

        .icon {
          cursor: pointer;
          color: $color-grey-8;

          &.disabled {
            color: $color-grey-4;
          }
        }
      }
    }
  }

  /*
margin-top-s
margin-top-m
*/
  &.dynamicListItem {
    .listItem {
      padding: 12px 0 24px;
      @media (min-width: $bootstrap-sm) {
        padding: 8px 0;
      }
      @media (min-width: $bootstrap-md) {
        padding: 12px 0;
      }
      .elements-wrapper {
        margin-top: 16px;
        @media (min-width: $bootstrap-sm) {
          margin-top: 0;
        }
        .elements {
          margin-top: 0;
        }
      }
    }
    .WaSelect {
      height: 45px;
      display: flex;
      align-items: center;
      @media (min-width: $bootstrap-sm) {
        height: 48px;
      }
      @media (min-width: $bootstrap-md) {
        height: 55px;
      }
      & + .WaSelect {
        margin-top: 8px;
        @media (min-width: $bootstrap-sm) {
          margin-top: 0;
          margin-left: 40px;
        }
        @media (min-width: $bootstrap-lg) {
          margin-left: 64px;
        }
      }
    }

    .label-wrapper {
      display: flex;
      flex-direction: column;
      @media (min-width: $bootstrap-sm) {
        flex-direction: row;
      }
      .dynamic-select {
        &:first-child {
          padding-top: 24px;
        }
        padding-bottom: 30px;
        @media (min-width: $bootstrap-sm) {
          &:first-child {
            padding-top: 0px;
          }
          padding-bottom: 0px;
        }
      }
      .select-spacing {
        padding-left: 0px;
        @media (min-width: $bootstrap-sm) {
          padding-left: 30px;
        }
      }
    }
    .removeFromList {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (min-width: $bootstrap-sm) {
        margin-left: 24px;
      }
      @media (min-width: $bootstrap-md) {
        margin-left: 32px;
      }
      &:hover {
        opacity: 0.75;
      }
      a {
        padding-right: 10px;
        text-decoration: none;
      }
    }
    .add-cta-wrapper {
      width: 100%;
      .add-cta {
        width: auto;
      }
    }
  }
}
</style>
