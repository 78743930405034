import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    'contest-published': false,
    'landing-page-url': '',
    'participate-page-url': '',
    'redeem-prize-page-url': '',
    'legal-age': '',
    'products-api': '',
    'stores-api': '',
    'privacy-check': '',
    'join-api': '',
    'check-winner-api': '',
    'check-winner-debug': '',
    'redeem-prize-api': '',
    'ch-key-pm5-6-sandwich-summer-campaign': '',
    'reg-source-pm5-6-sandwich-summer-campaign': '',
    'i18n': {
      participate: {
        hero_title: 'VINN JBL-HöGTALARE ELLER HAGLöFS-RYGGSäCK!',
        hero_title_small: '',
        hero_description: 'On the go med Wasa Sandwich!',
        product_heading: 'Which PRODUCT DID YOU BUY?',
        products_error_required: 'The products selection is mandatory',
        data_total_products: 5,
        data_info_text: '',
        data_info_text_remove: '',
        shop_type_heading: 'WHERE DID YOU BUY?',
        shop_type_description:
          '<p>Each receipt allows only one participation <br> (even if you purchase more packages in the same document)</p>',
        shop_type_store_label: 'Store',
        shop_type_online_label: 'Online',
        shop_type_error_required: 'The shop type is mandatory',
        shop_label: 'Insert store name',
        shop_placeholder: 'Insert store name',
        shop_error_required: 'Insert store name',
        picture_heading: 'Upload your receipt',
        picture_description: '',
        picture_label: 'Drag & Drop',
        picture_label_small: 'Upload an image',
        picture_label_hover: 'téléchargez-le ici',
        picture_cta: 'browse',
        picture_helper: 'jpg or png (max 3 mb) or browse',
        picture_helper_small: 'jpg or png (max 3 mb) or browse',
        picture_error_required: 'The picture is mandatory',
        picture_error_format: 'File format not allowed',
        picture_error_size: 'Le fichier dépasse la taille maximale',
        picture_instruction_heading: 'How to correctly photograph your receipt',
        picture_instruction_legend_1: 'Store name',
        picture_instruction_legend_2: 'Wasa product',
        picture_instruction_legend_3: 'Date and time',
        picture_instruction_legend_4: 'Total sum',
        picture_instruction_legend_5: 'Etc (tbc with Reinbold Rost)',
        picture_instruction_hint_1: 'Place the receipt of a flat surface',
        picture_instruction_hint_2:
          'Frame the receipt so taht it is fully visible',
        picture_instruction_hint_3:
          'Make sure yuor smartphone is parallel to the receipt',
        picture_instruction_info_heading: 'Info to know',
        picture_instruction_info_1:
          'You can take a picture of a long receipt by folding it.',
        picture_instruction_info_2:
          'If the back of the receipt also has data on it, photograph it and upload the photo.',
        rules_label: 'I have read the %contest rules%',
        rules_href: '',
        privacy_href: '',
        rules_error_required: 'This field is mandatory',
        eligible_text_label: 'Write your cover letter here',
        eligible_text_helper: '* These fields are mandatory',
        eligible_text_required: 'Mandatory',
        submit_cta: 'Play now',
        global_error_logged:
          'Error occurred: You must be logged in to participate in the contest',
        global_error_legal_age:
          'Error occurred: You must be of legal age to participate in the contest',
        global_error_inactive_contest:
          'Error occurred: The contest is inactive',
        global_error_external_server_communication_failed:
          'Error occurred: Communication with external server has failed',
        global_error_unable_to_retrieve_user_token:
          'Error occurred: User token not retrived',
        global_error_missing_parameters_in_configuration:
          'Error occurred: Missing some parameters in configuration',
        global_error_rule_acceptance_flag_false:
          'Error occurred: Rule acceptance missing',
        thank_you_title: 'All Done!<br>Thank you for participating',
        thank_you_description: 'We will let you kow soon',
        thank_you_cta: 'Back tho homepage',
      },
      prize: {
        hero_title: 'Remplir pour recevoir le prix',
        hero_title_small: 'Contest',
        hero_description:
          'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise.',
        title: 'Your contact information',
        description_0:
          'Leave your contact information and we will get back to you. - Backpack product',
        description_1:
          'Leave your contact information and we will get back to you product - JBL',
        name_label: 'Name*',
        name_error_required: 'This field is required',
        name_error_minLength: 'Incorrect min length',
        name_error_maxLength: 'Incorrect max length',
        name_error_regex: 'An error has occured',
        surname_label: 'Surname*',
        surname_error_required: 'This field is required',
        surname_error_minLength: 'Incorrect min length',
        surname_error_maxLength: 'Incorrect max length',
        surname_error_regex: 'An error has occured',
        email_label: 'Email*',
        tooltip_personal_area_text: "Modificabile dall'area personale",
        prefix_label: 'Prefix',
        prefix_default: '+39',
        phone_label: 'Phone*',
        phone_error_required: 'This field is required',
        phone_error_minLength: 'Incorrect min length',
        phone_error_maxLength: 'Incorrect max length',
        phone_error_regex: 'An error has occured',
        city_label: 'City*',
        city_placeholder: 'City name',
        city_error_required: 'This field is required',
        postal_code_label: 'ZIP code*',
        postal_code_error_required: 'This field is required',
        postal_code_error_regex: 'An error has occured',
        address_label: 'Street name*',
        address_error_required: 'This field is required',
        address_error_minLength: 'Incorrect min length',
        address_error_maxLength: 'Incorrect max length',
        address_error_regex: 'An error has occured',
        mandatory_field_indication_placeholder: '* These fields are mandatory',
        submit_cta: 'Continue',
        global_error_logged:
          'Error occurred: You must be logged in to participate in the contest',
        global_error_legal_age:
          'Error occurred: You must be of legal age to participate in the contest',
        global_error_inactive_contest:
          'Error occurred: The contest is inactive',
        global_error_external_server_communication_failed:
          'Error occurred: Communication with external server has failed',
        global_error_unable_to_retrieve_user_token:
          'Error occurred: User token not retrived',
        global_error_missing_parameters_in_configuration:
          'Error occurred: Missing some parameters in configuration',
        thank_you_title: 'All Done!<br>Thank you for participating',
        thank_you_description: 'You will receive your prize soon',
        thank_you_cta: 'Back tho homepage',
      },
    },
  },
  actions,
  mutations,
  getters,
};
