<template>
  <div class="WaLottieAnimation">
    <div ref="$enteringReference"></div>
    <div ref="$animation"></div>
  </div>
</template>

<script>
/*
<wa-lottie-animation
          data-path="{% static 'project/json/1.json' %}"
          data-loop="true"
          data-autoplay="true"
          data-play-on-viewport-enter="true"
          data-renderer="svg"
        ></wa-lottie-animation>
        */
import lottie from 'lottie-web';
import { useScroll } from '@vueuse/core';

export default {
  name: 'WaLottieAnimation',
  props: {
    dataPath: {
      type: String,
      required: true,
    },
    dataLoop: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    dataAutoplay: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    dataPlayOnViewportEnter: {
      type: [String, Boolean],
      required: false,
      default: true,
    },
    dataRenderer: {
      type: String,
      required: true,
      validator: function (value) {
        const validValues = ['svg', 'canvas', 'html'];
        // The value must match one of these strings
        return validValues.includes(value);
      },
    },
  },
  setup() {
    const { x, y /*, isScrolling, arrivedState, directions*/ } =
      useScroll(document);
    return {
      x,
      y /*,
      isScrolling,
      arrivedState,
      directions,*/,
    };
  },
  data() {
    return {};
  },
  computed: {
    xAndY() {
      return `${this.x}|${this.y}`;
    },
    lottieConfig() {
      return {
        path: this.dataPath,
        loop: this.$_cmsSupport_castPropToType(this.dataLoop, 'boolean'),
        autoplay: this.$_cmsSupport_castPropToType(
          this.dataAutoplay,
          'boolean'
        ),
        name: `${this._uid}`,
        renderer: this.dataRenderer,
        container: this.$refs.$animation,
      };
    },
    playOnViewportEnter() {
      return this.$_cmsSupport_castPropToType(
        this.dataPlayOnViewportEnter,
        'boolean'
      );
    },
  },
  watch: {
    xAndY: {
      handler: function () {
        if (this.$refs.$animation) {
          if (
            this.enteredInViewport(
              this.$refs.$animation,
              document.documentElement
            )
          ) {
            this.animation.play();
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    (() => {
      let doCheck = () => {
        if (!this.$refs.$animation) {
          setTimeout(doCheck, 50);
        } else {
          this.animation = lottie.loadAnimation(this.lottieConfig);
        }
      };
      doCheck();
    })();
  },
  methods: {
    enteredInViewport(ele, container) {
      const eleRect = ele.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      const isVisible =
        Math.round(eleRect.y) >= Math.round(containerRect.y) &&
        Math.round(eleRect.y + eleRect.height) <=
          Math.round(containerRect.y + containerRect.height);

      return isVisible;
    },
  },
};
</script>
