<template>
  <div class="WaContestPlantATree">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WaContestPlantATree',
  props: {
    dataContestPlantATree: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    contentData() {
      return this.$_cmsSupport_castPropToType(
        this.dataContestPlantATree,
        'object'
      );
    },
  },
  created() {
    this.setContestPlantATreeContentData(this.contentData);
  },
  methods: {
    ...mapActions('contestPlantATree', ['setContestPlantATreeContentData']),
  },
};
</script>

<style scoped></style>
