<template>
  <div class="ContactFormTicketingCountryCmp">
    <form @submit.prevent>
      <wa-dropdown
        v-model.trim="$v.ticketing_country.$model"
        data-name="ticketing_country"
        :data-use-int="false"
        :data-label="ticketingCountryLabel"
        :data-option-list="
          countries.items && countries.items.length
            ? [
                { key: 'GLOBAL', dataValue: '----------' },
                ...countries.items.map((e) => {
                  return {
                    key: e.value,
                    dataValue: e.label,
                  };
                }),
              ]
            : [{ key: 'GLOBAL', dataValue: '----------' }]
        "
        :data-error-occurred="$v.ticketing_country.$error"
        :data-errors="[
          {
            condition: !$v.ticketing_country.required,
            message: routes.ticketing_country.controls.dropdown.requiredError,
          },
          {
            condition: !$v.ticketing_country.notGlobal,
            message: routes.ticketing_country.controls.dropdown.requiredError,
          },
        ]"
        @change="onChange"
      />
      <div class="submit-wrapper text-center">
        <wa-cta
          class="primary"
          data-type="submit"
          @click.prevent="onSubmitForm"
        >
          {{ routes.ticketing_country.controls.continueCta.label }}
        </wa-cta>
      </div>
    </form>
  </div>
</template>

<script>
import { helpers, required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

const notGlobal = (value) => !helpers.req(value) || value !== 'GLOBAL';

export default {
  name: 'ContactFormTicketingCountryCmp',
  data() {
    return {
      ticketing_country: null,
    };
  },
  computed: {
    ...mapState('contactForm2024', ['routes', 'queryString', 'countries']),
    ticketingCountryLabel() {
      return this.routes?.ticketing_country?.controls?.dropdown?.labels[
        this.queryString?.triage_topic
      ];
    },
  },
  mounted() {
    this.ticketing_country = this.countries.currentCountry.toUpperCase();
  },
  validations() {
    return {
      ticketing_country: {
        required,
        notGlobal,
      },
    };
  },
  methods: {
    onChange() {
      const item = this.countries.items.find(
        (e) => e.value === this.ticketing_country
      );
      if (item && item.goToUrl && !item.cleanQs) {
        location.href = `${item.goToUrl}/#${
          this.routes.ticketing_country.controls.continueCta.goToNames[
            this.queryString.triage_topic
          ]
        }?${new URLSearchParams({
          ...this.queryString,
          initial_country: this.countries.currentCountry,
          ticketing_country: this.ticketing_country,
          page_order: `${this.queryString.page_order},${this.routes.ticketing_country.name}`,
        }).toString()}`;
      } else if (item && item.goToUrl && item.cleanQs) {
        location.href = `${item.goToUrl}`;
      }
    },
    onSubmitForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const qs = {
          ...this.queryString,
          initial_country: this.countries.currentCountry,
          ticketing_country: this.ticketing_country,
          page_order: `${this.queryString.page_order},${this.routes.ticketing_country.name}`,
        };

        const item = this.countries.items.find(
          (e) => e.value === this.ticketing_country
        );
        if (item && item.goToUrl) {
          location.href = `${item.goToUrl}/#${
            this.routes.ticketing_country.controls.continueCta.goToNames[
              this.queryString.triage_topic
            ]
          }?${new URLSearchParams(qs).toString()}`;
        } else {
          this.$router.push({
            name: this.routes.ticketing_country.controls.continueCta.goToNames[
              this.queryString.triage_topic
            ],
            query: qs,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ContactFormTicketingCountryCmp {
  .submit-wrapper {
    padding-top: var(
      --contact-form-ticketing-country-submit-wrapper-padding-top,
      0
    );
  }
}
</style>
