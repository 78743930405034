import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      current: null,
    };
  },
  computed: {
    ...mapState('contact', ['currentStep', 'formData', 'contentData']),
    ...mapGetters('contact', [
      'currentStepNavigation',
      'totalStepNavigation',
      'steps',
    ]),
    prev() {
      return this.current ? this.current.prev : 'home';
    },
    next() {
      return this.current ? this.current.next : 'home';
    },
    step1Links() {
      return [
        {
          title: this.contentData.step_one_suggestion_cta_title,
          subtitle: this.contentData.step_one_suggestion_cta_subtitle,
          iconId: this.contentData.step_one_suggestion_icon,
          active: this.contentData.step_one_suggestion_cta_active,
          slug: 'advice',
        },
        {
          title: this.contentData.step_one_question_cta_title,
          subtitle: this.contentData.step_one_question_cta_subtitle,
          iconId: this.contentData.step_one_question_icon,
          active: this.contentData.step_one_question_cta_active,
          slug: 'info',
        },
        {
          title: this.contentData.step_one_problem_cta_title,
          subtitle: this.contentData.step_one_problem_cta_subtitle,
          iconId: this.contentData.step_one_problem_icon,
          active: this.contentData.step_one_problem_cta_active,
          slug: 'problem',
        },
      ];
    },
    step2Links() {
      return [
        {
          title: this.contentData.step_two_products_cta_label,
          iconId: this.contentData.step_two_products_icon,
          active: this.contentData.step_two_products_cta_active,
          slug: 'products',
          where: ['advice', 'info', 'problem'],
        },
        {
          title: this.contentData.step_two_contests_cta_label,
          iconId: this.contentData.step_two_contests_icon,
          active: this.contentData.step_two_contests_cta_active,
          slug: 'promotions',
          where: ['advice', 'info', 'problem'],
        },
        {
          title: this.contentData.step_two_advertising_cta_label,
          iconId: this.contentData.step_two_advertising_icon,
          active: this.contentData.step_two_advertising_cta_active,
          slug: 'spot',
          where: ['advice'],
        },
        {
          title: this.contentData.step_two_shops_cta_label,
          iconId: this.contentData.step_two_shops_icon,
          active: this.contentData.step_two_shops_cta_active,
          slug: 'where-to-buy',
          where: ['info'],
        },
        {
          title: this.contentData.step_two_company_cta_label,
          iconId: this.contentData.step_two_company_icon,
          active: this.contentData.step_two_company_cta_active,
          slug: 'companies',
          where: ['info'],
        },
        {
          title: this.contentData.step_two_sponsorship_cta_label,
          iconId: this.contentData.step_two_sponsorship_icon,
          active: this.contentData.step_two_sponsorship_cta_active,
          slug: 'sponsorship',
          where: ['info'],
        },
        {
          title: this.contentData.step_two_other_cta_label,
          iconId: this.contentData.step_two_other_icon,
          active: this.contentData.step_two_other_cta_active,
          slug: 'other',
          where: ['advice', 'info', 'problem'],
        },
      ];
    },
    stepLabel() {
      switch (this.formData['step-1']) {
        case 'info':
          return this.contentData.step_two_question_step_label;
        case 'problem':
          return this.contentData.step_two_problem_step_label;
        case 'advice':
          return this.contentData.step_two_suggestion_step_label;
        default:
          return '';
      }
    },
    stepTitle() {
      let title;
      switch (this.formData['step-1']) {
        case 'info':
          title = this.contentData.step_two_question_step_title;
          break;
        case 'problem':
          title = this.contentData.step_two_problem_step_title;
          break;
        case 'advice':
          title = this.contentData.step_two_suggestion_step_title;
          break;
        default:
          title = '';
          break;
      }
      const obj = this.step2Links.find(
        (a) => a.slug === this.formData['step-2']
      );
      return obj ? obj.title : title;
    },
  },
  updated() {
    if (!this.currentStep) {
      console.log('handle currentStep setting after gigya login');
    } else {
      if (this.currentStep.slug === this.current.slug) {
        this.setHeight(this.$el.offsetHeight);
      }
    }
  },
  methods: {
    ...mapActions('contact', ['setCurrentStep', 'setHeight', 'setFormData']),
  },
};
