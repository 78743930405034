<template>
  <footer :class="['WaFooter', theme]">
    <!--
      Variants
      blue-logo -> set the backgroundColor blue with text white
    -->
    <div :class="['container-fluid', dataTopSpacing, dataBottomSpacing]">
      <div class="row">
        <div class="col-12">
          <!-- TopFooter -->
          <div class="row topFooter">
            <nav
              v-if="$_cmsSupport_hasContentSlotWithName('columns')"
              class="col-12 col-md-9 navigationColumns"
            >
              <div class="row">
                <slot name="columns" />
              </div>
            </nav>
            <div
              v-if="['md', 'lg'].includes($currentStep)"
              class="col-md-3 d-flex justify-content-md-end"
            >
              <div
                class="navbarLanguage typo-a-9"
                @click.prevent="onBtnLangClick"
              >
                <a class="currentLangItem" href="#">
                  {{ currentCountry }} ({{ currentLang }})
                </a>
                <svg width="24" height="24">
                  <use href="#icon-world" fill="currentColor" />
                </svg>
              </div>
            </div>
          </div>
          <!-- end TopFooter -->
          <!-- BottomFooter -->
          <div class="bottomFooter">
            <div
              v-if="['xs', 'sm'].includes($currentStep)"
              class="navbarLanguage typo-a-9"
            >
              <a class="currentLangItem" @click.prevent="onBtnLangClick">
                {{ currentCountry }} ({{ currentLang }})
              </a>
              <svg width="24" height="24">
                <use href="#icon-world" fill="currentColor" />
              </svg>
            </div>
            <slot name="wa-social-share" />
            <div
              v-if="$_cmsSupport_hasContentSlotWithName('legalText')"
              class="copyrightFooter"
            >
              <slot name="legalText" />
            </div>
          </div>
          <!-- end BottomFooter -->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LanguageSupport from '@mixins/language-support';

export default {
  name: 'WaFooter',
  mixins: [LanguageSupport],
  props: {
    dataTopSpacing: {
      type: String,
      default: 'padding-top-l',
    },
    dataBottomSpacing: {
      type: String,
      default: 'padding-bottom-l',
    },
  },
  computed: {
    theme() {
      return this.$store.state.config.inverted_footer
        ? 'bg-color-blue-logo'
        : 'light';
    },
  },
  methods: {
    onChange(event) {
      return (this.label = event.target.value);
    },
    onBtnLangClick() {
      this.$modal.show('modal-name');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables.scss';
@import '~bootstrap/scss/mixins/breakpoints';

.WaFooter {
  background-color: $color-grey-4;
  color: $color-blue-logo;

  .topFooter {
    margin-bottom: 28px;

    nav {
      ul {
        display: block;
        list-style: none;
        margin-top: 0px;
        padding-left: 0px;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
      }

      li {
        margin-bottom: 32px;
      }
    }

    .navigationColumns {
      .WaFooterCol:nth-child(n + 3) {
        @media (min-width: $bootstrap-xs) {
          margin-top: 32px;
        }

        @media (min-width: $bootstrap-sm) {
          margin-top: 0px;
        }
      }
    }

    .navbarLanguage {
      margin-top: 59px;
      cursor: pointer;

      @media (min-width: $bootstrap-sm) {
        margin-top: 0px;
      }
    }
  }

  .bottomFooter {
    @media (min-width: $bootstrap-sm) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @include media-breakpoint-up(md) {
      flex-flow: row-reverse;
      align-items: center;
    }

    /deep/ .WaSocialShare {
      @include media-breakpoint-down(xs) {
        margin-bottom: 32px;
        margin-top: 28px;
        text-align: center;
        justify-content: center;
      }

      @include media-breakpoint-up(sm) {
        flex: 0 0 50%;
        justify-content: flex-end;
      }
    }

    /deep/ .WaSocialShareItem {
      @include media-breakpoint-up(xs) {
        justify-content: center;
      }

      //&:hover {
      //  color: $color-cobalt-blue;
      //}
    }

    .copyrightFooter {
      color: $color-grey-8;
      text-align: center;
      //flex: 100%;

      @include media-breakpoint-up(sm) {
        margin-top: 40px;
        text-align: left;
        flex: 100%;
      }

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }

      //@media (min-width: $bootstrap-sm) {
      //  margin-top: 40px;
      //}
      //
      //@media (min-width: $bootstrap-md) {
      //  text-align: left;
      //  flex: 0 50%;
      //  margin-top: 0px;
      //}
      //
      //@media (min-width: $bootstrap-lg) {
      //  text-align: left;
      //}

      p {
        margin-bottom: 0;
      }
    }

    .navbarLanguage {
      text-align: center;
      cursor: pointer;

      @include media-breakpoint-up(sm) {
        display: flex;
        flex: 0 0 50%;
        align-items: center;
      }

      // flex: 0 50%;

      // @media (min-width: $bootstrap-sm) {
      //   text-align: left;

      // }
    }
  }

  .currentLangItem {
    color: $color-blue-logo;
    text-decoration: underline;
    display: inline-block;
    padding-right: 8px;
  }

  &.bg-color-blue-logo {
    background-color: $color-blue-logo;
    color: #ffffff;

    .currentLangItem {
      color: $color-white;
      text-decoration: underline;
      display: inline-block;
      padding-right: 8px;
    }

    .socialIconsFooter {
      .socialIcon {
        color: #ffffff;
      }
    }

    .copyrightFooter {
      color: $color-grey-4;
    }
  }
}
</style>
