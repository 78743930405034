<template>
  <div class="WaPm56SandwichSurfPrize">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WaPm56SandwichSurfPrize',
  props: {
    dataSandwichSurf: {
      type: [Object, String],
      required: true,
    },
    dataRegex: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    i18n() {
      return this.$_cmsSupport_castPropToType(this.dataSandwichSurf, 'object');
    },
    regex() {
      return this.$_cmsSupport_castPropToType(this.dataRegex, 'object');
    },
  },
  created() {
    this.setPm56SandwichSurfI18nPrize(this.i18n);
    this.setPm56SandwichSurfRegex(this.regex);
  },
  methods: {
    ...mapActions('pm56SandwichSurf', [
      'setPm56SandwichSurfI18nPrize',
      'setPm56SandwichSurfRegex',
    ]),
  },
};
</script>

<style scoped></style>
