<template functional>
  <div
    :ref="data.ref"
    :class="['WaRegularDivider', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <svg class="divider">
      <line x1="0" y1="0" x2="100%" y2="1" stroke="currentColor" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'WaRegularDivider',
  functional: true,
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';

.WaRegularDivider {
  color: $color-grey-4;

  .divider {
    display: block;
    width: 100%;
    height: 1px;
    margin: 0 auto;
  }
}
</style>
