import { render, staticRenderFns } from "./wa-upload.vue?vue&type=template&id=3e33d558&scoped=true&"
import script from "./wa-upload.vue?vue&type=script&lang=js&"
export * from "./wa-upload.vue?vue&type=script&lang=js&"
import style0 from "./wa-upload.vue?vue&type=style&index=0&id=3e33d558&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e33d558",
  null
  
)

export default component.exports