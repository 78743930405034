<template>
  <div class="WaPm56SeptemberGameRedeem">
    <div v-if="showPage">
      <wa-hero
        data-variant-class="center"
        :data-bg-src-image="require('@images/september-game/banner-bg.jpg')"
        :data-bg-mobile-src-image="
          require('@images/september-game/banner-bg-small.jpg')
        "
        :data-has-down-arrow="true"
      >
        <h1 slot="title" class="text-color-white">
          {{ i18nPrize.hero_title }}
        </h1>

        <h4 slot="title-small" class="text-color-white">
          {{ i18nPrize.hero_small_title }}
        </h4>

        <p slot="description" class="text-color-white">
          {{ i18nPrize.hero_description }}
        </p>
      </wa-hero>
      <div class="container-fluid padding-top-xxl padding-bottom-xxl">
        <h3
          class="text-color-sport-blue text-center typo-a-4 typo-extra-bold margin-bottom-0"
        >
          {{ i18nPrize.title }}
        </h3>
        <p class="text-center typo-a-7 typo-light margin-top-m margin-bottom-m">
          <span>{{ i18nPrize.description }}</span>
        </p>
        <form ref="$form" class="WaPm56SeptemberGameRedeemForm" @submit.prevent>
          <div v-if="showForm" class="row justify-content-center">
            <div class="col-12 col-md-4 padding-bottom-r">
              <wa-input
                v-model.trim="$v.first_name.$model"
                :data-label="i18nPrize.name_label"
                data-name="first_name"
                :data-error-occurred="$v.first_name.$error"
                :data-errors="[
                  {
                    condition: !$v.first_name.required,
                    message: i18nPrize.name_error_required,
                  },
                  {
                    condition: !$v.first_name.minLength,
                    message: i18nPrize.name_error_minLength,
                  },
                  {
                    condition: !$v.first_name.maxLength,
                    message: i18nPrize.name_error_maxLength,
                  },
                  {
                    condition: !$v.first_name.regex,
                    message: i18nPrize.name_error_regex,
                  },
                  {
                    condition: !$v.first_name.serverError,
                    message: serverErrors.first_name,
                  },
                ]"
              />
            </div>
            <div class="col-12 col-md-4 padding-bottom-r">
              <wa-input
                v-model.trim="$v.last_name.$model"
                :data-label="i18nPrize.surname_label"
                data-name="last_name"
                :data-error-occurred="$v.last_name.$error"
                :data-errors="[
                  {
                    condition: !$v.last_name.required,
                    message: i18nPrize.surname_error_required,
                  },
                  {
                    condition: !$v.last_name.minLength,
                    message: i18nPrize.surname_error_minLength,
                  },
                  {
                    condition: !$v.last_name.maxLength,
                    message: i18nPrize.surname_error_maxLength,
                  },
                  {
                    condition: !$v.last_name.regex,
                    message: i18nPrize.surname_error_regex,
                  },
                  {
                    condition: !$v.last_name.serverError,
                    message: serverErrors.last_name,
                  },
                ]"
              />
            </div>
          </div>
          <div
            v-if="showForm"
            class="row align-items-start justify-content-center"
          >
            <div
              class="col-12 col-md-4 padding-bottom-r padding-top-xs d-flex align-items-center"
            >
              <svg
                v-tooltip.auto="{
                  content: loggedDataTooltip,
                  autoHide: true,
                  hideOnTargetClick: true,
                  trigger: 'hover click',
                }"
                class="info"
              >
                <use href="#icon-info-circle" fill="currentColor" />
              </svg>
              <wa-input
                v-model.trim="$v.email.$model"
                :data-label="i18nPrize.email_label"
                data-name="email"
                :data-disabled="true"
              />
            </div>
            <div
              class="col-12 col-md-4 padding-bottom-r padding-top-xs phone-container"
            >
              <wa-input
                v-model.trim="$v.phone_prefix.$model"
                :data-label="i18nPrize.prefix_label"
                data-name="phone_prefix"
                class="phone-prefix"
                :data-disabled="true"
              />
              <wa-input
                v-model.trim="$v.phone_number.$model"
                :data-label="i18nPrize.phone_label"
                data-name="phone_number"
                class="phone-number"
                :data-error-occurred="$v.phone_number.$error"
                :data-errors="[
                  {
                    condition: !$v.phone_number.required,
                    message: i18nPrize.phone_error_required,
                  },
                  {
                    condition: !$v.phone_number.minLength,
                    message: i18nPrize.phone_error_minLength,
                  },
                  {
                    condition: !$v.phone_number.maxLength,
                    message: i18nPrize.phone_error_maxLength,
                  },
                  {
                    condition: !$v.phone_number.regex,
                    message: i18nPrize.phone_error_regex,
                  },
                  {
                    condition: !$v.phone_number.serverError,
                    message: serverErrors.phone_number,
                  },
                ]"
              />
            </div>
          </div>
          <div v-if="showForm" class="row justify-content-center">
            <div class="col-12 col-md-8 padding-top-xs padding-bottom-l">
              <span class="typo-a-9 typo-light text-color-grey-8">
                {{ i18nPrize.mandatory_field_indication_placeholder }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <div class="d-block d-sm-inline-block">
                <wa-cta
                  v-if="showForm"
                  class="primary"
                  data-type="submit"
                  :data-icon-id="spinner ? 'icon-ellipsis-loader' : ''"
                  :data-disabled="disableSubmit || submitted"
                  @click.prevent="onSubmit"
                >
                  {{ i18nPrize.submit_cta }}
                </wa-cta>
                <span
                  v-for="(error, i) in hasGlobalErrors"
                  :key="`error-${i}-${_uid}`"
                  class="global-error typo-a-10 typo-medium"
                  v-html="error.message"
                />
                <span
                  v-if="showGlobalErrorFixFields"
                  class="global-error typo-a-10 typo-medium"
                  v-html="i18nPrize.global_error_fix_fields"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
      <div class="padding-top-xxl padding-bottom-xxl">
        <div class="dimmer" />
        <div class="loader" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  requiredUnless,
} from 'vuelidate/lib/validators';
import LanguageSupport from '@mixins/language-support';
import axios from 'axios';
import { debug, colorLog, brain, pop } from '@helpers/utils.js';
import { getGigyaAdditionalDataForWinningCalls } from './utils';

const localDebug = false;

const checkServerErrors = (param) => {
  return (value, vm) => {
    return Object.keys(vm.serverErrors).indexOf(param) === -1;
  };
};

export default {
  name: 'WaPm56SeptemberGameRedeem',
  mixins: [LanguageSupport],
  data() {
    return {
      showPage: false,
      showForm: false,
      prizeId: null,
      /* form field */
      first_name: null,
      last_name: null,
      email: null,
      phone_prefix: null,
      phone_number: null,
      city: null,
      postal_code: null,
      address: null,
      playToken: null,
      /* after submit */
      submitted: false,
      spinner: false,
      serverErrors: {},
      globalErrors: [],
      showGlobalErrorFixFields: false,
      isEditMode: 'waitingForXhrResponse',
    };
  },
  computed: {
    ...mapState(['storeSetupCompleted']),
    ...mapState('config', ['home_page_url', 'apis']),
    ...mapState('gigya', ['loaded', 'user']),
    ...mapState('pm56SeptemberGame', {
      checkWinner: (state) => state['personal_area_api'],
      redeemPrize: (state) => state['instant_win_api'],
      regex: (state) => state.regex,
    }),
    ...mapGetters('pm56SeptemberGame', ['i18nPrize']),
    disableSubmit() {
      if (this.$v && (this.$v.$anyError || this.$v.$invalid)) {
        return true;
      }

      return false;
    },
    loggedDataTooltip() {
      return (
        '<p style="font-size: 1rem; line-height: 1; margin: 0; padding: 10px 5px;">' +
        this.i18nPrize.tooltip_personal_area_text +
        '</p>'
      );
    },
    hasGlobalErrors() {
      if (this.globalErrors.length) {
        return this.globalErrors
          .map((e) => {
            if (Array.isArray(e.message)) {
              e.message = e.message.join('<br>');
            }
            return e;
          })
          .filter((e) => e.condition);
      } else {
        return [];
      }
    },
  },
  watch: {
    storeSetupCompleted: {
      handler(val) {
        console.log('1', debug);
        if (val) {
          this.phone_prefix = this.i18nPrize.prefix_default;
        }
      },
      immediate: true,
    },
    loaded: {
      async handler(val) {
        if (val) {
          // se sono pronto
          try {
            const editModeCheckRequest = await axios.get(
              this.apis['edit-mode-api']
            );
            if (editModeCheckRequest.data.edit_mode) {
              // sono in edit mode
              this.isEditMode = true;
              this.handlePage(true);
            } else {
              // sono sul sito pubblico
              this.isEditMode = false;
              this.handlePage(false);
            }
          } catch (e) {
            debug && console.log(e);
            await this.redirectToPage('homepage');
          }
        }
      },
      immediate: true,
    },
  },
  validations() {
    let validations = {
      first_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(60),
        // eslint-disable-next-line no-misleading-character-class
        regex: (val) => new RegExp(this.regex.first_name.regex).test(val),
        serverError: checkServerErrors('first_name'),
      },
      last_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(60),
        // eslint-disable-next-line no-misleading-character-class
        regex: (val) => new RegExp(this.regex.last_name.regex).test(val),
        serverError: checkServerErrors('last_name'),
      },
      email: {},
      phone_prefix: {
        required,
      },
      phone_number: {
        required,
        minLength: minLength(7),
        maxLength: maxLength(60),
        regex: (val) => new RegExp(this.regex.phone.regex).test(val),
        serverError: checkServerErrors('phone_number'),
      },
    };

    return validations;
  },
  created() {
    this.setInvertedHeader(true);
  },
  methods: {
    ...mapActions('config', ['setInvertedHeader']),
    async handlePage(editMode) {
      if (!this.user) {
        if (editMode) {
          this.showPage = true;
          this.showForm = true;
          this.globalErrors.push({
            condition: true,
            message: this.i18nPrize.global_error_logged,
          });
        } else {
          await this.redirectToPage('homepage');
        }
      } else {
        try {
          const apiUrl = localDebug
            ? 'https://run.mocky.io/v3/ff28fdce-3866-400c-8401-5d868f898043'
            : this.checkWinner;

          const winningAdditionalPayload =
            await getGigyaAdditionalDataForWinningCalls();

          const result = await axios.post(apiUrl, {
            ...winningAdditionalPayload,
          });

          /**
            {
              "message":"Redeem Requested",
              "data":{
                "prizes":[
                  {
                    "is_winner":true,
                    "redeemed":true,
                    "play_token":""
                  }
                ]
              }
            }
           */
          if (result.data) {
            let prize = result.data.data.prizes[0];
            if (prize.play_token) {
              this.playToken = prize.play_token;
            }
            if (prize.is_winner) {
              this.showPage = true;
              this.showForm = true;
              if (prize.id === 1) {
                this.prizeId = 1;
              } else if (prize.id === 2) {
                this.prizeId = 2;
              }
              if (prize.redeemed) {
                await this.redirectToPage('redeemed');
              }
            } else {
              if (!editMode) {
                //Probabilmente jeko ha cambiato qualcosa o il nostro backend non sta rispondendo correttamente?
                await this.redirectToPage('homepage');
              } else {
                // siamo in debug mode
                // loggati con gigya
                // con un utente che ha partecipato all'iniziativa
                // dobbiamo permettere l'edit del widget per le traduzioni
                // probabilmente è uno dei nostri utenti di staff
                this.showPage = true;
                this.showForm = true;
              }
            }
          }
        } catch (e) {
          const propertyNames = Object.getOwnPropertyNames(e);
          if (propertyNames.includes('response')) {
            const responsePropertyContent = Object.getOwnPropertyDescriptor(
              e,
              'response'
            );
            const responseCode = responsePropertyContent.value.status;
            const serverResponse = responsePropertyContent.value.data;
            if (responseCode === 400) {
              if (
                'errors' in serverResponse &&
                'idCode' in serverResponse.errors
              ) {
                const code = serverResponse.errors.idCode;
                const message = this.i18nPrize[code]
                  ? this.i18nPrize[code]
                  : this.i18nPrize.global_server_error;
                this.globalErrors.push({
                  condition: true,
                  message: message,
                });
              } else {
                for (const [property, value] of Object.entries(
                  serverResponse.errors
                )) {
                  if (property == 'non_field_errors') {
                    // Global validation errors
                    //this is an old code used for all the promos, luckly arrives always just one value in the array 'value' so
                    //this.i18nParticipate[value] works but if multiple values will arrives it will brake
                    this.globalErrors.push({
                      condition: true,
                      message: this.i18nPrize[value]
                        ? this.i18nPrize[value]
                        : value,
                    });
                  } else {
                    if (property === 'receipt_products') {
                      this.show_selected_products_error = true;
                    } else {
                      let temp = {};
                      //Here we removed some dead code about temp[property] = this.i18nParticipate[value] ? this.i18nParticipate[value]: value;
                      //First of all because this.i18nParticipate[value] will be always undefined, value is a string and not the key for the object i18nParticipate
                      //Second because we are not handling client errors between our frontend and our backend
                      //just between our frontend and proxied messages from promo engine backend

                      //6/9/2022 we fixed implementation and now we can take the correct translations
                      //let's comment old code and put new implementation
                      // temp[property] = value;
                      // this.serverErrors = { ...this.serverErrors, ...temp };
                      // this.$v[property] && this.$v[property].$touch();
                      const translatedMessagesConcatenatedString = value.reduce(
                        (acc, curr) => {
                          if (!acc) {
                            return this.i18nPrize[curr]
                              ? this.i18nPrize[curr]
                              : curr;
                          } else {
                            return `${acc}, ${
                              this.i18nPrize[curr] ? this.i18nPrize[curr] : curr
                            }`;
                          }
                        },
                        ''
                      );
                      temp[property] = translatedMessagesConcatenatedString;
                      this.serverErrors = { ...this.serverErrors, ...temp };
                      this.$v[property] && this.$v[property].$touch();
                    }
                  }
                }

                //receipt_products

                Object.keys(this.serverErrors).forEach((key) => {
                  const unwatch = this.$watch(key, (newVal, oldVal) => {
                    if (newVal !== oldVal) {
                      Vue.delete(this.serverErrors, key);
                      setTimeout(() => {
                        this.$v[key] && this.$v[key].$touch();
                      }, 0);
                      if (unwatch) {
                        unwatch();
                      }
                    }
                  });
                });

                // Renew the submission
              }
              this.showPage = true;
              if (editMode) {
                debug &&
                  colorLog({
                    icon: brain,
                    label: 'DEBUG',
                    message: `EDIT MODE - Private area 400 error- FORCING SHOW FORM`,
                    themeName: 'red',
                  });
                this.showForm = true;
              }
            } else if (responseCode === 401 || responseCode === 403) {
              //No redeemable prizes or no played at all
              if (editMode) {
                debug &&
                  colorLog({
                    icon: brain,
                    label: 'DEBUG',
                    message: `EDIT MODE - No redeemable prizes or no played at all 401/403 - SKIP REDIRECT HOMEPAGE`,
                    themeName: 'red',
                  });
                this.showPage = true;
                this.showForm = true;
              } else {
                await this.redirectToPage('homepage');
              }
            } else if (responseCode === 500) {
              //Server error
              if (editMode) {
                debug &&
                  colorLog({
                    icon: brain,
                    label: 'DEBUG',
                    message: `EDIT MODE - Server error 500 - SKIP REDIRECT HOMEPAGE`,
                    themeName: 'red',
                  });
                this.showPage = true;
                this.showForm = true;
              } else {
                await this.redirectToPage('homepage');
              }
            } else {
              //Unknown responsecode
              if (editMode) {
                debug &&
                  colorLog({
                    icon: brain,
                    label: 'DEBUG',
                    message: `EDIT MODE - Unknown responsecode - SKIP REDIRECT HOMEPAGE`,
                    themeName: 'red',
                  });
                this.showPage = true;
                this.showForm = true;
              } else {
                await this.redirectToPage('homepage');
              }
            }
          } else {
            //Errror in frontend code?
            if (editMode) {
              debug &&
                colorLog({
                  icon: brain,
                  label: 'DEBUG',
                  message: `EDIT MODE - Errror in frontend code? - SKIP REDIRECT HOMEPAGE`,
                  themeName: 'red',
                });
              this.showPage = true;
              this.showForm = true;
            } else {
              await this.redirectToPage('homepage');
            }
          }
        }

        if (this.user.firstName) {
          this.first_name = this.user.firstName;
        }

        if (this.user.lastName) {
          this.last_name = this.user.lastName;
        }

        if (this.user.email) {
          this.email = this.user.email;
        }
      }
    },
    async redirectToPage(page) {
      switch (page) {
        case 'redeemed':
          // Already Redeemed or just redeemed after successfully form submit
          await this.$store.dispatch('setUnlockedView', 'redeemed');
          // Navigate
          await this.$router.push({ name: 'redeemed' });
          break;
        default:
          window.location.href = this.home_page_url;
      }
    },
    async onSubmit() {
      if (!this.submitted && !this.disableSubmit) {
        this.submitted = true;
        this.spinner = true;
        this.showGlobalErrorFixFields = false;
        this.globalErrors = [];

        try {
          const formData = new FormData();
          const winningAdditionalPayload =
            await getGigyaAdditionalDataForWinningCalls();

          Object.keys(winningAdditionalPayload).forEach((key) => {
            formData.append(key, winningAdditionalPayload[key]);
          });

          formData.append('play_token', this.playToken);
          formData.append('user_first_name', this.first_name);
          formData.append('user_last_name', this.last_name);
          this.phone_prefix &&
            this.phone_number &&
            formData.append(
              'user_phone',
              this.phone_prefix.split('+')[0] + this.phone_number
            );

          let result;
          if (localDebug) {
            result = await axios.get(
              'https://run.mocky.io/v3/0ca68403-0e1b-49fb-b119-7b87820f2c4d',
              formData
            );
          } else {
            result = await axios.post(this.redeemPrize, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
          }
          if (result.data) {
            await this.redirectToPage('redeemed');
          }
        } catch (e) {
          const propertyNames = Object.getOwnPropertyNames(e);
          if (propertyNames.includes('response')) {
            const responsePropertyContent = Object.getOwnPropertyDescriptor(
              e,
              'response'
            );
            const responseCode = responsePropertyContent.value.status;
            const serverResponse = responsePropertyContent.value.data;
            if (responseCode === 400) {
              if (
                'errors' in serverResponse &&
                'idCode' in serverResponse.errors
              ) {
                const code = serverResponse.errors.idCode;
                const message = this.i18nPrize[code]
                  ? this.i18nPrize[code]
                  : this.i18nPrize.global_server_error;
                this.globalErrors.push({
                  condition: true,
                  message: message,
                });
                this.showGlobalErrorFixFields = true;
              } else {
                console.error('Unhandled 400 error, wrong response payload');
              }
            } else if (responseCode === 401 || responseCode === 403) {
              if ('detail' in serverResponse) {
                this.globalErrors.push({
                  condition: true,
                  message: this.i18nPrize.global_error_logged,
                });
              } else {
                console.error(
                  'Unhandled 401 or 403 error, wrong response payload'
                );
              }
            } else if (responseCode === 500) {
              this.globalErrors.push({
                condition: true,
                message: this.i18nPrize.global_server_error,
              });
            } else {
              console.warn('Unknown response code');
              this.globalErrors.push({
                condition: true,
                message: this.i18nPrize.global_server_error,
              });
            }
          } else {
            console.error('Unhandled error, wrong format');
          }
          this.submitted = false;
        } finally {
          this.spinner = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaPm56SeptemberGameRedeem {
  .dimmer {
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw !important;
    height: 100vh !important;
  }

  .loader {
    height: 48px !important;
    width: 48px !important;
    color: rgba(255, 255, 255, 1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: rgba(0, 0, 0, 0.8);
    animation: rotate 1s linear infinite;
    min-width: 0;
    min-height: 0;
    background: none;
  }

  @keyframes rotate {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0);
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(360deg);
    }
  }

  .icon {
    width: 48px;
    height: 48px;

    @media (min-width: $bootstrap-md) {
      width: 64px;
      height: 64px;
    }
  }

  /deep/ svg.info {
    color: $color-blue-logo;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
  }

  /deep/ .phone-container {
    display: flex;

    .phone-prefix {
      max-width: 70px;
      flex-grow: 0;

      input[type='text'] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0 !important;
      }
    }

    .phone-number {
      width: auto;
      flex-grow: 1;

      input[type='text'] {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .global-error {
    display: block;
    padding-top: 14px;
    color: $color-informative-red;
    text-align: left;
  }
}
</style>
