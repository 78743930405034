<template>
  <div class="WaHeroSlider">
    <div ref="$scroller" class="swiper-container">
      <button
        type="button"
        class="swiper-button swiper-button-prev"
        @click="prevSlide"
      >
        <svg width="24" height="24">
          <use href="#icon-arrow-angle-right" fill="currentColor" />
        </svg>
      </button>
      <button
        type="button"
        class="swiper-button swiper-button-next"
        @click="nextSlide"
      >
        <svg width="24" height="24">
          <use href="#icon-arrow-angle-right" fill="currentColor" />
        </svg>
      </button>
      <div class="swiper-wrapper">
        <swiper-slide
          v-for="(node, index) of $slots.slides"
          :key="`hero-slide-${index}`"
          :value="node"
        />
      </div>
      <div ref="$pagination" class="swiper-pagination padding-bottom-m" />
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';

export default {
  name: 'WaHeroSlider',
  components: {
    SwiperSlide: {
      props: {
        value: { type: [Object, Array], required: true },
      },
      render: function (createElement) {
        let nodes = this.value;
        if (!Array.isArray(nodes)) {
          nodes = [nodes];
        }
        return createElement('div', { class: 'swiper-slide' }, nodes);
      },
    },
  },
  props: {
    dataAutoplay: { type: [Boolean, String], default: false },
  },
  data() {
    return {
      swiper: null,
      animation: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const auto =
        typeof this.dataAutoplay === 'string'
          ? this.dataAutoplay === 'true'
            ? true
            : false
          : this.dataAutoplay;
      this.swiper = new Swiper(this.$refs.$scroller, {
        slidesPerView: 1,
        speed: 1000,
        effect: 'fade',
        autoHeight: true,
        autoplay: auto
          ? {
              delay: 8000,
              disableOnInteraction: false,
            }
          : false,
        fadeEffect: {
          crossFade: false,
        },
        allowTouchMove: false,
        pagination: {
          el: this.$refs.$pagination,
          type: 'bullets',
          clickable: true,
          renderBullet: (index, className) => {
            return `<button type="button" class="${className}"><span class="sr-only">${
              index + 1
            }</span></button>`;
          },
        },
        on: {
          slideChangeTransitionStart: () => {
            this.animation = true;
          },
          slideChangeTransitionEnd: () => {
            this.animation = false;
          },
        },
      });
    });
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  },
  methods: {
    prevSlide() {
      if (this.animation) {
        return false;
      }
      let prev = this.swiper.activeIndex - 1;
      if (prev < 0) {
        prev = this.swiper.slides.length - 1;
      }
      this.swiper.slideTo(prev);
    },
    nextSlide() {
      if (this.animation) {
        return false;
      }
      let next = this.swiper.activeIndex + 1;
      if (next > this.swiper.slides.length - 1) {
        next = 0;
      }
      this.swiper.slideTo(next);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/_variables';
@import '~bootstrap/scss/mixins/breakpoints';

.WaHeroSlider {
  ::v-deep {
    .WaHero {
      .bg {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba($color-black, 0.35);
          z-index: 1;
        }

        img {
          transform: scale(1);
          transition: transform 9s ease-in-out;
        }
      }

      .hero-content {
        opacity: 0;
        transition: opacity 0.8s ease-in-out;
      }
    }

    .swiper-slide-active {
      .WaHero {
        .bg {
          img {
            transform: scale(1.1);
          }
        }

        .hero-content {
          opacity: 1;
          transition-delay: 0.8s;
        }
      }
    }
  }
}

.swiper-container {
  position: relative;

  .swiper-wrapper {
    position: relative;
    z-index: 1;
  }
}

.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  ::v-deep {
    .swiper-pagination-bullet {
      display: block;
      width: 40px;
      height: 44px;
      margin: 0 4px;
      border: 0;
      padding: 0;
      background: none;
      color: inherit;
      cursor: pointer;
      position: relative;

      &:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1px;
        border-radius: 2px;
        background: $color-grey-2-75;
        transition: background-color 0.3s ease;
      }

      &-active {
        &:after {
          background: $color-white;
        }
      }
    }
  }
}

.swiper-button {
  padding: 0;
  border: 0;
  background: rgba($color-black, 0.5);
  color: rgba($color-white, 0.75);
  font-size: 14px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  position: absolute;
  right: 16px;
  top: 50%;
  z-index: 2;
  margin-top: -20px;
  outline-width: 0;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  @include media-breakpoint-up(md) {
    right: 24px;
  }

  &:hover {
    background-color: $color-black;
  }

  svg {
    display: block;
    margin-right: -1px;
    pointer-events: none;
  }

  &.swiper-button-prev {
    right: auto;
    left: 16px;

    @include media-breakpoint-up(md) {
      left: 24px;
    }

    svg {
      transform: rotate(180deg);
      margin-right: 2px;
    }
  }
}
</style>
