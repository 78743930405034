<template>
  <div class="ContactFormGridCmp container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactFormGridCmp',
};
</script>

<style scoped></style>
