<template functional>
  <a
    ref="data.ref"
    :href="props.dataHref"
    :target="props.dataTarget"
    :rel="props.dataRel ? props.dataRel : null"
    :class="['WaHeaderMenuItem', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <span class="content">
      <slot />
    </span>
  </a>
</template>

<script>
export default {
  name: 'WaHeaderMenuItem',
  functional: true,
  props: {
    dataHref: {
      type: String,
      required: true,
    },
    dataTarget: {
      type: String,
      default: '_self',
    },
    dataRel: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$scss/variables';

.WaHeaderMenuItem {
  color: inherit;
  position: relative;
  padding: 8px 0;
  margin: 0 0 24px;

  @media (min-width: $bootstrap-md) {
    padding: 8px 12px;
    margin: 0;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  @media (min-width: $bootstrap-lg) {
    padding: 8px 16px;
  }

  .content {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 2px;
      background-image: linear-gradient($color-blue-logo, $color-blue-logo);
      background-size: 0 100%, auto;
      background-repeat: no-repeat;
      background-position: left bottom;
      transition: all 0.2s ease-out;

      @media (min-width: $bootstrap-md) {
        background-position: center bottom;
      }
    }
  }

  &:hover,
  &:active,
  &.active {
    text-decoration: none;

    .content {
      &::after {
        background-size: 100% 100%, auto;
      }
    }
  }
}
</style>
