<template functional>
  <div
    :class="['WaGridContainer', data.class, data.staticClass]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot name="wa-grid-row" />
  </div>
</template>

<script>
export default {
  name: 'WaGridContainer',
  functional: true,
};
</script>

<style lang="scss" scoped>
.WaGridContainer {
  position: relative;
}
</style>
